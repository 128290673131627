import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
  Grid,
  Modal,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import {
  profile,
  generateTicketDetails,
  generateTicketAcknowledge,
} from "../redux/nbmData/action";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfImage from "../../src/images/pdfImage.png";
import { useLocation } from "react-router-dom";
import VajraLogo from "../images/Vajra_Logo_Design.png";

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",
  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Stack)(({ theme }) => ({
  width: "750px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomSet = styled(Stack)(({ theme }) => ({
  width: "750px",
  height: "70px",
  padding: "20px",
  flexDirection: "column",
  alignItems: "flex-end",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "0px",
    paddingTop: "20px",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextSet = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: "white",

  padding: "10px 20px 10px 20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerComponent = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftComponent = styled(Box)(({ theme }) => ({
  width: "60%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightComponent = styled(Box)(({ theme }) => ({
  width: "40%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const GeneratedTicketDetails = () => {
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlSegments = location.pathname.split("/");
  const tktid = urlSegments[urlSegments.length - 1];
  const params = useParams();
  const [acknowlegmentdata, setacknowlegmentdata] = useState(false);
    let profileData = useSelector((store) => store.data.profile);
    useEffect(() => {
      let data = {
        userid: userIds.userid,
        hospid: userIds.hospid,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };
  
      dispatch(profile(data));
    }, [dispatch]);

  const ticketdetails = useSelector(
    (store) => store.nbmData.generateticketdetailsdata
  );
  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      tktid: tktid,
    };
    dispatch(generateTicketDetails(data));
  }, [dispatch]);

  const HospitalLeftDataOpen = {
    "Department Name": ticketdetails.deptname,
    "Issue Level": ticketdetails.level,
    Status: ticketdetails.status,
  };

  const HospitalRightDataOpen = {
    "Issue Type": ticketdetails.issuetype,
    "Issue Date & Time": `${ticketdetails.startdate || ""} & ${
      ticketdetails.starttime || ""
    }`.trim(),
    "Created By": ticketdetails.created_by,
  };

  const UserLeftData = {
    "Cleared By": ticketdetails.cleared_by,
    "Cleared Date & Time": `${ticketdetails.cleardate || ""} & ${
      ticketdetails.cleartime || ""
    }`.trim(),
  };

  const UserRightData = {
    "Cleared Date": ticketdetails.cleardate,
  };

  const IssueData = {
    "Problem Identified ": ticketdetails.reason,
  };
  const IssueData1 = {
    "Action Taken": ticketdetails?.actiontaken,
  };
  const IssueData2 = {
    Notes: ticketdetails?.notes,
  };
  const ExpenditureData = {
    Cost: ticketdetails.amount,
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.download = "downloaded_image.jpg";
    link.click();
  };

  const handleOpenAcknowlegement = () => {
    setacknowlegmentdata(true);
  };

  const isPDF = (url) => {
    const lowerCaseUrl = url.toLowerCase();
    // console.log("Checking URL:", lowerCaseUrl);
    const urlWithoutParams = lowerCaseUrl.split("?")[0];
    return urlWithoutParams.endsWith(".pdf");
  };
  const downloadImage = (url, index) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `receipt_${index}.jpg`; // Adjust file extension as needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAcknowledge = () => {
    const payload = {
      hospid: userData?.hospid,
      hospname: userIds?.hospname,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      username: userIds?.username,
      userid: userIds?.userid,
      tktid: tktid,
      userrole: userAccess?.role,
      departname: ticketdetails.deptname,
    };

    dispatch(generateTicketAcknowledge(payload))
      .then(() => {
        setacknowlegmentdata(false);
        let data = {
          hospid: userData?.hospid,
          userid: userData?.userid,
          branchid: userBranch.id,
          tktid: tktid,
        };
        dispatch(generateTicketDetails(data));
      })
      .catch((error) => {
        console.error("Error acknowledging ticket:", error);
      });

    navigate("/General-Ticket-list");
  };


  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    const labelX = 20;
    const labelY = 65;
    const dataX = 73;
    let yPosition = 30;
    const pageHeight = doc.internal.pageSize.height;

    const hasEnoughSpace = (heightNeeded) => {
      return yPosition + heightNeeded < pageHeight - 10; // Keep a margin at the bottom
    };

    const addFooter = (doc) => {
      const footerY = doc.internal.pageSize.height - 7; // Adjusted position from bottom
      const textX = doc.internal.pageSize.width - 53; // Right-aligned text

      doc.setTextColor(100, 100, 100);
      doc.setFontSize(10);
      doc.text("Powered by :", textX, footerY, "left");

      if (VajraLogo) {
        const imgWidth = 20;
        const imgHeight = 12;
        const imgX = doc.internal.pageSize.width - 30;
        const imgY = footerY - 7;
        doc.addImage(VajraLogo, "PNG", imgX, imgY, imgWidth, imgHeight);
      }
    };
    // Add logo on top-right
    const addHeader = () => {
      if (profileData.logo_url) {
        doc.addImage(
          profileData.logo_url,
          "JPEG",
          doc.internal.pageSize.width - 200,
          10,
          50,
          25
        );
      }
      doc.setFontSize(16);
      doc.setTextColor("#1F86CB");
      doc.setFont("Helvetica", "bold");
      doc.text(userIds.hospname || "Default Hospital", labelX + 72, 20);

      doc.setFont("Helvetica", "bold");
      doc.text(
        profileData.city + ", " + (profileData.state || "Main Branch"),
        labelX + 64,
        28
      );
    };

    addHeader();

    yPosition = 40;

    const lineStartX = 10; // Move the line closer to the left
    const lineEndX = doc.internal.pageSize.width - 10; // Extend the line closer to the right edge
    doc.setDrawColor(0, 0, 0); // Set line color to black
    doc.setLineWidth(0.5); // Set line width
    doc.line(lineStartX, yPosition, lineEndX, yPosition); // Draw the line
    yPosition += 15;

    // Title: "Incident Report" should be bold
    doc.setFontSize(18);
    doc.setTextColor("black");
    doc.setFont("helvetica", "bold");
    doc.text(
      "Generic Ticket Report",
      doc.internal.pageSize.width / 2,
      yPosition,
      null,
      null,
      "center"
    );

    yPosition += 18;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    const addText = (label, value, heightNeeded) => {
      if (!hasEnoughSpace(heightNeeded)) {
        doc.addPage();
        yPosition = 20;
        addFooter(doc);
      }

      doc.setTextColor(0, 0, 0);
      doc.text(label, labelX, yPosition);
      doc.setTextColor(0, 0, 0); // Set colon color to black
      doc.text(":", labelY, yPosition);
      doc.text(value || "-", dataX, yPosition);
      yPosition += heightNeeded;
    };

    const addUnderlinedText = (text, yPosition, isBold = false) => {
      doc.setFont("Helvetica", isBold ? "normal" : "normal"); // Set to bold or normal font
      doc.setTextColor(0, 0, 0); // Set text color to black
      doc.text(text, labelX, yPosition);
      const textWidth = doc.getTextWidth(text); // Get the width of the text
      doc.line(labelX, yPosition + 1, labelX + textWidth, yPosition + 1); // Draw a line under the text
      return yPosition + 10; // Return the new yPosition after adding the line
    };

    // Add "Product details" section with underline
    yPosition = addUnderlinedText("Ticket details", yPosition, true);
    addText("Department", ticketdetails.deptname, 10);
    addText("Issue Type", ticketdetails.issuetype, 10);
    addText("Issue Level", ticketdetails.level, 10); 
    addText("Issue Date", ticketdetails.startdate, 10);
    addText("Issue Time", ticketdetails.starttime, 10);
    addText("Created By", ticketdetails.created_by, 10);
    addText("Closed Date", ticketdetails.cleardate, 10);
    addText("Closed Time", ticketdetails.cleartime, 10);
    addText("Problem Identified", ticketdetails?.reason, 10);

    if (
      Array.isArray(ticketdetails?.issue_imgs) &&
      ticketdetails.issue_imgs.length > 0
    ) {
      const imageWidth = 50;
      const imageHeight = 50;
      const margin = 10;
      let rowXPosition = 20;
      let rowYPosition = yPosition;

      ticketdetails.issue_imgs.forEach((image, index) => {
        if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
          rowXPosition = 20;
          rowYPosition += imageHeight + margin;
        }

        doc.addImage(
          image,
          "JPEG",
          rowXPosition,
          rowYPosition,
          imageWidth,
          imageHeight
        );
        rowXPosition += imageWidth + margin;
      });

      yPosition += imageHeight + margin;
    }

    addText("Action Taken", ticketdetails?.actiontaken, 10);

    if (ticketdetails?.clear_imgs && ticketdetails?.clear_imgs.length > 0) {
      const imageWidth = 50;
      const imageHeight = 50;
      const margin = 10;
      let rowXPosition = 20;
      const rowYPosition = yPosition;

      ticketdetails.clear_imgs.forEach((image, index) => {
        if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
          rowXPosition = 20;
          yPosition += imageHeight + margin;
        }

        doc.addImage(
          image,
          "JPEG",
          rowXPosition,
          rowYPosition,
          imageWidth,
          imageHeight
        );
        rowXPosition += imageWidth + margin;
      });

      yPosition += imageHeight + margin;
    }

    addText("Notes", ticketdetails.notes, 10);
    addText("Expenditure Cost", ticketdetails.amount, 10);


    // ticketdetails.expenditure.forEach((expenditure, index) => {
    //   addText("Reason", expenditure, 10);
    //   addText("Cost", `${ticketdetails.amt_spent[index]}`, 10);

    //   if (ticketdetails.receipt_id[index]) {
    //     const receiptUrl = ticketdetails.receipt_id[index];
    //     const imageHeight = 60;

    //     if (!hasEnoughSpace(imageHeight)) {
    //       doc.addPage();
    //       yPosition = 20;
    //       addFooter(doc);
    //     }

    //     if (isPDF(receiptUrl)) {
    //       doc.addImage(pdfImage, "JPEG", 20, yPosition, 50, 50);
    //       yPosition += 60;
    //     } else {
    //       doc.addImage(receiptUrl, "JPEG", 20, yPosition, 50, 50);
    //       yPosition += 60;
    //     }
    //   } else {
    //     doc.text("No image available", 20, yPosition);
    //     yPosition += 10;
    //   }

    //   if (!hasEnoughSpace(15)) {
    //     doc.addPage();
    //     yPosition = 20;
    //     addFooter(doc);
    //   }
    // });
    addFooter(doc);
    doc.save("Generic Ticket.pdf");
  };

  return (
    <>
      {ticketdetails?.cleared_by === "NA" ? (
        <Wrapper>
          <InnerSet>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "white",
                padding: "10px 20px 10px 20px",
              }}
            >
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Ticket Details
              </Label>

              <Label sx={{ color: "#FF6B00" }}>{ticketdetails.status}</Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <InnerComponent>
                <LeftComponent sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LeftComponent>
                <RightComponent sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </RightComponent>
              </InnerComponent>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Issue
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      marginLeft: "10px",
                    }}
                  >
                    <Label>Reason</Label>
                  </Stack>

                  <Stack
                    sx={{
                      width: "auto",

                      textAlign: "start",
                      minHeight: "30px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    {ticketdetails.reason}
                  </Stack>
                </Stack>
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      marginLeft: "10px",
                    }}
                  >
                    <Label>Images</Label>
                  </Stack>
                  <Stack
                    sx={{
                      width: "auto",

                      textAlign: "start",
                      minHeight: "170px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    {ticketdetails?.issue_imgs !== "NA" &&
                      ticketdetails?.issue_imgs !== "" && (
                        <Stack
                          flexDirection={"row"}
                          sx={{
                            width: "50%",
                            height: "100px",
                            // overflowY: "hidden",
                            justifyContent: "start",
                            alignItems: "center",
                            // overflowX: "auto",
                            display: { xs: "none", sm: "flex" },
                          }}
                        >
                          {ticketdetails?.issue_imgs?.map((images, index) => (
                            <Box
                              sx={{ height: "65%" }}
                              key={index}
                              position="relative"
                              display="inline-block"
                              mx={1} // Add some margin to separate the images
                            >
                              <Box
                                as="img"
                                src={images}
                                alt="incImage"
                                sx={{ width: "80px", height: "80px" }} // Adjusted width and height
                              />
                              <Box
                                position="absolute"
                                bottom={-20}
                                right={-8}
                                borderRadius={25}
                                sx={{ backgroundColor: "white" }}
                              >
                                <IconButton
                                  sx={{ padding: "0px", cursor: "pointer" }}
                                >
                                  <DownloadIcon
                                    onClick={() => handleDownload(images)}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </InnerSet>

          <BottomSet sx={{}}>
            <Button
              onClick={() => navigate(`/Close-General-Ticket/${params.id}`)}
              variant="contained"
              sx={{ width: "150px", borderRadius: "22px" }}
            >
              Clear Ticket
            </Button>
          </BottomSet>
        </Wrapper>
      ) : (
        <>
          <Wrapper>
            <InnerSet>
              <TextSet>
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Closed Ticket
                </Label>

                <Label>{ticketdetails?.cleardate}</Label>
              </TextSet>

              <Stack sx={{ bgcolor: "white", padding: "20px" }}>
                <Stack
                  sx={{
                    textAlign: "start",

                    marginTop: "25px",
                    paddingLeft: "10px",
                    marginBottom: "20px",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Label variant="subtitle1">Ticket Details</Label>
                  <Divider />
                </Stack>
                <InnerComponent>
                  <LeftComponent>
                    <TableContainer
                      style={{ overflowX: "auto", maxWidth: "100%" }}
                      sx={{}}
                    >
                      <Table sx={{ maxWidth: "100%" }}>
                        <TableBody>
                          {Object.entries(HospitalLeftDataOpen).map(
                            ([key, value]) => (
                              <TableRow key={key}>
                                <TableCells
                                  component="th"
                                  scope="row"
                                  sx={{
                                    color: "rgba(27, 37, 53, 1)",
                                    fontWeight: 700,
                                  }}
                                >
                                  {key}
                                </TableCells>

                                <TableCells sx={{}}>{value}</TableCells>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </LeftComponent>
                  <RightComponent>
                    <TableContainer
                      style={{ overflowX: "auto", maxWidth: "100%" }}
                      sx={{}}
                    >
                      <Table sx={{ width: "100%" }}>
                        <TableBody>
                          {Object.entries(HospitalRightDataOpen).map(
                            ([key, value]) => (
                              <TableRow key={key}>
                                <TableCells
                                  component="th"
                                  scope="row"
                                  sx={{
                                    color: "rgba(27, 37, 53, 1)",
                                    fontWeight: 700,
                                  }}
                                >
                                  {key}
                                </TableCells>

                                <TableCells>{value}</TableCells>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </RightComponent>
                </InnerComponent>

                <Stack
                  sx={{
                    textAlign: "start",

                    marginTop: "25px",
                    paddingLeft: "10px",
                    marginBottom: "20px",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Label variant="subtitle1">Contact Person</Label>
                  <Divider />
                </Stack>
                <Stack
                  flexDirection={"row"}
                  sx={{
                    gap: "20px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack sx={{ width: { xs: "100%", md: "60%" } }}>
                    <TableContainer
                      style={{ overflowX: "auto", width: "100%" }}
                      sx={{}}
                    >
                      <Table sx={{ maxWidth: "100%" }}>
                        <TableBody>
                          {Object.entries(UserLeftData).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                </Stack>

                <Stack
                  sx={{
                    textAlign: "start",

                    marginTop: "25px",
                    paddingLeft: "10px",
                    marginBottom: "20px",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Label variant="subtitle1">Issue Details</Label>
                  <Divider />
                </Stack>

                <Stack
                  flexDirection={"row"}
                  sx={{
                    gap: "20px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    {Object.entries(IssueData).map(([key, value]) => (
                      <Stack
                        sx={{
                          flexDirection: "row",
                        }}
                      >
                        <Stack
                          sx={{
                            width: { xs: "100%", sm: "100%", md: "60%" },

                            textAlign: "start",
                            minHeight: "130px",
                            background: "rgba(244, 246, 246, 1)",
                            padding: "6px",
                          }}
                        >
                          <Label sx={{ margin: "10px" }}>{key}</Label>

                          <Typography
                            sx={{ marginLeft: "10px", wordWrap: "break-word" }}
                          >
                            {value}
                          </Typography>
                          <Stack
                            sx={{
                              width: "20%",
                              gap: "10px",
                              display: { xs: "flex", sm: "none" },
                              flexDirection: "row",
                            }}
                          >
                            <>
                              <Stack
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                {ticketdetails?.issue_imgs !== "NA" &&
                                  ticketdetails?.issue_imgs !== "" && (
                                    <>
                                      <>
                                        {ticketdetails?.issue_imgs?.map(
                                          (images) => (
                                            <Badge
                                              sx={{
                                                height: "85%",
                                                width: "30%",
                                              }}
                                              badgeContent={
                                                <DownloadIcon
                                                  sx={{
                                                    color: "#757575",
                                                  }}
                                                  size={15}
                                                  style={{}}
                                                  onClick={() =>
                                                    handleDownload(images)
                                                  }
                                                />
                                              }
                                              color="secondary"
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                              }}
                                            >
                                              <Stack
                                                sx={{
                                                  width: "65px",

                                                  height: "80px",
                                                  margin: "10px",
                                                }}
                                              >
                                                <Box
                                                  as="img"
                                                  src={images}
                                                  alt="incImage"
                                                  style={{ height: "100%" }}
                                                />
                                              </Stack>
                                            </Badge>
                                          )
                                        )}
                                      </>
                                    </>
                                  )}
                              </Stack>
                            </>
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            width: { md: "40%" },
                            gap: "10px",
                            display: { xs: "none", sm: "flex" },
                            flexDirection: "row",
                          }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            {ticketdetails?.issue_imgs !== "NA" &&
                              ticketdetails?.issue_imgs !== "" && (
                                <>
                                  <>
                                    {ticketdetails?.issue_imgs?.map(
                                      (images) => (
                                        <Badge
                                          sx={{
                                            height: "70%",
                                            width: "30%",
                                          }}
                                          badgeContent={
                                            <DownloadIcon
                                              sx={{
                                                color: "#757575",
                                              }}
                                              size={15}
                                              style={{}}
                                              onClick={() =>
                                                handleDownload(images)
                                              }
                                            />
                                          }
                                          color="secondary"
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                        >
                                          <Stack
                                            sx={{
                                              width: "80px",

                                              height: "80px",
                                              margin: "10px",
                                            }}
                                          >
                                            <Box
                                              as="img"
                                              src={images}
                                              alt="incImage"
                                              style={{ height: "100%" }}
                                            />
                                          </Stack>
                                        </Badge>
                                      )
                                    )}
                                  </>
                                </>
                              )}
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                    {Object.entries(IssueData1).map(([key, value]) => (
                      <Stack
                        sx={{
                          flexDirection: "row",
                        }}
                      >
                        <Stack
                          sx={{
                            width: { xs: "100%", sm: "100%", md: "60%" },

                            textAlign: "start",
                            minHeight: "130px",
                            background: "rgba(244, 246, 246, 1)",
                            padding: "6px",
                          }}
                        >
                          <Label sx={{ margin: "10px" }}>{key}</Label>

                          <Typography
                            sx={{ marginLeft: "10px", wordWrap: "break-word" }}
                          >
                            {value}
                          </Typography>
                          <Stack
                            sx={{
                              width: "20%",
                              gap: "10px",
                              display: { xs: "flex", sm: "none" },
                              flexDirection: "row",
                            }}
                          >
                            <>
                              <Stack
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                {ticketdetails?.clear_imgs !== "NA" &&
                                  ticketdetails?.clear_imgs !== "" && (
                                    <>
                                      {ticketdetails?.clear_imgs?.map(
                                        (images) => (
                                          <Badge
                                            sx={{
                                              height: "85%",
                                              width: "30%",
                                            }}
                                            badgeContent={
                                              <DownloadIcon
                                                sx={{
                                                  color: "#757575",
                                                }}
                                                size={15}
                                                style={{}}
                                                onClick={() =>
                                                  handleDownload(images)
                                                }
                                              />
                                            }
                                            color="secondary"
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right",
                                            }}
                                          >
                                            <Stack
                                              sx={{
                                                width: "65px",

                                                height: "80px",
                                                margin: "10px",
                                              }}
                                            >
                                              <Box
                                                as="img"
                                                src={images}
                                                alt="incImage"
                                                style={{ height: "100%" }}
                                              />
                                            </Stack>
                                          </Badge>
                                        )
                                      )}
                                    </>
                                  )}
                              </Stack>
                            </>
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            width: { md: "40%" },
                            gap: "10px",
                            display: { xs: "none", sm: "flex" },
                            flexDirection: "row",
                          }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            {ticketdetails?.clear_imgs !== "NA" &&
                              ticketdetails?.clear_imgs !== "" && (
                                <>
                                  {ticketdetails?.clear_imgs?.map((images) => (
                                    <Badge
                                      sx={{
                                        height: "63%",
                                        width: "30%",
                                      }}
                                      badgeContent={
                                        <DownloadIcon
                                          sx={{
                                            color: "#757575",
                                          }}
                                          size={15}
                                          style={{}}
                                          onClick={() => handleDownload(images)}
                                        />
                                      }
                                      color="secondary"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                    >
                                      <Stack
                                        sx={{
                                          width: "80px",

                                          height: "80px",
                                          margin: "10px",
                                        }}
                                      >
                                        <Box
                                          as="img"
                                          src={images}
                                          alt="incImage"
                                          style={{ height: "100%" }}
                                        />
                                      </Stack>
                                    </Badge>
                                  ))}
                                </>
                              )}
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                    {Object.entries(IssueData2).map(([key, value]) => (
                      <Stack
                        sx={{
                          width: { sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "20px",
                        }}
                      >
                        <Label>{key}</Label>

                        <Typography
                          sx={{ marginTop: "10px", wordWrap: "break-word" }}
                        >
                          {value}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
                <Box sx={{ width: "100%" }}>
                  {/* Cost Section */}
                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography
                      sx={{
                        textAlign: "start",
                        margin: "5px",
                        fontWeight: "700",
                      }}
                      variant="body1"
                    >
                      Cost
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "start",
                        margin: "5px",
                        fontSize: "0.85rem",
                        fontWeight: "500",
                      }}
                    >
                      ₹ {ticketdetails?.amount || "N/A"}
                    </Typography>
                  </Box>

                  {/* Receipt Section */}
                  <Box>
                    {[ticketdetails.receipt].flat().map((receiptUrl, index) =>
                      receiptUrl ? (
                        <Stack key={index} sx={{ margin: "10px" }}>
                          <Stack sx={{ display: "flex", alignItems: "start" }}>
                            <Typography
                              sx={{ fontWeight: "700" }}
                              variant="body1"
                            >
                              Receipt
                            </Typography>
                          </Stack>

                          <Stack
                            sx={{
                              minHeight: "130px",
                              background: "rgba(244, 246, 246, 1)",
                              width: "60%",
                            }}
                          >
                            <Stack
                              sx={{
                                position: "relative",
                                width: "fit-content",
                                margin: "10px",
                              }}
                            >
                              {isPDF(receiptUrl) ? (
                                <IconButton>
                                  <img
                                    src={pdfImage}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                </IconButton>
                              ) : (
                                <img
                                  src={receiptUrl}
                                  style={{ width: "100px", height: "100px" }}
                                  onClick={() =>
                                    downloadImage(receiptUrl, index)
                                  }
                                />
                              )}
                              <IconButton
                                onClick={() => downloadImage(receiptUrl, index)}
                                aria-label="download"
                                sx={{
                                  position: "absolute",
                                  bottom: -6,
                                  right: -3,
                                  backgroundColor: "white",
                                  color: "#757575",
                                  padding: "5px",
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        <Typography key={index} variant="body1">
                          No image available
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
                <Stack
                                  sx={{
                                    justifyContent: "end",
                                    display: "flex",
                                    alignItems: "end",
                                  }}
                                >
                                  <Button
                                    onClick={handleDownloadPDF}
                                    sx={{
                                      border: "2px solid #4690ff",
                                      borderRadius: "20px",
                                      fontWeight: "bold",
                                      color: "#4690ff",
                                      width: "120px",
                                    }}
                                  >
                                    Download PDF
                                  </Button>
                                </Stack>
              </Stack>
            </InnerSet>
          </Wrapper>
          <Modal
            open={acknowlegmentdata}
            onClose={() => setacknowlegmentdata(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6" component="h2">
                  Confirmation
                </Typography>
                <CloseIcon
                  onClick={() => setacknowlegmentdata(false)}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Are you sure you want to Acknowledge this Ticket?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setacknowlegmentdata(false)}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAcknowledge}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default GeneratedTicketDetails;
