import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Stack, Typography, styled, Tooltip } from "@mui/material";
import { useState } from "react";
import { Amc, Cmc } from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const CmcPage = () => {
  const cmcData = useSelector((store) => store.data.cmcData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole:userAccess?.role,
    };

    dispatch(Cmc(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (cmcData && cmcData?.length > 0) {
      setData(cmcData);
    }
  }, [cmcData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="space-between"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px">
      <Box display="flex" flexDirection="column" gap="10px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px">
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}>{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={""}
              Filter1="department"
              Filter2=""
              FilterName1="Department"
              FilterName2=""
              sortDate=""
              dueDate=""
              name="name"
              csvName="Not Calibrated"
            />
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 130px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 237, 237, 1)",
                    },
                  }}>
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>
                  <TableHeading>Serial No.</TableHeading>
                  <TableHeading>Department</TableHeading>
                  <TableHeading>Date</TableHeading>{" "}
                  <TableHeading>End Date</TableHeading>
                </TableRow>
              </TableHead>
              {/* Table Body with Scrollbar */}
              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData.map((item, index) => (
                  <TableRow
                    key={item?.servid}
                    component={Link}
                    to={`/asset-detail/${item.assetid}`}
                    sx={{
                      "&:hover": {
                        bgcolor: "#EEF5FF",
                      },
                      textDecoration: "none",
                    }}>
                    <TableCells>
                      <Tooltip title={item.name}>{item.name}</Tooltip>
                    </TableCells>

                    <TableCells>
                      <Tooltip title={item.assetcode}>{item.assetcode}</Tooltip>
                    </TableCells>
                    <TableCells>{item.serialno}</TableCells>

                    <TableCells>
                      <Tooltip title={item.department}>
                        {item.department}
                      </Tooltip>
                    </TableCells>
                    <TableCells>{item.startdate}</TableCells>
                    <TableCells>{item.duedate}</TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default CmcPage;
