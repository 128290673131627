import * as React from "react";
import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AllAsset from "./AllAsset";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";



const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
export default function AssetsMobile() {
  const navigate = useNavigate();

  const [assetPageCount, setAssetPageCount] = React.useState({
    department:0,
    asset:0,
    movement:0,
    checkout:0
  })


  const handleDataChange = (childData) => {

    setAssetPageCount({
      department:childData.department_count,
      asset:childData.asset,
      movement:childData.movement,
      checkout:childData.checkout
    });

  };
  const dataCount = useSelector((store) => store.data.allAssetsNew);
  const [data, setData] = useState([]);
  const [allCounts, setAllCounts] = useState({});


  useEffect(() => {
    if (Object.keys(dataCount)?.length > 0) {
      setData(dataCount?.assets);
      let data = {
        department: dataCount?.department_count || 0,
        checkout: dataCount?.checkout_count || 0,
        movements: dataCount?.movement_count || 0,
      };
      setAllCounts(data);
    }
  }, [dataCount]);

  const handleLabelClick = () => {
    navigate('/contact'); 
  };
  return (
    <>
    <Stack
      sx={{
        width: "100%",
        // height: "100vh",
        marginTop:'-11px',
       
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        gap: "30px",
        paddingBottom:'11px',
      }}>
      <Stack
        sx={{
         
          height: "130px",
          bgcolor: "#1B2535",
          width: "100%",
          color: "white",
        }}>
        <Stack
          justifyContent={"space-between"}
          sx={{ flexDirection: "row", p: 2, alignItems: "end", height: "90%" }}>
          <Label variant="h4">Assets.</Label>
          <Label variant="h6"onClick={handleLabelClick}>Request</Label>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "80%",
          height: "45%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          gap: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/department-list")}
            sx={{
              cursor: "pointer",
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {dataCount?.department_count}
            </Label>
            <Label variant="h6">Department</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/all-assets")}
            sx={{
              width: "100px",
              textAlign:"start",
              fontSize:"20px",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {dataCount?.asset_count}
            </Label>
            <Label variant="h6" sx={{width:"80px"}}>Assets</Label>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/asset-history-list")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {dataCount?.movement_count}
            </Label>
            <Label variant="h6">Movements</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/check-in")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {dataCount?.checkout_count}
            </Label>
            <Label variant="h6"sx={{width:"80px",fontSize:"15px"}}>Check Outs</Label>
          </Stack>
        </Box>
      </Box>
      <Divider sx={{ width: "90%" }} />
      {/* <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "22px", width: "80%" }}
        onClick={() => navigate("/depreciation")}>
        Calculate Depreciation
      </Button> */}




    </Stack>


<AllAsset  handleDataChange={handleDataChange} />

    </>
  );
}
