import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Box, Button, Grid } from "@mui/material";
import empty from "../images/notificationImage.png";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Description } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { Stack } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecyclingIcon from "@mui/icons-material/Recycling";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import TaskIcon from "@mui/icons-material/Task";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { notificationList, notificationAllList } from "../redux/data/action";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  width: "100%",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  boxShadow:
    " rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
  padding: "10px",
  background: "white",
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "70px",
}));

const InnerTop = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MainMidSet = styled(Box)(({ theme }) => ({
  boxShadow:
    " rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
  display: "flex",
  flexDirection: "column",
  background: "white",
  width: "100%",
}));

const TitleText = styled(Typography)(({ theme, color }) => ({
  fontWeight: "600",
  fontSize: "18px",
  textAlign: "left",
  color: color || "inherit",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleDescription = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  textAlign: "left",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CloseButton = styled(CloseIcon)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "30px",
  color: "grey",
  marginLeft: "auto",
  transition: "background-color 0.3s, color 0.3s",
  padding: "5px", // Add padding to make the hover area more noticeable
  borderRadius: "50%", // Optional, for a circular button effect
  "&:hover": {
    backgroundColor: "#1B2535",
    color: "white",
  },
}));

export default function BottomAppBar() {
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = React.useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);

  const existingNotificationsString = localStorage.getItem("notification");
  // console.log("eawf",existingNotificationsString)

  const notificationdata = useSelector(
    (store) => store.data.notificationlistdata
  );
  const allnotificationdata = useSelector(
    (store) => store.data.notificationalldata
  );

  useEffect(() => {
    const data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(notificationAllList(data, token));
    dispatch(notificationList(data, token));
  }, [dispatch]);

  const handleNavigate = (link) => {
    navigate(link);
  };
  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  const handleNavigatePage = (link, notification_title) => {
    if (notification_title === "Asset Added") {
      navigate("/all-assets");
    } else if (notification_title === "Asset Moved") {
      navigate("/asset-history-list");
    } else if (notification_title === "Asset Deleted") {
      navigate("/all-assets");
    } else if (notification_title === "Asset Checked OUT") {
      navigate("/check-in");
    } else if (notification_title === "Generic Ticket Raised") {
      navigate("/General-Ticket-list");
    } else if (notification_title === "Generic Ticket Closed") {
      navigate("/General-Ticket-list");
    } else if (notification_title === "Asset Checked IN") {
      navigate("/check-in");
    } else if (notification_title === "Incident Raised") {
      navigate("/incident-list");
    } else if (notification_title === "Incident Cleared") {
      navigate("/incident-list");
    } else if (notification_title === "Service Requested") {
      navigate("/service-list");
    } else if (notification_title === "Service Done") {
      navigate("/service-list");
    } else if (notification_title === "Department Added") {
      navigate("/department-list");
    } else if (notification_title === "Department Deleted") {
      navigate("/department-list");
    } else {
      navigate(link || "/");
    }
  };

  const handleRemoveNotification = (id) => {
    setNotifications((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <Wrapper>
        <TopBox sx={{ borderRadius: "10px" }}>
          <InnerTop>
            <TopLeft>
              <TitleText>Notification</TitleText>
              {/* <CountText>{data.length}</CountText> */}
            </TopLeft>
            <Box sx={{ padding: "10px", display: "flex" }}>
              <NotificationsIcon />
              <Box
                sx={{
                  borderRadius: "50%",
                  background: "rgba(27, 37, 53, 1)",
                  position: "relative",
                  color: "white",
                  width: "20px",
                  height: "20px",
                  bottom: "10px",
                  right: "5px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "8px",
                  justifyContent: "center",
                }}
              >
                {allnotificationdata.length}
              </Box>
            </Box>
          </InnerTop>
        </TopBox>
        <Stack sx={{ width: "100%", gap: "10px" }}>
          {(showAll ? allnotificationdata : notificationdata).length > 0 ? (
            (showAll ? allnotificationdata : notificationdata).map((item) => (
              <MainMidSet key={item.id} sx={{ borderRadius: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    cursor: "pointer",
                    gap: "20px",
                  }}
                >
                  {item.notification_title === "Incident Raised" ? (
                    <Box
                      sx={{
                        background: "#FFBC0399",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <AddCircleIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Service Requested" ? (
                    <Box
                      sx={{
                        background: "#FFBC0399",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <AddCircleIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Asset Deleted" ? (
                    <Box
                      sx={{
                        background: "#FF000499",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Service Done" ? (
                    <Box
                      sx={{
                        background: "#FF000499",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Incident Cleared" ? (
                    <Box
                      sx={{
                        background: "#FF000499",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Department Deleted" ? (
                    <Box
                      sx={{
                        background: "#FF000499",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Asset Moved" ? (
                    <Box
                      sx={{
                        background: "#0026FF99",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <RecyclingIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Asset Added" ? (
                    <Box
                      sx={{
                        background: "#076B18",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <CheckBoxIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Department Added" ? (
                    <Box
                      sx={{
                        background: "#076B18",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <CheckBoxIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Alert" ? (
                    <Box
                      sx={{
                        background: "#FF0000",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <ReportProblemIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Timeout" ? (
                    <Box
                      sx={{
                        background: "#FF000499",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                          xs: "60px",
                          sm: "70px",
                          md: "75px",
                        },
                        width: {
                          xs: "60px",
                          sm: "60px",
                          md: "75px",
                        },
                      }}
                    >
                      <PendingActionsIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Asset Checked OUT" ? (
                    <Box
                      sx={{
                        background: "#B803FF",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "75px",
                        width: "75px",
                      }}
                    >
                      <CallMissedOutgoingIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Generic Ticket Raised" ? (
                    <Box
                      sx={{
                        background: "#B803FF",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "75px",
                        width: "75px",
                      }}
                    >
                      <LocalActivityIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Generic Ticket Closed" ? (
                    <Box
                      sx={{
                        background: "#ff6831",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "75px",
                        width: "75px",
                      }}
                    >
                      <ConfirmationNumberIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : item.notification_title === "Asset Checked IN" ? (
                    <Box
                      sx={{
                        background: "#00812F",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "75px",
                        width: "75px",
                      }}
                    >
                      <TaskIcon
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "20px",
                            sm: "30px",
                            md: "35px",
                            lg: "35px",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <div>No icon available</div> // Fallback case
                  )}

                  <Box
                    sx={{
                      flexGrow: 1,
                      paddingLeft: "10px",
                      display: "grid",
                      gap: "10px",
                    }}
                    onClick={() =>
                      handleNavigatePage(item.link, item.notification_title)
                    }
                  >
                    <TitleText
                      sx={{ display: { sx: "gird", md: "gird", lg: "flex" } }}
                      color={
                        item.notification_title === "Incident Raised"
                          ? "#FFD768"
                          : item.notification_title === "Asset Deleted"
                          ? "#FF6669"
                          : item.notification_title === "Department Deleted"
                          ? "#FF6669"
                          : item.notification_title === "Incident Cleared"
                          ? "#FF6669"
                          : item.notification_title === "Service Requested"
                          ? "#FFD768"
                          : item.notification_title === "Service Done"
                          ? "#FF6669"
                          : item.notification_title === "Asset Added"
                          ? "#076B18"
                          : item.notification_title === "Department Added"
                          ? "#076B18"
                          : item.notification_title === "Timeout"
                          ? "#FF6669"
                          : item.notification_title === "Asset Moved"
                          ? "#0026FF"
                          : item.notification_title === "Generic Ticket Raised"
                          ? "#B803FF"
                           : item.notification_title === "Generic Ticket Closed"
                          ? "#ff6831"
                          : item.notification_title === "Asset Checked OUT"
                          ? "#B803FF"
                          : item.notification_title === "Asset Checked IN"
                          ? "#00812F"
                          : item.notification_title === "Alert"
                          ? "#FF0000"
                          : "black"
                      }
                    >
                      <Box>{item.notification_title} </Box>
                      {/* <Box>
                        <span
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            padding: "20px",
                            fontSize: "13px",
                            fontWeight: "100",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.date} <span>{item.time}</span>
                        </span>
                      </Box> */}
                    </TitleText>

                    <Grid
                      container
                      spacing={2}
                      mt={1}
                      sx={{ textAlign: "left", marginTop: "-20px" }}
                    >
                      {item.asset && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.asset}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.asset_code && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.asset_code}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.departname && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.departname}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.username && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.username}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {/* {item.date && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.date}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.time && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.time}</strong>
                          </Typography>
                        </Grid>
                      )} */}
                      {item.clearuser && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.clearuser}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.downtime && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.downtime}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.startdate && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.startdate}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.enddate && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.enddate}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.despt && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.despt}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.incharge && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.incharge}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.serialno && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.serialno}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.newdeprt && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.newdeprt}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.status && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.status}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.expireddate && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.expireddate}</strong>
                          </Typography>
                        </Grid>
                      )}
                      {item.dateTime && (
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            <strong>{item.dateTime}</strong>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    <TitleDescription>{item.description}</TitleDescription>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "5px",
                      }}
                    >
                      <span>{item.date}</span>
                      <span>{item.time}</span>
                    </Typography>
                  </Box>
                  {/* <CloseButton
                    onClick={() => handleRemoveNotification(item.id)}
                  /> */}
                </Box>
              </MainMidSet>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <NotificationImportantIcon style={{ fontSize: "80px" }} />
              <Typography>No Notifications yet.</Typography>
              <Button variant="contained" onClick={() => navigate(`/`)}>
                Dashboard
              </Button>
            </Box>
          )}

          {/* ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <NotificationImportantIcon style={{ fontSize: "80px" }} />
              <Typography>No Notifications yet.</Typography>
              <Button variant="contained" onClick={handleNavigate}>
                Dashboard
              </Button>
            </Box>
          ) */}
        </Stack>
        {notificationdata.length > 0 &&
          allnotificationdata.length > notificationdata.length && (
            <Stack>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={handleSeeMoreClick}
              >
                {showAll ? (
                  <>
                    Show Less <ArrowUpwardIcon />
                  </>
                ) : (
                  <>
                    See More <ArrowDownwardIcon />
                  </>
                )}
              </Button>
            </Stack>
          )}
      </Wrapper>
    </Box>
  );
}
