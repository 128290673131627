import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { v4 as uuid } from "uuid";
import { useNavigate, useParams } from "react-router-dom";

import {
  getDepartmentData,
  clearGenerateTicket,
  clearIncidentImageUpload,
  clearIncidentImageRemove,
  incidentExpenditureImageUpload,
  clearIncidentExpenditureImageRemove,
  generateTicketDetails,
} from "../redux/nbmData/action";

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  height: "20px",
}));

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const CloseGenerateTicket = () => {
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const allDepartment = useSelector((store) => store.data.department);
  const [assetType, setAssetType] = React.useState("");
  const navigate = useNavigate();
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [customIssue, setCustomIssue] = useState("");
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const [notes, setNotes] = useState(""); // Stores the actual note value
  const [notesError, setNotesError] = useState(""); // Stores the validation error
  const [expenditures, setExpenditures] = React.useState([
    { totalAmountSpent: "", files: [] }, // Default single expenditure
  ]);
  const [fileUrlAccessory, setFileUrlAccessory] = useState([]);
  const [isAccessoryPhotoError, setIsAccessoryPhotoError] = useState("");
  const params = useParams();
  const [actionTaken, setAction] = useState("");
  const [isActionError, setIsActionError] = useState(null);
  const ticketdetails = useSelector(
    (store) => store.nbmData.generateticketdetailsdata
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    // Validation logic to enable/disable the button
    const isValid =
      actionTaken.trim() !== "" &&
      notes.trim() !== "" &&
      expenditures.every((item) => item.totalAmountSpent > 0) &&
      fileUrl.length > 0 &&
      expenditures.every(
        (item, index) =>
          fileUrlAccessory[index] && fileUrlAccessory[index].length > 0
      );

    setIsSubmitDisabled(!isValid);
  }, [actionTaken, notes, expenditures, fileUrl, fileUrlAccessory]);

  const [newExpenditure, setNewExpenditure] = useState({
    description: "",
    totalAmountSpent: "",
    expImage: [{ image: "", imageId: "" }],
  });
  const handleDepartmentChange = (event, value) => {
    setSelectedDepartment(value);
  };

  const handleIssueTypeChange = (event, newValue) => {
    if (newValue === "Other") {
      setSelectedAsset(newValue);
    } else {
      setSelectedAsset(newValue);
      setCustomIssue("");
    }
  };

  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:, -]+$/;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };

  const handleNotes = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:, -]+$/;

    if (value === "") {
      setNotesError("Please fill the field");
      setNotes(""); // Clear the note value
    } else if (!regex.test(value)) {
      setNotesError("Invalid character");
    } else {
      setNotesError(""); // Clear validation error
      setNotes(value); // Update the note value
    }
  };

  const handleTotalAmountSpentObject = (e, index) => {
    const value = e.target.value;
    // Allow only numbers, decimals, and empty values
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value)) {
      let updatedExpenditures = [...expenditures];
      updatedExpenditures[index].totalAmountSpent = value;
      setExpenditures(updatedExpenditures);
    }
  };

  const handleDescriptionObject = (e, index) => {
    const value = e.target.value;
    // Disallow numbers in the reason description
    const regex = /^[a-zA-Z0-9.,\s-]*$/;

    if (regex.test(value)) {
      let updatedExpenditures = [...expenditures];
      updatedExpenditures[index].description = value;
      setExpenditures(updatedExpenditures);
    }
  };

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(getDepartmentData(data));
  }, [dispatch]);
  const [formType, setFormType] = useState(null);
  const handleCloseBox = () => {
    setFormType(null);
  };

  const handlePhotoUpload = (e) => {
    const selectedFiles = e.target.files;
  
    const newFiles = Array.from(selectedFiles).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 4MB limit
  
      if (!allowedTypes.includes(file.type)) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }
  
      if (file.size > allowedSize) {
        setIsPhotoError("Can't upload image more than 4MB.");
        return false;
      }
  
      return true;
    });
  
    // If no valid files, exit early
    if (newFiles.length === 0) return;
  
    // Reset the error message if files are valid
    setIsPhotoError(null);
  
    // Handle each valid file
    newFiles.forEach((file) => {
      const fileType = file.type.split("/")[1];
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // Generate exactly 16-character imageid
        let uniqueId = uuid().replace(/-/g, "").substring(0, 10); // 10 random characters
        let imageid = `asset_${uniqueId}`; // Ensures exactly 16 characters
  
        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}.${fileType}`,
          image: reader.result,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess?.departmentids,
        };
  
        // Add new file to the state
        setFileUrl((prevFiles) => [...prevFiles, sendData]);
  
        // Dispatch the action
        dispatch(clearIncidentImageUpload(sendData, token));
      };
  
      reader.readAsDataURL(file);
    });
  };
  
  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(clearIncidentImageRemove(data));
    setFileUrl(fileUrl?.filter((item) => item?.imageid !== id));
  };
  const assets = [
    "Electrical Issues",
    "Plumbing Issues",
    "AC Issue",
    "Elevator Malfunctions",
    "Building Maintenance",
    "Waste Management",
    "Security Systems",
    "Other",
  ];

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
  };

  const handleAddExpenditure = () => {
    setExpenditures([...expenditures, { ...newExpenditure }]);
    setNewExpenditure({
      description: "",
      totalAmountSpent: "",
      expImage: [{ image: "", imageId: "" }],
    });
  };

  const handleRemoveExpenditure = (index) => {
    const updatedExpenditures = expenditures.filter((_, i) => i !== index);
    setExpenditures(updatedExpenditures);

    const updatedFileUrlAccessory = fileUrlAccessory.filter(
      (_, i) => i !== index
    );
    setFileUrlAccessory(updatedFileUrlAccessory);
  };

  const handleFileUpload = (event, index) => {
    const file = event.target.files[0];
  
    if (file) {
      const allowedImageSize = 4 * 1024 * 1024; // 4 MB
      const allowedPdfSize = 6 * 1024 * 1024; // 6 MB
      const isPdf = file.type === "application/pdf";
      const isImage = file.type.includes("image");
  
      // Check for file size restrictions
      if (isImage && file.size > allowedImageSize) {
        setIsAccessoryPhotoError("Image files must be less than 4MB.");
        return;
      }
  
      if (isPdf && file.size > allowedPdfSize) {
        setIsAccessoryPhotoError("PDF files must be less than 6MB.");
        return;
      }
  
      setIsAccessoryPhotoError(null);
      const fileType = isPdf ? "pdf" : "png";
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // Generate exactly 16-character imageid
        let uniqueId = uuid().replace(/-/g, "").substring(0, 10); // 10 random characters
        let imageid = `asset_${uniqueId}`; // Ensures exactly 16 characters
  
        const newFile = {
          id: uuid(), // Unique id for the file
          name: file.name,
          type: file.type,
          url: URL.createObjectURL(file), // Create a blob URL for immediate display
          imageid: `${imageid}.${fileType}`, // 16-character imageid
        };
  
        const payload = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: newFile.imageid,
          image: reader.result,
          branchid: userBranch.id,
          userrole: userAccess?.role,
          depart_ids: userAccess?.departmentids,
        };
  
        // Dispatching the action for file upload
        dispatch(incidentExpenditureImageUpload(payload))
          .then((response) => {
            const uploadedImage = {
              ...newFile,
              url: response?.payload?.url || newFile.url,
            };
  
            const updatedExpenditures = [...expenditures];
            updatedExpenditures[index].expImage = [uploadedImage];
  
            setExpenditures(updatedExpenditures);
  
            const updatedFileUrls = [...fileUrlAccessory];
            updatedFileUrls[index] = [uploadedImage];
  
            setFileUrlAccessory(updatedFileUrls);
          })
          .catch((error) => {
            // console.error("File upload error:", error);
          });
      };
  
      // Read file as Data URL
      reader.readAsDataURL(file);
    }
  
    event.target.value = null;
  };
  

  const handleDeleteAccessory = async (id, index) => {
    const data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    try {
      await dispatch(clearIncidentExpenditureImageRemove(data));

      const updatedExpenditures = expenditures.map((exp, idx) => {
        if (idx === index) {
          const updatedExpImages = exp.expImage.filter((img) => img.id !== id);
          return { ...exp, expImage: updatedExpImages };
        }
        return exp;
      });

      setExpenditures(updatedExpenditures);

      const updatedFiles = [...fileUrlAccessory];
      updatedFiles[index] = updatedFiles[index].filter(
        (file) => file.id !== id
      );
      setFileUrlAccessory(updatedFiles);
    } catch (error) {}
  };

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      tktid: params.id,
    };
    dispatch(generateTicketDetails(data));
  }, [dispatch]);

  const handleSubmit = () => {
    if (isSubmitDisabled) return;

    const payload = {
      hospid: userData?.hospid,
      hospname: userIds?.hospname,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      username: userIds?.username,
      userid: userIds?.userid,
      userrole: userAccess?.role,
      tktid: params.id,
      creator_id: ticketdetails.created_id,
      departname: ticketdetails.deptname,
      departid: ticketdetails.departid,
      action_taken: actionTaken,
      notes: notes,
      cleared_images: fileUrl.map((file) => file.imageid),
      amt_spent: expenditures?.reduce(
        (total, item) => total + Number(item.totalAmountSpent),
        0
      ),
      receipt_id: expenditures
      ?.flatMap((item, index) => fileUrlAccessory[index]?.map((img) => img.imageid) || [])
      .join(","),
    };
    dispatch(clearGenerateTicket(payload));

    navigate(`/General-Ticket-Details/${params.id}`);
  };

  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

          height: "100%",
        }}
      >
        <Stack
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "80%",
              lg: "70%",
              xl: "70%",
            },

            borderRadius: "8px",

            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            gap: "10px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",

              padding: "20px",
            }}
          >
            <Label
              variant="subtitle1"
              color="primary"
              sx={{ paddingLeft: "20px" }}
            >
              Clear Ticket
            </Label>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              // maxWidth: 800,
              margin: "auto",
              bgcolor: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginTop: 2, width: "90%" }}>
              <Stack sx={{ bgcolor: "white", gap: "30px" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  <Stack
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                      },

                      gap: "15px",

                      height: "100%",
                      textAlign: "start",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "white",
                        padding: "20px",
                        height: "100%",
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      <Label style={{ color: "#4690FF", textAlign: "left" }}>
                        Representative
                      </Label>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          bgcolor: "white",
                          padding: "15px",
                          textAlign: "start",
                        }}
                      >
                        {/* Divider Section */}
                        <Grid item xs={12}>
                          <Divider
                            sx={{ background: "rgba(70, 144, 255, 1)" }}
                          />
                        </Grid>

                        <Stack
                          sx={{
                            flexDirection: "row",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <Stack sx={{ width: "100%" }}>
                            {/* Department Selection */}

                            <Stack sx={{ gap: "10px" }}>
                              <Label>Action Taken  <span style={{color:"red",fontWeight:"100"}}>*</span></Label>
                              <Field
                                multiline
                                rows={3}
                                label="Action Taken"
                                onChange={handleAction}
                                variant="filled"
                                InputProps={{ disableUnderline: true }}
                                sx={{ width: "100%" }}
                              />
                            </Stack>

                            {actionTaken.trim() === "" && (
                              <Typography variant="body2" sx={{ color: "red" }}>
                                Please fill in the Action Taken field.
                              </Typography>
                            )}

                            <Stack sx={{ gap: "10px", marginTop: "10px" }}>
                              <Label>Note  <span style={{color:"red",fontWeight:"100"}}>*</span></Label>
                              <Field
                                multiline
                                rows={3}
                                label="Note"
                                onChange={handleNotes}
                                variant="filled"
                                InputProps={{ disableUnderline: true }}
                                sx={{ width: "100%" }}
                              />
                              {notes.trim() === "" && (
                                <Typography
                                  variant="body2"
                                  sx={{ color: "red" }}
                                >
                                  Please fill in the Notes field.
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              width: "100%",
                              paddingLeft: "10px",
                              gap: "10px",
                            }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                height: "20px",
                              }}
                            >
                              <Stack>
                                <Label variant="subtitle1">Upload Image  <span style={{color:"red",fontWeight:"100"}}>*</span></Label>
                              </Stack>
                              <Stack>
                                <label htmlFor="upload-input">
                                  <input
                                    id="upload-input"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    style={{ display: "none" }}
                                    onChange={handlePhotoUpload}
                                  />
                                  {fileUrl.length < 3 && (
                                    <IconButton component="span">
                                      <FileUploadIcon />
                                    </IconButton>
                                  )}
                                </label>
                              </Stack>
                            </Stack>

                            <Stack
                              sx={{
                                height: "50%",
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "100%",
                                  lg: "100%",
                                  xl: "100%",
                                },
                                flexDirection: "row",
                                overflowX: "auto",
                                overflowY: "hidden",
                                gap: "20px",
                                alignItems: "center",
                                background: "rgba(244, 246, 246, 1)",
                                // paddingLeft: "20px",
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              {fileUrl.map((item) => (
                                <Badge
                                  key={item.imageid}
                                  badgeContent={
                                    <IconButton
                                      size="small"
                                      sx={{
                                        color: "rgba(255, 58, 68, 1)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => removePhoto(item?.imageid)}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  }
                                  sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={item.image}
                                    alt="uploaded"
                                    sx={{
                                      width: "80px",
                                      height: "90px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </Badge>
                              ))}
                            </Stack>

                            <Stack
                              sx={{
                                width: "100%",
                                flexDirection: "row",
                                alignItems: "end",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="body2" sx={{ color: "red" }}>
                                {isPhotoError}
                              </Typography>
                              {fileUrl.length === 0 && (
                                <Typography
                                  variant="body2"
                                  sx={{ color: "red" }}
                                >
                                  Please upload at least one image.
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>

                        {/* Reason Input */}
                        <Grid item xs={12} md={12}>
                          <Divider
                            sx={{ background: "rgba(70, 144, 255, 1)" }}
                          />
                        </Grid>

                        <Stack
                          sx={{
                            width: "100%",
                            flexDirection: "row",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Label
                            style={{ color: "#4690FF", textAlign: "left" }}
                          >
                            Expenditure
                          </Label>
                        </Stack>

                        {expenditures.map((item, index) => (
                          <Stack
                            key={index}
                            sx={{ display: "flex", gap: "30px", width: "100%" }}
                          >
                            <Stack
                              sx={{
                                justifyContent: "space-between",
                                flexDirection: "row",
                                gap: "30px",
                              }}
                            >
                              <Stack
                                sx={{
                                  gap: "10px",
                                  width: "50%",
                                  textAlign: "start",
                                }}
                              >
                                <Label>Cost  <span style={{color:"red",fontWeight:"100"}}>*</span></Label>
                                <Field
                                  value={item.totalAmountSpent || ""} // Make sure value is tied to item.totalAmountSpent
                                  placeholder="Total amount spent "
                                  onChange={(e) =>
                                    handleTotalAmountSpentObject(e, index)
                                  }
                                  variant="outlined"
                                  sx={{ width: "100%" }}
                                />
                                <HelperText>
                                  {item.totalAmountSpent
                                    ? ""
                                    : "Please fill in the Total amount spent."}
                                </HelperText>
                              </Stack>
                              <Stack
                                sx={{
                                  width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "50%",
                                    lg: "50%",
                                    xl: "50%",
                                  },
                                  gap: "3px",
                                  height: "100%",
                                  textAlign: "start",
                                }}
                              >
                                <Stack
                                  sx={{
                                    width: "100%",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Label>
                                    Upload Receipt (Png, jpeg or pdf)  <span style={{color:"red",fontWeight:"100"}}>*</span>
                                  </Label>
                                  {/* {fileUrlAccessory.length < 3 && ( */}
                                  <>
                                    <label htmlFor={`img-${index}`}>
                                      <FileUploadIcon
                                        size={20}
                                        style={{
                                          color: "rgba(27, 37, 53, 1)",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      id={`img-${index}`}
                                      style={{
                                        display: "none",
                                        width: "42px",
                                        height: "42px",
                                      }}
                                      accept="image/*,.pdf"
                                      onChange={(e) =>
                                        handleFileUpload(e, index)
                                      }
                                    />
                                  </>
                                  {/* )} */}
                                </Stack>

                                <Stack
                                  sx={{
                                    height: "93px",
                                    width: {
                                      xs: "100%",
                                      sm: "100%",
                                      md: "100%",
                                      lg: "100%",
                                      xl: "100%",
                                    },
                                    flexDirection: "row",
                                    overflowX: "auto",
                                    overflowY: "hidden",
                                    gap: "20px",
                                    alignItems: "center",
                                    background: "rgba(244, 246, 246, 1)",
                                    // paddingLeft: "20px",
                                  }}
                                >
                                  {fileUrlAccessory[index]?.map(
                                    (file, fileIndex) => (
                                      <Badge
                                        key={file.id} // Ensure this is unique
                                        badgeContent={
                                          <CloseIcon
                                            size={15}
                                            style={{
                                              color: "rgba(255, 58, 68, 1)",
                                            }}
                                            onClick={() =>
                                              handleDeleteAccessory(
                                                file.id,
                                                index,
                                                fileIndex
                                              )
                                            }
                                          />
                                        }
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: "10px",
                                          padding: "5px",
                                        }}
                                      >
                                        {file.type.startsWith("image") ? (
                                          <Box
                                            as="img"
                                            src={file.url}
                                            alt="uploaded-file"
                                            sx={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        ) : (
                                          <Box
                                            sx={{
                                              width: "50px",
                                              height: "50px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              background: "gray",
                                            }}
                                          >
                                            <span>PDF</span>
                                          </Box>
                                        )}
                                      </Badge>
                                    )
                                  )}
                                </Stack>
                                {(!fileUrlAccessory[index] ||
                                  fileUrlAccessory[index].length === 0) && (
                                  <HelperText sx={{ color: "red" }}>
                                    Please upload an image
                                  </HelperText>
                                )}

                                {isAccessoryPhotoError && (
                                  <HelperText sx={{ color: "red" }}>
                                    {isAccessoryPhotoError}
                                  </HelperText>
                                )}
                              </Stack>
                            </Stack>

                            <Stack sx={{ marginBottom: "10px" }}>
                              <Divider />
                            </Stack>
                          </Stack>
                        ))}
                      </Grid>
                      <Stack
                        sx={{
                          width: "100%",
                          justifyContent: "end",
                          display: "flex",
                          alignItems: "end",
                        }}
                      >
                        
                        <Stack sx={{ width: "max-content" }}>
                        {userAccess.role !== "Guest" && (

                          <Button
                            variant="contained"
                            disabled={isSubmitDisabled}
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        )}
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default CloseGenerateTicket;
