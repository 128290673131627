import { Pagination } from "@mui/material";
import React, { useRef } from "react";
// import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import nocheckin from "../images/No Checkin.png";
import {
  IconButton,
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  Modal,
} from "@mui/material";
import { useState } from "react";
import { DownloadPDF, checkIn, checkInOut } from "../redux/data/action";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

import AddVendor from "./AddVendor";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const CheckInPage = () => {
  const checkInOutData = useSelector((store) => store.data.checkInOutData);
  const checkInData = useSelector((store) => store.data.checkInData);
  const navigate = useNavigate();
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [checkId, setCheckId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [assetcheckin, setassetcheckin] = useState(false);
  const [showMovementBox, setShowMovementBox] = useState(false);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(checkInOut(data, token));
  }, [dispatch, checkInData]);

  useEffect(() => {
    if (checkInOutData && checkInOutData?.length > 0) {
      setData(checkInOutData);
    }
  }, [checkInOutData]);
  // console.log("checkInOutData", checkInOutData);

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };


  const handleOpenassetcheckin = (item) => {
    setassetcheckin(true);
    setCheckId(item);
  };

  const handleSubmit = (checkId) => {
    let data = {
      hospid: ids.hospid,

      checkid: checkId.checkid,
      assetid: checkId?.assetid,
      userid: ids.userid,
      username: ids.username,
      userrole: userAccess.role,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(checkIn(data));
    setassetcheckin(false);
  };

  const isGuest = userAccess && userAccess.role === "Guest";

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <>
      <Stack sx={{ padding: "10px", marginTop: "-15px" }}>
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Check in.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          <Button
            onClick={() => navigate("/check-out")}
            sx={{
              bgcolor: "#1B2535",
              width: "auto",
              height: "27px",
              textDecoration: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#1B2535",
                color: "white",
              },
              borderRadius: "20px",
              display: { xs: "flex", lg: "inline-flex", marginBottom: "5px" },
            }}
          >
            check out <AddIcon style={{ color: "white", fontSize: "20px" }} />
          </Button>
        </Stack>
        <Box display="flex" justifyContent="end" marginBottom="10px">
          <Button
            onClick={() => navigate("/check-out")}
            variant="contained"
            sx={{
              display: { xs: "none", sm: "none", lg: "flex" },
              borderRadius: "22px",
              background: "rgba(70, 144, 255, 1)",
              marginRight: "20px",
              width: "170px",
            }}
          >
            Add Checkout <AddIcon />
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          gap="10px"
          height="85vh"
          width={"100%"}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            style={{ width: "100%" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="stretch"
              gap="10px"
            >
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  display: { xs: "none", sm: "none", lg: "flex" },
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                }}
              >
                <Typography>{`Total : ${data?.length}`}</Typography>
              </Stack>
              <Box flex="2">
                <SortSearchFilter
                  data={data}
                  setData={setData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  heading={"My Orders"}
                  Filter1="status"
                  Filter2=""
                  sortPrice=""
                  sortDate="checkindate"
                  dueDate=""
                  Grade=""
                  name="assetname"
                />
              </Box>
            </Box>
            <Stack
              style={{
                width: "100%",
                height: "100%",

                bgcolor: "white",
              }}
              overflow="auto"
              flexDirection={"column"}
            >
              <TableContainer
                component={Paper}
                style={{
                  height: "100%",

                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  width: "100%",
                }}
                ref={tableRef}
              >
                {currentData.length > 0 ? (
                  <Table stickyHeader>
                    {/* Table Header */}
                    <TableHead>
                      <TableRow>
                        <TableHeading> Asset Name</TableHeading>
                        <TableHeading> Asset Code</TableHeading>
                        <TableHeading>Department</TableHeading>
                        <TableHeading> Checked Out</TableHeading>

                        <TableHeading> Checked In</TableHeading>
                        <TableHeading> User</TableHeading>
                        <TableHeading>Status</TableHeading>
                        <TableHeading>Pdf</TableHeading>
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ overflowY: "scroll" }}>
                      {currentData.map((item, index) => (
                        <TableRow>
                          <TableCells
                            sx={{
                              textOverflow: "clip",
                              whiteSpace: "normal",
                              textDecoration: "none",
                            }}
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}
                          >
                            <Tooltip title={item.assetname}>
                              {item.assetname}
                            </Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}
                          >
                            <Tooltip title={item.assetcode}>
                              {item.assetcode}
                            </Tooltip>
                          </TableCells>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}
                          >
                            <Tooltip title={item.depart}>{item.depart}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={Link}
                            to={`/checkin-checkout-details/${item.checkid}`}
                          >
                            {item.checkoutdate}
                            <br />
                            {item.checkouttime}
                          </TableCells>

                          {item.checkindate !== item?.checkintime ? (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                              component={Link}
                              to={`/checkin-checkout-details/${item.checkid}`}
                            >
                              <></>
                              {item.checkindate} <br /> @ {item.checkintime}
                            </TableCells>
                          ) : (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                              component={Link}
                              to={`/checkin-checkout-details/${item.checkid}`}
                            >
                              NA
                            </TableCells>
                          )}
                          {item.checkin_username === "NA" ? (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                              style={{ whiteSpace: "nowrap" }}
                              component={Link}
                              to={`/checkin-checkout-details/${item.checkid}`}
                            >
                              {" "}
                              {item.checkout_username}
                            </TableCells>
                          ) : (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                              component={Link}
                              to={`/checkin-checkout-details/${item.checkid}`}
                            >
                              {item.checkin_username}
                            </TableCells>
                          )}

                          {item.status === "check_out" ? (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                                textAlign: "start",
                              }}
                            >
                               {userAccess.role !== "Guest" &&
                              <Box
                                onClick={() => !isGuest && handleOpenassetcheckin(item)}
                                sx={{
                                  borderRadius: "30px",
                                  margin: "auto",
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  height: "30px",
                                  padding: "4px",

                                  width: {
                                    xs: "85px",
                                    sm: "65px",
                                    md: "70px",
                                    lg: "100px",
                                  },
                                  bgcolor: "rgba(255, 107, 0, 1)",

                                  border: "1px solid rgba(255, 107, 0, 1)",

                                  color: "white",
                                }}
                              >
                                <Typography variant="caption" display="block">
                                  Check in
                                </Typography>
                              </Box>
}
                            </TableCells>
                          ) : (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                                textAlign: "center",
                              }}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  borderRadius: "30px",
                                  margin: "auto",
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",

                                  height: "30px",
                                  width: "100px",

                                  color: " rgba(70, 144, 255, 1)",
                                }}
                              >
                                <Typography variant="caption" display="block">
                                  Completed
                                </Typography>
                              </Box>
                            </TableCells>
                          )}
                          {item.status === "check_out" ? (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Tooltip
                                title="Check in asset to get pdf"
                                placement="top"
                                sx={{}}
                              >
                                <IconButton></IconButton>
                              </Tooltip>
                            </TableCells>
                          ) : (
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <IconButton>
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.report_url,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  style={{
                                    fontSize: "20px",
                                    color: " #FF731D",
                                  }}
                                />
                              </IconButton>
                            </TableCells>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Stack sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>
                  <img src={nocheckin} style={{height:"50%", width: window.innerWidth <= 320 ? "90%" : "25%",}}></img>
                  <Typography
                   sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                   }}
                 >
                    No check in data found.
                    </Typography>
                  </Stack>
                )}
              </TableContainer>

              {!showMovementBox && (
                <Stack
                  sx={{
                    padding: "20px",
                    bgcolor: "white",
                    gap: "20px",
                  }}
                >
                  <Divider />
                  <Stack
                    sx={{
                      bgcolor: "white",
                      display: {
                        xs: "grid",
                        sm: "grid",
                        md: "flex",
                        lg: "flex",
                      },
                      gap: "10px",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Stack sx={{ flexDirection: "row" }}>
                      <Button
                        onClick={() => navigate("/vendor")}
                        variant="contained"
                        sx={{
                          borderRadius: "22px",
                          background: "rgba(70, 144, 255, 1)",
                          marginRight: "20px",
                          width: "max-content",
                        }}
                      >
                        Suppliers
                      </Button>
                    
                    </Stack>
                    <Pagination
                      count={Math.ceil(filteredData.length / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      // sx={{
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   marginTop: "20px",
                      // }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
          {showMovementBox && (
            <AddVendor handleCloseDepart={handleCloseDepart} />
          )}
        </Box>
      </Stack>

      <Modal open={assetcheckin} onClose={() => setassetcheckin(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirmation
            </Typography>
            <CloseIcon
              onClick={() => setassetcheckin(false)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to Check In this Asset?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setassetcheckin(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(checkId)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CheckInPage;
