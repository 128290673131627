import styled from "@emotion/styled";
import { useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormHelperText,
  IconButton,
  Input,
  Modal,
  Stack,
  TableFooter,
  TableHead,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Autocomplete,
  Grid,
  Paper,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ShareIcon from "@mui/icons-material/Share";
import VajraLogo from "../images/Vajra_Logo_Design.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { FaShareAlt, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import PersonIcon from "@mui/icons-material/Person"; // User icon
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EastIcon from "@mui/icons-material/East";
import pdfImage from "../images/pdfImage.png";
import jsPDF from "jspdf";
import React, { useEffect, useState, lazy } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import comingSoon from "../images/comingSoon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment/moment";
import CheckIcon from "@mui/icons-material/Check";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  WhatsappShareButton,
  EmailShareButton,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // You can change the icon

import { useLocation } from "react-router-dom";

import {
  DownloadPDF,
  DownloadPDFHistory,
  addAccesoryUpdateHistory,
  addAccesoryUploadImageHistory,
  assetDetailsData,
  deleteAsset,
  deleteImageAddAccessoryHistory,
  fetchDataFromAPI,
  assetsDelete,
  discardrequest,
  gradeUpdate,
  accessoryAvailableList,
} from "../redux/data/action";

import UpdateReport from "./UpdateReport";
import ViewImageModal from "../components/ViewImageModal";
import { Icon } from "@react-pdf-viewer/core";
import CloseIconNew from "@mui/icons-material/Close";
import { useAddAccessory } from "../hooks/useAddAccesory";
import { v4 as uuid } from "uuid";
import {
  addAccesoryUploadImage,
  deleteImageAddAccessory,
  addAccesoryUpdate,
} from "../redux/data/action";
import ChatBoxGPT from "../components/ChatBotGPT";
import Socialshare from "../components/Socialshare";
import GradeIcon from "@mui/icons-material/Grade";
const InnerImageBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  justifyContent: "space-evenly",
  margin: "10px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "230px",
  height: "230px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "230px",
    height: "230px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "300px",
}));

const InnerWarrantyImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'6px solid red',

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerWarrantyImageBox2 = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'6px solid red',

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "380px",
    // height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "0",
}));

const WarrantyInnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //    border:'2px solid black',
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "159.84px",
  height: "155px",
}));

const WarrantyImageCard = styled(Box)(({ theme }) => ({
  width: "159px",
  height: "145px",
  //  border:"3px solid black"
}));

const QrBox = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TextBox = styled(Box)(({ theme }) => ({
  margin: "5px",
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const SplitCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: "2",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",

  gap: "15px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  background: "white",
  height: "50px",
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  background: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidTop = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",

  background: "white",
  // height:'200px',
  padding: "10px",
  gap: "15px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidTopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  background: "white",
  height: "auto",
  padding: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidBottom = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "10% 22% 22% 22% 22%",
  alignItems: "center",

  background: "white",
  // height:'50px',
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "20px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MiniQRset = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function AssetDetailPage() {
  const updateReportResponse = useSelector((store) => store.data.updateReports);
  const avilablelistdata = useSelector(
    (store) => store.data.accessoryavilablelistdata
  );

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });

  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtime;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${
          totalDuration.months() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenQr = () => {
    setOpen(true);
  };

  const handleCloseQr = () => {
    setOpen(false);
  };

  const [image, setImage] = useState([]);
  const [status, setStatus] = useState(false);
  const [reportImage, setReportImage] = useState([]);
  const [reportImageName, setReportImageName] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [zoomImageUrl, setZoomImageUrl] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [hidden, setHidden] = React.useState(false);
  const [certificateImage, setCertificateImage] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const params = useParams();
  const dispatch = useDispatch();
  const detailData = useSelector((store) => store.data.assetDetails);

  const messagesEndRef = useRef(null);

  const [openImage, setOpenImage] = useState(false);

  const handleImageOpen = (url) => {
    setZoomImageUrl(url);
    setOpenImage(true);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleSubmitAndClose = (index) => {
    handleSubmit();
    handleRemoveExpenditure(index);
    setIsFormOpen(false);
  };

  const [accessoriesAdded, setAccessoriesAdded] = useState(false);
  const [accessorieshistoryAdded, setAccessorieshistoryAdded] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const text = "Asset";
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handlePrevious = () => {
    setCount((prevCount) => {
      if (prevCount === 0) {
        return image?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const [openDropdown, setOpenDropdown] = useState(false);
  const [product, setProduct] = useState(null);
  const [Quantity, setQuantitys] = useState(null);

  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [quantities, setQuantities] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);

  // Populate product dropdown
  useEffect(() => {
    if (avilablelistdata) {
      const productOptions = avilablelistdata.map((item) => ({
        label: item.accessname,
        value: item.accessid,
        prices: item.availprices, // Store prices for later filtering
      }));
      setProducts(productOptions);
    }
  }, [avilablelistdata]);

  // Handle product selection
  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
    setSelectedPrice(null);
    setSelectedQuantity(null);

    if (newValue && newValue.prices) {
      const priceOptions = newValue.prices.map((priceItem) => ({
        label: `${priceItem.priceid}`,
        value: priceItem.priceid,
        available: priceItem.available, // Store quantity info
      }));
      setPrices(priceOptions);
    } else {
      setPrices([]);
    }
  };

  // Handle price selection
  const handlePriceChange = (event, newValue) => {
    setSelectedPrice(newValue);
    setSelectedQuantity(null); // Reset quantity when price changes

    if (newValue && newValue.available !== undefined) {
      const quantityOptions = Array.from(
        { length: newValue.available },
        (_, i) => ({
          label: `${i + 1}`,
          value: i + 1,
        })
      );
      setQuantities(quantityOptions);
    } else {
      setQuantities([]);
    }
  };

  const handleQuantityChange = (event, newValue) => {
    setSelectedQuantity(newValue);
  };

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(false);
  };

  const handleNext = () => {
    setCount((prevCount) => {
      if (prevCount === image?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleReportPrevious = () => {
    setCount3((prevCount) => {
      if (prevCount === 0) {
        return reportImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleReportNext = () => {
    setCount3((prevCount) => {
      if (prevCount === reportImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handlePreviousCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === 0) {
        return certificateImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNextCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === certificateImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  // const handleDelete = () => {
  //   let data = {
  //     hospid: userIds.hospid,
  //     userid: userIds.userid,
  //     assetid: params.id,
  //   };

  //   dispatch(deleteAsset(data, token));
  //   navigate(-1);
  // };

  const handlediscard = () => {
    const payload = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      depart_id: userAccess.departmentids,
      assetid: params.id,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      username: userIds.username,
      asset_grade: detailData.asset_grade,
    };

    dispatch(discardrequest(payload))
      .then(() => {
        let data = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          assetid: params.id,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
        dispatch(assetDetailsData(data, token));
      })
      .catch((error) => {
        console.error("Error in discard request:", error);
      });

    handleDiscardConfirm();
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(accessoryAvailableList(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch, updateReportResponse]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };

  const handleDownloadHistory = (fileUrl, title) => {
    dispatch(DownloadPDFHistory(fileUrl, title));
  };

  useEffect(() => {
    setImage("");
    setCertificateImage("");
    setReportImage("");
    setReportImageName("");
  }, []);

  useEffect(() => {
    if (detailData?.imgs) {
      setImage(detailData?.imgs);
      setCertificateImage(detailData?.wimg);
    }
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }
    if (detailData?.reportnames) {
      setReportImageName(detailData?.reportnames);
    }
    // setReportImageName
  }, [detailData]);

  const handleClick = () => {
    localStorage.setItem("incidentId", detailData?.incidid);
  };

  const handleDownloadPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";

      const imageX = (pdfWidth - 30) / 2;

      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);

      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;

      pdf.save(`${name}.pdf`);
    };

    img.src = pdfData;
  };

  const handlePrintPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";
      const imageX = (pdfWidth - 30) / 2;
      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);
      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;
      pdf.text(name, pdfWidth / 2, nameY, { align: "center" });
      pdf.text(code, pdfWidth / 2, codeY, { align: "center" });

      const pdfBlob = pdf.output("blob");

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const newWindow = window.open(pdfUrl);

      newWindow.onload = function () {
        newWindow.print();

        URL.revokeObjectURL(pdfUrl);
      };
    };

    img.src = pdfData;
  };

  const handleDownloadPDF2 = (
    logoUrl,
    pdfData,
    name,
    code,
    brand,
    calibdate,
    calibduedate,
    calibmnth,
    calibreq,
    calibstatus,
    cmcamc,
    cmcamcdue,
    cmcamcstart,
    dept,
    dop,
    imgs,
    wimg,
    serialno,
    incidstatus,
    lifeyrs,
    modelno,
    price,
    servstatus,
    soldby,
    status
  ) => {
    if (!pdfData || !logoUrl) return;

    const pdf = new jsPDF();
    const img = new Image();
    img.src = logoUrl;
    img.onload = function () {
      const desiredHeight = 30;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const lineWidth = 0.9 * pageWidth;
      pdf.setFontSize(22);
      pdf.setTextColor("#FF731D");
      const xPosition = (pageWidth - lineWidth) / 2;
      const yPosition = 10;
      const logoYPosition = 10;

      let yTop = yPosition + 7;
      const logoLineY = yPosition + 22; // Adjust this value to move the line above the table
      pdf.setLineWidth(1);
      pdf.setDrawColor("1b76bc");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.hospname, lineWidth / 2.3, yTop);

      pdf.setFontSize(10);
      pdf.setTextColor("#1746A2");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.city, lineWidth / 2, yTop + 7);
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const parameterData = [
        ["Name", name],
        ["Code", code],
        ["Brand", brand],
        ["Department", dept],
        ["Status", status],
        ["Calibration Date", calibdate],
        ["Calibration Status", calibstatus],
        ["Useful Life", lifeyrs],
        ["Model No", modelno],
        ["AMC/CMC", cmcamc],
        ["Purchase Date", dop],
        ["Price", price],
        ["Sold by", soldby],
        ["Incident Status", incidstatus],
        ["Service Status", servstatus],
        ["CMC/AMC Start", cmcamcstart],
        ["CMC/AMC Due", cmcamcdue],
        ["Calibration Month", calibmnth],
        ["Calibration End Date", calibduedate],
        ["Calibration Request", calibreq],
      ];

      pdf.autoTable({
        body: parameterData,
        startY: 40,
        headStyles: {
          fillColor: [26, 188, 156],
          textColor: [255, 255, 255],
        },
      });

      const contentWidth = pdf.internal.pageSize.getWidth();
      const qrCodeX = contentWidth - 70;
      const qrCodeY = pdf.autoTable.previous.finalY + 10; // Position it below the table

      const assetImageX = contentWidth - 70;
      const assetImageY = qrCodeY + 40;

      pdf.addImage(pdfData, "JPEG", qrCodeX, qrCodeY, 25, 25);
      pdf.setTextColor("1b76bc");
      pdf.text("QR Code", qrCodeX, qrCodeY);
      let imageX = assetImageX;
      let imageY = assetImageY + 5;
      const imageWidth = 18;
      const imageHeight = 18;

      pdf.setFontSize(14);
      pdf.setLineWidth(0.5);
      pdf.setTextColor("1b76bc");
      pdf.text("Asset Image", assetImageX, assetImageY + 2);

      for (let i = 0; i < imgs.length; i++) {
        const { imgurl } = imgs[i];
        pdf.setDrawColor("1b76bc");
        pdf.setLineWidth(0.5);

        pdf.rect(imageX, imageY, imageWidth, imageHeight);
        pdf.addImage(imgurl, "JPEG", imageX, imageY, imageWidth, imageHeight);

        imageX += imageWidth + 3;
        if (imageX > contentWidth - imageWidth - 3) {
          imageX = assetImageX;
          imageY += imageHeight + 3;
        }
      }

      if (wimg !== "NA" && wimg.length > 0) {
        const warrantyImageWidth = 18;
        const warrantyImageHeight = 18;
        let warrantyImageX = 20;
        let warrantyImageY = pdf.autoTable.previous.finalY + 10;

        const warrantyImageHeadingX = 20;
        const warrantyImageHeadingY = pdf.autoTable.previous.finalY + 15;

        pdf.setFontSize(14);
        pdf.setLineWidth(0.5);
        pdf.setTextColor("1b76bc");
        pdf.text(
          "Warranty Images",
          warrantyImageHeadingX,
          warrantyImageHeadingY - 7
        );

        for (let i = 0; i < wimg.length; i++) {
          const { imgurl } = wimg[i];

          // Check if imgurl is a PDF
          if (imgurl.toLowerCase().includes(".pdf")) {
            // Handle PDF images if needed
          } else {
            pdf.setDrawColor("1b76bc");
            pdf.setLineWidth(0.5);
            pdf.rect(
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );

            pdf.addImage(
              imgurl,
              "JPEG",
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );
            warrantyImageY += warrantyImageHeight + 3;
          }
        }
      }

      pdf.save(`${name}.pdf`);
    };

    // Set the source of the image to the logo data
    img.src = logoUrl;
  };

  const AssetDetail = {
    Code: detailData.code,
    "Serial No.": detailData.serialno,
    Department: detailData.dept,
    Status: detailData.status,
    Note: detailData.notes,
  };
  const Specifications = {
    Type: detailData.type,
    "Model Number": detailData.modelno,
    "Useful life ": detailData.lifeyrs || "",
    "Warranty Expiry":
      detailData.wrntexpdate === "Invalid date" ? "" : detailData.wrntexpdate,
    "AMC/CMC": detailData.cmcamc,
    // ...(detailData.cmcamc === "AMC" && {
    //   "AMC Start": detailData.cmcamcstart || "",
    //   "AMC Due": detailData.cmcamcdue || "",
    // }),
    // ...(detailData.cmcamc === "CMC" && {
    //   "CMC Start": detailData.cmcamcstart || "",
    //   "CMC End": detailData.cmcamcdue || "",
    // }),
    ...(detailData.cmcamc === "AMC" && {
      "AMC Start": detailData.cmcamcstart || "",
      "AMC Due": detailData.cmcamcdue || "",
      "AMC Cost": detailData.amc_cmc_cost || "",
      "AMC File": detailData.amc_cmc_file || "",
    }),

    ...(detailData.cmcamc === "CMC" && {
      "CMC Start": detailData.cmcamcstart || "",
      "CMC End": detailData.cmcamcdue || "",
      "CMC Cost": detailData.amc_cmc_cost || "",
      "CMC File": detailData.amc_cmc_file || "",
    }),
    ...(detailData.cmcamc === "None" && {
      "AMC/CMC Start": detailData.cmcamcstart || "",
      "AMC/CMC End": detailData.cmcamcdue || "",
    }),
    Calibration: detailData.calibreq === "NA" ? "Yes" : detailData.calibreq,
    "Start Date": detailData.calibdate || "",
    "End Date": detailData.calibduedate || "",
  };
  const Purchase = {
    Price: `${"₹" & detailData.price || ""}`,
    Date: detailData.dop,
    "Seller Brand ": detailData.brand || "",
    "Seller Name": detailData.soldby || "",
    // Representative: detailData.soldby,
    "Seller Contact": detailData.sellermobile || "",
    "Seller Email ID ": detailData.selleremail || "",
  };

  const handleOpenService = () => {
    setStatus(true);
  };

  const handleCloseService = () => {
    setStatus(false);
  };

  const handleNavigate = (requested, assetId) => {
    navigate(
      `/service-request/${assetId}?requested=${
        requested === "Medpick" ? "Vajra" : requested
      }`
    );
  };

  const downloadImage = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        // setImageSrc(url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloaded_image.png");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the image:", error));
  };

  const printImage = (imageSrc) => {
    if (imageSrc) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<img src="${imageSrc}" onload="window.print();window.close()" />`
      );
      printWindow.document.close();
    } else {
      // console.log("Image not downloaded yet.");
    }
  };

  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState("");
  const [description, setDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isQuantityError, setIsQuantityError] = useState("");
  const fileInputRef = useRef(null);
  const historyFileInputRef = useRef(null);

  const handleOpenModal2 = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal2 = () => {
    setIsModalOpen(false);
  };

  const handleDeleteAccessory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    // dispatch(clearServiceImgDel(data));
    // setFileUrlAccessory(fileUrl?.filter((el) => el?.imageid !== id));
  };

  const handleDescription = (e) => {
    const value = e.target.value.trim();
    const regex = /^[a-zA-Z0-9.,'"\s-]*$/;
    if (value === "") {
      setDescription(value);
      setIsDescriptionError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setDescription(value);
      setIsDescriptionError(null);
    } else {
      setIsDescriptionError("Enter a valid description");
    }
  };

  const handleQuantity = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setQuantity(value);
      setIsQuantityError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setQuantity(value);
      setIsQuantityError(null);
    } else {
      setIsQuantityError("Enter a valid quantity");
    }
  };

  const handleQuantityHistory = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setQuantityHistory(value);
      setIsQuantityErrorHistory("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setQuantityHistory(value);
      setIsQuantityErrorHistory(null);
    } else {
      setIsQuantityErrorHistory("Enter a valid quantity");
    }
  };

  const handleTotalAmountSpent = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError(null);
    } else {
      setIsTotalAmountSpentError("Enter a valid amount");
    }
  };

  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    description: "",
    totalAmountSpent: "",
    quanity: "",
    expImage: [{ image: "", imageId: "" }],
  });

  const handleAddExpenditure = () => {
    setExpenditures([...expenditures, { ...newExpenditure }]);
    setNewExpenditure({
      description: "",
      totalAmountSpent: "",
      quanity: "",
      expImage: [{ image: "", imageId: "" }],
    });
    setAssetName("");
    setPrice("");
    setQuantity("");
    setFileUrl([]);
    setIsFormOpen(true);
    setIsAddingAccessory(true);
    setAccessoriesAdded(true);
    setIsFormOpen(true);
  };

  const handleRemoveExpenditure = (index) => {
    const updatedExpenditures = expenditures.filter((_, i) => i !== index);
    setExpenditures(updatedExpenditures);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewExpenditure({ ...newExpenditure, [name]: value });
  };

  const handleTotalAmountSpentObject = (e, index) => {
    handleTotalAmountSpent(e);
    let value = e.target.value;
    let edit = [...expenditures];
    edit[index].totalAmountSpent = value;
    setExpenditures([...edit]);
  };

  const handleDescriptionObject = (e, index) => {
    handleDescription(e);
    let value = e.target.value;
    let edit = [...expenditures];
    edit[index].description = value;
    setExpenditures([...edit]);
  };

  const handleQuantityObject = (e, index) => {
    handleQuantity(e);
    let value = e.target.value;
    let edit = [...expenditures];
    edit[index].quanity = value;
    setExpenditures([...edit]);
  };

  const handleEdit = () => {
    navigate(`/edit-asset/${params.id}`);
  };

  const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);
  // console.log(assetLogo);

  const [selectedFile, setSelectedFile] = useState(null);

  const [isPhotoError, setIsPhotoError] = useState(null);
  const [fileUrl, setFileUrl] = useState([]);
  const [assetName, setAssetName] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [accessoryNames, setAccessoryNames] = useState([]);
  const [accessoryPrices, setAccessoryPrices] = useState([]);
  const [accessoryQuantities, setAccessoryQuantities] = useState([]);
  const [accessoryImages, setAccessoryImages] = useState([]);
  const [accessoryNamesHistory, setAccessoryNamesHistory] = useState([]);
  const [accessoryPricesHistory, setAccessoryPricesHistory] = useState([]);
  const [accessoryQuantitiesHistory, setAccessoryQuantitiesHistory] = useState(
    []
  );
  const [accessoryImagesHistory, setAccessoryImagesHistory] = useState([]);
  const [addHistory, setAddHistory] = useState(false);
  const [assetNameHistory, setAssetNameHistory] = useState("");
  const [priceHistory, setPriceHistory] = useState("");
  const [quantityHistory, setQuantityHistory] = useState("");
  const [isAssetErrorHistory, setIsAssetErrorHistory] = useState(null);
  const [isPriceErrorHistory, setIsPriceErrorHistory] = useState(null);
  const [isQuantityErrorHistory, setIsQuantityErrorHistory] = useState("");
  const [isPhotoErrorHistory, setIsPhotoErrorHistory] = useState(null);
  const [fileUrlHistory, setFileUrlHistory] = useState([]);
  const [isAddingAccessory, setIsAddingAccessory] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isFeedbackValid, setIsFeedbackValid] = useState(true);

  const handleFeedbackChange = (event) => {
    const value = event.target.value;
    // Replace multiple spaces with a single space
    setFeedback(value.replace(/\s{2,}/g, ' '));
  };
  

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const handleDelete = () => {
    if (!feedback.trim()) { // Trim spaces and check if it's empty
      setIsFeedbackValid(false);
      return;
    }
    dispatch(
      assetsDelete({
        hospid: userIds.hospid,
        userid: userIds.userid,
        assetid: params.id,
        username: userIds.username,
        branchid: userBranch?.id,
        departid: detailData.departid,
        departname: detailData?.dept,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
        reason: feedback.trim(),
      })
    );
    setFeedback();
    setOpen1(false);
    navigate("/all-assets");
  };
  const hasErrors =
    isAssetErrorHistory ||
    isPriceErrorHistory ||
    isQuantityErrorHistory ||
    isPhotoErrorHistory;

  const assetData = useSelector((store) => store?.data?.data);

  const assetDataHistory = useSelector((store) => store?.data?.dataHistory);
  const assetExpenditure = useSelector(
    (store) => store?.data?.accessoryHistoryExpenditure
  );

  let idSplit = params.id.substring(0, 4);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize =
        fileType === "application/pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;

      if (allowedTypes.indexOf(singlefile.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        // setIsPhotoError("Greater than 2 MB can't be uploaded");
        setIsPhotoError(
          fileType === "application/pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return;
      }

      if (
        singlefile.size > allowedSize &&
        singlefile.type.startsWith("image/")
      ) {
        compressImage(singlefile);
        return;
      } else {
        setIsPhotoError(null);

        if (singlefile.type === "application/pdf") {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            branchid: userBranch?.id,
            imageid: `${uuid()}.${fileType}`,
            fileName: singlefile.name,
            fileType: fileType,
            image: singlefile,
            userrole: userAccess?.role,
            depart_ids: userAccess.departmentids,
          };

          setFileUrl([sendData]);

          dispatch(addAccesoryUploadImage(sendData, token));
          fileInputRef.current.value = "";
        } else {
          const reader = new FileReader();

          reader.onloadend = () => {
            let sendData = {
              hospid: userIds?.hospid,
              userid: userIds?.userid,
              branchid: userBranch?.id,
              imageid: `${uuid()}.${fileType}`,
              fileName: singlefile.name,
              fileType: fileType,
              image: reader.result,
              userrole: userAccess?.role,
              depart_ids: userAccess.departmentids,
            };

            setFileUrl([sendData]);

            dispatch(addAccesoryUploadImage(sendData, token));
          };
          reader.readAsDataURL(singlefile);
        }
      }
    }
  };

  const handlePhotoUploadHistory = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type;
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize =
        fileType === "application/pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;

      if (!allowedTypes.includes(fileType)) {
        setIsPhotoErrorHistory(`Can't upload ${fileType} file.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        // setIsPhotoErrorHistory("Greater than 2 MB can't be uploaded");
        setIsPhotoErrorHistory(
          fileType === "application/pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return;
      }

      setIsPhotoErrorHistory(null);

      const reader = new FileReader();

      reader.onloadend = () => {
        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          branchid: userBranch?.id,
          imageid: `${uuid()}.${fileType.split("/")[1]}`,
          fileName: singlefile.name,
          fileType: fileType,
          image: reader.result,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };

        setFileUrlHistory([sendData]);

        dispatch(addAccesoryUploadImageHistory(sendData, token));

        historyFileInputRef.current.value = "";
      };

      reader.readAsDataURL(singlefile);
    }
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
                userrole: userAccess?.role,
                depart_ids: userAccess.departmentids,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(addAccesoryUploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const compressImageHistory = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
                userrole: userAccess?.role,
                depart_ids: userAccess.departmentids,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(addAccesoryUploadImageHistory(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      imageid: id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(deleteImageAddAccessory(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  const removePhotoHistory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      imageid: id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(deleteImageAddAccessoryHistory(data));
    setFileUrlHistory(fileUrlHistory.filter((item) => item.imageid !== id));
  };

  const handleAssetName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    setAssetName(value);
    if (value === "" || regex.test(value)) {
      setAssetName(value);
      setIsAssetError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetError("wrong asset Name");
    }
    if (value === "") {
      setIsAssetError("Please fill the field");
    }
  };

  const handleAssetNameHistory = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    setAssetNameHistory(value);
    if (value === "" || regex.test(value)) {
      setAssetNameHistory(value);
      setIsAssetErrorHistory(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetErrorHistory("wrong asset Name");
    }
    if (value === "") {
      setIsAssetErrorHistory("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  const handlePriceHistory = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]+$/; // This regex now requires at least one digit (no leading zeros)

    setPriceHistory(value);

    if (value === "") {
      setIsPriceErrorHistory("Please fill the field");
    } else if (value === "0") {
      setIsPriceErrorHistory("Price cannot be 0");
    } else if (regex.test(value)) {
      setIsPriceErrorHistory(null);
    } else {
      setIsPriceErrorHistory("Enter valid price");
    }
  };

  const areFieldsValid = () => {
    return (
      assetName.trim() !== "" &&
      price.trim() !== "" &&
      quantity.trim() !== "" &&
      fileUrl.length > 0
    );
  };

  const handleSubmit = async () => {


    if (!selectedProduct || !selectedPrice || !selectedQuantity) {
      console.error("Please select all fields before submitting.");
      return;
    }

    let data = {
      hospid: userIds.hospid,
      hospname: userIds.hospname,
      branchid: userBranch?.id,
      branchname: userBranch.branch,
      userid: userIds.userid,
      username: userIds?.username,

      accsid: selectedProduct?.value,
      accsname: selectedProduct?.label,
      price: selectedPrice?.value ? Number(selectedPrice.value) : "MISSING",
      quantity: selectedQuantity?.value
        ? Number(selectedQuantity.value)
        : "MISSING",
      assetid: params.id,
      assetname: detailData.name,
      departid: detailData.departid,
      departname: detailData?.dept,
    };

    // Dispatching the accessory update action
    dispatch(addAccesoryUpdate(data, token)).then(() => {
      let assetDetailsDataPayload = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        assetid: params.id,
        branchid: userBranch?.id,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };

      // Dispatch the asset details data action
      dispatch(assetDetailsData(assetDetailsDataPayload, token)).then(() => {
        // Dispatch the accessory available list action after asset details data is updated
        let accessoryListData = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
        dispatch(accessoryAvailableList(accessoryListData, token));
      });
    });

    // Clear all selected fields
    setSelectedProduct(null);
    setSelectedPrice(null);
    setSelectedQuantity(null);

    // Optionally, close any dropdowns or modals
    handleCloseDropdown();
  };

  const handleSubmitHistory = async () => {
    // console.log("Submit button clicked");

    if (assetNameHistory === "") {
      setIsAssetErrorHistory("Please fill the field");
      // console.log("Asset name is empty");
    }
    if (priceHistory === "") {
      setIsPriceErrorHistory("Please fill the field");
      // console.log("Price is empty");
    }
    if (quantityHistory === "") {
      setIsQuantityErrorHistory("Please fill the field");
      // console.log("Quantity is empty");
    }
    if (fileUrlHistory.length === 0) {
      setIsPhotoErrorHistory("Please add an image");
      // console.log("No file uploaded");
    }

    if (
      assetNameHistory === "" ||
      priceHistory === "" ||
      quantityHistory === "" ||
      fileUrlHistory.length === 0
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      // console.log("Mandatory fields are missing");
      return;
    }

    // console.log("All validations passed");

    const assetIndex = accessoryNamesHistory.indexOf(assetNameHistory.trim());

    let newAccessoryName = assetNameHistory.trim();
    let newAccessoryPrice = Number(priceHistory.trim());
    let newAccessoryQuantity = Number(quantityHistory.trim());
    let newAccessoryImage =
      fileUrlHistory.length > 0 ? fileUrlHistory[0].imageid : "";

    if (assetIndex !== -1) {
      accessoryNamesHistory[assetIndex] = newAccessoryName;
      accessoryPricesHistory[assetIndex] = newAccessoryPrice;
      accessoryQuantitiesHistory[assetIndex] = newAccessoryQuantity;
      accessoryImagesHistory[assetIndex] = newAccessoryImage;
    } else {
      setAccessoryNamesHistory([...accessoryNamesHistory, newAccessoryName]);
      setAccessoryPricesHistory([...accessoryPricesHistory, newAccessoryPrice]);
      setAccessoryQuantitiesHistory([
        ...accessoryQuantitiesHistory,
        newAccessoryQuantity,
      ]);
      setAccessoryImagesHistory([...accessoryImagesHistory, newAccessoryImage]);
    }

    let data = {
      assetid: params.id,
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      accessoryname: newAccessoryName,
      accessoryprice: newAccessoryPrice,
      accessoryquantity: newAccessoryQuantity,
      accessoryimage_id: newAccessoryImage,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    try {
      await dispatch(addAccesoryUpdateHistory(data, token));
      // console.log("API call successful");

      setAssetNameHistory("");
      setPriceHistory("");
      setQuantityHistory("");
      setFileUrlHistory([]);

      const fetchDataRequestBody = {
        assetid: params.id,
        hospid: userIds.hospid,
        userid: userIds.userid,
        branchid: userBranch?.id,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };

      await dispatch(fetchDataFromAPI(fetchDataRequestBody, token));
      setAddHistory(false);
      // console.log("Fetched updated data from API");
    } catch (error) {
      // console.error("Failed to submit or fetch data:", error);
    }
  };

  useEffect(() => {
    const requestBody = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      assetid: params.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(fetchDataFromAPI(requestBody, token));
  }, [dispatch]);

  const downTimeData = useSelector((store) => store?.data?.downTime);
  useEffect(() => {
    setDowntime({
      tot_years: downTimeData?.year,
      tot_months: downTimeData?.month,
      tot_days: downTimeData?.day,
      tot_hrs: downTimeData?.hour,
      tot_mins: downTimeData?.min,
      tot_secs: downTimeData?.sec,
    });
  }, [downTimeData]);

  // console.log(downTimeData);

  const location = useLocation();
  const { fromScanner, scannedAssetId } = location.state || {};
  const assetList = useSelector((store) => store?.data?.allAssets) || [];
  const assetIds = assetList.map((asset) => asset.assetid);

  const isAssetAvailable = assetIds.includes(scannedAssetId);

  if (fromScanner && !detailData?.name) {
    navigate("/scan", { state: { fromScan: true } });
    return null;
  }

  const isGuest = userAccess && userAccess.role === "Guest";

  const [showIcons, setShowIcons] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  const generatePDF = () => {
    const doc = new jsPDF();

    const labelX = 20;
    const labelY = 80;
    const dataX = 110;
    let yPosition = 30;
    const pageHeight = doc.internal.pageSize.height;

    const hasEnoughSpace = (heightNeeded) => {
      return yPosition + heightNeeded < pageHeight;
    };

    // Footer function
    const addFooter = () => {
      const footerY = doc.internal.pageSize.height - 7; // Position from bottom
      const textX = doc.internal.pageSize.width - 53; // Right-aligned text
      doc.setTextColor(100, 100, 100);
      doc.setFontSize(10);
      doc.text("Powered by :", textX, footerY, "left");

      if (VajraLogo) {
        const imgWidth = 20;
        const imgHeight = 12;
        const imgX = doc.internal.pageSize.width - 30;
        const imgY = footerY - 7;
        doc.addImage(VajraLogo, "PNG", imgX, imgY, imgWidth, imgHeight);
      }
    };

    // Automatically add footer to every new page
    doc.internal.events.subscribe("addPage", () => {
      addFooter();
    });

    const addHeader = () => {
      if (assetLogo) {
        doc.addImage(
          assetLogo,
          "JPEG",
          doc.internal.pageSize.width - 60,
          10,
          50,
          25
        );
      }
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFont("Helvetica", "bold");
      doc.text("Hospital Name:", labelX, 20);
      doc.setFont("Helvetica", "normal");
      doc.text(userIds.hospname || "Default Hospital", labelX + 45, 20);

      doc.setFont("Helvetica", "bold");
      doc.text("Branch:", labelX, 30);
      doc.setFont("Helvetica", "normal");
      doc.text(userBranch.branch || "Main Branch", labelX + 45, 30);
    };

    addHeader();
    yPosition = 50;

    doc.setFontSize(18);
    doc.setTextColor(255, 165, 0);
    doc.text(
      "Asset Details",
      doc.internal.pageSize.width / 2,
      yPosition,
      null,
      null,
      "center"
    );

    yPosition += 20;
    doc.setFontSize(12);

    const addText = (label, value, heightNeeded) => {
      if (!hasEnoughSpace(heightNeeded)) {
        doc.addPage();
        yPosition = 20;
      }

      doc.setTextColor(0, 0, 255);
      doc.text(label, labelX, yPosition);
      doc.setTextColor(0, 0, 0);
      doc.text(":", labelY, yPosition);
      doc.text(
        value && value !== "Invalid date" ? value : "-",
        dataX,
        yPosition
      );
      yPosition += heightNeeded;
    };

    [
      ["Asset Name", detailData?.name],
      ["Asset Code", detailData?.code],
      ["Serial No", detailData?.serialno],
      ["Department", detailData?.dept],
      ["Status", detailData?.status],
      ["Brand", detailData?.brand],
      ["Type", detailData?.type],
      ["Model Number", detailData?.modelno],
      ["Useful life", detailData?.lifeyrs],
      ["Warranty Expiry", detailData?.wrntexpdate],
      ["AMC/CMC", detailData?.cmcamc],
      ["AMC/CMC Start", detailData?.cmcamcstart],
      ["AMC/CMC End", detailData?.cmcamcdue],
      ["Calibration", detailData?.calibreq],
      ["Start Date", detailData?.calibdate],
      ["End Date", detailData?.calibduedate],
      ["Price", detailData?.price],
      ["Date", detailData?.dop],
      ["Seller Brand", detailData?.brand],
      ["Seller Name", detailData?.soldby],
      ["Seller Contact", detailData?.sellermobile],
      ["Seller Email ID", detailData?.selleremail],
    ].forEach(([label, value]) => addText(label, value, 10));

    addText("Asset Image", " ", 10);

    if (detailData?.imgs && Array.isArray(detailData.imgs)) {
      detailData.imgs.forEach((imgObj, index) => {
        const imgUrl = imgObj?.imgurl;
        if (typeof imgUrl === "string") {
          if (!hasEnoughSpace(50)) {
            doc.addPage();
            yPosition = 20;
          }
          doc.addImage(imgUrl, "JPEG", labelX, yPosition, 50, 50);
          yPosition += 60;
        } else {
          console.error(`Invalid image URL at index ${index}:`, imgObj);
        }
      });
    }

    addText("Asset QR Code", " ", 10);

    if (detailData?.qrimg && typeof detailData.qrimg === "string") {
      if (!hasEnoughSpace(50)) {
        doc.addPage();
        yPosition = 20;
      }
      doc.addImage(detailData.qrimg, "JPEG", labelX, yPosition, 50, 50);
      yPosition += 60;
    }

    addFooter(); // Ensure footer is added to the first page

    doc.save("Asset_Details.pdf");

    const pdfBlob = doc.output("blob");
    setPdfBlob(pdfBlob);
  };

  const shareViaEmail = () => {
    if (!pdfBlob) return;

    // Create a Blob URL for the PDF file
    const fileURL = URL.createObjectURL(pdfBlob);

    // Email details
    const recipient = "recipient@example.com";
    const subject = "Asset Details PDF";
    const body = `Please find the asset details here: ${fileURL}`;

    // Create a mailto link
    window.location.href = `mailto:${encodeURIComponent(
      recipient
    )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const shareViaWhatsApp = () => {
    if (!pdfBlob) return;

    const fileURL = URL.createObjectURL(pdfBlob);
    const whatsappMessage = `Check out this PDF: ${fileURL}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    window.open(whatsappURL, "_blank");
  };

  const handleSystemShare = () => {
    if (!pdfBlob) {
      return;
    }

    if (navigator.share) {
      const fileName = "Asset_Details.pdf";

      navigator
        .share({
          title: "Share PDF",
          text: "Please find the attached PDF with asset details.",
          files: [new File([pdfBlob], fileName, { type: "application/pdf" })],
        })
        .then(() => {
          // console.log("Sharing successful");
        })
        .catch((error) => {
          // console.error("Error while sharing:", error);
        });
    } else {
      alert("System share is not supported on this device.");
    }
  };

  // console.log("QRimage", detailData?.qrimg);

  // console.log("ASsetimage", detailData?.imgs);

  const handleDownloadClick = () => {
    generatePDF();
    setShowOptions(true);
  };

  const isCheckedOut = assetData && assetData.check_in_status === "checked_out";

  const isDisabled =
    detailData.status === "Discard" &&
    userAccess &&
    (userAccess.role === "Guest" ||
      userAccess.role === "Branch_Admin" ||
      userAccess.role === "Admin");

  const [opendiscard, setOpendiscard] = useState(false);
  const [accessories, setAccessories] = useState([]);

  const handleOpenModal1 = () => {
    setOpendiscard(true);
  };

  const handleCloseModal1 = () => {
    setOpendiscard(false);
  };

  const handleDiscardConfirm = () => {
    setOpendiscard(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(
    detailData.asset_grade ? [detailData.asset_grade] : []
  );

  const options = ["A", "B", "C", "D"];
  const tooltipTexts = {
    A: "A. Refers to Very Critical",
    B: "B. Refers to Critical",
    C: "C. Refers to Important",
    D: "D. Refers to Others",
  };

  useEffect(() => {
    if (detailData.asset_grade) {
      setSelected([detailData.asset_grade]); // Set old grade when detailData is available
    }
  }, [detailData.asset_grade]);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleToggle = (option) => {
    setSelected([option]); // Ensure only one selection
  };

  const handlegradeupdate = () => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      username: userIds.username,
      grade: selected.join(","), // Convert to string if needed
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(gradeUpdate(data)).then(() => {
      let data = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        assetid: params.id,
        branchid: userBranch?.id,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };
      dispatch(assetDetailsData(data, token));
    });
    handleClose1(); // Close the menu after update
  };

  const images = ["image1.jpg", "image2.jpg", "image3.jpg", "image4.jpg"];

  const [openrecentimage, setOpenRecentImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenRecentImage = () => setOpenRecentImage(true);
  const handleCloseRecentImage = () => setOpenRecentImage(false);

  const handleNextimage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const [countdata, setCountdata] = useState(0);
  const recentImages = detailData?.recentimgs || [];

  useEffect(() => {
    if (openrecentimage && recentImages.length > 0) {
      const interval = setInterval(() => {
        setCountdata((prev) => (prev + 1) % recentImages.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [openrecentimage, recentImages.length]);

  const handleDotClick = (index) => {
    setCountdata(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <TopContainer
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "none",
          },
        }}
      >
        <Label variant="subtitle1" sx={{ fontSize: "20px", fontWeight: 700 }}>
          {detailData?.dept}{" "}
        </Label>
      </TopContainer>
      <TopContainer>
        <Label
          variant="subtitle1"
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
          }}
        >
          {detailData?.dept}{" "}
        </Label>
        <Label variant="subtitle1" sx={{ fontSize: "20px", fontWeight: 700 }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              disabled={
                userAccess.role === "Guest" ||
                (fromScanner && !isAssetAvailable) ||
                isCheckedOut
              }
              sx={{
                borderRadius: "22px",
                fontSize: "15px",
                width: "max-content",
                backgroundColor: "#4690ff",
                color: "white",
              }}
              onClick={handleOpenRecentImage}
            >
              <CameraAltIcon
                sx={{ marginRight: { xs: "2px", sm: "4px", md: "6px" } }}
              />
              <Stack
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                  },
                }}
              >
                Recent Images
              </Stack>
            </Button>

            {detailData?.status !== "Discard" && (
              <Button
                variant="contained"
                disabled={
                  userAccess.role === "Guest" ||
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut
                }
                onClick={
                  userAccess.role === "Guest" ||
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut
                    ? null
                    : handleOpenModal1
                }
                sx={{
                  borderRadius: "22px",
                  fontSize: "15px",
                  width: "max-content",
                  background:
                    userAccess.role === "Guest" ||
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut
                      ? "#e0e0e0"
                      : "#1B2535", // Disabled color
                  color:
                    userAccess.role === "Guest" ||
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut
                      ? "#b1abc0"
                      : "#fff", // Disabled text color
                  "&:hover": {
                    background:
                      userAccess.role === "Guest" ||
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut
                        ? "#e0e0e0"
                        : "red", // Disabled hover color
                    color:
                      userAccess.role === "Guest" ||
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut
                        ? "#b1abc0"
                        : "#fff", // Disabled hover text color
                  },
                }}
              >
                Discard
              </Button>
            )}

            <Button
              variant="contained"
              disabled={
                ["Guest", "Branch_Admin", "Admin"].includes(userAccess.role) ||
                (fromScanner && !isAssetAvailable) ||
                isCheckedOut
              }
              onClick={
                ["Guest", "Branch_Admin", "Admin"].includes(userAccess.role) ||
                (fromScanner && !isAssetAvailable) ||
                isCheckedOut
                  ? null
                  : handleOpen1
              }
              sx={{
                borderRadius: "22px",
                fontSize: "15px",
                width: "max-content",
                background:
                  ["Guest", "Branch_Admin", "Admin"].includes(
                    userAccess.role
                  ) ||
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut
                    ? "#e0e0e0"
                    : "#1B2535",
                color:
                  ["Guest", "Branch_Admin", "Admin"].includes(
                    userAccess.role
                  ) ||
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut
                    ? "#b1abc0"
                    : "#fff",
                "&:hover": {
                  background:
                    ["Guest", "Branch_Admin", "Admin"].includes(
                      userAccess.role
                    ) ||
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut
                      ? "#e0e0e0"
                      : "red",
                  color:
                    ["Guest", "Branch_Admin", "Admin"].includes(
                      userAccess.role
                    ) ||
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut
                      ? "#b1abc0"
                      : "#fff",
                },
              }}
            >
              <DeleteIcon />
              <Stack>Delete</Stack>
            </Button>
          </Box>

          {/* Confirmation Modal */}
          <Dialog open={open1} onClose={handleClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              Are you sure you want to delete{" "}
              <span style={{ color: "red" }}>"{detailData?.name}"</span> asset?
              <br />
              <Stack sx={{ marginTop: "10px" }}>
                <Label>FeedBack:</Label>
                <TextField
                  placeholder="Feedback"
                  fullWidth
                  variant="outlined"
                  value={feedback}
                  onChange={handleFeedbackChange}
                  error={!isFeedbackValid}
                  helperText={!isFeedbackValid ? "Feedback is required" : ""}
                  required
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error" variant="contained">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Label>
      </TopContainer>
      <MidContainer>
        <MidTop>
          <MidTopBox>
            <InnerBorderBoxBox>
              <Box sx={{ width: "100%" }}>
                <Tooltip title={detailData?.name} placement="top" arrow>
                  <Typography
                    sx={{
                      color: "#4690FF",
                      fontWeight: 700,
                      textAlign: "left",
                      fontSize: "24px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: {
                        xs: "280px",
                        sm: "280px",
                        md: "350px",
                      },
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 3, // Limits to 2 lines
                    }}
                    variant="subtitle1"
                  >
                    {detailData?.name}
                  </Typography>
                </Tooltip>
              </Box>

              <InnerImageBox>
                <IconButton onClick={handlePrevious}>
                  <KeyboardBackspaceIcon
                    style={{ color: "#4690FF" }}
                    sx={{ width: "38px", height: "38px" }}
                  />
                </IconButton>
                <ImageCard
                  as="img"
                  src={`${image[count]?.imgurl}`}
                  alt="Asset Image"
                  loading="lazy"
                  onClick={() => handleImageOpen(image[count]?.imgurl)}
                />
                <IconButton onClick={handleNext}>
                  <EastIcon
                    style={{ color: "#4690FF" }}
                    sx={{ width: "35px", height: "35px" }}
                  />
                </IconButton>
              </InnerImageBox>
              <Stack
                sx={{ width: "100%" }}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack gap="10px" flexDirection={"row"}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                    Asset Image
                  </Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                    {image.length}/{count + 1}
                  </Typography>
                </Stack>

                <Tooltip title="Download">
                  <IconButton
                    onClick={() => handleDownload(image[count]?.imgurl)}
                  >
                    <DownloadIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </InnerBorderBoxBox>
          </MidTopBox>

          <MidTopBox>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Label
                  sx={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Working Status
                </Label>
                {detailData?.rating !== "0" && detailData?.rating !== "NA" && (
                  <Label
                    sx={{
                      textAlign: "left",
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    Rating: {detailData?.rating}
                  </Label>
                )}
              </Box>
              <Divider sx={{ position: "relative", bottom: "25px" }} />

              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap="5px"
              >
                <Box sx={{ width: "100%" }}>
                  <Label sx={{ textAlign: "left", fontSize: "20px" }}>
                    Status
                  </Label>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color:
                        detailData?.status === "Working"
                          ? "#4690FF"
                          : "#FF6B00",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    {detailData?.status}
                  </Typography>
                </Box>

                {detailData?.status === "Working" ? (
                  <CheckIcon style={{ color: "#4690FF", fontSize: "40px" }} />
                ) : (
                  <WarningAmberIcon
                    CheckIcon
                    style={{ color: "#FF6B00", fontSize: "40px" }}
                  />
                )}
              </Stack>

              <Box sx={{ width: "100%" }}>
                <Label sx={{ textAlign: "left", fontSize: "20px" }}>
                  Downtime
                </Label>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "#FF6B00",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  {convertTime() === "" ? "No down time" : convertTime()}
                </Typography>
              </Box>
            </Box>
          </MidTopBox>

          <MidTopBox style={{ flexDirection: "row", display: "flex" }}>
            <Box>
              <ImageBox as="img" src={comingSoon} alt="image-box" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                position: "relative",
                left: {
                  xs: "1px",
                  sm: "60px",
                  md: "40px",
                },
                bottom: "90px",
                fontSize: "30px",
                color: "white",
                height: "50px",
                width: "50px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                  left: "40px",
                  top: "10px",
                  fontSize: "30px",
                  color: "#1b2535",
                  borderRadius: "50px",
                  width: "50px",
                  fontWeight: "bold",
                }}
              >
                {!detailData.asset_grade || detailData.asset_grade === "NA" ? (
                  <span style={{ fontSize: "15px" }}>Not Available</span>
                ) : (
                  detailData.asset_grade
                )}
                          {userAccess.role !== "Guest" && (

                <Box
                  sx={{
                    position: "absolute",
                    bottom: "30px",
                    left: "35px",
                    color: "#FFD700",
                    cursor: "pointer",
                  }}
                  onClick={handleClick1}
                >
                  <GradeIcon />
                </Box>
                          )}
              </Box>

              <Box>
                <Box
                  style={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Grade
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose1}
                >
                  {options.map((option) => (
                    <Tooltip key={option} title={tooltipTexts[option]} arrow>
                      <MenuItem
                        key={option}
                        onClick={() => handleToggle(option)}
                      >
                        <Checkbox checked={selected.includes(option)} />
                        <ListItemText primary={option} />
                      </MenuItem>
                    </Tooltip>
                  ))}
                  <MenuItem>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handlegradeupdate}
                    >
                      Submit
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </MidTopBox>
        </MidTop>

        <Divider />
        <MidBottom>
          <MiniQRset
            onClick={handleOpenQr}
            as="img"
            src={detailData?.qrimg}
          ></MiniQRset>

          <Box>
            <Button
              sx={{
                color: "black",
                borderRadius: "20px",
                border: "1px solid",
                fontSize: "18px",
                width: "auto",
              }}
              disabled={
                userAccess.role === "Guest" ||
                (fromScanner && !isAssetAvailable) ||
                detailData?.status === "Discard"
              }
              onClick={handleEdit}
              variant="outlined"
            >
              Edit Asset
            </Button>
          </Box>

          <Button
            onClick={() => {
              generatePDF(); // Generate the PDF first
              setTimeout(() => {
                handleSystemShare(); // Share the PDF after a short delay
              }, 100); // Delay to ensure PDF is ready
            }}
            sx={{
              zIndex: "999",
              position: "fixed",
              right: "20px",
              bottom: {
                xs: "70px",
                sm: "70px",
                md: "40px",
              },
              backgroundColor: "#4690FF",
              color: "white",
              borderRadius: "50px",
              height: "50px",
              width: "50px",
              borderColor: "transparent",
              cursor: "pointer",
            }}
          >
            <ShareIcon />
          </Button>

          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              visibility:
                detailData?.servstatus === "Open" ? "hidden" : "visible",
            }}
          >
            {detailData?.incidstatus !== "Open" && (
              <Button
                variant="contained"
                disabled={
                  userAccess.role === "Guest" ||
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard"
                }
                onClick={
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard"
                    ? null
                    : () => navigate(`/record-incident/${params.id}`)
                }
                sx={{
                  borderRadius: "22px",
                  fontSize: "15px",
                  width: "max-content",
                  background:
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard"
                      ? "#e0e0e0"
                      : "#FF6B00", // Disabled color
                  color:
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard"
                      ? "#b1abc0"
                      : "#fff", // Disabled text color
                  "&:hover": {
                    background:
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard"
                        ? "#e0e0e0"
                        : "#FF6B00", // Disabled hover color
                    color:
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard"
                        ? "#b1abc0"
                        : "#fff", // Disabled hover text color
                  },
                }}
              >
                Add Incident
              </Button>
            )}

            {detailData?.incidstatus === "Open" && (
              <Button
                variant="contained"
                disabled={
                  userAccess.role === "Guest" || // Disable for "Guest" role
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard"
                }
                onClick={
                  userAccess.role === "Guest" || // Prevent action for "Guest" role
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard"
                    ? null
                    : () => navigate(`/incident-list/${detailData?.incidid}`)
                }
                sx={{
                  borderRadius: "22px",
                  fontSize: "15px",
                  width: "max-content",
                  background:
                    userAccess.role === "Guest" || // Disabled color for "Guest" role
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard"
                      ? "#e0e0e0"
                      : "#FF6B00",
                  color:
                    userAccess.role === "Guest" || // Disabled text color for "Guest" role
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard"
                      ? "#b1abc0"
                      : "#fff",
                  "&:hover": {
                    background:
                      userAccess.role === "Guest" || // Disabled hover color for "Guest" role
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard"
                        ? "#e0e0e0"
                        : "#FF6B00",
                    color:
                      userAccess.role === "Guest" || // Disabled hover text color for "Guest" role
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard"
                        ? "#b1abc0"
                        : "#fff",
                  },
                }}
              >
                Clear Incident
              </Button>
            )}
          </Stack>

          <Stack sx={{ width: "100%", alignItems: "center" }}>
            {detailData?.servstatus === "NA" && (
              <Button
                onClick={
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard" ||
                  userAccess.role === "Guest"
                    ? null
                    : handleOpenModal
                }
                variant="contained"
                disabled={
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard" ||
                  userAccess.role === "Guest"
                }
                sx={{
                  borderRadius: "22px",
                  fontSize: "15px",
                  width: "max-content",
                  visibility: "hidden",
                  background:
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard" ||
                    userAccess.role === "Guest"
                      ? "#e0e0e0"
                      : "#FF6B00", // Disabled color
                  color:
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard" ||
                    userAccess.role === "Guest"
                      ? "#b1abc0"
                      : "#fff", // Disabled text color
                  "&:hover": {
                    background:
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard" ||
                      userAccess.role === "Guest"
                        ? "#e0e0e0"
                        : "#FF6B00", // Disabled hover color
                    color:
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard" ||
                      userAccess.role === "Guest"
                        ? "#b1abc0"
                        : "#fff", // Disabled hover text color
                  },
                }}
              >
                Add Service
              </Button>
            )}

            {detailData?.servstatus === "Open" && (
              <Button
                onClick={
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard" ||
                  userAccess.role === "Guest"
                    ? null
                    : () => navigate(`/service-list/${detailData.servid}`)
                }
                variant="contained"
                disabled={
                  (fromScanner && !isAssetAvailable) ||
                  isCheckedOut ||
                  detailData?.status === "Discard" ||
                  userAccess.role === "Guest"
                }
                sx={{
                  borderRadius: "22px",
                  fontSize: "14px",
                  width: "auto",
                  visibility: "hidden",
                  background:
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard" ||
                    userAccess.role === "Guest"
                      ? "#e0e0e0"
                      : "#FF6B00", // Disabled color
                  color:
                    (fromScanner && !isAssetAvailable) ||
                    isCheckedOut ||
                    detailData?.status === "Discard" ||
                    userAccess.role === "Guest"
                      ? "#b1abc0"
                      : "#fff", // Disabled text color
                  "&:hover": {
                    background:
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard" ||
                      userAccess.role === "Guest"
                        ? "#e0e0e0"
                        : "#FF6B00", // Disabled hover color
                    color:
                      (fromScanner && !isAssetAvailable) ||
                      isCheckedOut ||
                      detailData?.status === "Discard" ||
                      userAccess.role === "Guest"
                        ? "#b1abc0"
                        : "#fff", // Disabled hover text color
                    visibility: "hidden",
                  },
                }}
              >
                Clear Service
              </Button>
            )}
          </Stack>

          <Box>
            <UpdateReport assetId={params.id} />
          </Box>
        </MidBottom>
      </MidContainer>
      {detailData.chat === "Yes" && (
        <ChatBoxGPT chatId={detailData.chatid} name={detailData.name} />
      )}
      {/* <ChatBoxGPT chatId={detailData.chatid} name={detailData.name} /> */}
      <MainWrapper>
        <Stack sx={{ flex: "1", gap: "20px" }}>
          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",

              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",

                height: "100%",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Asset Details
              </Label>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(AssetDetail).map(
                        ([key, value]) =>
                          value !== "" && (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells
                                sx={{ fontSize: "15px", fontWeight: 400 }}
                              >
                                {value}
                              </TableCells>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
                marginTop: "20px",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Specifications
              </Label>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(Specifications).map(([key, value]) => {
                        if (value && value !== "" && value !== "Invalid date") {
                          const isPdf =
                            typeof value === "string" &&
                            /(\.pdf)(\?|$)/i.test(value);

                          return (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells
                                sx={{ fontSize: "15px", fontWeight: 400 }}
                              >
                                {key.includes("File") ? (
                                  <Stack
                                    sx={{
                                      bgcolor: "white",
                                      height: "100%",
                                      gap: "10px",
                                    }}
                                  >
                                    <WarrantyInnerBorderBoxBox>
                                      <Stack
                                        sx={{
                                          width: "100%",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <InnerWarrantyImageBox2>
                                          <WarrantyImageCard
                                            as="img"
                                            src={isPdf ? pdfImage : value}
                                            alt={`${key} File`}
                                            onClick={() =>
                                              handleImageOpen(value)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              width: "100px",
                                              height: "70px",
                                            }}
                                          />
                                        </InnerWarrantyImageBox2>
                                        <IconButton
                                          onClick={() => handleDownload(value)}
                                        >
                                          <DownloadIcon />
                                        </IconButton>
                                      </Stack>
                                    </WarrantyInnerBorderBoxBox>
                                  </Stack>
                                ) : (
                                  value
                                )}
                              </TableCells>
                            </TableRow>
                          );
                        }
                        return null;
                      })}
                    </TableBody>
                    {/* <TableBody>
                      {Object.entries(Specifications).map(
                        ([key, value]) =>
                          value !== "" &&
                          value !== "Invalid date" && (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells
                                sx={{ fontSize: "15px", fontWeight: 400 }}
                              >
                                {value}
                              </TableCells>
                            </TableRow>
                          )
                      )}
                    </TableBody> */}
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
                marginBottom: "20px",
                height: "280px",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Purchase
              </Label>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(Purchase).map(
                        ([key, value]) =>
                          value !== "" && (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells
                                sx={{ fontSize: "15px", fontWeight: 400 }}
                              >
                                {value}
                              </TableCells>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>

            <Stack
              sx={{
                flex: "1",
                padding: "10px",
                bgcolor: "white",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                    Accessories
                  </Label>
                  {userAccess.role !== "Guest" && (
                    <Button variant="outlined" onClick={handleOpenDropdown}>
                      Add Accessory
                    </Button>
                  )}
                </Box>

                <Divider />

                {!openDropdown ? (
                  Array.isArray(detailData?.used_accessory) &&
                  detailData.used_accessory.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      sx={{ marginTop: "20px" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Product Name</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Quantity</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Price</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {detailData.used_accessory.map(
                            (used_accessory, index) => (
                              <TableRow key={index}>
                                <TableCell>{used_accessory.accsname}</TableCell>
                                <TableCell>{used_accessory.quantity}</TableCell>
                                <TableCell>{used_accessory.price}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null
                ) : products.length === 0 ? (
                  <Stack
                    sx={{
                      width: "100%",
                      gap: "10px",
                      marginTop: "20px",
                      padding: "10px",
                      border: "1px solid #A6A6A6",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Label variant="subtitle1"></Label>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={handleCloseDropdown}>
                          <CancelIcon sx={{ color: "black" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Stack
                      sx={{
                        height: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No Accessory Found in your Store
                      <Button onClick={() => navigate("/Accessories-list")}>
                        Accessory
                      </Button>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <Stack
                      sx={{
                        width: "100%",
                        gap: "10px",
                        marginTop: "20px",
                        padding: "10px",
                        border: "1px solid #A6A6A6",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Label variant="subtitle1">Add New Accessory</Label>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={handleCloseDropdown}>
                            <CancelIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        {/* Product Dropdown */}
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <Autocomplete
                              value={selectedProduct}
                              onChange={handleProductChange}
                              options={products}
                              renderInput={(params) => (
                                <TextField {...params} label="Product" />
                              )}
                              disableClearable
                            />
                          </FormControl>
                        </Grid>

                        {/* Price Dropdown (Enabled only if product is selected) */}
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth disabled={!selectedProduct}>
                            <Autocomplete
                              value={selectedPrice}
                              onChange={handlePriceChange}
                              options={prices}
                              renderInput={(params) => (
                                <TextField {...params} label="Price" />
                              )}
                              disableClearable
                            />
                          </FormControl>
                        </Grid>

                        {/* Quantity Dropdown (Enabled only if price is selected) */}
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <Tooltip
                              title="Please select a price first"
                              disableHoverListener={selectedPrice !== null}
                            >
                              <span>
                                <Autocomplete
                                  value={selectedQuantity}
                                  onChange={handleQuantityChange}
                                  options={quantities}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Quantity" />
                                  )}
                                  disableClearable
                                  disabled={!selectedPrice}
                                />
                              </span>
                            </Tooltip>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Stack
                        sx={{
                          justifyContent: "end",
                          alignItems: "end",
                          display: "flex",
                        }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={handleSubmit}
                          sx={{ width: "auto" }}
                          disabled={!selectedQuantity}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>

            <Stack sx={{ flex: "1", gap: "20px" }}>
              <Stack sx={{ height: "300px" }}>
                <>
                  <Stack
                    sx={{
                      flex: "1",

                      padding: "10px",
                      bgcolor: "white",
                    }}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        gap: "10px",
                        textAlign: "start",
                      }}
                    >
                      <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                        Warranty
                      </Label>
                      <Divider />
                      <Stack
                        sx={{
                          width: "100%",

                          gap: "10px",
                        }}
                      >
                        {detailData?.wimg !== "NA" ? (
                          <Stack sx={{ bgcolor: "white", height: "100%" }}>
                            <WarrantyInnerBorderBoxBox>
                              <InnerWarrantyImageBox>
                                <IconButton onClick={handlePreviousCertificate}>
                                  <KeyboardBackspaceIcon
                                    style={{ color: "#4690FF" }}
                                  />
                                </IconButton>

                                <WarrantyImageCard
                                  as="img"
                                  src={`${
                                    certificateImage[count2]?.imgid
                                      ?.split(".")
                                      ?.pop() === "pdf"
                                      ? pdfImage
                                      : certificateImage[count2]?.imgurl
                                  }`}
                                  alt="check"
                                  onClick={() =>
                                    handleImageOpen(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }
                                />
                                <IconButton onClick={handleNextCertificate}>
                                  <EastIcon style={{ color: "#4690FF" }} />
                                </IconButton>
                              </InnerWarrantyImageBox>
                              <Stack
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  marginTop: "20px",
                                }}
                              >
                                <Typography>Warranty Certificate</Typography>
                                <IconButton
                                  onClick={() =>
                                    handleDownload(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Stack>
                            </WarrantyInnerBorderBoxBox>
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              height: "150px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            No warranty
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ flex: "1", gap: "20px" }}>
          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Incident History -{" "}
                {Array.isArray(detailData?.incident_history)
                  ? detailData.incident_history.length
                  : 0}
              </Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {detailData?.incident_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.incident_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              Incident Lable
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {}
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {detailData?.serialno}
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.cleardate}
                            </TableCells>

                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              {/* {incident_history.clear.startdate} */}
                            </TableCells>
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No incident recorded
                  </Stack>
                )}
                <Divider />
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flex: "1",
              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                Service History -{" "}
                {Array.isArray(detailData?.service_history)
                  ? detailData.service_history.length
                  : 0}
              </Label>

              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {detailData?.service_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.service_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Service Lable
                            </TableCells>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {/* {item?.servstatus} */}
                              {/* {item?.serialno} */}
                              {detailData?.serialno}
                            </TableCells>

                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {/* {item?.servstatus} */}
                              {item?.servdate}
                            </TableCells>
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No service history
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                  Accessory History -{" "}
                  {assetDataHistory?.length > 0
                    ? `${assetDataHistory.length} `
                    : "0"}
                </Label>
                <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                  Expenditure:₹{assetExpenditure}
                </Label>
              </Stack>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {assetDataHistory?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {assetDataHistory?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.accessoryname}
                            </TableCells>

                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {item?.accessoryquantity} nos
                            </TableCells>

                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              ₹{item?.accessoryprice}
                            </TableCells>

                            <TableCells
                              sx={{
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDFHistory(
                                        item?.receipt,
                                        item?.accessoryname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  !addHistory && (
                    <Stack
                      sx={{
                        height: "150px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Accessory History uploaded yet
                    </Stack>
                  )
                )}

                <Divider />

                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {/* <UpdateReport assetId={params.id} /> */}
                  <Button
                    sx={{
                      borderRadius: "20px",
                      width: "200px",
                      height: "44px",
                      fontSize: "18px",
                      fontWeight: 700,
                      color:
                        addHistory === true ||
                        (fromScanner && !isAssetAvailable) ||
                        (detailData?.status === "Discard" &&
                          userAccess &&
                          ["Guest", "Branch_Admin", "Admin"].includes(
                            userAccess.role
                          ))
                          ? "#b1abc0" // Disabled text color
                          : "#1B2535", // Active text color
                      border: "1px solid",
                      background:
                        addHistory === true ||
                        (fromScanner && !isAssetAvailable) ||
                        (detailData?.status === "Discard" &&
                          userAccess &&
                          ["Guest", "Branch_Admin", "Admin"].includes(
                            userAccess.role
                          ))
                          ? "#e0e0e0" // Disabled background color
                          : "transparent", // Active background color
                      "&:hover": {
                        background:
                          addHistory === true ||
                          (fromScanner && !isAssetAvailable) ||
                          (detailData?.status === "Discard" &&
                            userAccess &&
                            ["Guest", "Branch_Admin", "Admin"].includes(
                              userAccess.role
                            ))
                            ? "#e0e0e0" // Disabled hover background color
                            : "transparent", // Active hover background color
                        color:
                          addHistory === true ||
                          (fromScanner && !isAssetAvailable) ||
                          (detailData?.status === "Discard" &&
                            userAccess &&
                            ["Guest", "Branch_Admin", "Admin"].includes(
                              userAccess.role
                            ))
                            ? "#b1abc0" // Disabled hover text color
                            : "#1B2535", // Active hover text color
                      },
                    }}
                    variant="outlined"
                    onClick={() => setAddHistory(true)}
                    disabled={
                      addHistory === true ||
                      (fromScanner && !isAssetAvailable) ||
                      detailData?.status === "Discard"
                    }
                  >
                    Add History
                  </Button>
                </Stack>
                <Divider />
                {addHistory && (
                  <Stack
                    sx={{
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <Stack
                      sx={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "30px",
                      }}
                    >
                      <Label variant="subtitle1">
                        Add new accessory history
                      </Label>
                      <IconButton onClick={() => setAddHistory(false)}>
                        <CancelIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Stack>

                    <Stack spacing={4}>
                      <Stack
                        sx={{
                          justifyContent: "space-between",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            gap: "10px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={assetNameHistory}
                            placeholder="Accessory name"
                            onChange={handleAssetNameHistory}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                            }}
                          />
                          <HelperText variant="caption">
                            {isAssetErrorHistory}
                          </HelperText>
                        </Stack>
                        <Stack
                          sx={{
                            gap: "10px",
                            width: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={priceHistory}
                            placeholder="Unit price"
                            onChange={handlePriceHistory}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                            }}
                          />
                          <HelperText variant="caption">
                            {isPriceErrorHistory}
                          </HelperText>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            width: { xs: "100%", md: "max-content" },
                            gap: "3px",
                            height: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Input
                            value={quantityHistory}
                            placeholder="Quantity"
                            onChange={handleQuantityHistory}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              background: "#f6f6f6",
                              border: "none",
                              height: "50px",
                              padding: "10px",
                              marginRight: {
                                md: "100px",
                              },
                            }}
                          />
                          <HelperText variant="caption">
                            {isQuantityErrorHistory}
                          </HelperText>
                        </Stack>

                        <Stack>
                          <Typography>
                            Upload Receipt (Png, jpeg, or pdf)
                          </Typography>
                          <Box
                            sx={{
                              background: "#f6f6f6",
                              padding: "5px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "223px",
                                  lg: "100%",
                                },
                                justifyContent: "flex-end",
                              }}
                            >
                              <Input
                                type="file"
                                accept=".png,.jpeg,.jpg,.pdf"
                                onChange={handlePhotoUploadHistory}
                                sx={{ display: "none" }}
                                id="file-input"
                                inputRef={historyFileInputRef}
                              />
                              {fileUrlHistory.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 2,
                                    position: "relative",
                                  }}
                                >
                                  <Tooltip title={item.fileName}>
                                    <Box
                                      sx={{
                                        backgroundColor:
                                          "rgba(27, 37, 53, 0.19)",
                                        color: "#1B2535",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        fontWeight: "bold",
                                        width: {
                                          xs: "160px",
                                          sm: "315px",
                                          md: "165px",
                                        },
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item.fileName} (
                                      {item.fileType.toUpperCase()})
                                    </Box>
                                  </Tooltip>
                                  <label
                                    htmlFor="file-input"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Box
                                      variant="contained"
                                      component="a"
                                      sx={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        fontSize: "13px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Replace Document
                                    </Box>
                                  </label>
                                  <IconButton
                                    onClick={() =>
                                      removePhotoHistory(item?.imageid)
                                    }
                                    size="small"
                                    sx={{
                                      marginLeft: "10px",
                                      position: "absolute",
                                      top: "-22px",
                                      right: "0",
                                    }}
                                  >
                                    <CloseIcon sx={{ color: "#FF3A44" }} />
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>

                            {fileUrlHistory.length === 0 && (
                              <label htmlFor="file-input">
                                <Button
                                  component="span"
                                  sx={{
                                    background: "rgba(27, 37, 53, 0.19)",
                                    width: "57px",
                                    height: "57px",
                                    color: "#1B2535",
                                  }}
                                >
                                  PDF
                                </Button>
                              </label>
                            )}
                          </Box>
                          <HelperText variant="primary">
                            {isPhotoErrorHistory}
                          </HelperText>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          justifyContent: "end",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleSubmitHistory}
                          sx={{ borderRadius: "25px" }}
                          disabled={isGuest || hasErrors}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Stack>

                    <Divider />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Label variant="subtitle1" sx={{ fontSize: "24px" }}>
                  Report -{" "}
                  {Array.isArray(detailData?.reportnames)
                    ? detailData.reportnames.length
                    : 0}
                </Label>
                <Stack sx={{ width: "50%", alignItems: "flex-end" }}>
                  <UpdateReport assetId={params.id} />
                </Stack>
              </Stack>

              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}
              >
                {reportImage?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "280px",
                    }}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {reportImage?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {reportImageName[index]}
                            </TableCells>

                            <TableCells
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >
                              {" "}
                              Download PDf
                            </TableCells>
                            <TableCells
                              sx={{
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              <IconButton
                                disabled={userAccess?.role === "Guest"}
                              >
                                <DownloadIcon
                                  onClick={() => handleDownload(item?.imgurl)}
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No reports uploaded yet
                  </Stack>
                )}
                <Divider />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </MainWrapper>
      <Dialog open={opendiscard} onClose={handleCloseModal1}>
        <DialogTitle>
          Discard Conformation
          <IconButton
            aria-label="close"
            onClick={handleCloseModal1}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "0px" }}>
          <h3>Are you sure you want to discard?</h3>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handlediscard} color="error" variant="contained">
            Yes
          </Button>
          <Button
            onClick={handleCloseModal1}
            color="primary"
            sx={{ paddingBottom: "10px" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}
            >
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",

                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Label variant="h6">Request services</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Choose Service</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Medpick", params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  >
                    Vajra
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Others", params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  >
                    Others
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>
      <Modal open={openrecentimage} onClose={handleCloseRecentImage}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "white",
            boxShadow: 24,
            p: 2,
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <div style={{ position: "relative", overflow: "hidden" }}>
            {/* Image Display */}
            {recentImages.length > 0 ? (
              <img
                src={recentImages[countdata]?.imgurl}
                style={{
                  width: "100%",
                  transition: "opacity 0.5s ease-in-out",
                  height: "500px",
                }}
                alt={`Asset Image ${countdata + 1}`}
                loading="lazy"
              />
            ) : (
              <p>No images available</p>
            )}

            {/* Dots for Manual Image Selection */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {recentImages.map((_, index) => (
                <span
                  key={index}
                  onClick={() => handleDotClick(index)}
                  style={{
                    height: "10px",
                    width: "10px",
                    margin: "0 5px",
                    backgroundColor: countdata === index ? "#000" : "#bbb",
                    borderRadius: "50%",
                    display: "inline-block",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                ></span>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
      ;
      <ViewImageModal
        imageUrl={detailData?.qrimg}
        assetId={detailData?.code}
        department={detailData?.dept}
        assestname={detailData?.name}
        handleClose={handleCloseQr}
        open={open}
      />
    </Box>
  );
}

export default AssetDetailPage;
