import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { Tabs, Tab } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

import {
  discardpending,
  discardapprove,
  discardApproved,
} from "../redux/data/action";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const DiscardList = () => {
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const discardpendingdata = useSelector((store) => store.data.discardpending);
  const discardapproveddata = useSelector(
    (store) => store.data.discardapproveddata
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      depart_ids: userAccess.departmentids,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      username: userIds.username,
    };

    dispatch(discardpending(payload));
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      depart_ids: userAccess.departmentids,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      username: userIds.username,
    };

    dispatch(discardApproved(payload));
  }, [dispatch]);

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterValue1, setFilterValue1] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const itemsPerPage = 10;
  const itemsPerPage1 = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfLastItem1 = currentPage1 * itemsPerPage1;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const currentData1 = filteredData1.slice(indexOfFirstItem1, indexOfLastItem1);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const handlePageChange1 = (event, newPage) => {
    setCurrentPage1(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? discardapproveddata.filter((row) => row.status === filterValue)
      : discardapproveddata;
    setData(newFilteredData);
  }, [filterValue, discardapproveddata]);

  useEffect(() => {
    const newFilteredData1 = filterValue1
      ? discardpendingdata.filter((row) => row.status === filterValue1)
      : discardpendingdata;
    setData1(newFilteredData1);
  }, [filterValue1, discardpendingdata]);

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false); // State for the approval modal
  const [selectedItem, setSelectedItem] = useState(null); // State to store selected item

  const openApproveModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setIsApproveModalOpen(true); // Open the approval modal
  };

  const closeApproveModal = () => {
    setIsApproveModalOpen(false); // Close the approval modal
  };

  const handleApprove = () => {
    const payload = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
      username: userIds?.username,
      assetid: selectedItem?.assetid,
    };

    // Dispatch the first action
    dispatch(discardapprove(payload))
      .then(() => {
        const secondPayload = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          depart_ids: userAccess.departmentids,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          username: userIds.username,
        };

        // Dispatch the second action after the first completes
        dispatch(discardApproved(secondPayload))
          .then(() => {
            closeApproveModal(); // Close the modal after both actions are done
          })
          .catch((error) => console.error("Error in second dispatch:", error));
      })
      .catch((error) => console.error("Error in first dispatch:", error));
  };

  console.log("currentData data is", currentData);

  const Sheet1 = () => (
    <>
      <TableContainer
        component={Paper}
        sx={{
          height: "calc(-200px + 95vh)",
          boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
          width: "100%",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="sheet 1 table">
          <TableHead>
            <TableRow>
              <TableHeading>Asset Name</TableHeading>
              <TableHeading>Asset Code</TableHeading>
              <TableHeading>Department</TableHeading>
              <TableHeading>Serial no</TableHeading>
              <TableHeading>Brand</TableHeading>
              <TableHeading sx={{ textAlign: "center" }}>Status</TableHeading>
              <TableHeading></TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((item, index) => (
              <TableRow key={index}>
                <TableCells>{item.name}</TableCells>
                <TableCells>{item.assetcode}</TableCells>
                <TableCells>{item.department}</TableCells>
                <TableCells>{item.serialno}</TableCells>
                <TableCells>{item.brand}</TableCells>
                <TableCells sx={{ textAlign: "center" }}>
                  {["Owner", "Super Admin"].includes(userAccess.role) ? (
                    <Button
                      sx={{
                        borderRadius: "30px",
                        margin: "auto",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        height: "30px",
                        padding: "4px",
                        width: {
                          xs: "85px",
                          sm: "65px",
                          md: "70px",
                          lg: "100px",
                        },
                        bgcolor: "rgba(255, 107, 0, 1)",
                        border: "1px solid rgba(255, 107, 0, 1)",
                        color: "white",
                        "&:hover": {
                          bgcolor: "rgba(255, 107, 0, 1)",
                          border: "1px solid rgba(255, 107, 0, 1)",
                        },
                      }}
                      onClick={() => openApproveModal(item)}
                    >
                      Pending
                    </Button>
                  ) : (
                    "Pending"
                  )}
                </TableCells>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Stack
        sx={{
          bgcolor: "white",
          padding: "20px",
          justifyContent: "right",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Pagination
          count={Math.ceil(discardapproveddata.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>
    </>
  );

  const Sheet2 = () => (
    <>
      <TableContainer
        component={Paper}
        sx={{
          height: "calc(-200px + 95vh)",
          boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
          width: "100%",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="sheet 2 table">
          <TableHead>
            <TableRow>
              <TableHeading>Asset Name</TableHeading>
              <TableHeading>Asset Code</TableHeading>
              <TableHeading>Department</TableHeading>
              <TableHeading>Serial no</TableHeading>
              <TableHeading>Brand</TableHeading>
              <TableHeading>Status</TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData1.map((item, index) => (
              <TableRow key={index}>
                <TableCells>{item.name}</TableCells>
                <TableCells>{item.assetcode}</TableCells>
                <TableCells>{item.department}</TableCells>
                <TableCells>{item.serialno}</TableCells>
                <TableCells>{item.brand}</TableCells>
                <TableCells
                  sx={{
                    textTransform: "none",
                    width: "90px",
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#74ACFF",
                  }}
                >
                  {item.status}
                </TableCells>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Stack
        sx={{
          bgcolor: "white",
          padding: "20px",
          justifyContent: "right",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Pagination
          count={Math.ceil(discardpendingdata.length / itemsPerPage)}
          page={currentPage1}
          onChange={handlePageChange1}
          color="primary"
        />
      </Stack>
    </>
  );

  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
          height: "100%",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <Stack
          style={{
            width: "100%",
            height: "95%",
          }}
        >
          <Stack overflow="auto" flexDirection="column">
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Box sx={{ width: "59%" }}>
                <Button
                  onClick={() => setActiveTab(0)}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor:
                      activeTab === 0 ? "#1B2535" : "transparent",
                    color: activeTab === 0 ? "white" : "#1B2535",
                    paddingLeft: "100px",
                    paddingRight: "100px",
                    border: "1px solid #1B2535",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}
                >
                  Pending List
                </Button>
              </Box>
              <Box sx={{ width: "59%" }}>
                <Button
                  onClick={() => setActiveTab(1)}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor:
                      activeTab === 1 ? "#1B2535" : "transparent",
                    color: activeTab === 1 ? "white" : "#1B2535",
                    paddingLeft: "100px",
                    paddingRight: "100px",
                    border: "1px solid #1B2535",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}
                >
                  Discarded List
                </Button>
              </Box>
            </Box>
            {activeTab === 0 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                gap="10px"
                sx={{ mt: 2 }}
              >
                <Stack
                  flexDirection={"row"}
                  height="50px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding="16px"
                  gap={"10px"}
                  sx={{
                    bgcolor: "white",
                    boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                    display: { xs: "none", sm: "none", lg: "flex" },
                  }}
                >
                  <Typography>{`Total : ${data?.length}`}</Typography>
                </Stack>
                <Box flex="2" width={"250px"}>
                  <SortSearchFilter
                    data={data}
                    setData={setData}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    heading={"My Orders"}
                    Filter1="department"
                    Filter2=""
                    FilterName1="Department"
                    FilterName2=""
                    sortDate=""
                    Grade=""
                    sortPrice=""
                    dueDate=""
                    name="name"
                    csvName=""
                  />
                </Box>
              </Box>
            )}
            {activeTab === 1 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                gap="10px"
                sx={{ mt: 2 }}
              >
                <Stack
                  flexDirection={"row"}
                  height="50px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding="16px"
                  gap={"10px"}
                  sx={{
                    bgcolor: "white",
                    boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                    display: { xs: "none", sm: "none", lg: "flex" },
                  }}
                >
                  <Typography>{`Total : ${data1?.length}`}</Typography>
                </Stack>
                <Box flex="2" width={"250px"}>
                  <SortSearchFilter
                    data={data1}
                    setData={setData1}
                    filteredData={filteredData1}
                    setFilteredData={setFilteredData1}
                    heading={"My Orders"}
                    Filter1="department"
                    Filter2=""
                    FilterName1="Department"
                    FilterName2=""
                    sortDate=""
                    Grade=""
                    sortPrice=""
                    dueDate=""
                    name="name"
                    csvName=""
                  />
                </Box>
              </Box>
            )}
            <Box mt={2} sx={{ border: "1px solid black" }}>
              {activeTab === 0 && <Sheet1 />}
              {activeTab === 1 && <Sheet2 />}
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Modal
        open={isApproveModalOpen}
        onClose={closeApproveModal}
        aria-labelledby="approval-modal-title"
        aria-describedby="approval-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: 24,
            width: 300,
          }}
        >
          <Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
              onClick={closeApproveModal}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <Typography id="approval-modal-title" variant="h6" component="h2">
            Are you sure you want to Approve this asset?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={closeApproveModal} // Close the modal
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApprove} // Approve and dispatch
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DiscardList;
