import React from "react";
import { useState } from "react";
import {
  Stack,
  Typography,
  styled,
  Button,
  Divider,
  IconButton,
  Checkbox,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import SortSearchFilter from "../components/SortSearchFilter";
import CloseIcon from "@mui/icons-material/Close";

import {
  departmentAuditList,
  assetAuditApproveList,
  auditRequestApprove,
  auditCompleted,
} from "../redux/data/action";
import { useEffect } from "react";
import EquipmentAudit from "./EquipmentAudit";
import { useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useRef } from "react";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const OuterSet = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  // alignItems: "stretch",
  gap: "10px",
  height: "85vh",
  width: "100%",
  bgcolor: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",

    // flexDirection:"column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AddDepartmentAudit = (audit) => {
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const auditId = queryParams.get("auditid");
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectedAuditId, setSelectedAuditId] = useState(null);
  const [selectedDepartId, setSelectedDepartId] = useState(null);

  const tableRef = useRef(null);
  const DepartmentAuditlist = useSelector(
    (store) => store.data.departmentauditlistdata
  );
  const AuditCompleteddatas = useSelector(
    (store) => store.data.auditcompleteddata
  );
  const [assetRequest, setassetRequest] = useState(false);
  const [assetApproved, setassetApproved] = useState(false);
  const [auditcomplted, setauditcomplted] = useState(false);

  const handleClose = (setter) => () => setter(false);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch.id,
      auditid: auditId,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(departmentAuditList(data));
  }, [dispatch]);

  // Function to open the modal and store selected values
  const handleOpenRequestApproval = (auditId, departId) => {
    setSelectedAuditId(auditId);
    setSelectedDepartId(departId);
    setassetRequest(true);
  };

  const handleRequestApproval = () => {
    if (selectedAuditId && selectedDepartId) {
      const data = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch.id,
        auditid: selectedAuditId,
        departid: selectedDepartId,
        userrole: userAccess?.role,
      };

      dispatch(assetAuditApproveList(data))
        .then(() => {
          const departmentData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            branchid: userBranch.id,
            auditid: selectedAuditId,
            depart_ids: userAccess.departmentids,
            userrole: userAccess?.role,
          };

          return dispatch(departmentAuditList(departmentData));
        })
        .catch((error) => {
          console.error("Error handling request approval:", error);
        });
    } else {
      console.error("Audit ID or Department ID is missing.");
    }
    setassetRequest(false);
  };

  const handleOpenApproveRequest = (auditId, departId) => {
    setSelectedAuditId(auditId);
    setSelectedDepartId(departId);
    setassetApproved(true); // Open the modal
  };

  const handleApproveRequest = () => {
    if (selectedAuditId && selectedDepartId) {
      const dataForApprove = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch.id,
        auditid: selectedAuditId, // Use selected auditId
        departid: selectedDepartId, // Use selected departId
        userrole: userAccess?.role,
        username: ids?.username,
      };

      dispatch(auditRequestApprove(dataForApprove))
        .then(() => {
          const dataForList = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            branchid: userBranch.id,
            auditid: selectedAuditId,
            depart_ids: userAccess.departmentids,
            userrole: userAccess?.role,
          };
          dispatch(departmentAuditList(dataForList));
        })
        .catch((error) => {
          console.error("Error in approving the audit request:", error);
        });
    } else {
      console.error("Audit ID or Department ID is missing.");
    }
    setassetApproved(false); // Close the modal after approval
  };

  const handleOpenCompletedAudit = () => {
    setauditcomplted(true);
  };

  const handleCompletedAudit = () => {
    selectedAssetIds.forEach((departId) => {
      const dataForApprove = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch.id,
        auditid: auditId, 
        departid: departId,
        username: ids?.username,
      };

      dispatch(auditCompleted(dataForApprove))
      .then(() => {
        const dataForList = {
          hospid: ids?.hospid,
          userid: ids?.userid,
          branchid: userBranch.id,
          auditid: auditId,
          depart_ids: userAccess.departmentids,
          userrole: userAccess?.role,
        };
        dispatch(departmentAuditList(dataForList));
      })
      setauditcomplted(false)  
    });
  };

  // window.location.reload();


  useEffect(() => {
    const newFilteredData = filterValue
      ? DepartmentAuditlist.filter((row) => row.status === filterValue)
      : DepartmentAuditlist;
    setData(newFilteredData);
  }, [filterValue, DepartmentAuditlist]);

  const isCompleteAuditEnabled = selectedAssetIds.some((selectedId) => {
    const item = currentData.find((data) => data.departid === selectedId);
    if (item) {
      const audited = Number(item.audited);
      const ignored = Number(item.ignored);
      const tot_assets = Number(item.tot_assets);
      const sum = audited + ignored;
      return sum === tot_assets && sum !== 0 && item.status !== "Completed";
    }
    return false;
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "10px",
          alignItems: "center",
          marginTop: "-15px",
        }}
      >
        <OuterSet>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            style={{ width: "100%" }}
          >
            <Stack
              sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
            >
              {selectedAssetIds.length > 0 && isCompleteAuditEnabled && (
                <Button
                  onClick={handleOpenCompletedAudit}
                  variant="contained"
                  sx={{
                    borderRadius: "22px",
                    bgcolor: "rgba(255, 107, 0, 1)",
                    border: "1px solid rgba(255, 107, 0, 1)",
                    color: "white",
                    "&:hover": {
                      bgcolor: "rgba(255, 107, 0, 1)",
                      border: "1px solid rgba(255, 107, 0, 1)",
                      color: "white",
                    },
                  }}
                  disabled={userAccess?.role === "Guest"}
                >
                  Completed Audit
                </Button>
              )}
            </Stack>

            <Stack
              justifyContent="space-between"
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
              }}
            >
              <Label variant="h6">
                Select Department .
                <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
              </Label>
            </Stack>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="stretch"
              gap="10px"
            >
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  display: { xs: "none", sm: "none", lg: "flex" },
                }}
              >
                <Typography>{`Total : ${data?.length}`}</Typography>
              </Stack>
              <Box flex="2" width={"250px"}>
                <SortSearchFilter
                  data={data}
                  setData={setData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  heading={"My Orders"}
                  Filter1="depart"
                  Filter2=""
                  FilterName1="Department"
                  FilterName2=""
                  sortDate=""
                  Grade=""
                  sortPrice=""
                  dueDate=""
                  name="depart"
                  csvName="All Assets"
                />
              </Box>
            </Box>

            <Stack
              sx={{
                width: "100%",
                height: { xs: "70%", sm: "70%", lg: "90%" },

                bgcolor: "white",
              }}
              flexDirection={"column"}
            >
              <TableContainer
                component={Paper}
                sx={{
                  height: "90%",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  width: "100%",
                }}
              >
                {currentData.length > 0 ? (
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeading></TableHeading>
                        <TableHeading>Department</TableHeading>
                        <TableHeading>Total Assets</TableHeading>
                        <TableHeading>Start Date</TableHeading>
                        <TableHeading>Audited</TableHeading>
                        <TableHeading>Done</TableHeading>
                        <TableHeading>Missed</TableHeading>
                        <TableHeading>Rating</TableHeading>
                        <TableHeading>Status</TableHeading>
                        <TableHeading>Approved Status</TableHeading>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {currentData.map((item, index) => {
                        // Ensure the values are numbers and calculate the sum
                        const audited = Number(item.audited);
                        const ignored = Number(item.ignored);
                        const tot_assets = Number(item.tot_assets);
                        const sum = audited + ignored;

                        return (
                          <TableRow key={index}>
                            <TableCells>
                              <Checkbox
                                checked={selectedAssetIds.includes(
                                  item.departid
                                )}
                                disabled={
                                  item.check_in_status === "checked_out" ||
                                  item.status === "Completed"
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    // Add departmentId to selectedAssetIds
                                    setSelectedAssetIds([
                                      ...selectedAssetIds,
                                      item.departid,
                                    ]);
                                  } else {
                                    // Remove departmentId from selectedAssetIds
                                    setSelectedAssetIds(
                                      selectedAssetIds.filter(
                                        (id) => id !== item.departid
                                      )
                                    );
                                  }
                                }}
                                onClick={(event) => {
                                  if (item.check_in_status === "checked_out") {
                                    event.stopPropagation();
                                    showModal(
                                      `The asset ${item.departid} is not available in your hospital.`
                                    );
                                  }
                                }}
                              />
                            </TableCells>

                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.depart}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.tot_assets}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.start}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.audited}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.done}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.ignored}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.rating}
                              </Link>
                            </TableCells>
                            <TableCells
                              sx={{
                                textOverflow: "clip",
                                whiteSpace: "normal",
                              }}
                            >
                              <Link
                                to={`/Add_Asset-audit-list/${auditId}/${item.departid}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.status}
                              </Link>
                            </TableCells>

                            {/* Conditionally render the Request button if sum equals tot_assets */}
                            <TableCells>
                              {sum === tot_assets &&
                              sum !== 0 &&
                              item.status === "Completed" &&
                              item.apprv_status !== "Pending" &&
                              item.apprv_status !== "Approved" ? (
                                <Button
                                  onClick={() =>
                                    handleOpenRequestApproval(
                                      auditId,
                                      item.departid
                                    )
                                  } // Pass the values to handleOpenRequestApproval
                                  sx={{
                                    bgcolor: "rgba(255, 107, 0, 1)",
                                    border: "1px solid rgba(255, 107, 0, 1)",
                                    color: "white",
                                    "&:hover": {
                                      bgcolor: "rgba(255, 107, 0, 1)",
                                      border: "1px solid rgba(255, 107, 0, 1)",
                                      color: "white",
                                    },
                                    width: "100px",
                                  }}
                                  disabled={
                                    userAccess?.role === "Guest" ||
                                    userAccess?.role === "Branch"
                                  }
                                >
                                  Request
                                </Button>
                              ) : item.apprv_status === "Pending" ? (
                                <Button
                                  sx={{
                                    bgcolor: "rgba(255, 107, 0, 1)",
                                    border: "1px solid rgba(255, 107, 0, 1)",
                                    color: "white",
                                    "&:hover": {
                                      bgcolor: "rgba(255, 107, 0, 1)",
                                      border: "1px solid rgba(255, 107, 0, 1)",
                                      color: "white",
                                    },
                                    width: "100px",
                                  }}
                                  onClick={() =>
                                    handleOpenApproveRequest(
                                      auditId,
                                      item.departid
                                    )
                                  }
                                  disabled={
                                    userAccess?.role === "Guest" ||
                                    userAccess?.role === "Branch"
                                  }
                                >
                                  Approve
                                </Button>
                              ) : (
                                item.apprv_status !== "NA" && "Approved"
                              )}
                            </TableCells>

                            {/* <TableCells>
                              {sum === tot_assets && sum !== 0 ? (
                                <Button
                                  onClick={() =>
                                    handleRequestApproval(
                                      auditId,
                                      item.departid
                                    )
                                  }
                                  sx={{
                                    bgcolor: "rgba(255, 107, 0, 1)",
                                    border: "1px solid rgba(255, 107, 0, 1)",
                                    color: "white",
                                    "&:hover": {
                                      bgcolor: "rgba(255, 107, 0, 1)",
                                      border: "1px solid rgba(255, 107, 0, 1)",
                                      color: "white",
                                    },
                                    width: "100px",
                                  }}
                                >
                                  Request
                                </Button>
                              ) : item.apprv_status === "Approved" ? (
                                <Button
                                  sx={{
                                    bgcolor: "rgba(255, 107, 0, 1)",
                                    border: "1px solid rgba(255, 107, 0, 1)",
                                    color: "white",
                                    "&:hover": {
                                      bgcolor: "rgba(255, 107, 0, 1)",
                                      border: "1px solid rgba(255, 107, 0, 1)",
                                      color: "white",
                                    },
                                    width: "100px",
                                  }}
                                >
                                  Approve
                                </Button>
                              ) : (
                                item.apprv_status !== "NA" && "Approved"
                              )}
                            </TableCells> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <p>No data available</p>
                )}
              </TableContainer>
              <Stack
                sx={{
                  padding: "20px",
                  bgcolor: "white",
                  gap: "20px",
                }}
              >
                <Divider />
                <Stack
                  sx={{
                    bgcolor: "white",
                    alignItems: "center",

                    justifyContent: "end",
                    flexDirection: "row",
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredData.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  ></Pagination>
                  {/* <Button
                                onClick={handleButtonClick}
                                variant="contained"
                                sx={{
                                  borderRadius: "22px",
                                  background: "rgba(70, 144, 255, 1)",
                                }}
                              >
                                Depreciation <Calculate sx={{ marginLeft: "10px" }} />
                              </Button> */}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </OuterSet>
      </Box>

      <Modal open={assetRequest} onClose={() => setassetRequest(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirmation
            </Typography>
            <CloseIcon
              onClick={() => setassetRequest(false)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to Request the Asset?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setassetRequest(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRequestApproval}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={assetApproved} onClose={() => setassetApproved(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirmation
            </Typography>
            <CloseIcon
              onClick={() => setassetApproved(false)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to Approve the Asset?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setassetApproved(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApproveRequest}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={auditcomplted} onClose={() => setauditcomplted(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirmation
            </Typography>
            <CloseIcon
              onClick={() => setauditcomplted(false)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to Completed the selected Department?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setauditcomplted(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCompletedAudit}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddDepartmentAudit;
