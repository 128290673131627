import * as React from "react";
import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IncidentListPage from "./IncidentListPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
export default function IncidentMobile() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [incidentPageCount, setIncidentPageCount] = React.useState({
    incident: 0,
    checkin: 0,
    checkout: 0,
    services: 0,
  });

  const incidentList = useSelector((store) => store?.data?.incidentData);
  const [dataCount, setDataCount] = useState({});

  useEffect(() => {
    if (Object.keys(incidentList)?.length > 0) {
      setData(incidentList?.incidents);
      let data = {
        incidentReported: incidentList?.incid_report_count,
        checkin: incidentList?.checkin_count,
        serviceOpen: incidentList?.serv_open_count,
        serviceTotal: incidentList?.serv_tot_count,
      };
      setDataCount(data);
    }
  }, [incidentList]);

  const handleDataChange = (childData) => {
    setIncidentPageCount({
      incident: childData.incident,
      checkin: childData.checkin_count,
      checkout: childData.checkout,
      services: childData.services,
    });
  };
  const handleLabelClick = () => {
    navigate("/contact");
  };
  return (
    <>
      <Stack
        sx={{
          width: "100%",

          marginTop: "-11px",

          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          bgcolor: "#f5f5f5",
          gap: "30px",
          paddingBottom: "11px",
        }}
      >
        <Stack
          sx={{
            height: "130px",
            bgcolor: "#1B2535",
            width: "100%",
            color: "white",
          }}
        >
          <Stack
            justifyContent={"space-between"}
            sx={{
              flexDirection: "row",
              p: 2,
              alignItems: "end",
              height: "90%",
            }}
          >
            <Label variant="h4">Incident.</Label>
            <Label  onClick={() => navigate("/calendar")} variant="h6">Request</Label>

          </Stack>
        </Stack>
        <Box
          sx={{
            width: "80%",
            height: "50%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "50%",
              gap: "10px",
            }}
          >
            <Stack
              onClick={() => navigate("/incident-mobile")}
              sx={{
                cursor: "pointer",
                width: "48%",
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "10px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {incidentPageCount.incident}
              </Label>
              <Label variant="h6">Incidents</Label>
            </Stack>
            <Stack
              onClick={() => navigate("/open-incident")}
              sx={{
                width: "48%",
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "10px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {incidentList.incid_report_count}
              </Label>
              <Label variant="h6">Open </Label>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "50%",
              gap: "10px",
            }}
          >
            <Stack
              onClick={() => navigate("/service-list")}
              sx={{
                width: "48%",
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "10px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {incidentList.serv_tot_count}
              </Label>
              <Label variant="h6">Work order</Label>
            </Stack>
            <Stack
              onClick={() => navigate("/check-in")}
              sx={{
                width: "48%",
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "10px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {incidentList.checkin_count}
              </Label>
              <Label variant="h6">Check In</Label>
            </Stack>
          </Box>
        </Box>
        <Divider sx={{ width: "90%" }} />
    
      </Stack>

      <IncidentListPage handleDataChange={handleDataChange} />
    </>
  );
}
