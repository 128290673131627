import {
  Autocomplete,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { postVendor, retrieveAllAssets } from "../redux/data/action";
import SuccessModal from "../components/SuccessModal";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
  width: "100%",
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const AddVendor = ({ handleCloseDepart }) => {
  const icon = <Checkbox fontSize="small" />;
  const checkedIcon = <Checkbox fontSize="small" checked />;
  const allAssets = useSelector((store) => store.data.allAssets);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [flag, setFlag] = React.useState(false);
  const [isAssetNameError, setIsAssetNameError] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");

  const [selectedAssets, setSelectedAssets] = React.useState([]);

  const handleAssetSelection = (event, newValue) => {
    setSelectedAssets(newValue);
  };

  const [toggle, setToggle] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    setEmail("");

    setEmailError("");
    setPhone("");
    setPhoneError("");
    setName("");
    setNameError("");
    setIsAssetNameError("");
    setSelectedAssets([]);
  };
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
      userrole:userAccess?.role,
    };

    dispatch(retrieveAllAssets(data));
  }, [dispatch]);

  const handleEmail = (e) => {
    const value = e.target.value;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Wrong email");
    }
    if (value === "") {
      setEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const regex = /^\d{10}$/;
    setPhone(value);
    if (value === "" || regex.test(value)) {
      setPhone(value);
      setPhoneError("");
    }
    if (value !== "" && !regex.test(value)) {
      setPhoneError("Invalid Phone Number");
    }
    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;
    setName(value);
    if (value === "" || regex.test(value)) {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Invalid name");
    }
    if (value === "") {
      setNameError("Please fill the field");
    }
  };

  const handleSubmit = () => {
    // Block submission immediately if name is empty
    if (name.trim() === "") {
      setNameError("Please fill the field");
      return; // stop the function
    }
  
    // Proceed only if all other validations are passed
    if (
      phoneError === "" &&
      emailError === "" &&
      nameError === "" &&
      isAssetNameError === "" &&
      email.trim() !== "" &&
      phone.trim() !== "" &&
      selectedAssets.length > 0
    ) {
      let data = {
        hospid: userIds?.hospid,
        hospname: userIds?.hospname,
        userid: userIds?.userid,
        username: userIds?.username,
        compname: name.trim(),
        mobile: phone.trim(),
        email: email.trim(),
        branchid: userBranch?.id,
        branchname: userBranch.branch,
        assetname:
          selectedAssets.length === 0
            ? "NA"
            : selectedAssets.map((item) => item.name),
        assetcode:
          selectedAssets.length === 0
            ? "NA"
            : selectedAssets.map((item) => item.assetcode),
        assetid:
          selectedAssets.length === 0
            ? "NA"
            : selectedAssets.map((item) => item.assetid),
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };
  
      dispatch(postVendor(data));
      setOpenModal(true);
      setToggle(true);
    }
  };
  
  

  return (
    <>
      <Stack
        sx={{
          width: { xs: "100%", sm: "100%", md: "40%" },
          gap: "10px",
          height: "100%",
        }}>
        <Stack
          flexDirection={"row"}
          height="50px"
          justifyContent={"space-between"}
          alignItems={"center"}
          padding="16px"
          gap={"10px"}
          sx={{
            bgcolor: "white",
            boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
          }}>
          <Typography style={{ color: "rgba(70, 144, 255, 1)" }}>
            Add New Vendor
          </Typography>
          <IconButton color="primary" onClick={handleCloseDepart}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack
          sx={{
            bgcolor: "white",
            alignItems: "start",
            padding: "15px",

            gap: "10px",
            textAlign: "start",
            justifyContent: "start",

            height: "100%",
          }}>
          <Typography>Details</Typography>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(70, 144, 255, 1)",
              margin: "5px 0",
            }}>
            <Divider />
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Field
              value={name}
              onChange={handleName}
              placeholder="Company Name *"
            />
            <HelperText>{nameError}</HelperText>
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Field
              value={phone}
              onChange={handlePhone}
              placeholder="Phone Number *"
            />
            <HelperText>{phoneError}</HelperText>
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Field value={email} onChange={handleEmail} placeholder="Email *" />
            <HelperText>{emailError}</HelperText>
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={allAssets}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={selectedAssets}
              onChange={handleAssetSelection}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    size="small"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography variant="subtitle2">
                    {option.name} ({option.assetcode})
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <Field
                  {...params}
                  placeholder="Select asset *"
                />
              )}
            />
          </Stack>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(70, 144, 255, 1)",
              margin: "5px 0",
            }}>
            <Divider />
          </Stack>
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={userAccess?.role === "Guest"}
              style={{ width: "100px", borderRadius: "22px" }}>
              {" "}
              Submit
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"New Vendor created"}
        subText2={"  "}
      />
    </>
  );
};

export default AddVendor;
