import React, { useCallback, useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import vajraBlack from "../images/vajraBlack.png";
import jsPDF from "jspdf";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import html2pdf from "html2pdf.js";
import {
  Box,
  OutlinedInput,
  Typography,
  styled,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyReport } from "../redux/data/action";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "130px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  height: "100%",
  width: "33%",
  padding: theme.spacing(2),
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  // background:'white',
  // border:'1px solid red',
  width: "100%",
  height: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopRight = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "white",
  // border: "1px solid red",
  justifyContent: "center",
  alignItems: "center",
  width: "30%",
  height: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  background: "white",
  // border: "1px solid red",
  alignItems: "center",
  width: "100%",
  height: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopInnerLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "white",
  // border: "1px solid red",
  paddingLeft: "10px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopInnerRight = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "white",
  // border: "1px solid red",
  paddingLeft: "10px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  background: "white",
  // border:'3px solid red',
  width: "100%",
  // height: "497mm",
  height: "auto",
  padding: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: { width: "86%" },
  [theme.breakpoints.down("xs")]: { width: "86%" },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCard = styled(Box)(({ theme }) => ({
  width: "90px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SecondTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  // border:'1px solid red',
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBoxLeft = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  // border:'1px solid blue',
  flexDirection: "column",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBoxRight = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  // border:'1px solid blue',
  flexDirection: "column",
  gap: "10px",
  justifyContent: "end",
  alignItems: "end",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Title3 = styled(Box)(({ theme }) => ({
  width: "100%",
  // border:'1px solid red',
  display: "flex",
  gap: "30px",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const GraphBox = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftGraphBox = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // border:'1px solid red',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightGraphBox = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "300px",
  display: "flex",
  alignItemes: "start",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FullGraph = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "300px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  // border:'2px solid red',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableSet = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "300px",
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  // border:'1px solid red',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubTableSet = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "300px",
  padding: "10px",
  border: "1px solid #848484",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentTableSet = styled(Box)(({ theme }) => ({
  width: "auto",

  padding: "10px",
  border: "1px solid #848484",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentSubTableSet = styled(Box)(({ theme }) => ({
  width: "auto",
  padding: "10px",
  border: "1px solid #848484",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DonutChart = () => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const monthlyReport = useSelector((store) => store.data.monthlyReport) || [];
  // console.log(monthlyReport);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [toggle, setToggle] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const [piChart, setPiChart] = useState([]);
  const [incidentData, setIncidentData] = useState([]);
  const [incidentPerDep, setIncidentPerDep] = useState([]);
  const [servicePerDep, setServicePerDep] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceChart, setServiceChart] = useState([]);
  const [incidentChart, setIncidentChart] = useState([]);
  const [assetTrackingChart, setAssetTrackingChart] = useState([]);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });
  const [downtimeIncident, setDowntimeIncident] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });
  const [monthFromUrl, setMonthFromUrl] = useState(null);
  const [yearFromUrl, setYearFromUrl] = useState(null);

  const [monthName, setMonthName] = useState(null);

  const tableRef = useRef(null);
  const chartRef1 = useRef(null);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const navigate = useNavigate();

  const prevDateRef = useRef(null);
  const timeoutRef = useRef(null);

  const handlePrintingScreen = () => {
    setToggle(true);
    setTimeout(() => {
      // generatePDF();
      setToggle(false);
    }, 4000);
  };


  const handleDownloadPdf = async () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [595, 842], // A4 size
    });

    const logoUrl = vajraBlack;

    const logoHeight = 40;
    const logoMarginTop = 20;

    const addHeaderLogo = (doc, logoUrl) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const logoWidth = 80;
      const logoX = (pageWidth - logoWidth) / 2;

      doc.addImage(logoUrl, "PNG", logoX, 10, logoWidth, logoHeight);

      doc.setLineWidth(1);
      doc.line(15, logoHeight + 20, pageWidth - 15, logoHeight + 20);
    };

    addHeaderLogo(doc, logoUrl);

    // const row1Column1 = `Monthly rating: ${(
    //   monthlyReport?.monthly_rating || ""
    // ).toString()}`;
    const row1Column1 = `${monthName} Month Report`;
    const row1Column2 = `Hospital: ${userIds?.hospname || ""}`;
    const row2Column1 = `Total assets: ${(
      monthlyReport?.total_assets || ""
    ).toString()}`;
    const row2Column2 = `Branch: ${userBranch?.branch || ""}`;

    const pageWidth = doc.internal.pageSize.getWidth();
    const marginLeft = 20;
    const marginRight = 20;
    const rowHeight = 20;

    let currentY = logoHeight + logoMarginTop + 30;

    doc.setFont("Helvetica", "400");

    doc.text(row1Column1, marginLeft, currentY);
    doc.text(
      row1Column2,
      pageWidth - marginRight - doc.getTextWidth(row1Column2),
      currentY
    );

    currentY += rowHeight;

    doc.text(row2Column1, marginLeft, currentY);
    doc.text(
      row2Column2,
      pageWidth - marginRight - doc.getTextWidth(row2Column2),
      currentY
    );

    currentY += rowHeight + 10;

    doc.text("Overview:", marginLeft, currentY);
    const chartElement = document.getElementById("chartSection");

    const chartWidth = chartElement.clientWidth;
    const chartHeight = chartElement.clientHeight;

    const maxChartWidth = 565;
    const maxChartHeight = 400;

    const aspectRatio = chartWidth / chartHeight;
    let chartDisplayWidth = maxChartWidth;
    let chartDisplayHeight = chartDisplayWidth / aspectRatio;

    if (chartDisplayHeight > maxChartHeight) {
      chartDisplayHeight = maxChartHeight;
      chartDisplayWidth = chartDisplayHeight * aspectRatio;
    }

    const chartCanvas = await html2canvas(chartElement, { scale: 2 });
    const chartImageData = chartCanvas.toDataURL("image/png");

    doc.addImage(
      chartImageData,
      "PNG",
      15,
      currentY,
      chartDisplayWidth,
      chartDisplayHeight
    );

    const notWorking = `Not working assets: ${monthlyReport?.not_working_assets}`;
    const totalDowntime = `Total Downtime: ${convertTime()}`;
    // const totalDowntime = `Total Downtime: 1 year 5 months 6 daya 14hrs 2 min 42 secs`;

    currentY += chartDisplayHeight + 10;
    doc.text(notWorking, marginLeft, currentY);
    // doc.text(
    //   totalDowntime,
    //   pageWidth - marginRight - doc.getTextWidth(totalDowntime),
    //   currentY
    // );
    const maxWidth = pageWidth - marginLeft - marginRight;

    const totalDowntimeLines = doc.splitTextToSize(totalDowntime, maxWidth);

    totalDowntimeLines.forEach((line, index) => {
      doc.text(
        line,
        pageWidth - marginRight - doc.getTextWidth(line),
        currentY + index * 6
      );
    });

    const firstTableStartY = currentY + 20;

    const firstTableBody = [
      [
        `${monthName}, ${yearFromUrl}`,
        {
          content: `Monthly rating: ${monthlyReport?.monthly_rating}`,
          colSpan: 4,
        },
      ],
      [
        "Total Assets",
        { content: `Total assets: ${monthlyReport?.total_assets}`, colSpan: 4 },
      ],
      ["", "Not working", "", "Working", "Discarded"],
      [
        "",
        `${monthlyReport?.not_working_assets}`,
        "",
        { content: `${monthlyReport?.total[0]?.[1]}`, rowSpan: 6 },
        { content: `${monthlyReport.total[2]?.[1]}`, rowSpan: 6 },
      ],
      ["Total Downtime", { content: `${convertTime()}`, colSpan: 2 }],
      ["", "Incidents", "Services", "", ""],
      [
        "Open",
        `${monthlyReport?.incidents?.open_incident}`,
        `${monthlyReport?.services?.open_service}`,
        "",
        "",
      ],
      [
        "Closed",
        `${monthlyReport?.incidents?.closed_incident}`,
        `${monthlyReport?.services?.closed_service}`,
        "",
        "",
      ],
      [
        "Budget Spent",
        `${monthlyReport?.incidents?.budget_spent}`,
        `${monthlyReport?.services?.budget_spent}`,
        "",
        "",
      ],
      [
        "Requested",
        "-",
        `Vajra: ${monthlyReport?.services?.from_vajra}, Others: ${monthlyReport?.services?.from_others}`,
        "",
        "",
      ],
      ["", "Check-Outs", "Check-Ins", "", ""],
      [
        "",
        `${monthlyReport?.checkinout?.checkoutcount}`,
        `${monthlyReport?.checkinout?.checkincount}`,
        "",
        "",
      ],
    ];

    doc.autoTable({
      body: firstTableBody,
      startY: firstTableStartY,
      margin: { top: 20, left: 15, right: 15 },
      pageBreak: "auto",
      didDrawPage: (data) => {
        addHeaderLogo(doc, logoUrl);
      },
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      didParseCell: (data) => {
        const value = data.cell.raw?.toString().trim();
        if (
          value.includes("Monthly rating") ||
          value.includes("Total Assets") ||
          value.includes("Not working") ||
          value.includes("Working") ||
          value.includes("Discarded") ||
          value.includes("Total Downtime") ||
          value.includes("Incidents") ||
          value.includes("Services") ||
          value.includes("Open") ||
          value.includes("Closed") ||
          value.includes("Budget Spent") ||
          value.includes("Requested") ||
          value.includes("Check-Outs") ||
          value.includes("Check-Ins") ||
          value.includes("Medpick") ||
          value.includes("Others") ||
          value === monthName ||
          value === yearFromUrl
        ) {
          data.cell.styles.fontStyle = "bold";
        }
      },
      margin: { top: logoHeight + logoMarginTop + 40 },
    });

    doc.text(
      `Incident Reported: ${monthlyReport?.incidents?.reported_incident}`,
      marginLeft,
      currentY + firstTableStartY + 10
    );
    currentY = doc.lastAutoTable.finalY + 55;

    

    const incidentElement = document.getElementById("incident-reported-graph");

    // Get the rendered width and height of the chart element
    const incidentChartWidth = incidentElement.offsetWidth; // Use offsetWidth instead of clientWidth to account for margins and borders
    const incidentChartHeight = incidentElement.offsetHeight; // Use offsetHeight for the actual height of the element

    // Set maximum dimensions
    const maxIncidentChartWidth = 565;
    const maxIncidentChartHeight = 400;

    // Calculate aspect ratio
    const aspectRatioIncident = incidentChartWidth / incidentChartHeight;

    // Initialize display dimensions with actual chart size
    let incidentChartDisplayWidth = incidentChartWidth;
    let incidentChartDisplayHeight = incidentChartHeight;

    // Adjust the width and height if they exceed maximum dimensions
    if (incidentChartDisplayWidth > maxIncidentChartWidth) {
      incidentChartDisplayWidth = maxIncidentChartWidth;
      incidentChartDisplayHeight =
        incidentChartDisplayWidth / aspectRatioIncident;
    }

    if (incidentChartDisplayHeight > maxIncidentChartHeight) {
      incidentChartDisplayHeight = maxIncidentChartHeight;
      incidentChartDisplayWidth =
        incidentChartDisplayHeight * aspectRatioIncident;
    }

    // Use html2canvas to capture the element
    const chartIncidentCanvas = await html2canvas(incidentElement, {
      scale: 2, // Adjust scale if needed for better resolution
    });

    // Convert the canvas to an image
    const chartIncidentImageData = chartIncidentCanvas.toDataURL("image/png");

    // Add the image to the PDF
    doc.addImage(
      chartIncidentImageData,
      "PNG",
      15, // X-axis position in the PDF
      currentY, // Y-axis position in the PDF
      incidentChartDisplayWidth, // Display width in the PDF
      incidentChartDisplayHeight // Display height in the PDF
    );

    currentY += incidentChartDisplayHeight + 10;

    const secondTableStartY = currentY;
    

    const incidents_reported_per_department =
      monthlyReport?.incidents_reported_per_department || [];

    const generateDepartmentTables = () => {
      const marginBottom = 20;
      let currentY = secondTableStartY;

      incidents_reported_per_department.forEach((department, index) => {
        const pageHeight = doc.internal.pageSize.height;
        const marginBottom = 20;
        const rowHeight = 10;

        const tableHeaders = [
          [
            { content: `Department: ${department?.department}`, rowSpan: 1 },
            {
              content: `Incident Reported: ${department.incident_reported}`,
              colSpan: 1,
            },
            {
              content: `Open Incidents: ${department.open_incident}`,
              colSpan: 1,
            },
            {
              content: `Total Downtime: ${department.total_downtime}`,
              colSpan: 3,
            },
          ],
          [
            { content: "Asset Name" },
            { content: "Code" },
            { content: "Status" },
            { content: "Incident Details" },
            { content: "Incident cleared details" },
            { content: "Downtime" },
          ],
        ];

        const tableBody = department.assets.map((asset) => [
          asset?.asset || "",
          asset?.code || "",
          asset?.status || "",

          `Date: ${asset?.report_date || "N/A"}\nTime: ${
            asset?.report_time || "N/A"
          }\nIssue: ${asset?.issue || "N/A"}`,

          `Date: ${asset?.clear_reported_date || "N/A"}\nTime: ${
            asset?.clear_reported_time || "N/A"
          }\nAction Taken: ${asset?.actiontaken || "N/A"}\nEngineer: ${
            asset?.engineer || "N/A"
          }\nMobile: ${asset?.mobile || "N/A"}\nEmail: ${
            asset?.emailid || "N/A"
          }\nExpenditure:${
            !asset?.expenditure_details_cost ||
            asset?.expenditure_details_cost === ""
              ? "N/A"
              : asset?.expenditure_details_cost
          }\nReason:${
            !asset?.expenditure_details_reason ||
            asset?.expenditure_details_reason === ""
              ? "N/A"
              : asset?.expenditure_details_reason
          }`,

          `Downtime:${asset?.downtime || "N/A"}\n`,
        ]);

        const estimatedTableHeight =
          tableBody.length * rowHeight + tableHeaders.length * rowHeight;

        if (currentY + estimatedTableHeight > pageHeight - marginBottom) {
          doc.addPage();
          currentY = 40;
        }

        doc.autoTable({
          head: tableHeaders,
          body: tableBody,
          startY: currentY + 30,
          margin: { top: logoHeight + logoMarginTop + 40 },
          columnStyles: {
            0: { cellWidth: "auto" },
            1: { cellWidth: "auto" },
          },
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.5,
            fontSize: 10,
          },
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            fontStyle: "bold",
          },
          bodyStyles: {
            fillColor: [255, 255, 255],
            lineColor: [0, 0, 0],
            lineWidth: 0.5,
          },
          alternateRowStyles: { fillColor: [255, 255, 255] },
          didDrawPage: (data) => {
            addHeaderLogo(doc, logoUrl);
          },
        });

        currentY = doc.lastAutoTable.finalY + 10;
      });

      const finalY = doc.lastAutoTable.finalY + 10;

      if (finalY > doc.internal.pageSize.height - marginBottom) {
        doc.addPage();
        currentY = 40;
      } else {
        currentY = finalY;
      }

      doc.text(
        `Services Reported: ${
          monthlyReport?.services?.reported_service || "N/A"
        }`,
        marginLeft,
        currentY + 10
      );
    };
    generateDepartmentTables();

    currentY = doc.lastAutoTable.finalY + 10;

    const pageHeight = doc.internal.pageSize.height;
    const remainingHeight = pageHeight - currentY;

    
    const servicesElement = document.getElementById("services-requested-graph");

    // Get the rendered width and height of the chart element
    const servicesChartWidth = servicesElement.offsetWidth; // Use offsetWidth instead of clientWidth to account for margins and borders
    const servicesChartHeight = servicesElement.offsetHeight; // Use offsetHeight for the actual height of the element

    // Set maximum dimensions
    const maxServicesChartWidth = 565;
    const maxServicesChartHeight = 400;

    // Calculate aspect ratio
    const aspectRatioServices = servicesChartWidth / servicesChartHeight;

    // Initialize display dimensions with actual chart size
    let servicesChartDisplayWidth = servicesChartWidth;
    let servicesChartDisplayHeight = servicesChartHeight;

    // Adjust the width and height if they exceed maximum dimensions
    if (servicesChartDisplayWidth > maxServicesChartWidth) {
      servicesChartDisplayWidth = maxServicesChartWidth;
      servicesChartDisplayHeight =
        servicesChartDisplayWidth / aspectRatioServices;
    }

    if (servicesChartDisplayHeight > maxServicesChartHeight) {
      servicesChartDisplayHeight = maxServicesChartHeight;
      servicesChartDisplayWidth =
        servicesChartDisplayHeight * aspectRatioServices;
    }

    // Check if the chart height exceeds the remaining height on the page
    if (servicesChartDisplayHeight > remainingHeight) {
      doc.addPage(); // Add a new page if there's not enough space
      currentY = 40; // Reset the Y position for the new page
      addHeaderLogo(doc, logoUrl); // Add the header/logo if necessary
    }

    // Use html2canvas to capture the element
    const chartServicesCanvas = await html2canvas(servicesElement, {
      scale: 2, // Adjust the scale if needed for better resolution
    });

    // Convert the canvas to an image
    const chartServicesImageData = chartServicesCanvas.toDataURL("image/png");

    // Add the image to the PDF
    doc.addImage(
      chartServicesImageData,
      "PNG",
      15, // X-axis position in the PDF
      currentY + 20, // Y-axis position in the PDF
      servicesChartDisplayWidth, // Display width in the PDF
      servicesChartDisplayHeight // Display height in the PDF
    );

    currentY += servicesChartDisplayHeight + 10;

    const services_reported_per_department =
      monthlyReport?.services_reported_per_department || [];

    const generateDepartmentTablesServices = () => {
      const marginBottom = 20;
      // let currentY = secondTableStartY;

      services_reported_per_department.forEach((department, index) => {
        const pageHeight = doc.internal.pageSize.height;
        const marginBottom = 20;
        const rowHeight = 10;

        const tableHeaders = [
          [
            { content: `Department: ${department?.department}`, rowSpan: 1 },
            {
              content: `Services Reported: ${department?.service_reported}`,
              colSpan: 1,
            },
            {
              content: `Open Services: ${department?.open_service}`,
              colSpan: 1,
            },
            {
              content: `Vajra: ${department?.fromvajra}`,
              colSpan: 1,
            },
            {
              content: `Others: ${department?.fromothers}`,
              colSpan: 1,
            },
          ],
          [
            { content: "Asset Name" },
            { content: "Code" },
            { content: "Status" },
            { content: "Service Details" },
            { content: "Service cleared details" },
          ],
        ];

        const tableBody = department.assets.map((asset) => [
          asset?.asset || "",
          asset?.code || "",
          asset?.status || "",

          `Date: ${asset?.report_date || "N/A"}\nTime: ${
            asset?.report_time || "N/A"
          }\nIssue: ${asset?.issue || "N/A"}`,

          `Date: ${asset?.clear_reported_date || "N/A"}\nTime: ${
            asset?.clear_reported_time || "N/A"
          }\nAction Taken: ${asset?.actiontaken || "N/A"}\nEngineer: ${
            asset?.engineer || "N/A"
          }\nMobile: ${asset?.mobile || "N/A"}\nEmail: ${
            asset?.emailid || "N/A"
          }\nExpenditure:${
            !asset?.expenditure_details_cost ||
            asset?.expenditure_details_cost === ""
              ? "N/A"
              : asset?.expenditure_details_cost
          }\nReason:${
            !asset?.expenditure_details_reason ||
            asset?.expenditure_details_reason === ""
              ? "N/A"
              : asset?.expenditure_details_reason
          }`,
        ]);

        const estimatedTableHeight =
          tableBody.length * rowHeight + tableHeaders.length * rowHeight;

        if (currentY + estimatedTableHeight > pageHeight - marginBottom) {
          doc.addPage();
          currentY = 40;
        }

        doc.autoTable({
          head: tableHeaders,
          body: tableBody,
          startY: currentY + 30,
          margin: { top: logoHeight + logoMarginTop + 40 },
          columnStyles: {
            0: { cellWidth: "auto" },
            1: { cellWidth: "auto" },
          },
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.5,
            fontSize: 10,
          },
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            fontStyle: "bold",
          },
          bodyStyles: {
            fillColor: [255, 255, 255],
            lineColor: [0, 0, 0],
            lineWidth: 0.5,
          },
          alternateRowStyles: { fillColor: [255, 255, 255] },
          didDrawPage: (data) => {
            addHeaderLogo(doc, logoUrl);
          },
        });

        currentY = doc.lastAutoTable.finalY + 10;
      });

      const finalY = doc.lastAutoTable.finalY + 10;

      if (finalY > doc.internal.pageSize.height - marginBottom) {
        doc.addPage();
        currentY = 40;
      } else {
        currentY = finalY;
      }
    };

    generateDepartmentTablesServices();

    doc.save("report.pdf");
  };

  const data = {
    checkouts: {
      checkedOut: 7,
      checkedIn: 5,
    },
    incidents: {
      reported: 15,
      totalDowntime: "2 Days, 12h : 23m : 34s",
      closed: 12,
      open: 3,
      budgetSpent: "₹10,000",
    },
    services: {
      requested: 5,
      closed: 3,
      open: 2,
      budgetSpent: "₹10,000",
      fromMedpick: 3,
      fromOthers: 2,
    },
  };

  const formattedData = piChart?.map((item, index) => {
    const colors = ["#4979d1", "#ee7743", "#f9d978"];
    return {
      id: item[0],
      value: item[1],
      color: colors[index],
      label: item[0],
    };
  });

  const formattedBarData = assetTrackingChart?.map((item, index) => {
    const colors = ["#4979d1", "#ee7743", "#f9d978"];
    return {
      id: item[2],
      open: parseInt(item[0]),
      closed: parseInt(item[1]),
      color: colors[index],
      label: item[2],
    };
  });

  const serviceBarData = serviceChart?.map((item, index) => {
    const colors = ["#4979d1", "#ee7743", "#f9d978"];
    return {
      id: item[2],
      open: parseInt(item[0]),
      closed: parseInt(item[1]),
      color: colors[index],
      label: item[2],
    };
  });

  const incidentBarData = incidentChart?.map((item, index) => {
    const colors = ["#4979d1", "#ee7743", "#f9d978"];
    return {
      id: item[2],
      open: parseInt(item[0]),
      closed: parseInt(item[1]),
      color: colors[index],
      label: item[2],
    };
  });

  const handleDataeChecker = () => {
    const date = dayjs(selectedDate);
    const month = date.format("MM");
    const year = date.format("YYYY");
    // console.log("selectedDate", year, month);
  };

  // console.log("selectedDate",selectedDate)

  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtime;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${
          totalDuration.months() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };

  const convertTimeIncident = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtimeIncident;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${
          totalDuration.months() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };


  const location = useLocation();

  useEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    const originalContent = metaViewport.content;

    metaViewport.content = 'width=1024, initial-scale=0.75';
    return () => {
      metaViewport.content = originalContent;
    };
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const month = searchParams.get("month");
    const year = searchParams.get("year");

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (month && year) {
      setMonthFromUrl(month);
      setYearFromUrl(year);
      setMonthName(month ? monthNames[parseInt(month) - 1] : "");

      const formattedMonth = month.padStart(2, "0");
      const date = dayjs(`${year}-${formattedMonth}-01`, "YYYY-MM-DD");
      setSelectedDate(date);
    }
  }, [location]);

  useEffect(() => {
    if (monthFromUrl !== null && yearFromUrl !== null) {
      const date = dayjs(selectedDate);
      const month = date.format("MM");
      const year = date.format("YYYY");

      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch.id,
        depart_ids: userAccess.departmentids,
        branchname: userBranch.branch,
        month: month,
        year: year,
        userrole:userAccess?.role,
        depart_ids:userAccess.departmentids,
      };

      dispatch(getMonthlyReport(data));
    }
  }, [selectedDate, monthFromUrl, yearFromUrl]);

  useEffect(() => {
    if (Object.keys(monthlyReport)?.length > 0) {
      setTotalData(monthlyReport);
      setPiChart(monthlyReport?.total);
      setAssetTrackingChart(monthlyReport?.asset_tracking);
      setServiceChart(monthlyReport?.service_report_graphs);
      setIncidentChart(monthlyReport?.incident_report_graphs);
      setDowntime(monthlyReport?.total_downtime);
      setDowntimeIncident(monthlyReport?.incidents?.total_downtime);
      setIncidentData(monthlyReport?.incidents);
      setServiceData(monthlyReport?.services);
      setIncidentPerDep(monthlyReport?.incidents_reported_per_department);
      setServicePerDep(monthlyReport?.services_reported_per_department);
    }
  }, [monthlyReport]);

  const MyBarChart = styled(BarChart)(({ theme }) => ({
    "& .MuiChart-bar": {
      maxWidth: "30px",
    },
  }));

  const debounceNavigate = useCallback(
    (month, year) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        navigate(`/m-report?month=${month}&year=${year}`);
      }, 300);
    },
    [navigate]
  );

  useEffect(() => {
    if (selectedDate) {
      const year = selectedDate.year();
      const month = selectedDate.month() + 1;

      if (
        !prevDateRef.current ||
        !selectedDate.isSame(prevDateRef.current, "month")
      ) {
        prevDateRef.current = selectedDate;
        debounceNavigate(month, year);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [selectedDate, debounceNavigate]);

  return (
    <Wrapper ref={chartRef} sx={{}}>
      <MainContainer>
        <TopContainer>
          <TopLeft>
            <Box sx={{ paddingLeft: "10px" }}>
              <Label>Monthly Report</Label>
            </Box>

            <TopInnerRight>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month"]}
                  sx={{ background: "white" }}
                  // label="Month and Year"
                  minDate={dayjs("2020-01-01")}
                  maxDate={dayjs("2030-12-31")}
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month"]}
                  sx={{ background: "white" }}
                  minDate={dayjs("2020-01-01")}
                  maxDate={dayjs("2030-12-31")}
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                    // console.log(
                    //   "New selected date:",
                    //   newValue.format("YYYY-MM")
                    // );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </TopInnerRight>
          </TopLeft>
          {!toggle && (
            <TopRight>
              <Button
                onClick={handleDownloadPdf}
                sx={{ borderRadius: "20px" }}
                variant="contained"
              >
                Download PDF
              </Button>
            </TopRight>
          )}
        </TopContainer>

        <MainContainer>
          <ImageBox>
            <ImageCard as="img" src={vajraBlack} />
          </ImageBox>

          <SecondTitleBox>
            <TitleBoxLeft>
              <Label>Hospital: {userIds.hospname}</Label>
              <Label>Branch: {userBranch.branch}</Label>
            </TitleBoxLeft>
            <TitleBoxRight>
              <Label>Monthly rating: {totalData?.monthly_rating}</Label>
              <Label>Total assets: {totalData?.total_assets}</Label>
            </TitleBoxRight>
          </SecondTitleBox>

          <Divider />

          <Title3>
            <Label>Overview: Assets</Label>
          </Title3>

          <GraphBox id="chartSection" >
            <LeftGraphBox>
              <PieChart
                series={[
                  {
                    data: formattedData,
                    arcLabel: (item) => `${item.value}`,
                    innerRadius: 50,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={200}
              />
            </LeftGraphBox>
            <RightGraphBox>
              <Title3 sx={{ paddingLeft: "50px" }}>
                <Typography>Asset tracking</Typography>
              </Title3>

              <BarChart
     

                series={[
                  {
                    data: formattedBarData?.map((item) => item.open),
                    stack: "A",
                    label: "Open",
                    color: "#4979d1",
                  },
                  {
                    data: formattedBarData.map((item) => item.closed),
                    stack: "A",
                    label: "Closed",
                    color: "#ee7743",
                  },
                ]}
                xAxis={[
                  {
                    scaleType: "band",
                    data: formattedBarData?.map((item) => item.label),
                  },
                ]}
                barLabel={(item, context) => {
                  if ((item.value ?? 0) > 10) {
                    return "High";
                  }
                  return context.bar.height < 10
                    ? null
                    : item.value?.toString();
                }}
                // width={500}
                height={300}
              />
            </RightGraphBox>
          </GraphBox>

          <Divider />

          <Title3>
            <Label>
              Not working assets: {totalData?.not_working_assets} Assets
            </Label>

            <Label>Total Downtime: {convertTime()}</Label>
          </Title3>

          {/* <TableSet>
    <SubTableSet>
<Title3>
<Label>
Check outs:
</Label>
</Title3>
    </SubTableSet>

    <SubTableSet>
    <Title3>
    <Label>
    Incidents:
</Label>
</Title3>
    </SubTableSet>

    <SubTableSet>
    <Title3>
    <Label>
    Services:
</Label>
</Title3>
    </SubTableSet>
</TableSet> */}

          <TableContainer
            sx={{ background: "white", mb: 2, pageBreakInside: "avoid" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "200px",
                        gap: "10px",
                      }}
                    >
                      <Label>Check outs:</Label>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Checked out:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.checkinout?.checkoutcount}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Checked in:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.checkinout?.checkincount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "200px",
                        gap: "10px",
                      }}
                    >
                      <Label>Incidents:</Label>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Incidents:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.incidents?.reported_incident}{" "}
                            Reported
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Total Downtime:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>{convertTimeIncident()}</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Closed:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.incidents?.closed_incident}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Open:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.incidents?.open_incident}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Budget Spent:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            ₹{monthlyReport.incidents?.budget_spent}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "200px",
                        gap: "10px",
                      }}
                    >
                      <Label>Services:</Label>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Services:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.services?.reported_service} Requested
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Closed:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.services?.closed_service}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Open:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.services?.open_service}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>Budget Spent:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            ₹{monthlyReport.services?.budget_spent}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>From Vajra:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.services?.from_vajra}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography>From Others:</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>
                            {monthlyReport.services?.from_others}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page-break" />
          <Divider />

          <Title3>
            <Label>Incidents Reported: {incidentData?.reported_incident}</Label>
          </Title3>

          <Box
            sx={{
              width: incidentBarData?.length <= 4 ? "600px" : "100%",
              mb: 2,
              pageBreakInside: "avoid",
            }}
          >
            <GraphBox id="incident-reported-graph">
              <FullGraph>
                <MyBarChart
                  series={[
                    {
                      data: incidentBarData?.map((item) => item.open),
                      stack: "A",
                      label: "Open",
                      color: "#4979d1",
                    },
                    {
                      data: incidentBarData.map((item) => item.closed),
                      stack: "A",
                      label: "Closed",
                      color: "#ee7743",
                    },
                    // { data: [14, 6, 5, 8, 9], label: 'Series B1' },
                  ]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: incidentBarData?.map((item) => item.label),
                      categoryGapRatio: 0.8,
                      barGapRatio: 0.8,
                    },
                  ]}
                  barLabel={(item, context) => {
                    if ((item.value ?? 0) > 10) {
                      return "High";
                    }
                    return context.bar.height < 10
                      ? null
                      : item.value?.toString();
                  }}
                  // width={600}
                  height={300}
                />
              </FullGraph>
            </GraphBox>
          </Box>

          <Title3>
            <Label>Services Requested: {serviceData?.reported_service}</Label>
          </Title3>

          <Box
            sx={{
              width: serviceBarData?.length <= 4 ? "600px" : "100%",
              mb: 2,
              pageBreakInside: "avoid",
            }}
            id
          >
            <GraphBox id="services-requested-graph">
              <FullGraph>
                <BarChart
                  sx={{
                    "& .MuiChart-bar": {
                      minWidth: "10px",
                      maxWidth: "30px",
                    },
                  }}
                  series={[
                    {
                      data: serviceBarData?.map((item) => item.open),
                      stack: "A",
                      label: "Checkout",
                      color: "#4979d1",
                    },
                    {
                      data: serviceBarData.map((item) => item.closed),
                      stack: "A",
                      label: "Checkin",
                      color: "#ee7743",
                    },
                    // { data: [14, 6, 5, 8, 9], label: 'Series B1' },
                  ]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: serviceBarData?.map((item) => item.label),
                      categoryGapRatio: 0.8,
                      barGapRatio: 0.8,
                    },
                  ]}
                  barLabel={(item, context) => {
                    if ((item.value ?? 0) > 10) {
                      return "High";
                    }
                    return context.bar.height < 10
                      ? null
                      : item.value?.toString();
                  }}
                  // width={600}
                  height={300}
                />
              </FullGraph>
            </GraphBox>
          </Box>
        </MainContainer>
      </MainContainer>

      <MainContainer>
        <Divider />

        <Box sx={{ mb: 2, pageBreakBefore: "always" }}>
          <Title3>
            <Label>Incidents Reported per department -</Label>
          </Title3>

          {incidentPerDep?.map((item, index) => (
            <IncidentTableSet>
              <Title3>
                <Label sx={{ paddingLeft: "10px" }}>
                  Department {index + 1}: {item?.department}
                </Label>
              </Title3>

              <IncidentSubTableSet>
                <Title3>
                  <Label>Incidents Reported: {item?.incident_reported}</Label>
                </Title3>

                <Title3>
                  <Label>Incidents Open: {item?.open_incident}</Label>
                </Title3>

                <Title3>
                  <Label>Total Downtime: {item?.total_downtime}</Label>
                </Title3>
              </IncidentSubTableSet>

              {item?.assets.map((item2, index2) => (
                <>
                  <IncidentSubTableSet>
                    <Title3>
                      <Label>
                        Asset {index2 + 1}: {item2?.asset}
                      </Label>
                    </Title3>
                    <Title3>
                      <Label>Code: {item2?.code}</Label>
                    </Title3>
                  </IncidentSubTableSet>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "200px",
                                gap: "10px",
                              }}
                            >
                              <Label>Incident Details:</Label>
                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Date:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.report_date}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Time:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.report_time}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Issue:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.issue}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Downtime:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.downtime}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Status:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.status}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "200px",
                                gap: "10px",
                              }}
                            >
                              <Label> Incident cleared details: </Label>
                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Date:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>
                                    {item2?.clear_reported_date === "NA"
                                      ? "NA"
                                      : `${item2?.clear_reported_date} Reported`}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Time:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>
                                    {item2?.report_time}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Action Taken:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.actiontaken}</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Engineer:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.engineer}</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Mobile No:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.mobile}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Email ID:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.emailid}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell>
                            {item2?.expenditure_details_cost?.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  minHeight: "200px",
                                  gap: "10px",
                                }}
                              >
                                <Label>Expenditure details:</Label>
                                <Divider />

                                {item2?.expenditure_details_cost?.map(
                                  (cost, index) => (
                                    <Box key={index}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>Cost:</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>{cost}</Typography>
                                        </Box>
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>Reason:</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>
                                            {
                                              item2
                                                ?.expenditure_details_reason?.[
                                                index
                                              ]
                                            }
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )
                                )}
                              </Box>
                            ) : (
                              <Typography sx={{ textAlign: "center" }}>
                                No expenditure available
                              </Typography>
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ))}
            </IncidentTableSet>
          ))}
        </Box>

        <Box sx={{ mb: 2, pageBreakBefore: "always" }}>
          <Title3>
            <Label>Services Reported per department -</Label>
          </Title3>

          {servicePerDep?.map((item, index) => (
            <IncidentTableSet>
              <Title3>
                <Label sx={{ paddingLeft: "10px" }}>
                  Department {index + 1}: {item?.department}
                </Label>
              </Title3>

              <IncidentSubTableSet>
                <Title3>
                  <Label>Services Reported: {item?.service_reported}</Label>
                </Title3>

                <Title3>
                  <Label>Services Open: {item?.open_service}</Label>
                </Title3>

                <Title3>
                  <Label>From Vajra: {item?.fromvajra}</Label>
                  <Label>From Others: {item?.fromothers}</Label>
                </Title3>
              </IncidentSubTableSet>

              {item?.assets.map((item2, index2) => (
                <>
                  <IncidentSubTableSet>
                    <Title3>
                      <Label>
                        Asset {index2 + 1}: {item2?.asset}
                      </Label>
                    </Title3>
                    <Title3>
                      <Label>Code: {item2?.code}</Label>
                    </Title3>
                  </IncidentSubTableSet>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "200px",
                                gap: "10px",
                              }}
                            >
                              <Label>Service Details:</Label>
                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Date:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.report_date}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Time:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.report_time}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Issue:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.issue}</Typography>
                                </Box>
                              </Box>

                              {/* <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Downtime:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.downtime}</Typography>
                                </Box>
                              </Box> */}

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Status:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.status}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "200px",
                                gap: "10px",
                              }}
                            >
                              <Label> Service cleared details: </Label>
                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Date:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>
                                    {item2?.clear_reported_date === "NA"
                                      ? "NA"
                                      : `${item2?.clear_reported_date} Reported`}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Reported Time:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>
                                    {item2?.report_time}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Action Taken:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.actiontaken}</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Engineer:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.engineer}</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Mobile No:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.mobile}</Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography>Email ID:</Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <Typography>{item2?.emailid}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell>
                            {item2?.expenditure_details_cost?.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  minHeight: "200px",
                                  gap: "10px",
                                }}
                              >
                                <Label>Expenditure details:</Label>
                                <Divider />

                                {item2?.expenditure_details_cost?.map(
                                  (cost, index) => (
                                    <Box key={index}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>Cost:</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>{cost}</Typography>
                                        </Box>
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>Reason:</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%" }}>
                                          <Typography>
                                            {
                                              item2
                                                ?.expenditure_details_reason?.[
                                                index
                                              ]
                                            }
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )
                                )}
                              </Box>
                            ) : (
                              <Typography sx={{ textAlign: "center" }}>
                                No expenditure available
                              </Typography>
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ))}
            </IncidentTableSet>
          ))}
        </Box>
      </MainContainer>
    </Wrapper>
  );
};

export default DonutChart;
