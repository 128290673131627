import React from "react";
import {
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  ListItemText,
  Stack,
  getTypographyUtilityClass,
  useMediaQuery,
  useTheme,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "50px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
  gap: "10px",
  backgroundColor: "white",
  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SortSearchFilter = ({
  data,
  setFilteredData,
  heading,
  Filter1,
  sortPrice,
  Filter2,
  sortDate,
  dueDate,
  csvName,
  Grade,
  name,
  FilterName1,
  FilterName2,
  sortDate2,
}) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  // console.log("sortPrice---------->", sortPrice, data);

  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [nameSort, setNameSort] = useState(null);
  const [assetGrade, setassetGrade] = useState(null);
  const [dateSort, setDateSort] = useState(null);
  const [sortByPrice, setSortByPrice] = useState(null);
  const [dueDateSort, setDueDateSort] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const location = useLocation();
  const isAuditPage = location.pathname.startsWith("/Add_Asset-audit-list/") || 
  location.pathname.startsWith("/Add_Deparment-audit-list");

  const handleBrandCheckboxChange = (event) => {
    const brand = event.target.name;
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brand)
        ? prevSelected.filter((selected) => selected !== brand)
        : [...prevSelected, brand]
    );
  };
  const handleCategoryCheckboxChange = (event) => {
    const category = event.target.name;
    setSelectedCategory((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((selected) => selected !== category)
        : [...prevSelected, category]
    );
  };

  const category = Filter1 && [...new Set(data?.map((item) => item[Filter1]))];
  const brand = Filter2 && [...new Set(data?.map((item) => item[Filter2]))];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNameSortChange = (event) => {
    setNameSort(event.target.checked ? event.target.value : null);
  };

  const handleAssetGradeChange = (event) => {
    const value = event.target.value;
    setassetGrade((prev) => (prev === value ? "" : value)); // Toggle selection
  };

  const handleDueDateSortChange = (event) => {
    // console.log(event.target.value);
    setDueDateSort(event.target.checked ? event.target.value : null);
  };

  const handleDateSortChange = (event) => {
    setDateSort(event.target.checked ? event.target.value : null);
  };

  const handleSortByPriceChange = (event) => {
    setSortByPrice(event.target.checked ? event.target.value : null);
  };

  useEffect(() => {
    const filterData = (
      data,
      selectedCategory,
      selectedBrands,
      nameSort,
      assetGrade,
      sortByPrice,
      dateSort,
      dueDateSort
    ) => {
      let filteredData = data.filter(
        (item) =>
          selectedCategory &&
          (selectedCategory?.length === 0 ||
            selectedCategory.includes(item[Filter1])) &&
          (selectedBrands.length === 0 ||
            selectedBrands.includes(item[Filter2]))
      );
      if (searchQuery) {
        filteredData = filteredData.filter((product) => {
          const assetCodeStr = Array.isArray(product.assetcode)
            ? product.assetcode.join(", ")
            : product.assetcode || "";

          const CodeStr = Array.isArray(product.code)
            ? product.code.join(", ")
            : product.code || "";

          const serialNoStr = Array.isArray(product.serialno)
            ? product.serialno.join(", ")
            : product.serialno || "";

          const departnameStr = Array.isArray(product.departname)
            ? product.departname.join(", ")
            : product.departname || "";

          const departStr = Array.isArray(product.depart)
            ? product.depart.join(", ")
            : product.depart || "";

            const Accitems = Array.isArray(product.accessname)
            ? product.accessname.join(", ")
            : product.accessname || "";

            const nameStr = Array.isArray(product.name)
            ? product.name.join(", ")
            : product.name || "";


          return (
            product[name]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            assetCodeStr.toLowerCase().includes(searchQuery.toLowerCase()) ||
            CodeStr.toLowerCase().includes(searchQuery.toLowerCase()) ||
            serialNoStr.toLowerCase().includes(searchQuery.toLowerCase()) ||
            departnameStr.toLowerCase().includes(searchQuery.toLowerCase()) ||
            departStr.toLowerCase().includes(searchQuery.toLowerCase()) ||
            Accitems.toLowerCase().includes(searchQuery.toLowerCase()) ||
            nameStr.toLowerCase().includes(searchQuery.toLowerCase()) 


          );
        });
      }

      if (assetGrade) {
        filteredData = filteredData.filter(
          (item) => item.asset_grade === assetGrade
        );
      }

      if (nameSort === "a-z") {
        filteredData.sort((a, b) =>
          a[name].trim().localeCompare(b[name].trim())
        );
      } else if (nameSort === "z-a") {
        filteredData.sort((a, b) =>
          b[name].trim().localeCompare(a[name].trim())
        );
      }

      if (
        sortDate !== "" &&
        (dateSort === "oldest-newest" || dateSort === "newest-oldest")
      ) {
        if (dateSort === "oldest-newest") {
          filteredData.sort((a, b) => {
            const dateA = new Date(a[sortDate].split("-").reverse().join("-"));
            const dateB = new Date(b[sortDate].split("-").reverse().join("-"));
            return dateA - dateB;
          });
        } else if (dateSort === "newest-oldest") {
          filteredData.sort((a, b) => {
            const dateA = new Date(a[sortDate].split("-").reverse().join("-"));
            const dateB = new Date(b[sortDate].split("-").reverse().join("-"));
            return dateB - dateA;
          });
        }
      }
      if (
        dueDate !== "" &&
        (dueDateSort === "oldest-newest" || dueDateSort === "newest-oldest")
      ) {
        filteredData.sort((a, b) => {
          const dateA = new Date(
            a[dueDate]?.split("-").reverse().join("-") || ""
          );
          const dateB = new Date(
            b[dueDate]?.split("-").reverse().join("-") || ""
          );
          if (dueDateSort === "oldest-newest") {
            return dateA - dateB;
          } else if (dueDateSort === "newest-oldest") {
            return dateB - dateA;
          }
        });
      }

      if (
        sortPrice !== "" &&
        (sortByPrice === "lowest-highest" || sortByPrice === "highest-lowest")
      ) {
        if (sortByPrice === "lowest-highest") {
          filteredData.sort((a, b) => {
            // console.log("a.sortPrice", a[sortPrice]);
            const priceA =
              a[sortPrice] === "NA" ? Infinity : Number(a[sortPrice]);
            const priceB =
              b[sortPrice] === "NA" ? Infinity : Number(b[sortPrice]);

            return priceA - priceB;
          });
        } else if (sortByPrice === "highest-lowest") {
          filteredData.sort((a, b) => {
            const priceA =
              a[sortPrice] === "NA" ? Infinity : Number(a[sortPrice]);
            const priceB =
              b[sortPrice] === "NA" ? Infinity : Number(b[sortPrice]);

            return priceB - priceA;
          });
        }
      }

      return filteredData;
    };

    const newFilteredData = filterData(
      data,
      selectedCategory,
      selectedBrands,
      nameSort,
      assetGrade,
      sortByPrice,
      dateSort,
      dueDateSort
    );
    setFilteredData(newFilteredData);
    // console.log("newFilteredData-------------->", newFilteredData);
  }, [
    data,
    selectedCategory,
    selectedBrands,
    nameSort,
    assetGrade,
    searchQuery,
    dateSort,
    sortByPrice,
  ]);
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    data.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(data);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${csvName}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const clearSearch = () => {
    setSearchQuery(""); // Assuming you have a state setter for searchQuery
  };

  // const placeholderText =
  // location.pathname === "/all-assets" || "/department-asset-details/:id"
  //   ? "Name, Code, Serial no"
  //   : "Search…";
  const placeholderText =
  location.pathname === "/all-assets" ||
  location.pathname === "/department-asset-details/:id"
    ? "Name, Code, Serial no"
    : location.pathname === "/Accessories-list"
    ? "Search…"
    : "Search…";


  return (
    <Wrapper>
      <Search sx={{ display: "flex" }}>
        <SearchIconWrapper>
          <SearchIcon size={15} style={{ color: "" }} />
        </SearchIconWrapper>
        <StyledInputBase
           placeholder={placeholderText}
          inputProps={{ "aria-label": "search" }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {searchQuery && ( // Show clear icon only if there is text in the input
          <IconButton onClick={clearSearch} size="small">
            <ClearIcon />
          </IconButton>
        )}
      </Search>
      <Stack flexDirection={"row"}>
        <Tooltip title="Filter">
          <IconButton color="info" onClick={handleFilterClick}>
            <FilterAltIcon size={16} />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterClose}
        >
          <MenuItem
            sx={{
              paddingLeft: "20px",
            }}
          >
            <FilterAltIcon />
            <Box sx={{ marginLeft: "10px", fontWeight: "700" }}> Filter</Box>
          </MenuItem>
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider sx={{ backgroundColor: "black", width: "90%" }} />
          </Stack>

          <MenuItem
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "start",

              gap: "20px",
            }}
          >
            {category && (
              <Stack>
                <Label variant="caption">{FilterName1}</Label>

                {category?.map((category) => (
                  <FormControlLabel
                    key={category}
                    control={
                      <Checkbox
                        color="info"
                        checked={selectedCategory.includes(category)}
                        onChange={handleCategoryCheckboxChange}
                        name={category}
                      />
                    }
                    label={<Label2 variant="body2">{category}</Label2>}
                  />
                ))}
              </Stack>
            )}

            {brand && (
              <Stack>
                <Label variant="caption">{FilterName2}</Label>

                {brand?.map((brand) => (
                  <FormControlLabel
                    color="info"
                    key={brand}
                    control={
                      <Checkbox
                        checked={selectedBrands.includes(brand)}
                        onChange={handleBrandCheckboxChange}
                        name={brand}
                      />
                    }
                    label={<Label2 variant="body2">{brand}</Label2>}
                  />
                ))}
              </Stack>
            )}
          </MenuItem>
        </Menu>
        <Tooltip title="Sort">
          <IconButton color="info" onClick={handleSortClick}>
            <SwapVertIcon size={25} />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={handleSortClose}
        >
          <MenuItem
            sx={{
              paddingLeft: "20px",
            }}
          >
            <SwapVertIcon />
            <Box sx={{ marginLeft: "10px", fontWeight: "700" }}> Sort</Box>
          </MenuItem>
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider sx={{ backgroundColor: "black", width: "90%" }} />
          </Stack>

          <MenuItem
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "start",

              gap: "20px",
            }}
          >
            <Stack>
              <Label variant="body2">Name Sort</Label>

              <FormControlLabel
                control={
                  <Checkbox
                    color="info"
                    value="a-z"
                    onChange={handleNameSortChange}
                    checked={nameSort === "a-z"}
                  />
                }
                label={<Label2 variant="body2">A-Z</Label2>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="info"
                    value="z-a"
                    onChange={handleNameSortChange}
                    checked={nameSort === "z-a"}
                  />
                }
                label={<Label2 variant="body2">Z-A</Label2>}
              />
            </Stack>

            {Grade !== "" && (
              <Stack>
                <Label variant="body2">Asset Grade</Label>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="A"
                      onChange={handleAssetGradeChange}
                      checked={assetGrade === "A"} // Use Grade for checking
                    />
                  }
                  label={<Label2 variant="body2">A</Label2>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="B"
                      onChange={handleAssetGradeChange}
                      checked={assetGrade === "B"} // Use Grade for checking
                    />
                  }
                  label={<Label2 variant="body2">B</Label2>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="C"
                      onChange={handleAssetGradeChange}
                      checked={assetGrade === "C"} // Use Grade for checking
                    />
                  }
                  label={<Label2 variant="body2">C</Label2>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="D"
                      onChange={handleAssetGradeChange}
                      checked={assetGrade === "D"} // Use Grade for checking
                    />
                  }
                  label={<Label2 variant="body2">D</Label2>}
                />
              </Stack>
            )}

            {sortDate !== "" && (
              <Stack>
                <Label variant="body2">Date Sort</Label>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="oldest-newest"
                      onChange={handleDateSortChange}
                      checked={dateSort === "oldest-newest"}
                    />
                  }
                  label={<Label2 variant="body2">Oldest-Newest</Label2>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="newest-oldest"
                      onChange={handleDateSortChange}
                      checked={dateSort === "newest-oldest"}
                    />
                  }
                  label={<Label2 variant="body2">Newest-Oldest</Label2>}
                />
              </Stack>
            )}

            {dueDate !== "" && (
              <Stack>
                <Label variant="body2">End Date Sort</Label>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="oldest-newest"
                      onChange={handleDueDateSortChange}
                      checked={dueDateSort === "oldest-newest"}
                    />
                  }
                  label={<Label2 variant="body2">Oldest-Newest</Label2>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="newest-oldest"
                      onChange={handleDueDateSortChange}
                      checked={dueDateSort === "newest-oldest"}
                    />
                  }
                  label={<Label2 variant="body2">Newest-Oldest</Label2>}
                />
              </Stack>
            )}

            {sortPrice !== "" && (
              <Stack>
                <Label variant="body2">Sort by price</Label>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="lowest-highest"
                      onChange={handleSortByPriceChange}
                      checked={sortByPrice === "lowest-highest"}
                    />
                  }
                  label={<Label2 variant="body2">Lowest-highest</Label2>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="info"
                      value="highest-lowest"
                      onChange={handleSortByPriceChange}
                      checked={sortByPrice === "highest-lowest"}
                    />
                  }
                  label={<Label2 variant="body2">Highest-lowest</Label2>}
                />
              </Stack>
            )}
          </MenuItem>
        </Menu>

        <Tooltip title="Download">
          <IconButton color="info">
            {!isAuditPage && <DownloadIcon onClick={downloadCsvFile} />}
          </IconButton>
        </Tooltip>
      </Stack>
    </Wrapper>
  );
};

export default SortSearchFilter;
