import React, { useState, useEffect, useRef } from "react";
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PersonIcon from "@mui/icons-material/Person";
import { chatbot } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import { assetDetailsData } from "../redux/data/action";

const ChatBoxGPT = ({ chatId, name }) => {
  const Chatbotresponse = useSelector((store) => store.data.chatbotdata);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [selectedBranch, setSelectedBranch] = useState(userBranch);

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOkayClicked, setIsOkayClicked] = useState(false);

  const messagesEndRef = useRef(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [secondMessageSent, setSecondMessageSent] = useState(false);
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();

  const getTimeStamp = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  useEffect(() => {
    if (isChatOpen && !secondMessageSent) {
      const sendMessage = async () => {
        const firstMessageTimer = new Promise((resolve) => {
          setTimeout(() => {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                heading: "Vajra GPT",
                text: "I'm Vajra Buddy, your one-stop shop for information and assistance.",
                timestamp: getTimeStamp(),
              },
            ]);
            resolve();
          }, 1000);
        });

        await firstMessageTimer;

        const secondMessageTimer = new Promise((resolve) => {
          setTimeout(() => {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                text: (
                  <>
                    I can answer your questions, suggest products, and more.{" "}
                    <a style={{ color: "#4690FF", textDecoration: "none" }}>
                      Ask me anything!
                    </a>
                  </>
                ),
                timestamp: getTimeStamp(),
              },
            ]);
            resolve();
          }, 1000);
        });

        await secondMessageTimer;

        const thirdMessageTimer = setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              text: (
                <>
                  <p>Ask a question based on {name} Asset! </p>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={() => setIsOkayClicked(true)}
                  >
                    Okay
                  </Button>
                </>
              ),
              timestamp: getTimeStamp(),
            },
          ]);
        }, 1000);

        return () => {
          clearTimeout(thirdMessageTimer);
        };
      };

      sendMessage();
      setSecondMessageSent(true);
    }
  }, [isChatOpen, secondMessageSent, name]);

  const [input, setInput] = useState("");

  const handleChatToggle = () => {
    setIsChatOpen((prev) => !prev);
  };

  const [isSending, setIsSending] = useState(false);
  const handleSend = () => {
    if (input.trim()) {
      const userMessage = {
        sender: "user",
        text: input,
        timestamp: getTimeStamp(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const typingMessage = {
        sender: "bot",
        text: "Vajra GPT is typing...",
        timestamp: getTimeStamp(),
        typing: true,
      };
      setMessages((prevMessages) => [...prevMessages, typingMessage]);

      const payload = {
        chatid: chatId,
        query: input,
        sec_key: "9gXImj8KyEwuuahMhvyu",
      };

      setIsSending(true);

      dispatch(chatbot(payload));

      setInput("");
    }
  };

  useEffect(() => {
    if (Chatbotresponse) {
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.typing
            ? {
                sender: "bot",
                text:
                  Chatbotresponse?.response ||
                  "I'm sorry, I couldn't process that. Please try again.",
                timestamp: getTimeStamp(),
                typing: false,
              }
            : message
        )
      );

      setIsSending(false);
    }
  }, [Chatbotresponse]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const truncatedUsername = userIds.username
    ? userIds.username.slice(0, 2).toUpperCase()
    : "";

  return (
    <div>
      <Stack
        sx={{
          zIndex: "999",
          position: "fixed",
          right: "20px",
          bottom: {
            xs: "70px",
            sm: "100px",
          },
          backgroundColor: "#4690FF",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={handleChatToggle}
      >
        <CommentOutlinedIcon sx={{ color: "white" }} />
      </Stack>

      <Dialog
        open={isChatOpen}
        onClose={handleChatToggle}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            position: "fixed",
            bottom: "30px",
            right: "30px",
            margin: 0,
            borderRadius: "10px",
            height: "70%",
            zIndex: "9999",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#1B2535",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-around",
              gap: "10px",
            }}
          >
            <Avatar sx={{ width: 38, height: 38, bgcolor: "#4690FF" }}>
              <Typography variant="caption" sx={{ fontSize: "15px" }}>
                VM
              </Typography>
            </Avatar>
            <Stack sx={{ color: "white" }}>
              Vajra GPT
              <p style={{ margin: "0", fontSize: "small" }}>
                Vajra’s AI powered Chat Bot
              </p>
            </Stack>
          </Stack>
          <IconButton onClick={handleChatToggle} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ backgroundColor: "#f3f3fa" }}>
          <Stack spacing={2} sx={{ margin: "12px" }}>
            {messages.map((message, index) => (
              <Stack
                key={index}
                sx={{
                  backgroundColor:
                    message.sender === "bot" ? "white" : "#4690FF33",
                  color: "black",
                  padding: "10px",
                  borderRadius: "10px",
                  width: "auto",
                  maxWidth: "70%",
                  alignSelf:
                    message.sender === "bot" ? "flex-start" : "flex-end",
                  position: "relative",
                  "::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: message.sender === "bot" ? "-10px" : "auto",
                    right: message.sender === "bot" ? "auto" : "-10px",
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth:
                      message.sender === "bot"
                        ? "15px 10px 0 20px"
                        : "15px 18px 20px 3px",
                    borderColor:
                      message.sender === "bot"
                        ? "white transparent transparent transparent"
                        : "#d0dffb transparent transparent transparent",
                  },
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: message.sender === "bot" ? "-30px" : "auto",
                    right: message.sender === "bot" ? "auto" : "-30px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 26,
                      height: 26,
                      bgcolor: message.sender === "bot" ? "#4690FF" : "gray",
                    }}
                  >
                    {message.sender === "bot" ? (
                      <SmartToyIcon sx={{ color: "white" }} />
                    ) : (
                      <IconButton color="black" sx={{ cursor: "default" }}>
                        <Stack
                          sx={{
                            bgcolor: "#1B2535",
                            color: "white",
                            boxShadow: "0px 0px 15px 0px #DBE7E5",
                            width: "35px ",
                            height: "35px",
                            fontSize: "small",
                            justifyContent: "center",
                            userSelect: "text",
                          }}
                        >
                          {truncatedUsername}
                        </Stack>
                      </IconButton>
                    )}
                  </Avatar>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {message.heading && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        color: "#4690FF",
                        userSelect: "text",
                      }}
                    >
                      {message.heading}
                    </Typography>
                  )}
                </Stack>
                <Typography variant="body2" sx={{ userSelect: "text" }}>
                  {message.text}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: "gray",
                    alignSelf: "flex-end",
                    userSelect: "text",
                  }}
                >
                  {message.timestamp}
                </Typography>
              </Stack>
            ))}
            <div ref={messagesEndRef} />
          </Stack>
        </DialogContent>

        <Stack
          direction="row"
          sx={{ padding: "20px", backgroundColor: "#1B2535" }}
          spacing={1}
        >
          <TextField
            sx={{ borderRadius: "2px" }}
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isSending) {
                handleSend();
                e.preventDefault();
              }
            }}
          />

          <Button
            onClick={handleSend}
            disabled={isSending || !isOkayClicked}
            sx={{
              backgroundColor: isSending ? "#cccccc" : "transparent",
              color: isSending ? "#888888" : "#4690FF",
              cursor: isSending ? "not-allowed" : "pointer",
            }}
          >
            <Stack sx={{ height: "20px" }}>
              <SendRoundedIcon />
            </Stack>
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ChatBoxGPT;
