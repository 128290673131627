import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirmation from "../components/Confirmation";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import noincident from "../images/No Incident.png";

import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  Autocomplete,
  TextField,
  Modal,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import {
  incidentData,
  retrieveAllAssets,
  retrieveAllAssetsIncident,
  incidentDelete,
} from "../redux/nbmData/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import { DownloadPDF } from "../redux/data/action";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  paddingBottom: "10px",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "stretch",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(3,1fr)",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentListPage = ({ handleDataChange }) => {
  const incidentList = useSelector((store) => store?.data?.incidentData);
  const allAssets = useSelector((store) => store?.nbmData?.allAssets);
  const incidentAssets = useSelector(
    (store) => store?.nbmData?.retriveDropdownIncidentNBM
  );
  // console.log(incidentAssets)

  const [dataCount, setDataCount] = useState({});
  const navigate = useNavigate();
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [getAllData, setGetAllData] = useState([]);
  const [getId, setGetId] = useState("");
  const [assetNameError, setAssetNameError] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(incidentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(incidentList)?.length > 0) {
      setData(incidentList?.incidents);
      let data = {
        incidentReported: incidentList?.incid_report_count,
        checkin: incidentList?.checkin_count,
        serviceOpen: incidentList?.serv_open_count,
        serviceTotal: incidentList?.serv_tot_count,
      };
      setDataCount(data);
    }
  }, [incidentList]);

  const handleAssetName = (event, value) => {
    if (value) {
      const selectedAsset = getAllData?.assets?.find((asset) => {
        const fullName = `${asset.name} - ${asset.assetcode}`;
        return fullName === value;
      });

      setGetId(selectedAsset.assetid);
      setAssetNameError("");
      // console.log("selectedAsset", selectedAsset);
    }
  };

  useEffect(() => {
    if (incidentAssets && incidentAssets?.length !== 0) {
      setGetAllData(incidentAssets);
    }
  }, [incidentAssets]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: "all",
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(retrieveAllAssetsIncident(data, token));
  }, [dispatch]);

  // console.log("allAssets testtttttttttttt------------", allAssets);
  const handleNavigate = () => {
    if (getId !== "") {
      navigate(`/record-incident/${getId}`);
    } else {
      setAssetNameError("Please select the asset.");
    }
  };

  // console.log("allAssets",allAssets)
  // console.log("getAllData",getAllData)

  useEffect(() => {
    if (handleDataChange !== undefined) {
      let data2 = {
        incident: filteredData?.length,
        checkin: 29,
        checkout: 6,
        services: 32,
      };
      handleDataChange(data2);
    }
  }, [filteredData]);

  const handleDeleteList = () => {
    navigate("/deleted-incident");
  };

  const handleDeleteOpenModal = (incidid) => {
    setDeleteId(incidid);
    setIsModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsModalOpen(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = () => {
    // Find the item to delete from filteredData or incidentList based on deleteId
    const selectedItem = filteredData?.find(
      (item) => item.incidid === deleteId
    );

    const payload = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      incidid: deleteId,
      username: ids.username,
      departid: selectedItem?.departid, // Assuming the departid is in the item
      departname: selectedItem?.dept, // Assuming the departname is in the item
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(incidentDelete(payload))
      .then(() => {
        setFilteredData((prevData) =>
          prevData.filter((item) => item.incidid !== deleteId)
        );
        handleDeleteCloseModal();
      })
      .catch((error) => {
        console.error("Error deleting the incident:", error);
        handleDeleteCloseModal();
      });
  };

  const handleDelete = (incidid) => {
    handleDeleteOpenModal(incidid);
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px"
      marginTop="-15px"
    >
     <Box display="flex" justifyContent="end" sx={{display:{xs:"flex",sm:"flex",md:"flex"}}}>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{
            borderRadius: "22px",
            background: "rgba(70, 144, 255, 1)",
            marginRight: "20px",
            width:{
              xs:"100%",
              sm:"30%",
              md:"12%"
            }
          }}
        >
          Add Incident <AddIcon />
        </Button>
      </Box>
      <Stack
        justifyContent="space-between"
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
      >
        <Label variant="h6">
          Incident.
          <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
        </Label>
        {/* <Button
          onClick={handleDeleteList}
          sx={{
            bgcolor: "#1B2535",
            width: "170px",
            height: "30px",
            textAlign: "center",
            textDecoration: "none",
            borderRight: "20px",
            color: "white",
            "&:hover": {
              backgroundColor: "#1B2535",
              color: "white",
            },
            borderRadius: "5px",
            fontSize: {
              xs: "0.75rem",
              sm: "8px",
              md: "0.75rem",
              lg: "0.75rem",
              xl: "0.75rem",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DeleteIcon
            sx={{
              color: "white",
              fontSize: {
                xs: "0.75rem",
                sm: "8px",
                md: "0.75rem",
                lg: "1rem",
                xl: "1.3rem",
              },
              mr: 1,
            }}
          />
          Deleted Incident
        </Button> */}
      </Stack>

      <InnerContainer sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}>
        <Box
          flex="1"
          onClick={() => navigate("/open-incident")}
          sx={{ cursor: "pointer" }}
        >
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              {dataCount?.incidentReported}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label
                sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                variant="subtitle2"
              >
                Open Incident
              </Label>

              <ArrowRightAltIcon onClick={() => navigate("/open-incident")} />
            </Stack>
          </Stack>
        </Box>

        <Box
          flex="1"
          onClick={() => navigate("/check-in")}
          sx={{ cursor: "pointer" }}
        >
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              {dataCount?.checkin}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label
                sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                variant="subtitle2"
              >
                Check In
              </Label>

              <ArrowRightAltIcon
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/check-in")}
              />
            </Stack>
          </Stack>
        </Box>

        <Box
          flex="1"
          onClick={() => navigate("/open-service")}
          sx={{ cursor: "pointer" }}
        >
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              {dataCount?.serviceOpen}/{dataCount?.serviceTotal}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Label
                sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                variant="subtitle2"
              >
                Open Services
              </Label>

              <ArrowRightAltIcon
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/open-service")}
              />
            </Stack>
          </Stack>
        </Box>
      </InnerContainer>

      <Box display="flex" flexDirection="column" gap="10px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}
            >{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="dept"
              Filter2="incid_status"
              Grade=""
              FilterName1="Department"
              FilterName2="Status"
              sortDate="incidcleardate"
              dueDate=""
              sortPrice=""
              name="assetname"
              csvName="All Assets"
            />
          </Box>
          {/* <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Button
              onClick={handleDeleteList}
              sx={{
                bgcolor: "#1B2535",
                width: "170px",
                height: "30px",
                textAlign: "center",
                textDecoration: "none",
                borderRight: "20px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
                borderRadius: "5px",
                fontSize: {
                  xs: "0.75rem",
                  sm: "8px",
                  md: "0.75rem",
                  lg: "0.75rem",
                  xl: "0.75rem",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DeleteIcon
                sx={{
                  color: "white",
                  fontSize: {
                    xs: "0.75rem",
                    sm: "8px",
                    md: "0.75rem",
                    lg: "1rem",
                    xl: "1.3rem",
                  },
                  mr: 1,
                }}
              />
              Deleted Incident
            </Button>
          </Stack> */}
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 250px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
            ref={tableRef}
          >
            {currentData.length > 0 ? (
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 237, 237, 1)",
                      },
                    }}
                  >
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>

                    <TableHeading>Department</TableHeading>

                    <TableHeading>Date&Time</TableHeading>

                    <TableHeading>Total Downtime</TableHeading>
                    <TableHeading
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      Status
                    </TableHeading>

                    {/* <TableHeading
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Download
                  </TableHeading> */}
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflowY: "scroll" }}>
                  {currentData?.map((item, index) => (
                    <TableRow
                      key={item?.incidid}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/incident-list/${item?.incidid}`}
                      >
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/incident-list/${item?.incidid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/incident-list/${item?.incidid}`}
                      >
                        <Tooltip title={item.dept}>{item.dept}</Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/incident-list/${item?.incidid}`}
                      >
                        {" "}
                        {item.incidcleardate === item?.incidcleartime ? (
                          <>NA</>
                        ) : (
                          <>
                            <>{item?.incidcleardate}</>@
                            <>{item?.incidcleartime}</>
                          </>
                        )}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/incident-list/${item?.incidid}`}
                      >
                        {item.downtime}
                      </TableCells>
                      <TableCells
                        component={Link}
                        to={`/incident-list/${item?.incidid}`}
                        sx={{
                          textAlign: "center",
                          display: "ruby-text",

                          color: "rgba(70, 144, 255, 1)",
                        }}
                      >
                        {item?.incid_status === "Open" ? (
                          <Box
                            disabled={userAccess?.role === "Guest"}
                            size="small"
                            sx={{
                              borderRadius: " 0px 8px 0px 8px",
                              textTransform: "none",
                              width: "90px",
                              height: "25px",
                              bgcolor: "#74ACFF",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            variant="contained"
                          >
                            Open
                          </Box>
                        ) : (
                          <>Cleared</>
                        )}
                      </TableCells>

                      {/* {item?.incid_status === "Cleared" &&
                        userAccess?.role === "Owner" && (
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <DeleteIcon
                              onClick={() => handleDelete(item?.incidid)}
                              style={{ cursor: "pointer" }}
                            />
                          </TableCells>
                        )} */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  src={noincident}
                  style={{
                    height: "50%",
                    width: window.innerWidth <= 320 ? "90%" : "25%",
                  }}
                ></img>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No incident data found.
                </Typography>
              </Stack>
            )}
          </TableContainer>
          <Stack
            sx={{
              padding: "10px",
              bgcolor: "white",
              gap: "20px",
            }}
          >
            <Divider />
            <Stack
              sx={{
                bgcolor: "white",
                alignItems: "center",
                justifyContent: "end",
                flexDirection: "row",
              }}
            >
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Confirmation
        handleCloseModal={handleDeleteCloseModal}
        handleDone={handleDeleteConfirm}
        open={isModalOpen}
        text={"Are you sure you want to delete?"}
      />

      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}
            >
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Label variant="h6">Record Incident</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

              <Stack gap={"10px"}>
                <Label textAlign={"start"}>
                  Choose Equipment{" "}
                  <span style={{ color: "red", fontWeight: "100" }}>*</span>
                </Label>
                <Stack
                  flexDirection={"column"}
                  gap={"10px"}
                  textAlign={"start"}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    onChange={handleAssetName}
                    placeholder="Asset Name *"
                    options={getAllData?.assets?.map(
                      (option) => `${option.name} - ${option.assetcode}`
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Asset Name"
                        sx={{
                          width: "300px",
                          background: "rgba(244, 246, 246, 1)",
                          "& fieldset": { border: "none" },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>{assetNameError}</FormHelperText>
                </Stack>
              </Stack>
              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Record incident</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="contained"
                    onClick={handleNavigate}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>
    </Box>
  );
};

export default IncidentListPage;
