import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Box, Button, Divider } from "@mui/material";
import empty from "../images/notificationImage.png";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Description } from "@mui/icons-material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import IosShareIcon from '@mui/icons-material/IosShare';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

const Wrapper = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  alignItems:'center',

  width:'100%',

    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
    
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
}));


const TopBox = styled(Box)(({ theme }) => ({
  boxShadow: ' rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  padding:'10px',
  background:'white',
  display:'flex',
  alignItems:'center',
  width:'50%',
  height:'70px',
  [theme.breakpoints.down("xl")]: {
    width:'70%',
  },
  [theme.breakpoints.down("lg")]: {
    width:'80%',
  },
  [theme.breakpoints.down("md")]: {
    width:'100%',
    padding:'0px',

  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));




const CustomButtom = styled(Button)(({ theme }) => ({
borderRadius:'30px',
background:"#4690FF",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));



const InnerTop = styled(Box)(({ theme }) => ({
  
  width:'100%',
 
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopLeft = styled(Box)(({ theme }) => ({
  
  display:'flex',
  gap:'10px',
  alignItems:'center',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    padding:'10px'
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidSet = styled(Box)(({ theme }) => ({
  
  display:'flex',
  background:'white',

  width:'100%',
  padding:'10px',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    padding:'0px',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MainMidSet = styled(Box)(({ theme }) => ({
  boxShadow: ' rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  display:'flex',
  flexDirection:'column',
  background:'white',
  width:'50%',

  [theme.breakpoints.down("xl")]: {
    width:'70%',
  },
  [theme.breakpoints.down("lg")]: {
    width:'80%',
  },
  [theme.breakpoints.down("md")]: {
    width:'100%',
    
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptySet = styled(Box)(({ theme }) => ({
  boxShadow: ' rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  background:'white',
  gap:'30px',
  width:'50%',
  height: "calc(100vh - 200px)",
  [theme.breakpoints.down("xl")]: {
    width:'70%',
  },
  [theme.breakpoints.down("lg")]: {
    width:'80%',
  },
  [theme.breakpoints.down("md")]: {
    width:'100%',
    
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const EmptyText = styled(Box)(({ theme }) => ({
   fontWeight:'500',
   fontSize:'12px',
  [theme.breakpoints.down("xl")]: {
    
  },
  [theme.breakpoints.down("lg")]: {
   
  },
  [theme.breakpoints.down("md")]: {
    
    
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  paddingLeft:'70px',
  display:'flex',
  paddingTop:'10px',
  paddingBottom:'10px',
//  padding:'10px',

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width:'100%',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));



const MidLeftSet = styled(Box)(({ theme }) => ({
    width:'90px',
    paddingTop:'10px',
    height:'100%',
    
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerRightSet = styled(Box)(({ theme }) => ({
  
  height:'100%',
  padding:'10px',
  
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight:'600',
  fontSize:'18px',
  textAlign:'left',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CountText = styled(Typography)(({ theme }) => ({
  fontWeight:'600',
  color:'#4690FF',
  fontSize:'18px',
  textAlign:'left',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const TitleDescription = styled(Typography)(({ theme }) => ({
  fontWeight:'500',
  fontSize:'16px',
  textAlign:'left',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const MainSet = styled(Box)(({ theme }) => ({
  marginTop:'10px',
  display:'flex',
  flexDirection:'column',
  // background:"white",
  alignItems:'center',
  height: "calc(100vh - 200px)",
  width:'100%',
  gap:'10px',
  overflowY: 'auto',  
  overflowX: 'hidden',  
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
width:'100%',
padding:'0px',

  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function BottomAppBar() {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = React.useState(1);
  const navigate = useNavigate();

  let existingNotifications =
    JSON.parse(localStorage.getItem("notification")) || [];
  const totalPages = Math.ceil(existingNotifications.length / itemsPerPage);
  const paginatedNotifications = existingNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };








  const handleNavigate = () => {
    navigate("/");

  };



  let data = [
    {type:"Success",title:'Quiet Moments', description:"No notifications. Just the peaceful silence of the present."},
    
  ]

  return (
    <Box sx={{padding:'10px'}}>
    <Wrapper>

<TopBox>
<InnerTop>
<TopLeft>
  <TitleText>Notification</TitleText>
  <CountText>{data.length}</CountText>
</TopLeft>
<Box sx={{padding:'10px'}}>
<NotificationsIcon />
</Box>

</InnerTop>
</TopBox>
    
<MainSet>
{data.length>0 ? data?.map((item)=>(
  <MainMidSet>
 <MidSet>
    <MidLeftSet>
    {item.type === "Success" ? < DoneIcon style={{color:'green', fontSize:'30px' }} /> : 
       item.type === "Failure" ? <CloseIcon style={{color:'red', fontSize:'30px' }} /> : 
       item.type === "Pending" ? <IosShareIcon style={{color:'grey', fontSize:'30px' }} /> : 
       null}


    </MidLeftSet>

<InnerRightSet>

  <TitleText>
{item.title}
  </TitleText>



  <TitleDescription>
{item.description}
  </TitleDescription>

  </InnerRightSet>
  </MidSet>
  <Divider/>
  <ButtonBox>
   
  <CustomButtom variant="contained">Home</CustomButtom>
  </ButtonBox>

  </MainMidSet>
 
 )):<EmptySet>
 < NotificationImportantIcon style={{fontSize:'80px'}}/>
 <EmptyText>
 No Notifications yet.
 </EmptyText>

 <Divider  />

<Box>
 
<CustomButtom variant="contained" onClick={handleNavigate}>
 Dashboard
</CustomButtom>
</Box>


 <Box>

 </Box>
 </EmptySet>}  


</MainSet>
  

    
    </Wrapper>
    </Box>
  );
}
