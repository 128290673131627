import React from "react";
import Box from "@mui/material/Box";
// import GraphComponent from "../components/GraphComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import dayjs from "dayjs";
import coming_soon from "../images/Coming_soon.png";
import {
  Divider,
  IconButton,
  Paper,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Modal,
} from "@mui/material";
import { StackedLineChartTwoTone } from "@mui/icons-material";
import HalfPieChart from "../components/HalfPieChart";
import HalfPieChart2 from "../components/HalfPieChart2";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SquareIcon from "@mui/icons-material/Square";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment/moment";
import monthlyRating from "../images/monthlyRating.png";
import {
  getDashboard,
  getDashboardList,
  getDashboardList2,
} from "../redux/nbmData/action";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import GraphComponent from "../pages/GraphComponent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IncidentGraphComponent from "../pages/IncidentGraphComponent";
import CloseIcon from "@mui/icons-material/Close";
import { setNavigationSource } from "../redux/data/action";
import Sessionexpired from "../components/Sessionexpired";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  fontsize: "small",
}));

const TitleHeading = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  fontsize: "large",
  width: "40%",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "stretch",
  gap: "10px",
  minHeight: "90vh",
  padding: "16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  // height:'100px',

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    // height:'200px',
    width: "100%",
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const GraphTop = styled(Box)(({ theme }) => ({
  display: "grid",
  width: "100%",
  gap: "10px",
  gridTemplateColumns: "repeat(3,1fr)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(3,1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(3,1fr)",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const GraphTopCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "start",
  gap: "10px",
  height: "70px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCut = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  background: "linear-gradient(#4690FF 70%, #2A5699 100%)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopRight = styled(Box)(({ theme }) => ({
  display: "grid",
  width: "60%",
  gridTemplateColumns: "repeat(3,1fr)",
  // height:'100px',

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    gridTemplateColumn: "repeat(2,1fr)",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  // height:'100px',
  padding: "5px",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AddButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  border: "1px solid black",
  color: "black",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "stretch",
  gap: "10px",
  minHeight: "90vh",
  padding: "16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Box)(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftHalf = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopSet = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightHalf = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DowntimeBox = styled(Box)(({ theme }) => ({
  height: "130px",
  display: "flex",
  gap: "10px",
  backgroundColor: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DowntimeSplit = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  padding: "10px",
  gap: "20px",
  width: "100%",
  height: "130px",
  boxShadow: "0px 0px 15px 0px #DBE7E5",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "start",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SemiCircleBox = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  backgroundColor: "white",
  justifyContent: "space-between",
  padding: "10px",
  boxShadow: "0px 0px 15px 0px #DBE7E5",
  height: "310px",
  alignItems: "center",
  gap: "5px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "200px",
    alignItems: "start",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SplitCircle = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  display: "flex",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "90%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "0%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SemiGraphBox = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  height: "140px",
  width: "300px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentSet = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  height: "70%",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomSet = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  justifyContent: "space-between",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1,1fr)",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  background: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "200px",
  borderRadius: "6px",
}));

const DashboardPage = ({ handleDataChange, request }) => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const [incidentReport, setIncidentReport] = React.useState("day");
  const [serviceReport, setServiceReport] = React.useState("day");
  const [getServiceValue, setGetServiceValue] = React.useState("2024");
  const [getIncidentValue, setGetIncidentValue] = React.useState("2024");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [assigned, setAssigned] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  const dashboardData = useSelector((store) => store.data.dashboard);
  const [value, setValue] = React.useState("day");
  const dashboardList = useSelector((store) => store.data.dashboardList);
  const dashboardList2 = useSelector((store) => store.data.dashboardList2);
  const [selectedGraph, setSelectedGraph] = React.useState([]);
  const [weeksCount, setWeeksCount] = React.useState(null);
  const [assetDetails, setAssetDetails] = React.useState(null);
  const [totalAsset, setTotalAsset] = useState("");
  const [totalCount, setTotalCount] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [dashList, setDashList] = useState([]);
  const [dashList2, setDashList2] = useState([]);
  const [incidentGraphData, setIncidentGraphData] = useState([]);
  const [incidents, setIncidents] = React.useState(null);
  const [serviceCounts, setServiceCounts] = React.useState(null);
  const [departIds, setDepartIds] = React.useState(null);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });

  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());

  const [allData, setAllData] = useState({});
  const [dayCounts, setDayCounts] = useState(null);
  const [monthCount, setMonthCount] = useState(null);

  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isIncidentTableVisible, setIsIncidentTableVisible] = useState(false);
  const [selectedMonthService, setSelectedMonthService] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [displayText, setDisplayText] = useState("");
  const [displayCount, setDisplayCount] = useState(0);
  const [graphDataIncident, setGraphDataIncident] = useState([]);
  const [selectedMonthIncident, setSelectedMonthIncident] = useState(null);
  const [incidentDisplayText, setIncidentDisplayText] = useState("");
  const [IncidentTableData, setIncidentTableData] = useState([]);
  const [incidentDisplayCount, setIncidentDisplayCount] = useState(0);
  const [openModal, setOpenModal] = React.useState(false);

  const store = useSelector((store) => store?.data?.dashboardList);
  const storeIncident = useSelector((store) => store?.data?.dashboardList2);

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(dashboardData).length > 0) {
      if (dashboardData?.service_graph_days) {
        setGraphData(dashboardData?.service_graph_days);
      }
      setAllData(dashboardData);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (Object.keys(dashboardData).length > 0) {
      if (dashboardData?.incident_graph_days) {
        setGraphDataIncident(dashboardData?.incident_graph_days);
      }
      setAllData(dashboardData);
    }
  }, [dashboardData]);

  // console.log("===========>>>>>>>>>", allData);

  useEffect(() => {
    if (Object.keys(dashboardData).length > 0) {
      if (dashboardData?.incident_graph_days) {
        setIncidentGraphData(dashboardData?.incident_graph_days);
      }
    }
  }, [dashboardData]);

  useEffect(() => {
    if (Object.keys(dashboardData)?.length > 0) {
      const totalAssetsValue = dashboardData?.calib_warranty_counts;

      setTotalAsset(totalAssetsValue);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (Object.keys(dashboardData)?.length > 0) {
      const totalAssetsValue = dashboardData?.total;

      setTotalCount(totalAssetsValue);
    }
  }, [dashboardData]);

  const options2 = {
    vAxis: {
      title: "Count",
      minValue: 0,
      maxValue: 100,
      ticks: [0, 25, 50, 75, 100],
    },
    legend: { position: "none" },
    chart: {
      chartType: "Bar",
    },
  };

  const convertData = (data) => {
    const convertedData = [["", "Incident"]];
    data?.forEach(([label, value]) => {
      const numberValue = Number(value);
      if (!isNaN(numberValue)) {
        convertedData.push([label, numberValue]);
      }
    });

    return convertedData;
  };

  const departmentData = convertData(dashboardData?.departcounts);

  const days = convertData(dayCounts);
  const weeks = convertData(weeksCount);
  const months = convertData(monthCount);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchname: userBranch.branch,
      branchid: userBranch.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDashboard(data, token));
  }, []);

  useEffect(() => {
    if (
      dashboardData?.assetcounts?.length > 0 &&
      dashboardData?.assetcounts?.length !== undefined
    ) {
      setAssetDetails(dashboardData?.assetcounts[0]);
    }
    if (
      dashboardData?.servicecounts?.length > 0 &&
      dashboardData?.servicecounts?.length !== undefined
    ) {
      setServiceCounts(dashboardData?.servicecounts[0]);
    }

    if (
      dashboardData?.departids?.length > 0 &&
      dashboardData?.departids?.length !== undefined
    ) {
      setDepartIds(dashboardData?.departids);
    }

    if (
      dashboardData?.incidents?.length > 0 &&
      dashboardData?.incidents?.length !== undefined
    ) {
      setIncidents(dashboardData?.incidents[0]);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (
      dashboardData?.incidentweekcounts?.length > 0 &&
      dashboardData?.incidentweekcounts !== undefined
    ) {
    }
    setWeeksCount(dashboardData?.incidentweekcounts);
    setDayCounts(dashboardData?.incidentcounts);
    setMonthCount(dashboardData?.incidentmonthcounts);

    if (dashboardData?.downtime !== undefined) {
      setDowntime(dashboardData?.downtime);
    }
  }, [dashboardData]);

  let notCleared = incidents?.open_incidents;
  let cleared = incidents?.closed_incidents;
  let totalIncident = incidents?.reported_incidents;
  const incidentPercentage = (count) => {
    if (count == 0) {
      return 0;
    }
    if (count != 0) {
      const totalCount = Number(cleared) + Number(notCleared);

      return (count / totalCount) * 100;
    }
  };

  const clearedCount = incidentPercentage(cleared);
  const notClearedCount = incidentPercentage(notCleared);

  let calibrated = totalAsset?.calibrated;
  let notCalibrated = totalAsset?.not_calibrated;
  let notRequired = totalAsset?.not_required;
  let totalCalibrated = totalAsset?.totalcalibrated;

  const calibrationPercentage = (count) => {
    const totalCount =
      Number(calibrated) + Number(notCalibrated) + Number(notRequired);

    return (count / totalCount) * 100;
  };

  const calibratedCount = calibrationPercentage(calibrated);
  const notCalibratedCount = calibrationPercentage(notCalibrated);
  const notRequiredCount = calibrationPercentage(notRequired);

  let totalAmc = totalAsset?.total_amc;
  let totalCmc = totalAsset?.total_cmc;
  let totalCmc_cmc = totalAsset?.total_amc_cmc;

  const amcCmcPercentage = (count) => {
    const totalCount = Number(totalAmc) + Number(totalCmc);

    return (count / totalCount) * 100;
  };

  const amcPercentage = amcCmcPercentage(totalAmc);
  const cmcPercentage = amcCmcPercentage(totalCmc);

  const handleDepartmentId = (value) => {
    const id = departIds?.find((item) => {
      return item[0] === value;
    });
    let departId = id[1];
    navigate(`/department-asset-details/${departId}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "day") {
      // alert("day")
      setSelectedGraph(days);
    }
    if (newValue === "week") {
      // alert("week")
      setSelectedGraph(weeks);
    }
    if (newValue === "month") {
      // alert("month")
      setSelectedGraph(months);
    }
  };

  useEffect(() => {
    if (
      dashboardData === "AUTHENTICATION_ERROR" ||
      userAccess?.act_status === "Inctive"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  }, [dashboardData, navigate, userAccess]);

  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtime;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${
          totalDuration.months() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };

  useEffect(() => {
    if (userAccess && Object.keys(userAccess)?.length !== 0) {
      setAssigned(userAccess?.departments[0] === "UN_ASSIGNED");
    }
    if (userAccess && Object.keys(userAccess)?.length === 0) {
      setUserToggle(Object.keys(userAccess)?.length === 0);
    }
  }, [userAccess]);

  useEffect(() => {
    if (handleDataChange !== undefined) {
      let data2 = {
        department: allData?.deptcount,
        checkin: allData?.checkincount,
        checkout: allData?.checkoutcount,
        asset: allData?.assetcount,
        downtime: convertTime(),
      };
      handleDataChange(data2);
    }
  }, [allData]);


  const handleServiceReport = (e) => {
    let value = e.target.value;
    setServiceReport(value);
    setIsTableVisible(true);

    if (value === "day") {
      setGraphData(dashboardData?.service_graph_days);
      setIsTableVisible(false);
    }

    if (value === "month") {
      setGraphData(dashboardData?.service_graph_months);
    }

    
    if (value === "year") {
      const reversedGraphData = [
        ...dashboardData?.service_graph_years,
      ].reverse();
      setGraphData(reversedGraphData);
    }
  };

  const handleIncidentReport = (e) => {
    let value = e.target.value;
    setIncidentReport(value);

    if (value === "year") {
      const reversedGraphData = [
        ...dashboardData?.incident_graph_years,
      ].reverse();
      setIncidentGraphData(reversedGraphData);
      // setIsIncidentTableVisible(true);
    }

    if (value === "month") {
      setIncidentGraphData(dashboardData?.incident_graph_months);
      // setIsIncidentTableVisible(true);
    }

    if (value === "day") {
      setIncidentGraphData(dashboardData?.incident_graph_days);
      setIsIncidentTableVisible(false);
    }
  };
  const handleGenerate = () => {
    const selectedMonthIndex = selectedMonth + 1;
    navigate(`/m-report?month=${selectedMonthIndex}&year=${selectedYear}`);
  };


  const handleData = (month) => {
    let monthValue = getIncidentValue;

    if (incidentReport === "month" && month) {
      monthValue = month; // Set the selected month
      setSelectedMonthIncident(month); // Update the selected month state
    }

    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      incident_or_service: "incident",
      month_or_year: incidentReport,
      month_year: monthValue,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(getDashboardList2(data));
  };

  const handleBarClickIncident = (monthNumber) => {
    handleData(monthNumber);
  };
  const handleDataService = (month) => {
    let monthValue = getServiceValue;

    if (serviceReport === "month" && month) {
      monthValue = month;
      setSelectedMonthService(month);
    }

    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      incident_or_service: "service",
      month_or_year: serviceReport,
      month_year: monthValue,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(getDashboardList(data));
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      // "incident_or_service": "service",
      // (Or)
      incident_or_service: "incident",
      month_or_year: incidentReport,
      // (or)
      // "month_or_year": "year",
      month_year: getIncidentValue,
      // (or)
      // "month_year":"2023"
    };

  }, [incidentReport, getIncidentValue]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      // "incident_or_service": "service",
      // (Or)
      incident_or_service: "service",
      month_or_year: serviceReport,
      // (or)
      // "month_or_year": "year",
      month_year: getServiceValue,
      // (or)
      // "month_year":"2023"
      
    };

    // dispatch(getDashboardList2(data));
  }, [serviceReport, getServiceValue]);

  useEffect(() => {
    if (dashboardList?.length > 0) {
      setDashList(dashboardList);
    }
  }, [dashboardList, incidentReport, getIncidentValue]);

  useEffect(() => {
    if (dashboardList2?.length > 0) {
      setDashList2(dashboardList2);
    }
    // console.log(
    //   "--oo----------o---------o--o-o--o-o",
    //   serviceReport,
    //   getServiceValue
    // );
  }, [dashboardList2, serviceReport, getServiceValue]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const years = Array.from({ length: 11 }, (_, i) => 2020 + i);
  const monthsNew = Array.from({ length: 12 }, (_, i) => i);
  // setDashList2

  const handleAllAsset = () => {
    navigate("/all-assets");
  };
  const handleNotWorking = () => {
    navigate("/not-working-asset");
    // console.log("clicked!!");
  };
  const handleDepartment = () => {
    dispatch(setNavigationSource("assets"));
    navigate("/department-list");
  };

  const handleBarClick = (monthNumber) => {
    handleDataService(monthNumber); // Pass the month number to the handler
  };

  const monthNamesArray = [
    "January",
    "Febrauary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();

  const monthMappingNew = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };

  const currentMonthName = monthMappingNew[currentMonthIndex];
  // console.log(currentMonthName);

  const currentMonthCount =
    dashboardData?.service_list_current_month?.length || "";
  const dataYear = dashboardData?.service_graph_years || [];
  const filteredDataYears = dataYear.filter(
    ([year, value]) => value && parseInt(value, 10) > 0
  );

  const yearsWithData = filteredDataYears.map(([year, value]) => ({
    year: year || "Unknown",
    value: value || "0",
  }));

  const year = yearsWithData.length > 0 ? yearsWithData[0].year : null;

  useEffect(() => {
    if (serviceReport === "month") {
      if (selectedMonthService) {
        const monthData =
          dashboardData?.service_list_current_month?.filter(
            (data) => data.month === selectedMonthService
          ) || [];
  
        setTableData(monthData.length > 0 ? monthData : store);
        setIsTableVisible(monthData.length > 0 || store.length > 0);
        setDisplayText(
          `${monthNamesArray[selectedMonthService - 1]} - ${store.length} Services`
        );
        setDisplayCount(store.length);
      } else {
        setTableData(dashboardData?.service_list_current_month || store);
        setIsTableVisible(
          (dashboardData?.service_list_current_month?.length || store.length) > 0
        );
        setDisplayText(`${currentMonthName} - ${currentMonthCount} Services`);
        setDisplayCount(currentMonthCount);
      }
    } else if (serviceReport === "year") {
      setTableData(dashboardData?.service_list_current_year || store);
      setIsTableVisible(
        (dashboardData?.service_list_current_year?.length || store.length) > 0
      );
  
      // Check if yearsWithData is defined and not empty
      const yearValue = yearsWithData?.length > 0 ? yearsWithData[0].value : 0;
      
      setDisplayText(`${year} - ${yearValue} Services`);
      setDisplayCount(yearValue);
    }
  }, [serviceReport, dashboardData, selectedMonthService, store]);

  const currentMonthCountIncident =
    dashboardData?.incident_list_current_month?.length || "";

  const dataYearIncident = dashboardData?.incident_graph_years || [];
  const filteredDataYearsIncident = dataYearIncident.filter(
    ([year, value]) => value && parseInt(value, 10) > 0
  );

  const yearsWithDataIncident = filteredDataYearsIncident.map(
    ([year, value]) => ({
      year: year || "Unknown",
      value: value || "0",
    })
  );

  useEffect(() => {
    if (incidentReport === "month") {
      if (selectedMonthIncident) {
        const monthData =
          dashboardData?.incident_list_current_month?.filter(
            (data) => data.month === selectedMonthIncident
          ) || [];
  
        setIncidentTableData(monthData.length > 0 ? monthData : storeIncident);
        setIsIncidentTableVisible(monthData.length > 0 || storeIncident.length > 0);
        setIncidentDisplayText(
          `${monthNamesArray[selectedMonthIncident - 1]} - ${storeIncident.length} Incidents`
        );
        setIncidentDisplayCount(monthData.length);
      } else {
        setIncidentTableData(
          dashboardData?.incident_list_current_month || storeIncident
        );
        setIsIncidentTableVisible(
          (dashboardData?.incident_list_current_month?.length || storeIncident.length) > 0
        );
        setIncidentDisplayText(
          `${currentMonthName} - ${currentMonthCountIncident} Incidents`
        );
        setIncidentDisplayCount(currentMonthCountIncident);
      }
    } else if (incidentReport === "year") {
      setIncidentTableData(
        dashboardData?.incident_list_current_year || storeIncident
      );
      setIsIncidentTableVisible(
        (dashboardData?.incident_list_current_year?.length || storeIncident.length) > 0
      );
  
      // Ensure yearsWithDataIncident exists and has at least one entry
      const yearValue = yearsWithDataIncident?.length > 0 ? yearsWithDataIncident[0].value : 0;
  
      setIncidentDisplayText(`${year} - ${yearValue} Incidents`);
      setIncidentDisplayCount(yearValue);
    }
  }, [incidentReport, dashboardData, selectedMonthIncident, storeIncident]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleAssetAddModal = () => {
    if (userIds?.subs_plan === "Basic" ) {
      navigate("/"); // Redirect to homepage
    } else if (totalCount?.total_department === 0) {
      handleOpenModal();
    } else {
      navigate("/add-asset");
    }
  };

  const goToDepartment = () => {
    if (userIds?.subs_plan === "Basic" ) {
      navigate("/"); // Redirect to homepage
    }  else {
      dispatch(setNavigationSource("dashboard"));
      navigate("/department-list");
    }
    
  };

  return (
    <>
      <Wrapper>
        <TopContainer>
          <TitleHeading
            variant="h5"
            sx={{
              fontSize: "20px",
              textAlign: "left",
              textTransform: "capitalize",
              wordWrap: "break-word",
              whiteSpace: "normal",
              padding: 0,
            }}
          >
            {userIds?.hospname},{userBranch?.branch}
            {/* Good life Multi-speciality Hospital, Vijaynagar */}
          </TitleHeading>

          <TopRight>
            <TopCard sx={{ justifyContent: "flex-start" }}>
              <Label sx={{ fontSize: "18px", color: "#1B2535" }}>
              ₹ {allData?.budget_spent?.toLocaleString('en-IN')}
              </Label>
              <Label sx={{ fontSize: "13.5px" }}>Budget Spent</Label>
            </TopCard>
            <TopCard sx={{ alignItems: "center" }}>
              <Label sx={{ fontSize: "18px" }}>Monthly Report</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    maxWidth: "200px",
                    width: "100%",
                  }}
                >
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiSelect-root": {
                        border: "none",
                        padding: 0,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "none",
                            padding: 0,
                          },
                        },
                      }}
                      sx={{ fontSize: "13.5px", fontWeight: 700 }}
                    >
                      {monthsNew.map((month) => (
                        <MenuItem key={month} value={month}>
                          {dayjs().month(month).format("MMMM")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiSelect-root": {
                        border: "none",
                        padding: 0,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      value={selectedYear}
                      onChange={handleYearChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "none",
                            padding: 0,
                          },
                        },
                      }}
                      sx={{ fontSize: "13.5px", fontWeight: 700 }}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Button
                  sx={{
                    borderRadius: "20px",
                    width: "50%%",
                    height: "40px",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                  onClick={handleGenerate}
                  size="small"
                  variant="contained"
                >
                  Generate
                </Button>
              </LocalizationProvider>
            </TopCard>
            <TopCard>
              <ImageCut>
              <img src={coming_soon} style={{height:"100%",width:"100%"}}></img>
              </ImageCut>
            </TopCard>
          </TopRight>
        </TopContainer>

        <InnerSet width="100%" gap={"20px"}>
          <LeftHalf sx={{ gap: "10px" }}>
            {request !== "asset" && request !== "incident" ? (
              <TopSet>
                <Stack
                  width="100%"
                  height="130px"
                  bgcolor="white"
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  p={2}
                  boxShadow={"0px 0px 15px 0px #DBE7E5"}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Label
                      variant="subtitle1"
                      sx={{ fontWeight: 700, fontSize: "13.7px" }}
                    >
                      {totalCount?.total_assets}
                    </Label>

                    <AddButton
                      onClick={handleAssetAddModal}
                      variant="outlined"
                      sx={{
                        width: "67px",
                        height: "44px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                    >
                      Add
                    </AddButton>
                  </Stack>
                  <Label
                    sx={{ fontSize: "13.7px", cursor: "pointer" }}
                    onClick={handleAllAsset}
                  >
                    Assets
                  </Label>
                </Stack>

                <Stack
                  width="100%"
                  height="130px"
                  bgcolor="white"
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  boxShadow={"0px 0px 15px 0px #DBE7E5"}
                  p={2}
                  sx={{ cursor: "pointer" }}
                  onClick={handleNotWorking}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Label
                      variant="subtitle1"
                      sx={{
                        fontWeight: 700,
                        color: "#FF6B00",
                        fontSize: "17px",
                      }}
                    >
                      {totalCount?.total_notworking}
                    </Label>

                    <WarningAmberIcon
                      sx={{ fontSize: "35px", color: "#FF6B00" }}
                    />
                  </Stack>
                  <Label sx={{ color: "#FF6B00", fontSize: "13.7px" }}>
                    Not Working
                  </Label>
                </Stack>

                <Stack
                  width="100%"
                  height="130px"
                  bgcolor="white"
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  boxShadow={"0px 0px 15px 0px #DBE7E5"}
                  p={2}
                  onClick={() => navigate("/Discarded")}
                  sx={{ cursor: "pointer" }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Label
                      variant="subtitle1"
                      sx={{ fontWeight: 700, fontSize: "13.7px" }}
                    >
                      {totalCount?.total_discard}
                    </Label>
                  </Stack>
                  <Label sx={{ fontSize: "13.7px" }}>Discarded</Label>
                </Stack>
              </TopSet>
            ) : (
              <></>
            )}


            {request !== "incident" ? (
              <Stack
                bgcolor="white"
                gap={"15px"}
                height={"100%"}
                p={2}
                boxShadow={"0px 0px 15px 0px #DBE7E5"}
              >
                <Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      gutterBottom
                      sx={{
                        color: "#4690FF",
                        textAlign: "start",
                        marginBottom: "10px",
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "17.1px",
                      }}
                    >
                      Services
                      <ArrowForwardIcon
                        sx={{ fontSize: "25px", color: "#1B2535" }}
                      />
                    </Typography>

                    <Button
                      onClick={() => navigate("/service-list")}
                      sx={{
                        border: "1px solid #FF6B00",
                        color: "#FF6B00",
                        borderRadius: "20px",
                        width: "67px",
                        height: "44px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                      varient="outlined"
                    >
                      Add
                    </Button>
                  </Stack>

                  <GraphTop>
                    <GraphTopCard>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        {allData?.services?.open_service}/
                        {allData?.services?.reported_service}
                      </Label>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        Open
                      </Label>
                    </GraphTopCard>

                    <GraphTopCard>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        {allData?.services?.closed_service}/
                        {allData?.services?.reported_service}
                      </Label>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        Closed
                      </Label>
                    </GraphTopCard>

                    <GraphTopCard>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                      ₹ {allData?.services?.expenditure?.toLocaleString('en-IN')}
                      </Label>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        Expenditure
                      </Label>
                    </GraphTopCard>
                  </GraphTop>

                  <Divider sx={{ margin: "10px 0" }} />

                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Label
                      sx={{
                        fontSize: "13.7px",
                        fontWeight: 700,
                        color: "#1B2535",
                      }}
                    >
                      Services reported
                    </Label>

                    <FormControl
                      sx={{ m: 1 }}
                      size="small"
                      style={{ flexDirection: "row" }}
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={serviceReport}
                        onChange={handleServiceReport}
                      >
                        {/* <MenuItem value=""></MenuItem> */}
                        <MenuItem value={"day"}>Day</MenuItem>
                        <MenuItem value={"month"}>Month</MenuItem>
                        <MenuItem value={"year"}>Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13.7px", fontWeight: 600 }}>
                      No. of services
                    </Typography>
                  </Stack>

                  <Divider sx={{ width: "100%" }} />
                </Stack>

                <Box width="100%" bgcolor="white" sx={{ height: 260 }}>
                  {graphData?.length > 0 && (
                    <GraphComponent
                      key={serviceReport}
                      data={graphData}
                      date={serviceReport}
                      dataValue={setGetServiceValue}
                      handleData={handleDataService}
                      onBarClick={handleBarClick}
                    />
                  )}
                </Box>

                {isTableVisible && (
                  <Box width="100%" bgcolor="white">
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        fontWeight: 600,
                        margin: "10px 0 10px 10px",
                      }}
                    >
                      {/* {serviceReport === "month"
                        ? `${currentMonthName} - ${currentMonthCount || store?.length} Services`
                        : `${year} - ${yearsWithData[0].value} Services`} */}
                      {displayText}
                    </Typography>
                    <TableContainer sx={{ maxHeight: "260px" }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableHeading
                              sx={{ fontSize: "13.7px", fontWeight: 700 }}
                            >
                              Asset Name
                            </TableHeading>

                            <TableHeading
                              sx={{ fontSize: "13.7px", fontWeight: 700 }}
                            >
                              Asset Code
                            </TableHeading>
                            <TableHeading
                              sx={{ fontSize: "13.7px", fontWeight: 700 }}
                            >
                              Department
                            </TableHeading>
                            {/* <TableHeading>Services</TableHeading> */}
                            <TableHeading
                              sx={{ fontSize: "13.7px", fontWeight: 700 }}
                            >
                              Status
                            </TableHeading>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{ textDecoration: "none" }}
                              component={Link}
                              to={`/asset-detail/${row.assetid}`}
                            >
                              {" "}
                              <TableCells>{row.name}</TableCells>
                              <TableCells>{row.assetcode}</TableCells>
                              <TableCells>{row.department}</TableCells>
                              <TableCells
                                sx={{
                                  textAlign: "left",
                                  color:
                                    row.status !== "Working"
                                      ? "#FF6B00"
                                      : "#4690FF",
                                }}
                              >
                                {row.status}
                              </TableCells>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

            

                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Label sx={{ fontSize: "17px", fontWeight: 800 }}>
                    Need Attention - {allData?.service_need_attention?.length}{" "}
                  </Label>
                  <ArrowForwardIcon
                    onClick={() => {
                      navigate("/service-list");
                    }}
                    sx={{ cursor: "pointer" }}
                  />
                </Stack>

                <Box width="100%" bgcolor="white">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeading
                            sx={{ fontSize: "13px", fontWeight: 700 }}
                          >
                            Asset Name
                          </TableHeading>

                          <TableHeading
                            sx={{ fontSize: "13px", fontWeight: 700 }}
                          >
                            Asset Code
                          </TableHeading>
                          <TableHeading
                            sx={{ fontSize: "13px", fontWeight: 700 }}
                          >
                            Department
                          </TableHeading>
                          <TableHeading
                            sx={{ fontSize: "13px", fontWeight: 700,textAlign:'center' }}
                          >
                            Services
                          </TableHeading>
                          <TableHeading
                            sx={{ fontSize: "13px", fontWeight: 700,textAlign:'center' }}
                          >
                            Status
                          </TableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allData?.service_need_attention?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ textDecoration: "none" }}
                            component={Link}
                            to={`/asset-detail/${row.assetid}`}
                          >
                            {" "}
                            <TableCells>{row.asset}</TableCells>
                            <TableCells>{row.code}</TableCells>
                            <TableCells>{row.dept}</TableCells>
                            <TableCells
                              sx={{ textAlign: "center", cursor: "default" }}
                            >
                              {row.no_of_services}
                            </TableCells>
                            <TableCells
                              sx={{
                                textAlign: "center",
                                cursor: "default",
                                color:
                                  row.status !== "Working"
                                    ? "#FF6B00"
                                    : "#4690FF",
                              }}
                            >
                              {row.status}
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
            ) : (
              <></>
            )}
          </LeftHalf>

          {request !== "asset" ? (
            <RightHalf sx={{ textAlign: "start", gap: "10px" }}>
              <DowntimeBox sx={{ background: "white" }}>
                <Stack
                  width="184px"
                  height={{ xl: "130px", md: "100%" }}
                  bgcolor="white"
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  boxShadow={"0px 0px 15px 0px #DBE7E5"}
                  p={2}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Label
                      variant="subtitle1"
                      sx={{ fontWeight: 700, fontSize: "13.7px" }}
                    >
                      {totalCount?.total_department}
                    </Label>

                    <AddButton
                      onClick={goToDepartment}
                      variant="outlined"
                      sx={{ width: "67px", height: "44px", fontSize: "12px" }}
                    >
                      Add
                    </AddButton>
                  </Stack>
                  <Label
                    sx={{ fontSize: "13.7px", cursor: "pointer" }}
                    onClick={handleDepartment}
                  >
                    Department
                  </Label>
                </Stack>

                <DowntimeSplit>
                 
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: {
                            sm: "grid",
                            md: "flex",
                            lg: "flex",
                          },
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Label
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "13px",

                            
                              cursor: "pointer",
                            }}
                            onClick={() => navigate("/incident-list")}
                          >
                            Cumulative Downtime
                          </Label>

                         
                        </Box>
                        <Box>
                          <ArrowForwardIcon
                            sx={{
                              fontSize: "20px",
                              color: "#1B2535",
                              width: "30px",
                            }}
                          ></ArrowForwardIcon>
                        </Box>
                        <Box sx={{ alignItems: "center" }}>
                          <Typography
                            sx={{
                              alignItems: "center",
                              color: "#FF6B00",
                              fontWeight: 100,
                              fontSize: "13px",
                            }}
                          >
                            {convertTime()}
                          </Typography>
                        </Box>
                      
                      </Box>
                    </Box>
                  </Box>
                </DowntimeSplit>
              </DowntimeBox>

              <Stack
                bgcolor="white"
                gap={"15px"}
                height={"100%"}
                p={2}
                boxShadow={"0px 0px 15px 0px #DBE7E5"}
              >
                <Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      gutterBottom
                      sx={{
                        color: "#4690FF",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 700,
                        fontSize: "17.1px",
                        gap: "20px",
                      }}
                    >
                      Incidents
                      <ArrowForwardIcon
                        sx={{ fontSize: "25px", color: "#1B2535" }}
                      />
                    </Typography>

                    <Button
                      onClick={() => navigate("/incident-list")}
                      sx={{
                        border: "1px solid #FF6B00",
                        color: "#FF6B00",
                        borderRadius: "20px",
                        width: "67px",
                        height: "44px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                      varient="outlined"
                    >
                      Add
                    </Button>
                  </Stack>
                  {/* closed_incid
: 
"14"
open_incid
: 
"7"
reported_incid
: 
"21" */}

                  <GraphTop>
                    <GraphTopCard>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        {allData?.incidents?.open_incid}/
                        {allData?.incidents?.reported_incid}
                      </Label>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 600 }}>
                        Open
                      </Label>
                    </GraphTopCard>

                    <GraphTopCard>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 700 }}>
                        {allData?.incidents?.closed_incid}/
                        {allData?.incidents?.reported_incid}
                      </Label>
                      <Label sx={{ fontSize: "13.7px", fontWeight: 600 }}>
                        Closed
                      </Label>
                    </GraphTopCard>

                    <GraphTopCard></GraphTopCard>
                  </GraphTop>

                  <Divider sx={{ margin: "10px 0" }} />

                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Label sx={{ fontSize: "13.7px", fontWeight: 800 }}>
                      Incidents reported
                    </Label>

                    <FormControl
                      sx={{ m: 1 }}
                      size="small"
                      style={{ flexDirection: "row" }}
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={incidentReport}
                        onChange={handleIncidentReport}
                      >
                        {/* <MenuItem value=""></MenuItem> */}
                        <MenuItem value={"day"}>Day</MenuItem>
                        <MenuItem value={"month"}>Month</MenuItem>
                        <MenuItem value={"year"}>Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13.7px", fontWeight: 600 }}>
                      No. of Incidents
                    </Typography>
                  </Stack>
                  <Divider sx={{ width: "100%" }} />
                </Stack>

                <Box width="100%" bgcolor="white" sx={{ height: 260 }}>
                  {graphDataIncident?.length > 0 && (
                 
                    <IncidentGraphComponent
                      key={incidentReport}
                      data={incidentGraphData}
                      date={incidentReport}
                      dataValue={setGetIncidentValue}
                      handleData={handleData}
                      onBarClick={handleBarClickIncident}
                    />
                  )}
                </Box>
                {isIncidentTableVisible && (
                  <Box width="100%" bgcolor="white">
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        fontWeight: 600,
                        margin: "10px 0 10px 10px",
                      }}
                    >
                      {" "}
                     
                      {incidentDisplayText}
                    </Typography>
                    <TableContainer sx={{ maxHeight: "260px" }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableHeading
                              sx={{ fontSize: "13px", fontWeight: 700 }}
                            >
                              Asset Name
                            </TableHeading>

                            <TableHeading
                              sx={{ fontSize: "13px", fontWeight: 700 }}
                            >
                              Asset Code
                            </TableHeading>
                            <TableHeading
                              sx={{ fontSize: "13px", fontWeight: 700 }}
                            >
                              Department
                            </TableHeading>
                            {/* <TableHeading>Services</TableHeading> */}
                            <TableHeading
                              sx={{ fontSize: "13px", fontWeight: 700 }}
                            >
                              Status
                            </TableHeading>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {IncidentTableData?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{ textDecoration: "none" }}
                              component={Link}
                              to={`/asset-detail/${row.assetid}`}
                            >
                              {" "}
                              <TableCells>{row.name}</TableCells>
                              <TableCells>{row.assetcode}</TableCells>
                              <TableCells>{row.department}</TableCells>
                              <TableCells
                                sx={{
                                  textAlign: "left",
                                  color:
                                    row.status !== "Working"
                                      ? "#FF6B00"
                                      : "#4690FF",
                                }}
                              >
                                {row.status}
                              </TableCells>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
            
              </Stack>

              <BottomSet>
                <SemiCircleBox>
                  <Stack>
                    <Label sx={{ fontSize: "13.7px", fontWeight: 800 }}>
                      Calibration
                    </Label>
                  </Stack>

                  <SplitCircle>
                    <Stack
                      sx={{
                        height: "80px",
                        width: "130px",
                        margin: "auto",
                      }}
                    >
                      <HalfPieChart2
                        value1={calibrated}
                        value2={notCalibrated}
                        value3={notRequired}
                        total={
                          Number(calibrated) +
                          Number(notCalibrated) +
                          Number(notRequired)
                        }
                      />
                    </Stack>
                    <CustomDivider />
                    <Stack
                      sx={{
                        alignItems: "start",
                        height: "100px",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                     
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        onClick={() => navigate("/request")}
                      >
                        <Box>
                          <Label2
                            variant="body2"
                            sx={{ display: "flex", gap: "5px" }}
                          >
                            <SquareIcon sx={{ fontSize: "12px" }} />
                            {calibrated} Calibrated
                          </Label2>
                        </Box>
                        <Box>
                          <ArrowForwardIcon sx={{cursor:'pointer'}}/>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        onClick={() => navigate("/not-calibrated")}
                      >
                        <Box>
                          <Label2
                            variant="body2"
                            sx={{ display: "flex", gap: "5px" }}
                          >
                            <SquareIcon
                              sx={{ fontSize: "12px", color: "#8B9099" }}
                            />
                            {notCalibrated} Not Calibrated
                          </Label2>
                        </Box>
                        <Box>
                          <ArrowForwardIcon sx={{cursor:'pointer'}}/>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        onClick={() => navigate("/calibration-not-required")}
                      >
                        <Box>
                          <Label2
                            variant="body2"
                            sx={{ display: "flex", gap: "5px" }}
                          >
                            <SquareIcon
                              sx={{ fontSize: "12px", color: "#BBBEC3" }}
                            />
                            {notRequired} Not Required
                          </Label2>
                        </Box>
                        <Box>
                          <ArrowForwardIcon sx={{cursor:'pointer'}}/>
                        </Box>
                      </Box>
                    </Stack>
                  </SplitCircle>
                  <Stack
                    sx={{
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "25px",
                    }}
                  >
                    <Label2
                      variant="body2"
                      sx={{
                        color: "#ffffff",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: 700,
                        background: "#FF6B00",
                        borderRadius: "20px",
                        padding: "5px 20px",
                        width: "140px",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      {totalAsset?.calibration_due} Due
                      <ArrowForwardIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/calendar")}
                      />
                    </Label2>
                    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                      Calender
                    </Typography>
                  </Stack>
                </SemiCircleBox>

                <SemiCircleBox>
                  <Stack>
                    <Label sx={{ fontSize: "13.7px", fontWeight: 800 }}>
                      Warranty
                    </Label>
                  </Stack>
                  <SplitCircle>
                    <Stack
                      sx={{
                        height: "80px",
                        width: "150px",
                        margin: "auto",
                      }}
                    >
                      <HalfPieChart2
                        value1={totalAsset?.total_warranty}
                        value2={totalAsset?.warranty_exp_soon}
                        value3={totalAsset?.warranty_requested}
                        total={totalAsset?.total_warranty}
                      />
                    </Stack>
                    <CustomDivider />
                    <Stack
                      onClick={() => navigate("/request")}
                      sx={{
                        alignItems: "start",
                        height: "100px",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                  
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Label2
                            variant="body2"
                            sx={{ display: "flex", gap: "5px" }}
                          >
                            <SquareIcon sx={{ fontSize: "12px" }} />
                            {totalAsset?.total_warranty} Total
                          </Label2>
                        </Box>
                        <Box>
                          <ArrowForwardIcon sx={{cursor:'pointer'}}/>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box>
                          {" "}
                          <Label2
                            variant="body2"
                            sx={{ display: "flex", gap: "5px" }}
                          >
                            <SquareIcon
                              sx={{ fontSize: "12px", color: "#8B9099" }}
                            />
                            {totalAsset?.warranty_expires_soon} Expires soon
                          </Label2>
                        </Box>
                        <Box>
                          <ArrowForwardIcon sx={{cursor:'pointer'}}/>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Label2
                            variant="body2"
                            sx={{ display: "flex", gap: "5px" }}
                          >
                            <SquareIcon
                              sx={{ fontSize: "12px", color: "#BBBEC3" }}
                            />
                            {totalAsset?.warranty_requested} Requested
                          </Label2>
                        </Box>
                        <Box>
                          <ArrowForwardIcon sx={{cursor:'pointer'}}/>
                        </Box>
                      </Box>
                    </Stack>
                  </SplitCircle>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    <Label2
                      variant="body2"
                      sx={{
                        color: "#1B2535",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: 700,
                        fontSize: "12px",
                      }}
                    >
                      {totalAsset?.warranty_expiry} Expiry
                      <ArrowForwardIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/calendar")}
                      />
                    </Label2>
                    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                      Calender
                    </Typography>
                  </Stack>
                </SemiCircleBox>
              </BottomSet>
            </RightHalf>
          ) : (
            <></>
          )}
        </InnerSet>
      </Wrapper>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <ModalBox>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}
          >
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ fill: "#000" }} />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
              position: "absolute",
              top: "50px",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              Please add the department before adding an asset.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
              onClick={() => navigate("/department-list")}
            >
              Add Department
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </>
  );
};

export default DashboardPage;
