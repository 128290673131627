import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  styled,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DashboardPage from "./DashboardPage";
import { useEffect } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import { Link, useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useDispatch, useSelector } from "react-redux";

import {
  getDashboard,
  getDashboardList,
  getDashboardList2,
  retrieveAllAssets,
  setNavigationSource,
} from "../redux/nbmData/action";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign: "left",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
}));

const ButtonSet = styled(Button)(({ theme }) => ({
  borderRadius: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

export default function DashboardMobile() {
  let userIds =
  JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"));
let userBranch =
  JSON.parse(sessionStorage.getItem("userBranch")) ||
  JSON.parse(localStorage.getItem("userBranch"));
let userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));
const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [request, setRequest] = React.useState("asset");
  const [dashboardDataCount, setDashboardDataCount] = React.useState({
    department: 0,
    checkin: 0,
    checkout: 0,
    asset: 0,
    downtime: 0,
  });

  const handleDataChange = (childData) => {
    setDashboardDataCount({
      department: childData.department,
      checkin: childData.checkin,
      checkout: childData.checkout,
      asset: childData.asset,
      downtime: childData.downtime,
    });
  };

  const handleAsset = () => {
    setRequest("asset");
  };
  const handleIncident = () => {
    setRequest("incident");
  };
  const dashboardData = useSelector((store) => store.data.dashboard);

  const [totalCount, setTotalCount] = useState({});

  useEffect(() => {
    if (Object.keys(dashboardData)?.length > 0) {
      const totalAssetsValue = dashboardData?.total;

      setTotalCount(totalAssetsValue);
    }
  }, [dashboardData]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchname: userBranch.branch,
      branchid: userBranch.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDashboard(data, token));
  }, []);


  const [incidentReport, setIncidentReport] = React.useState("day");
  const [serviceReport, setServiceReport] = React.useState("day");
  const [getServiceValue, setGetServiceValue] = React.useState("2024");
  const [getIncidentValue, setGetIncidentValue] = React.useState("2024");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [assigned, setAssigned] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  const [value, setValue] = React.useState("day");
  const dashboardList = useSelector(
    (store) => store.data.dashboard.incident_list_current_month
  );
  const dashboardList2 = useSelector(
    (store) => store.data.dashboard.service_list_current_month
  );
  const serviceGraphMonth = useSelector(
    (store) => store.data.dashboard.service_graph_months
  );

  const [selectedGraph, setSelectedGraph] = React.useState([]);
  const [weeksCount, setWeeksCount] = React.useState(null);
  const [assetDetails, setAssetDetails] = React.useState(null);
  const [totalAsset, setTotalAsset] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [graphDataIncident, setGraphDataIncident] = useState([]);

  const [dashList, setDashList] = useState([]);
  const [dashList2, setDashList2] = useState([]);
  const [incidentGraphData, setIncidentGraphData] = useState([]);
  const [incidents, setIncidents] = React.useState(null);
  const [serviceCounts, setServiceCounts] = React.useState(null);
  const [departIds, setDepartIds] = React.useState(null);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });

  const [allData, setAllData] = useState({});
  const [dayCounts, setDayCounts] = useState(null);
  const [monthCount, setMonthCount] = useState(null);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());

  const [openModal, setOpenModal] = React.useState(false);
  const [assetdata, setAssetdata] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [IncidentTableData, setIncidentTableData] = useState([]);

  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isIncidentTableVisible, setIsIncidentTableVisible] = useState(false);

  const store = useSelector((store) => store?.data?.dashboardList);

  const storeIncident = useSelector((store) => store?.data?.dashboardList2);

  const testData = useSelector((store) => store?.data);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const years = Array.from({ length: 11 }, (_, i) => 2020 + i);
  const monthsNew = Array.from({ length: 12 }, (_, i) => i);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(dashboardData).length > 0) {
      if (dashboardData?.service_graph_days) {
        setGraphData(dashboardData?.service_graph_days);
      }
      setAllData(dashboardData);
    }
  }, [dashboardData]);

  const TopCard = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // height:'100px',
    gap: "10px",

    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));
  const ImageCut = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    background: "linear-gradient(#4690FF 70%, #2A5699 100%)",
    gap: "15px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const handleGenerate = () => {
    const selectedMonthIndex = selectedMonth + 1;
    navigate(`/m-report?month=${selectedMonthIndex}&year=${selectedYear}`);
  };
  return (
    <>
      <Stack
        sx={{
          width: "100%",
          // height: "100vh",
          marginTop: "-11px",

          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          bgcolor: "#f5f5f5",
          gap: "30px",
          paddingBottom: "11px",
        }}
      >
        <Stack
          sx={{
            height: "310px",
            bgcolor: "#1B2535",
            width: "100%",
            color: "white",
          }}
        >
          <Stack
            justifyContent={"space-between"}
            sx={{ flexDirection: "row", p: 2, alignItems: "end" }}
          >
            <Label
              variant="h4"
              sx={{
                fontSize: "20px",
                textAlign: "left",
                textTransform: "capitalize",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {userIds?.hospname},{userBranch?.branch}
            </Label>
          </Stack>

          <Stack
            justifyContent={"space-between"}
            sx={{
              flexDirection: "row",
              padding: "10px",
              alignItems: "end",
              color: "white",
            }}
          >
            <Box>
              <Label
                variant="h6"
                sx={{ fontSize: "12px", marginBottom: "5px  " }}
              >
                {" "}
                ₹ {allData?.budget_spent?.toLocaleString("en-IN")}
              </Label>
              <Label
                sx={{ textAlign: "left", lineHeight: "2.6", fontSize: "10px" }}
                variant="h6"
              >
                Budget Spent
              </Label>
              <Label
                variant="h6"
                sx={{ fontSize: "12px", marginBottom: "5px  ", width: "150px" }}
              >
                {dashboardDataCount?.downtime}
              </Label>
              <Label
                sx={{ textAlign: "left", lineHeight: "2.6", fontSize: "10px" }}
                variant="h6"
              >
                Working.
              </Label>
            </Box>

            <Label variant="h6">
              <TopCard>
                <ImageCut sx={{ padding: "20px", width: "100px" }}>
                  <Label sx={{ color: "white", fontSize: "14px" }}>
                    Coming Soon!
                  </Label>
                  <Label sx={{ color: "white", fontSize: "12px" }}>
                    Monthly Ratings
                  </Label>
                </ImageCut>
              </TopCard>
            </Label>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px ",
            }}
          >
            <Divider
              sx={{ width: "90%", backgroundColor: "#4A4A4A", height: "1px" }}
            />
          </Stack>

          <TopCard sx={{ alignItems: "start", margin: "10px" }}>
            <Label sx={{ fontSize: "12px" }}>Monthly Report</Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",

                    maxWidth: "200px",
                    width: "100%",
                  }}
                >
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiSelect-root": {
                        border: "none",
                        padding: 0,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "none",
                            padding: 0,
                          },
                        },
                      }}
                      sx={{
                        fontSize: "13.5px",
                        fontWeight: 700,
                        color: "white",
                      }}
                    >
                      {monthsNew.map((month) => (
                        <MenuItem
                          key={month}
                          value={month}
                          sx={{ color: "Black" }}
                        >
                          {dayjs().month(month).format("MMMM")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiSelect-root": {
                        border: "none",
                        padding: 0,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      value={selectedYear}
                      onChange={handleYearChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "none",
                            padding: 0,
                          },
                        },
                      }}
                      sx={{
                        fontSize: "13.5px",
                        fontWeight: 700,
                        color: "white",
                      }}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Button
                  sx={{
                    borderRadius: "20px",
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                  onClick={handleGenerate}
                  size="small"
                  variant="contained"
                >
                  Generate
                </Button>
              </Box>
            </LocalizationProvider>
          </TopCard>
        </Stack>
        <Box
          sx={{
            width: "80%",
            height: "45%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // display: "grid",
              // gridTemplateColumns:'repeat(3,1fr)',

              height: "100px",
              gap: "10px",
            }}
          >
            <Stack
              onClick={() => navigate("/department-list")}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                background: "white",
                alignItems: "start",
                gap: "10px",
                width: "50px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {totalCount?.total_department}
              </Label>
              <Label sx={{ fontSize: "10px" }}>Dept.</Label>
            </Stack>

            <Stack
              onClick={() => navigate("/all-assets")}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                background: "white",
                gap: "10px",
                width: "50px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {totalCount?.total_assets}
              </Label>
              <Label sx={{ fontSize: "10px" }}>Assets.</Label>
            </Stack>

            <Stack
              onClick={() => navigate("/service-list")}
              sx={{
                display: "flex",
                cursor: "pointer",
                justifyContent: "space-between",
                alignItems: "start",
                background: "white",
                gap: "10px",
                width: "50px",
                p: 2,
                boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
              }}
            >
              <Label variant="h6" sx={{ color: "#4690FF" }}>
                {allData?.services?.closed_service}/
                {allData?.services?.reported_service}
              </Label>
              <Label sx={{ fontSize: "10px" }}>Services</Label>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "50%",
              paddingTop: "20px",
              gap: "10px",
            }}
          >
            <ButtonSet
              variant={request === "asset" ? "contained" : "outlined"}
              sx={{
                ...(request === "incident" && {
                  color: "black",
                  borderColor: "black",
                }),
              }}
              onClick={handleAsset}
            >
              Assets
            </ButtonSet>

            <ButtonSet
              sx={{
                ...(request === "asset" && {
                  color: "black",
                  borderColor: "black",
                }),
              }}
              variant={request === "incident" ? "contained" : "outlined"}
              onClick={handleIncident}
            >
              Incidents
            </ButtonSet>
          </Box>
        </Box>
      </Stack>
      <DashboardPage handleDataChange={handleDataChange} request={request} />
    </>
  );
}
