import axios from "axios";
import FileSaver from "file-saver";
import QRCode from "qrcode";
const token = localStorage.getItem("userToken");
const Id = JSON.parse(localStorage.getItem("userTokenId"));

export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

export const COUNT_REQUEST = "COUNT_REQUEST";
export const COUNT_SUCCESS = "COUNT_SUCCESS";
export const COUNT_FAILURE = "COUNT_FAILURE";

export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

export const POST_USER_DATA_REQUEST = "POST_USER_DATA_REQUEST";
export const POST_USER_DATA_SUCCESS = "POST_USER_DATA_SUCCESS";
export const POST_USER_DATA_FAILURE = "POST_USER_DATA_FAILURE";

export const GET_DEPARTMENT_DATA_REQUEST = "GET_DEPARTMENT_DATA_REQUEST";
export const GET_DEPARTMENT_DATA_SUCCESS = "GET_DEPARTMENT_DATA_SUCCESS";
export const GET_DEPARTMENT_DATA_FAILURE = "GET_DEPARTMENT_DATA_FAILURE";

export const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_FAILURE = "UPDATE_DATA_FAILURE";

export const CURRENT_DATA_REQUEST = "CURRENT_DATA_REQUEST";
export const CURRENT_DATA_SUCCESS = "CURRENT_DATA_SUCCESS";
export const CURRENT_DATA_FAILURE = "CURRENT_DATA_FAILURE";

export const INCIDENT_DATA_REQUEST = "INCIDENT_DATA_REQUEST";
export const INCIDENT_DATA_SUCCESS = "INCIDENT_DATA_SUCCESS";
export const INCIDENT_DATA_FAILURE = "INCIDENT_DATA_FAILURE";

export const INCIDENT_DETAIL_REQUEST = "INCIDENT_DETAIL_REQUEST";
export const INCIDENT_DETAIL_SUCCESS = "INCIDENT_DETAIL_SUCCESS";
export const INCIDENT_DETAIL_FAILURE = "INCIDENT_DETAIL_FAILURE";

export const DOWNLOAD_PDF_REQUEST = "DOWNLOAD_PDF_REQUEST";
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS";
export const DOWNLOAD_PDF_FAILURE = "DOWNLOAD_PDF_FAILURE";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const UPLOADED_IMAGE_REQUEST = "UPLOADED_IMAGE_REQUEST";
export const UPLOADED_IMAGE_SUCCESS = "UPLOADED_IMAGE_SUCCESS";
export const UPLOADED_IMAGE_FAILURE = "UPLOADED_IMAGE_FAILURE";

export const UPLOADED_CERTIFICATE_REQUEST = "UPLOADED_CERTIFICATE_REQUEST";
export const UPLOADED_CERTIFICATE_SUCCESS = "UPLOADED_CERTIFICATE_SUCCESS";
export const UPLOADED_CERTIFICATE_FAILURE = "UPLOADED_CERTIFICATE_FAILURE";

export const UPLOAD_QR_CODE_IMAGE_REQUEST = "UPLOAD_QR_CODE_IMAGE_REQUEST";
export const UPLOAD_QR_CODE_IMAGE_SUCCESS = "UPLOAD_QR_CODE_IMAGE_SUCCESS";
export const UPLOAD_QR_CODE_IMAGE_FAILURE = "UPLOAD_QR_CODE_IMAGE_FAILURE";

export const CREATE_QR_CODE_REQUEST = "CREATE_QR_CODE_REQUEST";
export const CREATE_QR_CODE_SUCCESS = "CREATE_QR_CODE_SUCCESS";
export const CREATE_QR_CODE_FAILURE = "CREATE_QR_CODE_FAILURE";

export const ADD_ASSET_REQUEST = "ADD_ASSET_REQUEST";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_FAILURE = "ADD_ASSET_FAILURE";

export const POST_DEPARTMENT_NAME_REQUEST = "POST_DEPARTMENT_NAME_REQUEST";
export const POST_DEPARTMENT_NAME_SUCCESS = "POST_DEPARTMENT_NAME_SUCCESS";
export const POST_DEPARTMENT_NAME_FAILURE = "POST_DEPARTMENT_NAME_FAILURE";

export const POST_DEPARTMENT_REQUEST = "POST_DEPARTMENT_REQUEST";
export const POST_DEPARTMENT_SUCCESS = "POST_DEPARTMENT_SUCCESS";
export const POST_DEPARTMENT_FAILURE = "POST_DEPARTMENT_FAILURE";

export const DEPARTMENT_ALREADY_SAVED_REQUEST =
  "DEPARTMENT_ALREADY_SAVED_REQUEST";
export const DEPARTMENT_ALREADY_SAVED_SUCCESS =
  "DEPARTMENT_ALREADY_SAVED_SUCCESS";
export const DEPARTMENT_ALREADY_SAVED_FAILURE =
  "DEPARTMENT_ALREADY_SAVED_FAILURE";

export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";
export const GET_ASSET_REQUEST = "GET_ASSET_REQUEST";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAILURE = "GET_ASSET_FAILURE";

export const GET_REQUEST_DASHBOARD_FAILURE = "GET_REQUEST_DASHBOARD_FAILURE";

export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_REQUEST_HISTORY = "DELETE_IMAGE_REQUEST_HISTORY";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";

export const DELETE_CERTIFICATE_REQUEST = "DELETE_CERTIFICATE_REQUEST";
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS";
export const DELETE_CERTIFICATE_FAILURE = "DELETE_CERTIFICATE_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

export const CONTACT_HOME_REQUEST = "CONTACT_HOME_REQUEST";
export const CONTACT_HOME_SUCCESS = "CONTACT_HOME_SUCCESS";
export const CONTACT_HOME_FAILURE = "CONTACT_HOME_FAILURE";

export const RETRIEVE_DEPARTMENT_REQUEST = "RETRIEVE_DEPARTMENT_REQUEST";
export const RETRIEVE_DEPARTMENT_SUCCESS = "RETRIEVE_DEPARTMENT_SUCCESS";
export const RETRIEVE_DEPARTMENT_FAILURE = "RETRIEVE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

// ..................Retrieve all assets............................................

export const RETRIEVE_ALL_ASSETS_REQUEST = "RETRIEVE_ALL_ASSETS_REQUEST";
export const RETRIEVE_ALL_ASSETS_SUCCESS = "RETRIEVE_ALL_ASSETS_SUCCESS";
export const RETRIEVE_ALL_ASSETS_FAILURE = "RETRIEVE_ALL_ASSETS_FAILURE";

// ..................Delete assets............................................

export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

// ...................Create Service...........................................

export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";

// ...................Create Service Image upload...........................................
export const CREATE_SERVICE_IMAGE_UPLOAD_REQUEST =
  "CREATE_SERVICE_IMAGE_UPLOAD_REQUEST";
export const CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS =
  "CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS";
export const CREATE_SERVICE_IMAGE_UPLOAD_FAILURE =
  "CREATE_SERVICE_IMAGE_UPLOAD_FAILURE";

// ...................Closed Service...........................................

export const CLOSED_SERVICE_REQUEST = "CLOSED_SERVICE_REQUEST";
export const CLOSED_SERVICE_SUCCESS = "CLOSED_SERVICE_SUCCESS";
export const CLOSED_SERVICE_FAILURE = "CLOSED_SERVICE_FAILURE";

// ...................open Service...........................................

export const OPEN_SERVICE_REQUEST = "OPEN_SERVICE_REQUEST";
export const OPEN_SERVICE_SUCCESS = "OPEN_SERVICE_SUCCESS";
export const OPEN_SERVICE_FAILURE = "OPEN_SERVICE_FAILURE";

// ................... Service List...........................................

export const SERVICE_LIST_REQUEST = "SERVICE_LIST_REQUEST";
export const SERVICE_LIST_SUCCESS = "SERVICE_LIST_SUCCESS";
export const SERVICE_LIST_FAILURE = "SERVICE_LIST_FAILURE";

// ................... Service List...........................................

export const SERVICE_DETAIL_REQUEST = "SERVICE_DETAIL_REQUEST";
export const SERVICE_DETAIL_SUCCESS = "SERVICE_DETAIL_SUCCESS";
export const SERVICE_DETAIL_FAILURE = "SERVICE_DETAIL_FAILURE";

// ................... Clear service...........................................
export const CLEAR_SERVICE_REQUEST = "CLEAR_SERVICE_REQUEST";
export const CLEAR_SERVICE_SUCCESS = "CLEAR_SERVICE_SUCCESS";
export const CLEAR_SERVICE_FAILURE = "CLEAR_SERVICE_FAILURE";

// ...................Issue...........................................
export const SERVICE_ISSUE_REQUEST = "SERVICE_ISSUE_REQUEST";
export const SERVICE_ISSUE_SUCCESS = "SERVICE_ISSUE_SUCCESS";
export const SERVICE_ISSUE_FAILURE = "SERVICE_ISSUE_FAILURE";

export const INCIDENT_ISSUE_REQUEST = "INCIDENT_ISSUE_REQUEST";
export const INCIDENT_ISSUE_SUCCESS = "INCIDENT_ISSUE_SUCCESS";
export const INCIDENT_ISSUE_FAILURE = "INCIDENT_ISSUE_FAILURE";

// ...................Service Image upload...........................................

export const SERVICE_IMAGE_UPLOAD_REQUEST = "SERVICE_IMAGE_UPLOAD_REQUEST";
export const SERVICE_IMAGE_UPLOAD_SUCCESS = "SERVICE_IMAGE_UPLOAD_SUCCESS";
export const SERVICE_IMAGE_UPLOAD_FAILURE = "SERVICE_IMAGE_UPLOAD_FAILURE";

// ...................Logout...........................................

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const EDIT_ASSET_REQUEST = "EDIT_ASSET_REQUEST";
export const EDIT_ASSET_SUCCESS = "EDIT_ASSET_SUCCESS";
export const EDIT_ASSET_FAILURE = "EDIT_ASSET_FAILURE";

// ...................Profile...........................................

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

// ...................usermanual...........................................
export const USER_MANUAL_REQUEST = "USER_MANUAL_REQUEST";
export const USER_MANUAL_SUCCESS = "USER_MANUAL_SUCCESS";
export const USER_MANUAL_FAILURE = "USER_MANUAL_FAILURE";

export const GET_CALIBRATED_REQUEST = "GET_CALIBRATED_REQUEST";
export const GET_CALIBRATED_SUCCESS = "GET_CALIBRATED_SUCCESS";
export const GET_CALIBRATED_FAILURE = "GET_CALIBRATED_FAILURE";

export const GET_NOTCALIBRATED_REQUEST = "GET_NOTCALIBRATED_REQUEST";
export const GET_NOTCALIBRATED_SUCCESS = "GET_NOTCALIBRATED_SUCCESS";
export const GET_NOTCALIBRATED_FAILURE = "GET_NOTCALIBRATED_FAILURE";

export const GET_NOTREQUIRED_REQUEST = "GET_NOTREQUIRED_REQUEST";
export const GET_NOTREQUIRED_SUCCESS = "GET_NOTREQUIRED_SUCCESS";
export const GET_NOTREQUIRED_FAILURE = "GET_NOTREQUIRED_FAILURE";

// ---------------------------------clear incident image-------------------------

export const CLEAR_INCIDENT_IMAGE_REQUEST = "CLEAR_INCIDENT_IMAGE_REQUEST";
export const CLEAR_INCIDENT_IMAGE_SUCCESS = "CLEAR_INCIDENT_IMAGE_SUCCESS";
export const CLEAR_INCIDENT_IMAGE_FAILURE = "CLEAR_INCIDENT_IMAGE_FAILURE";

export const CLEAR_INCIDENT_REQUEST = "CLEAR_INCIDENT_REQUEST";
export const CLEAR_INCIDENT_SUCCESS = "CLEAR_INCIDENT_SUCCESS";
export const CLEAR_INCIDENT_FAILURE = "CLEAR INCIDENT_FAILURE";

export const SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST =
  "SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST";
export const SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS =
  "SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS";
export const SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE =
  "SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE";

export const INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST =
  "INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST";
export const INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS =
  "INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS";
export const INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE =
  "INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE";

export const CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST =
  "CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST";
export const CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS =
  "CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS";
export const CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE =
  "CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE";

export const CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST =
  "CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST";
export const CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS =
  "CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS";
export const CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE =
  "CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE";

export const CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST =
  "CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST";
export const CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS =
  "CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS";
export const CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE =
  "CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE";

export const SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST =
  "SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST";
export const SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS =
  "SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS";
export const SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE =
  "SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE";

// ..................Retrieve all assets............................................

export const ASSET_DETAILS_REQUEST = "ASSET_DETAILS_REQUEST";
export const ASSET_DETAILS_SUCCESS = "ASSET_DETAILS_SUCCESS";
export const ASSET_DETAILS_FAILURE = "ASSET_DETAILS_FAILURE";

// ..................Record incident image upload............................................

export const RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST =
  "RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST";
export const RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS =
  "RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS";
export const RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE =
  "RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE";

// ..................Record incident image upload............................................

export const INCIDENT_DELETE_REQUEST = "INCIDENT_DELETE_REQUEST";
export const INCIDENT_DELETE_SUCCESS = "INCIDENT_DELETE_SUCCESS";
export const INCIDENT_DELETE_FAILURE = "INCIDENT_DELETE_FAILURE";

// ..................Record incident image Delete............................................

export const RECORD_INCIDENT_IMAGE_DELETE_REQUEST =
  "RECORD_INCIDENT_IMAGE_DELETE_REQUEST";
export const RECORD_INCIDENT_IMAGE_DELETE_SUCCESS =
  "RECORD_INCIDENT_IMAGE_DELETE_SUCCESS";
export const RECORD_INCIDENT_IMAGE_DELETE_FAILURE =
  "RECORD_INCIDENT_IMAGE_DELETE_FAILURE";

// ..................Create Incident............................................

export const CREATE_INCIDENT_REQUEST = "CREATE_INCIDENT_REQUEST";
export const CREATE_INCIDENT_SUCCESS = "CREATE_INCIDENT_SUCCESS";
export const CREATE_INCIDENT_FAILURE = "CREATE_INCIDENT_FAILURE";

// ..................Get Incident History............................................

export const GET_INCIDENT_HISTORY_REQUEST = "GET_INCIDENT_HISTORY_REQUEST";
export const GET_INCIDENT_HISTORY_SUCCESS = "GET_INCIDENT_HISTORY_SUCCESS";
export const GET_INCIDENT_HISTORY_FAILURE = "GET_INCIDENT_HISTORY_FAILURE";

// ..................Get Dashboard Data............................................

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

//..................... Report..............................................

export const SERVICE_REPORT_REQUEST = "SERVICE_REPORT_REQUEST";
export const SERVICE_REPORT_SUCCESS = "SERVICE_REPORT_SUCCESS";
export const SERVICE_REPORT_FAILURE = "SERVICE_REPORT_FAILURE";

export const INCIDENT_REPORT_REQUEST = "INCIDENT_REPORT_REQUEST";
export const INCIDENT_REPORT_SUCCESS = "INCIDENT_REPORT_SUCCESS";
export const INCIDENT_REPORT_FAILURE = "INCIDENT_REPORT_FAILURE";

export const CALIBRATION_REPORT_REQUEST = "CALIBRATION_REPORT_REQUEST";
export const CALIBRATION_REPORT_SUCCESS = "CALIBRATION_REPORT_SUCCESS";
export const CALIBRATION_REPORT_FAILURE = "CALIBRATION_REPORT_FAILURE";

export const DEPARTMENT_ASSET_DETAILS_REQUEST =
  "DEPARTMENT_ASSET_DETAILS_REQUEST";
export const DEPARTMENT_ASSET_DETAILS_SUCCESS =
  "DEPARTMENT_ASSET_DETAILS_SUCCESS";
export const DEPARTMENT_ASSET_DETAILS_FAILURE =
  "DEPARTMENT_ASSET_DETAILS_FAILURE";

export const DEPARTMENT_USER_DETAILS_REQUEST =
  "DEPARTMENT_USER_DETAILS_REQUEST";
export const DEPARTMENT_USER_DETAILS_SUCCESS =
  "DEPARTMENT_USER_DETAILS_SUCCESS";
export const DEPARTMENT_USER_DETAILS_FAILURE =
  "DEPARTMENT_USER_DETAILS_FAILURE";

export const USER_RETRIEVE_BY_BRANCH_REQUEST =
  "USER_RETRIEVE_BY_BRANCH_REQUEST";
export const USER_RETRIEVE_BY_BRANCH_SUCCESS =
  "USER_RETRIEVE_BY_BRANCH_SUCCESS";
export const USER_RETRIEVE_BY_BRANCH_FAILURE =
  "DEPARTMENT_USER_RETRIEVE_BY_BRANCH_FAILURE";

export const GET_PM_ASSET_REQUEST = "GET_PM_ASSET_REQUEST";
export const GET_PM_ASSET_SUCCESS = "GET_PM_ASSET_SUCCESS";
export const GET_PM_ASSET_FAILURE = "GET_PM_ASSET_FAILURE";

//..................... Post pm check list..............................................

export const POST_PM_CHECKLIST_REQUEST = "POST_PM_CHECKLIST_REQUEST";
export const POST_PM_CHECKLIST_SUCCESS = "POST_PM_CHECKLIST_SUCCESS";
export const POST_PM_CHECKLIST_FAILURE = "POST_PM_CHECKLIST_FAILURE";

//..................... Post pm check list..............................................

export const PM_CHECKLIST_PDF_REQUEST = "PM_CHECKLIST_PDF_REQUEST";
export const PM_CHECKLIST_PDF_SUCCESS = "PM_CHECKLIST_PDF_SUCCESS";
export const PM_CHECKLIST_PDF_FAILURE = "PM_CHECKLIST_PDF_FAILURE";

// ...................Closed Incident..........................................

export const CLOSED_INCIDENT_REQUEST = "CLOSED_INCIDENT_REQUEST";
export const CLOSED_INCIDENT_SUCCESS = "CLOSED_INCIDENT_SUCCESS";
export const CLOSED_INCIDENT_FAILURE = "CLOSED_INCIDENT_FAILURE";

// ...................open Incident..........................................

export const OPEN_INCIDENT_REQUEST = "OPEN_INCIDENT_REQUEST";
export const OPEN_INCIDENT_SUCCESS = "OPEN_INCIDENT_SUCCESS";
export const OPEN_INCIDENT_FAILURE = "OPEN_INCIDENT_FAILURE";

// ...................warranty status..........................................

export const WARRANTY_STATUS_REQUEST = "WARRANTY_STATUS_REQUEST";
export const WARRANTY_STATUS_SUCCESS = "WARRANTY_STATUS_SUCCESS";
export const WARRANTY_STATUS_FAILURE = "WARRANTY_STATUS_FAILURE";

// ................... Request Manual..........................................

export const REQUEST_WARRANTY_REQUEST = "REQUEST_WARRANTY_REQUEST";
export const REQUEST_WARRANTY_SUCCESS = "REQUEST_WARRANTY_SUCCESS";
export const REQUEST_WARRANTY_FAILURE = "REQUEST_WARRANTY_FAILURE";

export const PROFILE_EDIT_REQUEST = "PROFILE_EDIT_REQUEST";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";

export const GET_INVALID_TOKEN_REQUEST = "GET_INVALID_TOKEN_REQUEST";
export const GET_INVALID_TOKEN_SUCCESS = "GET_INVALID_TOKEN_SUCCESS";
export const GET_INVALID_TOKEN_FAILURE = "GET_INVALID_TOKEN_FAILURE";

export const BIOMEDICAL_SERVICE_REQUEST = "BIOMEDICAL_SERVICE_REQUEST";
export const BIOMEDICAL_SERVICE_SUCCESS = "BIOMEDICAL_SERVICE_SUCCESS";
export const BIOMEDICAL_SERVICE_FAILURE = "BIOMEDICAL_SERVICE_FAILURE";
//-------------------------Clear Service Receipt-----------------------

export const CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST =
  "CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST";
export const CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS =
  "CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS";
export const CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE =
  "CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE";

//-------------------------Clear Service Receipt delete-----------------------

export const CLEAR_SERVICE_RECEIPT_DELETE_REQUEST =
  "CLEAR_SERVICE_RECEIPT_DELETE_REQUEST";
export const CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS =
  "CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS";
export const CLEAR_SERVICE_RECEIPT_DELETE_FAILURE =
  "CLEAR_SERVICE_RECEIPT_DELETE_FAILURE";

export const GET_AUTHENTICATION_ERROR_SUCCESS =
  "GET_AUTHENTICATION_ERROR_SUCCESS";

export const CLEAR_SERVICE_IMAGE_DELETE_REQUEST =
  "CLEAR_SERVICE_IMAGE_DELETE_REQUEST";
export const CLEAR_SERVICE_IMAGE_DELETE_SUCCESS =
  "CLEAR_SERVICE_IMAGE_DELETE_SUCCESS";
export const CLEAR_SERVICE_IMAGE_DELETE_FAILURE =
  "CLEAR_SERVICE_IMAGE_DELETE_FAILURE";

export const CSV_FILE_UPLOAD_REQUEST = "CSV_FILE_UPLOAD_REQUEST";
export const CSV_FILE_UPLOAD_SUCCESS = "CSV_FILE_UPLOAD_SUCCESS";
export const CSV_FILE_UPLOAD_FAILURE = "CSV_FILE_UPLOAD_FAILURE";

export const DELETE_CSV_FILE_UPLOAD_REQUEST = "DELETE_CSV_FILE_UPLOAD_REQUEST";
export const DELETE_CSV_FILE_UPLOAD_SUCCESS = "DELETE_CSV_FILE_UPLOAD_SUCCESS";
export const DELETE_CSV_FILE_UPLOAD_FAILURE = "DELETE_CSV_FILE_UPLOAD_FAILURE";

export const CSV_FILE_UPLOAD_SUBMIT_REQUEST = "CSV_FILE_UPLOAD_SUBMIT_REQUEST";
export const CSV_FILE_UPLOAD_SUBMIT_SUCCESS = "CSV_FILE_UPLOAD_SUBMIT_SUCCESS";
export const CSV_FILE_UPLOAD_SUBMIT_FAILURE = "CSV_FILE_UPLOAD_SUBMIT_FAILURE";

export const AMC_CMC_REQUEST = "AMC_CMC_REQUEST";
export const AMC_CMC_SUCCESS = "AMC_CMC_SUCCESS";
export const AMC_CMC_FAILURE = "AMC_CMC_FAILURE";

export const AMC_REQUEST = "AMC_REQUEST";
export const AMC_SUCCESS = "AMC_SUCCESS";
export const AMC_FAILURE = "AMC_FAILURE";

export const CMC_REQUEST = "CMC_REQUEST";
export const CMC_SUCCESS = "CMC_SUCCESS";
export const CMC_FAILURE = "CMC_FAILURE";

export const ASSET_HISTORY_REQUEST = "ASSET_HISTORY_REQUEST";
export const ASSET_HISTORY_SUCCESS = "ASSET_HISTORY_SUCCESS";
export const ASSET_HISTORY_FAILURE = "ASSET_HISTORY_FAILURE";

export const ASSET_HISTORY_RETRIEVE_REQUEST = "ASSET_HISTORY_RETRIEVE_REQUEST";
export const ASSET_HISTORY_RETRIEVE_SUCCESS = "ASSET_HISTORY_RETRIEVE_SUCCESS";
export const ASSET_HISTORY_RETRIEVE_FAILURE = "ASSET_HISTORY_RETRIEVE_FAILURE";

export const ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST =
  "ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST";
export const ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS =
  "ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS";
export const ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE =
  "ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE";

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILURE = "CREATE_TASK_FAILURE";

export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";

export const GET_VENDOR_REQUEST = "GET_VENDOR_REQUEST";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAILURE = "GET_VENDOR_FAILURE";

export const POST_VENDOR_REQUEST = "POST_VENDOR_REQUEST";
export const POST_VENDOR_SUCCESS = "POST_VENDOR_SUCCESS";
export const POST_VENDOR_FAILURE = "POST_VENDOR_FAILURE";

export const GET_VENDOR_SERVICE_REQUEST = "GET_VENDOR_SERVICE_REQUEST";
export const GET_VENDOR_SERVICE_SUCCESS = "GET_VENDOR_SERVICE_SUCCESS";
export const GET_VENDOR_SERVICE_FAILURE = "GET_VENDOR_SERVICE_FAILURE";

export const POST_VENDOR_SERVICE_REQUEST = "POST_VENDOR_SERVICE_REQUEST";
export const POST_VENDOR_SERVICE_SUCCESS = "POST_VENDOR_SERVICE_SUCCESS";
export const POST_VENDOR_SERVICE_FAILURE = "POST_VENDOR_SERVICE_FAILURE";

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const CHECKIN_REQUEST = "CHECKIN_REQUEST";
export const CHECKIN_SUCCESS = "CHECKIN_SUCCESS";
export const CHECKIN_FAILURE = "CHECKIN_FAILURE";

export const CHECKIN_CHECKOUT_REQUEST = "CHECKIN_CHECKOUT_REQUEST";
export const CHECKIN_CHECKOUT_SUCCESS = "CHECKIN_CHECKOUT_SUCCESS";
export const CHECKIN_CHECKOUT_FAILURE = "CHECKIN_CHECKOUT_FAILURE";

export const CHECKIN_CHECKOUT_DETAIL_REQUEST =
  "CHECKIN_CHECKOUT_DETAIL_REQUEST";
export const CHECKIN_CHECKOUT_DETAIL_SUCCESS =
  "CHECKIN_CHECKOUT_DETAIL_SUCCESS";
export const CHECKIN_CHECKOUT_DETAIL_FAILURE =
  "CHECKIN_CHECKOUT_DETAIL_FAILURE";

export const CALENDER_REQUEST = "CALENDER_REQUEST";
export const CALENDER_SUCCESS = "CALENDER_SUCCESS";
export const CALENDER_FAILURE = "CALENDER_FAILURE";

export const CHECKIN_PDF_REQUEST = "CHECKIN_PDF_REQUEST";
export const CHECKIN_PDF_SUCCESS = "CHECKIN_PDF_SUCCESS";
export const CHECKIN_PDF_FAILURE = "CHECKIN_PDF_FAILURE";

export const AMC_CMC_REQ_REQUEST = "AMC_CMC_REQ_REQUEST";
export const AMC_CMC_REQ_SUCCESS = "AMC_CMC_REQ_SUCCESS";
export const AMC_CMC_REQ_FAILURE = "AMC_CMC_REQ_FAILURE";

export const CALIBRATION_REQ_REQUEST = "CALIBRATION_REQ_REQUEST";
export const CALIBRATION_REQ_SUCCESS = "CALIBRATION_REQ_SUCCESS";
export const CALIBRATION_REQ_FAILURE = "CALIBRATION_REQ_FAILURE";

export const ASSET_ADD_REPORT_REQUEST = "ASSET_ADD_REPORT_REQUEST";
export const ASSET_ADD_REPORT_SUCCESS = "ASSET_ADD_REPORT_SUCCESS";
export const ASSET_ADD_REPORT_FAILURE = "ASSET_ADD_REPORT_FAILURE";

export const ASSET_DELETE_REPORT_REQUEST = "ASSET_DELETE_REPORT_REQUEST";
export const ASSET_DELETE_REPORT_SUCCESS = "ASSET_DELETE_REPORT_SUCCESS";
export const ASSET_DELETE_REPORT_FAILURE = "ASSET_DELETE_REPORT_FAILURE";

export const WARRANTY_REQUEST_REQUEST = "WARRANTY_REQUEST_REQUEST";
export const WARRANTY_REQUEST_SUCCESS = "WARRANTY_REQUEST_SUCCESS";
export const WARRANTY_REQUEST_FAILURE = "WARRANTY_REQUEST_FAILURE";

export const WARRANTY_TASK_COMPLETE_REQUEST = "WARRANTY_TASK_COMPLETE_REQUEST";
export const WARRANTY_TASK_COMPLETE_SUCCESS = "WARRANTY_TASK_COMPLETE_SUCCESS";
export const WARRANTY_TASK_COMPLETE_FAILURE = "WARRANTY_TASK_COMPLETE_FAILURE";

export const AMC_CMC_TASK_COMPLETE_REQUEST = "AMC_CMC_TASK_COMPLETE_REQUEST";
export const AMC_CMC_TASK_COMPLETE_SUCCESS = "AMC_CMC_TASK_COMPLETE_SUCCESS";
export const AMC_CMC_TASK_COMPLETE_FAILURE = "AMC_CMC_TASK_COMPLETE_FAILURE";

export const CALIBRATION_TASK_COMPLETE_REQUEST =
  "CALIBRATION_TASK_COMPLETE_REQUEST";
export const CALIBRATION_TASK_COMPLETE_SUCCESS =
  "CALIBRATION_TASK_COMPLETE_SUCCESS";
export const CALIBRATION_TASK_COMPLETE_FAILURE =
  "CALIBRATION_TASK_COMPLETE_FAILURE";

export const UPDATE_REPORT_REQUEST = "UPDATE_REPORT_REQUEST";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAILURE = "UPDATE_REPORT_FAILURE";

export const BULK_ASSET_DELETE_REQUEST = "BULK_ASSET_DELETE_REQUEST";
export const BULK_ASSET_DELETE_SUCCESS = "BULK_ASSET_DELETE_SUCCESS";
export const BULK_ASSET_DELETE_FAILURE = "BULK_ASSET_DELETE_FAILURE";

export const BULK_DEPARTMENT_UPDATE_REQUEST = "BULK_DEPARTMENT_UPDATE_REQUEST";
export const BULK_DEPARTMENT_UPDATE_SUCCESS = "BULK_DEPARTMENT_UPDATE_SUCCESS";
export const BULK_DEPARTMENT_UPDATE_FAILURE = "BULK_DEPARTMENT_UPDATE_FAILURE";

export const RETRIEVE_ASSET_REQUEST = "RETRIEVE_ASSET_REQUEST";
export const RETRIEVE_ASSET_SUCCESS = "RETRIEVE_ASSET_SUCCESS";
export const RETRIEVE_ASSET_FAILURE = "RETRIEVE_ASSET_FAILURE";

export const RETRIEVE_SERVICE_ASSET_REQUEST = "RETRIEVE_SERVICE_ASSET_REQUEST";
export const RETRIEVE_SERVICE_ASSET_SUCCESS = "RETRIEVE_SERVICE_ASSET_SUCCESS";
export const RETRIEVE_SERVICE_ASSET_FAILURE = "RETRIEVE_SERVICE_ASSET_FAILURE";

export const USER_DETAIL_CHECK_REQUEST = "USER_DETAIL_CHECK_REQUEST";
export const USER_DETAIL_CHECK_SUCCESS = "USER_DETAIL_CHECK_SUCCESS";
export const USER_DETAIL_CHECK_FAILURE = "USER_DETAIL_CHECK_FAILURE";

export const CREATE_SUPERADMIN_REQUEST = "CREATE_SUPERADMIN_REQUEST";
export const CREATE_SUPERADMIN_SUCCESS = "CREATE_SUPERADMIN_SUCCESS";
export const CREATE_SUPERADMIN_FAILURE = "CREATE_SUPERADMIN_FAILURE";

export const ALL_USER_LIST_REQUEST = "ALL_USER_LIST_REQUEST";
export const ALL_USER_LIST_SUCCESS = "ALL_USER_LIST_SUCCESS";
export const ALL_USER_LIST_FAILURE = "ALL_USER_LIST_FAILURE";

export const GET_DASHBOARD_LIST_REQUEST = "GET_DASHBOARD_LIST_REQUEST";
export const GET_DASHBOARD_LIST_SUCCESS = "GET_DASHBOARD_LIST_SUCCESS";
export const GET_DASHBOARD_LIST_FAILURE = "GET_DASHBOARD_LIST_FAILURE";

export const GET_DASHBOARD_LIST2_REQUEST = "GET_DASHBOARD_LIST2_REQUEST";
export const GET_DASHBOARD_LIST2_SUCCESS = "GET_DASHBOARD_LIST2_SUCCESS";
export const GET_DASHBOARD_LIST2_FAILURE = "GET_DASHBOARD_LIST2_FAILURE";

export const CREATE_SUBUSER_TO_SUPERADMIN_REQUEST =
  "CREATE_SUBUSER_TO_SUPERADMIN_REQUEST";
export const CREATE_SUBUSER_TO_SUPERADMIN_SUCCESS =
  "CREATE_SUBUSER_TO_SUPERADMIN_SUCCESS";
export const CREATE_SUBUSER_TO_SUPERADMIN_FAILURE =
  "CREATE_SUBUSER_TO_SUPERADMIN_FAILURE";

export const UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST =
  "UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST";
export const UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS =
  "UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS";
export const UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE =
  "UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE";

export const POST_NOTIFICATION_TOKEN_REQUEST =
  "POST_NOTIFICATION_TOKEN_REQUEST";
export const POST_NOTIFICATION_TOKEN_SUCCESS =
  "POST_NOTIFICATION_TOKEN_SUCCESS";
export const POST_NOTIFICATION_TOKEN_FAILURE =
  "POST_NOTIFICATION_TOKEN_FAILURE";

export const REQUEST_LIST_REQUEST = "REQUEST_LIST_REQUEST";
export const REQUEST_LIST_SUCCESS = "REQUEST_LIST_SUCCESS";
export const REQUEST_LIST_FAILURE = "REQUEST_LIST_FAILURE";

export const CREATE_SUBUSER_REQUEST = "CREATE_SUBUSER_REQUEST";
export const CREATE_SUBUSER_SUCCESS = "CREATE_SUBUSER_SUCCESS";
export const CREATE_SUBUSER_FAILURE = "CREATE_SUBUSER_FAILURE";

export const GET_NEW_ASSETS_REQUEST = "GET_NEW_ASSETS_REQUEST";
export const GET_NEW_ASSETS_SUCCESS = "GET_NEW_ASSETS_SUCCESS";
export const GET_NEW_ASSETS_FAILURE = "GET_NEW_ASSETS_FAILURE";

export const GET_MONTHLY_REPORT_REQUEST = "GET_MONTHLY_REPORT_REQUEST";
export const GET_MONTHLY_REPORT_SUCCESS = "GET_MONTHLY_REPORT_SUCCESS";
export const GET_MONTHLY_REPORT_FAILURE = "GET_MONTHLY_REPORT_FAILURE";

export const ADD_ACCESSORY_UPLOAD_FILE = "ADD_ACCESSORY_UPLOAD_FILE";
export const ADD_ACCESSORY_UPLOAD_FILE_HISTORY =
  "ADD_ACCESSORY_UPLOAD_FILE_HISTORY";
export const ADD_ACCESSORY_UPDATE = "ADD_ACCESSORY_UPDATE";
export const ADD_ACCESSORY_UPDATE_HISTORY = "ADD_ACCESSORY_UPDATE_HISTORY";
export const ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS =
  "ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS";
export const ADD_ACCESSORY_UPDATE_HISTORY_FAILURE =
  "ADD_ACCESSORY_UPDATE_HISTORY_FAILURE";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const DOWNLOAD_PDF_REQUEST_HISTORY = "DOWNLOAD_PDF_REQUEST_HISTORY";
export const DOWNLOAD_PDF_SUCCESS_HISTORY = "DOWNLOAD_PDF_SUCCESS_HISTORY";
export const DOWNLOAD_PDF_FAILURE_HISTORY = "DOWNLOAD_PDF_FAILURE_HISTORY";

export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";

export const GET_REQUEST_DASHBOARD = "GET_REQUEST_DASHBOARD";
export const GET_REQUEST_DASHBOARD_SUCCESS = "GET_REQUEST_DASHBOARD_SUCCESS";

export const SELL_ONLINE_ASSET_REQUEST = "SELL_ONLINE_ASSET_REQUEST";
export const SELL_ONLINE_ASSET_SUCCESS = "SELL_ONLINE_ASSET_SUCCESS";
export const SELL_ONLINE_ASSET_FAILURE = "SELL_ONLINE_ASSET_FAILURE";

export const GET_RETRIEVE_INCIDENT_ASSETS_REQUEST =
  "GET_RETRIEVE_INCIDENT_ASSETS_REQUEST";
export const GET_RETRIEVE_INCIDENT_ASSETS_SUCCESS =
  "GET_RETRIEVE_INCIDENT_ASSETS_SUCCESS";
export const GET_RETRIEVE_INCIDENT_ASSETS_FAILURE =
  "GET_RETRIEVE_INCIDENT_ASSETS_FAILURE";

export const CHECKOUT_ASSET_REQUEST = "CHECKOUT_ASSET_REQUEST";
export const CHECKOUT_ASSET_SUCCESS = " CHECKOUT_ASSET_SUCCESS";
export const CHECKOUT_ASSET_FAILURE = " CHECKOUT_ASSET_FAILURE";

// ..................not working assets............................................

export const NOT_WORKING_ASSETS_REQUEST = "NOT_WORKING_ASSETS_REQUEST";
export const NOT_WORKING_ASSETS_SUCCESS = "NOT_WORKING_ASSETS_SUCCESS";
export const NOT_WORKING_ASSETS_FAILURE = "NOT_WORKING_ASSETS_FAILURE";

// ..................Discarded assets............................................

export const DISCARDED_ASSETS_REQUEST = "DISCARDED_ASSETS_REQUEST";
export const DISCARDED_ASSETS_SUCCESS = "DISCARDED_ASSETS_SUCCESS";
export const DISCARDED_ASSETS_FAILURE = "DISCARDED_ASSETS_FAILURE";

// ..................assets delete............................................

export const ASSETS_DELETE_REQUEST = "ASSETS_DELETE_REQUEST";
export const ASSETS_DELETE_SUCCESS = "ASSETS_DELETE_SUCCESS";
export const ASSETS_DELETE_FAILURE = "ASSETS_DELETE_FAILURE";

export const CHAT_BOT_REQUEST = "CHAT_BOT_REQUEST";
export const CHAT_BOT_SUCCESS = "CHAT_BOT_SUCCESS";
export const CHAT_BOT_FAILURE = "CHAT_BOT_FAILURE";

// ..................Deleted Assets Data .....................................

export const DELETEDDATA_ASSET_REQUEST = "DELETEDDATA_ASSET_REQUEST";
export const DELETEDDATA_ASSET_SUCCESS = "DELETEDDATA_ASSET_SUCCESS";
export const DELETEDDATA_ASSET_FAILURE = "DELETEDDATA_ASSET_FAILURE";

// ..................Deleted IncidentList............................................

export const DELETED_INCIDENT_REQUEST = "DELETED_INCIDENT_REQUEST";
export const DELETED_INCIDENT_SUCCESS = "DELETED_INCIDENT_SUCCESS";
export const DELETED_INCIDENT_FAILURE = "DELETED_INCIDENT_FAILURE";

// ..................Movement Approved ............................................

export const MOVEMENT_APPROVE_REQUEST = "MOVEMENT_APPROVE_REQUEST";
export const MOVEMENT_APPROVE_SUCCESS = "MOVEMENT_APPROVE_SUCCESS";
export const MOVEMENT_APPROVE_FAILURE = "MOVEMENT_APPROVE_FAILURE";

// ..................Movement Delete ............................................

export const MOVEMENT_DELETE_REQUEST = "MOVEMENT_DELETE_REQUEST";
export const MOVEMENT_DELETE_SUCCESS = "MOVEMENT_DELETE_SUCCESS";
export const MOVEMENT_DELETE_FAILURE = "MOVEMENT_DELETE_FAILURE";

// ..................Sell Online Pending ............................................

export const SELL_ONLINE_PRENDING_REQUEST = "SELL_ONLINE_PRENDING_REQUEST";
export const SELL_ONLINE_PRENDING_SUCCESS = "SELL_ONLINE_PRENDING_SUCCESS";
export const SELL_ONLINE_PRENDING_FAILURE = "SELL_ONLINE_PRENDING_FAILURE";

// ..................Sell Online Approved ............................................

export const SELL_ONLINE_Approved_REQUEST = "SELL_ONLINE_Approved_REQUEST";
export const SELL_ONLINE_Approved_SUCCESS = "SELL_ONLINE_Approved_SUCCESS";
export const SELL_ONLINE_Approved_FAILURE = "SELL_ONLINE_Approved_FAILURE";

// ..................Sell Online Approve ............................................

export const SELL_ONLINE_Approve_REQUEST = "SELL_ONLINE_Approve_REQUEST";
export const SELL_ONLINE_Approve_SUCCESS = "SELL_ONLINE_Approve_SUCCESS";
export const SELL_ONLINE_Approve_FAILURE = "SELL_ONLINE_Approve_FAILURE";

// ..................Department All ............................................

export const DEPARTMENT_ALL_REQUEST = "DEPARTMENT_ALL_REQUEST";
export const DEPARTMENT_ALL_SUCCESS = "DEPARTMENT_ALL_SUCCESS";
export const DEPARTMENT_ALL_FAILURE = "DEPARTMENT_ALL_FAILURE";

// .................. Incident Check ............................................

export const INICDENT_CHECK_REQUEST = "INICDENT_CHECK_REQUEST";
export const INICDENT_CHECK_SUCCESS = "INICDENT_CHECK_SUCCESS";
export const INICDENT_CHECK_FAILURE = "INICDENT_CHECK_FAILURE";

// .................. Incident Acknowledge ............................................

export const INICDENT_ACKNOWLEDGE_REQUEST = "INICDENT_ACKNOWLEDGE_REQUEST";
export const INICDENT_ACKNOWLEDGE_SUCCESS = "INICDENT_ACKNOWLEDGE_SUCCESS";
export const INICDENT_ACKNOWLEDGE_FAILURE = "INICDENT_ACKNOWLEDGE_FAILURE";

// .................. Service Work Order ............................................

export const SERVICE_WORK_ORDER_REQUEST = "SERVICE_WORK_ORDER_REQUEST";
export const SERVICE_WORK_ORDER_SUCCESS = "SERVICE_WORK_ORDER_SUCCESS";
export const SERVICE_WORK_ORDER_FAILURE = "SERVICE_WORK_ORDER_FAILURE";

// ..................Add Service Work Order ............................................

export const ADD_SERVICE_WORK_ORDER_REQUEST = "ADD_SERVICE_WORK_ORDER_REQUEST";
export const ADD_SERVICE_WORK_ORDER_SUCCESS = "ADD_SERVICE_WORK_ORDER_SUCCESS";
export const ADD_SERVICE_WORK_ORDER_FAILURE = "ADD_SERVICE_WORK_ORDER_FAILURE";

// ..................Add Equipment Audit ............................................

export const ADD_EQUIPMENT_AUDIT_REQUEST = "ADD_EQUIPMENT_AUDIT_REQUEST";
export const ADD_EQUIPMENT_AUDIT_SUCCESS = "ADD_EQUIPMENT_AUDIT_SUCCESS";
export const ADD_EQUIPMENT_AUDIT_FAILURE = "ADD_EQUIPMENT_AUDIT_FAILURE";

// .................. Equipment Audit List............................................

export const EQUIPMENT_AUDIT_LIST_REQUEST = "EQUIPMENT_AUDIT_LIST_REQUEST";
export const EQUIPMENT_AUDIT_LIST_SUCCESS = "EQUIPMENT_AUDIT_LIST_SUCCESS";
export const EQUIPMENT_AUDIT_LIST_FAILURE = "EQUIPMENT_AUDIT_LIST_FAILURE";

// .................. Equipment Audit Approve............................................

export const EQUIPMENT_AUDIT_APPROVE_REQUEST =
  "EQUIPMENT_AUDIT_APPROVE_REQUEST";
export const EQUIPMENT_AUDIT_APPROVE_SUCCESS =
  "EQUIPMENT_AUDIT_APPROVE_SUCCESS";
export const EQUIPMENT_AUDIT_APPROVE_FAILURE =
  "EQUIPMENT_AUDIT_APPROVE_FAILURE";

// .................. Create Equipment Audit ............................................

export const CREATE_EQUIPMENT_AUDIT_REQUEST = "CREATE_EQUIPMENT_AUDIT_REQUEST";
export const CREATE_EQUIPMENT_AUDIT_SUCCESS = "CREATE_EQUIPMENT_AUDIT_SUCCESS";
export const CREATE_EQUIPMENT_AUDIT_FAILURE = "CREATE_EQUIPMENT_AUDIT_FAILURE";

// .................. Department Audit List............................................

export const DEPARTMENT_AUDIT_LIST_REQUEST = "DEPARTMENT_AUDIT_LIST_REQUEST";
export const DEPARTMENT_AUDIT_LIST_SUCCESS = "DEPARTMENT_AUDIT_LIST_SUCCESS";
export const DEPARTMENT_AUDIT_LIST_FAILURE = "DEPARTMENT_AUDIT_LIST_FAILURE";

// .................. Asset Audit List............................................

export const ASSET_AUDIT_LIST_REQUEST = "ASSET_AUDIT_LIST_REQUEST";
export const ASSET_AUDIT_LIST_SUCCESS = "ASSET_AUDIT_LIST_SUCCESS";
export const ASSET_AUDIT_LIST_FAILURE = "ASSET_AUDIT_LIST_FAILURE";

// .................. Asset Audit Ignore............................................

export const ASSET_AUDIT_IGNORE_REQUEST = "ASSET_AUDIT_IGNORE_REQUEST";
export const ASSET_AUDIT_IGNORE_SUCCESS = "ASSET_AUDIT_IGNORE_SUCCESS";
export const ASSET_AUDIT_IGNORE_FAILURE = "ASSET_AUDIT_IGNORE_FAILURE";

// .................. Asset Audit Approve List............................................

export const ASSET_AUDIT_APPROVE_LIST_REQUEST =
  "ASSET_AUDIT_APPROVE_LIST_REQUEST";
export const ASSET_AUDIT_APPROVE_LIST_SUCCESS =
  "ASSET_AUDIT_APPROVE_LIST_SUCCESS";
export const ASSET_AUDIT_APPROVE_LIST_FAILURE =
  "ASSET_AUDIT_APPROVE_LIST_FAILURE";

// .................. Asset Audit Ignore List............................................

export const ASSET_AUDIT_IGNORE_LIST_REQUEST =
  "ASSET_AUDIT_IGNORE_LIST_REQUEST";
export const ASSET_AUDIT_IGNORE_LIST_SUCCESS =
  "ASSET_AUDIT_IGNORE_LIST_SUCCESS";
export const ASSET_AUDIT_IGNORE_LIST_FAILURE =
  "ASSET_AUDIT_IGNORE_LIST_FAILURE";

// .................. Audit Request Approve............................................

export const AUDIT_REQUEST_APPROVE_REQUEST = "AUDIT_REQUEST_APPROVE_REQUEST";
export const AUDIT_REQUEST_APPROVE_SUCCESS = "AUDIT_REQUEST_APPROVE_SUCCESS";
export const AUDIT_REQUEST_APPROVE_FAILURE = "AUDIT_REQUEST_APPROVE_FAILURE";

// .................. Audit Completed............................................

export const AUDIT_COMPLETED_REQUEST = "AUDIT_COMPLETED_REQUEST";
export const AUDIT_COMPLETED_SUCCESS = "AUDIT_COMPLETED_SUCCESS";
export const AUDIT_COMPLETED_FAILURE = "AUDIT_COMPLETED_FAILURE";

// .................. Audit Details............................................

export const AUDIT_DETAILS_REQUEST = "AUDIT_DETAILS_REQUEST";
export const AUDIT_DETAILS_SUCCESS = "AUDIT_DETAILS_SUCCESS";
export const AUDIT_DETAILS_FAILURE = "AUDIT_DETAILS_FAILURE";

// .................. Grade Update............................................

export const GRADE_UPDATE_REQUEST = "GRADE_UPDATE_REQUEST";
export const GRADE_UPDATE_SUCCESS = "GRADE_UPDATE_SUCCESS";
export const GRADE_UPDATE_FAILURE = "GRADE_UPDATE_FAILURE";

// ..................Accessory Upload Image............................................

export const ACCESSORY_UPLOAD_IMAGE_REQUEST = "ACCESSORY_UPLOAD_IMAGE_REQUEST";
export const ACCESSORY_UPLOAD_IMAGE_SUCCESS = "ACCESSORY_UPLOAD_IMAGE_SUCCESS";
export const ACCESSORY_UPLOAD_IMAGE_FAILURE = "ACCESSORY_UPLOAD_IMAGE_FAILURE";

// ..................Accessory Delete Image............................................

export const ACCESSORY_DELETE_IMAGE_REQUEST = "ACCESSORY_DELETE_IMAGE_REQUEST";
export const ACCESSORY_DELETE_IMAGE_SUCCESS = "ACCESSORY_DELETE_IMAGE_SUCCESS";
export const ACCESSORY_DELETE_IMAGE_FAILURE = "ACCESSORY_DELETE_IMAGE_FAILURE";

// ..................Create Accessory............................................

export const CREATE_ACCESSORY_REQUEST = "CREATE_ACCESSORY_REQUEST";
export const CREATE_ACCESSORY_SUCCESS = "CREATE_ACCESSORY_SUCCESS";
export const CREATE_ACCESSORY_FAILURE = "CREATE_ACCESSORY_FAILURE";

// ..................Create Accessory Name List............................................

export const CREATE_ACCESSORY_NAME_LIST_REQUEST =
  "CREATE_ACCESSORY_NAME_LIST_REQUEST";
export const CREATE_ACCESSORY_NAME_LIST_SUCCESS =
  "CREATE_ACCESSORY_NAME_LIST_SUCCESS";
export const CREATE_ACCESSORY_NAME_LIST_FAILURE =
  "CREATE_ACCESSORY_NAME_LIST_FAILURE";

// ..................Audit Upload Image ............................................

export const AUDIT_UPLOAD_IMAGE_REQUEST = "AUDIT_UPLOAD_IMAGE_REQUEST";
export const AUDIT_UPLOAD_IMAGE_SUCCESS = "AUDIT_UPLOAD_IMAGE_SUCCESS";
export const AUDIT_UPLOAD_IMAGE_FAILURE = "AUDIT_UPLOAD_IMAGE_FAILURE";

// ..................Audit Delete Image ............................................

export const AUDIT_DELETE_IMAGE_REQUEST = "AUDIT_DELETE_IMAGE_REQUEST";
export const AUDIT_DELETE_IMAGE_SUCCESS = "AUDIT_DELETE_IMAGE_SUCCESS";
export const AUDIT_DELETE_IMAGE_FAILURE = "AUDIT_DELETE_IMAGE_FAILURE";

// ..................Accesssory Available List............................................

export const ACCESSORY_AVAILABLE_LIST_REQUEST =
  "ACCESSORY_AVAILABLE_LIST_REQUEST";
export const ACCESSORY_AVAILABLE_LIST_SUCCESS =
  "ACCESSORY_AVAILABLE_LIST_SUCCESS";
export const ACCESSORY_AVAILABLE_LIST_FAILURE =
  "ACCESSORY_AVAILABLE_LIST_FAILURE";

// ..................Retive Incident Details............................................

export const RETRIVE_INCIDENT_DETAILS_REQUEST =
  "RETRIVE_INCIDENT_DETAILS_REQUEST";
export const RETRIVE_INCIDENT_DETAILS_SUCCESS =
  "RETRIVE_INCIDENT_DETAILS_SUCCESS";
export const RETRIVE_INCIDENT_DETAILS_FAILURE =
  "RETRIVE_INCIDENT_DETAILS_FAILURE";

// ..................Edit Clear Incident............................................

export const EDIT_CLEAR_INCIDENT_REQUEST = "EDIT_CLEAR_INCIDENT_REQUEST";
export const EDIT_CLEAR_INCIDENT_SUCCESS = "EDIT_CLEAR_INCIDENT_SUCCESS";
export const EDIT_CLEAR_INCIDENT_FAILURE = "EDIT_CLEAR_INCIDENT_FAILURE";

// ..................Accessory Update List............................................

export const ACCESSORY_UPDATE_LIST_REQUEST = "ACCESSORY_UPDATE_LIST_REQUEST";
export const ACCESSORY_UPDATE_LIST_SUCCESS = "ACCESSORY_UPDATE_LIST_SUCCESS";
export const ACCESSORY_UPDATE_LIST_FAILURE = "ACCESSORY_UPDATE_LIST_FAILURE";

// ..................Add Work Order............................................

export const ADD_WORK_ORDER_REQUEST = "ADD_WORK_ORDER_REQUEST";
export const ADD_WORK_ORDER_SUCCESS = "ADD_WORK_ORDER_SUCCESS";
export const ADD_WORK_ORDER_FAILURE = "ADD_WORK_ORDER_FAILURE";

// ..................Accessory List............................................

export const ACCESSORY_LIST_REQUEST = "ACCESSORY_LIST_REQUEST";
export const ACCESSORY_LIST_SUCCESS = "ACCESSORY_LIST_SUCCESS";
export const ACCESSORY_LIST_FAILURE = "ACCESSORY_LIST_FAILURE";

// ..................Approve Incident............................................

export const APPROVE_INCIDENT_REQUEST = "APPROVE_INCIDENT_REQUEST";
export const APPROVE_INCIDENT_SUCCESS = "APPROVE_INCIDENT_SUCCESS";
export const APPROVE_INCIDENT_FAILURE = "APPROVE_INCIDENT_FAILURE";

// ..................Sell Online Add Image............................................

export const SELL_ONLINE_ADD_IMAGE_REQUEST = "SELL_ONLINE_ADD_IMAGE_REQUEST";
export const SELL_ONLINE_ADD_IMAGE_SUCCESS = "SELL_ONLINE_ADD_IMAGE_SUCCESS";
export const SELL_ONLINE_ADD_IMAGE_FAILURE = "SELL_ONLINE_ADD_IMAGE_FAILURE";

// ..................Sell Online Delete Image............................................

export const SELL_ONLINE_DELETE_IMAGE_REQUEST =
  "SELL_ONLINE_DELETE_IMAGE_REQUEST";
export const SELL_ONLINE_DELETE_IMAGE_SUCCESS =
  "SELL_ONLINE_DELETE_IMAGE_SUCCESS";
export const SELL_ONLINE_DELETE_IMAGE_FAILURE =
  "SELL_ONLINE_DELETE_IMAGE_FAILURE";

// ..................Department Asset List............................................

export const DEPARTMENT_ASSET_LIST_REQUEST = "DEPARTMENT_ASSET_LIST_REQUEST";
export const DEPARTMENT_ASSET_LIST_SUCCESS = "DEPARTMENT_ASSET_LIST_SUCCESS";
export const DEPARTMENT_ASSET_LIST_FAILURE = "DEPARTMENT_ASSET_LIST_FAILURE";

// ..................Discard Requested ............................................

export const DISCARD_REQUESTED_REQUEST = "DISCARD_REQUESTED_REQUEST";
export const DISCARD_REQUESTED_SUCCESS = "DISCARD_REQUESTED_SUCCESS";
export const DISCARD_REQUESTED_FAILURE = "DISCARD_REQUESTED_FAILURE";

// ..................Discard Pending List............................................

export const DISCARD_PENDING_REQUEST = "DISCARD_PENDING_REQUEST";
export const DISCARD_PENDING_SUCCESS = "DISCARD_PENDING_SUCCESS";
export const DISCARD_PENDING_FAILURE = "DISCARD_PENDING_FAILURE";

// ..................Discard Approve ............................................

export const DISCARD_Approve_REQUEST = "DISCARD_Approve_REQUEST";
export const DISCARD_Approve_SUCCESS = "DISCARD_Approve_SUCCESS";
export const DISCARD_Approve_FAILURE = "DISCARD_Approve_FAILURE";

// ..................Discard Approved List ............................................

export const DISCARD_Approved_REQUEST = "DISCARD_Approved_REQUEST";
export const DISCARD_Approved_SUCCESS = "DISCARD_Approved_SUCCESS";
export const DISCARD_Approved_FAILURE = "DISCARD_Approved_FAILURE";

// ..................Department Deleted List............................................

export const DEPARTMENT_DELETE_LIST_REQUEST = "DEPARTMENT_DELETE_LIST_REQUEST";
export const DEPARTMENT_DELETE_LIST_SUCCESS = "DEPARTMENT_DELETE_LIST_SUCCESS";
export const DEPARTMENT_DELETE_LIST_FAILURE = "DEPARTMENT_DELETE_LIST_FAILURE";

// ..................Notification List............................................

export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAILURE = "NOTIFICATION_LIST_FAILURE";

// ..................Notification All List............................................

export const NOTIFICATION_ALL_LIST_REQUEST = "NOTIFICATION_ALL_LIST_REQUEST";
export const NOTIFICATION_ALL_LIST_SUCCESS = "NOTIFICATION_ALL_LIST_SUCCESS";
export const NOTIFICATION_ALL_LIST_FAILURE = "NOTIFICATION_ALL_LIST_FAILURE";

// ................. Delete AMC/CMC Image Upload ............................................

export const DELETE_AMCCMC_IMAGE_REQUEST = "DELETE_AMCCMC_IMAGE_REQUEST";
export const DELETE_AMCCMC_IMAGE__SUCCESS = "DELETE_AMCCMC_IMAGE__SUCCESS";
export const DELETE_AMCCMC_IMAGE_FAILURE = "DELETE_AMCCMC_IMAGE_FAILURE";

//   AddAsset AMC/CMC Image Upload
export const UPLOADEDAMCCMC_IMAGE_REQUEST = "UPLOADEDAMCCMC_IMAGE_REQUEST";
export const UPLOADEDAMCCMC_IMAGE__SUCCESS = "UPLOADEDAMCCMC_IMAGE__SUCCESS";
export const UPLOADEDAMCCMC_IMAGE_FAILURE = "UPLOADEDAMCCMC_IMAGE_FAILURE";

export const SET_NAVIGATION_SOURCE = "SET_NAVIGATION_SOURCE";

export const setNavigationSource = (source) => ({
  type: SET_NAVIGATION_SOURCE,
  payload: source,
});

export const sellOnlineAssetRequest = () => {
  return {
    type: SELL_ONLINE_ASSET_REQUEST,
  };
};

export const sellOnlineAssetSuccess = (data) => {
  return {
    type: SELL_ONLINE_ASSET_SUCCESS,
    payload: data,
  };
};

export const sellOnlineAssetFailure = (error) => {
  return {
    type: SELL_ONLINE_ASSET_SUCCESS,
    payload: error,
  };
};

export const DownloadPDFRequestHistory = () => ({
  type: DOWNLOAD_PDF_REQUEST_HISTORY,
});

export const DownloadPDFSuccessHistory = (data) => ({
  type: DOWNLOAD_PDF_SUCCESS_HISTORY,
  payload: data,
});

export const DownloadPDFFailureHistory = (error) => ({
  type: DOWNLOAD_PDF_FAILURE_HISTORY,
  payload: error,
});

const addAccessoryUploadFile = () => {
  return { type: ADD_ACCESSORY_UPLOAD_FILE };
};

const addAccessoryUploadFileHistory = () => {
  return { type: ADD_ACCESSORY_UPLOAD_FILE_HISTORY };
};

const uploadUserProfileImage = () => {
  return { type: UPLOAD_PROFILE_IMAGE };
};

const updateUserProfileImage = () => {
  return { type: UPDATE_PROFILE_IMAGE };
};

const addAccessoryUpdate = () => {
  return { type: ADD_ACCESSORY_UPDATE };
};

const addAccessoryUpdateHistory = () => {
  return { type: ADD_ACCESSORY_UPDATE_HISTORY };
};

const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

const getDashboardListRequest = () => {
  return { type: GET_DASHBOARD_LIST_REQUEST };
};

const getDashboardListSuccess = (data) => {
  return { type: GET_DASHBOARD_LIST_SUCCESS, payload: data };
};

const getDashboardListFailure = (error) => {
  return { type: GET_DASHBOARD_LIST_FAILURE, payload: error };
};

const getMonthlyReportRequest = () => {
  return { type: GET_MONTHLY_REPORT_REQUEST };
};

const getMonthlyReportSuccess = (data) => {
  return { type: GET_MONTHLY_REPORT_SUCCESS, payload: data };
};

const getMonthlyReportFailure = (error) => {
  return { type: GET_MONTHLY_REPORT_FAILURE, payload: error };
};

const getDashboardList2Request = () => {
  return { type: GET_DASHBOARD_LIST2_REQUEST };
};

const getDashboardList2Success = (data) => {
  return { type: GET_DASHBOARD_LIST2_SUCCESS, payload: data };
};

const getDashboardList2Failure = (error) => {
  return { type: GET_DASHBOARD_LIST2_FAILURE, payload: error };
};

const getNewAssetsRequest = () => {
  return { type: GET_NEW_ASSETS_REQUEST };
};

const getNewAssetsSuccess = (data) => {
  return { type: GET_NEW_ASSETS_SUCCESS, payload: data };
};

const getNewAssetsFailure = (error) => {
  return { type: GET_NEW_ASSETS_FAILURE, payload: error };
};

const postNotificationTokenRequest = () => {
  return { type: POST_NOTIFICATION_TOKEN_REQUEST };
};

const postNotificationTokenSuccess = (data) => {
  return { type: POST_NOTIFICATION_TOKEN_SUCCESS, payload: data };
};

const postNotificationTokenFailure = (error) => {
  return { type: POST_NOTIFICATION_TOKEN_FAILURE, payload: error };
};

const bulkAssetDeleteRequest = () => {
  return { type: BULK_ASSET_DELETE_REQUEST };
};

const bulkAssetDeleteSuccess = (data) => {
  return { type: BULK_ASSET_DELETE_SUCCESS, payload: data };
};

const bulkAssetDeleteFailure = (error) => {
  return { type: BULK_ASSET_DELETE_FAILURE, payload: error };
};

const bulkDepartmentUpdateRequest = () => {
  return { type: BULK_DEPARTMENT_UPDATE_REQUEST };
};

const bulkDepartmentUpdateSuccess = (data) => {
  return { type: BULK_DEPARTMENT_UPDATE_SUCCESS, payload: data };
};

const bulkDepartmentUpdateFailure = (error) => {
  return { type: BULK_DEPARTMENT_UPDATE_FAILURE, payload: error };
};

const createSubUserRequest = () => {
  return { type: CREATE_SUBUSER_REQUEST };
};

const createSubUserSuccess = (data) => {
  return { type: CREATE_SUBUSER_SUCCESS, payload: data };
};

const createSubUserFailure = (error) => {
  return { type: CREATE_SUBUSER_FAILURE, payload: error };
};

const postRegisterRequest = () => {
  return { type: POST_REGISTER_REQUEST };
};

const postRegisterSuccess = (data) => {
  return { type: POST_REGISTER_SUCCESS, payload: data };
};

const postRegisterFailure = (error) => {
  return { type: POST_REGISTER_FAILURE, payload: error };
};

const postDataRequest = () => {
  return { type: POST_DATA_REQUEST };
};

const postDataSuccess = (data) => {
  return { type: POST_DATA_SUCCESS, payload: data };
};

const postDataFailure = (error) => {
  return { type: POST_DATA_FAILURE, payload: error };
};

const countRequest = () => {
  return { type: COUNT_REQUEST };
};

const countSuccess = (data) => {
  return { type: COUNT_SUCCESS, payload: data };
};

const countFailure = () => {
  return { type: COUNT_FAILURE };
};

const getUserDataRequest = () => {
  return { type: GET_USER_DATA_REQUEST };
};

const getUserDataSuccess = (data) => {
  return { type: GET_USER_DATA_SUCCESS, payload: data };
};

const getUserDataFailure = (error) => {
  return { type: GET_USER_DATA_FAILURE, payload: error };
};

const postUserDataRequest = () => {
  return { type: POST_USER_DATA_REQUEST };
};

const postUserDataSuccess = (data) => {
  return { type: POST_USER_DATA_SUCCESS, payload: data };
};

const postUserDataFailure = (error) => {
  return { type: POST_USER_DATA_FAILURE, payload: error };
};

const getDepartmentDataRequest = () => {
  return { type: GET_DEPARTMENT_DATA_REQUEST };
};

const getDepartmentDataSuccess = (data) => {
  return { type: GET_DEPARTMENT_DATA_SUCCESS, payload: data };
};

const getDepartmentDataFailure = (error) => {
  return { type: GET_DEPARTMENT_DATA_FAILURE, payload: error };
};

const updateDataRequest = () => {
  return { type: UPDATE_DATA_REQUEST };
};

const updateDataSuccess = (data) => {
  return { type: UPDATE_DATA_SUCCESS, payload: data };
};

const updateDataFailure = (error) => {
  return { type: UPDATE_DATA_FAILURE, payload: error };
};

const currentDataRequest = () => {
  return { type: CURRENT_DATA_REQUEST };
};

const currentDataSuccess = (data) => {
  return { type: CURRENT_DATA_SUCCESS, payload: data };
};

const currentDataFailure = (error) => {
  return { type: CURRENT_DATA_FAILURE, payload: error };
};

const incidentDataRequest = () => {
  return { type: INCIDENT_DATA_REQUEST };
};

const incidentDataSuccess = (data) => {
  return { type: INCIDENT_DATA_SUCCESS, payload: data };
};

const incidentDataFailure = (error) => {
  return { type: INCIDENT_DATA_FAILURE, payload: error };
};

const incidentDetailRequest = () => {
  return { type: INCIDENT_DETAIL_REQUEST };
};

const incidentDetailSuccess = (data) => {
  return { type: INCIDENT_DETAIL_SUCCESS, payload: data };
};

const incidentDetailFailure = (error) => {
  return { type: INCIDENT_DETAIL_FAILURE, payload: error };
};

const DownloadPDFRequest = () => {
  return { type: DOWNLOAD_PDF_REQUEST };
};

const DownloadPDFSuccess = (data) => {
  return { type: DOWNLOAD_PDF_SUCCESS, payload: data };
};

const DownloadPDFFailure = (error) => {
  return { type: DOWNLOAD_PDF_FAILURE, payload: error };
};

const addImageRequest = () => {
  return { type: UPLOAD_IMAGE_REQUEST };
};

const addImageSuccess = (data) => {
  return { type: UPLOAD_IMAGE_SUCCESS, payload: data };
};

const addImageFailure = (error) => {
  return { type: UPLOAD_IMAGE_FAILURE, payload: error };
};

const addedImageRequest = () => {
  return { type: UPLOADED_IMAGE_REQUEST };
};

const addedImageSuccess = (data) => {
  return { type: UPLOADED_IMAGE_SUCCESS, payload: data };
};

const addedImageFailure = (error) => {
  return { type: UPLOADED_IMAGE_FAILURE, payload: error };
};

const addedCertificateRequest = () => {
  return { type: UPLOADED_CERTIFICATE_REQUEST };
};

const addedCertificateSuccess = (data) => {
  return { type: UPLOADED_CERTIFICATE_SUCCESS, payload: data };
};

const postDepartmentRequest = () => {
  return { type: POST_DEPARTMENT_NAME_REQUEST };
};

const postDepartmentSuccess = (data) => {
  return { type: POST_DEPARTMENT_SUCCESS, payload: data };
};

const postDepartmentFailure = (error) => {
  return { type: POST_DEPARTMENT_FAILURE, payload: error };
};

const postDepartmentNameRequest = () => {
  return { type: POST_DEPARTMENT_NAME_REQUEST };
};

const postDepartmentNameSuccess = (data) => {
  return { type: POST_DEPARTMENT_NAME_SUCCESS, payload: data };
};

const postDepartmentNameFailure = (error) => {
  return { type: POST_DEPARTMENT_NAME_FAILURE, payload: error };
};

const departmentAlreadySavedRequest = () => {
  return { type: DEPARTMENT_ALREADY_SAVED_REQUEST };
};

const departmentAlreadySavedSuccess = (data) => {
  return { type: DEPARTMENT_ALREADY_SAVED_SUCCESS, payload: data };
};
const departmentAlreadySavedFailure = (error) => {
  return { type: DEPARTMENT_ALREADY_SAVED_FAILURE, payload: error };
};

const updateDepartRequest = () => {
  return { type: UPDATE_DEPARTMENT_REQUEST };
};

const updateDepartSuccess = (data) => {
  return { type: UPDATE_DEPARTMENT_SUCCESS, payload: data };
};
const updateDepartFailure = (error) => {
  return { type: UPDATE_DEPARTMENT_FAILURE, payload: error };
};
const retrieveDepartRequest = () => {
  return { type: RETRIEVE_DEPARTMENT_REQUEST };
};
const retrieveDepartSuccess = (data) => {
  return { type: RETRIEVE_DEPARTMENT_SUCCESS, payload: data };
};
const retrieveDepartFailure = (error) => {
  return { type: RETRIEVE_DEPARTMENT_FAILURE, payload: error };
};

const deleteDepartRequest = () => {
  return { type: DELETE_DEPARTMENT_REQUEST };
};
const deleteDepartSuccess = (data) => {
  return { type: DELETE_DEPARTMENT_SUCCESS, payload: data };
};
const deleteDepartFailure = (error) => {
  return { type: DELETE_DEPARTMENT_FAILURE, payload: error };
};

// ..................Retrieve all assets............................................

const retrieveAllAssetsRequest = () => {
  return { type: RETRIEVE_ALL_ASSETS_REQUEST };
};

const retrieveAllAssetsSuccess = (data) => {
  return { type: RETRIEVE_ALL_ASSETS_SUCCESS, payload: data };
};

const retrieveAllAssetsFailure = (error) => {
  return { type: RETRIEVE_ALL_ASSETS_FAILURE, payload: error };
};

// ..................Delete assets............................................

const deleteAssetRequest = () => {
  return { type: DELETE_ASSET_REQUEST };
};

const deleteAssetSuccess = (data) => {
  return { type: DELETE_ASSET_SUCCESS, payload: data };
};

const deleteAssetFailure = (error) => {
  return { type: DELETE_ASSET_FAILURE, payload: error };
};

// ...................Create Service...........................................

const createServiceRequest = () => {
  return { type: CREATE_SERVICE_REQUEST };
};

const createServiceSuccess = (data) => {
  return { type: CREATE_SERVICE_SUCCESS, payload: data };
};

const createServiceFailure = (error) => {
  return { type: CREATE_SERVICE_FAILURE, payload: error };
};

// ...................Create Service Image upload...........................................

const createServiceImageUploadRequest = () => {
  return { type: CREATE_SERVICE_IMAGE_UPLOAD_REQUEST };
};

const createServiceImageUploadSuccess = (data) => {
  return { type: CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const createServiceImageUploadFailure = (error) => {
  return { type: CREATE_SERVICE_IMAGE_UPLOAD_FAILURE, payload: error };
};

const addedCertificateFailure = (error) => {
  return { type: UPLOADED_CERTIFICATE_FAILURE, payload: error };
};
// ...................Closed Service...........................................

const closedServiceRequest = () => {
  return { type: CLOSED_SERVICE_REQUEST };
};

const closedServiceSuccess = (data) => {
  return { type: CLOSED_SERVICE_SUCCESS, payload: data };
};

const closedServiceFailure = (error) => {
  return { type: CLOSED_SERVICE_FAILURE, payload: error };
};
// ...................open Service...........................................

const openServiceRequest = () => {
  return { type: OPEN_SERVICE_REQUEST };
};

const openServiceSuccess = (data) => {
  return { type: OPEN_SERVICE_SUCCESS, payload: data };
};

const openServiceFailure = (error) => {
  return { type: OPEN_SERVICE_FAILURE, payload: error };
};

// ................... Service List...........................................

const serviceListRequest = () => {
  return { type: SERVICE_LIST_REQUEST };
};

const serviceListSuccess = (data) => {
  return { type: SERVICE_LIST_SUCCESS, payload: data };
};

const serviceListFailure = (error) => {
  return { type: SERVICE_LIST_FAILURE, payload: error };
};

// ................... Service Detail...........................................

const serviceDetailRequest = () => {
  return { type: SERVICE_DETAIL_REQUEST };
};

const serviceDetailSuccess = (data) => {
  return { type: SERVICE_DETAIL_SUCCESS, payload: data };
};

const serviceDetailFailure = (error) => {
  return { type: SERVICE_DETAIL_FAILURE, payload: error };
};

// ................... Clear service...........................................

const clearServiceRequest = () => {
  return { type: CLEAR_SERVICE_REQUEST };
};

const clearServiceSuccess = (data) => {
  return { type: CLEAR_SERVICE_SUCCESS, payload: data };
};

const clearServiceFailure = (error) => {
  return { type: CLEAR_SERVICE_FAILURE, payload: error };
};

const uploadQrCodeImageRequest = () => {
  return { type: UPLOAD_QR_CODE_IMAGE_REQUEST };
};

const uploadQrCodeImageSuccess = (data) => {
  return { type: UPLOAD_QR_CODE_IMAGE_SUCCESS, payload: data };
};

const uploadQrCodeImageFailure = (error) => {
  return { type: UPLOAD_QR_CODE_IMAGE_FAILURE, payload: error };
};

const createQRcodeRequest = () => {
  return { type: CREATE_QR_CODE_REQUEST };
};

const createQRcodeSuccess = (data) => {
  return { type: CREATE_QR_CODE_SUCCESS, payload: data };
};

const createQRcodeFailure = (error) => {
  return { type: CREATE_QR_CODE_FAILURE, payload: error };
};

const addAssetRequest = () => {
  return { type: ADD_ASSET_REQUEST };
};

const addAssetSuccess = (data) => {
  return { type: ADD_ASSET_SUCCESS, payload: data };
};

const addAssetFailure = (error) => {
  return { type: ADD_ASSET_FAILURE, payload: error };
};
// ...................Issue...........................................
const serviceIssueRequest = () => {
  return { type: SERVICE_ISSUE_REQUEST };
};

const serviceIssueSuccess = (data) => {
  return { type: SERVICE_ISSUE_SUCCESS, payload: data };
};

const serviceIssueFailure = (error) => {
  return { type: SERVICE_ISSUE_FAILURE, payload: error };
};

const incidentIssueRequest = () => {
  return { type: INCIDENT_ISSUE_REQUEST };
};

const incidentIssueSuccess = (data) => {
  return { type: INCIDENT_ISSUE_SUCCESS, payload: data };
};

const incidentIssueFailure = (error) => {
  return { type: INCIDENT_ISSUE_FAILURE, payload: error };
};
// ...................service image upload...........................................
const serviceImageUploadRequest = () => {
  return { type: SERVICE_IMAGE_UPLOAD_REQUEST };
};

const serviceImageUploadSuccess = (data) => {
  return { type: SERVICE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const serviceImageUploadFailure = (error) => {
  return { type: SERVICE_IMAGE_UPLOAD_FAILURE, payload: error };
};

// ...................logout...........................................
const logOutRequest = () => {
  return { type: LOGOUT_REQUEST };
};

const logOutSuccess = (data) => {
  return { type: LOGOUT_SUCCESS, payload: data };
};

const logOutFailure = (error) => {
  return { type: LOGOUT_FAILURE, payload: error };
};
const editAssetRequest = () => {
  return { type: EDIT_ASSET_REQUEST };
};

const serviceExpenditureImageUploadRequest = () => {
  return { type: SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST };
};

const serviceExpenditureImageUploadSuccess = (data) => {
  return { type: SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const serviceExpenditureImageUploadFailure = (error) => {
  return { type: SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE, payload: error };
};

const incidentExpenditureImageUploadRequest = () => {
  return { type: INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST };
};

const incidentExpenditureImageUploadSuccess = (data) => {
  return { type: INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const incidentExpenditureImageUploadFailure = (error) => {
  return { type: INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE, payload: error };
};

const editAssetSuccess = (data) => {
  return { type: EDIT_ASSET_SUCCESS, payload: data };
};

const editAssetFailure = (error) => {
  return { type: EDIT_ASSET_FAILURE, payload: error };
};

const getAssetRequest = () => {
  return { type: GET_ASSET_REQUEST };
};

const requestDashboard = () => {
  return { type: GET_REQUEST_DASHBOARD };
};

const getAssetSuccess = (data) => {
  return { type: GET_ASSET_SUCCESS, payload: data };
};

const getRequestDashboardSuccess = (data) => {
  return { type: GET_REQUEST_DASHBOARD_SUCCESS, payload: data };
};

const getAssetFailure = (error) => {
  return { type: GET_ASSET_FAILURE, payload: error };
};

const getRequestDashboardFailure = (error) => {
  return { type: GET_REQUEST_DASHBOARD_FAILURE, payload: error };
};

const getSingleUserRequest = () => {
  return { type: GET_SINGLE_USER_REQUEST };
};

const getSingleUserSuccess = (data) => {
  return { type: GET_SINGLE_USER_SUCCESS, payload: data };
};

const getSingleUserFailure = (error) => {
  return { type: GET_SINGLE_USER_FAILURE, payload: error };
};

const deleteImageRequest = () => {
  return { type: DELETE_IMAGE_REQUEST };
};

const deleteImageRequestHistory = () => {
  return { type: DELETE_IMAGE_REQUEST_HISTORY };
};

const deleteProfileUserImage = () => {
  return { type: DELETE_PROFILE_IMAGE };
};

const deleteImageSuccess = (data) => {
  return { type: DELETE_IMAGE_SUCCESS, payload: data };
};

const deleteImageFailure = (error) => {
  return { type: DELETE_IMAGE_FAILURE, payload: error };
};

const deleteCertificateRequest = () => {
  return { type: DELETE_CERTIFICATE_REQUEST };
};

const deleteCertificateSuccess = (data) => {
  return { type: DELETE_CERTIFICATE_SUCCESS, payload: data };
};

const deleteCertificateFailure = (error) => {
  return { type: DELETE_CERTIFICATE_FAILURE, payload: error };
};

const deleteUserRequest = () => {
  return { type: DELETE_USER_REQUEST };
};

const deleteUserSuccess = (data) => {
  return { type: DELETE_USER_SUCCESS, payload: data };
};

const deleteUserFailure = (error) => {
  return { type: DELETE_USER_FAILURE, payload: error };
};

const contactUsRequest = () => {
  return { type: CONTACT_US_REQUEST };
};

const contactUsSuccess = (data) => {
  return { type: CONTACT_US_SUCCESS, payload: data };
};

const contactUsFailure = (error) => {
  return { type: CONTACT_US_FAILURE, payload: error };
};
const contactHomeRequest = () => {
  return { type: CONTACT_HOME_REQUEST };
};

const contactHomeSuccess = (data) => {
  return { type: CONTACT_HOME_SUCCESS, payload: data };
};

const contactHomeFailure = (error) => {
  return { type: CONTACT_HOME_FAILURE, payload: error };
};
const clearUserData = (data) => {
  return { type: CLEAR_USER_DATA, payload: data };
};
// ...................Profile...........................................
const profileRequest = () => {
  return { type: PROFILE_REQUEST };
};

const profileSuccess = (data) => {
  return { type: PROFILE_SUCCESS, payload: data };
};

const profileFailure = (error) => {
  return { type: PROFILE_FAILURE, payload: error };
};

// ...................usermanual...........................................

const userManualRequest = () => {
  return { type: USER_MANUAL_REQUEST };
};

const userManualSuccess = (data) => {
  return { type: USER_MANUAL_SUCCESS, payload: data };
};

const userManualFailure = (error) => {
  return { type: USER_MANUAL_FAILURE, payload: error };
};
// ---------------------------------clear incident image-------------------------
const clearIncidentImageRequest = () => {
  return { type: CLEAR_INCIDENT_IMAGE_REQUEST };
};

const clearIncidentImageSuccess = (data) => {
  return { type: CLEAR_INCIDENT_IMAGE_SUCCESS, payload: data };
};

const clearIncidentImageFailure = (error) => {
  return { type: CLEAR_INCIDENT_IMAGE_FAILURE, payload: error };
};

// ......................................clear incident.............................

const clearIncidentRequest = () => {
  return { type: CLEAR_INCIDENT_REQUEST };
};
const clearIncidentSuccess = (data) => {
  return {
    type: CLEAR_INCIDENT_SUCCESS,
    payload: data,
  };
};
const clearIncidentFailure = (error) => {
  return {
    type: CLEAR_INCIDENT_FAILURE,
    payload: error,
  };
};

const clearIncidentImageRemoveRequest = () => {
  return { type: CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST };
};
const clearIncidentImageRemoveSuccess = (data) => {
  return {
    type: CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS,
    payload: data,
  };
};
const clearIncidentImageRemoveFailure = (error) => {
  return {
    type: CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE,
    payload: error,
  };
};

const clearServiceExpenditureImageRemoveRequest = () => {
  return { type: CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST };
};
const clearServiceExpenditureImageRemoveSuccess = (data) => {
  return {
    type: CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS,
    payload: data,
  };
};
const clearServiceExpenditureImageRemoveFailure = (error) => {
  return {
    type: CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE,
    payload: error,
  };
};

const clearIncidentExpenditureImageRemoveRequest = () => {
  return { type: CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST };
};
const clearIncidentExpenditureImageRemoveSuccess = (data) => {
  return {
    type: CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS,
    payload: data,
  };
};
const clearIncidentExpenditureImageRemoveFailure = (error) => {
  return {
    type: CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE,
    payload: error,
  };
};

const serviceRequestImageRemoveRequest = () => {
  return { type: SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST };
};
const serviceRequestImageRemoveSuccess = (data) => {
  return {
    type: SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS,
    payload: data,
  };
};
const serviceRequestImageRemoveFailure = (error) => {
  return {
    type: SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE,
    payload: error,
  };
};

const assetDetailsRequest = () => {
  return {
    type: ASSET_DETAILS_REQUEST,
  };
};

const assetDetailsSuccess = (data) => {
  return {
    type: ASSET_DETAILS_SUCCESS,
    payload: data,
  };
};

const assetDetailsFailure = (error) => {
  return {
    type: ASSET_DETAILS_FAILURE,
    payload: error,
  };
};

const getIncidentHistoryRequest = () => {
  return {
    type: GET_INCIDENT_HISTORY_REQUEST,
  };
};

const getIncidentHistorySuccess = (data) => {
  return {
    type: GET_INCIDENT_HISTORY_SUCCESS,
    payload: data,
  };
};

const getIncidentHistoryFailure = (error) => {
  return {
    type: GET_INCIDENT_HISTORY_FAILURE,
    payload: error,
  };
};

const recordIncidentImageUploadRequest = () => {
  return {
    type: RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST,
  };
};

const recordIncidentImageUploadSuccess = (data) => {
  return {
    type: RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS,
    payload: data,
  };
};

const recordIncidentImageUploadFailure = (error) => {
  return {
    type: RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE,
    payload: error,
  };
};

const IncidentDeleteRequest = () => {
  return {
    type: INCIDENT_DELETE_REQUEST,
  };
};

const IncidentDeleteSuccess = (data) => {
  return {
    type: INCIDENT_DELETE_SUCCESS,
    payload: data,
  };
};

const IncidentDeleteFailure = (error) => {
  return {
    type: INCIDENT_DELETE_FAILURE,
    payload: error,
  };
};

const recordIncidentImageDeleteRequest = () => {
  return {
    type: RECORD_INCIDENT_IMAGE_DELETE_REQUEST,
  };
};

const recordIncidentImageDeleteSuccess = (data) => {
  return {
    type: RECORD_INCIDENT_IMAGE_DELETE_SUCCESS,
    payload: data,
  };
};

const recordIncidentImageDeleteFailure = (error) => {
  return {
    type: RECORD_INCIDENT_IMAGE_DELETE_FAILURE,
    payload: error,
  };
};

const createIncidentRequest = () => {
  return {
    type: CREATE_INCIDENT_REQUEST,
  };
};

const createIncidentSuccess = (data) => {
  return {
    type: CREATE_INCIDENT_SUCCESS,
    payload: data,
  };
};

const createIncidentFailure = (error) => {
  return {
    type: CREATE_INCIDENT_FAILURE,
    payload: error,
  };
};

export const getNotRequiredRequest = () => {
  return { type: GET_NOTREQUIRED_REQUEST };
};

export const getNotRequiredSuccess = (data) => {
  return { type: GET_NOTREQUIRED_SUCCESS, payload: data };
};

export const getNotRequiredFailure = (error) => {
  return { type: GET_NOTREQUIRED_FAILURE, payload: error };
};

export const getNotCalibratedRequest = () => {
  return { type: GET_NOTCALIBRATED_REQUEST };
};

export const getNotCalibratedSuccess = (data) => {
  return { type: GET_NOTCALIBRATED_SUCCESS, payload: data };
};

export const getNotCalibratedFailure = (error) => {
  return { type: GET_NOTCALIBRATED_FAILURE, payload: error };
};

export const getCalibratedRequest = () => {
  return { type: GET_CALIBRATED_REQUEST };
};

export const getCalibratedSuccess = (data) => {
  return { type: GET_CALIBRATED_SUCCESS, payload: data };
};

export const getCalibratedFailure = (error) => {
  return { type: GET_CALIBRATED_FAILURE, payload: error };
};
const getDashboardRequest = () => {
  return {
    type: GET_DASHBOARD_REQUEST,
  };
};

const getDashboardSuccess = (data) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: data,
  };
};

const getDashboardFailure = (error) => {
  return {
    type: GET_DASHBOARD_FAILURE,
    payload: error,
  };
};

const departmentAssetDetailsRequest = () => {
  return { type: DEPARTMENT_ASSET_DETAILS_REQUEST };
};

const departmentAssetDetailsSuccess = (data) => {
  return {
    type: DEPARTMENT_ASSET_DETAILS_SUCCESS,
    payload: data,
  };
};

const departmentAssetDetailsFailure = (error) => {
  return { type: DEPARTMENT_ASSET_DETAILS_FAILURE, payload: error };
};

const departmentUserDetailsRequest = () => {
  return { type: DEPARTMENT_USER_DETAILS_REQUEST };
};

const departmentUserDetailsSuccess = (data) => {
  return {
    type: DEPARTMENT_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

const departmentUserDetailsFailure = (error) => {
  return { type: DEPARTMENT_USER_DETAILS_FAILURE, payload: error };
};

const userRetrieveByBranchFailure = (error) => {
  return { type: USER_RETRIEVE_BY_BRANCH_FAILURE, payload: error };
};

const userRetrieveByBranchRequest = () => {
  return { type: USER_RETRIEVE_BY_BRANCH_REQUEST };
};

const userRetrieveByBranchSuccess = (data) => {
  return {
    type: USER_RETRIEVE_BY_BRANCH_SUCCESS,
    payload: data,
  };
};
//...............................Report..............................

const serviceReportRequest = () => {
  return { type: SERVICE_REPORT_REQUEST };
};

const serviceReportSuccess = (data) => {
  return {
    type: SERVICE_REPORT_SUCCESS,
    payload: data,
  };
};

const serviceReportFailure = (error) => {
  return { type: SERVICE_REPORT_FAILURE, payload: error };
};
const requestListRequest = () => {
  return { type: REQUEST_LIST_REQUEST };
};

const requestListSuccess = (data) => {
  return {
    type: REQUEST_LIST_SUCCESS,
    payload: data,
  };
};

const requestListFailure = (error) => {
  return { type: REQUEST_LIST_FAILURE, payload: error };
};

const getPmAssetRequest = () => {
  return { type: GET_PM_ASSET_REQUEST };
};

const getPmAssetSuccess = (data) => {
  return {
    type: GET_PM_ASSET_SUCCESS,
    payload: data,
  };
};

const getPmAssetFailure = (error) => {
  return { type: GET_PM_ASSET_FAILURE, payload: error };
};

const postPmCheckListRequest = () => {
  return { type: POST_PM_CHECKLIST_REQUEST };
};

const postPmCheckListSuccess = (data) => {
  return {
    type: POST_PM_CHECKLIST_SUCCESS,
    payload: data,
  };
};

const postPmCheckListFailure = (error) => {
  return { type: POST_PM_CHECKLIST_FAILURE, payload: error };
};

const getPmChecklistPdfRequest = () => {
  return {
    type: PM_CHECKLIST_PDF_REQUEST,
  };
};

const getPmChecklistPdfSuccess = (data) => {
  return {
    type: PM_CHECKLIST_PDF_SUCCESS,
    payload: data,
  };
};

const getPmChecklistPdfFailure = (error) => {
  return {
    type: PM_CHECKLIST_PDF_FAILURE,
    payload: error,
  };
};
const closedIncidentRequest = () => {
  return { type: CLOSED_INCIDENT_REQUEST };
};

const closedIncidentSuccess = (data) => {
  return { type: CLOSED_INCIDENT_SUCCESS, payload: data };
};

const closedIncidentFailure = (error) => {
  return { type: CLOSED_INCIDENT_FAILURE, payload: error };
};
// ...................open Incident..........................................

const openIncidentRequest = () => {
  return { type: OPEN_INCIDENT_REQUEST };
};

const openIncidentSuccess = (data) => {
  return { type: OPEN_INCIDENT_SUCCESS, payload: data };
};

const openIncidentFailure = (error) => {
  return { type: OPEN_INCIDENT_FAILURE, payload: error };
};

// ...................Warranty status..........................................

const warrantyStatusRequest = () => {
  return { type: WARRANTY_STATUS_REQUEST };
};

const warrantyStatusSuccess = (data) => {
  return { type: WARRANTY_STATUS_SUCCESS, payload: data };
};

const warrantyStatusFailure = (error) => {
  return { type: WARRANTY_STATUS_FAILURE, payload: error };
};

// ...................Request Manual..........................................

const requestWarrantyRequest = () => {
  return { type: REQUEST_WARRANTY_REQUEST };
};

const requestWarrantySuccess = (data) => {
  return { type: REQUEST_WARRANTY_SUCCESS, payload: data };
};

const requestWarrantyFailure = (error) => {
  return { type: REQUEST_WARRANTY_FAILURE, payload: error };
};

// ...................Profile edit..........................................

const profileEditRequest = () => {
  return { type: PROFILE_EDIT_REQUEST };
};

const profileEditSuccess = (data) => {
  return { type: PROFILE_EDIT_SUCCESS, payload: data };
};

const profileEditFailure = (error) => {
  return { type: PROFILE_EDIT_FAILURE, payload: error };
};

const clearServiceImgDelRequest = () => {
  return { type: CLEAR_SERVICE_IMAGE_DELETE_REQUEST };
};

const clearServiceImgDelSuccess = (data) => {
  return { type: CLEAR_SERVICE_IMAGE_DELETE_SUCCESS, payload: data };
};

const clearServiceImgDelFailure = (error) => {
  return { type: CLEAR_SERVICE_IMAGE_DELETE_FAILURE, payload: error };
};

const getInvalidTokenRequest = () => {
  return { type: GET_INVALID_TOKEN_REQUEST };
};

const getInvalidTokenSuccess = (data) => {
  return { type: GET_INVALID_TOKEN_SUCCESS, payload: data };
};

const getInvalidTokenFailure = (error) => {
  return { type: GET_INVALID_TOKEN_FAILURE, payload: error };
};

const getAuthenticationSuccess = (data) => {
  return { type: GET_AUTHENTICATION_ERROR_SUCCESS, payload: data };
};

// export const getInvalidToken = (data) => (dispatch) => {
//   // dispatch(getInvalidTokenRequest());

//   dispatch(getInvalidTokenSuccess(data));

//   // dispatch(getInvalidTokenFailure());
// };

// export const getInvalidToken = (data) => (dispatch) => {
//   if (data === "INVALID_TOKEN") {
//     fetch(process.env.REACT_APP_API_TOKEN, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ refreshtoken: Id.refreshToken }),
//     })
//       .then((response) => {
//         return response.json();
//       })
//       .then((responseData) => {
//         if (responseData.token) {
//           const newFbToken = responseData.token;
//           const updatedId = { ...Id, fbtoken: newFbToken };

//           localStorage.setItem("userTokenId", JSON.stringify(updatedId));
//           localStorage.setItem("userToken", newFbToken);

//           dispatch(getInvalidTokenSuccess(data));
//         } else {
//           console.log("No new token found in the response data.");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching new fbtoken:", error);
//       });
//   } else {
//     console.log("Token is valid, no refresh needed.");
//     dispatch(getInvalidTokenSuccess(data));
//   }
// };

// export const getInvalidToken = (data) => async (dispatch) => {
//   if (data === "INVALID_TOKEN") {
//     try {
//       const response = await fetch(process.env.REACT_APP_API_TOKEN, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ refreshtoken: Id.refreshToken }),
//       });

//       const responseData = await response.json();

//       if (responseData.token) {
//         const newFbToken = responseData.token;
//         const updatedId = { ...Id, fbtoken: newFbToken };

//         localStorage.setItem("userTokenId", JSON.stringify(updatedId));
//         localStorage.setItem("userToken", newFbToken);

//         dispatch(getInvalidTokenSuccess(data));
//       } else {
//         console.log("No new token found in the response data.");
//       }
//     } catch (error) {
//       console.error("Error fetching new fbtoken:", error);
//     }
//   } else {
//     console.log("Token is valid, no refresh needed.");
//     dispatch(getInvalidTokenSuccess(data));
//   }
// };

export const getInvalidToken = (data) => async (dispatch) => {
  if (data === "INVALID_TOKEN") {
    try {
      const response = await fetch(process.env.REACT_APP_API_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshtoken: Id.refreshToken }),
      });

      const responseData = await response.json();

      if (responseData.token) {
        const newFbToken = responseData.token;
        const updatedId = { ...Id, fbtoken: newFbToken };

        localStorage.setItem("userTokenId", JSON.stringify(updatedId));
        localStorage.setItem("userToken", newFbToken);

        dispatch(getInvalidTokenSuccess(data));

        return newFbToken; // Return the new token
      } else {
        console.log("No new token found in the response data.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching new fbtoken:", error);
      return null;
    }
  } else {
    console.log("Token is valid, no refresh needed.");
    dispatch(getInvalidTokenSuccess(data));
    return null;
  }
};

const userData =
  localStorage.getItem("userData") || sessionStorage.getItem("userData");

if (!userData) {
  localStorage.clear();
}

// export const getInvalidToken = (data) => (dispatch) =>  {
//   if (data === "INVALID_TOKEN") {
//     fetch(process.env.REACT_APP_API_TOKEN, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ refreshtoken: Id.refreshToken }),
//     })
//       .then((response) => response.json())
//       .then((responseData) => {
//         if (responseData.token) {
//           const newFbToken = responseData.token;
//           const updatedId = { ...Id, fbtoken: newFbToken };

//           if (responseData.userdata) {
//             localStorage.setItem("userData", JSON.stringify(responseData.userdata));
//             sessionStorage.setItem("userData", JSON.stringify(responseData.userdata));
//           }

//           // Store new tokens
//           localStorage.setItem("userTokenId", JSON.stringify(updatedId));
//           localStorage.setItem("userToken", newFbToken);

//           // Store user data (if available)

//           window.location.reload();
//           dispatch(getInvalidTokenSuccess(data));
//         } else {
//           console.log("No new token found in the response data.");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching new fbtoken:", error);
//       });
//   } else {
//     console.log("Token is valid, no refresh needed.");
//     dispatch(getInvalidTokenSuccess(data));
//   }
// };

export const getAuthenticationError = (data) => (dispatch) => {
  dispatch(getAuthenticationSuccess(data));
};

//-------------------------Clear Service Receipt-----------------------

const clearServiceReceiptRequest = () => {
  return {
    type: CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST,
  };
};

const clearServiceReceiptSuccess = (data) => {
  return { type: CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS, payload: data };
};

const clearServiceReceiptFailure = (error) => {
  return { type: CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE, payload: error };
};
//-------------------------Clear Service Receipt  Delete-----------------------

const clearServiceReceiptDeleteRequest = () => {
  return {
    type: CLEAR_SERVICE_RECEIPT_DELETE_REQUEST,
  };
};

const clearServiceReceiptDeleteSuccess = (data) => {
  return { type: CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS, payload: data };
};

const clearServiceReceiptDeleteFailure = (error) => {
  return { type: CLEAR_SERVICE_RECEIPT_DELETE_FAILURE, payload: error };
};

const bioMedicalServiceRequest = () => {
  return {
    type: BIOMEDICAL_SERVICE_REQUEST,
  };
};

const bioMedicalServiceSuccess = (data) => {
  return { type: BIOMEDICAL_SERVICE_SUCCESS, payload: data };
};

const bioMedicalServiceFailure = (error) => {
  return { type: BIOMEDICAL_SERVICE_FAILURE, payload: error };
};

const csvFileUploadRequest = () => {
  return { type: CSV_FILE_UPLOAD_REQUEST };
};

const csvFileUploadSuccess = (data) => {
  return { type: CSV_FILE_UPLOAD_SUCCESS, payload: data };
};

const csvFileUploadFailure = (error) => {
  return { type: CSV_FILE_UPLOAD_FAILURE, payload: error };
};

const deleteCsvFileUploadRequest = () => {
  return { type: DELETE_CSV_FILE_UPLOAD_REQUEST };
};

const deleteCsvFileUploadSuccess = (data) => {
  return { type: DELETE_CSV_FILE_UPLOAD_SUCCESS, payload: data };
};

const deleteCsvFileUploadFailure = (error) => {
  return { type: DELETE_CSV_FILE_UPLOAD_FAILURE, payload: error };
};

const csvFileUploadSubmitRequest = () => {
  return { type: CSV_FILE_UPLOAD_SUBMIT_REQUEST };
};

const csvFileUploadSubmitSuccess = (data) => {
  return { type: CSV_FILE_UPLOAD_SUBMIT_SUCCESS, payload: data };
};

const csvFileUploadSubmitFailure = (error) => {
  return { type: CSV_FILE_UPLOAD_SUBMIT_FAILURE, payload: error };
};
const AmcCmcRequest = () => {
  return { type: AMC_CMC_REQUEST };
};

export const AmcCmcSuccess = (data) => {
  return { type: AMC_CMC_SUCCESS, payload: data };
};

const AmcCmcFailure = (error) => {
  return { type: AMC_CMC_FAILURE, payload: error };
};

const AmcRequest = () => {
  return { type: AMC_REQUEST };
};

const AmcSuccess = (data) => {
  return { type: AMC_SUCCESS, payload: data };
};

const AmcFailure = (error) => {
  return { type: AMC_FAILURE, payload: error };
};
const CmcRequest = () => {
  return { type: CMC_REQUEST };
};

const CmcSuccess = (data) => {
  return { type: CMC_SUCCESS, payload: data };
};

const CmcFailure = (error) => {
  return { type: CMC_FAILURE, payload: error };
};

const assetHistoryRequest = () => {
  return { type: ASSET_HISTORY_REQUEST };
};

const assetHistorySuccess = (data) => {
  return { type: ASSET_HISTORY_SUCCESS, payload: data };
};

const assetHistoryFailure = (error) => {
  return { type: ASSET_HISTORY_FAILURE, payload: error };
};

const assetHistoryRetrieveRequest = () => {
  return { type: ASSET_HISTORY_RETRIEVE_REQUEST };
};

const assetHistoryRetrieveSuccess = (data) => {
  return { type: ASSET_HISTORY_RETRIEVE_SUCCESS, payload: data };
};

const assetHistoryRetrieveFailure = (error) => {
  return { type: ASSET_HISTORY_RETRIEVE_FAILURE, payload: error };
};

const assetHistoryDropdownRetrieveRequest = () => {
  return { type: ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST };
};

const assetHistoryDropdownRetrieveSuccess = (data) => {
  return { type: ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS, payload: data };
};

const assetHistoryDropdownRetrieveFailure = (error) => {
  return { type: ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE, payload: error };
};

const createTaskRequest = () => {
  return { type: CREATE_TASK_REQUEST };
};

const createTaskSuccess = (data) => {
  return { type: CREATE_TASK_SUCCESS, payload: data };
};

const createTaskFailure = (error) => {
  return { type: CREATE_TASK_FAILURE, payload: error };
};

const getTaskRequest = () => {
  return { type: GET_TASK_REQUEST };
};

const getTaskSuccess = (data) => {
  return { type: GET_TASK_SUCCESS, payload: data };
};

const getTaskFailure = (error) => {
  return { type: GET_TASK_FAILURE, payload: error };
};

const postVendorRequest = () => {
  return { type: POST_VENDOR_REQUEST };
};

const postVendorSuccess = (data) => {
  return { type: POST_VENDOR_SUCCESS, payload: data };
};

const postVendorFailure = (error) => {
  return { type: POST_VENDOR_FAILURE, payload: error };
};

const getVendorRequest = () => {
  return { type: GET_VENDOR_REQUEST };
};

const getVendorSuccess = (data) => {
  return { type: GET_VENDOR_SUCCESS, payload: data };
};

const getVendorFailure = (error) => {
  return { type: GET_VENDOR_FAILURE, payload: error };
};

const getVendorServiceRequest = () => {
  return { type: GET_VENDOR_SERVICE_REQUEST };
};

const getVendorServiceSuccess = (data) => {
  return { type: GET_VENDOR_SERVICE_SUCCESS, payload: data };
};

const getVendorServiceFailure = (error) => {
  return { type: GET_VENDOR_SERVICE_FAILURE, payload: error };
};

const postVendorServiceRequest = () => {
  return { type: POST_VENDOR_SERVICE_REQUEST };
};

const postVendorServiceSuccess = (data) => {
  return { type: POST_VENDOR_SERVICE_SUCCESS, payload: data };
};

const postVendorServiceFailure = (error) => {
  return { type: POST_VENDOR_SERVICE_FAILURE, payload: error };
};

const checkOutRequest = () => {
  return { type: CHECKOUT_REQUEST };
};

const checkOutSuccess = (data) => {
  return { type: CHECKOUT_SUCCESS, payload: data };
};

const checkOutFailure = (error) => {
  return { type: CHECKOUT_FAILURE, payload: error };
};

const checkInRequest = () => {
  return { type: CHECKIN_REQUEST };
};

const checkInSuccess = (data) => {
  return { type: CHECKIN_SUCCESS, payload: data };
};

const checkInFailure = (error) => {
  return { type: CHECKIN_FAILURE, payload: error };
};

const checkInOutRequest = () => {
  return { type: CHECKIN_CHECKOUT_REQUEST };
};

const checkInOutSuccess = (data) => {
  return { type: CHECKIN_CHECKOUT_SUCCESS, payload: data };
};

const checkInOutFailure = (error) => {
  return { type: CHECKIN_CHECKOUT_FAILURE, payload: error };
};

const checkInOutDetailRequest = () => {
  return { type: CHECKIN_CHECKOUT_DETAIL_REQUEST };
};

const checkInOutDetailSuccess = (data) => {
  return { type: CHECKIN_CHECKOUT_DETAIL_SUCCESS, payload: data };
};

const checkInOutDetailFailure = (error) => {
  return { type: CHECKIN_CHECKOUT_DETAIL_FAILURE, payload: error };
};

const CalenderRequest = () => {
  return { type: CALENDER_REQUEST };
};

export const CalenderSuccess = (data) => {
  return { type: CALENDER_SUCCESS, payload: data };
};

const CalenderFailure = (error) => {
  return { type: CALENDER_FAILURE, payload: error };
};

const CheckInPdfRequest = () => {
  return { type: CHECKIN_PDF_REQUEST };
};

const CheckInPdfSuccess = (data) => {
  return { type: CHECKIN_PDF_SUCCESS, payload: data };
};

const CheckInPdfFailure = (error) => {
  return { type: CHECKIN_PDF_FAILURE, payload: error };
};

const AmcCmcReqRequest = () => {
  return { type: AMC_CMC_REQ_REQUEST };
};

const AmcCmcReqSuccess = (data) => {
  return { type: AMC_CMC_REQ_SUCCESS, payload: data };
};

const AmcCmcReqFailure = (error) => {
  return { type: AMC_CMC_REQ_FAILURE, payload: error };
};

const CalibrationReqRequest = () => {
  return { type: CALIBRATION_REQ_REQUEST };
};

const CalibrationReqSuccess = (data) => {
  return { type: CALIBRATION_REQ_SUCCESS, payload: data };
};

const CalibrationReqFailure = (error) => {
  return { type: CALIBRATION_REQ_FAILURE, payload: error };
};

const AddAssetReportRequest = () => {
  return { type: ASSET_ADD_REPORT_REQUEST };
};

const AddAssetReportSuccess = (data) => {
  return { type: ASSET_ADD_REPORT_SUCCESS, payload: data };
};

const AddAssetReportFailure = (error) => {
  return { type: ASSET_ADD_REPORT_FAILURE, payload: error };
};

const AssetDeleteReportRequest = () => {
  return { type: ASSET_DELETE_REPORT_REQUEST };
};

const AssetDeleteReportSuccess = (data) => {
  return { type: ASSET_DELETE_REPORT_SUCCESS, payload: data };
};

const AssetDeleteReportFailure = (error) => {
  return { type: ASSET_DELETE_REPORT_FAILURE, payload: error };
};

const WarrantyRequestRequest = () => {
  return { type: WARRANTY_REQUEST_REQUEST };
};

const WarrantyRequestSuccess = (data) => {
  return { type: WARRANTY_REQUEST_SUCCESS, payload: data };
};

const WarrantyRequestFailure = (error) => {
  return { type: WARRANTY_REQUEST_FAILURE, payload: error };
};

const WarrantyTaskCompletedRequest = () => {
  return { type: WARRANTY_TASK_COMPLETE_REQUEST };
};

const WarrantyTaskCompletedSuccess = (data) => {
  return { type: WARRANTY_TASK_COMPLETE_SUCCESS, payload: data };
};

const WarrantyTaskCompletedFailure = (error) => {
  return { type: WARRANTY_TASK_COMPLETE_FAILURE, payload: error };
};

const AmcCmcTaskCompletedRequest = () => {
  return { type: AMC_CMC_TASK_COMPLETE_REQUEST };
};

const AmcCmcTaskCompletedSuccess = (data) => {
  return { type: AMC_CMC_TASK_COMPLETE_SUCCESS, payload: data };
};

const AmcCmcTaskCompletedFailure = (error) => {
  return { type: AMC_CMC_TASK_COMPLETE_FAILURE, payload: error };
};

const CalibrationTaskCompletedRequest = () => {
  return { type: CALIBRATION_TASK_COMPLETE_REQUEST };
};

const CalibrationTaskCompletedSuccess = (data) => {
  return { type: CALIBRATION_TASK_COMPLETE_SUCCESS, payload: data };
};

const CalibrationTaskCompletedFailure = (error) => {
  return { type: CALIBRATION_TASK_COMPLETE_FAILURE, payload: error };
};

const updateReportRequest = () => {
  return { type: UPDATE_REPORT_REQUEST };
};

const updateReportSuccess = (data) => {
  return { type: UPDATE_REPORT_SUCCESS, payload: data };
};

const updateReportFailure = (error) => {
  return { type: UPDATE_REPORT_FAILURE, payload: error };
};

const retrieveAssetRequest = () => {
  return { type: RETRIEVE_ASSET_REQUEST };
};

const retrieveAssetSuccess = (data) => {
  return { type: RETRIEVE_ASSET_SUCCESS, payload: data };
};

const retrieveAssetFailure = (error) => {
  return { type: RETRIEVE_ASSET_FAILURE, payload: error };
};

const retrieveServiceAssetRequest = () => {
  return { type: RETRIEVE_SERVICE_ASSET_REQUEST };
};

const retrieveServiceAssetSuccess = (data) => {
  return { type: RETRIEVE_SERVICE_ASSET_SUCCESS, payload: data };
};

const retrieveServiceAssetFailure = (error) => {
  return { type: RETRIEVE_ALL_ASSETS_FAILURE, payload: error };
};

const userDetailCheckRequest = () => {
  return { type: USER_DETAIL_CHECK_REQUEST };
};

const userDetailCheckSuccess = (data) => {
  return { type: USER_DETAIL_CHECK_SUCCESS, payload: data };
};

const userDetailCheckFailure = (error) => {
  return { type: USER_DETAIL_CHECK_FAILURE, payload: error };
};

const createSuperAdminRequest = () => {
  return { type: CREATE_SUPERADMIN_REQUEST };
};

const createSuperAdminSuccess = (data) => {
  return { type: CREATE_SUPERADMIN_SUCCESS, payload: data };
};

const createSuperAdminFailure = (error) => {
  return { type: CREATE_SUPERADMIN_FAILURE, payload: error };
};

const allUserListRequest = () => {
  return { type: ALL_USER_LIST_REQUEST };
};

const allUserListSuccess = (data) => {
  return { type: ALL_USER_LIST_SUCCESS, payload: data };
};

const allUserListFailure = (error) => {
  return { type: ALL_USER_LIST_FAILURE, payload: error };
};

const createSubuserToSuperAdminRequest = () => {
  return { type: CREATE_SUBUSER_TO_SUPERADMIN_REQUEST };
};

const createSubuserToSuperAdminSuccess = (data) => {
  return { type: CREATE_SUBUSER_TO_SUPERADMIN_SUCCESS, payload: data };
};

const createSubuserToSuperAdminFailure = (error) => {
  return { type: CREATE_SUBUSER_TO_SUPERADMIN_FAILURE, payload: error };
};

const updateSubuserToSuperAdminRequest = () => {
  return { type: UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST };
};

const updateSubuserToSuperAdminSuccess = (data) => {
  return { type: UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS, payload: data };
};

const updateSubuserToSuperAdminFailure = (error) => {
  return { type: UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE, payload: error };
};

const retrieveIncidentdropdownFailure = (error) => {
  return { type: GET_RETRIEVE_INCIDENT_ASSETS_FAILURE, payload: error };
};
const retrieveIncidentdropdownRequest = () => {
  return { type: GET_RETRIEVE_INCIDENT_ASSETS_REQUEST };
};

const retrieveIncidentdropdownSuccess = (data) => {
  return {
    type: GET_RETRIEVE_INCIDENT_ASSETS_SUCCESS,
    payload: data,
  };
};

const checkoutassetRequest = () => {
  return { type: CHECKOUT_ASSET_REQUEST };
};

const checkoutassetSuccess = (data) => {
  return { type: CHECKOUT_ASSET_SUCCESS, payload: data };
};

const checkoutassetFailure = (error) => {
  return { type: CHECKOUT_ASSET_FAILURE, payload: error };
};

// ..................Assets not working............................................

const assetsnotworkingRequest = () => {
  return { type: NOT_WORKING_ASSETS_REQUEST };
};

const assetsnotworkingSuccess = (data) => {
  return { type: NOT_WORKING_ASSETS_SUCCESS, payload: data };
};

const assetsnotworkingFailure = (error) => {
  return { type: NOT_WORKING_ASSETS_FAILURE, payload: error };
};

// ..................Assets discarded............................................

const discardedRequest = () => {
  return { type: DISCARDED_ASSETS_REQUEST };
};

const discardedSuccess = (data) => {
  return { type: DISCARDED_ASSETS_SUCCESS, payload: data };
};

const discardedFailure = (error) => {
  return { type: DISCARDED_ASSETS_FAILURE, payload: error };
};

// ..................Assets Delete............................................

const AssetDeleteRequest = () => {
  return { type: ASSETS_DELETE_REQUEST };
};

const AssetDeleteSuccess = (data) => {
  return { type: ASSETS_DELETE_SUCCESS, payload: data };
};

const AssetDeleteFailure = (error) => {
  return { type: ASSETS_DELETE_FAILURE, payload: error };
};

const chatbotRequest = () => {
  return { type: CHAT_BOT_REQUEST };
};

const chatbotSuccess = (data) => {
  return { type: CHAT_BOT_SUCCESS, payload: data };
};

const chatbotFailure = (error) => {
  return { type: CHAT_BOT_FAILURE, payload: error };
};

// ..................Deleted assets List............................................

const deleteDataAssetRequest = () => {
  return { type: DELETEDDATA_ASSET_REQUEST };
};

const deleteDataAssetSuccess = (data) => {
  return { type: DELETEDDATA_ASSET_SUCCESS, payload: data };
};

const deleteDataAssetFailure = (error) => {
  return { type: DELETEDDATA_ASSET_FAILURE, payload: error };
};

// ..................Deleted Incident ............................................

const deletedIncidentRequest = () => {
  return {
    type: DELETED_INCIDENT_REQUEST,
  };
};

const deletedIncidentSuccess = (data) => {
  return {
    type: DELETED_INCIDENT_SUCCESS,
    payload: data,
  };
};

const deletedIncidentFailure = (error) => {
  return {
    type: DELETED_INCIDENT_FAILURE,
    payload: error,
  };
};

// ..................Asset Movement Approve ............................................

const movementApproveRequest = () => {
  return {
    type: MOVEMENT_APPROVE_REQUEST,
  };
};

const movementApproveSuccess = (data) => {
  return {
    type: MOVEMENT_APPROVE_SUCCESS,
    payload: data,
  };
};

const movementApproveFailure = (error) => {
  return {
    type: MOVEMENT_APPROVE_FAILURE,
    payload: error,
  };
};

// ..................Asset Movement Delete ............................................

const movementDeleteRequest = () => {
  return {
    type: MOVEMENT_DELETE_REQUEST,
  };
};

const movementDeleteSuccess = (data) => {
  return {
    type: MOVEMENT_DELETE_SUCCESS,
    payload: data,
  };
};

const movementDeleteFailure = (error) => {
  return {
    type: MOVEMENT_DELETE_FAILURE,
    payload: error,
  };
};

// ..................Sell Online Pending ............................................

const sellOnlinePendingRequest = () => {
  return {
    type: SELL_ONLINE_PRENDING_REQUEST,
  };
};

const sellOnlinePendingSuccess = (data) => {
  return {
    type: SELL_ONLINE_PRENDING_SUCCESS,
    payload: data,
  };
};

const sellOnlinePendingFailure = (error) => {
  return {
    type: SELL_ONLINE_PRENDING_FAILURE,
    payload: error,
  };
};

// ..................Sell Online Approved ............................................

const sellOnlineApprovedRequest = () => {
  return {
    type: SELL_ONLINE_Approved_REQUEST,
  };
};

const sellOnlineApprovedSuccess = (data) => {
  return {
    type: SELL_ONLINE_Approved_SUCCESS,
    payload: data,
  };
};

const sellOnlineApprovedFailure = (error) => {
  return {
    type: SELL_ONLINE_Approved_FAILURE,
    payload: error,
  };
};

// ..................Sell Online Approve ............................................

const sellOnlineApproveRequest = () => {
  return {
    type: SELL_ONLINE_Approve_REQUEST,
  };
};

const sellOnlineApproveSuccess = (data) => {
  return {
    type: SELL_ONLINE_Approve_SUCCESS,
    payload: data,
  };
};

const sellOnlineApproveFailure = (error) => {
  return {
    type: SELL_ONLINE_Approve_FAILURE,
    payload: error,
  };
};

// ..................Discard Request ............................................

const discardRequestedRequest = () => {
  return {
    type: DISCARD_REQUESTED_REQUEST,
  };
};

const discardRequestedSuccess = (data) => {
  return {
    type: DISCARD_REQUESTED_SUCCESS,
    payload: data,
  };
};

const discardRequestedFailure = (error) => {
  return {
    type: DISCARD_REQUESTED_FAILURE,
    payload: error,
  };
};
// ..................Department All............................................

const DepartmentAllRequest = () => {
  return {
    type: DEPARTMENT_ALL_REQUEST,
  };
};

const DepartmentAllSuccess = (data) => {
  return {
    type: DEPARTMENT_ALL_SUCCESS,
    payload: data,
  };
};

const DepartmentAllFailure = (error) => {
  return {
    type: DEPARTMENT_ALL_FAILURE,
    payload: error,
  };
};

// .................. Incident Check............................................

const IncidentCheckRequest = () => {
  return {
    type: INICDENT_CHECK_REQUEST,
  };
};

const IncidentCheckSuccess = (data) => {
  return {
    type: INICDENT_CHECK_SUCCESS,
    payload: data,
  };
};

const IncidentCheckFailure = (error) => {
  return {
    type: INICDENT_CHECK_FAILURE,
    payload: error,
  };
};

// .................. Incident Acknowledge............................................

const IncidentAcknowledgeRequest = () => {
  return {
    type: INICDENT_ACKNOWLEDGE_REQUEST,
  };
};

const IncidentAcknowledgeSuccess = (data) => {
  return {
    type: INICDENT_ACKNOWLEDGE_SUCCESS,
    payload: data,
  };
};

const IncidentAcknowledgeFailure = (error) => {
  return {
    type: INICDENT_ACKNOWLEDGE_FAILURE,
    payload: error,
  };
};

// .................. Service Work Order............................................

const ServiceWorkOrderRequest = () => {
  return {
    type: SERVICE_WORK_ORDER_REQUEST,
  };
};

const ServiceWorkOrderSuccess = (data) => {
  return {
    type: SERVICE_WORK_ORDER_SUCCESS,
    payload: data,
  };
};

const ServiceWorkOrderFailure = (error) => {
  return {
    type: SERVICE_WORK_ORDER_FAILURE,
    payload: error,
  };
};

// ..................Add Service Work Order............................................

const AddServiceWorkOrderRequest = () => {
  return {
    type: ADD_SERVICE_WORK_ORDER_REQUEST,
  };
};

const AddServiceWorkOrderSuccess = (data) => {
  return {
    type: ADD_SERVICE_WORK_ORDER_SUCCESS,
    payload: data,
  };
};

const AddServiceWorkOrderFailure = (error) => {
  return {
    type: ADD_SERVICE_WORK_ORDER_FAILURE,
    payload: error,
  };
};

// ..................Add Equipment Audit............................................

const EquipmentAuditRequest = () => {
  return {
    type: ADD_EQUIPMENT_AUDIT_REQUEST,
  };
};

const EquipmentAuditSuccess = (data) => {
  return {
    type: ADD_EQUIPMENT_AUDIT_SUCCESS,
    payload: data,
  };
};

const EquipmentAuditFailure = (error) => {
  return {
    type: ADD_EQUIPMENT_AUDIT_FAILURE,
    payload: error,
  };
};

// .................. Equipment Audit List............................................

const EquipmentAuditListRequest = () => {
  return {
    type: EQUIPMENT_AUDIT_LIST_REQUEST,
  };
};

const EquipmentAuditListSuccess = (data) => {
  return {
    type: EQUIPMENT_AUDIT_LIST_SUCCESS,
    payload: data,
  };
};

const EquipmentAuditListFailure = (error) => {
  return {
    type: EQUIPMENT_AUDIT_LIST_FAILURE,
    payload: error,
  };
};

// .................. Equipment Audit Approve............................................

const EquipmentAuditApproveRequest = () => {
  return {
    type: EQUIPMENT_AUDIT_APPROVE_REQUEST,
  };
};

const EquipmentAuditApproveSuccess = (data) => {
  return {
    type: EQUIPMENT_AUDIT_APPROVE_SUCCESS,
    payload: data,
  };
};

const EquipmentAuditApproveFailure = (error) => {
  return {
    type: EQUIPMENT_AUDIT_APPROVE_FAILURE,
    payload: error,
  };
};

// ..................Create Equipment Audit ............................................

const CreateEquipmentAuditRequest = () => {
  return {
    type: CREATE_EQUIPMENT_AUDIT_REQUEST,
  };
};

const CreateEquipmentAuditSuccess = (data) => {
  return {
    type: CREATE_EQUIPMENT_AUDIT_SUCCESS,
    payload: data,
  };
};

const CreateEquipmentAuditFailure = (error) => {
  return {
    type: CREATE_EQUIPMENT_AUDIT_FAILURE,
    payload: error,
  };
};

// ..................Department Audit List............................................

const DepartmentAuditListRequest = () => {
  return {
    type: DEPARTMENT_AUDIT_LIST_REQUEST,
  };
};

const DepartmentAuditListSuccess = (data) => {
  return {
    type: DEPARTMENT_AUDIT_LIST_SUCCESS,
    payload: data,
  };
};

const DepartmentAuditListFailure = (error) => {
  return {
    type: DEPARTMENT_AUDIT_LIST_FAILURE,
    payload: error,
  };
};

// .................. Asset Audit List............................................

const AssetAuditListRequest = () => {
  return {
    type: ASSET_AUDIT_LIST_REQUEST,
  };
};

const AssetAuditListSuccess = (data) => {
  return {
    type: ASSET_AUDIT_LIST_SUCCESS,
    payload: data,
  };
};

const AssetAuditListFailure = (error) => {
  return {
    type: ASSET_AUDIT_LIST_FAILURE,
    payload: error,
  };
};

// .................. Asset Audit Ignore............................................

const AssetAuditIgnoreRequest = () => {
  return {
    type: ASSET_AUDIT_IGNORE_REQUEST,
  };
};

const AssetAuditIgnoreSuccess = (data) => {
  return {
    type: ASSET_AUDIT_IGNORE_SUCCESS,
    payload: data,
  };
};

const AssetAuditIgnoreFailure = (error) => {
  return {
    type: ASSET_AUDIT_IGNORE_FAILURE,
    payload: error,
  };
};

// .................. Asset Audit Approve List............................................

const AssetAuditApproveListRequest = () => {
  return {
    type: ASSET_AUDIT_APPROVE_LIST_REQUEST,
  };
};

const AssetAuditApproveListSuccess = (data) => {
  return {
    type: ASSET_AUDIT_APPROVE_LIST_SUCCESS,
    payload: data,
  };
};

const AssetAuditApproveListFailure = (error) => {
  return {
    type: ASSET_AUDIT_APPROVE_LIST_FAILURE,
    payload: error,
  };
};

// .................. Asset Audit Ignore List............................................

const AssetAuditIgnoreListRequest = () => {
  return {
    type: ASSET_AUDIT_IGNORE_LIST_REQUEST,
  };
};

const AssetAuditIgnoreListSuccess = (data) => {
  return {
    type: ASSET_AUDIT_IGNORE_LIST_SUCCESS,
    payload: data,
  };
};

const AssetAuditIgnoreListFailure = (error) => {
  return {
    type: ASSET_AUDIT_IGNORE_LIST_FAILURE,
    payload: error,
  };
};

// ..................Audit Request Approve............................................

const AuditRequestApproveRequest = () => {
  return {
    type: AUDIT_REQUEST_APPROVE_REQUEST,
  };
};

const AuditRequestApproveSuccess = (data) => {
  return {
    type: AUDIT_REQUEST_APPROVE_SUCCESS,
    payload: data,
  };
};

const AuditRequestApproveFailure = (error) => {
  return {
    type: AUDIT_REQUEST_APPROVE_FAILURE,
    payload: error,
  };
};

// ..................Audit Completed............................................

const AuditCompletedRequest = () => {
  return {
    type: AUDIT_COMPLETED_REQUEST,
  };
};

const AuditCompletedSuccess = (data) => {
  return {
    type: AUDIT_COMPLETED_SUCCESS,
    payload: data,
  };
};

const AuditCompletedFailure = (error) => {
  return {
    type: AUDIT_COMPLETED_FAILURE,
    payload: error,
  };
};

// ..................Audit Details............................................

const AuditDetailsRequest = () => {
  return {
    type: AUDIT_DETAILS_REQUEST,
  };
};

const AuditDetailsSuccess = (data) => {
  return {
    type: AUDIT_DETAILS_SUCCESS,
    payload: data,
  };
};

const AuditDetailsFailure = (error) => {
  return {
    type: AUDIT_DETAILS_FAILURE,
    payload: error,
  };
};

// ..................Grade Update............................................

const GradeUpdateRequest = () => {
  return {
    type: GRADE_UPDATE_REQUEST,
  };
};

const GradeUpdateSuccess = (data) => {
  return {
    type: GRADE_UPDATE_SUCCESS,
    payload: data,
  };
};

const GradeUpdateFailure = (error) => {
  return {
    type: GRADE_UPDATE_FAILURE,
    payload: error,
  };
};

// ..................Accessory Upload Image............................................

const AccessoryUploadImageRequest = () => {
  return {
    type: ACCESSORY_UPLOAD_IMAGE_REQUEST,
  };
};

const AccessoryUploadImageSuccess = (data) => {
  return {
    type: ACCESSORY_UPLOAD_IMAGE_SUCCESS,
    payload: data,
  };
};

const AccessoryUploadImageFailure = (error) => {
  return {
    type: ACCESSORY_UPLOAD_IMAGE_FAILURE,
    payload: error,
  };
};

// ..................Accessory Delete Image............................................

const AccessoryDeleteImageRequest = () => {
  return {
    type: ACCESSORY_DELETE_IMAGE_REQUEST,
  };
};

const AccessoryDeleteImageSuccess = (data) => {
  return {
    type: ACCESSORY_DELETE_IMAGE_SUCCESS,
    payload: data,
  };
};

const AccessoryDeleteImageFailure = (error) => {
  return {
    type: ACCESSORY_DELETE_IMAGE_FAILURE,
    payload: error,
  };
};

// ..................Create Accessory............................................

const CreateAccessoryRequest = () => {
  return {
    type: CREATE_ACCESSORY_REQUEST,
  };
};

const CreateAccessorySuccess = (data) => {
  return {
    type: CREATE_ACCESSORY_SUCCESS,
    payload: data,
  };
};

const CreateAccessoryFailure = (error) => {
  return {
    type: CREATE_ACCESSORY_FAILURE,
    payload: error,
  };
};

// ..................Create Accessory Name List............................................

const CreateAccessoryNameListRequest = () => {
  return {
    type: CREATE_ACCESSORY_NAME_LIST_REQUEST,
  };
};

const CreateAccessoryNameListSuccess = (data) => {
  return {
    type: CREATE_ACCESSORY_NAME_LIST_SUCCESS,
    payload: data,
  };
};

const CreateAccessoryNameListFailure = (error) => {
  return {
    type: CREATE_ACCESSORY_NAME_LIST_FAILURE,
    payload: error,
  };
};

// ..................Audit Upload Image............................................

const AuditUploadImageRequest = () => {
  return {
    type: AUDIT_UPLOAD_IMAGE_REQUEST,
  };
};

const AuditUploadImageSuccess = (data) => {
  return {
    type: AUDIT_UPLOAD_IMAGE_SUCCESS,
    payload: data,
  };
};

const AuditUploadImageFailure = (error) => {
  return {
    type: AUDIT_UPLOAD_IMAGE_FAILURE,
    payload: error,
  };
};

// ..................Audit Delete Image............................................

const AuditDeleteImageRequest = () => {
  return {
    type: AUDIT_DELETE_IMAGE_REQUEST,
  };
};

const AuditDeleteImageSuccess = (data) => {
  return {
    type: AUDIT_DELETE_IMAGE_SUCCESS,
    payload: data,
  };
};

const AuditDeleteImageFailure = (error) => {
  return {
    type: AUDIT_DELETE_IMAGE_FAILURE,
    payload: error,
  };
};

// ..................Audit Available List ............................................

const AccessoryAvailableListRequest = () => {
  return {
    type: ACCESSORY_AVAILABLE_LIST_REQUEST,
  };
};

const AccessoryAvailableListSuccess = (data) => {
  return {
    type: ACCESSORY_AVAILABLE_LIST_SUCCESS,
    payload: data,
  };
};

const AccessoryAvailableListFailure = (error) => {
  return {
    type: ACCESSORY_AVAILABLE_LIST_FAILURE,
    payload: error,
  };
};

// ..................Retrive Incident Details............................................

const RetriveIncidentDetailsRequest = () => {
  return { type: RETRIVE_INCIDENT_DETAILS_REQUEST };
};

const RetriveIncidentDetailsSuccess = (data) => {
  return { type: RETRIVE_INCIDENT_DETAILS_SUCCESS, payload: data };
};

const RetriveIncidentDetailsFailure = (error) => {
  return { type: RETRIVE_INCIDENT_DETAILS_FAILURE, payload: error };
};

// ..................Edit Clear Incident............................................

const EdtClearIncidentRequest = () => {
  return { type: EDIT_CLEAR_INCIDENT_REQUEST };
};

const EdtClearIncidentSuccess = (data) => {
  return { type: EDIT_CLEAR_INCIDENT_SUCCESS, payload: data };
};

const EdtClearIncidentFailure = (error) => {
  return { type: EDIT_CLEAR_INCIDENT_FAILURE, payload: error };
};

// ..................Accessory Update List............................................

const AccessoryUpdateListRequest = () => {
  return { type: ACCESSORY_UPDATE_LIST_REQUEST };
};

const AccessoryUpdateListSuccess = (data) => {
  return { type: ACCESSORY_UPDATE_LIST_SUCCESS, payload: data };
};

const AccessoryUpdateListFailure = (error) => {
  return { type: ACCESSORY_UPDATE_LIST_FAILURE, payload: error };
};

// ..................Add Work Order............................................

const AddWorkOrderListRequest = () => {
  return { type: ADD_WORK_ORDER_REQUEST };
};

const AddWorkOrderListSuccess = (data) => {
  return { type: ADD_WORK_ORDER_SUCCESS, payload: data };
};

const AddWorkOrderListFailure = (error) => {
  return { type: ADD_WORK_ORDER_FAILURE, payload: error };
};

// ..................Accessory List............................................

const AccessoryListRequest = () => {
  return { type: ACCESSORY_LIST_REQUEST };
};

const AccessoryListSuccess = (data) => {
  return { type: ACCESSORY_LIST_SUCCESS, payload: data };
};

const AccessoryListFailure = (error) => {
  return { type: ACCESSORY_LIST_FAILURE, payload: error };
};

// ..................Approve Incident............................................

const ApproveIncidentRequest = () => {
  return { type: APPROVE_INCIDENT_REQUEST };
};

const ApproveIncidentSuccess = (data) => {
  return { type: APPROVE_INCIDENT_SUCCESS, payload: data };
};

const ApproveIncidentFailure = (error) => {
  return { type: APPROVE_INCIDENT_FAILURE, payload: error };
};

// ..................Sell Online Add Image............................................

const SellOnlineAddImageRequest = () => {
  return { type: SELL_ONLINE_ADD_IMAGE_REQUEST };
};

const SellOnlineAddImageSuccess = (data) => {
  return { type: SELL_ONLINE_ADD_IMAGE_SUCCESS, payload: data };
};

const SellOnlineAddImageFailure = (error) => {
  return { type: SELL_ONLINE_ADD_IMAGE_FAILURE, payload: error };
};

// ..................Sell Online Delete Image............................................

const SellOnlineDeleteImageRequest = () => {
  return { type: SELL_ONLINE_DELETE_IMAGE_REQUEST };
};

const SellOnlineDeleteImageSuccess = (data) => {
  return { type: SELL_ONLINE_DELETE_IMAGE_SUCCESS, payload: data };
};

const SellOnlineDeleteImageFailure = (error) => {
  return { type: SELL_ONLINE_DELETE_IMAGE_FAILURE, payload: error };
};

// ..................Department Asset List............................................

const DepartmentAssetListRequest = () => {
  return { type: DEPARTMENT_ASSET_LIST_REQUEST };
};

const DepartmentAssetListSuccess = (data) => {
  return { type: DEPARTMENT_ASSET_LIST_SUCCESS, payload: data };
};

const DepartmentAssetListFailure = (error) => {
  return { type: DEPARTMENT_ASSET_LIST_FAILURE, payload: error };
};

// ..................Discard Pending List............................................

const discardPendingRequest = () => {
  return {
    type: DISCARD_PENDING_REQUEST,
  };
};

const discardPendingSuccess = (data) => {
  return {
    type: DISCARD_PENDING_SUCCESS,
    payload: data,
  };
};

const discardPendingFailure = (error) => {
  return {
    type: DISCARD_PENDING_FAILURE,
    payload: error,
  };
};

// ..................Discard Approve ............................................

const discardApproveRequest = () => {
  return {
    type: DISCARD_Approve_REQUEST,
  };
};

const discardApproveSuccess = (data) => {
  return {
    type: DISCARD_Approve_SUCCESS,
    payload: data,
  };
};

const discardApproveFailure = (error) => {
  return {
    type: DISCARD_Approve_FAILURE,
    payload: error,
  };
};

// ..................Discard Approved ............................................

const discardApprovedRequest = () => {
  return {
    type: DISCARD_Approved_REQUEST,
  };
};

const discardApprovedSuccess = (data) => {
  return {
    type: DISCARD_Approved_SUCCESS,
    payload: data,
  };
};

const discardApprovedFailure = (error) => {
  return {
    type: DISCARD_Approved_FAILURE,
    payload: error,
  };
};

// ..................Depertment Deleted list ............................................

const departmentDeleteListRequest = () => {
  return {
    type: DEPARTMENT_DELETE_LIST_REQUEST,
  };
};

const departmentDeleteListSuccess = (data) => {
  return {
    type: DEPARTMENT_DELETE_LIST_SUCCESS,
    payload: data,
  };
};

const departmentDeleteListFailure = (error) => {
  return {
    type: DEPARTMENT_DELETE_LIST_FAILURE,
    payload: error,
  };
};

// ..................Notification list ............................................

const notificationListRequest = () => {
  return {
    type: NOTIFICATION_LIST_REQUEST,
  };
};

const notificationListSuccess = (data) => {
  return {
    type: NOTIFICATION_LIST_SUCCESS,
    payload: data,
  };
};

const notificationListFailure = (error) => {
  return {
    type: NOTIFICATION_LIST_FAILURE,
    payload: error,
  };
};

// ..................Notification All list ............................................

const notificationAllListRequest = () => {
  return {
    type: NOTIFICATION_ALL_LIST_REQUEST,
  };
};

const notificationAllListSuccess = (data) => {
  return {
    type: NOTIFICATION_ALL_LIST_SUCCESS,
    payload: data,
  };
};

const notificationAllListFailure = (error) => {
  return {
    type: NOTIFICATION_ALL_LIST_FAILURE,
    payload: error,
  };
};

// Delete AMC/CMC image upload

const deleteAMCCMCImageFailure = (error) => {
  return { type: DELETE_AMCCMC_IMAGE_FAILURE, payload: error };
};

const deleteAMCCMCImageRequest = () => {
  return { type: DELETE_AMCCMC_IMAGE_REQUEST };
};

const deleteAMCCMCImageSuccess = (data) => {
  return { type: DELETE_AMCCMC_IMAGE__SUCCESS, payload: data };
};

//   AddAsset AMC/CMC IMAGE Upload

const uploadCertificateFailure = (error) => {
  return { type: UPLOADEDAMCCMC_IMAGE_FAILURE, payload: error };
};

const uploadCertificateRequest = () => {
  return { type: UPLOADEDAMCCMC_IMAGE_REQUEST };
};

const uploadCertificateSuccess = (data) => {
  return { type: UPLOADEDAMCCMC_IMAGE__SUCCESS, payload: data };
};

export const assetcheckout = (data) => async (dispatch) => {
  dispatch(checkoutassetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_ASSETS_RETRIVE,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(assetcheckout(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(checkoutassetSuccess(res.data));
    }
  } catch (error) {
    dispatch(checkoutassetFailure(error));
  }
};

export const getNewAssets = (data) => async (dispatch) => {
  dispatch(getNewAssetsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_BM_RETRIEVE_ASSET_NEW,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(getNewAssets(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getNewAssetsSuccess(res.data));
    }
  } catch (error) {
    dispatch(getNewAssetsFailure(error));
  }
};

export const bioMedicalService = (data) => async (dispatch) => {
  dispatch(bioMedicalServiceRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_BIOMEDICAL_SERVICE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(bioMedicalService(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(bioMedicalServiceSuccess(res.data));
    }
  } catch (error) {
    dispatch(bioMedicalServiceFailure(error));
  }
};

//-------------------------Clear Service Receipt  Delete-----------------------
export const clearServiceReceiptDelete = (data) => async (dispatch) => {
  dispatch(clearServiceReceiptDeleteRequest());
  let token = localStorage.getItem("userToken");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_SERVICE_RECEIPT_DELETE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(clearServiceReceiptDelete(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(clearServiceReceiptDeleteSuccess(res.data));
    }
  } catch (error) {
    dispatch(clearServiceReceiptDeleteFailure(error));
  }
};

export const clearServiceReceipt = (data) => async (dispatch) => {
  dispatch(clearServiceReceiptRequest());
  let token = localStorage.getItem("userToken");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_SERVICE_RECEIPT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(clearServiceReceipt(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(clearServiceReceiptSuccess(res.data));
    }
  } catch (error) {
    dispatch(clearServiceReceiptFailure(error));
  }
};

export const clearServiceImgDel = (data) => async (dispatch) => {
  dispatch(clearServiceImgDelRequest());
  let token = localStorage.getItem("userToken");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_SERVICE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(clearServiceImgDel(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(clearServiceImgDelSuccess(res.data));
    }
  } catch (error) {
    dispatch(clearServiceImgDelFailure(error));
  }
};

export const postData = (data) => async (dispatch) => {
  dispatch(postDataRequest());
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_REQUEST_DEMO,
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(postData(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(postDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(postDataFailure(error));
  }
};

export const getCount = (data) => (dispatch) => {
  dispatch(countRequest());
  dispatch(countSuccess(data));
  dispatch(countFailure());
};
//--//
export const getUserData = (data) => async (dispatch) => {
  dispatch(getUserDataRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_USERS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(getUserData(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getUserDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(getUserDataFailure(error));
  }
};
//--//

export const postUserData = (data) => async (dispatch) => {
  dispatch(postUserDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_POST_SUB_USERS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(postUserData(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(postUserDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(postUserDataFailure(error));
  }
};

export const getDepartmentData = (data) => async (dispatch) => {
  dispatch(getDepartmentDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_DEPARTMENTS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(getDepartmentData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getDepartmentDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(getDepartmentDataFailure(error));
  }
};

export const updateData = (data) => async (dispatch) => {
  dispatch(updateDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_POST_SUBUSER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(updateData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(updateDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(updateDataFailure(error));
  }
};

export const currentData = (data) => async (dispatch) => {
  dispatch(currentDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_POST_SUBUSER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(currentData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(currentDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(currentDataFailure(error));
  }
};

export const incidentData = (data) => async (dispatch) => {
  dispatch(incidentDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_INCIDENTS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(incidentData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(incidentDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(incidentDataFailure(error));
  }
};

export const getIncidentDetail = (data) => async (dispatch) => {
  dispatch(incidentDetailRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_INCIDENT_DETAILS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(getIncidentDetail(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(incidentDetailSuccess(res.data));
    }
  } catch (error) {
    dispatch(incidentDetailFailure(error));
  }
};

export const DownloadPDF = (pdf, title) => (dispatch) => {
  dispatch(DownloadPDFRequest());
  axios({
    url: `${pdf}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, `${title}`);
      dispatch(DownloadPDFSuccess(response.data));
    })
    .catch((error) => {
      dispatch(DownloadPDFFailure(error));
    });
};

export const DownloadPDFHistory = (fileUrl, title) => (dispatch) => {
  if (!fileUrl || !title) {
    console.error("Invalid fileUrl or title", fileUrl, title);
    return;
  }
  dispatch(DownloadPDFRequestHistory());

  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob", // important for binary data
  })
    .then((response) => {
      const contentType = response.headers["content-type"] || "";
      let fileBlob;
      let fileExtension = "";

      const urlExtension = fileUrl
        .split(".")
        .pop()
        .split(/[\?#]/)[0]
        .toLowerCase();

      if (urlExtension === "pdf") {
        fileBlob = new Blob([response.data], { type: "application/pdf" });
        fileExtension = "pdf";
      } else if (urlExtension === "jpg" || urlExtension === "jpeg") {
        fileBlob = new Blob([response.data], { type: "image/jpeg" });
        fileExtension = "jpg";
      } else if (urlExtension === "png") {
        fileBlob = new Blob([response.data], { type: "image/png" });
        fileExtension = "png";
      } else {
        fileBlob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        fileExtension = urlExtension || "bin";
      }

      FileSaver.saveAs(fileBlob, `${title}.${fileExtension}`);
      dispatch(DownloadPDFSuccessHistory(response.data));
    })
    .catch((error) => {
      dispatch(DownloadPDFFailureHistory(error));
    });
  console.log("fileUrl:", fileUrl, "title:", title);
};

export const uploadImage = (data) => async (dispatch) => {
  dispatch(addImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_POST_UPLOAD_ASSET_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(uploadImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

export const addAccesoryUploadImage = (data) => async (dispatch) => {
  dispatch(addAccessoryUploadFile());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_ACCESSORY_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(addAccesoryUploadImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

// export const addAccesoryUploadImageHistory = (data) => (dispatch) => {
//   dispatch(addAccessoryUploadFileHistory());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_UPLOAD_ACCESSORY_ASSET_HISTORY,
//     headers: { Authorization: `Bearer ${token}` },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
//         dispatch(addImageSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(addImageFailure(error));
//     });
// };

export const addAccesoryUploadImageHistory = (data) => async (dispatch) => {
  dispatch(addAccessoryUploadFileHistory());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_ACCESSORY_ASSET_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(addAccesoryUploadImageHistory(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

export const uploadProfileImage = (data) => async (dispatch) => {
  dispatch(uploadUserProfileImage());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_PROFILE_LOGO,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(uploadProfileImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

export const updateProfileImage = (data) => async (dispatch) => {
  dispatch(updateUserProfileImage());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPDATE_PROFILE_LOGO,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(updateProfileImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

export const addAccesoryUpdate = (data) => async (dispatch) => {
  dispatch(addAccessoryUpdate());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPDATE_ADD_ACCESSORRY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(addAccesoryUpdate(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

// export const addAccesoryUpdateHistory = (data) => (dispatch) => {
//   dispatch(addAccessoryUpdateHistory());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_UPDATE_ADD_ACCESSORRY_HISTORY,
//     headers: { Authorization: `Bearer ${token}` },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
//         dispatch(addImageSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(addImageFailure(error));
//     });
// };

export const addAccesoryUpdateHistory = (data) => async (dispatch) => {
  dispatch({ type: ADD_ACCESSORY_UPDATE_HISTORY });
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPDATE_ADD_ACCESSORRY_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(addAccesoryUpdateHistory(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      // Dispatch the accessory_history array to the reducer
      dispatch({
        type: ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS,
        payload: {
          accessory_history: resData.accessory_history, // Use the correct field from the response
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_ACCESSORY_UPDATE_HISTORY_FAILURE,
      payload: error,
    });
  }
};

export const fetchDataFromAPI = (requestBody) => async (dispatch) => {
  dispatch(fetchDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_RETRIVENEW_ASSET_DETAILS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: requestBody,
    });

    if (response.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(response.data));
      if (newToken) return dispatch(fetchDataFromAPI(requestBody));
    } else if (response.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(response.data));
    } else {
      dispatch(fetchDataSuccess(response.data));
    }
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};

// export const addAccesoryUpdate = (data, token) => (dispatch) => {
//   return axios({
//     method: 'POST',
//     url: process.env.REACT_APP_UPDATE_ADD_ACCESSORRY,
//     headers: { Authorization: `Bearer ${token}` },
//     data
//   })
//     .then((res) => {
//       dispatch({ type: 'ADD_ACCESSORY_UPDATE', payload: res.data });
//     })
//     .catch((error) => {
//       dispatch({ type: 'FETCH_ACCESSORIES_FAILURE', payload: error });
//     });
// };

// export const fetchAccessoriesData = (data, token) => (dispatch) => {
//   dispatch({ type: 'FETCH_ACCESSORIES_REQUEST' });

//   return axios({
//     method: 'POST',
//     url: process.env.REACT_APP_RETRIVENEW_ASSET_DETAILS,
//     headers: { Authorization: `Bearer ${token}` },
//     data
//   })
//   .then((res) => {
//     console.log("API response data:", res.data);
//     dispatch({ type: 'FETCH_ACCESSORIES_SUCCESS', payload: res.data });
//   })
//   .catch((error) => {
//     dispatch({ type: 'FETCH_ACCESSORIES_FAILURE', payload: error });
//   });
// };

export const uploadedImage = (data) => async (dispatch) => {
  dispatch(addedImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_UPLOAD_ASSET_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(uploadedImage(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(addedImageSuccess(resData));
    }
  } catch (error) {
    dispatch(addedImageFailure(error));
  }
};

export const uploadedCertificate = (data) => async (dispatch) => {
  dispatch(addedCertificateRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_ASSET_WARRANTY_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(uploadedCertificate(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(addedCertificateSuccess(resData));
    }
  } catch (error) {
    dispatch(addedCertificateFailure(error));
  }
};

export const uploadQrCodeImage = (data) => async (dispatch) => {
  dispatch(uploadQrCodeImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_ASSET_QRCODE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(uploadQrCodeImage(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(uploadQrCodeImageSuccess(resData));
    }
  } catch (error) {
    dispatch(uploadQrCodeImageFailure(error));
  }
};

export const CreateQRCode = (data) => (dispatch) => {
  dispatch(createQRcodeRequest());
  QRCode.toDataURL(data, { width: 300 })
    .then((url) => {
      dispatch(createQRcodeSuccess(url));
    })
    .catch((error) => {
      dispatch(createQRcodeFailure(error));
    });
};

export const addAsset = (data) => async (dispatch) => {
  dispatch(addAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_ADD_ASSET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(addAsset(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(addAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(addAssetFailure(error));
  }
};

export const postDepartment = (data) => async (dispatch) => {
  dispatch(postDepartmentRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_USER_GET_ALL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postDepartment(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postDepartmentSuccess(resData));
    }
  } catch (error) {
    dispatch(postDepartmentFailure(error));
  }
};

export const postDepartmentName = (data) => async (dispatch) => {
  dispatch(postDepartmentNameRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_POST_DEPARTMENT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postDepartmentName(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postDepartmentNameSuccess(resData));
    }
  } catch (error) {
    dispatch(postDepartmentNameFailure(error));
  }
};

export const departmentAlreadySaved = (data) => async (dispatch) => {
  dispatch(departmentAlreadySavedRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(departmentAlreadySaved(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(departmentAlreadySavedSuccess(resData));
    }
  } catch (error) {
    dispatch(departmentAlreadySavedFailure(error));
  }
};

export const updateDepartment = (data) => async (dispatch) => {
  dispatch(updateDepartRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPDATE_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(updateDepartment(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(updateDepartSuccess(resData));
    }
  } catch (error) {
    dispatch(updateDepartFailure(error));
  }
};

export const retrieveDepart = (data) => async (dispatch) => {
  dispatch(retrieveDepartRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SINGLE_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(retrieveDepart(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(retrieveDepartSuccess(resData));
    }
  } catch (error) {
    dispatch(retrieveDepartFailure(error));
  }
};

export const deleteDepart = (data) => (dispatch) => {
  dispatch(deleteDepartRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_DELETE_DEPARTMENT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(deleteDepartSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(deleteDepartFailure(error));
    });
};

// ..................Retrieve all assets............................................

// export const retrieveAllAssets = (data) => (dispatch) => {
//   dispatch(retrieveAllAssetsRequest());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_GET_ALL_ASSETS,
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
//         dispatch(retrieveAllAssetsSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(retrieveAllAssetsFailure(error));
//     });
// };

export const retrieveAllAssets = (data) => async (dispatch) => {
  dispatch(retrieveAllAssetsRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(retrieveAllAssets(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(retrieveAllAssetsSuccess(res.data));
    }
  } catch (error) {
    dispatch(retrieveAllAssetsFailure(error));
  }
};

// ..................Delete assets............................................

export const deleteAsset = (data) => async (dispatch) => {
  dispatch(deleteAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteAsset(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteAssetFailure(error));
  }
};

// ...................Create Service...........................................

export const createService = (data) => async (dispatch) => {
  dispatch(createServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CREATE_SERVICE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(createServiceFailure(error));
  }
};

// ...................Create Service Image Upload...........................................

export const createServiceImageUpload = (data) => async (dispatch) => {
  dispatch(createServiceImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_SERVICE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createServiceImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createServiceImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(createServiceImageUploadFailure(error));
  }
};

export const closedService = (data) => async (dispatch) => {
  dispatch(closedServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLOSE_SERVICE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(closedService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(closedServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(closedServiceFailure(error));
  }
};

// ................... Open Service ...........................................

export const openService = (data) => async (dispatch) => {
  dispatch(openServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_RETRIEVE_OPEN_SERVICES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(openService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(openServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(openServiceFailure(error));
  }
};

// ................... Service List ...........................................

export const serviceList = (data) => async (dispatch) => {
  dispatch(serviceListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_SERVICES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceList(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceListSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceListFailure(error));
  }
};

export const serviceDetail = (data) => async (dispatch) => {
  dispatch(serviceDetailRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SERVICES_DETAILS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceDetail(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceDetailSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceDetailFailure(error));
  }
};

// ................... Clear Service ...........................................

export const clearService = (data) => async (dispatch) => {
  dispatch(clearServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_SERVICES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(clearServiceFailure(error));
  }
};

// ................... Service Issue ...........................................

export const serviceIssue = (data) => async (dispatch) => {
  dispatch(serviceIssueRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_RETERIVE_ALL_SERVICE_ISSUE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceIssue(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceIssueSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceIssueFailure(error));
  }
};

export const incidentIssue = (data) => async (dispatch) => {
  dispatch(incidentIssueRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ALL_INCIDENT_ISSUE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(incidentIssue(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(incidentIssueSuccess(resData));
    }
  } catch (error) {
    dispatch(incidentIssueFailure(error));
  }
};

// ................... Service Image Upload ........................................

export const serviceImageUpload = (data) => async (dispatch) => {
  dispatch(serviceImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_CLEARED_SERVICE_IMAGES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceImageUploadFailure(error));
  }
};

// ................... Dropdown Incident ........................................

export const retrieveIncidentdropdown = (data) => async (dispatch) => {
  dispatch(retrieveIncidentdropdownRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_RETRIEVE_Incident_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(retrieveIncidentdropdown(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(retrieveIncidentdropdownSuccess(resData.assets));
    }
  } catch (error) {
    console.error("API Error:", error);
    dispatch(retrieveIncidentdropdownFailure(error));
  }
};

// ................... Logout..........................................

export const logOut = (data) => async (dispatch) => {
  dispatch(logOutRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_LOGOUT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(logOut(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(logOutSuccess(res.data));
    }
  } catch (error) {
    dispatch(logOutFailure(error));
  }
};


export const serviceExpenditureImageUpload = (data) => async (dispatch) => {
  dispatch(serviceExpenditureImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_SERVICE_EXPENDITURE_UPLOAD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceExpenditureImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceExpenditureImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceExpenditureImageUploadFailure(error));
  }
};

// ................... Incident Expenditure Image Upload ............................

export const incidentExpenditureImageUpload = (data) => async (dispatch) => {
  dispatch(incidentExpenditureImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_INCIDENT_EXPENDITURE_UPLOAD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;
    console.log("Image Upload Response:", resData);

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(incidentExpenditureImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(incidentExpenditureImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(incidentExpenditureImageUploadFailure(error));
  }
};

export const editAssetFun = (data) => async (dispatch) => {
  dispatch(editAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_EDIT_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(editAssetFun(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(editAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(editAssetFailure(error));
  }
};

// ................... Get Single Asset ............................

export const getAsset = (data) => async (dispatch) => {
  dispatch(getAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SINGLE_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getAsset(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(getAssetFailure(error));
  }
};

// ................... Dashboard Request Data ............................

export const getDashboardRequestData = (data) => async (dispatch) => {
  dispatch(requestDashboard());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_REQUEST_DASHBOARD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getDashboardRequestData(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getRequestDashboardSuccess(resData));
    }
  } catch (error) {
    dispatch(getRequestDashboardFailure(error));
  }
};

export const getSingleUser = (data) => async (dispatch) => {
  dispatch(getSingleUserRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SINGLE_USER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getSingleUser(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getSingleUserSuccess(resData));
    }
  } catch (error) {
    dispatch(getSingleUserFailure(error));
  }
};

export const deleteImage = (data) => async (dispatch) => {
  dispatch(deleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_ASSET_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteImage(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const deleteImageAddAccessory = (data) => async (dispatch) => {
  dispatch(deleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_UPLOAD_ACCESSORY_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteImageAddAccessory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const deleteImageAddAccessoryHistory = (data) => async (dispatch) => {
  dispatch(deleteImageRequestHistory());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_UPLOAD_ACCESSORY_ASSET_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteImageAddAccessoryHistory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const deleteProfileImage = (data) => async (dispatch) => {
  dispatch(deleteProfileUserImage());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_PROFILE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteProfileImage(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const deleteCertificate = (data) => async (dispatch) => {
  dispatch(deleteCertificateRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_WARRANTY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteCertificate(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteCertificateSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteCertificateFailure(error));
  }
};

export const deleteUser = (data) => async (dispatch) => {
  dispatch(deleteUserRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_DELETE_SUBUSER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteUser(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteUserSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteUserFailure(error));
  }
};

export const clearData = () => (dispatch) => {
  dispatch(clearUserData([]));
};

export const contactUs = (data) => async (dispatch) => {
  dispatch(contactUsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CONTACT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(contactUs(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(contactUsSuccess(resData));
    }
  } catch (error) {
    dispatch(contactUsFailure(error));
  }
};

export const contactHome = (data) => (dispatch) => {
  dispatch(contactHomeRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_HOME_CONTACT,

    data,
  })
    .then((res) => {
      dispatch(contactHomeSuccess(res.data));
    })
    .catch((error) => {
      dispatch(contactUsFailure(error));
    });
};
// ...................Profile...........................................
export const profile = (data) => async (dispatch) => {
  dispatch(profileRequest());
  let token = localStorage.getItem("userToken"); // Ensure latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_PROFILE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(profile(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(profileSuccess(resData));
    }
  } catch (error) {
    dispatch(profileFailure(error));
  }
};

export const postRegister = (data) => async (dispatch) => {
  dispatch(postRegisterRequest());
  let token = localStorage.getItem("userToken"); // Ensure latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPDATE_PROFILE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postRegister(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postRegisterSuccess(resData));
    }
  } catch (error) {
    dispatch(postRegisterFailure(error));
  }
};

// ...................usermanual...........................................
export const userManual = (data) => async (dispatch) => {
  dispatch(userManualRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_ASSETS_MANUAL,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(userManual(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(userManualSuccess(resData));
    }
  } catch (error) {
    dispatch(userManualFailure(error));
  }
};

// ---------------------------------clear incident image-------------------------

export const clearIncidentImageUpload = (data) => async (dispatch) => {
  dispatch(clearIncidentImageRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_UPLOAD_CLEAR_INCIDENT_IMAGES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearIncidentImageUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearIncidentImageSuccess(resData));
    }
  } catch (error) {
    dispatch(clearIncidentImageFailure(error));
  }
};

// ......................................clear incident.............................

export const clearIncident = (data) => async (dispatch) => {
  dispatch(clearIncidentRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(clearIncidentFailure(error));
  }
};

export const clearIncidentImageRemove = (data) => async (dispatch) => {
  dispatch(clearIncidentImageRemoveRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_INCIDENT_IMAGE_REMOVE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearIncidentImageRemove(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearIncidentImageRemoveSuccess(resData));
    }
  } catch (error) {
    dispatch(clearIncidentImageRemoveFailure(error));
  }
};

export const clearServiceExpenditureImageRemove =
  (data) => async (dispatch) => {
    dispatch(clearServiceExpenditureImageRemoveRequest());
    let token = localStorage.getItem("userToken"); // Ensure the latest token is used

    try {
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_SERVICE_EXPENDITURE_DELETE,
        headers: { Authorization: `Bearer ${token}` },
        data,
      });

      const resData = response.data;

      if (resData === "INVALID_TOKEN") {
        const newToken = await dispatch(getInvalidToken(resData));
        if (newToken) return dispatch(clearServiceExpenditureImageRemove(data)); // Retry with new token
      } else if (resData === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(resData));
      } else {
        dispatch(clearServiceExpenditureImageRemoveSuccess(resData));
      }
    } catch (error) {
      dispatch(clearServiceExpenditureImageRemoveFailure(error));
    }
  };

export const clearIncidentExpenditureImageRemove =
  (data) => async (dispatch) => {
    dispatch(clearIncidentExpenditureImageRemoveRequest());
    let token = localStorage.getItem("userToken"); // Get the latest token

    try {
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_INCIDENT_EXPENDITURE_DELETE,
        headers: { Authorization: `Bearer ${token}` },
        data,
      });

      const resData = response.data;

      if (resData === "INVALID_TOKEN") {
        const newToken = await dispatch(getInvalidToken(resData));
        if (newToken)
          return dispatch(clearIncidentExpenditureImageRemove(data)); // Retry with new token
      } else if (resData === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(resData));
      } else {
        dispatch(clearIncidentExpenditureImageRemoveSuccess(resData));
      }
    } catch (error) {
      dispatch(clearIncidentExpenditureImageRemoveFailure(error));
    }
  };

export const serviceRequestImageRemove = (data) => async (dispatch) => {
  dispatch(serviceRequestImageRemoveRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CLEAR_SERVICES_IMAGE_REMOVE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceRequestImageRemove(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceRequestImageRemoveSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceRequestImageRemoveFailure(error));
  }
};

export const assetDetailsData = (data) => async (dispatch) => {
  dispatch(assetDetailsRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_ASSET_DETAILS_DATA,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(assetDetailsData(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetDetailsSuccess(resData));
    }
  } catch (error) {
    dispatch(assetDetailsFailure(error));
  }
};

export const recordIncidentImageUpload = (data) => async (dispatch) => {
  dispatch(recordIncidentImageUploadRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_RECORD_INCIDENT_IMAGE_UPLOAD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(recordIncidentImageUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(recordIncidentImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(recordIncidentImageUploadFailure(error));
  }
};

// console.log("Response data are", process.env.REACT_APP_INCIDENT_DELETE)

export const IncidentDelete = (data) => async (dispatch) => {
  dispatch(IncidentDeleteRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_INCIDENT_DELETE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(IncidentDelete(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(IncidentDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(IncidentDeleteFailure(error));
  }
};

export const recordIncidentImageDelete = (data) => async (dispatch) => {
  dispatch(recordIncidentImageDeleteRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_INCIDENTS_DELETE_RECORD_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(recordIncidentImageDelete(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(recordIncidentImageDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(recordIncidentImageDeleteFailure(error));
  }
};

export const createIncident = (data) => async (dispatch) => {
  dispatch(createIncidentRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CREATE_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(createIncidentFailure(error));
  }
};

export const getIncidentHistory = (data) => async (dispatch) => {
  dispatch(getIncidentHistoryRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_INCIDENT_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getIncidentHistory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getIncidentHistorySuccess(resData));
    }
  } catch (error) {
    dispatch(getIncidentHistoryFailure(error));
  }
};

export const getCalibrated = (data) => async (dispatch) => {
  dispatch(getCalibratedRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_CALIBRATED_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getCalibrated(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getCalibratedSuccess(resData));
    }
  } catch (error) {
    dispatch(getCalibratedFailure(error));
  }
};

export const getNotCalibrated = (data) => async (dispatch) => {
  dispatch(getNotCalibratedRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_NOTCALIBRATED_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getNotCalibrated(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getNotCalibratedSuccess(resData));
    }
  } catch (error) {
    dispatch(getNotCalibratedFailure(error));
  }
};
export const getNotRequired = (data) => async (dispatch) => {
  dispatch(getNotRequiredRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_NOTREQUIRED_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getNotRequired(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getNotRequiredSuccess(resData));
    }
  } catch (error) {
    dispatch(getNotRequiredFailure(error));
  }
};

export const departmentAssetDetails = (data) => async (dispatch) => {
  dispatch(departmentAssetDetailsRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_ASSETS_BY_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(departmentAssetDetails(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(departmentAssetDetailsSuccess(resData));
    }
  } catch (error) {
    dispatch(departmentAssetDetailsFailure(error));
  }
};

export const sellOnlineAsset = (data) => async (dispatch) => {
  dispatch(sellOnlineAssetRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_BM_CELL_PRODUCT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(sellOnlineAsset(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(sellOnlineAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(sellOnlineAssetFailure(error));
  }
};

export const departmentUserDetails = (data) => async (dispatch) => {
  dispatch(departmentUserDetailsRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_USER_BY_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(departmentUserDetails(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(departmentUserDetailsSuccess(resData));
    }
  } catch (error) {
    dispatch(departmentUserDetailsFailure(error));
  }
};

export const userRetrieveByBranch = (data) => async (dispatch) => {
  dispatch(userRetrieveByBranchRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_BM_USER_RETRIEVE_BY_BRANCH,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(userRetrieveByBranch(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(userRetrieveByBranchSuccess(resData));
    }
  } catch (error) {
    dispatch(userRetrieveByBranchFailure(error));
  }
};

//.............................Report...............................

export const serviceReport = (data, report) => async (dispatch) => {
  dispatch(serviceReportRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_GET_REPORT_DATA}${report}1`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceReport(data, report)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceReportSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceReportFailure(error));
  }
};

export const requestList = (data, request) => async (dispatch) => {
  dispatch(requestListRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_REQUEST_LIST}${request}1`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(requestList(data, request)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(requestListSuccess(resData));
    }
  } catch (error) {
    dispatch(requestListFailure(error));
  }
};

export const getPmAsset = (data) => async (dispatch) => {
  dispatch(getPmAssetRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_PM_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getPmAsset(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getPmAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(getPmAssetFailure(error));
  }
};

// console.log("responce data",process.env.REACT_APP_CREATE_PM_CHECKLIST)
export const postPmCheckList = (data) => async (dispatch) => {
  dispatch(postPmCheckListRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_CREATE_PM_CHECKLIST,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postPmCheckList(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postPmCheckListSuccess(resData));
    }
  } catch (error) {
    dispatch(postPmCheckListFailure(error));
  }
};

export const postPmCheckListPdf = (data) => async (dispatch) => {
  dispatch(getPmChecklistPdfRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_PM_REPORT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postPmCheckListPdf(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getPmChecklistPdfSuccess(resData));
    }
  } catch (error) {
    dispatch(getPmChecklistPdfFailure(error));
  }
};

// ...................open Incident..........................................
export const openIncident = (data) => async (dispatch) => {
  dispatch(openIncidentRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_OPEN_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(openIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(openIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(openIncidentFailure(error));
  }
};

// ...................Closed Incident..........................................

export const closedIncident = (data) => async (dispatch) => {
  dispatch(closedIncidentRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_CLOSE_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(closedIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(closedIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(closedIncidentFailure(error));
  }
};

// ...................warranty status..........................................

export const warrantyStatus = (data) => async (dispatch) => {
  dispatch(warrantyStatusRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_GET_WARRANTY_STATUS,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(warrantyStatus(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(warrantyStatusSuccess(resData));
    }
  } catch (error) {
    dispatch(warrantyStatusFailure(error));
  }
};

// ...................Request Manual..........................................

export const requestWarranty = (data) => async (dispatch) => {
  dispatch(requestWarrantyRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_REQUEST_WARRANTY,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(requestWarranty(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(requestWarrantySuccess(resData));
    }
  } catch (error) {
    dispatch(requestWarrantyFailure(error));
  }
};

// ...................Profile Edit..........................................

export const profileEdit = (data) => async (dispatch) => {
  dispatch(profileEditRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_UPDATE_USER_HOSPITAL_DATA,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(profileEdit(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(profileEditSuccess(resData));
    }
  } catch (error) {
    dispatch(profileEditFailure(error));
  }
};

// export const getDashboard = (data) => (dispatch) => {
//   dispatch(getDashboardRequest());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_GET_DASHBOARD,
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "INVALID_TOKEN" || res.data !== "AUTHENTICATION_ERROR") {
//         if (res.data?.user_access) {
//           const convertArraysToStrings = (obj) => {
//             const newObj = { ...obj };

//             if (
//               Array.isArray(newObj.departments) &&
//               newObj.departments.length === 1 &&
//               newObj.departments[0] !== "All"
//             ) {
//               newObj.departments = newObj.departments;
//             } else if (
//               Array.isArray(newObj.departments) &&
//               newObj.departments.length === 1
//             ) {
//               newObj.departments = newObj.departments[0];
//             }

//             if (
//               Array.isArray(newObj.departmentids) &&
//               newObj.departmentids.length === 1 &&
//               newObj.departmentids[0] !== "all"
//             ) {
//               newObj.departmentids = newObj.departmentids;
//             } else if (
//               Array.isArray(newObj.departmentids) &&
//               newObj.departmentids.length === 1
//             ) {
//               newObj.departmentids = newObj.departmentids[0];
//             }

//             for (const key in newObj) {
//               if (Array.isArray(newObj[key]) && newObj[key].length === 1) {
//                 if (newObj[key][0] === "All" || newObj[key][0] === "all") {
//                   newObj[key] = newObj[key][0];
//                 } else {
//                   newObj[key] = newObj[key];
//                 }
//               }
//             }

//             return newObj;
//           };

//           const convertedData = convertArraysToStrings(res.data?.user_access);
//           localStorage.setItem("user_access", JSON.stringify(convertedData));
//           sessionStorage.setItem("user_access", JSON.stringify(convertedData));
//         }
//         dispatch(getDashboardSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(getDashboardFailure(error));
//     });
// };

export const getDashboard = (data) => async (dispatch) => {
  dispatch(getDashboardRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_DASHBOARD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(getDashboard(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      if (res.data?.user_access) {
        const convertArraysToStrings = (obj) => {
          const newObj = { ...obj };

          if (
            Array.isArray(newObj.departments) &&
            newObj.departments.length === 1 &&
            newObj.departments[0] !== "All"
          ) {
            newObj.departments = newObj.departments;
          } else if (
            Array.isArray(newObj.departments) &&
            newObj.departments.length === 1
          ) {
            newObj.departments = newObj.departments[0];
          }

          if (
            Array.isArray(newObj.departmentids) &&
            newObj.departmentids.length === 1 &&
            newObj.departmentids[0] !== "all"
          ) {
            newObj.departmentids = newObj.departmentids;
          } else if (
            Array.isArray(newObj.departmentids) &&
            newObj.departmentids.length === 1
          ) {
            newObj.departmentids = newObj.departmentids[0];
          }

          for (const key in newObj) {
            if (Array.isArray(newObj[key]) && newObj[key].length === 1) {
              if (newObj[key][0] === "All" || newObj[key][0] === "all") {
                newObj[key] = newObj[key][0];
              } else {
                newObj[key] = newObj[key];
              }
            }
          }

          return newObj;
        };

        const convertedData = convertArraysToStrings(res.data?.user_access);
        localStorage.setItem("user_access", JSON.stringify(convertedData));
        sessionStorage.setItem("user_access", JSON.stringify(convertedData));
      }
      dispatch(getDashboardSuccess(res.data));
    }
  } catch (error) {
    dispatch(getDashboardFailure(error));
  }
};

export const csvFileUpload = (data) => async (dispatch) => {
  dispatch(csvFileUploadRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_CSV_FILE_UPLOAD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(csvFileUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(csvFileUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(csvFileUploadFailure(error));
  }
};

export const deleteCsvFileUpload = (data) => async (dispatch) => {
  dispatch(deleteCsvFileUploadRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_CSV_FILE_UPLOAD_DELETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteCsvFileUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteCsvFileUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteCsvFileUploadFailure(error));
  }
};

export const csvFileUploadSubmit = (data) => async (dispatch) => {
  dispatch(csvFileUploadSubmitRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_CSV_FILE_UPLOAD_SUBMIT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(csvFileUploadSubmit(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(csvFileUploadSubmitSuccess(resData));
    }
  } catch (error) {
    dispatch(csvFileUploadSubmitFailure(error));
  }
};

export const AmcCmc = (data) => async (dispatch) => {
  dispatch(AmcCmcRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(process.env.REACT_APP_BM_AMC_CMC, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AmcCmc(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcCmcSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcCmcFailure(error));
  }
};

export const createTask = (data) => async (dispatch) => {
  dispatch(createTaskRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_CREATE_TASK,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createTask(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(createTaskSuccess(resData));
    }
  } catch (error) {
    dispatch(createTaskFailure(error));
  }
};

export const getTask = (data) => async (dispatch) => {
  dispatch(getTaskRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(process.env.REACT_APP_BM_GET_TASK, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getTask(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(getTaskSuccess(resData));
    }
  } catch (error) {
    dispatch(getTaskFailure(error));
  }
};

export const getVendor = (data) => async (dispatch) => {
  dispatch(getVendorRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_GET_VENDOR,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getVendor(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(getVendorSuccess(resData));
    }
  } catch (error) {
    dispatch(getVendorFailure(error));
  }
};

export const postVendor = (data) => async (dispatch) => {
  dispatch(postVendorRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_INSERT_VENDOR,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postVendor(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(postVendorSuccess(resData));
    }
  } catch (error) {
    dispatch(postVendorFailure(error));
  }
};

export const postVendorService = (data) => async (dispatch) => {
  dispatch(postVendorServiceRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_INSERT_SERVICE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postVendorService(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(postVendorServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(postVendorServiceFailure(error));
  }
};

export const getVendorService = (data) => async (dispatch) => {
  dispatch(getVendorServiceRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_GET_SERVICE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getVendorService(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(getVendorServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(getVendorServiceFailure(error));
  }
};

export const Amc = (data) => async (dispatch) => {
  dispatch(AmcRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(process.env.REACT_APP_BM_AMC, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(Amc(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcFailure(error));
  }
};

export const Cmc = (data) => async (dispatch) => {
  dispatch(CmcRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(process.env.REACT_APP_BM_CMC, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(Cmc(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CmcSuccess(resData));
    }
  } catch (error) {
    dispatch(CmcFailure(error));
  }
};

export const AssetHistory = (data) => async (dispatch) => {
  dispatch(assetHistoryRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_ASSET_HISTORY,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AssetHistory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetHistorySuccess(resData));
    }
  } catch (error) {
    dispatch(assetHistoryFailure(error));
  }
};

export const AssetHistoryRetrieve = (data) => async (dispatch) => {
  dispatch(assetHistoryRetrieveRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_ASSET_HISTORY_RETRIEVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AssetHistoryRetrieve(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetHistoryRetrieveSuccess(resData));
    }
  } catch (error) {
    dispatch(assetHistoryRetrieveFailure(error));
  }
};

export const AssetHistoryDropdownRetrieve = (data) => async (dispatch) => {
  dispatch(assetHistoryDropdownRetrieveRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_ASSET_HISTORY_DROPDOWN_RETRIEVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AssetHistoryDropdownRetrieve(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetHistoryDropdownRetrieveSuccess(resData));
    }
  } catch (error) {
    dispatch(assetHistoryDropdownRetrieveFailure(error));
  }
};

export const checkOut = (data) => async (dispatch) => {
  dispatch(checkOutRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_CHECK_OUT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkOut(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkOutSuccess(resData));
    }
  } catch (error) {
    dispatch(checkOutFailure(error));
  }
};

export const checkIn = (data) => async (dispatch) => {
  dispatch(checkInRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(process.env.REACT_APP_BM_CHECK_IN, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkIn(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkInSuccess(resData));
    }
  } catch (error) {
    dispatch(checkInFailure(error));
  }
};

export const checkInOut = (data) => async (dispatch) => {
  dispatch(checkInOutRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_CHECK_IN_OUT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkInOut(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkInOutSuccess(resData));
    }
  } catch (error) {
    dispatch(checkInOutFailure(error));
  }
};

export const refreshData = () => async (dispatch) => {
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await fetch(process.env.REACT_APP_BM_CHECK_IN_OUT, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();

    if (data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(data));
      if (newToken) return dispatch(refreshData()); // Retry with new token
    } else if (data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(data));
    } else {
      dispatch({ type: "REFRESH_DATA_SUCCESS", data });
    }
  } catch (error) {
    console.error("Error refreshing data:", error);
  }
};

export const checkInOutDetail = (data) => async (dispatch) => {
  dispatch(checkInOutDetailRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_CHECK_IN_OUT_DETAIL,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkInOutDetail(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkInOutDetailSuccess(resData));
    }
  } catch (error) {
    dispatch(checkInOutDetailFailure(error));
  }
};

export const calender = (data) => async (dispatch) => {
  dispatch(CalenderRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM1_CALENDER,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(calender(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CalenderSuccess(resData));
    }
  } catch (error) {
    dispatch(CalenderFailure(error));
  }
};

export const checkinPdf = (data) => async (dispatch) => {
  dispatch(CheckInPdfRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_CHECKIN_PFD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkinPdf(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CheckInPdfSuccess(resData));
    }
  } catch (error) {
    dispatch(CheckInPdfFailure(error));
  }
};

export const AmcCmcReq = (data) => async (dispatch) => {
  dispatch(AmcCmcReqRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_AMC_CMC_REQ,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AmcCmcReq(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcCmcReqSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcCmcReqFailure(error));
  }
};

export const CalibrationReq = (data) => async (dispatch) => {
  dispatch(CalibrationReqRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_CALIBRATION_REQ,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(CalibrationReq(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CalibrationReqSuccess(resData));
    }
  } catch (error) {
    dispatch(CalibrationReqFailure(error));
  }
};

export const AddAssetReport = (data) => async (dispatch) => {
  dispatch(AddAssetReportRequest());

  try {
    const response = await axios.post(process.env.REACT_APP_ADD_REPORT, data);

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AddAssetReport(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      // Uncomment this line if needed
      // dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AddAssetReportSuccess(resData));
    }
  } catch (error) {
    dispatch(AddAssetReportFailure(error));
  }
};

export const assetDeleteReport = (data) => async (dispatch) => {
  dispatch(AssetDeleteReportRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_REMOVE_REPORT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(assetDeleteReport(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AssetDeleteReportSuccess(resData));
    }
  } catch (error) {
    dispatch(AssetDeleteReportFailure(error));
  }
};

export const WarrantyRequest = (data) => async (dispatch) => {
  dispatch(WarrantyRequestRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_BM_REQUEST_WARRANTY,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(WarrantyRequest(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(WarrantyRequestSuccess(resData));
    }
  } catch (error) {
    dispatch(WarrantyRequestFailure(error));
  }
};

// export const WarrantyTaskCompleted = (data) => (dispatch) => {
//   dispatch(WarrantyTaskCompletedRequest());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_BM_WARRANTY_TASK_COMPLETE,
//     headers: { Authorization: `Bearer ${token}` },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
//         dispatch(WarrantyTaskCompletedSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(WarrantyTaskCompletedFailure(error));
//     });
// };

export const WarrantyTaskCompleted = (data) => async (dispatch) => {
  dispatch(WarrantyTaskCompletedRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_WARRANTY_TASK_COMPLETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(WarrantyTaskCompleted(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(WarrantyTaskCompletedSuccess(resData));
    }

    return resData; // Return response data
  } catch (error) {
    dispatch(WarrantyTaskCompletedFailure(error));
    throw error;
  }
};

export const AmcCmcTaskCompleted = (data) => async (dispatch) => {
  dispatch(AmcCmcTaskCompletedRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_AMC_CMC_TASK_COMPLETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AmcCmcTaskCompleted(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcCmcTaskCompletedSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcCmcTaskCompletedFailure(error));
  }
};

export const CalibrationTaskCompleted = (data) => async (dispatch) => {
  dispatch(CalibrationTaskCompletedRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_CALIBRATION_TASK_COMPLETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(CalibrationTaskCompleted(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CalibrationTaskCompletedSuccess(resData));
    }
  } catch (error) {
    dispatch(CalibrationTaskCompletedFailure(error));
  }
};

export const updateReport = (data) => async (dispatch) => {
  dispatch(updateReportRequest());

  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const res = await axios.post(process.env.REACT_APP_BM_UPDATE_REPORT, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(updateReport(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(updateReportSuccess(resData));
    }
  } catch (error) {
    dispatch(updateReportFailure(error));
  }
};

export const createSubUser = (data) => async (dispatch) => {
  dispatch(createSubUserRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_CREATE_SUBUSER,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createSubUser(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createSubUserSuccess(resData));
    }
  } catch (error) {
    dispatch(createSubUserFailure(error));
  }
};

export const bulkAssetDelete = (data) => async (dispatch) => {
  dispatch(bulkAssetDeleteRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_BULK_ASSET_DELETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(bulkAssetDelete(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(bulkAssetDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(bulkAssetDeleteFailure(error));
  }
};

export const bulkDepartmentUpdate = (data) => async (dispatch) => {
  dispatch(bulkDepartmentUpdateRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_BULK_DEPARTMENT_UPLOAD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(bulkDepartmentUpdate(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(bulkDepartmentUpdateSuccess(resData));
    }
  } catch (error) {
    dispatch(bulkDepartmentUpdateFailure(error));
  }
};

// export const retrieveAsset = (data) => (dispatch) => {
//   dispatch(retrieveAssetRequest());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_BM_RETRIEVE_ASSET,
//     headers: { Authorization: `Bearer ${token}` },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
//         dispatch(retrieveAssetSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(retrieveAssetFailure(error));
//     });
// };

export const retrieveAsset = (data) => async (dispatch) => {
  dispatch(retrieveAssetRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_RETRIEVE_ASSET,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken); // Store new token
        return dispatch(retrieveAsset(data)); // Retry with updated token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(retrieveAssetSuccess(res.data));
    }
  } catch (error) {
    dispatch(retrieveAssetFailure(error));
  }
};

export const retrieveServiceAssets = (data) => async (dispatch) => {
  dispatch(retrieveServiceAssetRequest());

  let token = localStorage.getItem("userToken"); // Fetch token inside function

  try {
    const res = await axios.post(process.env.REACT_APP_SERVICE_ASSETS, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(retrieveServiceAssets(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(retrieveServiceAssetSuccess(res.data));
    }
  } catch (error) {
    dispatch(retrieveServiceAssetFailure(error));
  }
};

export const userDetailCheck = (data) => async (dispatch) => {
  dispatch(userDetailCheckRequest());

  let token = localStorage.getItem("userToken"); // Fetch token inside function

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_USER_DETAIL_CHECK,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(userDetailCheck(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(userDetailCheckSuccess(res.data));
    }
  } catch (error) {
    dispatch(userDetailCheckFailure(error));
  }
};

export const createSuperAdmin = (data) => async (dispatch) => {
  dispatch(createSuperAdminRequest());

  let token = localStorage.getItem("userToken"); // Fetch token inside function

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_CREATE_SUPER_ADMIN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken); // Store new token
        return dispatch(createSuperAdmin(data)); // Retry with updated token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(createSuperAdminSuccess(res.data));
    }
  } catch (error) {
    dispatch(createSuperAdminFailure(error));
  }
};

// export const allUserList = (data) => (dispatch) => {
//   dispatch(allUserListRequest());
//   return axios({
//     method: "POST",
//     url: process.env.REACT_APP_BM_ALL_USER_LIST,
//     headers: { Authorization: `Bearer ${token}` },
//     data,
//   })
//     .then((res) => {
//       if (res.data === "INVALID_TOKEN") {
//         dispatch(getInvalidToken(res.data));
//       }
//       if (res.data === "AUTHENTICATION_ERROR") {
//         dispatch(getAuthenticationError(res.data));
//       }
//       if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
//         dispatch(allUserListSuccess(res.data));
//       }
//     })
//     .catch((error) => {
//       dispatch(allUserListFailure(error));
//     });
// };

export const allUserList = (data) => async (dispatch) => {
  dispatch(allUserListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_ALL_USER_LIST, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(allUserList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(allUserListSuccess(res.data));
    }
  } catch (error) {
    dispatch(allUserListFailure(error));
  }
};

// Create Subuser to Super Admin
export const createSubuserToSuperAdmin = (data) => async (dispatch) => {
  dispatch(createSubuserToSuperAdminRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SUBUSER_TO_SUPER_ADMIN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(createSubuserToSuperAdmin(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(createSubuserToSuperAdminSuccess(res.data));
    }
  } catch (error) {
    dispatch(createSubuserToSuperAdminFailure(error));
  }
};

// Update Subuser to Super Admin
export const updateSubuserToSuperAdmin = (data) => async (dispatch) => {
  dispatch(updateSubuserToSuperAdminRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_UPDATE_SUBUSER_TO_SUPER_ADMIN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(updateSubuserToSuperAdmin(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(updateSubuserToSuperAdminSuccess(res.data));
    }
  } catch (error) {
    dispatch(updateSubuserToSuperAdminFailure(error));
  }
};

export const postNotificationToken = (data) => async (dispatch) => {
  dispatch(postNotificationTokenRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_UPDATE_SUBUSER_TO_SUPER_ADMIN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(postNotificationToken(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(postNotificationTokenSuccess(res.data));
    }
  } catch (error) {
    dispatch(postNotificationTokenFailure(error));
  }
};

export const getDashboardList = (data) => async (dispatch) => {
  dispatch(getDashboardListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_GET_DASHBOARD_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(getDashboardList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getDashboardListSuccess(res.data));
    }
  } catch (error) {
    dispatch(getDashboardListFailure(error));
  }
};

export const getDashboardList2 = (data) => async (dispatch) => {
  dispatch(getDashboardList2Request());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_GET_DASHBOARD_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(getDashboardList2(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getDashboardList2Success(res.data));
    }
  } catch (error) {
    dispatch(getDashboardList2Failure(error));
  }
};

export const getMonthlyReport = (data) => async (dispatch) => {
  dispatch(getMonthlyReportRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_MONTHLY_REPORT_STATUS,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(getMonthlyReport(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getMonthlyReportSuccess(res.data));
    }
  } catch (error) {
    dispatch(getMonthlyReportFailure(error));
  }
};

// ..................ASSETS NOT WORKING............................................

export const assetsnotworking = (data) => async (dispatch) => {
  dispatch(assetsnotworkingRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ASSET_NOT_WORKING,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetsnotworking(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(assetsnotworkingSuccess(res.data));
    }
  } catch (error) {
    dispatch(assetsnotworkingFailure(error));
  }
};

// ..................ASSETS Discarded............................................

export const assetsdiscarded = (data) => async (dispatch) => {
  dispatch(discardedRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ASSET_Discarded,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetsdiscarded(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(discardedSuccess(res.data));
    }
  } catch (error) {
    dispatch(discardedFailure(error));
  }
};

// console.log("response data is",process.env.REACT_APP_CHATBOT)

export const chatbot = (data) => async (dispatch) => {
  dispatch(chatbotRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_CHATBOT, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(chatbot(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(chatbotSuccess(res.data));
    }
  } catch (error) {
    console.error("API Error:", error);
    dispatch(chatbotFailure(error));
  }
};

// console.log("respone data",process.env.REACT_APP_BM_Asset_DELETE)

// ..................ASSETS Delete............................................

export const assetsDelete = (data) => async (dispatch) => {
  dispatch(AssetDeleteRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_Asset_DELETE, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetsDelete(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AssetDeleteSuccess(res.data));
    }
  } catch (error) {
    dispatch(AssetDeleteFailure(error));
  }
};

// ..................Deleted  assets Details............................................

export const deleteAssetData = (data) => async (dispatch) => {
  dispatch(deleteDataAssetRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_DELETED_ASSET, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(deleteAssetData(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(deleteDataAssetSuccess(res.data));
    }
  } catch (error) {
    dispatch(deleteDataAssetFailure(error));
  }
};

// ..................Deleted  Incident............................................

export const deletedIncidentData = (data) => async (dispatch) => {
  dispatch(deletedIncidentRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_INCIDENT_DELETED_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(deletedIncidentData(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(deletedIncidentSuccess(res.data));
    }
  } catch (error) {
    dispatch(deletedIncidentFailure(error));
  }
};

// ..................Department delete list............................................

export const departmentDeleteList = (data) => async (dispatch) => {
  dispatch(departmentDeleteListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_DEPEARTMENT_DELETED_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(departmentDeleteList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(departmentDeleteListSuccess(res.data));
    }
  } catch (error) {
    dispatch(departmentDeleteListFailure(error));
  }
};

// ..................Notification  list............................................

export const notificationList = (data) => async (dispatch) => {
  dispatch(notificationListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_NOTIFICATION, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(notificationList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(notificationListSuccess(res.data));
    }
  } catch (error) {
    dispatch(notificationListFailure(error));
  }
};

// ..................Notification All list............................................

export const notificationAllList = (data) => async (dispatch) => {
  dispatch(notificationAllListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_NOTIFICATION_ALL,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(notificationAllList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(notificationAllListSuccess(res.data));
    }
  } catch (error) {
    dispatch(notificationAllListFailure(error));
  }
};

// ..................Approve Movement............................................

export const movementApproveData = (data) => async (dispatch) => {
  dispatch(movementApproveRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_ASSET_MOVEMENT_APPROVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(movementApproveData(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(movementApproveSuccess(res.data));
    }
  } catch (error) {
    dispatch(movementApproveFailure(error));
  }
};

// ..................Delete Movement............................................

export const movementDeleteData = (data) => async (dispatch) => {
  dispatch(movementDeleteRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_ASSET_MOVEMENT_DELETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(movementDeleteData(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(movementDeleteSuccess(res.data));
    }
  } catch (error) {
    dispatch(movementDeleteFailure(error));
  }
};

// ..................Sell Online Pending List............................................

export const sellOnlinePending = (data) => async (dispatch) => {
  dispatch(sellOnlinePendingRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SELL_ONLINE_PENDING_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(sellOnlinePending(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(sellOnlinePendingSuccess(res.data));
    }
  } catch (error) {
    dispatch(sellOnlinePendingFailure(error));
  }
};

// ..................Sell Online Approved List............................................

export const sellOnlineApproved = (data) => async (dispatch) => {
  dispatch(sellOnlineApprovedRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SELL_ONLINE_APPROVED_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(sellOnlineApproved(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(sellOnlineApprovedSuccess(res.data));
    }
  } catch (error) {
    dispatch(sellOnlineApprovedFailure(error));
  }
};

// ..................Sell Online Approve............................................

export const sellOnlineApprove = (data) => async (dispatch) => {
  dispatch(sellOnlineApproveRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SELL_ONLINE_APPROVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(sellOnlineApprove(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(sellOnlineApproveSuccess(res.data));
    }
  } catch (error) {
    dispatch(sellOnlineApproveFailure(error));
  }
};

// ..................Discard Request............................................

export const discardrequest = (data) => async (dispatch) => {
  dispatch(discardRequestedRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_DISCARD_REQUEST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(discardrequest(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(discardRequestedSuccess(res.data));
    }
  } catch (error) {
    dispatch(discardRequestedFailure(error));
  }
};

// ..................Discard Pending List............................................

export const discardpending = (data) => async (dispatch) => {
  dispatch(discardPendingRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_PENDING_DISCARD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(discardPending(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(discardPendingSuccess(res.data));
    }
  } catch (error) {
    dispatch(discardPendingFailure(error));
  }
};

// console.log(process.env.REACT_APP_BM_APPROVE_DISCARD);
// console.log(process.env.REACT_APP_BM_APPROVED_DISCARD);

// ..................Discard Approve............................................

export const discardapprove = (data) => async (dispatch) => {
  dispatch(discardApproveRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_APPROVE_DISCARD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(discardapprove(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(discardApproveSuccess(res.data));
    }
  } catch (error) {
    dispatch(discardApproveFailure(error));
  }
};

// ..................Discard Approved List............................................

export const discardApproved = (data) => async (dispatch) => {
  dispatch(discardApprovedRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_APPROVED_DISCARD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(discardApproved(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(discardApprovedSuccess(res.data));
    }
  } catch (error) {
    dispatch(discardApprovedFailure(error));
  }
};

// ..................Delete AMC/CMC Certificate ............................................

export const deleteAMCCMCCertificate = (data) => async (dispatch) => {
  dispatch(deleteAMCCMCImageRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_ADD_ASSET_AMC_CMC_DELETE_IMAGE_UPLOAD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(deleteAMCCMCCertificate(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(deleteAMCCMCImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(deleteAMCCMCImageFailure(error));
  }
};

// AddAsset Upload AMC/CMC Images
export const uploadedAMCCMCCertificate = (data) => async (dispatch) => {
  dispatch(uploadCertificateRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_ADD_ASSET_AMC_CMC_IMAGE_UPLOAD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(uploadedAMCCMCCertificate(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(uploadCertificateSuccess(res.data));
    }
  } catch (error) {
    dispatch(uploadCertificateFailure(error));
  }
};

// ..................departmentall............................................

export const departmentall = (data) => async (dispatch) => {
  dispatch(DepartmentAllRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_DEPARTMENT_ALL,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(departmentAll(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(DepartmentAllSuccess(res.data));
    }
  } catch (error) {
    dispatch(DepartmentAllFailure(error));
  }
};

// ..................Incident Check............................................

export const incidentCheck = (data) => async (dispatch) => {
  dispatch(IncidentCheckRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_INICDENT_CHECK,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(incidentCheck(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(IncidentCheckSuccess(res.data));
    }
  } catch (error) {
    dispatch(IncidentCheckFailure(error));
  }
};

// ..................Incident Acknowledge............................................

export const incidentAcknowledge = (data) => async (dispatch) => {
  dispatch(IncidentAcknowledgeRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_INICDENT_ACKNOWLEDGE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(incidentAcknowledge(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(IncidentAcknowledgeSuccess(res.data));
    }
  } catch (error) {
    dispatch(IncidentAcknowledgeFailure(error));
  }
};

// ..................Service Work Order............................................

export const serviceWorkOrder = (data) => async (dispatch) => {
  dispatch(ServiceWorkOrderRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SERVICE_WORK_ORDER,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(serviceWorkOrder(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(ServiceWorkOrderSuccess(res.data));
    }
  } catch (error) {
    dispatch(ServiceWorkOrderFailure(error));
  }
};

// ..................Add Service Work Order............................................

export const addServiceWorkOrder = (data) => async (dispatch) => {
  dispatch(AddServiceWorkOrderRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ADD_SERVICE_WORK_ORDER,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(addServiceWorkOrder(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AddServiceWorkOrderSuccess(res.data));
    }
  } catch (error) {
    dispatch(AddServiceWorkOrderFailure(error));
  }
};

// ..................Add Equipment Audit............................................

export const equipmentAudit = (data) => async (dispatch) => {
  dispatch(EquipmentAuditRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ADD_EQUIPMENT_AUDIT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(equipmentAudit(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(EquipmentAuditSuccess(res.data));
    }
  } catch (error) {
    dispatch(EquipmentAuditFailure(error));
  }
};

// .................. Equipment Audit List............................................

export const equipmentAuditList = (data) => async (dispatch) => {
  dispatch(EquipmentAuditListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_EQUIPMENT_AUDIT_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(equipmentAuditList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(EquipmentAuditListSuccess(res.data));
    }
  } catch (error) {
    dispatch(EquipmentAuditListFailure(error));
  }
};

// .................. Equipment Audit Approve............................................

export const equipmentAuditApprove = (data) => async (dispatch) => {
  dispatch(EquipmentAuditApproveRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_EQUIPMENT_AUDIT_APPROVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(equipmentAuditApprove(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(EquipmentAuditApproveSuccess(res.data));
    }
  } catch (error) {
    dispatch(EquipmentAuditApproveFailure(error));
  }
};

// ..................Create Equipment Audit............................................

export const createEquipmentAudit = (data) => async (dispatch) => {
  dispatch(CreateEquipmentAuditRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_Create_EQUIPMENT_AUDIT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(createEquipmentAudit(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(CreateEquipmentAuditSuccess(res.data));
    }

    return res.data; // Return response data
  } catch (error) {
    dispatch(CreateEquipmentAuditFailure(error));
    throw error; // Propagate error for handling in calling function
  }
};

// ..................Department Audit List............................................

export const departmentAuditList = (data) => async (dispatch) => {
  dispatch(DepartmentAuditListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_DEPARTMENT_AUDIT_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(departmentAuditList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(DepartmentAuditListSuccess(res.data));
    }
  } catch (error) {
    dispatch(DepartmentAuditListFailure(error));
  }
};

// .................. Equipment Audit Approve............................................

export const assetAuditLists = (data) => async (dispatch) => {
  dispatch(AssetAuditListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ASSET_AUDIT_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetAuditLists(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AssetAuditListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AssetAuditListFailure(error));
  }
};

// .................. Equipment Audit Ignore............................................

export const assetAuditIgnore = (data) => async (dispatch) => {
  dispatch(AssetAuditIgnoreRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_EQUIPMENT_AUDIT_IGNORE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetAuditIgnore(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AssetAuditIgnoreSuccess(res.data));
    }
  } catch (error) {
    dispatch(AssetAuditIgnoreFailure(error));
  }
};

// .................. Equipment Audit Approve List............................................

export const assetAuditApproveList = (data) => async (dispatch) => {
  dispatch(AssetAuditApproveListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_EQUIPMENT_AUDIT_APPROVE_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetAuditApproveList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AssetAuditApproveListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AssetAuditApproveListFailure(error));
  }
};

// .................. Equipment Audit Ignore List............................................

export const assetAuditIgnoreList = (data) => async (dispatch) => {
  dispatch(AssetAuditIgnoreListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_EQUIPMENT_AUDIT_IGNORE_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetAuditIgnoreList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AssetAuditIgnoreListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AssetAuditIgnoreListFailure(error));
  }
};

// ..................  Audit Request Approve............................................

export const auditRequestApprove = (data) => async (dispatch) => {
  dispatch(AuditRequestApproveRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_AUDIT_REQUEST_APPROVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(auditRequestApprove(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AuditRequestApproveSuccess(res.data));
    }
  } catch (error) {
    dispatch(AuditRequestApproveFailure(error));
  }
};

// ..................  Audit Completed............................................

export const auditCompleted = (data) => async (dispatch) => {
  dispatch(AuditCompletedRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_AUDIT_COMPLETED,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(auditCompleted(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AuditCompletedSuccess(res.data));
    }
  } catch (error) {
    dispatch(AuditCompletedFailure(error));
  }
};

// ..................  Audit Details............................................

export const auditDetails = (data) => async (dispatch) => {
  dispatch(AuditDetailsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_AUDIT_DETAILS, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(auditDetails(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AuditDetailsSuccess(res.data));
    }
  } catch (error) {
    dispatch(AuditDetailsFailure(error));
  }
};

// ..................  Grade Update............................................

export const gradeUpdate = (data) => async (dispatch) => {
  dispatch(GradeUpdateRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_UPDATE_GRADE, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(gradeUpdate(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(GradeUpdateSuccess(res.data));
    }
  } catch (error) {
    dispatch(GradeUpdateFailure(error));
  }
};

// ..................  Accessory Update Image............................................

export const accessoryUploadImage = (data) => async (dispatch) => {
  dispatch(AccessoryUploadImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ACCESSORY_UPDATE_IMAGE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(accessoryUploadImage(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AccessoryUploadImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(AccessoryUploadImageFailure(error));
  }
};

// ..................  Accessory Delete Image............................................

export const accessoryDeleteImage = (data) => async (dispatch) => {
  dispatch(AccessoryDeleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ACCESSORY_DELETE_IMAGE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(accessoryDeleteImage(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AccessoryDeleteImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(AccessoryDeleteImageFailure(error));
  }
};

// .................. Create Accessory............................................

export const createAccessory = (data) => async (dispatch) => {
  dispatch(CreateAccessoryRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ACCESSORY_CREATE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(createAccessory(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(CreateAccessorySuccess(res.data));
    }
  } catch (error) {
    dispatch(CreateAccessoryFailure(error));
  }
};

// .................. Create Accessory Name List............................................

export const createAccessoryNameList = (data) => async (dispatch) => {
  dispatch(CreateAccessoryNameListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ACCESSORY_NAME_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(createAccessoryNameList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(CreateAccessoryNameListSuccess(res.data));
    }
  } catch (error) {
    dispatch(CreateAccessoryNameListFailure(error));
  }
};

// .................. Audit Upload Image............................................

export const auditUploadImage = (data) => async (dispatch) => {
  dispatch(AuditUploadImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_AUDIT_UPLOAD_IMAGE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(auditUploadImage(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AuditUploadImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(AuditUploadImageFailure(error));
  }
};
// ..................Audit Delete Image............................................

export const auditDeleteImage = (data) => async (dispatch) => {
  dispatch(AuditDeleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_AUDIT_UPLOAD_DELETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(auditDeleteImage(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AuditDeleteImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(AuditDeleteImageFailure(error));
  }
};

// ..................Accessory Available List ............................................

export const accessoryAvailableList = (data) => async (dispatch) => {
  dispatch(AccessoryAvailableListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ACCESSORY_AVAILABLE_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(accessoryAvailableList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AccessoryAvailableListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AccessoryAvailableListFailure(error));
  }
};

// ..................Incident Details............................................

export const retriveIncidentDetails = (data) => async (dispatch) => {
  dispatch(RetriveIncidentDetailsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_INCIDENT_DETAILS,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(retriveIncidentDetails(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(RetriveIncidentDetailsSuccess(res.data));
    }
  } catch (error) {
    dispatch(RetriveIncidentDetailsFailure(error));
  }
};

// ..................Edit Clear Incident............................................

export const edtClearIncident = (data) => async (dispatch) => {
  dispatch(EdtClearIncidentRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_EDIT_CLEAR_INCIDENT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(edtClearIncident(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(EdtClearIncidentSuccess(res.data));
    }
  } catch (error) {
    dispatch(EdtClearIncidentFailure(error));
  }
};

// ..................Accessory Update List............................................

export const accessoryUpdateList = (data) => async (dispatch) => {
  dispatch(AccessoryUpdateListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_UPDATE_ACCESSORRY_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(accessoryUpdateList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AccessoryUpdateListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AccessoryUpdateListFailure(error));
  }
};

// ..................Service Work Order ............................................

export const addWorkOrderList = (data) => async (dispatch) => {
  dispatch(AddWorkOrderListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ADD_WORK_ORDER,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(addWorkOrderList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AddWorkOrderListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AddWorkOrderListFailure(error));
  }
};

// ..................Accessory List ............................................

export const AccessoryList = (data) => async (dispatch) => {
  dispatch(AccessoryListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_ACCESSORY_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(AccessoryList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AccessoryListSuccess(res.data));
    }
  } catch (error) {
    dispatch(AccessoryListFailure(error));
  }
};

// ..................Accessory List ............................................

export const ApproveIncident = (data) => async (dispatch) => {
  dispatch(ApproveIncidentRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_APPROVE_INCIDENT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(ApproveIncident(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(ApproveIncidentSuccess(res.data));
    }
  } catch (error) {
    dispatch(ApproveIncidentFailure(error));
  }
};

// ..................Sell Online Add Image ............................................

export const SellOnlineAddImage = (data) => async (dispatch) => {
  dispatch(SellOnlineAddImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SELL_ADD_IMAGES,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(SellOnlineAddImage(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(SellOnlineAddImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(SellOnlineAddImageFailure(error));
  }
};

// ..................Sell Online Delete Image ............................................

export const SellOnlineDeleteImage = (data) => async (dispatch) => {
  dispatch(SellOnlineDeleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_SELL_DELETE_IMAGES,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(SellOnlineDeleteImage(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(SellOnlineDeleteImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(SellOnlineDeleteImageFailure(error));
  }
};

// ..................Department Asset List ............................................

export const DepartmentAssetList = (data) => async (dispatch) => {
  dispatch(DepartmentAssetListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_DEPARTMENT_ASSET_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(DepartmentAssetList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(DepartmentAssetListSuccess(res.data));
    }
  } catch (error) {
    dispatch(DepartmentAssetListFailure(error));
  }
};
