import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Divider, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RefreshIcon from "@mui/icons-material/Refresh";
import DoneIcon from "@mui/icons-material/Done";

import {
  Box,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  Modal,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { getPmAsset, postPmCheckList } from "../redux/data/action";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import jsPDF from "jspdf";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const ParaBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: "2px",
  marginBottom: 2,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "20%",
}));

const ItemText = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  textTransform: "uppercase",
  fontSize: "18px",
  width: "80%",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  color: "#4690FF",
  fontSize: "22px",
  marginTop: "10px",
  marginBottom: "10px",
}));

const PdfButton = styled(Button)(({ theme }) => ({
  borderRadius: "35px",
  color: "#F78117",
  border: "1px solid #FF731D",
  textTransform: "none",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "7px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));

const FirstSet = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",

  marginTop: "20px",
  gap: "20px",
  height: "80%",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const OuterSet = styled(Box)(({ theme }) => ({
  width: "700px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "98%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InputSet = styled(Box)(({ theme }) => ({
  width: "50%",
  flexDirection: "column",
  height: "100%",
  gap: "10px",
  display: "flex",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const departments = [
  { department: "Critical Care" },
  { department: "OT" },
  { department: "Laboratory" },
  { department: "Radiology" },
];

const equipments = [
  { equipment: "Electrocardiograph" },
  { equipment: "ECHO" },
  { equipment: "Anaesthetic Gas Scavenging System" },
  { equipment: "CPAP" },
  { equipment: "Surgical Table" },
  { equipment: "NBP Monitor" },
  { equipment: "Operating Lamp" },
  { equipment: "Laser Yag" },
  { equipment: "Sterilizer" },
  { equipment: "Microscope" },
  { equipment: "Dental Unit" },
  { equipment: "Ultrasonic Cleaner" },
  { equipment: "Blood Warmer" },
  { equipment: "Slit Lamp" },
  { equipment: "Drying Cabinet" },
  { equipment: "Infusion Pump" },
  { equipment: "Patient Monitor" },
  { equipment: "Hypo/Hyperthermia Blanket" },
  { equipment: "Electrosurgical" },
  { equipment: "Defibrilator" },
  { equipment: "Ventilator" },
  { equipment: "Transport Incubator" },
  { equipment: "Doppler" },
  { equipment: "Fetal Monitor/CTG" },
  { equipment: "Phototherapy Lamp" },
  { equipment: "Eagle Ten Sterilizer" },
  { equipment: "Baby Incubator" },
  { equipment: "USG" },
  { equipment: "ENT Unit" },
  { equipment: "Cauter" },
  { equipment: "Medical Air Equipment" },
  { equipment: "Tonometer" },
  { equipment: "Suction Regulator" },
  { equipment: "Ultrasound Therapy" },
  { equipment: "EEG" },
  { equipment: "Nebulizer" },
  { equipment: "Light Source" },
  { equipment: "EMG" },
  { equipment: "Bilirubinometer" },
  { equipment: "Blood Bank" },
  { equipment: "Centrifuge" },
  { equipment: "Unit Endoscopy" },
  { equipment: "Infant Warmer" },
  { equipment: "Short Wave Diathermy" },
  { equipment: "Microwave Diathermy" },
  { equipment: "Electric Stimulator" },
  { equipment: "Infra Red Lamp" },
  { equipment: "Electronic Laparofator" },
  { equipment: "Nerve Detector" },
  { equipment: "Suction Pump Unit" },
  { equipment: "Entonox" },
  { equipment: "IABP" },
  { equipment: "AudioMeter" },
  { equipment: "Resuscitator" },
  { equipment: "Water Bath" },
  { equipment: "Warming Cabinet" },
  { equipment: "Tourniquet System" },
  { equipment: "Heart Lung Machine" },
  { equipment: "syringe pump" },
];

const MyComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [equipmentDetails, setEquipmentDetails] = useState([]);
  const [isRemarkDisabled, setIsRemarkDisabled] = useState(false);

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [flag, setFlag] = useState(false);
  const token = localStorage.getItem("userToken");
  const pmChecklistData = useSelector(
    (store) => store.data.postPmCheckListData
  );
  const pdfUrl = useSelector((store) => store.data.pmChecklistPdf);
  const postPmCheckListDataC = useSelector(
    (store) => store.data.postPmCheckListData
  );
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const [department, setDepartment] = useState([]);
  const [iframeData, setIframeData] = useState({});
  const [singleDepartment, setSingleDepartment] = useState("");
  const [departmentId, setDepartmentId] = useState("");

  const [check, setCheck] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetName, setAssetName] = useState("");
  const [remark, setRemark] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [doneBy, setDoneBy] = useState("");
  const [isParameterError, setIsParameterError] = useState("");
  const [isDoneByError, setIsDoneByError] = useState("");
  const [isDateError, setIsDateError] = useState("");
  const [isSerialNumberError, setIsSerialNumberError] = useState("");
  const [isModelNumberError, setIsModelNumberError] = useState("");
  const [isRemarkError, setIsRemarkError] = useState("");
  const [isAssetNameError, setIsAssetNameError] = useState("");
  const [dropdown, setDropdown] = useState("");
  const [observedValues, setObservedValues] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [recording, setRecording] = useState(false);
  const [savedSignature, setSavedSignature] = useState(null);
  const [showSignatureCanvas, setShowSignatureCanvas] = useState(true);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const sigCanvas = useRef();
  const recordTimeoutRef = useRef(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const timerRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(300);

  useEffect(() => {
    // Function to update canvas width based on screen size
    const updateCanvasWidth = () => {
      if (window.innerWidth <= 768) {
        setCanvasWidth(290); // Mobile view width
      } else {
        setCanvasWidth(500); // Default width for larger screens
      }
    };

    updateCanvasWidth(); // Set initial width
    window.addEventListener("resize", updateCanvasWidth); // Update on resize

    return () => {
      window.removeEventListener("resize", updateCanvasWidth);
    };
  }, []);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    audioChunksRef.current = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      setAudioUrl(URL.createObjectURL(audioBlob));
    };

    mediaRecorderRef.current.start();
    setRecording(true);
    setRecordingTime(0);

    timerRef.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);

    // Stop recording after 5 seconds
    recordTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 10000);
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      clearTimeout(recordTimeoutRef.current);
      clearInterval(timerRef.current);
    }
  };

  const deleteRecording = () => {
    setAudioUrl(null);
  };

  const clearSignature = () => {
    setShowSignatureCanvas(true);
    setTimeout(() => {
      if (sigCanvas.current) {
        sigCanvas.current.clear();
        setSavedSignature(null);
      } else {
        console.warn("Signature canvas is still not available.");
      }
    });
  };

  const saveSignature = () => {
    setSavedSignature(sigCanvas.current.toDataURL());
    setShowSignatureCanvas(false);
  };

  // Function to handle input changes
  const handleObservedChange = (
    supplyParameter,
    selectedValue,
    observedValue
  ) => {
    setObservedValues((prev) => {
      const updatedValues = prev.filter(
        (item) => item.selectedValue !== selectedValue
      );
      return [
        ...updatedValues,
        { observedValue, selectedValue, supplyParameter },
      ];
    });
  };

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const dispatch = useDispatch();
  const [color, setColor] = useState("");
  // const [equipmentsList,setEquipmentsList ] = useState("")
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInputValue(""); // Clear input on close
  };

  const handleAddSubmit = () => {
    if (inputValue.trim() === "") return;

    const newParameter = {
      name: inputValue,
      status: "N/A", // Default status
    };

    setEquipmentDetails((prevDetails) => {
      const updatedParameters = [...prevDetails[0].parameter, newParameter];
      return [{ ...prevDetails[0], parameter: updatedParameters }];
    });

    setInputValue(""); // Clear input field
    handleClose(); // Close modal
  };

  const [assetId, setAssetId] = useState("");
  const [getAllData, setGetAllData] = useState([]);
  const assetData = useSelector((store) => store.data.getPmAssetData);
  const [submittedName, setSubmittedName] = useState("");
  const [submitDate, setSubmitDate] = useState("");

  const handleSelectedDepartment = (event, newValue) => {
    setSelectedDepartment(newValue);
    setDropdown("");
  };

  let data2 = [
    {
      department: "Critical Care",
      equipment: [
        {
          name: "NST MACHINE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PAPPER NOTCH", status: "N/A" },
            { name: "TRANSDUCERS", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY WEIGHING SCALE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "WEIGHT TEST", status: "N/A" },
            { name: "0.5kg", status: "N/A" },
            { name: "1kg", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "SPO2 MONITOR",
          parameter: [
            { name: "BATTERY COVER", status: "N/A" },
            { name: "LED", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY RESUSCITATOR",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            {
              name: "Temperature sensor and sensor cover for prolonged resuscitation",
              status: "N/A",
            },
            { name: "Plastic bag or plastic wrap", status: "N/A" },
            { name: "Cleanliness", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "ABG ANALYSER",
          parameter: [
            { name: "CHARGER/MAIN", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "CATRIDGE AREA", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "THERMAL PAPER", status: "N/A" },
            { name: "MEMORY", status: "N/A" },
          ],
        },
        {
          name: "DEFIBRILLATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEY PAD", status: "N/A" },
            { name: "PADDLES", status: "N/A" },
            { name: "CLEANED", status: "N/A" },
            { name: "SPRINTER", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "PATTERN TEST", status: "N/A" },
            { name: "ALARM", status: "N/A" },
            { name: "VERIFICATION", status: "N/A" },
            { name: "HEART RATE TEST : 80", status: "N/A" },
            { name: "SET ENERGY", status: "N/A" },
            { name: "50J", status: "N/A" },
            { name: "70J", status: "N/A" },
            { name: "100J", status: "N/A" },
            { name: "150J", status: "N/A" },
            { name: "200J", status: "N/A" },
          ],
        },
        {
          name: "ECG MACHINE",
          parameter: [
            {
              name: "AC MAINS VOLTAGE /N to E=3v/N to L=230v/L to E=230v",
              status: "N/A",
            },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "CLEANESS OF MACHINE", status: "N/A" },
            { name: "PRINTER CONDITION", status: "N/A" },
            { name: "BATTERY TEST", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "CALIBRATION", status: "N/A" },
            { name: "VALUE SELECTED 30", status: "N/A" },
            { name: "VALUE SELECTED 60", status: "N/A" },
            { name: "VALUE SELECTED 120", status: "N/A" },
          ],
        },
        {
          name: "BP APPARATUS ",
          parameter: [
            {
              name: "CHECK THE CLEANLINESS,COMPLETENESS,DAMAGE OF THE UNIT",
              status: "N/A",
            },
            { name: "CHECK THE BULB WITH CONTROL VALVE", status: "N/A" },
            { name: "CHECK THE BP CUFF", status: "N/A" },
            { name: "CLEAN THE MERCURY", status: "N/A" },
            { name: "REPLACE THE RUBBER WASHER IF REQUIRED", status: "N/A" },
          ],
        },
        {
          name: "INCUBATOR",
          parameter: [
            { name: "Power indicator", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Equipment display", status: "N/A" },
            { name: "Keypad", status: "N/A" },
            { name: "Heater function", status: "N/A" },
            { name: "Temperature display", status: "N/A" },
            { name: "Door locking movement", status: "N/A" },
            { name: "Sensor function", status: "N/A" },
            { name: "Feather touch switches", status: "N/A" },
          ],
        },
        {
          name: "LIGHT SOURCE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Power ON Indicator", status: "N/A" },
            { name: "Fiber Optic Cable", status: "N/A" },
            { name: "Fan Function", status: "N/A" },
            { name: "Adapter for Light Cable", status: "N/A" },
          ],
        },
        {
          name: "MEDICAL MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "REMOTE FUNCTION", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "PATIENT MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "ECG CABLE", status: "N/A" },
            { name: "NIBP CUFF", status: "N/A" },
            { name: "SPO2 PROBE", status: "N/A" },
          ],
        },

        {
          name: "TOURNIQUET",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Pressure bag and hose function", status: "N/A" },
            { name: "Timer Function", status: "N/A" },
            { name: "Pump Function", status: "N/A" },
            { name: "All Switches", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },

        {
          name: "VENTILATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BUTTONS", status: "N/A" },
            { name: "CIRCUIT ASSEMBLY", status: "N/A" },
            { name: "SENSORS", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "O2 AND AIR HOSE", status: "N/A" },
            { name: "CLENINESS", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "OT",
      equipment: [
        {
          name: "Cautery Machine or Diathermy",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "FOOT SWITCH", status: "N/A" },
            { name: "BIPLOAR& MONOPOLAR CABLE WORKING", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "SPO2 MONITOR",
          parameter: [
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "LED", status: "N/A" },
            { name: "BATTERY COVER", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "CAUTERY MACHINE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "FOOT SWITCH", status: "N/A" },
            { name: "BIPLOAR& MONOPOLAR CABLE WORKING", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY RESUSCITATOR",
          parameter: [
            { name: "KEYPAD ", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            {
              name: "Temperature sensor and sensor cover for prolonged resuscitation",
              status: "N/A",
            },
            { name: "Plastic bag or plastic wrap", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "Check for proper working of alarms", status: "N/A" },
            { name: "Cleanliness", status: "N/A" },
          ],
        },
        {
          name: "ANAESTHESIA MACHINE",
          parameter: [
            { name: "AC MAINS/POWEP CORD/BATTERY BACK UP", status: "N/A" },
            {
              name: "DISASSEMBLY OF BREATHING SYSTEMS AND BELLOWS ASSEMS ASSEMBLY FOR CLEANING",
              status: "N/A",
            },
            {
              name: "CLEANING OF FILTER /FLOW SENSORS FIT NEW SEALS BODOKS,RING",
              status: "N/A",
            },
            { name: "LEAKAGE  TEST WITHOUT VAPORIZER", status: "N/A" },
            { name: "LEAKAGE TEST WITH VAPORIZER", status: "N/A" },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY CYLINDER",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY PIPE LINE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF OXIGEN FAILURE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF CIRCLE ABSORBER",
              status: "N/A",
            },
            { name: "CHECK FOR PROPER MOVEMENTS OF WHEELS", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
            { name: "CALIBRATION OF OXIGEN SENSORS", status: "N/A" },
            {
              name: "FINAL TEST OF TIDAL VOLUME RATE AND I:E RATIO ,FIO2",
              status: "N/A",
            },
          ],
        },
        {
          name: "BP APPARATUS",
          parameter: [
            {
              name: "CHECK THE CLEANLINESS,COMPLETENESS,DAMAGE OF THE UNIT",
              status: "N/A",
            },
            { name: "CHECK THE BULB WITH CONTROL VALVE", status: "N/A" },
            { name: "CHECK THE BP CUFF", status: "N/A" },
            { name: "CLEAN THE MERCURY", status: "N/A" },
            { name: "REPLACE THE RUBBER WASHER IF REQUIRED", status: "N/A" },
          ],
        },
        {
          name: "DEFIBRILLATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEY PAD", status: "N/A" },
            { name: "PADDLES", status: "N/A" },
            { name: "CLEANED", status: "N/A" },
            { name: "SPRINTER", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "PATTERN TEST", status: "N/A" },
            { name: "ALARM", status: "N/A" },
            { name: "VERIFICATION", status: "N/A" },
            { name: "HEART RATE TEST : 80", status: "N/A" },
            { name: "SET ENERGY ", status: "N/A" },
          ],
        },
        {
          name: "ELECTRO SURGICAL UNIT",
          parameter: [
            { name: "KEY PAD FUNCTIONING", status: "N/A" },
            { name: "FOOTSWITH FUNCTIONING IN ALL MODES", status: "N/A" },
            { name: "MONOPOLAR  ND BIPOLAR FUNCTIONING", status: "N/A" },
            { name: "REM CONNECTION CHECK WITH ELECTRODE", status: "N/A" },
            { name: "ALARMS CHECK AND CLEANEESS", status: "N/A" },
            { name: "THE POWER SUPPLY", status: "N/A" },
          ],
          powerSupply: [
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+5V(RANGE0.15)AT  TP8",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE+/- 0.36)AT TP6",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE +/-0.36)AT TP5",
              observedValue: "",
            },
          ],
        },
        {
          name: "ETO STERLIZER",
          parameter: [
            { name: "DISPLAY AND KEYPAD", status: "N/A" },
            { name: "POWER INTEGRITY", status: "N/A" },
            { name: "FUSES", status: "N/A" },
            { name: "INPUT AIR REGULATOR", status: "N/A" },
            { name: "EXHAUST SYSTEM", status: "N/A" },
            { name: "DOOR LOCKING MECHANISM", status: "N/A" },
            { name: "GASKET", status: "N/A" },
            { name: "THERMAL SWITCH", status: "N/A" },
            { name: "LEAK TESTS", status: "N/A" },
            {
              name: "ETHYLENE OXIDE CARTRIDGE MOUNTING MACHINE",
              status: "N/A",
            },
          ],
        },
        {
          name: "FLASH AUTOCLAVE",
          parameter: [
            { name: "DISPLAY AND KEYPAD", status: "N/A" },
            { name: "GASKET CLEANING", status: "N/A" },
            { name: "DOOR LOCKING", status: "N/A" },
            { name: "TEMPERATURE INDICATION", status: "N/A" },
            { name: "WATR LEVEL INDICATION", status: "N/A" },
            { name: "DOOR LOCKING INDICAION", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
          ],
        },
        {
          name: "ANAESTHESIA MACHINE",
          parameter: [
            { name: "AC MAINS/POWEP CORD/BATTERY BACK UP", status: "N/A" },
            {
              name: "DISASSEMBLY OF BREATHING SYSTEMS AND BELLOWS ASSEMS ASSEMBLY FOR CLEANING",
              status: "N/A",
            },
            {
              name: "CLEANING OF FILTER /FLOW SENSORS FIT NEW SEALS BODOKS,RING",
              status: "N/A",
            },
            { name: "LEAKAGE  TEST WITHOUT VAPORIZER", status: "N/A" },
            { name: "LEAKAGE TEST WITH VAPORIZER", status: "N/A" },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY CYLINDER",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY PIPE LINE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF OXIGEN FAILURE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF CIRCLE ABSORBER",
              status: "N/A",
            },
            { name: "CHECK FOR PROPER MOVEMENTS OF WHEELS", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
            { name: "CALIBRATION OF OXIGEN SENSORS", status: "N/A" },
            {
              name: "FINAL TEST OF TIDAL VOLUME RATE AND I:E RATIO ,FIO2",
              status: "N/A",
            },
          ],
        },
        {
          name: "HARMONIC SCALPEL",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "All Switches Function", status: "N/A" },
            { name: "High Frequency Module Checking", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
          ],
          powerSupply: [
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+5V(RANGE0.15)AT  TP8",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE+/- 0.36)AT TP6",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE +/-0.36)AT TP5",
              observedValue: "",
            },
          ],
        },
        {
          name: "LIGHT SOURCE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Power ON Indicator", status: "N/A" },
            { name: "Fan Function", status: "N/A" },
            { name: "Adapter for Light Cable", status: "N/A" },
          ],
        },
        {
          name: "LITHOCLAST",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display Functions", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
          ],
        },
        {
          name: "MEDICAL MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "REMOTE FUNCTION", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "ORTHO DRILL",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Motor Function", status: "N/A" },
            { name: "Hand Unit Function", status: "N/A" },
            { name: "Forward/Reverse Function", status: "N/A" },
            { name: "Speed Variation Function", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Indicators", status: "N/A" },
          ],
        },
        {
          name: "OT  LIGHT",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Optical Assembly", status: "N/A" },
            { name: "Different Positioning of Domes", status: "N/A" },
            { name: "Dome  Intensity", status: "N/A" },
            { name: "Battery Backup Function", status: "N/A" },
            { name: "Light Beam Focusing", status: "N/A" },
          ],
        },
        {
          name: "OT TABLE",
          parameter: [
            { name: "PHYSICAL APPERANCE OF THE TABLE", status: "N/A" },
            { name: "NO HYDRAULIC FLUID LEAKAGE", status: "N/A" },
            { name: "MOVEMENT OF TABLE ON CASTORS", status: "N/A" },
            { name: "FIXATION OF TABLE TO THE GROUND", status: "N/A" },
            { name: "CONNECTIVITY OF TABLE WITH MAINS CORD", status: "N/A" },
            { name: "LED INDICATION ON REMOTE/ CONTROL PANEL", status: "N/A" },
            {
              name: "OPERATION OF THE  TABLE  BY REMOTE CONTROL PANEL",
              status: "N/A",
            },
            {
              name: "OPERATION OF TABLE  BY OVERRIDE /EMERGENCY CONTROL PANEL",
              status: "N/A",
            },
            { name: "OPERATION OF TABLE  ON BACK UP BATTERIES", status: "N/A" },
            {
              name: "LOCKING SYSTEM AND FIXATION OF VARIOUS  PARTS (HEAD REST,LEG SUPPORTS EXTENSIONS ETC.)",
              status: "N/A",
            },
            { name: "FIXATION OF ACCESSORIES TO THE TABLE ", status: "N/A" },
            { name: "CORRECT POSITIONING OF CUSHIONS", status: "N/A" },
            { name: "BATTERY VOLTAGE  24+/-2 V DC", status: "N/A" },
          ],
        },
        {
          name: "STEEM STERILIZER",
          parameter: [
            { name: "POWER  SUPPLY", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SAFETY  VALVE,TEMPERATURE GUAGE", status: "N/A" },
            { name: "PRESSURE  GUAGE", status: "N/A" },
            { name: "HEATING COIL", status: "N/A" },
            { name: "LEAKAGE", status: "N/A" },
            { name: "OVER ALL CLEANING", status: "N/A" },
          ],
        },
        {
          name: "SUCTION MACHINE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Battery Voltage", status: "N/A" },
            { name: "Tubings", status: "N/A" },
            { name: "Suction Container", status: "N/A" },
            { name: "Pressure", status: "N/A" },
            { name: "Earth Resistance", status: "N/A" },
            { name: "Leakage Current", status: "N/A" },
          ],
        },
        {
          name: "SYRINGE PMP",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEY PAD&DISPLAY", status: "N/A" },
            { name: "ALARM FUNCTION", status: "N/A" },
            { name: "OCCULSION", status: "N/A" },
            { name: "NEARLY EMPTY", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SYRINGE DETECTION", status: "N/A" },
            { name: "10ML,20ML", status: "N/A" },
            { name: "50ML", status: "N/A" },
          ],
        },
        {
          name: "TOURNIQUET",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Pressure bag and hose function", status: "N/A" },
            { name: "Timer Function", status: "N/A" },
            { name: "Pump Function", status: "N/A" },
            { name: "All Switches", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "Laboratory",
      equipment: [
        {
          name: "BIOCHEMISTRY ANALYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "SUCKING TUBE", status: "N/A" },
            { name: "MOVING ART", status: "N/A" },
            { name: "ELECTRICAL SAFEY", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING ", status: "N/A" },
          ],
        },
        {
          name: "BIOSAFETY CABINET",
          parameter: [
            { name: "Proper leveling and Stability", status: "N/A" },
            {
              name: "Proper flicker free operation of Florescent light",
              status: "N/A",
            },
            {
              name: "Proper operation of work chamber UV light",
              status: "N/A",
            },
            {
              name: "Proper operation of exhaust UV light through the check hole in the chamber,located above the gas cock",
              status: "N/A",
            },
            { name: "Manometer float lifting", status: "N/A" },
            {
              name: "Operation of all electrical switches on the control panel",
              status: "N/A",
            },
            {
              name: "Checked the gas leakage from the gas cock with soap solution",
              status: "N/A",
            },
            { name: "Checked the smooth door operation", status: "N/A" },
          ],
        },
        {
          name: "BLOOD CULTURE SYSTEM",
          parameter: [
            { name: "Power switch", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Key pad nad LCD display", status: "N/A" },
            { name: "Home Rotor Key/Rotor", status: "N/A" },
            { name: "Alaram Indicator", status: "N/A" },
            { name: "Barcode reader function ", status: "N/A" },
            { name: "Temperature verification", status: "N/A" },
            { name: "New positive indicator", status: "N/A" },
            { name: "Door interlock switch", status: "N/A" },
            { name: "Floppy Disk Drive", status: "N/A" },
            { name: "System start UP", status: "N/A" },
            { name: "Air Filter cleaning", status: "N/A" },
          ],
        },
        {
          name: "BLOOD MIXER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "ROTATING ARM", status: "N/A" },
            { name: "ROTATING SPEED", status: "N/A" },
            { name: "CUSION ON ROTATING ARM", status: "N/A" },
            { name: "TRAY", status: "N/A" },
            { name: "EARTHING", status: "N/A" },
          ],
        },
        {
          name: "CENTRIFUGE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "TUBES", status: "N/A" },
            { name: "CARBON BRUSHES", status: "N/A" },
            { name: "CLEANINESS", status: "N/A" },
            { name: "LUBRICANT", status: "N/A" },
            { name: "ELECTRICAL SAFETY", status: "N/A" },
          ],
        },
        {
          name: "COAGULOMETER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECTRICAL SAFETY", status: "N/A" },
            { name: "CHNNEL", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "PAPER", status: "N/A" },
            { name: "LAMP", status: "N/A" },
          ],
        },
        {
          name: "COBLATOR",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "All Switches Function", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },
        {
          name: "ELOCTOLYTE ANLYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SCREEN", status: "N/A" },
            { name: "BUTTONS", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "SAMPLE SUCKING", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "OVER ALL CLEANINESS", status: "N/A" },
          ],
        },
        {
          name: "ELISA READER",
          parameter: [
            { name: "Power integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Equipment filters", status: "N/A" },
            { name: "Equipment lamp", status: "N/A" },
            { name: "Measuring tray movement", status: "N/A" },
            { name: "Dispensing and aspiration", status: "N/A" },
            { name: "Switch function", status: "N/A" },
            { name: "Equipment display", status: "N/A" },
            { name: "Tubing", status: "N/A" },
            { name: "Earth resistance", status: "N/A" },
          ],
        },
        {
          name: "ENT MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "Lamps", status: "N/A" },
            { name: "Connectors", status: "N/A" },
            { name: "Objectives", status: "N/A" },
            { name: "Condenser", status: "N/A" },
            { name: "Mechanical Movements", status: "N/A" },
          ],
        },
        {
          name: "HEMOSTASIS  ANALYZER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "TOUCH SCREEN", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "SAMPLE  SUKING", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "OVER ALL CLEANINESS", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "SEMI AUTO MATIC ANALYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "SUCKING TUBE", status: "N/A" },
            { name: "SAMPLE AREA", status: "N/A" },
            { name: "ELECTRICAL SAFEY", status: "N/A" },
            { name: "TOUCH SCREEN", status: "N/A" },
            { name: "INTERNAL CLEANING ", status: "N/A" },
            { name: "LAMP", status: "N/A" },
            { name: "TUBINS", status: "N/A" },
          ],
        },
        {
          name: "VDRL SHAKER",
          parameter: [
            { name: "Power integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch functions", status: "N/A" },
            { name: "Mechanical movements", status: "N/A" },
            { name: "Leeds", status: "N/A" },
            { name: "Rollers", status: "N/A" },
            { name: "Earth resistance", status: "N/A" },
            { name: "Leakage current", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "Radiology",
      equipment: [
        {
          name: "ECHO MACHINE(HAND HELD)",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "ECHO PROBE", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "CR PRINTER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PHYSICAL APPERANCE", status: "N/A" },
            { name: "CONTRAST", status: "N/A" },
            { name: "PRINT QUALITY", status: "N/A" },
            { name: "SELF TEST SEQUENCE", status: "N/A" },
          ],
        },
        {
          name: "CR READER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PHYSICAL APPERANCE", status: "N/A" },
            { name: "LOADING SLOT", status: "N/A" },
            { name: "SCANNING TIME", status: "N/A" },
            { name: "EJECTION", status: "N/A" },
            { name: "CLEANESS", status: "N/A" },
          ],
        },
        {
          name: "PRESSURE INJECTOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "CONTROLS & SETTINGS", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "MECHANICAL ASSEMBLY CHECKING", status: "N/A" },
            { name: "PARAMETERS & SAMPLE  READINGS", status: "N/A" },
            { name: "CHECK FOR ALL SCREWS, KNOBS", status: "N/A" },
          ],
        },
        {
          name: "STEEM STERILIZER",
          parameter: [
            { name: "POWER  SUPPLY", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SAFETY  VALVE,TEMPERATURE GUAGE", status: "N/A" },
            { name: "PRESSURE  GUAGE", status: "N/A" },
            { name: "HEATING COIL", status: "N/A" },
            { name: "LEAKAGE", status: "N/A" },
            { name: "OVER ALL CLEANING", status: "N/A" },
          ],
        },
        {
          name: "ULTRASOUND",
          parameter: [
            { name: "SURFACE CLEANING/INTERNAL CLEANING", status: "N/A" },
            { name: "CHECKED ELECTRICAL CONNECTION AND CABLE", status: "N/A" },
            { name: "IMAGE QUALITY", status: "N/A" },
            { name: "TRANSDUCER CONNECTION", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
          ],
        },
      ],
    },
  ];

  let equipmentData = [
    {
      equipment: "ECHO",
      equipmentContent: [
        {
          parameter: [
            // { heading: "Physical Checklist" },
            { name: "Table Console", status: "N/A" },
            { name: "Probe Holders", status: "N/A" },
            { name: "Control Panel", status: "N/A" },
            { name: "Brake system", status: "N/A" },
            { name: "Probe", status: "N/A" },
            { name: "Monitor", status: "N/A" },
            { name: "Cooling/ Fans", status: "N/A" },
            { name: "Keyboard Harness", status: "N/A" },
            { name: "Power Cord", status: "N/A" },
            { name: "Voltage Stabilizer", status: "N/A" },
            { name: "Cover", status: "N/A" },
            { name: "Peripheral Input/output", status: "N/A" },
            { name: "Printer", status: "N/A" },
            // { heading: "System Diagnostics Checklist" },
            { name: "Error Check", status: "N/A" },
            { name: "Keyboard Function Check", status: "N/A" },
            { name: "Color Monitor System Check", status: "N/A" },
            { name: "Configuration Color Printer", status: "N/A" },
            { name: "Calibration", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Tonometer",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator Display", status: "N/A" },
            { name: "Bulb", status: "N/A" },
            { name: "Lens", status: "N/A" },
            { name: "Subflex", status: "N/A" },
            { name: "Airpulse", status: "N/A" },
            { name: "Set/Reset", status: "N/A" },
            { name: "Review", status: "N/A" },
            { name: "Demo", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "EEG",
      equipmentContent: [
        {
          parameter: [
            { name: "Overview", status: "N/A" },
            { name: "Power", status: "N/A" },
            { name: "Input Circuit and Amplifier", status: "N/A" },
            { name: "Operation", status: "N/A" },
            { name: "Activation", status: "N/A" },
            { name: "Disk Drive", status: "N/A" },
            { name: "Electrode Lead", status: "N/A" },
            { name: "Hard Disk and MO", status: "N/A" },
            { name: "Printer", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Bilirubinometer",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Power/Line Indicator", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Bulb Lamp", status: "N/A" },
            { name: "Cleaning", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Blood Bank",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Temperature", status: "N/A" },
            { name: "Freezer", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Centrifuge",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Speed", status: "N/A" },
            { name: "Start Button", status: "N/A" },
            { name: "Stop Button", status: "N/A" },
            { name: "Lid Button", status: "N/A" },
            { name: "Timer", status: "N/A" },
            { name: "Decelerate Button", status: "N/A" },
            { name: "Door Switch", status: "N/A" },
            { name: "Imbalance", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Unit Endoscopy",
      equipmentContent: [
        {
          parameter: [
            { name: "Control/switch", status: "N/A" },
            { name: "Fitting and connectors", status: "N/A" },
            { name: "Cable and accessories", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Suction System", status: "N/A" },
            { name: "Xenon lamp", status: "N/A" },
            { name: "Gastro scope", status: "N/A" },
            { name: "Colon scope", status: "N/A" },
            { name: "Broncos scope", status: "N/A" },
            { name: "Printer", status: "N/A" },
            { name: "White Balance", status: "N/A" },
            { name: "Monitor", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Infant Warmer",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mount/Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Probe", status: "N/A" },
            { name: "Heater", status: "N/A" },
            { name: "Suction System", status: "N/A" },
            { name: "Flow meterO2", status: "N/A" },
            { name: "Bassinet Tilt Control", status: "N/A" },
            { name: "Side and End Panel", status: "N/A" },
            { name: "X-Ray Tray", status: "N/A" },
            { name: "Examination light", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Short Wave Diathermy",
      equipmentContent: [
        {
          parameter: [
            { name: "Power cord", status: "N/A" },
            { name: "Fuse drawer", status: "N/A" },
            { name: "Power output meter", status: "N/A" },
            { name: "Power adjust step", status: "N/A" },
            { name: "Electrode", status: "N/A" },
            { name: "Electrode cable", status: "N/A" },
            { name: "Timer+ indicator", status: "N/A" },
            { name: "Electrode holding", status: "N/A" },
            { name: "Wheel+ Brake", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Microwave Diathermy",
      equipmentContent: [
        {
          parameter: [
            { name: "Power cord", status: "N/A" },
            { name: "Fuse drawer", status: "N/A" },
            { name: "Power output meter", status: "N/A" },
            { name: "Power adjust step", status: "N/A" },
            { name: "Electrode", status: "N/A" },
            { name: "Electrode cable", status: "N/A" },
            { name: "Timer+ indicator", status: "N/A" },
            { name: "Electrode holding", status: "N/A" },
            { name: "Wheel+ Brake", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Electric Stimulator",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mount / Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Brake system", status: "N/A" },
            { name: "Vacuum System", status: "N/A" },
            { name: "Pad electrode", status: "N/A" },
            { name: "Water reservoir", status: "N/A" },
            { name: "Intensity", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Infra Red Lamp",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mount / Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Timer", status: "N/A" },
            { name: "Lamp", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Microscope",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/ Fasteners", status: "N/A" },
            { name: "Power/Line Indicator", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Dimmer System", status: "N/A" },
            { name: "Bulb Lamp", status: "N/A" },
            { name: "Focus System", status: "N/A" },
            { name: "LENS Cleaning", status: "N/A" },
            { name: "Balancing", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Electronic Laparofator",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mount / Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Accessories", status: "N/A" },
            { name: "Gas Supply", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Nerve Detector",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mount / Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Accessories", status: "N/A" },
            { name: "Battery", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Suction Pump Unit",
      equipmentContent: [
        {
          parameter: [
            { name: "Pump Lubrication", status: "N/A" },
            { name: "Pump Cylinder", status: "N/A" },
            { name: "Valve", status: "N/A" },
            { name: "Regulator", status: "N/A" },
            { name: "Control Circuit", status: "N/A" },
            { name: "Collection Bottle and Cap Assembly", status: "N/A" },
            { name: "Manometer Tube Sterilization", status: "N/A" },
            { name: "Casing", status: "N/A" },
            { name: "Brake System", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Entonox",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Oxygen Accessories", status: "N/A" },
            { name: "Nitrous Oxide Accessories", status: "N/A" },
            { name: "Mixer(%)", status: "N/A" },
            { name: "Test Lung", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "IABP",
      equipmentContent: [
        {
          parameter: [
            { name: "Cords and Cables", status: "N/A" },
            { name: "Controls and Switches", status: "N/A" },
            { name: "Safety Disk", status: "N/A" },
            { name: "Cooling Fan", status: "N/A" },
            { name: "Doppler", status: "N/A" },
            { name: "Pneumatic Compartment", status: "N/A" },
            { name: "Fill Assembly", status: "N/A" },
            { name: "Motor Compartment", status: "N/A" },
            { name: "Electronic Panel", status: "N/A" },
            { name: "Helium Supply", status: "N/A" },
            { name: "Battery Back Up", status: "N/A" },
            {
              name: "Calibrate System and Perform Functional Test",
              status: "N/A",
            },
          ],
        },
      ],
    },

    {
      equipment: "CPAP",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/ Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Resuscitator",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/ Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Water Bath",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/ Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Temperature", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Doppler",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/ Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Alarm/Audible Signals", status: "N/A" },
            { name: "Tranduser", status: "N/A" },
            { name: "Cabel+Conector Tranduser", status: "N/A" },
            { name: "Beep", status: "N/A" },
            { name: "Calibrasi", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Fetal Monitor/CTG",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Control/ Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/display", status: "N/A" },
            { name: "Selft Test", status: "N/A" },
            { name: "Transducer Test", status: "N/A" },
            { name: "Parameter Test", status: "N/A" },
            { name: "System Test", status: "N/A" },
            { name: "Printing Test", status: "N/A" },
            { name: "Beep", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Eagle Ten Sterilizer",
      equipmentContent: [
        {
          parameter: [
            { name: "Preparation", status: "N/A" },
            { name: "Door Assembly", status: "N/A" },
            { name: "Selenoid Valve", status: "N/A" },
            { name: "Over temperature Controller", status: "N/A" },
            { name: "Air Vent (Steam)", status: "N/A" },
            { name: "Gauge", status: "N/A" },
            { name: "Chamber & Water Reservoir", status: "N/A" },
            { name: "Control Components", status: "N/A" },
            { name: "Final Test", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "USG",
      equipmentContent: [
        {
          parameter: [
            // {heading:"Physical Checklist"},
            { name: "Table Console", status: "N/A" },
            { name: "Probe Holders", status: "N/A" },
            { name: "Control Panel", status: "N/A" },
            { name: "Brake system", status: "N/A" },
            { name: "Probe", status: "N/A" },
            { name: "Monitor", status: "N/A" },
            { name: "Cooling /Fans", status: "N/A" },
            { name: "Keyboard & Track Ball", status: "N/A" },
            { name: "Power Cord", status: "N/A" },
            { name: "Voltage Stabilizer", status: "N/A" },
            { name: "Cover", status: "N/A" },
            { name: "Peripheral Input/output", status: "N/A" },
            { name: "Printer", status: "N/A" },
            // {heading:"System Diagnostics Checklist"},
            { name: "Error Check", status: "N/A" },
            { name: "Keyboard Function Check", status: "N/A" },
            { name: "Color Monitor System Check", status: "N/A" },
            { name: "Configuration Color Printer", status: "N/A" },
            { name: "Calibration", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "NBP Monitor",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Alarm/Audible Signals", status: "N/A" },
            { name: "Manset", status: "N/A" },
            { name: "Self Test", status: "N/A" },
            { name: "Pump", status: "N/A" },
            { name: "Calibrasi", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Phototherapy Lamp",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Timer", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Cooling Fan", status: "N/A" },
            { name: "Bulb", status: "N/A" },
            { name: "Light output Check", status: "N/A" },
            { name: "Cleaning", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Electrosurgical",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Foot Switch", status: "N/A" },
            { name: "Isolation Switch", status: "N/A" },
            { name: "Low Frequency Output", status: "N/A" },
            { name: "REM Circuit", status: "N/A" },
            { name: "Cooling Fan Test", status: "N/A" },
            { name: "Power On Switch and Circuit Breaker", status: "N/A" },
            // { heading: "Power Supply" },
          ],
          powerSupplyParams: [
            {
              supplyParameter: "Pure Cut Levels",
              values: [
                { value: "10 (375 ± 25 Watts Tolerance)" },
                { value: "7 (245 ± 30 Watts Tolerance)" },
                { value: "5 (160 ± 30 Watts Tolerance)" },
                { value: "2 (30 ± 20 Watts Tolerance)" },
              ],
            },
            {
              supplyParameter: "Blend Levels",
              values: [
                { value: "10 (250 ± 25 Watts Tolerance)" },
                { value: "7 (140 ± 40 Watts Tolerance)" },
                { value: "5 (95 ± 20 WattsTolerance)" },
                { value: "2 (25 ± 15 Watts Tolerance)" },
              ],
            },
            {
              supplyParameter: "Coag Levels",
              values: [
                { value: "10 (125 ± 15 Watts Tolerance)" },
                { value: "7 (75 ± 10 Watts Tolerance)" },
                { value: "5 (45 ± 10 Watts Tolerance)" },
                { value: "2 (10 ± 5 Watts Tolerance)" },
              ],
            },
          ],
          heading: "Electro surgical Quantitative Test",
          powerSupplyFinal: [],
        },
      ],
    },
    {
      equipment: "Transport Incubator",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Alarms/Audible Signals", status: "N/A" },
            { name: "Air oxygen system", status: "N/A" },
            { name: "Temperature", status: "N/A" },
            { name: "Infant Chamber", status: "N/A" },
            { name: "Air Flow System", status: "N/A" },
            { name: "Tank Inspection", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Defibrilator",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Alarms/Audible Signals", status: "N/A" },
            { name: "Recorder/Printer", status: "N/A" },
            {
              name: "Cardio version Test (Paper Speed 25mm / 50 mm)",
              status: "N/A",
            },
            { name: "Defib Paddles (Delivered)", status: "N/A" },
            { name: "Safety Checks (100 Joule)", status: "N/A" },
            { name: "Intemal Cal. Test", status: "N/A" },
          ],
          powerSupplyParams: [
            {
              supplyParameter: "Power Supply",
              values: [
                { value: "10 (375 ± 25 Watts Tolerance)" },
                { value: "20 (16 -24 J Tolerance)" },
                { value: "50 (45 - 57 J Tolerance" },
                { value: "100 (85 - 115 J Tolerance)" },
                { value: "200 (170 - 230 J Tolerance" },
                { value: "300 (225 - 345 J Tolerance)" },
                { value: "360 (306 - 414 J Tolerance)" },
              ],
            },
          ],
          heading: "Electro surgical Quantitative Test",
          powerSupplyFinal: [],
        },
      ],
    },
    {
      equipment: "ENT Unit",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator Display", status: "N/A" },
            { name: "Warm Water System", status: "N/A" },
            { name: "Light System", status: "N/A" },
            { name: "Suction System", status: "N/A" },
            { name: "Mirror Warming", status: "N/A" },
            { name: "Compressed Air System", status: "N/A" },
            { name: "Stroboscope", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Dental Unit",
      equipmentContent: [
        {
          parameter: [
            { name: "Water Input Block", status: "N/A" },
            { name: "Air Input Block", status: "N/A" },
            { name: "Disinfection System", status: "N/A" },
            { name: "Drain", status: "N/A" },
            { name: "Hand Piece", status: "N/A" },
            { name: "Suction System", status: "N/A" },
            { name: "Amalgam Separator", status: "N/A" },
            { name: "Spittoon", status: "N/A" },
            { name: "Dental Chair Unit", status: "N/A" },
            { name: "Compresor Unit", status: "N/A" },
            { name: "Media", status: "N/A" },
            { name: "Voltages", status: "N/A" },
            { name: "Foot Control", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Patient Monitor",
      equipmentContent: [
        {
          parameter: [
            { name: "Display housing", status: "N/A" },
            { name: "Computer module housing", status: "N/A" },
            { name: "Line / Power plug", status: "N/A" },
            { name: "Line / Power cord", status: "N/A" },
            { name: "Display fuse holder", status: "N/A" },
            {
              name: "System cables at rear of Display &  Computer Module",
              status: "N/A",
            },
            { name: "Cable connectors", status: "N/A" },
            { name: "Rack & Parameter Module connectors", status: "N/A" },
            { name: "Labeling and accessories", status: "N/A" },
            { name: "Patient safety checks", status: "N/A" },
            { name: "Indicators on/off and screen", status: "N/A" },
            { name: "LEDs on the parameter Modules", status: "N/A" },
            { name: "Display performance", status: "N/A" },
            { name: "Visual and audible Alarm", status: "N/A" },
            { name: "Self-check procedures", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Warming Cabinet",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Power/Line Indicator", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Heating Filament", status: "N/A" },
            { name: "Fan", status: "N/A" },
            { name: "Door Sensor", status: "N/A" },
            { name: "Display", status: "N/A" },
            { name: "Clean Cabinet", status: "N/A" },
            { name: "Heat Sensor", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Tourniquet System",
      equipmentContent: [
        {
          parameter: [
            { name: "Cleaning", status: "N/A" },
            { name: "Inspection", status: "N/A" },
            { name: "Functional and Calibration Checks", status: "N/A" },
            { name: "Calibration Transducer Offset", status: "N/A" },
            { name: "Calibration Common Mode", status: "N/A" },
            { name: "Calibration Span Adjustment", status: "N/A" },
            { name: "Calibration Iteration Of Adjustment", status: "N/A" },
            { name: "Watchdog Timer Test", status: "N/A" },
            { name: "Leak Testing", status: "N/A" },
            { name: "Power Supply/ Battery Charger", status: "N/A" },
            {
              name: "Battery Voltage Check and Battery Service ",
              status: "N/A",
            },
            { name: "Overpressure Regulator ", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Heart Lung Machine",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts /Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Alarms/Audible Signals", status: "N/A" },
            { name: "Pump", status: "N/A" },
            { name: "Pressure Meter", status: "N/A" },
            {
              name: "Power Supply Voltage (5 V, 12 V, 15 V and 24 V)",
              status: "N/A",
            },
          ],
        },
      ],
    },
    {
      equipment: "Electrocardiograph",
      equipmentContent: [
        {
          parameter: [
            // { heading: "Visual Inspection:" },
            { name: "Loss or missing hardware", status: "N/A" },
            { name: "Frayed or damage wiring", status: "N/A" },
            { name: "Mechanical damage", status: "N/A" },
            { name: "Evidence of liquid spill", status: "N/A" },
            { name: "Printer drive gear wear", status: "N/A" },
            { name: "Printer roller wear", status: "N/A" },
            {
              name: "Wear or damage to power cord and Associated strain relief",
              status: "N/A",
            },
            { name: "Corroded or damage electrodes", status: "N/A" },
            {
              name: "Damage lead wires or patient module cable",
              status: "N/A",
            },
            { name: "Dirt on thermal printer head", status: "N/A" },
            { name: "The AC indicator is lit", status: "N/A" },
            {
              name: "One or more green battery indicator are lit when On-Standby is pressed Turn on the cardiograph",
              status: "N/A",
            },
            // { heading: "Extended Self-test:" },
            { name: "Patient module and cable", status: "N/A" },
            { name: "CPU assembly", status: "N/A" },
            { name: "Printer", status: "N/A" },
            { name: "Preview display", status: "N/A" },
            { name: "Keyboard display", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Ventilator",
      equipmentContent: [
        {
          parameter: [
            // { heading: "Physical/Qualitative test:" },
            { name: "Chassis/Mounts /Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Battery/Charger", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Alarms/Audible Signals", status: "N/A" },
            // { heading: "Calibration/Verification Checklist:" },
            { name: "Spontaneous Flow", status: "N/A" },
            { name: "Main Flow", status: "N/A" },
            { name: "Respiratory Rate", status: "N/A" },
            { name: "Inspiratory Time", status: "N/A" },
            { name: "A/C Sigh", status: "N/A" },
            { name: "Nebulizer", status: "N/A" },
            { name: "Peep", status: "N/A" },
            { name: "Peak Inspiratory Pressure (PIP)", status: "N/A" },
            { name: "Leakage Test", status: "N/A" },
            { name: "Tidal Volume", status: "N/A" },
            { name: "Total Rate", status: "N/A" },
            { name: "I: E Ratio", status: "N/A" },
            { name: "Manual Breath", status: "N/A" },
            { name: "Alarm Silence", status: "N/A" },
            { name: "Expiration Time/Led", status: "N/A" },
            { name: "Apnea Time", status: "N/A" },
            { name: "Preset", status: "N/A" },
            { name: "Pressure (Peak, Mean,& Base)", status: "N/A" },
            { name: "Mode Selector", status: "N/A" },
            { name: "Trigger level", status: "N/A" },
            { name: "Low Pressure Alarm", status: "N/A" },
            { name: "High Pressure Alarm", status: "N/A" },
            {
              name: "External power Off /Power Disconnect Alarm",
              status: "N/A",
            },
            { name: "Battery power", status: "N/A" },
            { name: "Low Battery Alarm", status: "N/A" },
            { name: "System Failure Alarm", status: "N/A" },
            { name: "FI 02", status: "N/A" },
            { name: "Hour meter", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Baby Incubator",
      equipmentContent: [
        {
          parameter: [
            // { heading: "Operational Checkout Procedure:" },
            { name: "Power Failure", status: "N/A" },
            { name: "AC Power Cord", status: "N/A" },
            { name: "VHA Stand", status: "N/A" },
            { name: "Hood Hinge and Latch operation", status: "N/A" },
            { name: "Access Panel Detent and Noise Level", status: "N/A" },
            { name: "Air Curtain Cover", status: "N/A" },
            { name: "Main Deck", status: "N/A" },
            { name: "Iris Entry Port", status: "N/A" },
            { name: "Access Panel Latches", status: "N/A" },
            { name: "Access Door Latch", status: "N/A" },
            { name: "Mattress Elevators", status: "N/A" },
            { name: "Mattress Tray Operation", status: "N/A" },
            { name: "Air Intake Micro filter", status: "N/A" },
            { name: "Oxygen Input Valve Filter", status: "N/A" },
            { name: "Air/Oxygen System", status: "N/A" },
            // { heading: "Operational Checkout - Controller" },
            { name: "Air Control Mode Of Operation", status: "N/A" },
            { name: "Air Set Temperature Alarm", status: "N/A" },
            { name: "Air Auxiliary Probe", status: "N/A" },
            { name: "Baby Control Mode Of Operation", status: "N/A" },
            { name: "Baby Set Temperature Alarm", status: "N/A" },
            { name: "Baby Temp Probe Fail Alarm", status: "N/A" },
            { name: "Air Flow Alarm", status: "N/A" },
            { name: "Max Air Temperature", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Cauter",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator Display", status: "N/A" },
            { name: "Electrode", status: "N/A" },
            { name: "Foot Switch", status: "N/A" },
            { name: "Surgical Output", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Infusion Pump",
      equipmentContent: [
        {
          parameter: [
            { name: "Main Unit/Pole Clamp (any damage)", status: "N/A" },
            { name: "Battery Power", status: "N/A" },
            { name: "Self Check", status: "N/A" },
            { name: "Charging System", status: "N/A" },
            { name: "Start/Stop/Silence Operation", status: "N/A" },
            { name: "Tube Clamp", status: "N/A" },
            { name: "Occlusion detection", status: "N/A" },
            { name: "Delivery Rate Accuracy", status: "N/A" },
            { name: "Air-in-line Sensor", status: "N/A" },
            { name: "Drop Sensor", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Medical Air Equipment",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator Display", status: "N/A" },
            { name: "Reverse Cylinder", status: "N/A" },
            { name: "Safety Valve", status: "N/A" },
            { name: "Regulator System", status: "N/A" },
            { name: "Alarm System", status: "N/A" },
            { name: "Pressure Meter", status: "N/A" },
            { name: "Motor Compression", status: "N/A" },
            { name: "Oil Motor Compression", status: "N/A" },
            { name: "Filter Air", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Anaesthetic Gas Scavenging System",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts / Fasteners", status: "N/A" },
            { name: "Controls/Switches", status: "N/A" },
            { name: "Fittings/Connectors", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Indicator Display", status: "N/A" },
            { name: "Vaccum Machine", status: "N/A" },
            { name: "Alarm System", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Suction Regulator",
      equipmentContent: [
        {
          parameter: [
            { name: "Switch On/off", status: "N/A" },
            { name: "Regulator", status: "N/A" },
            { name: "Cartidge/meter pointer ", status: "N/A" },
            { name: "Suction output 100mmHg", status: "N/A" },
            { name: "Suction output 200mmHg", status: "N/A" },
            { name: "Suction output 300mmHg", status: "N/A" },
            { name: "Suction output 400mmHg", status: "N/A" },
            { name: "Suction output 500mmHg", status: "N/A" },
            { name: "Suction output 600mmHg", status: "N/A" },
            { name: "Suction output 700mmHg", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Ultrasound Therapy",
      equipmentContent: [
        {
          parameter: [
            { name: "Power Cord", status: "N/A" },
            { name: "Fuse Drawer", status: "N/A" },
            { name: "Folding Handle", status: "N/A" },
            { name: "Power/Intensity Key", status: "N/A" },
            { name: "Output Calibration Key", status: "N/A" },
            { name: "Transducer Data Key", status: "N/A" },
            { name: "Transducer Head", status: "N/A" },
            { name: "Contrast Display", status: "N/A" },
            { name: "Transducer Cable", status: "N/A" },
            { name: "Cleaning Unit", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Nebulizer",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Power line", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Ultrasonic Electrode", status: "N/A" },
            { name: "Timer", status: "N/A" },
            { name: "Sensor Water Level", status: "N/A" },
            { name: "Air Filter", status: "N/A" },
            { name: "Fan", status: "N/A" },
            { name: "Cleaning", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Light Source",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Bulb", status: "N/A" },
            { name: "Fiber Optic", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "EMG",
      equipmentContent: [
        {
          parameter: [
            { name: "Overview", status: "N/A" },
            { name: "Power", status: "N/A" },
            { name: "Input Circuit and Amplifier", status: "N/A" },
            { name: "Operation", status: "N/A" },
            { name: "Activation", status: "N/A" },
            { name: "Disk Drive", status: "N/A" },
            { name: "Electrode Lead", status: "N/A" },
            { name: "Hard Disk", status: "N/A" },
            { name: "Printer", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "AudioMeter",
      equipmentContent: [
        {
          parameter: [
            { name: "Power on/off", status: "N/A" },
            { name: "Present/Interrupt Switch (2)", status: "N/A" },
            { name: "Left/Right Earphone Selector", status: "N/A" },
            { name: "Stimulus on (interrupt)", status: "N/A" },
            { name: "Stimulus off (Present)", status: "N/A" },
            { name: "Automatic Pulsing", status: "N/A" },
            { name: "Frequenchy Modulation (FM)", status: "N/A" },
            { name: "Test Signal", status: "N/A" },
            { name: "+10 dB Switch", status: "N/A" },
            { name: "Tone Stimulus Select", status: "N/A" },
            { name: "Tape/Microphone Select", status: "N/A" },
            { name: "Speaker Select", status: "N/A" },
            { name: "+2.5 Select", status: "N/A" },
            { name: "Talk Forward", status: "N/A" },
            { name: "Frequency Select Control Dial", status: "N/A" },
            { name: "Intensity Control Dial", status: "N/A" },
            { name: "Masking Level Control", status: "N/A" },
            { name: "Test Microphone Level Control", status: "N/A" },
            { name: "LED Read Out of Frequency Selected", status: "N/A" },
            { name: "LED Read Out Of Intensity Selected", status: "N/A" },
            { name: "LED Indicator (subject Respond)", status: "N/A" },
            { name: "LED Indicator (Stimulus Present)", status: "N/A" },
            { name: "LED Indicator of Active Test", status: "N/A" },
            { name: "Earphone or Masking For Bone", status: "N/A" },
            { name: "LED indicator Stimulus On/Off", status: "N/A" },
            { name: "LED indicator of Auto Pulsign", status: "N/A" },
            { name: "LED Indicator for FM", status: "N/A" },
            {
              name: "LED indicator for masking level intensity",
              status: "N/A",
            },
            { name: "LED indicator of +10 dB", status: "N/A" },
            { name: "LED Indicator Earphone/Bone", status: "N/A" },
            { name: "LED Indicator of +2.5 dB", status: "N/A" },
            { name: "LED Indicator Speaker Selection", status: "N/A" },
            { name: "LED Indicator of Tone Stimulus", status: "N/A" },
            {
              name: "LED Indicator of Tape or Microphone Stimulus Selected",
              status: "N/A",
            },
            { name: "Speech Level VU meter", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Laser Yag",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Laser Output", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Sterilizer",
      equipmentContent: [
        {
          parameter: [
            { name: "Preparation", status: "N/A" },
            { name: "Door Assembly", status: "N/A" },
            { name: "Solenoid Valve", status: "N/A" },
            { name: "Over temperature Controller", status: "N/A" },
            { name: "Air Vent (Steam)", status: "N/A" },
            { name: "Gauge", status: "N/A" },
            { name: "Chamber & Water Reservoir", status: "N/A" },
            { name: "Control Components", status: "N/A" },
            { name: "Final Test", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Surgical Table",
      equipmentContent: [
        {
          parameter: [
            { name: "Preparation", status: "N/A" },
            { name: "Hydraulic System", status: "N/A" },
            { name: "Casters and Floor Locks", status: "N/A" },
            { name: "Controls", status: "N/A" },
            { name: "Electrical Checks", status: "N/A" },
            { name: "Table Rigidity", status: "N/A" },
            { name: "Final Test", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Blood Warmer",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Heater", status: "N/A" },
            { name: "Line Of Tubing Set", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Operating Lamp",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Power/Adaptor Voltage", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Dimmer Regulator System", status: "N/A" },
            { name: "Brake Rotary System", status: "N/A" },
            { name: "Focus System", status: "N/A" },
            { name: "Cleaning", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "syringe pump",
      equipmentContent: [
        {
          parameter: [
            { name: "Self Diagnosis", status: "N/A" },
            { name: "Dial", status: "N/A" },
            { name: "Clear Σml", status: "N/A" },
            { name: "Buzzer Volume", status: "N/A" },
            { name: "Body weight mode", status: "N/A" },
            { name: "Syringe size detection", status: "N/A" },
            { name: "Nearly empty alarm", status: "N/A" },
            { name: "Occlusion", status: "N/A" },
            { name: "Flow rate accuracy", status: "N/A" },
            { name: "Battery", status: "N/A" },
          ],
        },
      ],
    },
    {
      equipment: "Slit Lamp",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Lamp", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Drying Cabinet",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Control/Switches", status: "N/A" },
            { name: "Fitting/Connector", status: "N/A" },
            { name: "Cable/Accessories", status: "N/A" },
            { name: "Indicator/Display", status: "N/A" },
            { name: "Fan", status: "N/A" },
            { name: "Heater", status: "N/A" },
            { name: "Cleaning", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Ultrasonic Cleaner",
      equipmentContent: [
        {
          parameter: [
            { name: "Chassis/Mounts/Fasteners", status: "N/A" },
            { name: "Cables/Accessories", status: "N/A" },
            { name: "Power/Adaptor", status: "N/A" },
            { name: "Rack system", status: "N/A" },
            { name: "Indicator", status: "N/A" },
            { name: "Timer", status: "N/A" },
            { name: "Cleaning", status: "N/A" },
          ],
        },
      ],
    },

    {
      equipment: "Hypo/Hyperthermia Blanket",
      equipmentContent: [
        {
          parameter: [
            {
              name: "External cabinet and control panel in good condition.",
              status: "N/A",
            },
            { name: "All warning labels properly affixed", status: "N/A" },
            {
              name: "Quick disconnect coupling (tight, straight, not leaking)",
              status: "N/A",
            },
            {
              name: "Power Cord (no cuts or exposed wire) and plug no (bent or missing pin)",
              status: "N/A",
            },
            {
              name: "Indicator lights (heat & cool, compressor, heaters, pump, power)",
              status: "N/A",
            },
            { name: "Drain and clean reservoir", status: "N/A" },
            { name: "Clean water filter", status: "N/A" },
            {
              name: "Refill reservoir with distilled or sterile water",
              status: "N/A",
            },
            {
              name: "Leakage current check (all reading should be under 110 μA for 115/110 Volt AC and 500μA for 230/240 Volt AC)",
              status: "N/A",
            },
            { name: "OFF normal polarity", status: "N/A" },
            { name: "OFF reverse polarity", status: "N/A" },
            { name: "ON normal polarity (heat)", status: "N/A" },
            { name: "ON reverse polarity (heat)", status: "N/A" },
            { name: "ON normal polarity (cool)", status: "N/A" },
            {
              name: "Condition of blanket, hoses, coupling (check for leaks)",
              status: "N/A",
            },
            { name: "Refrigerant test Clean condenser and fan", status: "N/A" },
            { name: "Refrigerant test Check sight glass", status: "N/A" },
            { name: "Check temperature (high/low and limit)", status: "N/A" },
          ],
        },
      ],
    },
  ];

  const handleEquipment = (e, value) => {
    setDropdown(value);
    setBrand("");
    setAssetCode("");
    setAssetName("");
    setDoneBy("");
    setRemark("");
    setStartDate("");
    setEndDate("");
    setSingleDepartment("");
    setSerialNumber("");
    setModelNumber("");
    setCheck([]);

    let selectedEquipment = department?.equipment?.filter((name) => {
      return name?.name === value;
    });

    setCheck([...selectedEquipment]);
    setFlag(false);
  };

  // const handleChangeStatus = (index, value) => {
  //   setSelectedOption(value);
  //   const updatedParameters = [...check];
  //   updatedParameters[0].parameter[index].status = value;
  //   setCheck(updatedParameters);
  //   setIsParameterError("");
  //   setFlag(false);
  // };

  // const handleChangeStatus = (index, newStatus) => {
  //   setEquipmentDetails((prevDetails) => {
  //     return prevDetails.map((detail) => ({
  //       ...detail,
  //       parameter: detail.parameter.map((param, i) =>
  //         i === index ? { ...param, status: newStatus } : param
  //       ),
  //     }));
  //   });
  // };

  const handleChangeStatus = (paramIndex, newStatus) => {
    setEquipmentDetails((prevDetails) =>
      prevDetails.map((detail) => ({
        ...detail,
        parameter: detail.parameter.map((param, index) =>
          index === paramIndex ? { ...param, status: newStatus } : param
        ),
      }))
    );
  };

  const handleChangeObservedValue = (e, index) => {
    const value = e.target.value;
    const updatedObservedValue = [...check];
    updatedObservedValue[0].powerSupply[index].observedValue = value;
    setCheck(updatedObservedValue);
    setIsParameterError("");
    setFlag(false);
  };

  const handleAssetName = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setAssetName(newValue.label);
      filterDetails(newValue.value);
      setIsAssetNameError("");
      setFlag(false);
    } else {
      setIsAssetNameError("Please fill the field");
      setBrand("");
      setAssetCode("");
      setSingleDepartment("");
      setSerialNumber("");
      setModelNumber("");
    }
  };

  const hanldeStartDate = (date) => {
    setStartDate(date);
    handleDate(date, endDate);
    setFlag(false);
  };

  const hanldeEndDate = (date) => {
    setEndDate(date);
    handleDate(startDate, date);
    setFlag(false);
  };

  const handleDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
      setIsDateError("Invalid date range");
    } else if (start.getTime() === end.getTime()) {
      setIsDateError("Done Date should be before Due Date");
    } else {
      setIsDateError("");
    }
  };

  const handleRemark = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;

    if (value === "" || regex.test(value)) {
      setRemark(value);
      setIsRemarkError("");
      setFlag(false);
    }
    if (value !== "" && !regex.test(value)) {
      setIsRemarkError("Invalid character");
    }
    if (value === "") {
      setIsRemarkError("Please fill the field");
    }
  };

  const handleDoneBy = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z]+$/;
    if (value === "" || regex.test(value)) {
      setDoneBy(value);
      setFlag(false);
      setIsDoneByError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsDoneByError("wrong Name");
    }
    if (value === "") {
      setIsDoneByError("Please fill the field");
    }
  };

  const filterDetails = (asset) => {
    let filterData = getAllData?.filter((item) => {
      return item.assetid === asset;
    });

    let singleData = filterData[0];
    setSerialNumber(singleData?.serialno);
    setModelNumber(singleData?.modelno);
    setBrand(singleData?.brand);
    setAssetCode(singleData?.assetcode);
    setSingleDepartment(singleData?.department);
    setAssetId(singleData?.assetid);
    setDepartmentId(singleData.department_id);
    setIframeData({ ...singleData });
    // console.log("singleData", singleData);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(getPmAsset(data, token));
  }, [dispatch]);

  useEffect(() => {
    setColor(selectedDepartment);

    let value = selectedDepartment && selectedDepartment.department;

    let filterData = data2?.filter((item) => {
      return item.department === value;
    });

    setDepartment(...filterData);
  }, [selectedDepartment]);

  useEffect(() => {
    if (assetData && assetData?.length !== 0) {
      setGetAllData(assetData);
    }
  }, [assetData, equipment]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const hasEmptyStatus = equipmentDetails.some((detail) =>
    detail.parameter.some(
      (item) => item.status === "Pass" || item.status === "Fail"
    )
  );

  const formattedStartDate = dayjs(startDate).format("DD-MM-YYYY");
  const formattedEndDate = dayjs(endDate).format("DD-MM-YYYY");

  const handleSubmit = () => {
    if (endDate === "" || startDate === "") {
      setIsDateError("Please fill the field");
    }
    if (doneBy === "") {
      setIsDoneByError("Please fill the field");
    }
    if (assetName === "") {
      setIsAssetNameError("Please fill the field");
    }
    if (!hasEmptyStatus) {
      setIsParameterError(
        "Please select at least one parameter as 'Pass' or 'Fail'"
      );
      return;
    }

    setSubmittedName(doneBy);
    setSubmitDate(dayjs().format("YYYY-MM-DD"));

    if (
      isAssetNameError === "" &&
      isDateError === "" &&
      isDoneByError === "" &&
      isModelNumberError === "" &&
      isSerialNumberError === ""
    ) {
      if (assetName !== "" && startDate !== "" && endDate !== "") {
        let data = {
          userid: userIds?.userid,
          branchname: userBranch.branch,
          hospname: userIds?.hospname,
          username: userIds?.username,
          hospid: userIds?.hospid,
          hosp_name: userIds?.hospname,
          asset_name: assetName,
          asset_id: assetId,
          asset_code: assetCode,
          brand: brand,
          serial_no: serialNumber,
          model_no: modelNumber,
          department: singleDepartment,
          pm_done_date: formattedStartDate,
          pm_due_date: formattedEndDate,
          remarks: remark,
          done_by: doneBy,
          department_id: departmentId,
          parameter: equipmentDetails.flatMap((detail) => detail.parameter),
          branchid: userBranch.id,
          power_supply: observedValues,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
        setFlag(true);
        setIsParameterError("");
        setIframeData(data);
        setIsRemarkDisabled(true);
        dispatch(postPmCheckList(data, token));
      }
    }
  };

  const handleDownload = () => {
    const pdf = new jsPDF();
    pdf.setFillColor(255, 251, 244);
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(16);
    pdf.text(
      "DEPARTMENT OF BIOMEDICAL ENGINEERING",
      pdf.internal.pageSize.width / 2,
      15,
      "center"
    );

    pdf.setFontSize(16);
    pdf.text("PM CHECKLIST", pdf.internal.pageSize.width / 2, 25, "center");

    pdf.setFontSize(12);
    pdf.setTextColor(14, 79, 205);
    pdf.text(`Asset Name:`, 20, 40);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.asset_name}`, 70, 40);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Serial Number:`, 20, 50);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.serial_no}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Model Number:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.model_no}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.department}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Done Date:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.pm_done_date}`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Due Date:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.pm_due_date}`, 70, 90);

    let startY = 100;

    // Add parameter table
    if (iframeData.parameter.length > 0) {
      const parameterData = iframeData.parameter.map((param) => [
        param.name,
        param.status,
      ]);

      pdf.autoTable({
        head: [["Parameter Name", "Status"]],
        body: parameterData,
        startY: startY,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });

      startY = pdf.autoTable.previous.finalY + 10;
    }

    // Add power supply details
    if (iframeData?.power_supply?.length > 0) {
      const powerSupplyData = iframeData.power_supply.map((supply) => [
        supply.supplyParameter,
        supply.selectedValue,
        supply.observedValue,
      ]);

      pdf.autoTable({
        head: [["Power Supply Parameter", "Selected Value", "Observed Value"]],
        body: powerSupplyData,
        startY: startY,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });

      startY = pdf.autoTable.previous.finalY + 10;
    }

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Remarks:`, 20, startY);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.remarks}`, 70, startY);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Done By:`, 20, startY + 10);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.done_by}`, 70, startY + 10);

    pdf.save("PMChecklist.pdf");
  };

  //   useEffect(() => {
  // if(check?.length>0){
  //   setIframeData({...iframeData,...check[0]})
  // }

  //   }, [check]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const AssetData = {
    "Serial No.": serialNumber,
    Department: singleDepartment,
    "Model No.": modelNumber,
    Brand: brand,
  };
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "auto",
        marginTop: "50px",
      }}
    >
      <OuterSet>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "15px",
          }}
        >
          <Label variant="h6" color="primary">
            Record PM Checklist
          </Label>
          {submittedName && (
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              By: {submittedName}
            </Typography>
          )}
          {submitDate && (
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              On: {submitDate}
            </Typography>
          )}
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px", minHeight: "350px" }}>
          <Stack sx={{ width: "100%", height: "100%" }}>
            {isMobile && (
              <Stepper activeStep={activeStep} nonLinear>
                <Step key={0}>
                  <StepLabel>Step 1</StepLabel>
                </Step>
                <Step key={1}>
                  <StepLabel>Step 2</StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel>Step 3</StepLabel>
                </Step>
              </Stepper>
            )}
            {activeStep === 0 && (
              <>
                <FirstSet>
                  <InputSet>
                    <Autocomplete
                      value={equipment}
                      options={equipments}
                      getOptionLabel={(option) => option.equipment}
                      // value={selectedDepartment}
                      onChange={(e, newValue) => {
                        setEquipment(newValue);
                        if (newValue) {
                          const selectedEquipment = equipmentData.find(
                            (item) => item.equipment === newValue.equipment
                          );
                          if (selectedEquipment) {
                            setEquipmentDetails(
                              selectedEquipment.equipmentContent || []
                            );
                          } else {
                            setEquipmentDetails([]);
                          }
                        } else {
                          setEquipmentDetails([]);
                        }
                      }}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          label="Choose Equipment *"
                          variant="filled"
                          sx={{ width: "100%" }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            sx: {
                              "& input:focus": {
                                backgroundColor: "transparent",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </InputSet>

                  {/* <InputSet>
                    <Autocomplete
                      disabled={!color}
                      sx={{ bgcolor: "#EFF5FE" }}
                      value={color && dropdown}
                      options={
                        color &&
                        department?.equipment?.map((option) => option.name)
                      }
                      onChange={handleEquipment}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          label="Choose Equipment"
                          variant="filled"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            sx: {
                              "& input:focus": {
                                backgroundColor: "transparent",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </InputSet> */}
                </FirstSet>

                <Stack
                  sx={{
                    alignItems: "flex-end",
                    padding: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: "150px", borderRadius: "22px" }}
                    disabled={!equipment}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Stack>
              </>
            )}
            {activeStep === 1 && (
              <>
                <FirstSet>
                  <InputSet>
                    <Label sx={{ textAlign: "left" }}>
                      Asset Details{" "}
                      <span style={{ color: "red", fontWeight: "100" }}>*</span>
                    </Label>
                    <Autocomplete
                      sx={{ bgcolor: "#EFF5FE" }}
                      id="free-solo-demo"
                      value={assetName}
                      onChange={handleAssetName}
                      placeholder="e.g. ventilator"
                      options={getAllData?.map((option) => ({
                        label: `${option?.name} - ${option?.serialno} - ${option?.assetcode}`,
                        value: option?.assetid,
                      }))}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Asset Name" />
                      )}
                    />
                    {assetName && (
                      <Box
                        sx={{
                          width: "100%",

                          bgcolor: "rgba(244, 246, 246, 1)",
                        }}
                      >
                        <TableContainer
                          style={{ overflowX: "auto", maxWidth: "100%" }}
                          sx={{}}
                        >
                          <Table sx={{ maxWidth: "100%" }}>
                            <TableBody>
                              {Object.entries(AssetData).map(([key, value]) => (
                                <TableRow key={key}>
                                  <TableCells
                                    component="th"
                                    scope="row"
                                    sx={{ color: "rgba(27, 37, 53, 1)" }}
                                  >
                                    {key}
                                  </TableCells>

                                  <TableCells sx={{}}>{value}</TableCells>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    )}
                  </InputSet>
                  <InputSet
                    sx={{ width: "50%", gap: "20px", textAlign: "start" }}
                  >
                    <Label>
                      Incharge{" "}
                      <span style={{ color: "red", fontWeight: "100" }}>*</span>
                    </Label>
                    <Stack>
                      <Field
                        label="Name"
                        variant="filled"
                        placeholder="e.g. Joseph"
                        onChange={handleDoneBy}
                        value={doneBy}
                        InputProps={{ disableUnderline: true }}
                      />
                      {<HelperText>{isDoneByError}</HelperText>}
                    </Stack>

                    <Stack>
                      <Label>
                        PM Date{" "}
                        <span style={{ color: "red", fontWeight: "100" }}>
                          *
                        </span>{" "}
                      </Label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="PM Done Date"
                          slotProps={{
                            textField: {
                              error: false,
                              variant: "filled",
                            },
                          }}
                          format="DD-MM-YYYY"
                          sx={{
                            border: "none",
                            backgroundColor: "rgba(244, 246, 246, 1)",
                            "& label.Mui-focused": {
                              color: "rgba(166, 166, 166, 1)",
                            },
                            "& .MuiFilledInput-root": {
                              backgroundColor: "rgba(244, 246, 246, 1)",
                            },
                            "&:hover .MuiFilledInput-root": {
                              backgroundColor: "rgba(244, 246, 246, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          value={startDate}
                          onChange={hanldeStartDate}
                          InputProps={{ disableUnderline: true }}
                        />
                      </LocalizationProvider>
                      {/* {<HelperText>{isDateError}</HelperText>} */}
                    </Stack>
                    <Stack>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="PM Due Date"
                          slotProps={{
                            textField: {
                              error: false,
                              variant: "filled",
                            },
                          }}
                          format="DD-MM-YYYY"
                          sx={{
                            border: "none",
                            backgroundColor: "rgba(244, 246, 246, 1)",
                            "& label.Mui-focused": {
                              color: "rgba(166, 166, 166, 1)",
                            },
                            "& .MuiFilledInput-root": {
                              backgroundColor: "rgba(244, 246, 246, 1)",
                            },
                            "&:hover .MuiFilledInput-root": {
                              backgroundColor: "rgba(244, 246, 246, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          value={endDate}
                          onChange={hanldeEndDate}
                          InputProps={{ disableUnderline: true }}
                        />
                      </LocalizationProvider>
                      {<HelperText>{isDateError}</HelperText>}
                    </Stack>
                  </InputSet>
                </FirstSet>

                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px",
                    // width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <KeyboardBackspaceIcon
                    size={20}
                    onClick={handleBack}
                    style={{ color: "#4690FF", cursor: "pointer" }}
                  />

                  <Button
                    variant="contained"
                    sx={{ width: "150px", borderRadius: "22px" }}
                    disabled={
                      // !selectedDepartment ||
                      !assetName || !startDate || !endDate || isDateError
                    }
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Stack>
              </>
            )}
            {activeStep === 2 && equipmentDetails.length > 0 && (
              <Stack sx={{ gap: "20px" }}>
                {equipmentDetails[0]?.parameter?.length > 0 && (
                  <Stack sx={{ marginTop: "30px" }}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label sx={{ textAlign: "start", padding: "10px" }}>
                        Parameters{" "}
                        <span style={{ color: "red", fontWeight: "100" }}>
                          *
                        </span>
                      </Label>
                      <Stack
                        sx={{
                          width: "auto",
                          bgcolor: "#1B2535",
                          height: "27px",
                          textDecoration: "none",
                          borderRadius: "20px",
                          borderRight: "20px",
                          color: "white",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#1B2535",
                            color: "white",
                          },
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px",
                        }}
                        onClick={handleOpen}
                      >
                        <AddIcon style={{ color: "white", fontSize: "20px" }} />
                        <Stack>Add Paramaters</Stack>
                      </Stack>
                    </Stack>

                    {equipmentDetails[0]?.parameter.map((item, index) => (
                      <ParaBox key={index}>
                        {item?.heading ? (
                          <Label sx={{ textAlign: "start", padding: "10px" }}>
                            {item.heading}
                          </Label>
                        ) : (
                          <>
                            <Typography
                              sx={{
                                bgcolor: "#F4F6F6",
                                width: "100%",
                                height: "100%",
                                textAlign: "start",
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                              }}
                            >
                              {item?.name}
                            </Typography>

                            <Stack
                              sx={{
                                width: "100%",
                                flexDirection: "row",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant={
                                  item.status === "Pass"
                                    ? "contained"
                                    : "outlined"
                                }
                                sx={{
                                  borderRadius: "22px",
                                  bgcolor:
                                    item.status !== "Pass"
                                      ? "white"
                                      : "#4690FF",
                                  width: "100px",
                                }}
                                onClick={() =>
                                  handleChangeStatus(index, "Pass")
                                }
                                disabled={
                                  pmChecklistData?.reportId !== undefined &&
                                  flag
                                }
                              >
                                Pass
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "22px",
                                  bgcolor:
                                    item.status !== "Fail"
                                      ? "white"
                                      : "#4690FF",
                                  width: "100px",
                                }}
                                variant={
                                  item.status === "Fail"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() =>
                                  handleChangeStatus(index, "Fail")
                                }
                                disabled={
                                  pmChecklistData?.reportId !== undefined &&
                                  flag
                                }
                              >
                                Fail
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "22px",
                                  bgcolor:
                                    item.status !== "N/A" ? "white" : "#4690FF",
                                  width: "100px",
                                }}
                                variant={
                                  item.status === "N/A"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => handleChangeStatus(index, "N/A")}
                                disabled={
                                  pmChecklistData?.reportId !== undefined &&
                                  flag
                                }
                              >
                                N/A
                              </Button>
                            </Stack>
                          </>
                        )}
                      </ParaBox>
                    ))}
                    {equipmentDetails[0]?.powerSupplyParams?.length > 0 && (
                      <>
                        <Divider sx={{ marginTop: "10px" }} />
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            marginLeft: "10px",
                          }}
                        >
                          <h4>Power Supply</h4>
                        </Stack>
                      </>
                    )}

                    {equipmentDetails[0]?.powerSupplyParams?.map(
                      (supply, index) => (
                        <>
                          <Box key={index} sx={{ marginLeft: "12px" }}>
                            <SubTitle
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {supply.supplyParameter}
                            </SubTitle>
                            {supply.values.map((item, idx) => (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  mt: 1,
                                }}
                              >
                                <ItemText
                                  sx={{
                                    textTransform: "none",
                                    color: "#4690FF",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.value}
                                </ItemText>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                  <TextField
                                    label="Enter Observed Value"
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "50%" }}
                                    type="number"
                                    disabled={isRemarkDisabled}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value !== "0") {
                                        handleObservedChange(
                                          supply.supplyParameter,
                                          item.value,
                                          value
                                        );
                                      }
                                    }}
                                    value={
                                      observedValues.find(
                                        (val) =>
                                          val.selectedValue === item.value
                                      )?.observedValue || ""
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </>
                      )
                    )}

                    {/* 
                    {equipmentDetails[0]?.powerSupply !== undefined &&
                      equipmentDetails[0]?.powerSupply?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <ItemText>{item?.supplyParameter}</ItemText>
                          <ItemText>{item?.selectedValue}</ItemText>

                          <RadioContainer sx={{ width: "40%" }}>
                            <OutlinedInput
                              onChange={(e) =>
                                handleChangeObservedValue(e, index)
                              }
                              value={item.observerdValue}
                              placeholder="value..."
                            />
                          </RadioContainer>
                        </Box>
                      ))} */}
                  </Stack>
                )}

                <Stack
                  sx={{
                    marginTop: "5px",

                    width: "100%",
                  }}
                >
                  <FormElement sx={{ textAlign: "start" }}>
                    <Label sx={{ padding: "10px" }}>Remark</Label>
                    <Field
                      multiline
                      rows={3}
                      sx={{}}
                      label="Remark"
                      value={remark}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      onChange={handleRemark}
                      disabled={isRemarkDisabled}
                    />
                    {<HelperText>{isRemarkError}</HelperText>}
                  </FormElement>
                </Stack>
                {<HelperText>{isParameterError}</HelperText>}
                <Modal open={open} onClose={handleClose}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "white",
                      boxShadow: 24,
                      p: 3,
                      borderRadius: "10px",
                      width: 300,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      disabled={isRemarkDisabled}
                    >
                      <h3>Add Parameters</h3>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Stack>

                    <TextField
                      fullWidth
                      label="Enter Name"
                      variant="outlined"
                      value={inputValue}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z0-9';.()/;: _]*$/;
                        if (
                          regex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setInputValue(e.target.value);
                        }
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddSubmit}
                      disabled={isRemarkDisabled}
                    >
                      Submit
                    </Button>
                  </Box>
                </Modal>


                {/* <Divider />
                <Stack
                  sx={{
                    display: "flex",
                    marginLeft: "12px",
                    alignItems: "start",
                  }}
                >
                  <h3 style={{ margin: "0" }}>Voice Record</h3>
                </Stack>
                <Stack>
                  <Stack>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "10px",
                        mb: "25px",
                      }}
                    >
                      <Button
                        onClick={startRecording}
                        disabled={recording}
                        variant="contained"
                        sx={{ borderRadius: "20px" }}
                      >
                        <KeyboardVoiceIcon /> Start Recording
                      </Button>
                      <Button
                        onClick={stopRecording}
                        disabled={!recording}
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          "&:hover": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        <StopCircleIcon /> Stop Recording
                      </Button>
                    </Stack>

                    {recording && (
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: "20px",
                        }}
                      >
                        <>
                          {" "}
                          <ShutterSpeedIcon />{" "}
                        </>
                        Recording: {recordingTime}s
                      </Stack>
                    )}

                    {audioUrl && (
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <audio controls src={audioUrl}></audio>
                        <Button
                          onClick={() => setAudioUrl(null)}
                          variant="contained"
                          sx={{
                            borderRadius: "30px",
                            height: "fit-content",
                            mt: "8px",
                            "&:hover": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          <DeleteForeverIcon /> Delete Recording
                        </Button>
                      </Stack>
                    )}
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      marginLeft: "12px",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <h3 style={{ margin: "0" }}>Draw Manual Signature</h3>
                  </Stack>
                  {showSignatureCanvas && (
                    <Stack sx={{ display: "flex", alignItems: "center" }}>
                      <div style={{ margin: "10px", justifyContent: "center" }}>
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor="black"
                          canvasProps={{
                            width: canvasWidth,
                            height: 200,
                            className: "sigCanvas",
                            style: {
                              border: "2px solid #1B2535",
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </div>
                    </Stack>
                  )}

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={clearSignature}
                      variant="contained"
                      sx={{
                        borderRadius: "30px",
                        height: "fit-content",
                        mt: "8px",
                      }}
                    >
                      <RefreshIcon />
                      Reset
                    </Button>
                    {showSignatureCanvas && (
                      <Button
                        onClick={saveSignature}
                        variant="contained"
                        sx={{
                          borderRadius: "30px",
                          height: "fit-content",
                          mt: "8px",
                          "&:hover": {
                            backgroundColor: "#4690FF",
                            color: "white",
                          },
                        }}
                      >
                        <DoneIcon />
                        Done
                      </Button>
                    )}
                  </Stack>

                  {savedSignature && (
                    <Stack sx={{ display: "flex", alignItems: "center" }}>
                      <h3 style={{ margin: "20px" }}>Saved Signature</h3>
                      <img
                        src={savedSignature}
                        alt="Saved Signature"
                        style={{
                          height: "200px",
                          width: "500px",
                          borderRadius: "10px",
                          border: "2px solid #1B2535",
                        }}
                      />
                    </Stack>
                  )}
                </Stack> */}
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <KeyboardBackspaceIcon
                    size={20}
                    onClick={handleBack}
                    style={{ color: "#4690FF", cursor: "pointer" }}
                  />

                  {pmChecklistData?.reportId !== undefined && flag ? (
                    <PdfButton
                      onClick={handleDownload}
                      sx={{
                        "&:hover": {
                          transform: "scale3d(1.05, 1.05, 2)",
                          cursor: "pointer",
                          color: "#F78117",
                        },
                      }}
                    >
                      Download PDF
                    </PdfButton>
                  ) : (
                    <>
                      <Button
                        sx={{ borderRadius: "22px", width: "150px" }}
                        onClick={handleSubmit}
                        disabled={userAccess?.role === "Guest"}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
      </OuterSet>
    </Stack>
  );
};

export default MyComponent;
