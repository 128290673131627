import {
  Typography,
  Box,
  FormControl,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  FormHelperText,
  AppBar,
  Toolbar,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Badge,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import feature1 from "../images/feature1.png";
import feature2 from "../images/feature2.png";
import feature3 from "../images/feature3.png";
import feature4 from "../images/feature4.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  postAuth,
  uploadProfileImage,
  deleteProfileImage,
} from "../redux/auth/action";
import { useSelector } from "react-redux";
import Navbar4 from "../components/Navbar4";
import Footer2 from "../components/Footer2";
import styled from "@emotion/styled";
import Progress from "../components/Progress";
import FooterMain from "./FooterMain";
import NavbarMain from "./NavbarMain";
import { postRegister } from "../redux/data/action";
import { v4 as uuidv4 } from "uuid";
import { v4 as uuid } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";

import RegisterModal from "./RegisterModal";

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "row",
  paddingTop: "20px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  gap: "25px",
  height: "100%",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    height: "1600px",
    marginTop: "60px",
    marginBottom: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "120%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox = styled(Box)(({ theme }) => ({
  width: "560px",
  height: "600px",

  [theme.breakpoints.down("xl")]: {
    width: "430px",
    height: "500px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "380px",
    height: "500px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "340px",
    height: "500px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "330px",
    height: "500px",
  },
}));

const RightBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  width: "530px",
  minHeight: "570px",

  [theme.breakpoints.down("xl")]: {
    width: "430px",
    minHeight: "500px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "380px",
    minHeight: "502px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  width: "100%",
}));

const CustomLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "rgba(192, 192, 192, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "50px",
  fontSize: "18px",
  [theme.breakpoints.down("xl")]: {
    width: "200px",
    height: "40px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "26px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "20px",
  marginBottom: "10px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  width: "300px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

function SignUpPage() {
  const registerdata = useSelector((store) => store.data.register);
  const isLoading = useSelector((store) => store.data.isLoading);
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  let userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const [other, setOther] = useState("");
  const [cityName, setCityName] = useState("");
  const [cityNameError, setCityNameError] = useState("");
  const [errors, setErrors] = useState({});

  const [hospitalType, setHospitalType] = useState("");
  const [hospitalTypeError, setHospitalTypeError] = useState("");

  const [stateName, setStateName] = useState("");
  const [stateNameError, setStateNameError] = useState("");
  const [otherError, setOtherError] = useState("");
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [branches, setBranches] = useState([
    { id: uuidv4().slice(-12), name: "", rid: "" },
  ]);

  const [branchError, setBranchError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleCityName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;
    if (value === "" || regex.test(value)) {
      setCityName(value);
      setCityNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setCityNameError("Invalid city name");
    }
    if (value === "") {
      setCityNameError("Please fill the field");
    }
  };

  const handleStateName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;
    if (value === "" || regex.test(value)) {
      setStateName(value);
      setStateNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setStateNameError("Invalid State name");
    }
    if (value === "") {
      setStateNameError("Please fill the field");
    }
  };

  const handleHospitalType = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      setHospitalType(value);
      setHospitalTypeError("");
      setOtherError("");
    }
    if (value !== "" && !regex.test(value)) {
      setHospitalTypeError("Invalid Hospital type");
    }
    if (value === "") {
      setHospitalTypeError("Please fill the field");
    }
    if (value === "Others") {
      setOtherError("Fill the type here");
    }
  };

  const handleOthers = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      if (hospitalType === "Others") {
        setOther(value);
        setOtherError("");
      }
    }
    if (value === "") {
      setOtherError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value.trim();

    const regex = /^\d{10}$/;

    setPhone(value);
    if (regex.test(value)) {
      setPhoneError("");
    }
    if (!regex.test(value)) {
      setPhoneError("Invalid phone number");
    }
    if (value === "") {
      setPhoneError("Please fill the field");
    }
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    setAddress(value);
    if (!value) {
      setAddressError("Address is required");
    } else {
      setAddressError("");
    }
  };

  const handlePin = (e) => {
    const value = e.target.value.trim();
    setPin(value);
    const pinCodeRegex = /^\d{6}$/;
    if (!value || !pinCodeRegex.test(value)) {
      setPinError("Please enter valid pin");
    } else {
      setPinError("");
    }
  };

  //   const handleBranch = () => {
  //     const trimmedBranchNames = branches.map((branch) => branch.name.trim());

  //     if (trimmedBranchNames.some((name) => name === "")) {
  //       setBranchError("Please fill the field.");
  //       return;
  //     }

  //     setBranches((prevBranches) => [
  //       ...prevBranches,
  //       { id: uuidv4().slice(-12), name: "", rid: "" },
  //     ]);
  //     setBranchError("");
  //   };

  //   const removeBranch = (idToRemove) => {
  //     setBranches((prevBranches) =>
  //       prevBranches.filter((branch) => branch.id !== idToRemove)
  //     );
  //     setBranchError("");
  //   };

  //   const handleInputChange = (id, value) => {
  //     const regex = /^[a-zA-Z0-9 ]+$/;

  //     const isNameDuplicate = branches.some(
  //       (branch) =>
  //         branch.id !== id &&
  //         branch.name.trim().toLowerCase() === value.trim().toLowerCase()
  //     );

  //     setBranches((prevBranches) =>
  //       prevBranches.map((branch) =>
  //         branch.id === id
  //           ? {
  //               ...branch,
  //               name: value,
  //               rid: `${value.replace(/\s/g, "").substring(0, 4)}${id}`,
  //             }
  //           : branch
  //       )
  //     );

  //     if (value === "" || regex.test(value)) {
  //       if (isNameDuplicate) {
  //         setBranchError("Branch with this name already exists.");
  //       } else {
  //         setBranchError("");
  //         setBranches((prevBranches) =>
  //           prevBranches.map((branch) =>
  //             branch.id === id
  //               ? {
  //                   ...branch,
  //                   name: value,
  //                   rid: `${value.replace(/\s/g, "").substring(0, 4)}${id}`,
  //                 }
  //               : branch
  //           )
  //         );
  //       }
  //     } else {
  //       setBranchError("Please fill the field.");
  //     }
  //   };
  const handleBranch = () => {
    const trimmedBranchNames = branches.map((branch) => branch.name.trim());

    if (trimmedBranchNames.some((name) => name === "")) {
      setBranchError("Please fill all fields.");
      return;
    }

    setBranches((prevBranches) => [
      ...prevBranches,
      { id: uuidv4().slice(-12), name: "", rid: "" },
    ]);
    setBranchError("");
  };

  const removeBranch = (idToRemove) => {
    setBranches((prevBranches) =>
      prevBranches.filter((branch) => branch.id !== idToRemove)
    );
    setBranchError("");
  };

  // const handleInputChange = (id, value) => {
  //   const regex = /^[a-zA-Z0-9 ]+$/;

  //   const isNameDuplicate = branches.some(
  //     (branch) =>
  //       branch.id !== id &&
  //       branch.name.trim().toLowerCase() === value.trim().toLowerCase()
  //   );

  //   setBranches((prevBranches) =>
  //     prevBranches.map((branch) =>
  //       branch.id === id
  //         ? {
  //             ...branch,
  //             name: value,
  //             rid: `${value.replace(/\s/g, "").substring(0, 4)}${id}`,
  //           }
  //         : branch
  //     )
  //   );

  //   if (value === "" || regex.test(value)) {
  //     if (isNameDuplicate) {
  //       setBranchError("Branch with this name already exists.");
  //     } else {
  //       setBranchError("");
  //     }
  //   } else {
  //     setBranchError("Please fill the field.");
  //   }
  // };

  const handleInputChange = (id, value) => {
    const regex = /^[a-zA-Z0-9 /-]+$/;

    const isNameDuplicate = branches.some(
      (branch) =>
        branch.id !== id &&
        branch.name.trim().toLowerCase() === value.trim().toLowerCase()
    );

    const isOnlyNumbers = /^[0-9]+$/.test(value);

    setBranches((prevBranches) =>
      prevBranches.map((branch) =>
        branch.id === id
          ? {
              ...branch,
              name: value,
              rid: `${value.replace(/\s/g, "").substring(0, 4)}${id}`,
            }
          : branch
      )
    );

    if (value === "") {
      setBranchError("Please fill the field.");
    } else if (isOnlyNumbers) {
      setBranchError("Branch name cannot be only numbers.");
    } else if (!regex.test(value)) {
      setBranchError("Please fill the field with valid characters.");
    } else if (isNameDuplicate) {
      setBranchError("Branch with this name already exists.");
    } else {
      setBranchError("");
    }
  };

  const handleRegister = () => {
    let hasError = false;

    if (!fileUrl || fileUrl.length === 0) {
      setIsPhotoError("Please upload an image");
      hasError = true;
    }

    if (cityName === "") {
      setCityNameError("Please fill the field");
      hasError = true;
    }
    if (hospitalType === "") {
      setHospitalTypeError("Please fill the field");
      hasError = true;
    }
    if (stateName === "") {
      setStateNameError("Please fill the field");
      hasError = true;
    }
    if (phone === "") {
      setPhoneError("Please fill the field");
      hasError = true;
    } else if (phone.length !== 10) {
      setPhoneError("Phone number must be 10 digits");
      hasError = true;
    }
    if (address === "") {
      setAddressError("Please fill the field");
      hasError = true;
    }
    if (pin === "") {
      setPinError("Please fill the field");
      hasError = true;
    }

    const trimmedBranchNames = branches.map((branch) => branch.name.trim());

    if (trimmedBranchNames.some((name) => name === "")) {
      setBranchError("Please fill the field.");
      hasError = true;
    }

    // Stop execution if there are any validation errors
    if (hasError) return;

    const receiptId =
      fileUrl?.length > 0
        ? fileUrl.map((item) => item.imageid).join(",")
        : "NA";

    let postData = {
      userid: userData.userid,
      hospid: userData.hospid,
      city: cityName.trim(),
      hosptype: hospitalType === "Others" ? other : hospitalType.trim(),
      state: stateName.trim(),
      mobile: phone.trim(),
      zip: pin.trim(),
      address: address.trim(),
      branchnames: branches?.map((item) => item.name),
      branchids: branches?.map((item) => item.rid),
      logo_id: receiptId,
    };

    dispatch(postRegister(postData))
      .then(() => {
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };

  useEffect(() => {
    if (registerdata === "AUTHENTICATION_ERROR") {
      navigate("/signin");
      window.location.reload();
    }
  }, [registerdata]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRegister(e);
    }
  };

  const handlePhotoUpload = (e) => {
    const newFiles = Array.from(e.target.files).filter((file) => {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        setIsPhotoError(`Can't upload ${file.type.split("/")[1]} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsPhotoError(`Can't upload image more than 4MB.`);
        return false;
      }

      setIsPhotoError(null);
      return true;
    });

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let sendData = {
          hospid: userData?.hospid,
          userid: userData?.userid,
          imageid: `${uuid().replace(/-/g, "").substring(0, 12)}.${
            file.type.split("/")[1]
          }`,
          image: reader.result,
        };

        setFileUrl((prev) => [...prev, sendData]);
        dispatch(uploadProfileImage(sendData, token));
      };
      reader.readAsDataURL(file);
    });
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userData.hospid,
      userid: userData.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    dispatch(deleteProfileImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        bgcolor: "white",
      }}
    >
      <NavbarMain />

      <Stack
        sx={{
          flex: 1,

          justifyContent: "center",
          alignItems: "center",
          textAlign: "start",
        }}
      >
        <Stack
          sx={{
            width: "80%",
            justifyContent: "center",
            p: 5,
            minHeight: "calc(100vh - 150px)",

            flexDirection: "row",
            gap: "30px",
          }}
        >
          <Stack
            sx={{
              width: "100%",

              boxShadow: "0px 0px 10px 0px rgba(219, 231, 229, 1)",
              padding: "25px",
              gap: "20px",
            }}
          >
            <Typography sx={{ fontWeight: 700 }} variant="h6">
              Register to start!
            </Typography>
            <Divider sx={{ background: "#E7E7E7", width: "100%" }} />
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",

                height: "100%",

                alignItems: "center",
                gap: "20px",
              }}
            >
              <Stack
                sx={{
                  height: "100%",
                  justifyContent: "start",

                  width: "50%",
                  textAlign: "start",
                  gap: "15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Hospital
                </Typography>
                <Stack sx={{}}>
                  <CustomLabel>Hospital Type</CustomLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Hospital Type"
                    sx={{
                      textAlign: "start",

                      "& fieldset": { border: "none" },

                      bgcolor: "#F4F6F6",
                    }}
                    displayEmpty
                    value={hospitalType}
                    onChange={handleHospitalType}
                  >
                    <MenuItem
                      placeholder="clinic"
                      value={""}
                      sx={{ color: "#a9a9a9" }}
                    >
                      Select Hospital Type
                    </MenuItem>
                    <MenuItem value={"Super Speciality"}>
                      Super Speciality
                    </MenuItem>
                    <MenuItem value={"Multi Speciality"}>
                      Multi Speciality
                    </MenuItem>
                    <MenuItem value={"Clinic"}>Clinic</MenuItem>{" "}
                    <MenuItem value={"Private Hospital"}>
                      Private Hospital
                    </MenuItem>
                    <MenuItem value={"Government Hospital"}>
                      Government Hospital
                    </MenuItem>
                    <MenuItem value={"NGO"}>NGO</MenuItem>
                    <MenuItem value={"Nursing Home"}>Nursing Home</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>
                  <HelperText>{hospitalTypeError}</HelperText>
                  {hospitalType === "Others" && (
                    <>
                      {" "}
                      <Field
                        size="small"
                        sx={{ bgcolor: "#EFF5FE" }}
                        value={other}
                        onChange={handleOthers}
                        placeholder="Enter the type"
                      />
                      {
                        <HelperText
                          sx={{
                            color:
                              otherError === "Fill the type here" && "#1746A2",
                          }}
                        >
                          {otherError}
                        </HelperText>
                      }{" "}
                    </>
                  )}
                </Stack>
                <Stack>
                  <CustomLabel>Whatsapp No</CustomLabel>
                  <Field
                    value={phone}
                    type="number"
                    onKeyPress={handleKeyPress}
                    placeholder="Whatsapp Number"
                    onChange={handlePhone}
                  />
                  <HelperText>{phoneError}</HelperText>
                </Stack>
                <Stack>
                  <CustomLabel>City</CustomLabel>
                  <Field
                    value={cityName}
                    onKeyPress={handleKeyPress}
                    placeholder="City"
                    onChange={handleCityName}
                  />
                  <HelperText>{cityNameError}</HelperText>
                </Stack>
                <Stack>
                  <CustomLabel>Address</CustomLabel>
                  <Field
                    value={address}
                    onKeyPress={handleKeyPress}
                    placeholder="e.g. 123 Main Street, City, Country"
                    onChange={handleAddress}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton disabled>
                          <EmailOutlinedIcon sx={{ color: "#787878" }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <HelperText>{addressError}</HelperText>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  height: "100%",
                  justifyContent: "start",

                  width: "50%",
                  textAlign: "start",
                  gap: "15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 700, color: "white" }}
                >
                  .
                </Typography>

                <Stack>
                  <CustomLabel>State</CustomLabel>
                  <Field
                    value={stateName}
                    onKeyPress={handleKeyPress}
                    placeholder="State"
                    onChange={handleStateName}
                  />
                  <HelperText>{stateNameError}</HelperText>
                </Stack>

                <Stack sx={{}}>
                  <CustomLabel>Pin</CustomLabel>
                  <Field
                    value={pin}
                    placeholder="Pin"
                    onChange={handlePin}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton disabled>
                          <LocationCityOutlinedIcon sx={{ color: "#787878" }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <HelperText>{pinError}</HelperText>
                </Stack>

                <Stack sx={{}}>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomLabel>Add Branch</CustomLabel>

                    <AddIcon
                      onClick={handleBranch}
                      style={{ cursor: "pointer" }}
                    />
                  </Stack>
                  {branches.map((branch, index) => (
                    <Stack key={branch.id} sx={{}}>
                      <Stack
                        sx={{
                          marginBottom: "15px",
                        }}
                      >
                        <Field
                          placeholder="Branch"
                          value={branch.name}
                          onChange={(e) =>
                            handleInputChange(branch.id, e.target.value)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {branches.length > 1 && (
                                  <IconButton
                                    onClick={() => removeBranch(branch.id)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      {index === branches.length - 1 && (
                        <HelperText>{branchError}</HelperText>
                      )}
                    </Stack>
                  ))}
                </Stack>
                <Stack
                  sx={{
                    marginTop: "10px",
                    paddingLeft: "10px",
                    gap: "10px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box>
                      <Label
                        variant="subtitle1"
                        sx={{ color: "rgba(70, 144, 255, 1)" }}
                      >
                        Upload Hospital Logo{" "}
                      </Label>
                    </Box>
                    <Box>
                      {uploadedImageName?.length < 1 && (
                        <>
                          <>
                            <label for="img">
                              <UploadIcon
                                size={20}
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  cursor: "pointer",
                                }}
                              />
                            </label>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img"
                              style={{
                                display: "none",
                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        </>
                      )}
                    </Box>
                  </Stack>
                  <Stack sx={{ flexDirection: "row", gap: "10px" }}>
                    {fileUrl?.map((item, index) => (
                      <Badge
                        key={index}
                        badgeContent={
                          <CloseIcon
                            style={{
                              color: "rgba(255, 58, 68, 1)",
                            }}
                            onClick={() => removePhoto(item?.imageid)}
                          />
                        }
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                          border: "0.5px solid rgba(27, 37, 53, 1)",
                          padding: "5px",
                          width: "max-content",
                        }}
                      >
                        <Box
                          as="img"
                          src={item?.image}
                          alt=""
                          sx={{ width: "70px", height: "90px" }}
                        />
                      </Badge>
                    ))}
                  </Stack>

                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "end",
                      justifyContent: "space-between",
                    }}
                  >
                    <HelperText sx={{ color: "red" }}>
                      {isPhotoError}
                    </HelperText>
                    {errors.fileUrl && (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.fileUrl}
                      </FormHelperText>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider sx={{ background: "#E7E7E7", width: "100%" }} />
            <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
              <Button
                onClick={handleRegister}
                variant="contained"
                sx={{ borderRadius: "22px", width: "150px" }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <FooterMain />
      <RegisterModal
        handleClose={handleClose}
        status={registerdata}
        open={open}
      />
    </Box>
  );
}

export default SignUpPage;
