import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Stack,
  FormHelperText,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Input,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FileUploadIcon from "@mui/icons-material/FileUpload";


import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "jspdf-autotable";
import { useEffect } from "react";
import {
  addAccesoryUploadImageHistory,
  deleteImageAddAccessoryHistory,
  deleteProfileImage,
  profile,
  updateProfileImage,
  uploadProfileImage,
} from "../redux/data/action";
import EditIcon from "@mui/icons-material/Edit";
import { v4 as uuid } from "uuid";
import CloseIcon from "@mui/icons-material/Close";

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const ProfilePage = () => {
  const [isPhotoErrorHistory, setIsPhotoErrorHistory] = useState(null);
  const [fileUrlHistory, setFileUrlHistory] = useState([]);

  const navigate = useNavigate();
  const profileData = useSelector((store) => store?.data?.profile);
  // console.log(profileData);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const HospitalLeftData = {
    "Hospital Name": profileData.hospname,
    "Hospital Type": profileData.hosptype,
    Address: profileData.address,
  };
  const HospitalRightData = {
    City: profileData.city,
    State: profileData.state,
    Zip: profileData.zip,
  };
  const UserLeftData = {
    Username: profileData.username,
    Email: profileData.email,
  };
  const UserRightData = {
    Designation: profileData.designate,
    "Whatsapp No": profileData.mobile,
  };
  const SubscriptionData = {
    "Subscription Plan": userIds.subs_plan,
    "Subscription Date": userIds.subs_date,
    "Subscription Expiry": userIds.subs_expdate,
  };

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type;
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (!allowedTypes.includes(fileType)) {
        setIsPhotoErrorHistory(`Only JPEG or PNG files are allowed.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        setIsPhotoErrorHistory("Files greater than 2 MB cannot be uploaded");
        return;
      }

      setIsPhotoErrorHistory(null);

      const reader = new FileReader();

      reader.onloadend =async () => {
        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${uuid()}.${fileType.split("/")[1]}`,
          image: reader.result,
        };

        let profileData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          profile_id: sendData.imageid,
          logo_url: reader.result,
        };

        let newProfile = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          
        }

        if (fileUrlHistory.length > 0) {
          setFileUrlHistory([...fileUrlHistory, sendData]);
          // console.log(fileUrlHistory); 
        } else {
          setFileUrlHistory([sendData]);
        }

       dispatch(uploadProfileImage(sendData, token));
       await dispatch(updateProfileImage(profileData, token));
      await  dispatch(profile(newProfile,token))

      e.target.value = null;
      //  await setFileUrlHistory([...fileUrlHistory, sendData]);
        
      };

      reader.readAsDataURL(singlefile);
    }
  };


  const removePhoto = async (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };
    try {
      // console.log("Deleting image with data:", data);

      await dispatch(deleteProfileImage(data, token));

      const profileData = {
        hospid: userIds.hospid,
        userid: userIds.userid,
      };

      let emptyData = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        logo_url: "",
        profile_id: "",
        userrole:userAccess?.role,
        depart_ids:userAccess.departmentids,
      };

      await dispatch(updateProfileImage(emptyData, token));
      // await  dispatch(profile(profileData,token))

      setFileUrlHistory((prevHistory) =>
        prevHistory.filter((item) => item.imageid !== id)
      );
      // console.log("Image removed and profile updated successfully:", id);
    } catch (error) {
      // console.error("Error removing image:", error);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("file-input").click();
  };



  useEffect(()=>{
    setFileUrlHistory([{image: profileData.logo_url,
      fileName: profileData.logo_id,
      imageid: profileData.logo_id,}]);
  },[profileData])



  useEffect(()=>{
    let newProfile = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    }
    dispatch(profile(newProfile,token))
  },[dispatch])

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}
    >
      <Stack
        sx={{
          width: {
            xs: "100%",  
            sm: "500px  ",
            md: "750px", 
            lg: "750px", 
            // xl: "auto" 
          },

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "10px 20px 10px 20px",
          }}
        >
          <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
            {profileData.hospname}
          </Label>

          {(userAccess?.role === "Super_Admin" ||
            userAccess?.role === "Owner") && (
            <>
              <IconButton onClick={() => navigate("/edit-profile")}>
                <EditIcon style={{ color: "rgba(70, 144, 255, 1)" }} />
              </IconButton>
            </>
          )}
        </Stack>

        <Stack sx={{ bgcolor: "white", padding: "20px" }}>
          <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}
          >
            <Label variant="subtitle1">Hospital Details</Label>
            <Divider />
          </Stack>
          <Stack
            
            sx={{
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(HospitalLeftData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}
                        >
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ width: "100%" }}>
                  <TableBody>
                    {Object.entries(HospitalRightData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)",width:"50%" }}
                        >
                          {key}
                        </TableCells>

                        <TableCells>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}
          >
            <Label variant="subtitle1">User Details</Label>
            <Divider />
          </Stack>
          <Stack
           
            sx={{
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(UserLeftData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}
                        >
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ width: "100%" }}>
                  <TableBody>
                    {Object.entries(UserRightData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}
                        >
                          {key}
                        </TableCells>

                        <TableCells>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}
          >
            <Label variant="subtitle1">Subscription Details</Label>
            <Divider />
          </Stack>

          <Stack
            
            sx={{
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(SubscriptionData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}
                        >
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ width: "100%" }}>
                  <TableBody>
                    <TableRow>
                      <TableCells
                        component="th"
                        scope="row"
                        sx={{ color: "rgba(27, 37, 53, 1)" }}
                      ></TableCells>

                      <TableCells></TableCells>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          <Divider sx={{ width: "100%", margin: "15px 0" }} />
          {/* <Box sx={{ width: "100%" }}>
            <Stack sx={{width:'49%',alignItems:'baseline'}}>
              <Typography sx={{fontWeight:600,marginBottom:'5px'}}>Upload Image (JPEG or PNG)</Typography>
              <Box
                sx={{
                  width: "100%",
                  background: "#f6f6f6",
                  padding: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Input
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    onChange={handlePhotoUpload}
                    sx={{ display: "none" }}
                    id="file-input"
                  />
                  {fileUrlHistory.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 2,
                        position: "relative",
                        width:'100%'
                      }}
                    >
                    {!item.image === 'NA' &&   <Box
                        component="img"
                        src={item.image} 
                        alt={item.fileName}
                        sx={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          width:'100%',
                          height:'auto',
                          borderRadius: "4px",
                          border: "1px solid #ddd",
                        }}
                      />}
                    
                      <IconButton
                      onClick={() => removePhoto(item?.imageid)}
                        size="small"
                        sx={{
                          marginLeft: "10px",
                          position: "absolute",
                          top: "-22px",
                          right: "0",
                        }}
                      >
                        <CloseIcon sx={{ color: "#FF3A44" }} />
                      </IconButton>
                    </Box>
                  ))}
                </Box>

                {(fileUrlHistory.length === 0 || fileUrlHistory?.image === 'NA') && (
                 <Box sx={{display:'flex'}}>
                   <label htmlFor="file-input">
                    <Button
                      component="span"
                      sx={{
                        background: "rgba(27, 37, 53, 0.19)",
                        width: "57px",
                        height: "57px",
                        color: "#1B2535",
                        fontWeight:500
                      }}
                    >
                      <PersonIcon sx={{fontSize:'50px'}}/>
                    </Button>
                  </label>
                 </Box>
                )}
              </Box>
              <HelperText variant="primary">{isPhotoErrorHistory}</HelperText>
            </Stack>
          </Box> */}
          {/* <Box sx={{ width: "100%" }}>
            <Stack sx={{ width: "49%", alignItems: "baseline" }}>
              <Typography sx={{ fontWeight: 600, marginBottom: "5px" }}>
                Upload Image (JPEG or PNG)
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  background: "#f6f6f6",
                  padding: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Input
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    onChange={handlePhotoUpload}
                    sx={{ display: "none" }}
                    id="file-input"
                  />
                  {fileUrlHistory.length === 0 ||
                  fileUrlHistory[0]?.image === "NA" ? (
                    <Box sx={{ display: "flex" }}>
                      <label htmlFor="file-input">
                        <Button
                          component="span"
                          sx={{
                            background: "rgba(27, 37, 53, 0.19)",
                            width: "57px",
                            height: "57px",
                            color: "#1B2535",
                            fontWeight: 500,
                          }}
                        >
                          <PersonIcon sx={{ fontSize: "50px" }} />
                        </Button>
                      </label>
                    </Box>
                  ) : (
                    fileUrlHistory.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 2,
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        {item.image !== "NA" && (
                          <Box
                            component="img"
                            src={item.image}
                            alt={item.fileName}
                            sx={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              width: "100%",
                              height: "auto",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                            }}
                          />
                        )}
                        <IconButton
                          onClick={() => removePhoto(item?.imageid)}
                          size="small"
                          sx={{
                            marginLeft: "10px",
                            position: "absolute",
                            top: "-22px",
                            right: "0",
                          }}
                        >
                          <CloseIcon sx={{ color: "#FF3A44" }} />
                        </IconButton>
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
              <HelperText variant="primary">{isPhotoErrorHistory}</HelperText>
            </Stack>
          </Box> */}
           <Box sx={{ width: "100%" }}>
      <Stack sx={{  width: {
                        xs: "100%",
                        sm: "100%",
                        md: "50%",
                      }, alignItems: "baseline" }}>
        <Typography sx={{ fontWeight: 600, marginBottom: "5px" }}>
          Upload Image (JPEG or PNG)
        </Typography>
        <Box
          sx={{
            width: "100%",
            background: "#f6f6f6",
            padding: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <Input
              type="file"
              accept=".png,.jpeg,.jpg"
              onChange={handlePhotoUpload}
              sx={{ display: "none" }}
              id="file-input"
            />
            {fileUrlHistory.length === 0 || fileUrlHistory[0]?.image === "NA" ? (
              <Box sx={{ display: "flex", alignItems: "center",width:'100%',justifyContent:'space-between' }}>
                <label htmlFor="file-input">
                  <Button
                    component="span"
                    sx={{
                      background: "rgba(27, 37, 53, 0.19)",
                      width: "57px",
                      height: "57px",
                      color: "#1B2535",
                      fontWeight: 500,
                    }}
                  >
                    <PersonIcon sx={{ fontSize: "50px" }} />
                  </Button>
                </label>
                <IconButton onClick={triggerFileInput} size="large">
                  <FileUploadIcon
                    size={20}
                    style={{ color: "rgba(27, 37, 53, 1)" }}
                  />
                </IconButton>
              </Box>
            ) : (
              fileUrlHistory.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 2,
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {item.image !== "NA" && (
                    <Box
                      component="img"
                      src={item.image}
                      alt={item.fileName}
                      sx={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        width: "100%",
                        height: "auto",
                        borderRadius: "4px",
                        border: "1px solid #ddd",
                      }}
                    />
                  )}
                  <IconButton
                    onClick={() => removePhoto(item?.imageid)}
                    size="small"
                    sx={{
                      marginLeft: "10px",
                      position: "absolute",
                      top: "-22px",
                      right: "0",
                    }}
                  >
                    <CloseIcon sx={{ color: "#FF3A44" }} />
                  </IconButton>
                </Box>
              ))
            )}
          </Box>
        </Box>
        <HelperText variant="primary">{isPhotoErrorHistory}</HelperText>
      </Stack>
    </Box>
  
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfilePage;
