import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  Divider,
  Stack,
  Rating,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getDepartmentData,
  departmentAssetDetails,
  equipmentAudit,
  equipmentAuditList,
  createEquipmentAudit,
  assetAuditApproveList,
  assetAuditIgnoreList,
} from "../redux/data/action";
import { useEffect } from "react";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));

const EquipmentAudit = () => {
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ratings, setRatings] = useState(Array(7).fill(0)); // Stores ratings for each page
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [yearlyData, setYearlyData] = useState([]);
  const [assetValue, setAssetValue] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [data, setData] = useState({});
  const [assetId, setAssetId] = useState("");
  const [model, setModel] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [audStartDate, setAudStartDate] = useState(""); // Audit Start Date
  const [audEndDate, setAudEndDate] = useState(""); // Audit End Date
  const [dateError, setDateError] = useState("");
  const navigate = useNavigate();

  const departmentData = useSelector((store) => store.data.department) || [];
  const detailData = useSelector((store) => store.data.departmentAssetDetails);
  const equipmentaudit = useSelector((store) => store.data.equipmentauditdata);
  const equipmentauditdata = useSelector(
    (store) => store.data.equipmentauditlistdata
  );
  const auditapprovelist = useSelector(
    (store) => store.data.assetauditApproveListdata
  );
  const auditIgnoreList = useSelector(
    (store) => store.data.assetauditignorelistdata
  );
  const auditId = equipmentauditdata?.[0]?.aud_id || "No ID available";


  const filteredDepartments = departmentData.filter(
    (department) => parseInt(department.totalassets) > 0
  );

  const handleCloseModal = () => setOpenModal(false);

  const handleRatingChange = (page, value) => {
    const updatedRatings = [...ratings];
    updatedRatings[page - 1] = value;
    setRatings(updatedRatings);
  };

  const nextPage = () => {
    if (currentPage < 8) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const calculateTotalRating = () => {
    return ratings.reduce((acc, curr) => acc + curr, 0);
  };

  const calculateAllTotalCount = () => {
    return calculateTotalRating() / 6;
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: selectedId,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(departmentAssetDetails(data));
  }, [dispatch, selectedId]);

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(equipmentAuditList(data));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(getDepartmentData(data));
  }, [dispatch]);

  const handleOpenAudit = () => {
    const auditData = {
      hospid: userIds?.hospid,
      hospname: userIds?.hospname,
      branchid: userBranch?.id,
      branchname: userBranch?.branch,
      userid: userIds?.userid,
      username: userIds?.username,
    };
  
    dispatch(createEquipmentAudit(auditData))
      .then((response) => {
  
        if (response === false) {
          setOpenModal(true);  
        }
  
        const data = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          branchid: userBranch?.id,
          depart_ids: userAccess?.departmentids,
          userrole: userAccess?.role,
        };
  
        dispatch(equipmentAuditList(data));
      })
      .catch((error) => {
        console.error("Error during audit creation:", error);
      });
  };
  
  
  



  

  useEffect(() => {
    if (equipmentauditdata && equipmentauditdata.aud_id) {
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        auditid: equipmentauditdata.aud_id, // Use audit.aud_id here
        depart_ids: userAccess?.departmentids,
        userrole: userAccess?.role,
      };
      dispatch(assetAuditApproveList(data));
    }
  }, [dispatch]);

  useEffect(() => {
    if (equipmentauditdata && equipmentauditdata.aud_id) {
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        auditid: equipmentauditdata.aud_id,
        depart_ids: userAccess?.departmentids,
        userrole: userAccess?.role,
      };
      dispatch(assetAuditIgnoreList(data));
    }
  }, [dispatch]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        gap="10px"
        height="90vh"
        padding="16px"
        marginTop="-15px"
      >
        <Box display="flex" justifyContent="end" gap="10px">
          <Button variant="contained" onClick={handleOpenAudit} disabled={userAccess?.role === "Guest"||
                          userAccess?.role === "Admin"}>
            Create Equipment Audit
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableHeading>Audit No</TableHeading>
                <TableHeading>Asset Total</TableHeading>
                <TableHeading>Audited</TableHeading>
                <TableHeading>Start Date</TableHeading>
                <TableHeading>Rating</TableHeading>
                <TableHeading>Missed</TableHeading>
                <TableHeading>Conducted By</TableHeading>
                <TableHeading>Done</TableHeading>
                <TableHeading>Status</TableHeading>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipmentauditdata.length === 0 ? (
                <TableRow>
                  <TableCells colSpan={10} align="center">
                    No audits submitted yet.
                  </TableCells>
                </TableRow>
              ) : (
                equipmentauditdata.map((audit, index) => (
                  <TableRow
                    key={index}
                    onClick={() =>
                      navigate(
                        `/Add_Deparment-audit-list?auditid=${audit.aud_id}`
                      )
                    }
                  >
                    <TableCells  sx={{ textOverflow: "clip", whiteSpace: "normal",width:"18%" }}>{audit.aud_id}</TableCells>
                    <TableCells>{audit.tot_assets}</TableCells>
                    <TableCells>{audit.audited}</TableCells>
                    <TableCells>{audit.start}</TableCells>
                    <TableCells>{audit.rating}</TableCells>
                    <TableCells>{audit.missed}</TableCells>
                    <TableCells>{audit.cond_by}</TableCells>
                    <TableCells>{audit.done}</TableCells>
                    <TableCells>{audit.status}</TableCells>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            width: "50%",
            margin: "auto",
            mt: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Typography variant="h6">Equipment Audit</Typography>

            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "relative",
                top: 10,
                right: 10,
                color: "red",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              margin: "auto",
              padding: 2,
              backgroundColor: "white",
            }}
          >
        
            <Stack>
              <h2>Equipment Audit is already Created </h2>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EquipmentAudit;
