import axios from "axios";
import FileSaver from "file-saver";
import QRCode from "qrcode";
const token = localStorage.getItem("userToken");
const Id = JSON.parse(localStorage.getItem("userTokenId"));

export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

export const COUNT_REQUEST = "COUNT_REQUEST";
export const COUNT_SUCCESS = "COUNT_SUCCESS";
export const COUNT_FAILURE = "COUNT_FAILURE";

export const GET_NEW_ASSETS_REQUEST = "GET_NEW_ASSETS_REQUEST";
export const GET_NEW_ASSETS_SUCCESS = "GET_NEW_ASSETS_SUCCESS";
export const GET_NEW_ASSETS_FAILURE = "GET_NEW_ASSETS_FAILURE";

export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

export const POST_USER_DATA_REQUEST = "POST_USER_DATA_REQUEST";
export const POST_USER_DATA_SUCCESS = "POST_USER_DATA_SUCCESS";
export const POST_USER_DATA_FAILURE = "POST_USER_DATA_FAILURE";

export const GET_DEPARTMENT_DATA_REQUEST = "GET_DEPARTMENT_DATA_REQUEST";
export const GET_DEPARTMENT_DATA_SUCCESS = "GET_DEPARTMENT_DATA_SUCCESS";
export const GET_DEPARTMENT_DATA_FAILURE = "GET_DEPARTMENT_DATA_FAILURE";

export const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_FAILURE = "UPDATE_DATA_FAILURE";

export const CURRENT_DATA_REQUEST = "CURRENT_DATA_REQUEST";
export const CURRENT_DATA_SUCCESS = "CURRENT_DATA_SUCCESS";
export const CURRENT_DATA_FAILURE = "CURRENT_DATA_FAILURE";

export const INCIDENT_DATA_REQUEST = "INCIDENT_DATA_REQUEST";
export const INCIDENT_DATA_SUCCESS = "INCIDENT_DATA_SUCCESS";
export const INCIDENT_DATA_FAILURE = "INCIDENT_DATA_FAILURE";

export const INCIDENT_DETAIL_REQUEST = "INCIDENT_DETAIL_REQUEST";
export const INCIDENT_DETAIL_SUCCESS = "INCIDENT_DETAIL_SUCCESS";
export const INCIDENT_DETAIL_FAILURE = "INCIDENT_DETAIL_FAILURE";

export const DOWNLOAD_PDF_REQUEST = "DOWNLOAD_PDF_REQUEST";
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS";
export const DOWNLOAD_PDF_FAILURE = "DOWNLOAD_PDF_FAILURE";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const UPLOADED_IMAGE_REQUEST = "UPLOADED_IMAGE_REQUEST";
export const UPLOADED_IMAGE_SUCCESS = "UPLOADED_IMAGE_SUCCESS";
export const UPLOADED_IMAGE_FAILURE = "UPLOADED_IMAGE_FAILURE";

export const UPLOADED_CERTIFICATE_REQUEST = "UPLOADED_CERTIFICATE_REQUEST";
export const UPLOADED_CERTIFICATE_SUCCESS = "UPLOADED_CERTIFICATE_SUCCESS";
export const UPLOADED_CERTIFICATE_FAILURE = "UPLOADED_CERTIFICATE_FAILURE";

export const UPLOAD_QR_CODE_IMAGE_REQUEST = "UPLOAD_QR_CODE_IMAGE_REQUEST";
export const UPLOAD_QR_CODE_IMAGE_SUCCESS = "UPLOAD_QR_CODE_IMAGE_SUCCESS";
export const UPLOAD_QR_CODE_IMAGE_FAILURE = "UPLOAD_QR_CODE_IMAGE_FAILURE";

export const CREATE_QR_CODE_REQUEST = "CREATE_QR_CODE_REQUEST";
export const CREATE_QR_CODE_SUCCESS = "CREATE_QR_CODE_SUCCESS";
export const CREATE_QR_CODE_FAILURE = "CREATE_QR_CODE_FAILURE";

export const ADD_ASSET_REQUEST = "ADD_ASSET_REQUEST";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_FAILURE = "ADD_ASSET_FAILURE";
export const POST_DEPARTMENT_NAME_REQUEST = "POST_DEPARTMENT_NAME_REQUEST";
export const POST_DEPARTMENT_NAME_SUCCESS = "POST_DEPARTMENT_NAME_SUCCESS";
export const POST_DEPARTMENT_NAME_FAILURE = "POST_DEPARTMENT_NAME_FAILURE";
export const POST_DEPARTMENT_REQUEST = "POST_DEPARTMENT_REQUEST";
export const POST_DEPARTMENT_SUCCESS = "POST_DEPARTMENT_SUCCESS";
export const POST_DEPARTMENT_FAILURE = "POST_DEPARTMENT_FAILURE";

export const DEPARTMENT_ALREADY_SAVED_REQUEST =
  "DEPARTMENT_ALREADY_SAVED_REQUEST";
export const DEPARTMENT_ALREADY_SAVED_SUCCESS =
  "DEPARTMENT_ALREADY_SAVED_SUCCESS";
export const DEPARTMENT_ALREADY_SAVED_FAILURE =
  "DEPARTMENT_ALREADY_SAVED_FAILURE";

export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";
export const GET_ASSET_REQUEST = "GET_ASSET_REQUEST";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAILURE = "GET_ASSET_FAILURE";

export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const DELETE_CERTIFICATE_REQUEST = "DELETE_CERTIFICATE_REQUEST";
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS";
export const DELETE_CERTIFICATE_FAILURE = "DELETE_CERTIFICATE_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

export const RETRIEVE_DEPARTMENT_REQUEST = "RETRIEVE_DEPARTMENT_REQUEST";
export const RETRIEVE_DEPARTMENT_SUCCESS = "RETRIEVE_DEPARTMENT_SUCCESS";
export const RETRIEVE_DEPARTMENT_FAILURE = "RETRIEVE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

// ..................Retrieve all assets............................................

export const RETRIEVE_ALL_ASSETS_REQUEST = "RETRIEVE_ALL_ASSETS_REQUEST";
export const RETRIEVE_ALL_ASSETS_SUCCESS = "RETRIEVE_ALL_ASSETS_SUCCESS";
export const RETRIEVE_ALL_ASSETS_FAILURE = "RETRIEVE_ALL_ASSETS_FAILURE";

export const RETRIEVE_ALL_ASSETS_REQUEST_INCIDENT =
  "RETRIEVE_ALL_ASSETS_REQUEST_INCIDENT";
export const RETRIEVE_ALL_ASSETS_SUCCESS_INCIDENT =
  "RETRIEVE_ALL_ASSETS_SUCCESS_INCIDENT";
export const RETRIEVE_ALL_ASSETS_FAILURE_INCIDENT =
  "RETRIEVE_ALL_ASSETS_FAILURE_INCIDENT";

// ..................Delete assets............................................

export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

// ...................Create Service...........................................

export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";

// ...................Create Service Image upload...........................................
export const CREATE_SERVICE_IMAGE_UPLOAD_REQUEST =
  "CREATE_SERVICE_IMAGE_UPLOAD_REQUEST";
export const CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS =
  "CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS";
export const CREATE_SERVICE_IMAGE_UPLOAD_FAILURE =
  "CREATE_SERVICE_IMAGE_UPLOAD_FAILURE";

// ...................Closed Service...........................................

export const CLOSED_SERVICE_REQUEST = "CLOSED_SERVICE_REQUEST";
export const CLOSED_SERVICE_SUCCESS = "CLOSED_SERVICE_SUCCESS";
export const CLOSED_SERVICE_FAILURE = "CLOSED_SERVICE_FAILURE";

// ...................open Service...........................................

export const OPEN_SERVICE_REQUEST = "OPEN_SERVICE_REQUEST";
export const OPEN_SERVICE_SUCCESS = "OPEN_SERVICE_SUCCESS";
export const OPEN_SERVICE_FAILURE = "OPEN_SERVICE_FAILURE";

// ................... Service List...........................................

export const SERVICE_LIST_REQUEST = "SERVICE_LIST_REQUEST";
export const SERVICE_LIST_SUCCESS = "SERVICE_LIST_SUCCESS";
export const SERVICE_LIST_FAILURE = "SERVICE_LIST_FAILURE";

export const SERVICE_LIST_REQUEST_DROPDOWN = "SERVICE_LIST_REQUEST_DROPDOWN";
export const SERVICE_LIST_SUCCESS_DROPDOWN = "SERVICE_LIST_SUCCESS_DROPDOWN";
export const SERVICE_LIST_FAILURE_DROPDOWN = "SERVICE_LIST_FAILURE_DROPDOWN";

// ................... Service List...........................................

export const SERVICE_DETAIL_REQUEST = "SERVICE_DETAIL_REQUEST";
export const SERVICE_DETAIL_SUCCESS = "SERVICE_DETAIL_SUCCESS";
export const SERVICE_DETAIL_FAILURE = "SERVICE_DETAIL_FAILURE";

// ................... Clear service...........................................
export const CLEAR_SERVICE_REQUEST = "CLEAR_SERVICE_REQUEST";
export const CLEAR_SERVICE_SUCCESS = "CLEAR_SERVICE_SUCCESS";
export const CLEAR_SERVICE_FAILURE = "CLEAR_SERVICE_FAILURE";

// ...................Issue...........................................
export const SERVICE_ISSUE_REQUEST = "SERVICE_ISSUE_REQUEST";
export const SERVICE_ISSUE_SUCCESS = "SERVICE_ISSUE_SUCCESS";
export const SERVICE_ISSUE_FAILURE = "SERVICE_ISSUE_FAILURE";

export const INCIDENT_ISSUE_REQUEST = "INCIDENT_ISSUE_REQUEST";
export const INCIDENT_ISSUE_SUCCESS = "INCIDENT_ISSUE_SUCCESS";
export const INCIDENT_ISSUE_FAILURE = "INCIDENT_ISSUE_FAILURE";
// ...................Service Image upload...........................................
export const SERVICE_IMAGE_UPLOAD_REQUEST = "SERVICE_IMAGE_UPLOAD_REQUEST";
export const SERVICE_IMAGE_UPLOAD_SUCCESS = "SERVICE_IMAGE_UPLOAD_SUCCESS";
export const SERVICE_IMAGE_UPLOAD_FAILURE = "SERVICE_IMAGE_UPLOAD_FAILURE";

// ...................Logout...........................................

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const EDIT_ASSET_REQUEST = "EDIT_ASSET_REQUEST";
export const EDIT_ASSET_SUCCESS = "EDIT_ASSET_SUCCESS";
export const EDIT_ASSET_FAILURE = "EDIT_ASSET_FAILURE";

// ...................Profile...........................................

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

// ...................usermanual...........................................
export const USER_MANUAL_REQUEST = "USER_MANUAL_REQUEST";
export const USER_MANUAL_SUCCESS = "USER_MANUAL_SUCCESS";
export const USER_MANUAL_FAILURE = "USER_MANUAL_FAILURE";

export const GET_CALIBRATED_REQUEST = "GET_CALIBRATED_REQUEST";
export const GET_CALIBRATED_SUCCESS = "GET_CALIBRATED_SUCCESS";
export const GET_CALIBRATED_FAILURE = "GET_CALIBRATED_FAILURE";

export const GET_NOTCALIBRATED_REQUEST = "GET_NOTCALIBRATED_REQUEST";
export const GET_NOTCALIBRATED_SUCCESS = "GET_NOTCALIBRATED_SUCCESS";
export const GET_NOTCALIBRATED_FAILURE = "GET_NOTCALIBRATED_FAILURE";

export const GET_NOTREQUIRED_REQUEST = "GET_NOTREQUIRED_REQUEST";
export const GET_NOTREQUIRED_SUCCESS = "GET_NOTREQUIRED_SUCCESS";
export const GET_NOTREQUIRED_FAILURE = "GET_NOTREQUIRED_FAILURE";

// ---------------------------------clear incident image-------------------------

export const CLEAR_INCIDENT_IMAGE_REQUEST = "CLEAR_INCIDENT_IMAGE_REQUEST";
export const CLEAR_INCIDENT_IMAGE_SUCCESS = "CLEAR_INCIDENT_IMAGE_SUCCESS";
export const CLEAR_INCIDENT_IMAGE_FAILURE = "CLEAR_INCIDENT_IMAGE_FAILURE";

export const CLEAR_INCIDENT_REQUEST = "CLEAR_INCIDENT_REQUEST";
export const CLEAR_INCIDENT_SUCCESS = "CLEAR_INCIDENT_SUCCESS";
export const CLEAR_INCIDENT_FAILURE = "CLEAR INCIDENT_FAILURE";

export const CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST =
  "CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST";
export const CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS =
  "CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS";
export const CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE =
  "CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE";

export const SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST =
  "SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST";
export const SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS =
  "SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS";
export const SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE =
  "SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE";

// ..................Retrieve all assets............................................

export const ASSET_DETAILS_REQUEST = "ASSET_DETAILS_REQUEST";
export const ASSET_DETAILS_SUCCESS = "ASSET_DETAILS_SUCCESS";
export const ASSET_DETAILS_FAILURE = "ASSET_DETAILS_FAILURE";

// ..................Record incident image upload............................................

export const RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST =
  "RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST";
export const RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS =
  "RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS";
export const RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE =
  "RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE";

// ..................Record incident image Delete............................................

export const RECORD_INCIDENT_IMAGE_DELETE_REQUEST =
  "RECORD_INCIDENT_IMAGE_DELETE_REQUEST";
export const RECORD_INCIDENT_IMAGE_DELETE_SUCCESS =
  "RECORD_INCIDENT_IMAGE_DELETE_SUCCESS";
export const RECORD_INCIDENT_IMAGE_DELETE_FAILURE =
  "RECORD_INCIDENT_IMAGE_DELETE_FAILURE";

// ..................Create Incident............................................

export const CREATE_INCIDENT_REQUEST = "CREATE_INCIDENT_REQUEST";
export const CREATE_INCIDENT_SUCCESS = "CREATE_INCIDENT_SUCCESS";
export const CREATE_INCIDENT_FAILURE = "CREATE_INCIDENT_FAILURE";

// ..................Get Incident History............................................

export const GET_INCIDENT_HISTORY_REQUEST = "GET_INCIDENT_HISTORY_REQUEST";
export const GET_INCIDENT_HISTORY_SUCCESS = "GET_INCIDENT_HISTORY_SUCCESS";
export const GET_INCIDENT_HISTORY_FAILURE = "GET_INCIDENT_HISTORY_FAILURE";

// ..................Get Dashboard Data............................................

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

//..................... Report..............................................

export const SERVICE_REPORT_REQUEST = "SERVICE_REPORT_REQUEST";
export const SERVICE_REPORT_SUCCESS = "SERVICE_REPORT_SUCCESS";
export const SERVICE_REPORT_FAILURE = "SERVICE_REPORT_FAILURE";

export const INCIDENT_REPORT_REQUEST = "INCIDENT_REPORT_REQUEST";
export const INCIDENT_REPORT_SUCCESS = "INCIDENT_REPORT_SUCCESS";
export const INCIDENT_REPORT_FAILURE = "INCIDENT_REPORT_FAILURE";

export const CALIBRATION_REPORT_REQUEST = "CALIBRATION_REPORT_REQUEST";
export const CALIBRATION_REPORT_SUCCESS = "CALIBRATION_REPORT_SUCCESS";
export const CALIBRATION_REPORT_FAILURE = "CALIBRATION_REPORT_FAILURE";

export const DEPARTMENT_ASSET_DETAILS_REQUEST =
  "DEPARTMENT_ASSET_DETAILS_REQUEST";
export const DEPARTMENT_ASSET_DETAILS_SUCCESS =
  "DEPARTMENT_ASSET_DETAILS_SUCCESS";
export const DEPARTMENT_ASSET_DETAILS_FAILURE =
  "DEPARTMENT_ASSET_DETAILS_FAILURE";

export const GET_PM_ASSET_REQUEST = "GET_PM_ASSET_REQUEST";
export const GET_PM_ASSET_SUCCESS = "GET_PM_ASSET_SUCCESS";
export const GET_PM_ASSET_FAILURE = "GET_PM_ASSET_FAILURE";

//..................... Post pm check list..............................................

export const POST_PM_CHECKLIST_REQUEST = "POST_PM_CHECKLIST_REQUEST";
export const POST_PM_CHECKLIST_SUCCESS = "POST_PM_CHECKLIST_SUCCESS";
export const POST_PM_CHECKLIST_FAILURE = "POST_PM_CHECKLIST_FAILURE";

//..................... Post pm check list..............................................

export const PM_CHECKLIST_PDF_REQUEST = "PM_CHECKLIST_PDF_REQUEST";
export const PM_CHECKLIST_PDF_SUCCESS = "PM_CHECKLIST_PDF_SUCCESS";
export const PM_CHECKLIST_PDF_FAILURE = "PM_CHECKLIST_PDF_FAILURE";

// ...................Closed Incident..........................................

export const CLOSED_INCIDENT_REQUEST = "CLOSED_INCIDENT_REQUEST";
export const CLOSED_INCIDENT_SUCCESS = "CLOSED_INCIDENT_SUCCESS";
export const CLOSED_INCIDENT_FAILURE = "CLOSED_INCIDENT_FAILURE";

// ...................open Incident..........................................

export const OPEN_INCIDENT_REQUEST = "OPEN_INCIDENT_REQUEST";
export const OPEN_INCIDENT_SUCCESS = "OPEN_INCIDENT_SUCCESS";
export const OPEN_INCIDENT_FAILURE = "OPEN_INCIDENT_FAILURE";

// ...................warranty status..........................................

export const WARRANTY_STATUS_REQUEST = "WARRANTY_STATUS_REQUEST";
export const WARRANTY_STATUS_SUCCESS = "WARRANTY_STATUS_SUCCESS";
export const WARRANTY_STATUS_FAILURE = "WARRANTY_STATUS_FAILURE";

// ................... Request Manual..........................................

export const REQUEST_WARRANTY_REQUEST = "REQUEST_WARRANTY_REQUEST";
export const REQUEST_WARRANTY_SUCCESS = "REQUEST_WARRANTY_SUCCESS";
export const REQUEST_WARRANTY_FAILURE = "REQUEST_WARRANTY_FAILURE";

export const PROFILE_EDIT_REQUEST = "PROFILE_EDIT_REQUEST";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";

export const GET_INVALID_TOKEN_REQUEST = "GET_INVALID_TOKEN_REQUEST";
export const GET_INVALID_TOKEN_SUCCESS = "GET_INVALID_TOKEN_SUCCESS";
export const GET_INVALID_TOKEN_FAILURE = "GET_INVALID_TOKEN_FAILURE";

export const GET_AUTHENTICATION_ERROR_SUCCESS =
  "GET_AUTHENTICATION_ERROR_SUCCESS";

export const CLEAR_SERVICE_IMAGE_DELETE_REQUEST =
  "CLEAR_SERVICE_IMAGE_DELETE_REQUEST";
export const CLEAR_SERVICE_IMAGE_DELETE_SUCCESS =
  "CLEAR_SERVICE_IMAGE_DELETE_SUCCESS";
export const CLEAR_SERVICE_IMAGE_DELETE_FAILURE =
  "CLEAR_SERVICE_IMAGE_DELETE_FAILURE";

//-------------------------Clear Service Receipt-----------------------

export const CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST =
  "CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST";
export const CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS =
  "CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS";
export const CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE =
  "CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE";

//-------------------------Clear Service Receipt delete-----------------------

export const CLEAR_SERVICE_RECEIPT_DELETE_REQUEST =
  "CLEAR_SERVICE_RECEIPT_DELETE_REQUEST";
export const CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS =
  "CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS";
export const CLEAR_SERVICE_RECEIPT_DELETE_FAILURE =
  "CLEAR_SERVICE_RECEIPT_DELETE_FAILURE";
export const AMC_CMC_REQUEST = "AMC_CMC_REQUEST";
export const AMC_CMC_SUCCESS = "AMC_CMC_SUCCESS";
export const AMC_CMC_FAILURE = "AMC_CMC_FAILURE";

export const CREATE_NBM_TASK_REQUEST = "CREATE_NBM_TASK_REQUEST";
export const CREATE_NBM_TASK_SUCCESS = "CREATE_NBM_TASK_SUCCESS";
export const CREATE_NBM_TASK_FAILURE = "CREATE_NBM_TASK_FAILURE";

export const GET_NBM_TASK_REQUEST = "GET_NBM_TASK_REQUEST";
export const GET_NBM_TASK_SUCCESS = "GET_NBM_TASK_SUCCESS";
export const GET_NBM_TASK_FAILURE = "GET_NBM_TASK_FAILURE";

export const GET_VENDOR_REQUEST = "GET_VENDOR_REQUEST";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAILURE = "GET_VENDOR_FAILURE";

export const POST_VENDOR_REQUEST = "POST_VENDOR_REQUEST";
export const POST_VENDOR_SUCCESS = "POST_VENDOR_SUCCESS";
export const POST_VENDOR_FAILURE = "POST_VENDOR_FAILURE";

export const GET_VENDOR_SERVICE_REQUEST = "GET_VENDOR_SERVICE_REQUEST";
export const GET_VENDOR_SERVICE_SUCCESS = "GET_VENDOR_SERVICE_SUCCESS";
export const GET_VENDOR_SERVICE_FAILURE = "GET_VENDOR_SERVICE_FAILURE";

export const POST_VENDOR_SERVICE_REQUEST = "POST_VENDOR_SERVICE_REQUEST";
export const POST_VENDOR_SERVICE_SUCCESS = "POST_VENDOR_SERVICE_SUCCESS";
export const POST_VENDOR_SERVICE_FAILURE = "POST_VENDOR_SERVICE_FAILURE";

export const AMC_REQUEST = "AMC_REQUEST";
export const AMC_SUCCESS = "AMC_SUCCESS";
export const AMC_FAILURE = "AMC_FAILURE";

export const CMC_REQUEST = "CMC_REQUEST";
export const CMC_SUCCESS = "CMC_SUCCESS";
export const CMC_FAILURE = "CMC_FAILURE";

export const ASSET_ADD_REPORT_REQUEST = "ASSET_ADD_REPORT_REQUEST";
export const ASSET_ADD_REPORT_SUCCESS = "ASSET_ADD_REPORT_SUCCESS";
export const ASSET_ADD_REPORT_FAILURE = "ASSET_ADD_REPORT_FAILURE";

export const ASSET_DELETE_REPORT_REQUEST = "ASSET_DELETE_REPORT_REQUEST";
export const ASSET_DELETE_REPORT_SUCCESS = "ASSET_DELETE_REPORT_SUCCESS";
export const ASSET_DELETE_REPORT_FAILURE = "ASSET_DELETE_REPORT_FAILURE";

export const DELETE_CSV_FILE_UPLOAD_REQUEST = "DELETE_CSV_FILE_UPLOAD_REQUEST";
export const DELETE_CSV_FILE_UPLOAD_SUCCESS = "DELETE_CSV_FILE_UPLOAD_SUCCESS";
export const DELETE_CSV_FILE_UPLOAD_FAILURE = "DELETE_CSV_FILE_UPLOAD_FAILURE";

export const CSV_FILE_UPLOAD_SUBMIT_REQUEST = "CSV_FILE_UPLOAD_SUBMIT_REQUEST";
export const CSV_FILE_UPLOAD_SUBMIT_SUCCESS = "CSV_FILE_UPLOAD_SUBMIT_SUCCESS";
export const CSV_FILE_UPLOAD_SUBMIT_FAILURE = "CSV_FILE_UPLOAD_SUBMIT_FAILURE";

export const CSV_FILE_UPLOAD_REQUEST = "CSV_FILE_UPLOAD_REQUEST";
export const CSV_FILE_UPLOAD_SUCCESS = "CSV_FILE_UPLOAD_SUCCESS";
export const CSV_FILE_UPLOAD_FAILURE = "CSV_FILE_UPLOAD_FAILURE";

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const CHECKIN_REQUEST = "CHECKIN_REQUEST";
export const CHECKIN_SUCCESS = "CHECKIN_SUCCESS";
export const CHECKIN_FAILURE = "CHECKIN_FAILURE";

export const CHECKIN_CHECKOUT_REQUEST = "CHECKIN_CHECKOUT_REQUEST";
export const CHECKIN_CHECKOUT_SUCCESS = "CHECKIN_CHECKOUT_SUCCESS";
export const CHECKIN_CHECKOUT_FAILURE = "CHECKIN_CHECKOUT_FAILURE";

export const CHECKIN_CHECKOUT_DETAIL_REQUEST =
  "CHECKIN_CHECKOUT_DETAIL_REQUEST";
export const CHECKIN_CHECKOUT_DETAIL_SUCCESS =
  "CHECKIN_CHECKOUT_DETAIL_SUCCESS";
export const CHECKIN_CHECKOUT_DETAIL_FAILURE =
  "CHECKIN_CHECKOUT_DETAIL_FAILURE";

export const CALENDER_REQUEST = "CALENDER_REQUEST";
export const CALENDER_SUCCESS = "CALENDER_SUCCESS";
export const CALENDER_FAILURE = "CALENDER_FAILURE";

export const CHECKIN_PDF_REQUEST = "CHECKIN_PDF_REQUEST";
export const CHECKIN_PDF_SUCCESS = "CHECKIN_PDF_SUCCESS";
export const CHECKIN_PDF_FAILURE = "CHECKIN_PDF_FAILURE";

export const AMC_CMC_REQ_REQUEST = "AMC_CMC_REQ_REQUEST";
export const AMC_CMC_REQ_SUCCESS = "AMC_CMC_REQ_SUCCESS";
export const AMC_CMC_REQ_FAILURE = "AMC_CMC_REQ_FAILURE";

export const CALIBRATION_REQ_REQUEST = "CALIBRATION_REQ_REQUEST";
export const CALIBRATION_REQ_SUCCESS = "CALIBRATION_REQ_SUCCESS";
export const CALIBRATION_REQ_FAILURE = "CALIBRATION_REQ_FAILURE";

export const ASSET_HISTORY_REQUEST = "ASSET_HISTORY_REQUEST";
export const ASSET_HISTORY_SUCCESS = "ASSET_HISTORY_SUCCESS";
export const ASSET_HISTORY_FAILURE = "ASSET_HISTORY_FAILURE";

export const ASSET_HISTORY_RETRIEVE_REQUEST = "ASSET_HISTORY_RETRIEVE_REQUEST";
export const ASSET_HISTORY_RETRIEVE_SUCCESS = "ASSET_HISTORY_RETRIEVE_SUCCESS";
export const ASSET_HISTORY_RETRIEVE_FAILURE = "ASSET_HISTORY_RETRIEVE_FAILURE";

export const ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST =
  "ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST";
export const ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS =
  "ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS";
export const ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE =
  "ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE";

export const WARRANTY_REQUEST_REQUEST = "WARRANTY_REQUEST_REQUEST";
export const WARRANTY_REQUEST_SUCCESS = "WARRANTY_REQUEST_SUCCESS";
export const WARRANTY_REQUEST_FAILURE = "WARRANTY_REQUEST_FAILURE";

export const WARRANTY_TASK_COMPLETE_REQUEST = "WARRANTY_TASK_COMPLETE_REQUEST";
export const WARRANTY_TASK_COMPLETE_SUCCESS = "WARRANTY_TASK_COMPLETE_SUCCESS";
export const WARRANTY_TASK_COMPLETE_FAILURE = "WARRANTY_TASK_COMPLETE_FAILURE";

export const AMC_CMC_TASK_COMPLETE_REQUEST = "AMC_CMC_TASK_COMPLETE_REQUEST";
export const AMC_CMC_TASK_COMPLETE_SUCCESS = "AMC_CMC_TASK_COMPLETE_SUCCESS";
export const AMC_CMC_TASK_COMPLETE_FAILURE = "AMC_CMC_TASK_COMPLETE_FAILURE";

export const CALIBRATION_TASK_COMPLETE_REQUEST =
  "CALIBRATION_TASK_COMPLETE_REQUEST";
export const CALIBRATION_TASK_COMPLETE_SUCCESS =
  "CALIBRATION_TASK_COMPLETE_SUCCESS";
export const CALIBRATION_TASK_COMPLETE_FAILURE =
  "CALIBRATION_TASK_COMPLETE_FAILURE";

export const UPDATE_REPORT_REQUEST = "UPDATE_REPORT_REQUEST";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAILURE = "UPDATE_REPORT_FAILURE";

export const BULK_ASSET_DELETE_REQUEST = "BULK_ASSET_DELETE_REQUEST";
export const BULK_ASSET_DELETE_SUCCESS = "BULK_ASSET_DELETE_SUCCESS";
export const BULK_ASSET_DELETE_FAILURE = "BULK_ASSET_DELETE_FAILURE";

export const BULK_DEPARTMENT_UPDATE_REQUEST = "BULK_DEPARTMENT_UPDATE_REQUEST";
export const BULK_DEPARTMENT_UPDATE_SUCCESS = "BULK_DEPARTMENT_UPDATE_SUCCESS";
export const BULK_DEPARTMENT_UPDATE_FAILURE = "BULK_DEPARTMENT_UPDATE_FAILURE";

export const CREATE_NBM_SUBUSER_REQUEST = "CREATE_NBM_SUBUSER_REQUEST";
export const CREATE_NBM_SUBUSER_SUCCESS = "CREATE_NBM_SUBUSER_SUCCESS";
export const CREATE_NBM_SUBUSER_FAILURE = "CREATE_NBM_SUBUSER_FAILURE";

export const ALL_USER_LIST_REQUEST = "ALL_USER_LIST_REQUEST";
export const ALL_USER_LIST_SUCCESS = "ALL_USER_LIST_SUCCESS";
export const ALL_USER_LIST_FAILURE = "ALL_USER_LIST_FAILURE";

export const UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST =
  "UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST";
export const UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS =
  "UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS";
export const UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE =
  "UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE";

export const DEPARTMENT_USER_DETAILS_REQUEST =
  "DEPARTMENT_USER_DETAILS_REQUEST";
export const DEPARTMENT_USER_DETAILS_SUCCESS =
  "DEPARTMENT_USER_DETAILS_SUCCESS";
export const DEPARTMENT_USER_DETAILS_FAILURE =
  "DEPARTMENT_USER_DETAILS_FAILURE";

export const USER_RETRIEVE_BY_BRANCH_REQUEST =
  "USER_RETRIEVE_BY_BRANCH_REQUEST";
export const USER_RETRIEVE_BY_BRANCH_SUCCESS =
  "USER_RETRIEVE_BY_BRANCH_SUCCESS";
export const USER_RETRIEVE_BY_BRANCH_FAILURE =
  "DEPARTMENT_USER_RETRIEVE_BY_BRANCH_FAILURE";

export const GET_DASHBOARD_LIST_REQUEST = "GET_DASHBOARD_LIST_REQUEST";
export const GET_DASHBOARD_LIST_SUCCESS = "GET_DASHBOARD_LIST_SUCCESS";
export const GET_DASHBOARD_LIST_FAILURE = "GET_DASHBOARD_LIST_FAILURE";

export const GET_DASHBOARD_LIST2_REQUEST = "GET_DASHBOARD_LIST2_REQUEST";
export const GET_DASHBOARD_LIST2_SUCCESS = "GET_DASHBOARD_LIST2_SUCCESS";
export const GET_DASHBOARD_LIST2_FAILURE = "GET_DASHBOARD_LIST2_FAILURE";

export const GET_MONTHLY_REPORT_REQUEST = "GET_MONTHLY_REPORT_REQUEST";
export const GET_MONTHLY_REPORT_SUCCESS = "GET_MONTHLY_REPORT_SUCCESS";
export const GET_MONTHLY_REPORT_FAILURE = "GET_MONTHLY_REPORT_FAILURE";

export const GET_REQUEST_DASHBOARD = "GET_REQUEST_DASHBOARD";
export const GET_REQUEST_DASHBOARD_SUCCESS = "GET_REQUEST_DASHBOARD_SUCCESS";
export const GET_REQUEST_DASHBOARD_FAILURE = "GET_REQUEST_DASHBOARD_FAILURE";

export const GET_REQUEST_DASHBOARD_NBM = "GET_REQUEST_DASHBOARD_NBM";
export const GET_REQUEST_DASHBOARD_SUCCESS_NBM =
  "GET_REQUEST_DASHBOARD_SUCCESS_NBM";
export const GET_REQUEST_DASHBOARD_FAILURE_NBM =
  "GET_REQUEST_DASHBOARD_FAILURE_NBM";

export const INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST =
  "INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST";
export const INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS =
  "INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS";
export const INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE =
  "INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE";

// ..................assets delete............................................

export const ASSETS_DELETE_REQUEST = "ASSETS_DELETE_REQUEST";
export const ASSETS_DELETE_SUCCESS = "ASSETS_DELETE_SUCCESS";
export const ASSETS_DELETE_FAILURE = "ASSETS_DELETE_FAILURE";

export const CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST =
  "CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST";
export const CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS =
  "CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS";
export const CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE =
  "CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE";

export const SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST =
  "SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST";
export const SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS =
  "SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS";
export const SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE =
  "SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE";

export const CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST =
  "CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST";
export const CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS =
  "CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS";
export const CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE =
  "CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE";

export const CHECKOUT_ASSET_REQUEST = "CHECKOUT_ASSET_REQUEST";
export const CHECKOUT_ASSET_SUCCESS = " CHECKOUT_ASSET_SUCCESS";
export const CHECKOUT_ASSET_FAILURE = " CHECKOUT_ASSET_FAILURE";

// ..................not working assets............................................

export const NOT_WORKING_ASSETS_REQUEST = "NOT_WORKING_ASSETS_REQUEST";
export const NOT_WORKING_ASSETS_SUCCESS = "NOT_WORKING_ASSETS_SUCCESS";
export const NOT_WORKING_ASSETS_FAILURE = "NOT_WORKING_ASSETS_FAILURE";

// ..................Discarded assets............................................

export const DISCARDED_ASSETS_REQUEST = "DISCARDED_ASSETS_REQUEST";
export const DISCARDED_ASSETS_SUCCESS = "DISCARDED_ASSETS_SUCCESS";
export const DISCARDED_ASSETS_FAILURE = "DISCARDED_ASSETS_FAILURE";

// ..................Deleted assets Data............................................

export const DELETEDDATA_ASSET_REQUEST = "DELETEDDATA_ASSET_REQUEST";
export const DELETEDDATA_ASSET_SUCCESS = "DELETEDDATA_ASSET_SUCCESS";
export const DELETEDDATA_ASSET_FAILURE = "DELETEDDATA_ASSET_FAILURE";

//  ................. Deleted Incident Data..........................................
export const INCIDENTDATA_DELETE_REQUEST = "INCIDENTDATA_DELETE_REQUEST";
export const INCIDENTDATA_DELETE_SUCCESS = "INCIDENTDATA_DELETE_SUCCESS";
export const INCIDENTDATA_DELETE_FAILURE = "INCIDENTDATA_DELETE_FAILURE";

//  ................. Delete Incident..........................................
export const INCIDENT_DELETE_REQUEST = "INCIDENT_DELETE_REQUEST";
export const INCIDENT_DELETE_SUCCESS = "INCIDENT_DELETE_SUCCESS";
export const INCIDENT_DELETE_FAILURE = "INCIDENT_DELETE_FAILURE";

// ..................Department Deleted List............................................

export const DEPARTMENT_DELETE_LIST_REQUEST = "DEPARTMENT_DELETE_LIST_REQUEST";
export const DEPARTMENT_DELETE_LIST_SUCCESS = "DEPARTMENT_DELETE_LIST_SUCCESS";
export const DEPARTMENT_DELETE_LIST_FAILURE = "DEPARTMENT_DELETE_LIST_FAILURE";

// ..................Add Generate Ticket............................................

export const ADD_GENERATE_TICKET_REQUEST = "ADD_GENERATE_TICKET_REQUEST";
export const ADD_GENERATE_TICKET_SUCCESS = "ADD_GENERATE_TICKET_SUCCESS";
export const ADD_GENERATE_TICKET_FAILURE = "ADD_GENERATE_TICKET_FAILURE";

// ..................List Generate Ticket............................................

export const LIST_GENERATE_TICKET_REQUEST = "LIST_GENERATE_TICKET_REQUEST";
export const LIST_GENERATE_TICKET_SUCCESS = "LIST_GENERATE_TICKET_SUCCESS";
export const LIST_GENERATE_TICKET_FAILURE = "LIST_GENERATE_TICKET_FAILURE";

// ..................Clear Generate Ticket............................................

export const CLEAR_GENERATE_TICKET_REQUEST = "CLEAR_GENERATE_TICKET_REQUEST";
export const CLEAR_GENERATE_TICKET_SUCCESS = "CLEAR_GENERATE_TICKET_SUCCESS";
export const CLEAR_GENERATE_TICKET_FAILURE = "CLEAR_GENERATE_TICKET_FAILURE";

// ..................Generate Ticket Details............................................

export const GENERATE_TICKET_DETAILS_REQUEST =
  "GENERATE_TICKET_DETAILS_REQUEST";
export const GENERATE_TICKET_DETAILS_SUCCESS =
  "GENERATE_TICKET_DETAILS_SUCCESS";
export const GENERATE_TICKET_DETAILS_FAILURE =
  "GENERATE_TICKET_DETAILS_FAILURE";

// ..................Generate Ticket Acknowledge............................................

export const GENERATE_TICKET_ACKNOWLEDGE_REQUEST =
  "GENERATE_TICKET_ACKNOWLEDGE_REQUEST";
export const GENERATE_TICKET_ACKNOWLEDGE_SUCCESS =
  "GENERATE_TICKET_ACKNOWLEDGE_SUCCESS";
export const GENERATE_TICKET_ACKNOWLEDGE_FAILURE =
  "GENERATE_TICKET_ACKNOWLEDGE_FAILURE";

// .................. Grade Update............................................

export const GRADE_UPDATE_REQUEST = "GRADE_UPDATE_REQUEST";
export const GRADE_UPDATE_SUCCESS = "GRADE_UPDATE_SUCCESS";
export const GRADE_UPDATE_FAILURE = "GRADE_UPDATE_FAILURE";

const getMonthlyReportRequest = () => {
  return { type: GET_MONTHLY_REPORT_REQUEST };
};

const getMonthlyReportSuccess = (data) => {
  return { type: GET_MONTHLY_REPORT_SUCCESS, payload: data };
};

const getMonthlyReportFailure = (error) => {
  return { type: GET_MONTHLY_REPORT_FAILURE, payload: error };
};

const getDashboardListRequest = () => {
  return { type: GET_DASHBOARD_LIST_REQUEST };
};

const getDashboardListSuccess = (data) => {
  return { type: GET_DASHBOARD_LIST_SUCCESS, payload: data };
};

const getDashboardListFailure = (error) => {
  return { type: GET_DASHBOARD_LIST_FAILURE, payload: error };
};

const getDashboardList2Request = () => {
  return { type: GET_DASHBOARD_LIST2_REQUEST };
};

const getDashboardList2Success = (data) => {
  return { type: GET_DASHBOARD_LIST2_SUCCESS, payload: data };
};

const getDashboardList2Failure = (error) => {
  return { type: GET_DASHBOARD_LIST2_FAILURE, payload: error };
};

const departmentUserDetailsRequest = () => {
  return { type: DEPARTMENT_USER_DETAILS_REQUEST };
};

const departmentUserDetailsSuccess = (data) => {
  return {
    type: DEPARTMENT_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

const departmentUserDetailsFailure = (error) => {
  return { type: DEPARTMENT_USER_DETAILS_FAILURE, payload: error };
};

const updateSubuserToSuperAdminRequest = () => {
  return { type: UPDATE_SUBUSER_TO_SUPERADMIN_REQUEST };
};

const updateSubuserToSuperAdminSuccess = (data) => {
  return { type: UPDATE_SUBUSER_TO_SUPERADMIN_SUCCESS, payload: data };
};

const updateSubuserToSuperAdminFailure = (error) => {
  return { type: UPDATE_SUBUSER_TO_SUPERADMIN_FAILURE, payload: error };
};

const allUserListRequest = () => {
  return { type: ALL_USER_LIST_REQUEST };
};

const allUserListSuccess = (data) => {
  return { type: ALL_USER_LIST_SUCCESS, payload: data };
};

const allUserListFailure = (error) => {
  return { type: ALL_USER_LIST_FAILURE, payload: error };
};

const bulkAssetDeleteRequest = () => {
  return { type: BULK_ASSET_DELETE_REQUEST };
};

const bulkAssetDeleteSuccess = (data) => {
  return { type: BULK_ASSET_DELETE_SUCCESS, payload: data };
};

const bulkAssetDeleteFailure = (error) => {
  return { type: BULK_ASSET_DELETE_FAILURE, payload: error };
};

const bulkDepartmentUpdateRequest = () => {
  return { type: BULK_DEPARTMENT_UPDATE_REQUEST };
};

const bulkDepartmentUpdateSuccess = (data) => {
  return { type: BULK_DEPARTMENT_UPDATE_SUCCESS, payload: data };
};

const bulkDepartmentUpdateFailure = (error) => {
  return { type: BULK_DEPARTMENT_UPDATE_FAILURE, payload: error };
};

const postRegisterRequest = () => {
  return { type: POST_REGISTER_REQUEST };
};

const postRegisterSuccess = (data) => {
  return { type: POST_REGISTER_SUCCESS, payload: data };
};

const postRegisterFailure = (error) => {
  return { type: POST_REGISTER_FAILURE, payload: error };
};

const postDataRequest = () => {
  return { type: POST_DATA_REQUEST };
};

const postDataSuccess = (data) => {
  return { type: POST_DATA_SUCCESS, payload: data };
};

const postDataFailure = (error) => {
  return { type: POST_DATA_FAILURE, payload: error };
};

const countRequest = () => {
  return { type: COUNT_REQUEST };
};

const countSuccess = (data) => {
  return { type: COUNT_SUCCESS, payload: data };
};

const countFailure = () => {
  return { type: COUNT_FAILURE };
};

const getUserDataRequest = () => {
  return { type: GET_USER_DATA_REQUEST };
};

const getUserDataSuccess = (data) => {
  return { type: GET_USER_DATA_SUCCESS, payload: data };
};

const getUserDataFailure = (error) => {
  return { type: GET_USER_DATA_FAILURE, payload: error };
};

const postUserDataRequest = () => {
  return { type: POST_USER_DATA_REQUEST };
};

const postUserDataSuccess = (data) => {
  return { type: POST_USER_DATA_SUCCESS, payload: data };
};

const postUserDataFailure = (error) => {
  return { type: POST_USER_DATA_FAILURE, payload: error };
};

const getDepartmentDataRequest = () => {
  return { type: GET_DEPARTMENT_DATA_REQUEST };
};

const getDepartmentDataSuccess = (data) => {
  return { type: GET_DEPARTMENT_DATA_SUCCESS, payload: data };
};

const getDepartmentDataFailure = (error) => {
  return { type: GET_DEPARTMENT_DATA_FAILURE, payload: error };
};

const updateDataRequest = () => {
  return { type: UPDATE_DATA_REQUEST };
};

const updateDataSuccess = (data) => {
  return { type: UPDATE_DATA_SUCCESS, payload: data };
};

const updateDataFailure = (error) => {
  return { type: UPDATE_DATA_FAILURE, payload: error };
};

const currentDataRequest = () => {
  return { type: CURRENT_DATA_REQUEST };
};

const currentDataSuccess = (data) => {
  return { type: CURRENT_DATA_SUCCESS, payload: data };
};

const currentDataFailure = (error) => {
  return { type: CURRENT_DATA_FAILURE, payload: error };
};

const incidentDataRequest = () => {
  return { type: INCIDENT_DATA_REQUEST };
};

const incidentDataSuccess = (data) => {
  return { type: INCIDENT_DATA_SUCCESS, payload: data };
};

const incidentDataFailure = (error) => {
  return { type: INCIDENT_DATA_FAILURE, payload: error };
};

const incidentDetailRequest = () => {
  return { type: INCIDENT_DETAIL_REQUEST };
};

const incidentDetailSuccess = (data) => {
  return { type: INCIDENT_DETAIL_SUCCESS, payload: data };
};

const incidentDetailFailure = (error) => {
  return { type: INCIDENT_DETAIL_FAILURE, payload: error };
};

const DownloadPDFRequest = () => {
  return { type: DOWNLOAD_PDF_REQUEST };
};

const DownloadPDFSuccess = (data) => {
  return { type: DOWNLOAD_PDF_SUCCESS, payload: data };
};

const DownloadPDFFailure = (error) => {
  return { type: DOWNLOAD_PDF_FAILURE, payload: error };
};

const addImageRequest = () => {
  return { type: UPLOAD_IMAGE_REQUEST };
};

const addImageSuccess = (data) => {
  return { type: UPLOAD_IMAGE_SUCCESS, payload: data };
};

const addImageFailure = (error) => {
  return { type: UPLOAD_IMAGE_FAILURE, payload: error };
};

const addedImageRequest = () => {
  return { type: UPLOADED_IMAGE_REQUEST };
};

const addedImageSuccess = (data) => {
  return { type: UPLOADED_IMAGE_SUCCESS, payload: data };
};

const addedImageFailure = (error) => {
  return { type: UPLOADED_IMAGE_FAILURE, payload: error };
};

const addedCertificateRequest = () => {
  return { type: UPLOADED_CERTIFICATE_REQUEST };
};

const addedCertificateSuccess = (data) => {
  return { type: UPLOADED_CERTIFICATE_SUCCESS, payload: data };
};

const postDepartmentRequest = () => {
  return { type: POST_DEPARTMENT_NAME_REQUEST };
};

const postDepartmentSuccess = (data) => {
  return { type: POST_DEPARTMENT_SUCCESS, payload: data };
};

const postDepartmentFailure = (error) => {
  return { type: POST_DEPARTMENT_FAILURE, payload: error };
};

const postDepartmentNameRequest = () => {
  return { type: POST_DEPARTMENT_NAME_REQUEST };
};

const postDepartmentNameSuccess = (data) => {
  return { type: POST_DEPARTMENT_NAME_SUCCESS, payload: data };
};

const postDepartmentNameFailure = (error) => {
  return { type: POST_DEPARTMENT_NAME_FAILURE, payload: error };
};

const departmentAlreadySavedRequest = () => {
  return { type: DEPARTMENT_ALREADY_SAVED_REQUEST };
};

const departmentAlreadySavedSuccess = (data) => {
  return { type: DEPARTMENT_ALREADY_SAVED_SUCCESS, payload: data };
};
const departmentAlreadySavedFailure = (error) => {
  return { type: DEPARTMENT_ALREADY_SAVED_FAILURE, payload: error };
};

const updateDepartRequest = () => {
  return { type: UPDATE_DEPARTMENT_REQUEST };
};

const updateDepartSuccess = (data) => {
  return { type: UPDATE_DEPARTMENT_SUCCESS, payload: data };
};
const updateDepartFailure = (error) => {
  return { type: UPDATE_DEPARTMENT_FAILURE, payload: error };
};
const retrieveDepartRequest = () => {
  return { type: RETRIEVE_DEPARTMENT_REQUEST };
};
const retrieveDepartSuccess = (data) => {
  return { type: RETRIEVE_DEPARTMENT_SUCCESS, payload: data };
};
const retrieveDepartFailure = (error) => {
  return { type: RETRIEVE_DEPARTMENT_FAILURE, payload: error };
};

const deleteDepartRequest = () => {
  return { type: DELETE_DEPARTMENT_REQUEST };
};
const deleteDepartSuccess = (data) => {
  return { type: DELETE_DEPARTMENT_SUCCESS, payload: data };
};
const deleteDepartFailure = (error) => {
  return { type: DELETE_DEPARTMENT_FAILURE, payload: error };
};

// ..................Retrieve all assets............................................

const retrieveAllAssetsRequest = () => {
  return { type: RETRIEVE_ALL_ASSETS_REQUEST };
};

const retrieveAllAssetsSuccess = (data) => {
  return { type: RETRIEVE_ALL_ASSETS_SUCCESS, payload: data };
};

const retrieveAllAssetsFailure = (error) => {
  return { type: RETRIEVE_ALL_ASSETS_FAILURE, payload: error };
};

const retrieveAllAssetsRequestIncident = () => {
  return { type: RETRIEVE_ALL_ASSETS_REQUEST_INCIDENT };
};

const retrieveAllAssetsSuccessIncident = (data) => {
  return { type: RETRIEVE_ALL_ASSETS_SUCCESS_INCIDENT, payload: data };
};

const retrieveAllAssetsFailureIncident = (error) => {
  return { type: RETRIEVE_ALL_ASSETS_FAILURE_INCIDENT, payload: error };
};

// ..................Delete assets............................................

const deleteAssetRequest = () => {
  return { type: DELETE_ASSET_REQUEST };
};

const deleteAssetSuccess = (data) => {
  return { type: DELETE_ASSET_SUCCESS, payload: data };
};

const deleteAssetFailure = (error) => {
  return { type: DELETE_ASSET_FAILURE, payload: error };
};

// ...................Create Service...........................................

const createServiceRequest = () => {
  return { type: CREATE_SERVICE_REQUEST };
};

const createServiceSuccess = (data) => {
  return { type: CREATE_SERVICE_SUCCESS, payload: data };
};

const createServiceFailure = (error) => {
  return { type: CREATE_SERVICE_FAILURE, payload: error };
};

// ...................Create Service Image upload...........................................

const createServiceImageUploadRequest = () => {
  return { type: CREATE_SERVICE_IMAGE_UPLOAD_REQUEST };
};

const createServiceImageUploadSuccess = (data) => {
  return { type: CREATE_SERVICE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const createServiceImageUploadFailure = (error) => {
  return { type: CREATE_SERVICE_IMAGE_UPLOAD_FAILURE, payload: error };
};

const addedCertificateFailure = () => {
  return { type: UPLOADED_CERTIFICATE_FAILURE };
};
// ...................Closed Service...........................................

const closedServiceRequest = () => {
  return { type: CLOSED_SERVICE_REQUEST };
};

const closedServiceSuccess = (data) => {
  return { type: CLOSED_SERVICE_SUCCESS, payload: data };
};

const closedServiceFailure = (error) => {
  return { type: CLOSED_SERVICE_FAILURE, payload: error };
};
// ...................open Service...........................................

const openServiceRequest = () => {
  return { type: OPEN_SERVICE_REQUEST };
};

const openServiceSuccess = (data) => {
  return { type: OPEN_SERVICE_SUCCESS, payload: data };
};

const openServiceFailure = (error) => {
  return { type: OPEN_SERVICE_FAILURE, payload: error };
};

// ................... Service List...........................................

const serviceListRequest = () => {
  return { type: SERVICE_LIST_REQUEST };
};

const serviceListSuccess = (data) => {
  return { type: SERVICE_LIST_SUCCESS, payload: data };
};

const serviceListFailure = (error) => {
  return { type: SERVICE_LIST_FAILURE, payload: error };
};

const serviceListRequestDropdown = () => {
  return { type: SERVICE_LIST_REQUEST_DROPDOWN };
};

const serviceListSuccessDropdown = (data) => {
  return { type: SERVICE_LIST_SUCCESS_DROPDOWN, payload: data };
};

const serviceListFailureDropdown = (error) => {
  return { type: SERVICE_LIST_FAILURE_DROPDOWN, payload: error };
};

// ................... Service Detail...........................................

const serviceDetailRequest = () => {
  return { type: SERVICE_DETAIL_REQUEST };
};

const serviceDetailSuccess = (data) => {
  return { type: SERVICE_DETAIL_SUCCESS, payload: data };
};

const serviceDetailFailure = (error) => {
  return { type: SERVICE_DETAIL_FAILURE, payload: error };
};

// ................... Clear service...........................................

const clearServiceRequest = () => {
  return { type: CLEAR_SERVICE_REQUEST };
};

const clearServiceSuccess = (data) => {
  return { type: CLEAR_SERVICE_SUCCESS, payload: data };
};

const clearServiceFailure = (error) => {
  return { type: CLEAR_SERVICE_FAILURE, payload: error };
};

const uploadQrCodeImageRequest = () => {
  return { type: UPLOAD_QR_CODE_IMAGE_REQUEST };
};

const uploadQrCodeImageSuccess = (data) => {
  return { type: UPLOAD_QR_CODE_IMAGE_SUCCESS, payload: data };
};

const uploadQrCodeImageFailure = (error) => {
  return { type: UPLOAD_QR_CODE_IMAGE_FAILURE, payload: error };
};

const createQRcodeRequest = () => {
  return { type: CREATE_QR_CODE_REQUEST };
};

const createQRcodeSuccess = (data) => {
  return { type: CREATE_QR_CODE_SUCCESS, payload: data };
};

const createQRcodeFailure = (error) => {
  return { type: CREATE_QR_CODE_FAILURE, payload: error };
};

const addAssetRequest = () => {
  return { type: ADD_ASSET_REQUEST };
};

const addAssetSuccess = (data) => {
  return { type: ADD_ASSET_SUCCESS, payload: data };
};

const addAssetFailure = (error) => {
  return { type: ADD_ASSET_FAILURE, payload: error };
};
// ...................Issue...........................................
const serviceIssueRequest = () => {
  return { type: SERVICE_ISSUE_REQUEST };
};

const serviceIssueSuccess = (data) => {
  return { type: SERVICE_ISSUE_SUCCESS, payload: data };
};

const serviceIssueFailure = (error) => {
  return { type: SERVICE_ISSUE_FAILURE, payload: error };
};

const incidentIssueRequest = () => {
  return { type: INCIDENT_ISSUE_REQUEST };
};

const incidentIssueSuccess = (data) => {
  return { type: INCIDENT_ISSUE_SUCCESS, payload: data };
};

const incidentIssueFailure = (error) => {
  return { type: INCIDENT_ISSUE_FAILURE, payload: error };
};
// ...................service image upload...........................................
const serviceImageUploadRequest = () => {
  return { type: SERVICE_IMAGE_UPLOAD_REQUEST };
};

const serviceImageUploadSuccess = (data) => {
  return { type: SERVICE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const serviceImageUploadFailure = (error) => {
  return { type: SERVICE_IMAGE_UPLOAD_FAILURE, payload: error };
};

// ..................Assets Delete............................................

const AssetDeleteRequest = () => {
  return { type: ASSETS_DELETE_REQUEST };
};

const AssetDeleteSuccess = (data) => {
  return { type: ASSETS_DELETE_SUCCESS, payload: data };
};

const AssetDeleteFailure = (error) => {
  return { type: ASSETS_DELETE_FAILURE, payload: error };
};

// ...................logout...........................................
const logOutRequest = () => {
  return { type: LOGOUT_REQUEST };
};

const logOutSuccess = (data) => {
  return { type: LOGOUT_SUCCESS, payload: data };
};

const logOutFailure = (error) => {
  return { type: LOGOUT_FAILURE, payload: error };
};
const editAssetRequest = () => {
  return { type: EDIT_ASSET_REQUEST };
};

const editAssetSuccess = (data) => {
  return { type: EDIT_ASSET_SUCCESS, payload: data };
};

const editAssetFailure = (error) => {
  return { type: EDIT_ASSET_FAILURE, payload: error };
};

const getAssetRequest = () => {
  return { type: GET_ASSET_REQUEST };
};

const getAssetSuccess = (data) => {
  return { type: GET_ASSET_SUCCESS, payload: data };
};

const getAssetFailure = (error) => {
  return { type: GET_ASSET_FAILURE, payload: error };
};

const getSingleUserRequest = () => {
  return { type: GET_SINGLE_USER_REQUEST };
};

const getSingleUserSuccess = (data) => {
  return { type: GET_SINGLE_USER_SUCCESS, payload: data };
};

const getSingleUserFailure = (error) => {
  return { type: GET_SINGLE_USER_FAILURE, payload: error };
};

const deleteImageRequest = () => {
  return { type: DELETE_IMAGE_REQUEST };
};

const deleteImageSuccess = (data) => {
  return { type: DELETE_IMAGE_SUCCESS, payload: data };
};

const deleteImageFailure = (error) => {
  return { type: DELETE_IMAGE_FAILURE, payload: error };
};

const deleteCertificateRequest = () => {
  return { type: DELETE_CERTIFICATE_REQUEST };
};

const deleteCertificateSuccess = (data) => {
  return { type: DELETE_CERTIFICATE_SUCCESS, payload: data };
};

const deleteCertificateFailure = (error) => {
  return { type: DELETE_CERTIFICATE_FAILURE, payload: error };
};

const deleteUserRequest = () => {
  return { type: DELETE_USER_REQUEST };
};

const deleteUserSuccess = (data) => {
  return { type: DELETE_USER_SUCCESS, payload: data };
};

const deleteUserFailure = (error) => {
  return { type: DELETE_USER_FAILURE, payload: error };
};

const contactUsRequest = () => {
  return { type: CONTACT_US_REQUEST };
};

const contactUsSuccess = (data) => {
  return { type: CONTACT_US_SUCCESS, payload: data };
};

const contactUsFailure = (error) => {
  return { type: CONTACT_US_FAILURE, payload: error };
};
const clearUserData = (data) => {
  return { type: CLEAR_USER_DATA, payload: data };
};
// ...................Profile...........................................
const profileRequest = () => {
  return { type: PROFILE_REQUEST };
};

const profileSuccess = (data) => {
  return { type: PROFILE_SUCCESS, payload: data };
};

const profileFailure = (error) => {
  return { type: PROFILE_FAILURE, payload: error };
};

// ...................usermanual...........................................

const userManualRequest = () => {
  return { type: USER_MANUAL_REQUEST };
};

const userManualSuccess = (data) => {
  return { type: USER_MANUAL_SUCCESS, payload: data };
};

const userManualFailure = (error) => {
  return { type: USER_MANUAL_FAILURE, payload: error };
};
// ---------------------------------clear incident image-------------------------
const clearIncidentImageRequest = () => {
  return { type: CLEAR_INCIDENT_IMAGE_REQUEST };
};

const clearIncidentImageSuccess = (data) => {
  return { type: CLEAR_INCIDENT_IMAGE_SUCCESS, payload: data };
};

const clearIncidentImageFailure = (error) => {
  return { type: CLEAR_INCIDENT_IMAGE_FAILURE, payload: error };
};

// ......................................clear incident.............................

const clearIncidentRequest = () => {
  return { type: CLEAR_INCIDENT_REQUEST };
};
const clearIncidentSuccess = (data) => {
  return {
    type: CLEAR_INCIDENT_SUCCESS,
    payload: data,
  };
};
const clearIncidentFailure = (error) => {
  return {
    type: CLEAR_INCIDENT_FAILURE,
    payload: error,
  };
};

const clearIncidentImageRemoveRequest = () => {
  return { type: CLEAR_INCIDENT_IMAGE_REMOVE_REQUEST };
};
const clearIncidentImageRemoveSuccess = (data) => {
  return {
    type: CLEAR_INCIDENT_IMAGE_REMOVE_SUCCESS,
    payload: data,
  };
};
const clearIncidentImageRemoveFailure = (error) => {
  return {
    type: CLEAR_INCIDENT_IMAGE_REMOVE_FAILURE,
    payload: error,
  };
};
const serviceRequestImageRemoveRequest = () => {
  return { type: SERVICE_REQUEST_IMAGE_REMOVAL_REQUEST };
};
const serviceRequestImageRemoveSuccess = (data) => {
  return {
    type: SERVICE_REQUEST_IMAGE_REMOVAL_SUCCESS,
    payload: data,
  };
};
const serviceRequestImageRemoveFailure = (error) => {
  return {
    type: SERVICE_REQUEST_IMAGE_REMOVAL_FAILURE,
    payload: error,
  };
};

const assetDetailsRequest = () => {
  return {
    type: ASSET_DETAILS_REQUEST,
  };
};

const assetDetailsSuccess = (data) => {
  return {
    type: ASSET_DETAILS_SUCCESS,
    payload: data,
  };
};

const assetDetailsFailure = (error) => {
  return {
    type: ASSET_DETAILS_FAILURE,
    payload: error,
  };
};

const getIncidentHistoryRequest = () => {
  return {
    type: GET_INCIDENT_HISTORY_REQUEST,
  };
};

const getIncidentHistorySuccess = (data) => {
  return {
    type: GET_INCIDENT_HISTORY_SUCCESS,
    payload: data,
  };
};

const getIncidentHistoryFailure = (error) => {
  return {
    type: GET_INCIDENT_HISTORY_FAILURE,
    payload: error,
  };
};

const recordIncidentImageUploadRequest = () => {
  return {
    type: RECORD_INCIDENT_IMAGE_UPLOAD_REQUEST,
  };
};

const recordIncidentImageUploadSuccess = (data) => {
  return {
    type: RECORD_INCIDENT_IMAGE_UPLOAD_SUCCESS,
    payload: data,
  };
};

const recordIncidentImageUploadFailure = (error) => {
  return {
    type: RECORD_INCIDENT_IMAGE_UPLOAD_FAILURE,
    payload: error,
  };
};

const recordIncidentImageDeleteRequest = () => {
  return {
    type: RECORD_INCIDENT_IMAGE_DELETE_REQUEST,
  };
};

const recordIncidentImageDeleteSuccess = (data) => {
  return {
    type: RECORD_INCIDENT_IMAGE_DELETE_SUCCESS,
    payload: data,
  };
};

const recordIncidentImageDeleteFailure = (error) => {
  return {
    type: RECORD_INCIDENT_IMAGE_DELETE_FAILURE,
    payload: error,
  };
};

const createIncidentRequest = () => {
  return {
    type: CREATE_INCIDENT_REQUEST,
  };
};

const createIncidentSuccess = (data) => {
  return {
    type: CREATE_INCIDENT_SUCCESS,
    payload: data,
  };
};

const createIncidentFailure = (error) => {
  return {
    type: CREATE_INCIDENT_FAILURE,
    payload: error,
  };
};
export const getNotRequiredRequest = () => {
  return { type: GET_NOTREQUIRED_REQUEST };
};

export const getNotRequiredSuccess = (data) => {
  return { type: GET_NOTREQUIRED_SUCCESS, payload: data };
};

export const getNotRequiredFailure = (error) => {
  return { type: GET_NOTREQUIRED_FAILURE, payload: error };
};

export const getNotCalibratedRequest = () => {
  return { type: GET_NOTCALIBRATED_REQUEST };
};

export const getNotCalibratedSuccess = (data) => {
  return { type: GET_NOTCALIBRATED_SUCCESS, payload: data };
};

export const getNotCalibratedFailure = (error) => {
  return { type: GET_NOTCALIBRATED_FAILURE, payload: error };
};

export const getCalibratedRequest = () => {
  return { type: GET_CALIBRATED_REQUEST };
};

export const getCalibratedSuccess = (data) => {
  return { type: GET_CALIBRATED_SUCCESS, payload: data };
};

export const getCalibratedFailure = (error) => {
  return { type: GET_CALIBRATED_FAILURE, payload: error };
};
const getDashboardRequest = () => {
  return {
    type: GET_DASHBOARD_REQUEST,
  };
};

const getDashboardSuccess = (data) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: data,
  };
};

const getDashboardFailure = (error) => {
  return {
    type: GET_DASHBOARD_FAILURE,
    payload: error,
  };
};

const departmentAssetDetailsRequest = () => {
  return { type: DEPARTMENT_ASSET_DETAILS_REQUEST };
};

const departmentAssetDetailsSuccess = (data) => {
  return {
    type: DEPARTMENT_ASSET_DETAILS_SUCCESS,
    payload: data,
  };
};

const departmentAssetDetailsFailure = (error) => {
  return { type: DEPARTMENT_ASSET_DETAILS_FAILURE, payload: error };
};

//...............................Report..............................

const serviceReportRequest = () => {
  return { type: SERVICE_REPORT_REQUEST };
};

const serviceReportSuccess = (data) => {
  return {
    type: SERVICE_REPORT_SUCCESS,
    payload: data,
  };
};

const serviceReportFailure = (error) => {
  return { type: SERVICE_REPORT_FAILURE, payload: error };
};

const getPmAssetRequest = () => {
  return { type: GET_PM_ASSET_REQUEST };
};

const getPmAssetSuccess = (data) => {
  return {
    type: GET_PM_ASSET_SUCCESS,
    payload: data,
  };
};

const getPmAssetFailure = (error) => {
  return { type: GET_PM_ASSET_FAILURE, payload: error };
};

const postPmCheckListRequest = () => {
  return { type: POST_PM_CHECKLIST_REQUEST };
};

const postPmCheckListSuccess = (data) => {
  return {
    type: POST_PM_CHECKLIST_SUCCESS,
    payload: data,
  };
};

const postPmCheckListFailure = (error) => {
  return { type: POST_PM_CHECKLIST_FAILURE, payload: error };
};

const getPmChecklistPdfRequest = () => {
  return {
    type: PM_CHECKLIST_PDF_REQUEST,
  };
};

const getPmChecklistPdfSuccess = (data) => {
  return {
    type: PM_CHECKLIST_PDF_SUCCESS,
    payload: data,
  };
};

const getPmChecklistPdfFailure = (error) => {
  return {
    type: PM_CHECKLIST_PDF_FAILURE,
    payload: error,
  };
};
const closedIncidentRequest = () => {
  return { type: CLOSED_INCIDENT_REQUEST };
};

const closedIncidentSuccess = (data) => {
  return { type: CLOSED_INCIDENT_SUCCESS, payload: data };
};

const closedIncidentFailure = (error) => {
  return { type: CLOSED_INCIDENT_FAILURE, payload: error };
};
// ...................open Incident..........................................

const openIncidentRequest = () => {
  return { type: OPEN_INCIDENT_REQUEST };
};

const openIncidentSuccess = (data) => {
  return { type: OPEN_INCIDENT_SUCCESS, payload: data };
};

const openIncidentFailure = (error) => {
  return { type: OPEN_INCIDENT_FAILURE, payload: error };
};

// ...................Warranty status..........................................

const warrantyStatusRequest = () => {
  return { type: WARRANTY_STATUS_REQUEST };
};

const warrantyStatusSuccess = (data) => {
  return { type: WARRANTY_STATUS_SUCCESS, payload: data };
};

const warrantyStatusFailure = (error) => {
  return { type: WARRANTY_STATUS_FAILURE, payload: error };
};

// ...................Request Manual..........................................

const requestWarrantyRequest = () => {
  return { type: REQUEST_WARRANTY_REQUEST };
};

const requestWarrantySuccess = (data) => {
  return { type: REQUEST_WARRANTY_SUCCESS, payload: data };
};

const requestWarrantyFailure = (error) => {
  return { type: REQUEST_WARRANTY_FAILURE, payload: error };
};

// ...................Profile edit..........................................

const profileEditRequest = () => {
  return { type: PROFILE_EDIT_REQUEST };
};

const profileEditSuccess = (data) => {
  return { type: PROFILE_EDIT_SUCCESS, payload: data };
};

const profileEditFailure = (error) => {
  return { type: PROFILE_EDIT_FAILURE, payload: error };
};

const clearServiceImgDelRequest = () => {
  return { type: CLEAR_SERVICE_IMAGE_DELETE_REQUEST };
};

const clearServiceImgDelSuccess = (data) => {
  return { type: CLEAR_SERVICE_IMAGE_DELETE_SUCCESS, payload: data };
};

const clearServiceImgDelFailure = (error) => {
  return { type: CLEAR_SERVICE_IMAGE_DELETE_FAILURE, payload: error };
};

const getInvalidTokenSuccess = (data) => {
  return { type: GET_INVALID_TOKEN_SUCCESS, payload: data };
};

const getAuthenticationSuccess = (data) => {
  return { type: GET_AUTHENTICATION_ERROR_SUCCESS, payload: data };
};

const WarrantyRequestRequest = () => {
  return { type: WARRANTY_REQUEST_REQUEST };
};

const WarrantyRequestSuccess = (data) => {
  return { type: WARRANTY_REQUEST_SUCCESS, payload: data };
};

const WarrantyRequestFailure = (error) => {
  return { type: WARRANTY_REQUEST_FAILURE, payload: error };
};

const clearIncidentExpenditureImageRemoveRequest = () => {
  return { type: CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_REQUEST };
};
const clearIncidentExpenditureImageRemoveSuccess = (data) => {
  return {
    type: CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_SUCCESS,
    payload: data,
  };
};
const clearIncidentExpenditureImageRemoveFailure = (error) => {
  return {
    type: CLEAR_INCIDENT_EXPENDITURE_IMAGE_REMOVE_FAILURE,
    payload: error,
  };
};

const serviceExpenditureImageUploadRequest = () => {
  return { type: SERVICE_EXPENDITURE_IMAGE_UPLOAD_REQUEST };
};

const serviceExpenditureImageUploadSuccess = (data) => {
  return { type: SERVICE_EXPENDITURE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const serviceExpenditureImageUploadFailure = (error) => {
  return { type: SERVICE_EXPENDITURE_IMAGE_UPLOAD_FAILURE, payload: error };
};

// ...................WarrantyRequest..........................................

export const WarrantyRequest = (data) => (dispatch) => {
  dispatch(WarrantyRequestRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_NBM_REQUEST_WARRANTY,
    headers: { Authorization: `Bearer ${token}` },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(WarrantyRequestSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(WarrantyRequestFailure(error));
    });
};

//-------------------------Clear Service Receipt-----------------------

const clearServiceReceiptRequest = () => {
  return {
    type: CLEAR_SERVICE_RECEIPT_UPLOAD_REQUEST,
  };
};

const clearServiceReceiptSuccess = (data) => {
  return { type: CLEAR_SERVICE_RECEIPT_UPLOAD_SUCCESS, payload: data };
};

const clearServiceReceiptFailure = (error) => {
  return { type: CLEAR_SERVICE_RECEIPT_UPLOAD_FAILURE, payload: error };
};
//-------------------------Clear Service Receipt  Delete-----------------------

const clearServiceReceiptDeleteRequest = () => {
  return {
    type: CLEAR_SERVICE_RECEIPT_DELETE_REQUEST,
  };
};

const clearServiceReceiptDeleteSuccess = (data) => {
  return { type: CLEAR_SERVICE_RECEIPT_DELETE_SUCCESS, payload: data };
};

const clearServiceReceiptDeleteFailure = (error) => {
  return { type: CLEAR_SERVICE_RECEIPT_DELETE_FAILURE, payload: error };
};

const AmcCmcRequest = () => {
  return { type: AMC_CMC_REQUEST };
};

const AmcCmcSuccess = (data) => {
  return { type: AMC_CMC_SUCCESS, payload: data };
};

const AmcCmcFailure = (error) => {
  return { type: AMC_CMC_FAILURE, payload: error };
};

const createNbmTaskRequest = () => {
  return { type: CREATE_NBM_TASK_REQUEST };
};

const createNbmTaskSuccess = (data) => {
  return { type: CREATE_NBM_TASK_SUCCESS, payload: data };
};

const createNbmTaskFailure = (error) => {
  return { type: CREATE_NBM_TASK_FAILURE, payload: error };
};

const getNbmTaskRequest = () => {
  return { type: GET_NBM_TASK_REQUEST };
};

const getNbmTaskSuccess = (data) => {
  return { type: GET_NBM_TASK_SUCCESS, payload: data };
};

const getNbmTaskFailure = (error) => {
  return { type: GET_NBM_TASK_FAILURE, payload: error };
};

// export const getInvalidToken = (data) => (dispatch) => {
//   // dispatch(getInvalidTokenRequest());

//   dispatch(getInvalidTokenSuccess(data));

//   // dispatch(getInvalidTokenFailure());
// };

export const getInvalidToken = (data) => async (dispatch) => {
  if (data === "INVALID_TOKEN") {
    try {
      const response = await fetch(process.env.REACT_APP_API_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshtoken: Id.refreshToken }),
      });

      const responseData = await response.json();

      if (responseData.token) {
        const newFbToken = responseData.token;
        const updatedId = { ...Id, fbtoken: newFbToken };

        localStorage.setItem("userTokenId", JSON.stringify(updatedId));
        localStorage.setItem("userToken", newFbToken);

        dispatch(getInvalidTokenSuccess(data));

        return newFbToken; // Return the new token
      } else {
        console.log("No new token found in the response data.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching new fbtoken:", error);
      return null;
    }
  } else {
    console.log("Token is valid, no refresh needed.");
    dispatch(getInvalidTokenSuccess(data));
    return null;
  }
};

const userData =
  localStorage.getItem("userData") || sessionStorage.getItem("userData");

if (!userData) {
  localStorage.clear();
}

export const getAuthenticationError = (data) => (dispatch) => {
  // dispatch(getInvalidTokenRequest());

  dispatch(getAuthenticationSuccess(data));

  // dispatch(getInvalidTokenFailure());
};

const postVendorRequest = () => {
  return { type: POST_VENDOR_REQUEST };
};

const postVendorSuccess = (data) => {
  return { type: POST_VENDOR_SUCCESS, payload: data };
};

const postVendorFailure = (error) => {
  return { type: POST_VENDOR_FAILURE, payload: error };
};

const getVendorRequest = () => {
  return { type: GET_VENDOR_REQUEST };
};

const getVendorSuccess = (data) => {
  return { type: GET_VENDOR_SUCCESS, payload: data };
};

const getVendorFailure = (error) => {
  return { type: GET_VENDOR_FAILURE, payload: error };
};

const getVendorServiceRequest = () => {
  return { type: GET_VENDOR_SERVICE_REQUEST };
};

const getVendorServiceSuccess = (data) => {
  return { type: GET_VENDOR_SERVICE_SUCCESS, payload: data };
};

const getVendorServiceFailure = (error) => {
  return { type: GET_VENDOR_SERVICE_FAILURE, payload: error };
};

const postVendorServiceRequest = () => {
  return { type: POST_VENDOR_SERVICE_REQUEST };
};

const postVendorServiceSuccess = (data) => {
  return { type: POST_VENDOR_SERVICE_SUCCESS, payload: data };
};

const postVendorServiceFailure = (error) => {
  return { type: POST_VENDOR_SERVICE_FAILURE, payload: error };
};

const AmcRequest = () => {
  return { type: AMC_REQUEST };
};

const AmcSuccess = (data) => {
  return { type: AMC_SUCCESS, payload: data };
};

const AmcFailure = (error) => {
  return { type: AMC_FAILURE, payload: error };
};
const CmcRequest = () => {
  return { type: CMC_REQUEST };
};

const CmcSuccess = (data) => {
  return { type: CMC_SUCCESS, payload: data };
};

const CmcFailure = (error) => {
  return { type: CMC_FAILURE, payload: error };
};

const AddAssetReportRequest = () => {
  return { type: ASSET_ADD_REPORT_REQUEST };
};

const AddAssetReportSuccess = (data) => {
  return { type: ASSET_ADD_REPORT_SUCCESS, payload: data };
};

const AddAssetReportFailure = (error) => {
  return { type: ASSET_ADD_REPORT_FAILURE, payload: error };
};

const AssetDeleteReportRequest = () => {
  return { type: ASSET_DELETE_REPORT_REQUEST };
};

const AssetDeleteReportSuccess = (data) => {
  return { type: ASSET_DELETE_REPORT_SUCCESS, payload: data };
};

const AssetDeleteReportFailure = (error) => {
  return { type: ASSET_DELETE_REPORT_FAILURE, payload: error };
};

const deleteCsvFileUploadRequest = () => {
  return { type: DELETE_CSV_FILE_UPLOAD_REQUEST };
};

const deleteCsvFileUploadSuccess = (data) => {
  return { type: DELETE_CSV_FILE_UPLOAD_SUCCESS, payload: data };
};

const deleteCsvFileUploadFailure = (error) => {
  return { type: DELETE_CSV_FILE_UPLOAD_FAILURE, payload: error };
};

const csvFileUploadSubmitRequest = () => {
  return { type: CSV_FILE_UPLOAD_SUBMIT_REQUEST };
};

const csvFileUploadSubmitSuccess = (data) => {
  return { type: CSV_FILE_UPLOAD_SUBMIT_SUCCESS, payload: data };
};

const csvFileUploadSubmitFailure = (error) => {
  return { type: CSV_FILE_UPLOAD_SUBMIT_FAILURE, payload: error };
};

const csvFileUploadRequest = () => {
  return { type: CSV_FILE_UPLOAD_REQUEST };
};

const csvFileUploadSuccess = (data) => {
  return { type: CSV_FILE_UPLOAD_SUCCESS, payload: data };
};

const csvFileUploadFailure = (error) => {
  return { type: CSV_FILE_UPLOAD_FAILURE, payload: error };
};

const assetHistoryRequest = () => {
  return { type: ASSET_HISTORY_REQUEST };
};

const assetHistorySuccess = (data) => {
  return { type: ASSET_HISTORY_SUCCESS, payload: data };
};

const assetHistoryFailure = (error) => {
  return { type: ASSET_HISTORY_FAILURE, payload: error };
};

const assetHistoryRetrieveRequest = () => {
  return { type: ASSET_HISTORY_RETRIEVE_REQUEST };
};

const assetHistoryRetrieveSuccess = (data) => {
  return { type: ASSET_HISTORY_RETRIEVE_SUCCESS, payload: data };
};

const assetHistoryRetrieveFailure = (error) => {
  return { type: ASSET_HISTORY_RETRIEVE_FAILURE, payload: error };
};

const assetHistoryDropdownRetrieveRequest = () => {
  return { type: ASSET_HISTORY_DROPDOWN_RETRIEVE_REQUEST };
};

const assetHistoryDropdownRetrieveSuccess = (data) => {
  return { type: ASSET_HISTORY_DROPDOWN_RETRIEVE_SUCCESS, payload: data };
};

const assetHistoryDropdownRetrieveFailure = (error) => {
  return { type: ASSET_HISTORY_DROPDOWN_RETRIEVE_FAILURE, payload: error };
};

const checkOutRequest = () => {
  return { type: CHECKOUT_REQUEST };
};

const checkOutSuccess = (data) => {
  return { type: CHECKOUT_SUCCESS, payload: data };
};

const checkOutFailure = (error) => {
  return { type: CHECKOUT_FAILURE, payload: error };
};

const checkInRequest = () => {
  return { type: CHECKIN_REQUEST };
};

const checkInSuccess = (data) => {
  return { type: CHECKIN_SUCCESS, payload: data };
};

const checkInFailure = (error) => {
  return { type: CHECKIN_FAILURE, payload: error };
};

const checkInOutRequest = () => {
  return { type: CHECKIN_CHECKOUT_REQUEST };
};

const checkInOutSuccess = (data) => {
  return { type: CHECKIN_CHECKOUT_SUCCESS, payload: data };
};

const checkInOutFailure = (error) => {
  return { type: CHECKIN_CHECKOUT_FAILURE, payload: error };
};

const checkInOutDetailRequest = () => {
  return { type: CHECKIN_CHECKOUT_DETAIL_REQUEST };
};

const checkInOutDetailSuccess = (data) => {
  return { type: CHECKIN_CHECKOUT_DETAIL_SUCCESS, payload: data };
};

const checkInOutDetailFailure = (error) => {
  return { type: CHECKIN_CHECKOUT_DETAIL_FAILURE, payload: error };
};

const CalenderRequest = () => {
  return { type: CALENDER_REQUEST };
};

export const CalenderSuccess = (data) => {
  return { type: CALENDER_SUCCESS, payload: data };
};

const CalenderFailure = (error) => {
  return { type: CALENDER_FAILURE, payload: error };
};

const CheckInPdfRequest = () => {
  return { type: CHECKIN_PDF_REQUEST };
};

const CheckInPdfSuccess = (data) => {
  return { type: CHECKIN_PDF_SUCCESS, payload: data };
};

const CheckInPdfFailure = (error) => {
  return { type: CHECKIN_PDF_FAILURE, payload: error };
};

const AmcCmcReqRequest = () => {
  return { type: AMC_CMC_REQ_REQUEST };
};

const AmcCmcReqSuccess = (data) => {
  return { type: AMC_CMC_REQ_SUCCESS, payload: data };
};

const AmcCmcReqFailure = (error) => {
  return { type: AMC_CMC_REQ_FAILURE, payload: error };
};

const CalibrationReqRequest = () => {
  return { type: CALIBRATION_REQ_REQUEST };
};

const CalibrationReqSuccess = (data) => {
  return { type: CALIBRATION_REQ_SUCCESS, payload: data };
};

const CalibrationReqFailure = (error) => {
  return { type: CALIBRATION_REQ_FAILURE, payload: error };
};

const WarrantyTaskCompletedRequest = () => {
  return { type: WARRANTY_TASK_COMPLETE_REQUEST };
};

const WarrantyTaskCompletedSuccess = (data) => {
  return { type: WARRANTY_TASK_COMPLETE_SUCCESS, payload: data };
};

const WarrantyTaskCompletedFailure = (error) => {
  return { type: WARRANTY_TASK_COMPLETE_FAILURE, payload: error };
};

const AmcCmcTaskCompletedRequest = () => {
  return { type: AMC_CMC_TASK_COMPLETE_REQUEST };
};

const AmcCmcTaskCompletedSuccess = (data) => {
  return { type: AMC_CMC_TASK_COMPLETE_SUCCESS, payload: data };
};

const AmcCmcTaskCompletedFailure = (error) => {
  return { type: AMC_CMC_TASK_COMPLETE_FAILURE, payload: error };
};

const CalibrationTaskCompletedRequest = () => {
  return { type: CALIBRATION_TASK_COMPLETE_REQUEST };
};

const CalibrationTaskCompletedSuccess = (data) => {
  return { type: CALIBRATION_TASK_COMPLETE_SUCCESS, payload: data };
};

const CalibrationTaskCompletedFailure = (error) => {
  return { type: CALIBRATION_TASK_COMPLETE_FAILURE, payload: error };
};

const updateReportRequest = () => {
  return { type: UPDATE_REPORT_REQUEST };
};

const updateReportSuccess = (data) => {
  return { type: UPDATE_REPORT_SUCCESS, payload: data };
};

const updateReportFailure = (error) => {
  return { type: UPDATE_REPORT_FAILURE, payload: error };
};

const createSubuserRequest = () => {
  return { type: CREATE_NBM_SUBUSER_REQUEST };
};

const createSubuserSuccess = (data) => {
  return { type: CREATE_NBM_SUBUSER_SUCCESS, payload: data };
};

const createSubuserFailure = (error) => {
  return { type: CREATE_NBM_SUBUSER_FAILURE, payload: error };
};

const getNewAssetsRequest = () => {
  return { type: GET_NEW_ASSETS_REQUEST };
};

const getNewAssetsSuccess = (data) => {
  return { type: GET_NEW_ASSETS_SUCCESS, payload: data };
};

const getNewAssetsFailure = (error) => {
  return { type: GET_NEW_ASSETS_FAILURE, payload: error };
};

const userRetrieveByBranchRequest = () => {
  return { type: USER_RETRIEVE_BY_BRANCH_REQUEST };
};

const userRetrieveByBranchSuccess = (data) => {
  return {
    type: USER_RETRIEVE_BY_BRANCH_SUCCESS,
    payload: data,
  };
};
const userRetrieveByBranchFailure = (error) => {
  return { type: USER_RETRIEVE_BY_BRANCH_FAILURE, payload: error };
};

const requestDashboard = () => {
  return { type: GET_REQUEST_DASHBOARD };
};
const getRequestDashboardSuccess = (data) => {
  return { type: GET_REQUEST_DASHBOARD_SUCCESS, payload: data };
};
const getRequestDashboardFailure = (error) => {
  return { type: GET_REQUEST_DASHBOARD_FAILURE, payload: error };
};

const requestDashboardNBM = () => {
  return { type: GET_REQUEST_DASHBOARD_NBM };
};
const getRequestDashboardSuccessNBM = (data) => {
  return { type: GET_REQUEST_DASHBOARD_SUCCESS_NBM, payload: data };
};
const getRequestDashboardFailureNBM = (error) => {
  return { type: GET_REQUEST_DASHBOARD_FAILURE_NBM, payload: error };
};

const incidentExpenditureImageUploadRequest = () => {
  return { type: INCIDENT_EXPENDITURE_IMAGE_UPLOAD_REQUEST };
};

const incidentExpenditureImageUploadSuccess = (data) => {
  return { type: INCIDENT_EXPENDITURE_IMAGE_UPLOAD_SUCCESS, payload: data };
};

const incidentExpenditureImageUploadFailure = (error) => {
  return { type: INCIDENT_EXPENDITURE_IMAGE_UPLOAD_FAILURE, payload: error };
};

const clearServiceExpenditureImageRemoveRequest = () => {
  return { type: CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_REQUEST };
};
const clearServiceExpenditureImageRemoveSuccess = (data) => {
  return {
    type: CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_SUCCESS,
    payload: data,
  };
};
const clearServiceExpenditureImageRemoveFailure = (error) => {
  return {
    type: CLEAR_SERVICE_EXPENDITURE_IMAGE_REMOVE_FAILURE,
    payload: error,
  };
};

const checkoutassetRequest = () => {
  return { type: CHECKOUT_ASSET_REQUEST };
};

const checkoutassetSuccess = (data) => {
  return { type: CHECKOUT_ASSET_SUCCESS, payload: data };
};

const checkoutassetFailure = (error) => {
  return { type: CHECKOUT_ASSET_FAILURE, payload: error };
};

// ..................Assets not working............................................

const assetsnotworkingRequest = () => {
  return { type: NOT_WORKING_ASSETS_REQUEST };
};

const assetsnotworkingSuccess = (data) => {
  return { type: NOT_WORKING_ASSETS_SUCCESS, payload: data };
};

const assetsnotworkingFailure = (error) => {
  return { type: NOT_WORKING_ASSETS_FAILURE, payload: error };
};

// ..................Assets discarded............................................

const discardedRequest = () => {
  return { type: DISCARDED_ASSETS_REQUEST };
};

const discardedSuccess = (data) => {
  return { type: DISCARDED_ASSETS_SUCCESS, payload: data };
};

const discardedFailure = (error) => {
  return { type: DISCARDED_ASSETS_FAILURE, payload: error };
};

// ..................Deleted assets List............................................

const deletedDataAssetRequest = () => {
  return { type: DELETEDDATA_ASSET_REQUEST };
};

const deletedDataAssetSuccess = (data) => {
  return { type: DELETEDDATA_ASSET_SUCCESS, payload: data };
};

const deletedDataAssetFailure = (error) => {
  return { type: DELETEDDATA_ASSET_FAILURE, payload: error };
};

// ........................... Deleted Incident List Data ................

const IncidentDataDeleteRequest = () => {
  return {
    type: INCIDENTDATA_DELETE_REQUEST,
  };
};

const IncidentDataDeleteSuccess = (data) => {
  return {
    type: INCIDENTDATA_DELETE_SUCCESS,
    payload: data,
  };
};

const IncidentDataDeleteFailure = (error) => {
  return {
    type: INCIDENTDATA_DELETE_FAILURE,
    payload: error,
  };
};

// ........................... Delete Incident  ................

const incidentDeleteRequest = () => {
  return {
    type: INCIDENT_DELETE_REQUEST,
  };
};

const incidentDeleteSuccess = (data) => {
  return {
    type: INCIDENT_DELETE_SUCCESS,
    payload: data,
  };
};

const incidentDeleteFailure = (error) => {
  return {
    type: INCIDENT_DELETE_FAILURE,
    payload: error,
  };
};

// ..................Depertment Deleted list ............................................

const departmentDeleteListRequest = () => {
  return {
    type: DEPARTMENT_DELETE_LIST_REQUEST,
  };
};

const departmentDeleteListSuccess = (data) => {
  return {
    type: DEPARTMENT_DELETE_LIST_SUCCESS,
    payload: data,
  };
};

const departmentDeleteListFailure = (error) => {
  return {
    type: DEPARTMENT_DELETE_LIST_FAILURE,
    payload: error,
  };
};

// ..................Add Generate Ticket ............................................

const AddGenerateTicketRequest = () => {
  return {
    type: ADD_GENERATE_TICKET_REQUEST,
  };
};

const AddGenerateTicketSuccess = (data) => {
  return {
    type: ADD_GENERATE_TICKET_SUCCESS,
    payload: data,
  };
};

const AddGenerateTicketFailure = (error) => {
  return {
    type: ADD_GENERATE_TICKET_FAILURE,
    payload: error,
  };
};

// ..................List Generate Ticket ............................................

const ListGenerateTicketRequest = () => {
  return {
    type: LIST_GENERATE_TICKET_REQUEST,
  };
};

const ListGenerateTicketSuccess = (data) => {
  return {
    type: LIST_GENERATE_TICKET_SUCCESS,
    payload: data,
  };
};

const ListGenerateTicketFailure = (error) => {
  return {
    type: LIST_GENERATE_TICKET_FAILURE,
    payload: error,
  };
};

// ..................Clear Generate Ticket ............................................

const ClearGenerateTicketRequest = () => {
  return {
    type: CLEAR_GENERATE_TICKET_REQUEST,
  };
};

const ClearGenerateTicketSuccess = (data) => {
  return {
    type: CLEAR_GENERATE_TICKET_SUCCESS,
    payload: data,
  };
};

const ClearGenerateTicketFailure = (error) => {
  return {
    type: CLEAR_GENERATE_TICKET_FAILURE,
    payload: error,
  };
};

// .................. Generate Ticket Details............................................

const GenerateTicketDetailsRequest = () => {
  return {
    type: GENERATE_TICKET_DETAILS_REQUEST,
  };
};

const GenerateTicketDetailsSuccess = (data) => {
  return {
    type: GENERATE_TICKET_DETAILS_SUCCESS,
    payload: data,
  };
};

const GenerateTicketDetailsFailure = (error) => {
  return {
    type: GENERATE_TICKET_DETAILS_FAILURE,
    payload: error,
  };
};

// ..................Grade Update............................................

const GradeUpdateRequest = () => {
  return {
    type: GRADE_UPDATE_REQUEST,
  };
};

const GradeUpdateSuccess = (data) => {
  return {
    type: GRADE_UPDATE_SUCCESS,
    payload: data,
  };
};

const GradeUpdateFailure = (error) => {
  return {
    type: GRADE_UPDATE_FAILURE,
    payload: error,
  };
};

// .................. Generate Ticket Acknowledge............................................

const GenerateTicketAcknowledgeRequest = () => {
  return {
    type: GENERATE_TICKET_ACKNOWLEDGE_REQUEST,
  };
};

const GenerateTicketAcknowledgeSuccess = (data) => {
  return {
    type: GENERATE_TICKET_ACKNOWLEDGE_SUCCESS,
    payload: data,
  };
};

const GenerateTicketAcknowledgeFailure = (error) => {
  return {
    type: GENERATE_TICKET_ACKNOWLEDGE_FAILURE,
    payload: error,
  };
};

export const clearServiceImgDel = (data) => async (dispatch) => {
  dispatch(clearServiceImgDelRequest());
  let token = localStorage.getItem("userToken");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_SERVICE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(clearServiceImgDel(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(clearServiceImgDelSuccess(res.data));
    }
  } catch (error) {
    dispatch(clearServiceImgDelFailure(error));
  }
};
export const postData = (data) => async (dispatch) => {
  dispatch(postDataRequest());
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_REQUEST_DEMO,
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(postData(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(postDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(postDataFailure(error));
  }
};

export const getCount = (data) => (dispatch) => {
  dispatch(countRequest());
  dispatch(countSuccess(data));
  dispatch(countFailure());
};

export const getUserData = (data) => async (dispatch) => {
  dispatch(getUserDataRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_USERS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(getUserData(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getUserDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(getUserDataFailure(error));
  }
};

export const postUserData = (data) => async (dispatch) => {
  dispatch(postUserDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_POST_SUB_USERS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(postUserData(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(postUserDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(postUserDataFailure(error));
  }
};

export const getDepartmentData = (data) => async (dispatch) => {
  dispatch(getDepartmentDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_DEPARTMENTS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(getDepartmentData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getDepartmentDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(getDepartmentDataFailure(error));
  }
};

export const updateData = (data) => async (dispatch) => {
  dispatch(updateDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_POST_SUBUSER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(updateData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(updateDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(updateDataFailure(error));
  }
};

export const currentData = (data) => async (dispatch) => {
  dispatch(currentDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_POST_SUBUSER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(currentData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(currentDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(currentDataFailure(error));
  }
};

export const incidentData = (data) => async (dispatch) => {
  dispatch(incidentDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_INCIDENTS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(incidentData(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(incidentDataSuccess(res.data));
    }
  } catch (error) {
    dispatch(incidentDataFailure(error));
  }
};

export const getIncidentDetail = (data) => async (dispatch) => {
  dispatch(incidentDetailRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_INCIDENT_DETAILS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(getIncidentDetail(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(incidentDetailSuccess(res.data));
    }
  } catch (error) {
    dispatch(incidentDetailFailure(error));
  }
};

export const DownloadPDF = (pdf, title) => (dispatch) => {
  dispatch(DownloadPDFRequest());
  axios({
    url: `${pdf}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, `${title}`);
      dispatch(DownloadPDFSuccess(response.data));
    })
    .catch((error) => {
      dispatch(DownloadPDFFailure(error));
    });
};

export const uploadImage = (data) => async (dispatch) => {
  dispatch(addImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_POST_UPLOAD_ASSET_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(uploadImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

export const uploadedImage = (data) => async (dispatch) => {
  dispatch(addedImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_UPLOAD_ASSET_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(uploadedImage(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(addedImageSuccess(resData));
    }
  } catch (error) {
    dispatch(addedImageFailure(error));
  }
};

export const uploadedCertificate = (data) => async (dispatch) => {
  dispatch(addedCertificateRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_ASSET_WARRANTY_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(uploadedCertificate(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(addedCertificateSuccess(resData));
    }
  } catch (error) {
    dispatch(addedCertificateFailure(error));
  }
};

export const uploadQrCodeImage = (data) => async (dispatch) => {
  dispatch(uploadQrCodeImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_ASSET_QRCODE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(uploadQrCodeImage(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(uploadQrCodeImageSuccess(resData));
    }
  } catch (error) {
    dispatch(uploadQrCodeImageFailure(error));
  }
};

export const CreateQRCode = (data) => (dispatch) => {
  dispatch(createQRcodeRequest());
  QRCode.toDataURL(data, { width: 300 })
    .then((url) => {
      dispatch(createQRcodeSuccess(url));
    })
    .catch((error) => {
      dispatch(createQRcodeFailure(error));
    });
};

export const addAsset = (data) => async (dispatch) => {
  dispatch(addAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_ADD_ASSET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(addAsset(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(addAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(addAssetFailure(error));
  }
};

export const postDepartment = (data) => async (dispatch) => {
  dispatch(postDepartmentRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_USER_GET_ALL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postDepartment(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postDepartmentSuccess(resData));
    }
  } catch (error) {
    dispatch(postDepartmentFailure(error));
  }
};

export const postDepartmentName = (data) => async (dispatch) => {
  dispatch(postDepartmentNameRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_POST_DEPARTMENT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postDepartmentName(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postDepartmentNameSuccess(resData));
    }
  } catch (error) {
    dispatch(postDepartmentNameFailure(error));
  }
};

export const departmentAlreadySaved = (data) => async (dispatch) => {
  dispatch(departmentAlreadySavedRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(departmentAlreadySaved(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(departmentAlreadySavedSuccess(resData));
    }
  } catch (error) {
    dispatch(departmentAlreadySavedFailure(error));
  }
};

export const getNewAssets = (data) => async (dispatch) => {
  dispatch(getNewAssetsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_NEW_ASSETS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(getNewAssets(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getNewAssetsSuccess(res.data));
    }
  } catch (error) {
    dispatch(getNewAssetsFailure(error));
  }
};

export const updateDepartment = (data) => async (dispatch) => {
  dispatch(updateDepartRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPDATE_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(updateDepartment(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(updateDepartSuccess(resData));
    }
  } catch (error) {
    dispatch(updateDepartFailure(error));
  }
};

export const retrieveDepart = (data) => async (dispatch) => {
  dispatch(retrieveDepartRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_SINGLE_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(retrieveDepart(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(retrieveDepartSuccess(resData));
    }
  } catch (error) {
    dispatch(retrieveDepartFailure(error));
  }
};

export const deleteDepart = (data) => (dispatch) => {
  dispatch(deleteDepartRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_NBM_DELETE_DEPARTMENT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(deleteDepartSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(deleteDepartFailure(error));
    });
};

// ..................Retrieve all assets............................................

export const retrieveAllAssets = (data) => async (dispatch) => {
  dispatch(retrieveAllAssetsRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(retrieveAllAssets(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(retrieveAllAssetsSuccess(res.data));
    }
  } catch (error) {
    dispatch(retrieveAllAssetsFailure(error));
  }
};

export const retrieveAllAssetsIncident = (data) => async (dispatch) => {
  dispatch(retrieveAllAssetsRequestIncident());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_ASSETS_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(retrieveAllAssetsIncident(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(retrieveAllAssetsSuccessIncident(res.data));
    }
  } catch (error) {
    dispatch(retrieveAllAssetsFailureIncident(error));
  }
};

// ..................Delete assets............................................

export const deleteAsset = (data) => async (dispatch) => {
  dispatch(deleteAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteAsset(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteAssetFailure(error));
  }
};

// ...................Create Service...........................................

export const createService = (data) => async (dispatch) => {
  dispatch(createServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CREATE_SERVICE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(createServiceFailure(error));
  }
};

// ...................Create Service Image upload...........................................

export const createServiceImageUpload = (data) => async (dispatch) => {
  dispatch(createServiceImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_SERVICE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createServiceImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createServiceImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(createServiceImageUploadFailure(error));
  }
};

// ...................Closed Service ...........................................

export const closedService = (data) => async (dispatch) => {
  dispatch(closedServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLOSE_SERVICE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(closedService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(closedServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(closedServiceFailure(error));
  }
};

// ...................open Service...........................................
export const openService = (data) => async (dispatch) => {
  dispatch(openServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_RETRIEVE_OPEN_SERVICES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(openService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(openServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(openServiceFailure(error));
  }
};
// ................... Service List...........................................

export const serviceList = (data) => async (dispatch) => {
  dispatch(serviceListRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_SERVICES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceList(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceListSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceListFailure(error));
  }
};

export const serviceListDropdown = (data) => (dispatch) => {
  dispatch(serviceListRequestDropdown());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_NBM_SERVICES,
    headers: { Authorization: `Bearer ${token}` },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(serviceListSuccessDropdown(res.data));
      }
    })
    .catch((error) => {
      dispatch(serviceListFailureDropdown(error));
    });
};

// ................... Service Detail...........................................

export const serviceDetail = (data) => async (dispatch) => {
  dispatch(serviceDetailRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_SERVICES_DETAILS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceDetail(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceDetailSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceDetailFailure(error));
  }
};

// ................... Clear service...........................................

export const clearService = (data) => async (dispatch) => {
  dispatch(clearServiceRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_SERVICES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearService(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(clearServiceFailure(error));
  }
};

// ................... Service issue..........................................

export const serviceIssue = (data) => async (dispatch) => {
  dispatch(serviceIssueRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_RETERIVE_ALL_SERVICE_ISSUE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceIssue(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceIssueSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceIssueFailure(error));
  }
};

export const incidentIssue = (data) => async (dispatch) => {
  dispatch(incidentIssueRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ALL_INCIDENT_ISSUE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(incidentIssue(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(incidentIssueSuccess(resData));
    }
  } catch (error) {
    dispatch(incidentIssueFailure(error));
  }
};
// ................... Service Image uplaod..........................................

export const serviceImageUpload = (data) => async (dispatch) => {
  dispatch(serviceImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_CLEARED_SERVICE_IMAGES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceImageUploadFailure(error));
  }
};
// ................... Logout..........................................

export const logOut = (data) => async (dispatch) => {
  dispatch(logOutRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_LOGOUT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(logOut(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(logOutSuccess(res.data));
    }
  } catch (error) {
    dispatch(logOutFailure(error));
  }
};

export const editAssetFun = (data) => async (dispatch) => {
  dispatch(editAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_EDIT_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(editAssetFun(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(editAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(editAssetFailure(error));
  }
};

export const getAsset = (data) => async (dispatch) => {
  dispatch(getAssetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_SINGLE_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getAsset(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(getAssetFailure(error));
  }
};

export const getSingleUser = (data) => async (dispatch) => {
  dispatch(getSingleUserRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_SINGLE_USER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getSingleUser(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getSingleUserSuccess(resData));
    }
  } catch (error) {
    dispatch(getSingleUserFailure(error));
  }
};

export const deleteImage = (data) => async (dispatch) => {
  dispatch(deleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_ASSET_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteImage(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const deleteCertificate = (data) => async (dispatch) => {
  dispatch(deleteCertificateRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_WARRANTY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteCertificate(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteCertificateSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteCertificateFailure(error));
  }
};

export const deleteUser = (data) => async (dispatch) => {
  dispatch(deleteUserRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_SUBUSER,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteUser(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteUserSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteUserFailure(error));
  }
};

export const clearData = () => (dispatch) => {
  dispatch(clearUserData([]));
};

export const contactUs = (data) => async (dispatch) => {
  dispatch(contactUsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CONTACT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(contactUs(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(contactUsSuccess(resData));
    }
  } catch (error) {
    dispatch(contactUsFailure(error));
  }
};
// ...................Profile...........................................
export const profile = (data) => async (dispatch) => {
  dispatch(profileRequest());
  let token = localStorage.getItem("userToken"); // Ensure latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_PROFILE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(profile(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(profileSuccess(resData));
    }
  } catch (error) {
    dispatch(profileFailure(error));
  }
};

export const postRegister = (data) => async (dispatch) => {
  dispatch(postRegisterRequest());
  let token = localStorage.getItem("userToken"); // Ensure latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPDATE_PROFILE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postRegister(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postRegisterSuccess(resData));
    }
  } catch (error) {
    dispatch(postRegisterFailure(error));
  }
};
// ...................usermanual...........................................
export const userManual = (data) => async (dispatch) => {
  dispatch(userManualRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_ASSETS_MANUAL,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(userManual(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(userManualSuccess(resData));
    }
  } catch (error) {
    dispatch(userManualFailure(error));
  }
};

// ---------------------------------clear incident image-------------------------
export const clearIncidentImageUpload = (data) => async (dispatch) => {
  dispatch(clearIncidentImageRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_CLEAR_INCIDENT_IMAGES,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearIncidentImageUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearIncidentImageSuccess(resData));
    }
  } catch (error) {
    dispatch(clearIncidentImageFailure(error));
  }
};
// ......................................clear incident.............................

export const clearIncident = (data) => async (dispatch) => {
  dispatch(clearIncidentRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(clearIncidentFailure(error));
  }
};
export const clearIncidentImageRemove = (data) => async (dispatch) => {
  dispatch(clearIncidentImageRemoveRequest());
  let token = localStorage.getItem("userToken"); // Ensure the latest token is used

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_INCIDENT_IMAGE_REMOVE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(clearIncidentImageRemove(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(clearIncidentImageRemoveSuccess(resData));
    }
  } catch (error) {
    dispatch(clearIncidentImageRemoveFailure(error));
  }
};
export const serviceRequestImageRemove = (data) => async (dispatch) => {
  dispatch(serviceRequestImageRemoveRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_SERVICES_IMAGE_REMOVE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceRequestImageRemove(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceRequestImageRemoveSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceRequestImageRemoveFailure(error));
  }
};

export const assetDetailsData = (data) => async (dispatch) => {
  dispatch(assetDetailsRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_ASSET_DETAILS_DATA,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(assetDetailsData(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetDetailsSuccess(resData));
    }
  } catch (error) {
    dispatch(assetDetailsFailure(error));
  }
};

export const recordIncidentImageUpload = (data) => async (dispatch) => {
  dispatch(recordIncidentImageUploadRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_RECORD_INCIDENT_IMAGE_UPLOAD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(recordIncidentImageUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(recordIncidentImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(recordIncidentImageUploadFailure(error));
  }
};

export const recordIncidentImageDelete = (data) => async (dispatch) => {
  dispatch(recordIncidentImageDeleteRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_INCIDENTS_DELETE_RECORD_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(recordIncidentImageDelete(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(recordIncidentImageDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(recordIncidentImageDeleteFailure(error));
  }
};

export const createIncident = (data) => async (dispatch) => {
  dispatch(createIncidentRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CREATE_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(createIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(createIncidentFailure(error));
  }
};

export const getIncidentHistory = (data) => async (dispatch) => {
  dispatch(getIncidentHistoryRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_INCIDENT_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getIncidentHistory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getIncidentHistorySuccess(resData));
    }
  } catch (error) {
    dispatch(getIncidentHistoryFailure(error));
  }
};

export const getCalibrated = (data) => async (dispatch) => {
  dispatch(getCalibratedRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_CALIBRATED_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getCalibrated(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getCalibratedSuccess(resData));
    }
  } catch (error) {
    dispatch(getCalibratedFailure(error));
  }
};

export const getNotCalibrated = (data) => async (dispatch) => {
  dispatch(getNotCalibratedRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_NOTCALIBRATED_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getNotCalibrated(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getNotCalibratedSuccess(resData));
    }
  } catch (error) {
    dispatch(getNotCalibratedFailure(error));
  }
};

export const getNotRequired = (data) => async (dispatch) => {
  dispatch(getNotRequiredRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_NOTREQUIRED_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getNotRequired(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getNotRequiredSuccess(resData));
    }
  } catch (error) {
    dispatch(getNotRequiredFailure(error));
  }
};

export const departmentAssetDetails = (data) => async (dispatch) => {
  dispatch(departmentAssetDetailsRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_ASSETS_BY_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(departmentAssetDetails(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(departmentAssetDetailsSuccess(resData));
    }
  } catch (error) {
    dispatch(departmentAssetDetailsFailure(error));
  }
};

//.............................Report...............................

export const serviceReport = (data, report) => async (dispatch) => {
  dispatch(serviceReportRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_NBM_GET_REPORT_DATA}${report}1`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceReport(data, report)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceReportSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceReportFailure(error));
  }
};

export const getPmAsset = (data) => async (dispatch) => {
  dispatch(getPmAssetRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_PM_ASSETS,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getPmAsset(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getPmAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(getPmAssetFailure(error));
  }
};

export const postPmCheckList = (data) => async (dispatch) => {
  dispatch(postPmCheckListRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CREATE_PM_CHECKLIST,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postPmCheckList(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(postPmCheckListSuccess(resData));
    }
  } catch (error) {
    dispatch(postPmCheckListFailure(error));
  }
};

export const postPmCheckListPdf = (data) => async (dispatch) => {
  dispatch(getPmChecklistPdfRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_PM_REPORT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postPmCheckListPdf(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getPmChecklistPdfSuccess(resData));
    }
  } catch (error) {
    dispatch(getPmChecklistPdfFailure(error));
  }
};

// ...................open Incident..........................................
export const openIncident = (data) => async (dispatch) => {
  dispatch(openIncidentRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_OPEN_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(openIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(openIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(openIncidentFailure(error));
  }
};
// ...................Closed Incident..........................................

export const closedIncident = (data) => async (dispatch) => {
  dispatch(closedIncidentRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_CLOSE_INCIDENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(closedIncident(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(closedIncidentSuccess(resData));
    }
  } catch (error) {
    dispatch(closedIncidentFailure(error));
  }
};

// ...................warranty status..........................................

export const warrantyStatus = (data) => async (dispatch) => {
  dispatch(warrantyStatusRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_GET_WARRANTY_STATUS,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(warrantyStatus(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(warrantyStatusSuccess(resData));
    }
  } catch (error) {
    dispatch(warrantyStatusFailure(error));
  }
};
// ...................Request Manual..........................................

export const requestWarranty = (data) => async (dispatch) => {
  dispatch(requestWarrantyRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_REQUEST_WARRANTY,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(requestWarranty(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(requestWarrantySuccess(resData));
    }
  } catch (error) {
    dispatch(requestWarrantyFailure(error));
  }
};

// ...................Profile Edit..........................................

export const profileEdit = (data) => async (dispatch) => {
  dispatch(profileEditRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_UPDATE_USER_HOSPITAL_DATA,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(profileEdit(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(profileEditSuccess(resData));
    }
  } catch (error) {
    dispatch(profileEditFailure(error));
  }
};

export const getDashboard = (data) => async (dispatch) => {
  dispatch(getDashboardRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_GET_DASHBOARD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(getDashboard(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      if (res.data?.user_access) {
        const convertArraysToStrings = (obj) => {
          const newObj = { ...obj };

          const processArrayField = (field, exceptionValue) => {
            if (Array.isArray(newObj[field])) {
              if (newObj[field].length === 1) {
                newObj[field] =
                  newObj[field][0] !== exceptionValue
                    ? newObj[field]
                    : newObj[field][0];
              }
            }
          };

          processArrayField("departments", "All");
          processArrayField("departmentids", "all");

          Object.keys(newObj).forEach((key) => {
            processArrayField(key, "All");
            processArrayField(key, "all");
          });

          return newObj;
        };

        const convertedData = convertArraysToStrings(res.data?.user_access);
        localStorage.setItem("user_access", JSON.stringify(convertedData));
        sessionStorage.setItem("user_access", JSON.stringify(convertedData));
      }
      dispatch(getDashboardSuccess(res.data));
    }
  } catch (error) {
    dispatch(getDashboardFailure(error));
  }
};

export const clearServiceReceipt = (data) => async (dispatch) => {
  dispatch(clearServiceReceiptRequest());
  let token = localStorage.getItem("userToken");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_SERVICE_RECEIPT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(clearServiceReceipt(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(clearServiceReceiptSuccess(res.data));
    }
  } catch (error) {
    dispatch(clearServiceReceiptFailure(error));
  }
};

export const clearServiceReceiptDelete = (data) => async (dispatch) => {
  dispatch(clearServiceReceiptDeleteRequest());
  let token = localStorage.getItem("userToken");
  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_CLEAR_SERVICE_RECEIPT_DELETE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(clearServiceReceiptDelete(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(clearServiceReceiptDeleteSuccess(res.data));
    }
  } catch (error) {
    dispatch(clearServiceReceiptDeleteFailure(error));
  }
};

// if (error.response && error.response.status === 401) {
//   // If the error is due to an "INVALID_TOKEN" response (status code 401)
//   // Set the flag in your state to indicate the token is invalid
//   dispatch(setTokenValidity(false));
// }

// if(res.data==="INVALID_TOKEN"){
//   dispatch(getInvalidToken(res.data))
// }
// if(res.data!=="INVALID_TOKEN"){

// }

export const AmcCmc = (data) => async (dispatch) => {
  dispatch(AmcCmcRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(process.env.REACT_APP_NBM_AMC_CMC, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AmcCmc(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcCmcSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcCmcFailure(error));
  }
};

export const createNbmTask = (data) => async (dispatch) => {
  dispatch(createNbmTaskRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CREATE_TASK,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(createNbmTask(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(createNbmTaskSuccess(resData));
    }
  } catch (error) {
    dispatch(createNbmTaskFailure(error));
  }
};

export const getNbmTask = (data) => async (dispatch) => {
  dispatch(getNbmTaskRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_GET_TASK,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getNbmTask(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(getNbmTaskSuccess(resData));
    }
  } catch (error) {
    dispatch(getNbmTaskFailure(error));
  }
};

export const getVendor = (data) => async (dispatch) => {
  dispatch(getVendorRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_GET_VENDOR,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getVendor(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(getVendorSuccess(resData));
    }
  } catch (error) {
    dispatch(getVendorFailure(error));
  }
};

export const postVendor = (data) => (dispatch) => {
  dispatch(postVendorRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_NBM_INSERT_VENDOR,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "INVALID_TOKEN" || res.data !== "AUTHENTICATION_ERROR") {
        if (res.data?.user_access) {
          localStorage.setItem(
            "user_access",
            JSON.stringify(res.data?.user_access)
          );
        }
        dispatch(postVendorSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(postVendorFailure(error));
    });
};

export const postVendorService = (data) => async (dispatch) => {
  dispatch(postVendorServiceRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_INSERT_SERVICE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(postVendorService(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(postVendorServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(postVendorServiceFailure(error));
  }
};

export const getVendorService = (data) => async (dispatch) => {
  dispatch(getVendorServiceRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_GET_SERVICE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getVendorService(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      if (resData?.user_access) {
        localStorage.setItem(
          "user_access",
          JSON.stringify(resData.user_access)
        );
      }
      dispatch(getVendorServiceSuccess(resData));
    }
  } catch (error) {
    dispatch(getVendorServiceFailure(error));
  }
};

export const Amc = (data) => async (dispatch) => {
  dispatch(AmcRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(process.env.REACT_APP_NBM_AMC, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(Amc(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcFailure(error));
  }
};

export const Cmc = (data) => async (dispatch) => {
  dispatch(CmcRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(process.env.REACT_APP_NBM_CMC, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(Cmc(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CmcSuccess(resData));
    }
  } catch (error) {
    dispatch(CmcFailure(error));
  }
};

export const AddAssetReport = (data) => async (dispatch) => {
  dispatch(AddAssetReportRequest());

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_ADD_REPORT,
      data
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AddAssetReport(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      // Uncomment this line if needed
      // dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AddAssetReportSuccess(resData));
    }
  } catch (error) {
    dispatch(AddAssetReportFailure(error));
  }
};

export const assetDeleteReport = (data) => async (dispatch) => {
  dispatch(AssetDeleteReportRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_REMOVE_REPORT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(assetDeleteReport(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AssetDeleteReportSuccess(resData));
    }
  } catch (error) {
    dispatch(AssetDeleteReportFailure(error));
  }
};

export const deleteCsvFileUpload = (data) => async (dispatch) => {
  dispatch(deleteCsvFileUploadRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CSV_FILE_UPLOAD_DELETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteCsvFileUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteCsvFileUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteCsvFileUploadFailure(error));
  }
};

export const csvFileUploadSubmit = (data) => async (dispatch) => {
  dispatch(csvFileUploadSubmitRequest());
  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CSV_FILE_UPLOAD_SUBMIT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(csvFileUploadSubmit(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(csvFileUploadSubmitSuccess(resData));
    }
  } catch (error) {
    dispatch(csvFileUploadSubmitFailure(error));
  }
};

export const csvFileUpload = (data) => async (dispatch) => {
  dispatch(csvFileUploadRequest());
  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CSV_FILE_UPLOAD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(csvFileUpload(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(csvFileUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(csvFileUploadFailure(error));
  }
};

export const AssetHistory = (data) => async (dispatch) => {
  dispatch(assetHistoryRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_ASSET_HISTORY,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AssetHistory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetHistorySuccess(resData));
    }
  } catch (error) {
    dispatch(assetHistoryFailure(error));
  }
};

export const AssetHistoryRetrieve = (data) => async (dispatch) => {
  dispatch(assetHistoryRetrieveRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_ASSET_HISTORY_RETRIEVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AssetHistoryRetrieve(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetHistoryRetrieveSuccess(resData));
    }
  } catch (error) {
    dispatch(assetHistoryRetrieveFailure(error));
  }
};

export const AssetHistoryDropdownRetrieve = (data) => async (dispatch) => {
  dispatch(assetHistoryDropdownRetrieveRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_ASSET_HISTORY_DROPDOWN_RETRIEVE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AssetHistoryDropdownRetrieve(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(assetHistoryDropdownRetrieveSuccess(resData));
    }
  } catch (error) {
    dispatch(assetHistoryDropdownRetrieveFailure(error));
  }
};

export const checkOut = (data) => async (dispatch) => {
  dispatch(checkOutRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CHECK_OUT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkOut(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkOutSuccess(resData));
    }
  } catch (error) {
    dispatch(checkOutFailure(error));
  }
};

export const checkIn = (data) => async (dispatch) => {
  dispatch(checkInRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CHECK_IN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkIn(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkInSuccess(resData));
    }
  } catch (error) {
    dispatch(checkInFailure(error));
  }
};

export const checkInOut = (data) => async (dispatch) => {
  dispatch(checkInOutRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CHECK_IN_OUT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkInOut(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkInOutSuccess(resData));
    }
  } catch (error) {
    dispatch(checkInOutFailure(error));
  }
};

export const checkInOutDetail = (data) => async (dispatch) => {
  dispatch(checkInOutDetailRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CHECK_IN_OUT_DETAIL,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkInOutDetail(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(checkInOutDetailSuccess(resData));
    }
  } catch (error) {
    dispatch(checkInOutDetailFailure(error));
  }
};

export const calender = (data) => async (dispatch) => {
  dispatch(CalenderRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CALENDER,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(calender(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CalenderSuccess(resData));
    }
  } catch (error) {
    dispatch(CalenderFailure(error));
  }
};

export const checkinPdf = (data) => async (dispatch) => {
  dispatch(CheckInPdfRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CHECKIN_PFD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(checkinPdf(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CheckInPdfSuccess(resData));
    }
  } catch (error) {
    dispatch(CheckInPdfFailure(error));
  }
};

export const AmcCmcReq = (data) => async (dispatch) => {
  dispatch(AmcCmcReqRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_AMC_CMC_REQ,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AmcCmcReq(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcCmcReqSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcCmcReqFailure(error));
  }
};

export const CalibrationReq = (data) => async (dispatch) => {
  dispatch(CalibrationReqRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const response = await axios.post(
      process.env.REACT_APP_NBM_CALIBRATION_REQ,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(CalibrationReq(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CalibrationReqSuccess(resData));
    }
  } catch (error) {
    dispatch(CalibrationReqFailure(error));
  }
};
export const WarrantyTaskCompleted = (data) => (dispatch) => {
  dispatch(WarrantyTaskCompletedRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_NBM_WARRANTY_TASK_COMPLETE,
    headers: { Authorization: `Bearer ${token}` },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(WarrantyTaskCompletedSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(WarrantyTaskCompletedFailure(error));
    });
};

export const AmcCmcTaskCompleted = (data) => async (dispatch) => {
  dispatch(AmcCmcTaskCompletedRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_AMC_CMC_TASK_COMPLETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(AmcCmcTaskCompleted(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(AmcCmcTaskCompletedSuccess(resData));
    }
  } catch (error) {
    dispatch(AmcCmcTaskCompletedFailure(error));
  }
};

export const CalibrationTaskCompleted = (data) => async (dispatch) => {
  dispatch(CalibrationTaskCompletedRequest());

  let token = localStorage.getItem("userToken"); // Fetch the latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_CALIBRATION_TASK_COMPLETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(CalibrationTaskCompleted(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(CalibrationTaskCompletedSuccess(resData));
    }
  } catch (error) {
    dispatch(CalibrationTaskCompletedFailure(error));
  }
};

export const updateReport = (data) => async (dispatch) => {
  dispatch(updateReportRequest());

  let token = localStorage.getItem("userToken"); // Fetch latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_UPDATE_REPORT,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(updateReport(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(updateReportSuccess(resData));
    }
  } catch (error) {
    dispatch(updateReportFailure(error));
  }
};

export const bulkAssetDelete = (data) => async (dispatch) => {
  dispatch(bulkAssetDeleteRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_BULK_ASSET_DELETE,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(bulkAssetDelete(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(bulkAssetDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(bulkAssetDeleteFailure(error));
  }
};

export const bulkDepartmentUpdate = (data) => async (dispatch) => {
  dispatch(bulkDepartmentUpdateRequest());

  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_BULK_DEPARTMENT_UPLOAD,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const resData = res.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(bulkDepartmentUpdate(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(bulkDepartmentUpdateSuccess(resData));
    }
  } catch (error) {
    dispatch(bulkDepartmentUpdateFailure(error));
  }
};

export const createSubuser = (data) => (dispatch) => {
  dispatch(createSubuserRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_BM_CREATE_NBM_SUBUSER,
    headers: { Authorization: `Bearer ${token}` },
    data,
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(createSubuserSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(createSubuserFailure(error));
    });
};

export const allUserList = (data) => async (dispatch) => {
  dispatch(allUserListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_ALL_USER_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(allUserList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(allUserListSuccess(res.data));
    }
  } catch (error) {
    dispatch(allUserListFailure(error));
  }
};

export const updateSubuserToSuperAdmin = (data) => async (dispatch) => {
  dispatch(updateSubuserToSuperAdminRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_BM_UPDATE_SUBUSER_TO_SUPER_ADMIN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(updateSubuserToSuperAdmin(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(updateSubuserToSuperAdminSuccess(res.data));
    }
  } catch (error) {
    dispatch(updateSubuserToSuperAdminFailure(error));
  }
};

export const departmentUserDetails = (data) => async (dispatch) => {
  dispatch(departmentUserDetailsRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_NBM_USER_BY_DEPARTMENT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(departmentUserDetails(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(departmentUserDetailsSuccess(resData));
    }
  } catch (error) {
    dispatch(departmentUserDetailsFailure(error));
  }
};

export const userRetrieveByBranch = (data) => async (dispatch) => {
  dispatch(userRetrieveByBranchRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_USER_RETRIEVE_BY_BRANCH,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(userRetrieveByBranch(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(userRetrieveByBranchSuccess(resData));
    }
  } catch (error) {
    dispatch(userRetrieveByBranchFailure(error));
  }
};

export const getDashboardList = (data) => async (dispatch) => {
  dispatch(getDashboardListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_GET_DASHBOARD_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(getDashboardList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getDashboardListSuccess(res.data));
    }
  } catch (error) {
    dispatch(getDashboardListFailure(error));
  }
};

export const getDashboardList2 = (data) => async (dispatch) => {
  dispatch(getDashboardList2Request());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_GET_DASHBOARD_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(getDashboardList2(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getDashboardList2Success(res.data));
    }
  } catch (error) {
    dispatch(getDashboardList2Failure(error));
  }
};

export const getMonthlyReport = (data) => async (dispatch) => {
  dispatch(getMonthlyReportRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_MONTHLY_REPORT_STATUS,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(getMonthlyReport(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(getMonthlyReportSuccess(res.data));
    }
  } catch (error) {
    dispatch(getMonthlyReportFailure(error));
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const DOWNLOAD_PDF_REQUEST_HISTORY = "DOWNLOAD_PDF_REQUEST_HISTORY";
export const DOWNLOAD_PDF_SUCCESS_HISTORY = "DOWNLOAD_PDF_SUCCESS_HISTORY";
export const DOWNLOAD_PDF_FAILURE_HISTORY = "DOWNLOAD_PDF_FAILURE_HISTORY";
export const ADD_ACCESSORY_UPDATE = "ADD_ACCESSORY_UPDATE";
export const ADD_ACCESSORY_UPDATE_HISTORY = "ADD_ACCESSORY_UPDATE_HISTORY";
export const ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS =
  "ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS";
export const ADD_ACCESSORY_UPDATE_HISTORY_FAILURE =
  "ADD_ACCESSORY_UPDATE_HISTORY_FAILURE";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const ADD_ACCESSORY_UPLOAD_FILE = "ADD_ACCESSORY_UPLOAD_FILE";
export const ADD_ACCESSORY_UPLOAD_FILE_HISTORY =
  "ADD_ACCESSORY_UPLOAD_FILE_HISTORY";
export const DELETE_IMAGE_REQUEST_HISTORY = "DELETE_IMAGE_REQUEST_HISTORY";
export const RETRIEVE_ASSET_REQUEST = "RETRIEVE_ASSET_REQUEST";
export const RETRIEVE_ASSET_SUCCESS = "RETRIEVE_ASSET_SUCCESS";
export const RETRIEVE_ASSET_FAILURE = "RETRIEVE_ASSET_FAILURE";
export const SELL_ONLINE_ASSET_REQUEST = "SELL_ONLINE_ASSET_REQUEST";
export const SELL_ONLINE_ASSET_SUCCESS = "SELL_ONLINE_ASSET_SUCCESS";
export const SELL_ONLINE_ASSET_FAILURE = "SELL_ONLINE_ASSET_FAILURE";
export const USER_DETAIL_CHECK_REQUEST = "USER_DETAIL_CHECK_REQUEST";
export const USER_DETAIL_CHECK_SUCCESS = "USER_DETAIL_CHECK_SUCCESS";
export const USER_DETAIL_CHECK_FAILURE = "USER_DETAIL_CHECK_FAILURE";
export const CREATE_SUPERADMIN_REQUEST = "CREATE_SUPERADMIN_REQUEST";
export const CREATE_SUPERADMIN_SUCCESS = "CREATE_SUPERADMIN_SUCCESS";
export const CREATE_SUPERADMIN_FAILURE = "CREATE_SUPERADMIN_FAILURE";
export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const deleteImageRequestHistory = () => {
  return { type: DELETE_IMAGE_REQUEST_HISTORY };
};

export const DownloadPDFRequestHistory = () => ({
  type: DOWNLOAD_PDF_REQUEST_HISTORY,
});

export const DownloadPDFSuccessHistory = (data) => ({
  type: DOWNLOAD_PDF_SUCCESS_HISTORY,
  payload: data,
});

export const DownloadPDFFailureHistory = (error) => ({
  type: DOWNLOAD_PDF_FAILURE_HISTORY,
  payload: error,
});

const addAccessoryUpdateHistory = () => {
  return { type: ADD_ACCESSORY_UPDATE_HISTORY };
};
const addAccessoryUpdate = () => {
  return { type: ADD_ACCESSORY_UPDATE };
};
const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

const addAccessoryUploadFile = () => {
  return { type: ADD_ACCESSORY_UPLOAD_FILE };
};

const addAccessoryUploadFileHistory = () => {
  return { type: ADD_ACCESSORY_UPLOAD_FILE_HISTORY };
};
const retrieveAssetRequest = () => {
  return { type: RETRIEVE_ASSET_REQUEST };
};

const retrieveAssetSuccess = (data) => {
  return { type: RETRIEVE_ASSET_SUCCESS, payload: data };
};

const retrieveAssetFailure = (error) => {
  return { type: RETRIEVE_ASSET_FAILURE, payload: error };
};

export const sellOnlineAssetRequest = () => {
  return {
    type: SELL_ONLINE_ASSET_REQUEST,
  };
};

export const sellOnlineAssetSuccess = (data) => {
  return {
    type: SELL_ONLINE_ASSET_SUCCESS,
    payload: data,
  };
};

export const sellOnlineAssetFailure = (error) => {
  return {
    type: SELL_ONLINE_ASSET_SUCCESS,
    payload: error,
  };
};
const userDetailCheckRequest = () => {
  return { type: USER_DETAIL_CHECK_REQUEST };
};

const userDetailCheckSuccess = (data) => {
  return { type: USER_DETAIL_CHECK_SUCCESS, payload: data };
};

const userDetailCheckFailure = (error) => {
  return { type: USER_DETAIL_CHECK_FAILURE, payload: error };
};
const createSuperAdminRequest = () => {
  return { type: CREATE_SUPERADMIN_REQUEST };
};

const createSuperAdminSuccess = (data) => {
  return { type: CREATE_SUPERADMIN_SUCCESS, payload: data };
};

const createSuperAdminFailure = (error) => {
  return { type: CREATE_SUPERADMIN_FAILURE, payload: error };
};
const deleteProfileUserImage = () => {
  return { type: DELETE_PROFILE_IMAGE };
};
const uploadUserProfileImage = () => {
  return { type: UPLOAD_PROFILE_IMAGE };
};

const updateUserProfileImage = () => {
  return { type: UPDATE_PROFILE_IMAGE };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const DownloadPDFHistory = (fileUrl, title) => (dispatch) => {
  dispatch(DownloadPDFRequestHistory());

  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob", // important for binary data
  })
    .then((response) => {
      const contentType = response.headers["content-type"] || "";
      let fileBlob;
      let fileExtension = "";

      const urlExtension = fileUrl
        .split(".")
        .pop()
        .split(/[\?#]/)[0]
        .toLowerCase();

      if (urlExtension === "pdf") {
        fileBlob = new Blob([response.data], { type: "application/pdf" });
        fileExtension = "pdf";
      } else if (urlExtension === "jpg" || urlExtension === "jpeg") {
        fileBlob = new Blob([response.data], { type: "image/jpeg" });
        fileExtension = "jpg";
      } else if (urlExtension === "png") {
        fileBlob = new Blob([response.data], { type: "image/png" });
        fileExtension = "png";
      } else {
        fileBlob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        fileExtension = urlExtension || "bin";
      }

      FileSaver.saveAs(fileBlob, `${title}.${fileExtension}`);
      dispatch(DownloadPDFSuccessHistory(response.data));
    })
    .catch((error) => {
      dispatch(DownloadPDFFailureHistory(error));
    });
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const userDetailCheck = (data) => async (dispatch) => {
  dispatch(userDetailCheckRequest());

  let token = localStorage.getItem("userToken"); // Fetch token inside function

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_USER_DETAIL_CHECK,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(userDetailCheck(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(userDetailCheckSuccess(res.data));
    }
  } catch (error) {
    dispatch(userDetailCheckFailure(error));
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const addAccesoryUpdate = (data) => async (dispatch) => {
  dispatch(addAccessoryUpdate());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPDATE_ADD_ACCESSORRY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(addAccesoryUpdate(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fetchDataFromAPI = (requestBody) => async (dispatch) => {
  dispatch(fetchDataRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_RETRIVENEW_ASSET_DETAILS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: requestBody,
    });

    if (response.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(response.data));
      if (newToken) return dispatch(fetchDataFromAPI(requestBody));
    } else if (response.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(response.data));
    } else {
      dispatch(fetchDataSuccess(response.data));
    }
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const addAccesoryUpdateHistory = (data, token) => async (dispatch) => {
  dispatch({ type: ADD_ACCESSORY_UPDATE_HISTORY });

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPDATE_ADD_ACCESSORRY_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      dispatch(getInvalidToken(resData));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      // Dispatch the accessory_history array to the reducer
      dispatch({
        type: ADD_ACCESSORY_UPDATE_HISTORY_SUCCESS,
        payload: {
          accessory_history: resData.accessory_history, // Use the correct field from the response
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_ACCESSORY_UPDATE_HISTORY_FAILURE,
      payload: error,
    });
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////

export const addAccesoryUploadImageHistory = (data) => (dispatch) => {
  dispatch(addAccessoryUploadFileHistory());

  return axios({
    method: "POST",
    url: process.env.REACT_APP_UPLOAD_ACCESSORY_ASSET_HISTORY_NBM,
    headers: { Authorization: `Bearer ${token}` },
    data, // Data should be in JSON format here
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" && res.data !== "INVALID_TOKEN") {
        dispatch(addImageSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(addImageFailure(error));
    });
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const addAccesoryUploadImage = (data) => async (dispatch) => {
  dispatch(addAccessoryUploadFile());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_ACCESSORY_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(addAccesoryUploadImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const deleteImageAddAccessoryHistory = (data) => async (dispatch) => {
  dispatch(deleteImageRequestHistory());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_UPLOAD_ACCESSORY_ASSET_HISTORY,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteImageAddAccessoryHistory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const deleteImageAddAccessory = (data) => async (dispatch) => {
  dispatch(deleteImageRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_UPLOAD_ACCESSORY_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteImageAddAccessory(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const retrieveAsset = (data) => async (dispatch) => {
  dispatch(retrieveAssetRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_RETRIEVE_ASSET,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        return dispatch(retrieveAsset(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(retrieveAssetSuccess(res.data));
    }
  } catch (error) {
    dispatch(retrieveAssetFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const sellOnlineAsset = (data) => async (dispatch) => {
  dispatch(sellOnlineAssetRequest());
  let token = localStorage.getItem("userToken"); // Get the latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_BM_CELL_PRODUCT,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(sellOnlineAsset(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(sellOnlineAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(sellOnlineAssetFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const createSuperAdmin = (data) => async (dispatch) => {
  dispatch(createSuperAdminRequest());

  let token = localStorage.getItem("userToken"); // Fetch token inside function

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_CREATE_SUPER_ADMIN,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken); // Store new token
        return dispatch(createSuperAdmin(data)); // Retry with updated token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(createSuperAdminSuccess(res.data));
    }
  } catch (error) {
    dispatch(createSuperAdminFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const deleteProfileImage = (data) => async (dispatch) => {
  dispatch(deleteProfileUserImage());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETE_PROFILE_IMAGE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deleteProfileImage(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deleteImageSuccess(resData));
    }
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const uploadProfileImage = (data) => async (dispatch) => {
  dispatch(uploadUserProfileImage());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPLOAD_PROFILE_LOGO,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(uploadProfileImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const updateProfileImage = (data) => async (dispatch) => {
  dispatch(updateUserProfileImage());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_UPDATE_PROFILE_LOGO,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(updateProfileImage(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(addImageSuccess(res.data));
    }
  } catch (error) {
    dispatch(addImageFailure(error));
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getDashboardRequestData = (data) => async (dispatch) => {
  dispatch(requestDashboard());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_REQUEST_DASHBOARD,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getDashboardRequestData(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getRequestDashboardSuccess(resData));
    }
  } catch (error) {
    dispatch(getRequestDashboardFailure(error));
  }
};

export const getDashboardRequestDataNBM = (data) => async (dispatch) => {
  dispatch(requestDashboardNBM());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_REQUEST_DASHBOARD_NBM,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(getDashboardRequestDataNBM(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(getRequestDashboardSuccessNBM(resData));
    }
  } catch (error) {
    dispatch(getRequestDashboardFailureNBM(error));
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////

export const incidentExpenditureImageUpload = (data) => async (dispatch) => {
  dispatch(incidentExpenditureImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_INCIDENT_EXPENDITURE_UPLOAD_NBM,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;
    console.log("Image Upload Response:", resData);

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(incidentExpenditureImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(incidentExpenditureImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(incidentExpenditureImageUploadFailure(error));
  }
};

export const clearIncidentExpenditureImageRemove =
  (data) => async (dispatch) => {
    dispatch(clearIncidentExpenditureImageRemoveRequest());
    let token = localStorage.getItem("userToken"); // Get the latest token

    try {
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_INCIDENT_EXPENDITURE_DELETE_NBM,
        headers: { Authorization: `Bearer ${token}` },
        data,
      });

      const resData = response.data;

      if (resData === "INVALID_TOKEN") {
        const newToken = await dispatch(getInvalidToken(resData));
        if (newToken)
          return dispatch(clearIncidentExpenditureImageRemove(data)); // Retry with new token
      } else if (resData === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(resData));
      } else {
        dispatch(clearIncidentExpenditureImageRemoveSuccess(resData));
      }
    } catch (error) {
      dispatch(clearIncidentExpenditureImageRemoveFailure(error));
    }
  };

export const serviceExpenditureImageUpload = (data) => async (dispatch) => {
  dispatch(serviceExpenditureImageUploadRequest());
  let token = localStorage.getItem("userToken");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_SERVICE_EXPENDITURE_UPLOAD_NBM,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(serviceExpenditureImageUpload(data));
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(serviceExpenditureImageUploadSuccess(resData));
    }
  } catch (error) {
    dispatch(serviceExpenditureImageUploadFailure(error));
  }
};

export const clearServiceExpenditureImageRemove =
  (data) => async (dispatch) => {
    dispatch(clearServiceExpenditureImageRemoveRequest());
    let token = localStorage.getItem("userToken"); // Ensure the latest token is used

    try {
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_SERVICE_EXPENDITURE_DELETE_NBM,
        headers: { Authorization: `Bearer ${token}` },
        data,
      });

      const resData = response.data;

      if (resData === "INVALID_TOKEN") {
        const newToken = await dispatch(getInvalidToken(resData));
        if (newToken) return dispatch(clearServiceExpenditureImageRemove(data)); // Retry with new token
      } else if (resData === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(resData));
      } else {
        dispatch(clearServiceExpenditureImageRemoveSuccess(resData));
      }
    } catch (error) {
      dispatch(clearServiceExpenditureImageRemoveFailure(error));
    }
  };

// ...................ASSET  checkout..........................................

export const assetcheckout = (data) => async (dispatch) => {
  dispatch(checkoutassetRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_ALL_ASSETS_RETRIVE,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) return dispatch(assetcheckout(data));
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(checkoutassetSuccess(res.data));
    }
  } catch (error) {
    dispatch(checkoutassetFailure(error));
  }
};

// ..................ASSETS NOT WORKING............................................

export const assetsnotworking = (data) => async (dispatch) => {
  dispatch(assetsnotworkingRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_ASSET_NOT_WORKING,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetsnotworking(data)); // Retry with the new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(assetsnotworkingSuccess(res.data));
    }
  } catch (error) {
    dispatch(assetsnotworkingFailure(error));
  }
};

// ..................ASSETS DISCARDED............................................

export const assetsdiscarded = (data) => async (dispatch) => {
  dispatch(discardedRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_ASSET_Discarded,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));

      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetsdiscarded(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(discardedSuccess(res.data));
    }
  } catch (error) {
    dispatch(discardedFailure(error));
  }
};

// console.log("ef",process.env.REACT_APP_NBM_Asset_DELETE)
// ..................ASSETS Delete............................................

export const assetsDelete = (data) => async (dispatch) => {
  dispatch(AssetDeleteRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_NBM_Asset_DELETE, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(assetsDelete(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AssetDeleteSuccess(res.data));
    }
  } catch (error) {
    dispatch(AssetDeleteFailure(error));
  }
};

// ..................Deleted assets Data............................................

export const deletedAssetData = (data) => async (dispatch) => {
  dispatch(deletedDataAssetRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_DELETED_ASSET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(deletedAssetData(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(deletedDataAssetSuccess(resData));
    }
  } catch (error) {
    dispatch(deletedDataAssetFailure(error));
  }
};

//  ................. Deleted Incident Data  ....................................

export const incidentDeletedData = (data) => async (dispatch) => {
  dispatch(IncidentDataDeleteRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_INCIDENT_DELETED_LIST,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(incidentDeletedData(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(IncidentDataDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(IncidentDataDeleteFailure(error));
  }
};

//  ................. Delete Incident  ....................................

export const incidentDelete = (data) => async (dispatch) => {
  dispatch(incidentDeleteRequest());
  let token = localStorage.getItem("userToken"); // Get latest token

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_NBM_INCIDENT_DELETE,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    const resData = response.data;

    if (resData === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(resData));
      if (newToken) return dispatch(incidentDeleteRequest(data)); // Retry with new token
    } else if (resData === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(resData));
    } else {
      dispatch(incidentDeleteSuccess(resData));
    }
  } catch (error) {
    dispatch(incidentDeleteFailure(error));
  }
};

// ..................Department delete list............................................

export const departmentDeleteList = (data) => async (dispatch) => {
  dispatch(departmentDeleteListRequest());

  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_DEPEARTMENT_DELETED_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(departmentDeleteList(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(departmentDeleteListSuccess(res.data));
    }
  } catch (error) {
    dispatch(departmentDeleteListFailure(error));
  }
};

// ..................Add Generate Ticket............................................

export const addGenerateTicket = (data) => async (dispatch) => {
  dispatch(AddGenerateTicketRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_ADD_GENERATE_TICKET,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(addGenerateTicket(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(AddGenerateTicketSuccess(res.data));
    }
  } catch (error) {
    dispatch(AddGenerateTicketFailure(error));
  }
};

// ..................List Generate Ticket............................................

export const listGenerateTicket = (data) => async (dispatch) => {
  dispatch(ListGenerateTicketRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_GENERATE_TICKET_LIST,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(listGenerateTicket(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(ListGenerateTicketSuccess(res.data));
    }
  } catch (error) {
    dispatch(ListGenerateTicketFailure(error));
  }
};

// ..................Clear Generate Ticket............................................

export const clearGenerateTicket = (data) => async (dispatch) => {
  dispatch(ClearGenerateTicketRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_CLEAR_GENERATE_TICKET,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(clearGenerateTicket(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(ClearGenerateTicketSuccess(res.data));
    }
  } catch (error) {
    dispatch(ClearGenerateTicketFailure(error));
  }
};

// .................. Generate Ticket Details............................................

export const generateTicketDetails = (data) => async (dispatch) => {
  dispatch(GenerateTicketDetailsRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_GENERATE_TICKET_DETAILS,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(generateTicketDetails(data));
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(GenerateTicketDetailsSuccess(res.data));
    }
  } catch (error) {
    dispatch(GenerateTicketDetailsFailure(error));
  }
};

// .................. Generate Ticket Acknowledge............................................

export const generateTicketAcknowledge = (data) => async (dispatch) => {
  dispatch(GenerateTicketAcknowledgeRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(
      process.env.REACT_APP_NBM_ACKNOWLEDGE_GENERATE_TICKET,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(generateTicketAcknowledge(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(GenerateTicketAcknowledgeSuccess(res.data));
    }
  } catch (error) {
    dispatch(GenerateTicketAcknowledgeFailure(error));
  }
};

// ..................  Grade Update............................................

export const gradeUpdate = (data) => async (dispatch) => {
  dispatch(GradeUpdateRequest());
  let token = localStorage.getItem("userToken");

  try {
    const res = await axios.post(process.env.REACT_APP_BM_UPDATE_GRADE, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data === "INVALID_TOKEN") {
      const newToken = await dispatch(getInvalidToken(res.data));
      if (newToken) {
        localStorage.setItem("userToken", newToken);
        return dispatch(gradeUpdate(data)); // Retry with new token
      }
    } else if (res.data === "AUTHENTICATION_ERROR") {
      dispatch(getAuthenticationError(res.data));
    } else {
      dispatch(GradeUpdateSuccess(res.data));
    }
  } catch (error) {
    dispatch(GradeUpdateFailure(error));
  }
};
