import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
  FormHelperText,
} from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import "jspdf-autotable";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import {
  assetDetailsData,
  createService,
  createServiceImageUpload,
  serviceRequestImageRemove,
} from "../redux/nbmData/action";

import { useState } from "react";

import SuccessPopUp from "../components/SuccessPopUp";
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "auto",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  "& fieldset": { border: "none" },
}));
const ServiceRequest = () => {
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const requested = params.get("requested");
  const detailData = useSelector((store) => store?.data?.assetDetails);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const response = useSelector((store) => store.data.createService);

  const token = localStorage.getItem("userToken");

  const [fileUrl, setFileUrl] = useState([]);
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isPhotoError, setIsPhotoError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const navigate = useNavigate();
  const userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate(-1);
  };
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];
  
    if (singlefile) {
      const fileType = singlefile.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 4MB
  
      if (!allowedTypes.includes(singlefile.type)) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return;
      }
  
      if (singlefile.size > allowedSize) {
        setIsPhotoError(`Can't upload image more than 4MB.`);
        return;
      }
  
      setIsPhotoError(null);
      const reader = new FileReader();
  
      reader.onloadend = () => {
        let uuidShort = uuid().replace(/-/g, "").substring(0, 12); // Ensuring 12 characters
        let assetPrefix = "SEV_"; // 4-character prefix
        let imageid = `${assetPrefix}${uuidShort}`; // Total 16 characters
  
        let sendData = {
          hospid: ids?.hospid,
          userid: ids?.userid,
          imageid: `${imageid}.${fileType}`, // Appends file extension
          image: reader.result,
          branchid: userBranch.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
  
        setFileUrl([...fileUrl, sendData]);
        dispatch(createServiceImageUpload(sendData, token));
      };
  
      reader.readAsDataURL(singlefile);
    }
  };


  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
                userrole:userAccess?.role,
                depart_ids: userAccess?.departmentids,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(createServiceImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const handleDelete = (id) => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      imageid: id,
      branchid: userBranch.id,
      depart_ids: userAccess?.departmentids,
      userrole:userAccess?.role,
    };
    dispatch(serviceRequestImageRemove(data));
    setFileUrl(fileUrl?.filter((el) => el?.imageid !== id));
  };

  const handleDescription = (e) => {
    let value = e.target.value;
    const regex = /^[a-zA-Z0-9.,\s-]*$/;
  
    setDescription(value);
  
    if (value.trim() === "") {
      setDescriptionError("Description is required");
    } else if (value.length < 3) {
      setDescriptionError("required description");
    } else if (!regex.test(value)) {
      setDescriptionError("Enter a valid description");
    } else {
      setDescriptionError("");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Wrong  email");
    }
    if (value === "") {
      setEmailError("Please fill the field");
    }
  };

  const handleSubmit = () => {
    if (description === "") {
      setDescriptionError("Please fill the field.");
    }

    if (requested === "Others") {
      if (email === "") {
        setEmailError("Please fill the field.");
      }
    }

    if (
      description !== "" &&
      descriptionError === "" &&
      (requested !== "Others" ||
        (requested === "Others" && email !== "" && emailError === ""))
    ) {
      let data = {
        hospid: ids?.hospid,
        hospname: ids?.hospname,
        userid: ids?.userid,
        username: ids?.username,
        usermail: ids?.email,
        userphone: ids?.mobile,
        assetid: id,
        assetname: detailData?.name,
        brand: detailData?.brand,
        departid: detailData?.departid,
        departname: detailData?.dept,
        serialno: detailData?.serialno,
        modelno: detailData?.modelno,
        issuedetail: description,
        issuedetail_img: uploadedImageName,
        request_email:
          requested === "Medpick" ? "contact@vajra.software" : email,
        assetcode: detailData.code,
        branchid: userBranch?.id,
        departid: detailData?.departid,
        branchname: userBranch.branch,
        userrole:userAccess?.role,
        depart_ids: userAccess?.departmentids,
      };

      setOpenModal(true);
      setToggle(true);
      dispatch(createService(data));
    }
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      assetid: id,
      branchid: userBranch?.id,
      userrole:userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);
  useEffect(() => {
    if (response === "Inserted" && description !== "") {
      setOpenModal(true);
    }
  }, [response]);

  const HospitalLeftDataOpen = {
    "Asset Name": detailData.name,
    "Asset Code": detailData.code,
    Department: detailData.dept,
    "Serial No.": detailData.serialno,
  };

  const HospitalRightDataOpen = {
    "Model No.": detailData.modelno,

    Brand: detailData.brand,
  };

  const isGuest = userAccess && userAccess.role === 'Guest';

  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

          height: "100%",
        }}
      >
        <Stack
          sx={{
            width: {
              xs: "100%",
              sm: "500px  ",
              md: "750px",
              lg: "750px",
              // xl: "auto"
            },

            borderRadius: "8px",

            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            gap: "10px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",

              padding: "10px 20px 10px 20px",
            }}
          >
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              Service Request
            </Label>

            {/* <Label sx={{ color: "rgba(70, 144, 255, 1)" }}>{requested}</Label> */}
            <Label sx={{ color: "rgba(70, 144, 255, 1)" }}>Vajra</Label>

          </Stack>

          <Stack sx={{ bgcolor: "white", padding: "20px" }}>
            <Stack
              sx={{
                textAlign: "start",

                marginTop: "25px",
                paddingLeft: "10px",
                marginBottom: "20px",
                width: "100%",
                gap: "10px",
              }}
            >
              <Label variant="subtitle1">Asset Details</Label>
              <Divider />
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                gap: "20px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ maxWidth: "100%" }}>
                    <TableBody >
                      {Object.entries(HospitalLeftDataOpen).map(
                        ([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                    <TableBody sx={{
                      display: {
                        xs: "table-row-group",
                        sm: "table-row-group",
                        md: "none",
                      },
                    }}>
                      {Object.entries(HospitalRightDataOpen).map(
                        ([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{
                width: "100%", 
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                },
              }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(HospitalRightDataOpen).map(
                        ([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>

            {requested === "Others" && (
              <>
                <Stack
                  sx={{
                    textAlign: "start",

                    marginTop: "25px",
                    paddingLeft: "10px",
                    marginBottom: "20px",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Label
                    variant="subtitle1"
                    style={{ color: "rgba(70, 144, 255, 1)" }}
                  >
                    Contact Person  <span style={{color:"red",fontWeight:"100"}}>*</span>
                  </Label>
                  <Divider />
                </Stack>
                <Stack
                  sx={{
                    width: {
                      sm: '100%',
                      md: '50%',
                      lg: '50%'
                    },
                    justifyContent: "space-between",
                    paddingLeft: "10px",
                  }}
                >
                  <FormInput
                    id="filled-basic"
                    label="Email"
                    value={email}
                    onChange={handleEmail}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {emailError}
                  </FormHelperText>
                </Stack>
              </>
            )}

            <Stack
              sx={{
                textAlign: "start",

                marginTop: "25px",
                paddingLeft: "10px",
                marginBottom: "20px",
                width: "100%",
                gap: "10px",
              }}
            >
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Issue  <span style={{color:"red",fontWeight:"100"}}>*</span>
              </Label>
              <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
            </Stack>

            <Stack
              flexDirection={"row"}
              sx={{
                gap: "20px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Stack
                sx={{
                  width: {
                    xs: "100%",
                    sm: "60%",
                  },
                }}
              >
                <TextField
                  value={description}
                  onChange={handleDescription}
                  multiline
                  rows={8}
                  placeholder="Describe the issue here..."
                  variant="outlined"
                  sx={{
                    width: "auto",

                    background: "rgba(244, 246, 246, 1)",
                    "& fieldset": { border: "none" },
                  }}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {descriptionError}
                </FormHelperText>



                <Stack
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "50%",
                    },
                    textAlign: "start",

                    justifyContent: "space-between",
                    height: "200px",
                    display: {
                      xs: "flex",
                      sm: "flex",
                      md: "none",
                    },
                  }}
                >
                  <Label
                    variant="subtitle1"
                    sx={{ color: "rgba(70, 144, 255, 1)", marginTop: "10px", marginBottom: "10px" }}
                  >
                    Upload Images
                  </Label>
                  <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
                  <Stack
                    sx={{
                      height: "180px",
                      width: "auto",
                      flexDirection: "row",
                      // overflowX: "auto",
                      // overflowY: "hidden",
                      gap: "20px",
                      alignItems: "center",
                      marginTop: "20px"
                    }}
                  >
                    {fileUrl?.map((item, index) => (
                      <Badge
                        key={index}
                        badgeContent={
                          <CloseIcon
                            size={15}
                            style={{
                              color: " rgba(255, 58, 68, 1)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(item?.imageid)}
                          />
                        }
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          as="img"
                          src={item?.image}
                          alt=""
                          sx={{ width: "80px", height: "90px" }}
                        />
                      </Badge>
                    ))}
                  </Stack>

                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "end",
                      justifyContent: "space-between",
                    }}
                  >
                    <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                    {uploadedImageName?.length < 3 && (
                      <>
                        <label for="img">
                          <FileUploadIcon
                            size={20}
                            style={{
                              cursor: "pointer",
                              color: "rgba(70, 144, 255, 1)",

                              height: "100%",
                              display: "flex",
                              alignItems: "end",
                            }}
                          />
                        </label>

                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",
                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handlePhotoUpload}
                        />
                      </>
                    )}
                  </Stack>
                </Stack>

              </Stack>

              <Stack
                sx={{
                  width: "50%",
                  textAlign: "start",

                  justifyContent: "space-between",
                  height: "200px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                  },
                }}
              >
                <Stack
                  sx={{
                    height: "180px",
                    width: "auto",
                    flexDirection: "row",
                    // overflowX: "auto",
                    // overflowY: "hidden",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {fileUrl?.map((item, index) => (
                    <Badge
                      key={index}
                      badgeContent={
                        <CloseIcon
                          size={15}
                          style={{
                            color: " rgba(255, 58, 68, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(item?.imageid)}
                        />
                      }
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <Box
                        as="img"
                        src={item?.image}
                        alt=""
                        sx={{ width: "90px", height: "100px" }}
                      />
                    </Badge>
                  ))}
                </Stack>

                <Stack
                  sx={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                  {uploadedImageName?.length < 3 && (
                    <>
                      <label for="img">
                        <FileUploadIcon
                          size={20}
                          style={{
                            cursor: "pointer",
                            color: "rgba(70, 144, 255, 1)",

                            height: "100%",
                            display: "flex",
                            alignItems: "end",
                          }}
                        />
                      </label>

                      <input
                        type="file"
                        name="uploadfile"
                        id="img"
                        style={{
                          display: "none",
                          width: "42px",
                          height: "42px",
                        }}
                        onChange={handlePhotoUpload}
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: {
              xs: "90%",
              sm: "500px  ",
              md: "750px",
              lg: "750px",
              // xl: "auto"
            },
            alignItems: "flex-end",
            marginTop: "20px",
            padding: "10px",
          }}
        >
          <Button
          disabled={isGuest}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "150px", borderRadius: "22px" }}
          >
            Submit
          </Button>
        </Stack>
        <SuccessPopUp
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          text={"Successful! "}
          subText1={"Your request has been successfully processed. "}
          subText2={"  "}
          toggle={toggle}
        />
      </Stack>
    </>
  );
};

export default ServiceRequest;
