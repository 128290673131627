import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
  Grid,
} from "@mui/material";
import { jsPDF } from "jspdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VajraLogo from "../images/Vajra_Logo_Design.png";

import "jspdf-autotable";
import { useEffect } from "react";
import {
  DownloadPDF,
  profile,
  serviceDetail,
  fetchDataFromAPI,
} from "../redux/data/action";

import { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import pdfImage from "../../src/images/pdfImage.png";

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const PhotoCard = styled(Box)(({ theme }) => ({
  //
  width: "100px",
  height: "130px",

  borderRadius: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
}));
const ServiceDetail = () => {
  const navigate = useNavigate();

  const userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");

  let serviceData = useSelector((store) => store.data.serviceDetail);
  let profileData = useSelector((store) => store.data.profile);
  // console.log("datas are", profileData.logo_url);

  const dispatch = useDispatch();

  const params = useParams();

  const [fileType, setFileType] = useState(null);
  const fileLink =
    Array.isArray(serviceData?.receipt_id) && serviceData.receipt_id.length > 0
      ? serviceData.receipt_id[0]
      : null;

  useEffect(() => {
    if (fileLink?.endsWith(".pdf") || fileLink?.includes(".pdf")) {
      setFileType("pdf");
    } else if (
      fileLink?.endsWith(".jpg") ||
      fileLink?.endsWith(".jpeg") ||
      fileLink?.endsWith(".png") ||
      fileLink?.endsWith(".gif") ||
      fileLink?.includes("image/")
    ) {
      setFileType("image");
    } else {
      setFileType("unknown");
    }
  }, [fileLink]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      servid: params.id,
      branchid: userBranch.id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    dispatch(serviceDetail(data));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };

  const handlePDFDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.click();
  };

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const HospitalLeftData = {
    "Asset Name": serviceData.assetname,
    "Asset Code": serviceData.assetcode,
    Department: serviceData.deptname,
    "Issue Date": serviceData.reqdate,
    "Clear Date": serviceData.servicedate,
  };
  const HospitalLeftDataOpen = {
    "Asset Name": serviceData.assetname,
    "Asset Code": serviceData.assetcode,
    Department: serviceData.deptname,
    "Issue Date": serviceData.reqdate,
  };
  const HospitalRightData = {
    "AMC/CMC": serviceData.amccamc,
    Status: serviceData.fixedstatus,
    // "Paid Service": serviceData.remarks,
    // ...(serviceData.paidservice !== "no" && {
    //   Cost: serviceData.servicecost,
    //   Receipt: "",
    // }),
  };

  const HospitalRightDataOpen = {
    "Model No.": serviceData.modelno,

    Brand: serviceData.brand,
  };
  const UserLeftData = {
    Name: serviceData.servicername,
    "Contact No.": serviceData.mobile,
  };
  const ExpenditureData = {
    Reason: serviceData.expenditure,
    Cost: serviceData.amt_spent,
    // Receipt: "",
  };

  const IssueData = {
    "Problem Identified ": serviceData?.probidentified,
  };

  const IssueData1 = {
    "Action Taken": serviceData?.actiontaken,
  };

  const IssueData2 = {
    ...(serviceData.remarks !== "NA" && {
      Remark: serviceData.remarks,
    }),
  };

  const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);
  // console.log(assetLogo);

  useEffect(() => {
    const requestBody = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      assetid: params.id,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };

    dispatch(fetchDataFromAPI(requestBody, token));
  }, [dispatch]);

  const isPDF = (url) => {
    const lowerCaseUrl = url.toLowerCase();
    // console.log("Checking URL:", lowerCaseUrl);
    const urlWithoutParams = lowerCaseUrl.split("?")[0];
    return urlWithoutParams.endsWith(".pdf");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    const labelX = 20;
    const labelY = 65;
    const dataX = 73;
    let yPosition = 30;
    const pageHeight = doc.internal.pageSize.height;

    const hasEnoughSpace = (heightNeeded) => {
      return yPosition + heightNeeded < pageHeight - 10; // Keep a margin at the bottom
    };

    const addFooter = (doc) => {
      const footerY = doc.internal.pageSize.height - 7; // Adjusted position from bottom
      const textX = doc.internal.pageSize.width - 53; // Right-aligned text

      doc.setTextColor(100, 100, 100);
      doc.setFontSize(10);
      doc.text("Powered by :", textX, footerY, "left");

      if (VajraLogo) {
          const imgWidth = 20;
          const imgHeight = 12;
          const imgX = doc.internal.pageSize.width - 30;
          const imgY = footerY - 7;
          doc.addImage(VajraLogo, "PNG", imgX, imgY, imgWidth, imgHeight);
      }
  };

    // Add logo on top-right
    const addHeader = () => {
      if (profileData.logo_url) {
        doc.addImage(
          profileData.logo_url,
          "JPEG",
          doc.internal.pageSize.width - 200,
          10,
          50,
          25
        );
      }
      doc.setFontSize(16);
      doc.setTextColor("#1F86CB");
      doc.setFont("Helvetica", "bold");
      doc.text(userIds.hospname || "Default Hospital", labelX + 72, 20);

      doc.setFont("Helvetica", "bold");
      doc.text(
        profileData.city + ", " + (profileData.state || "Main Branch"),
        labelX + 64,
        28
      );
    };

    addHeader();

    yPosition = 40;

    const lineStartX = 10; // Move the line closer to the left
    const lineEndX = doc.internal.pageSize.width - 10; // Extend the line closer to the right edge
    doc.setDrawColor(0, 0, 0); // Set line color to black
    doc.setLineWidth(0.5); // Set line width
    doc.line(lineStartX, yPosition, lineEndX, yPosition); // Draw the line
    yPosition += 15;

    // Title: "Incident Report" should be bold
    doc.setFontSize(18);
    doc.setTextColor("black");
    doc.setFont("helvetica", "bold");
    doc.text(
      "Service Report",
      doc.internal.pageSize.width / 2,
      yPosition,
      null,
      null,
      "center"
    );

    yPosition += 18;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    const addText = (label, value, heightNeeded) => {
      if (!hasEnoughSpace(heightNeeded)) {
        doc.addPage();
        yPosition = 20;
        addFooter(doc); 
      }

      doc.setTextColor(0, 0, 0);
      doc.text(label, labelX, yPosition);
      doc.setTextColor(0, 0, 0); // Set colon color to black
      doc.text(":", labelY, yPosition);
      doc.text(value || "-", dataX, yPosition);
      yPosition += heightNeeded;
    };

    const addUnderlinedText = (text, yPosition, isBold = false) => {
      doc.setFont("Helvetica", isBold ? "normal" : "normal"); // Set to bold or normal font
      doc.setTextColor(0, 0, 0); // Set text color to black
      doc.text(text, labelX, yPosition);
      const textWidth = doc.getTextWidth(text); // Get the width of the text
      doc.line(labelX, yPosition + 1, labelX + textWidth, yPosition + 1); // Draw a line under the text
      return yPosition + 10; // Return the new yPosition after adding the line
    };

    // Add "Product details" section with underline
    yPosition = addUnderlinedText("Product details", yPosition, true);
    addText("Asset Name", serviceData.assetname, 10);
    addText("Asset Code", serviceData.assetcode, 10);
    addText("Department", serviceData.deptname, 20);

    // Add "Incident Details" section with underline
    yPosition = addUnderlinedText("Service Details", yPosition, true);
    addText("Issue Date", serviceData.reqdate, 10);
    addText("Clear Date", serviceData.servicedate, 10);
    addText("Serviced Time", serviceData.servicetime, 20);

    // Add "Service Clear Details" section with underline
    yPosition = addUnderlinedText("Service Clear Details", yPosition, true);
    addText("Servicer Name", serviceData.servicername, 10);
    addText("Contact No", serviceData.mobile, 10);
    addText("Email", serviceData.servicermail, 10);
    addText("Problem Identified", serviceData?.probidentified, 10);

    if (
      Array.isArray(serviceData?.issueimgs) &&
      serviceData.issueimgs.length > 0
    ) {
      const imageWidth = 50;
      const imageHeight = 50;
      const margin = 10;
      let rowXPosition = 20;
      let rowYPosition = yPosition;

      serviceData.issueimgs.forEach((image, index) => {
        if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
          rowXPosition = 20;
          rowYPosition += imageHeight + margin;
        }

        doc.addImage(
          image,
          "JPEG",
          rowXPosition,
          rowYPosition,
          imageWidth,
          imageHeight
        );
        rowXPosition += imageWidth + margin;
      });

      yPosition += imageHeight + margin;
    }

    addText("Action Taken", serviceData?.actiontaken, 10);

    if (
      Array.isArray(serviceData?.servicedimgs) &&
      serviceData.servicedimgs.length > 0
    ) {
      const imageWidth = 50;
      const imageHeight = 50;
      const margin = 10;
      let rowXPosition = 20;
      const rowYPosition = yPosition;

      serviceData.servicedimgs.forEach((image, index) => {
        if (rowXPosition + imageWidth + margin > doc.internal.pageSize.width) {
          rowXPosition = 20;
          yPosition += imageHeight + margin;
        }

        doc.addImage(
          image,
          "JPEG",
          rowXPosition,
          rowYPosition,
          imageWidth,
          imageHeight
        );
        rowXPosition += imageWidth + margin;
      });

      yPosition += imageHeight + margin;
    }

    addText("Remark", serviceData.remarks, 10);

    serviceData.expenditure.forEach((expenditure, index) => {
      addText("Reason", expenditure, 10);
      addText("Cost", `${serviceData.amt_spent[index]}`, 10);

      if (serviceData.receipt_id[index]) {
        const receiptUrl = serviceData.receipt_id[index];
        const imageHeight = 60;

        if (!hasEnoughSpace(imageHeight)) {
          doc.addPage();
          yPosition = 20;
          addFooter(doc); 
        }

        if (isPDF(receiptUrl)) {
          doc.addImage(pdfImage, "JPEG", 20, yPosition, 50, 50);
          yPosition += 60;
        } else {
          doc.addImage(receiptUrl, "JPEG", 20, yPosition, 50, 50);
          yPosition += 60;
        }
      } else {
        doc.text("No image available", 20, yPosition);
        yPosition += 10;
      }

      if (!hasEnoughSpace(15)) {
        doc.addPage();
        yPosition = 20;
        addFooter(doc); 
      }
    });
    addFooter(doc); 
    doc.save("Closed_Service_Details.pdf");
  };
  return (
    <>
      {serviceData?.servicestatus === "Cleared" ? (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Stack
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "80%",
                xl: "50%",
              },

              borderRadius: "8px",

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              gap: "10px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",

                padding: "10px 20px 10px 20px",
              }}
            >
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Cleared Service
              </Label>

              <Label>{serviceData.servicedate}</Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                        },
                      }}
                    >
                      <TableBody>
                        {Object.entries(HospitalLeftData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                      <TableBody
                        sx={{
                          display: {
                            xs: "table-row-group",
                            sm: "table-row-group",
                            md: "none",
                          },
                        }}
                      >
                        {Object.entries(HospitalRightData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                    },
                  }}
                >
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>

                    {serviceData?.receipturl !== "NA" &&
                      serviceData?.receipturl !== "" && (
                        <Stack
                          sx={{
                            textAlign: "start",

                            height: "80px",
                            width: "90%",
                            alignItems: "start",
                            marginLeft: "10px",
                            paddingLeft: "10px",
                            bgcolor: "rgba(244, 246, 246, 1)",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            {fileType === "pdf" ? (
                              <Badge
                                badgeContent={
                                  <DownloadIcon
                                    style={{ cursor: "pointer" }}
                                    size={15}
                                    onClick={() =>
                                      dispatch(
                                        DownloadPDF(
                                          serviceData.receipturl,
                                          "receipt"
                                        )
                                      )
                                    }
                                  />
                                }
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                              >
                                <PictureAsPdfIcon sx={{ fontSize: "50px" }} />
                              </Badge>
                            ) : (
                              <Box>
                                <PhotoCard
                                  as="img"
                                  src={serviceData?.receipturl}
                                  alt="incImage"
                                />
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      )}
                  </TableContainer>
                </Box>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Contact Person</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "50%",
                    },
                  }}
                >
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(UserLeftData).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Issue Details</Label>
                <Divider />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  {Object.entries(IssueData).map(([key, value]) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "20px",
                        }}
                      >
                        <Label>{key}</Label>

                        <Typography
                          sx={{
                            marginTop: "10px",
                            marginBottom: "20px",
                            wordWrap: "break-word",
                          }}
                        >
                          {value}
                        </Typography>
                        <Stack
                          sx={{
                            width: "20%",
                            gap: "10px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "row",
                          }}
                        >
                          <>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              {serviceData?.issueimgs !== "NA" &&
                                serviceData?.issueimgs !== "" && (
                                  <Stack
                                    display="flex"
                                    flexDirection={"row"}
                                    sx={{ justifyContent: "center" }}
                                  >
                                    {serviceData?.issueimgs?.map(
                                      (images, index) => (
                                        <Box
                                          sx={{
                                            height: "85%",
                                            width: "30%",
                                          }}
                                          key={index}
                                          position="relative"
                                          display="inline-block"
                                        >
                                          <Box
                                            as="img"
                                            src={images}
                                            alt="incImage"
                                            sx={{
                                              width: "60px",

                                              height: "80px",
                                              margin: "10px",
                                            }}
                                          />
                                          <Box
                                            position="absolute"
                                            bottom={-30}
                                            right={-10}
                                            borderRadius={25}
                                            sx={{ backgroundColor: "white" }}
                                          >
                                            <IconButton
                                              style={{ cursor: "pointer" }}
                                            >
                                              <DownloadIcon
                                                onClick={() =>
                                                  handleDownload(images)
                                                }
                                              />
                                            </IconButton>
                                          </Box>
                                        </Box>
                                      )
                                    )}
                                  </Stack>
                                )}
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Stack
                          sx={{
                            gap: "10px",
                            display: { xs: "none", sm: "flex" },
                            flexDirection: "row",
                          }}
                        >
                          <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            {serviceData?.issueimgs !== "NA" &&
                              serviceData?.issueimgs !== "" && (
                                <Stack
                                  display="flex"
                                  flexDirection={"row"}
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    // overflowY: "hidden",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // overflowX: "auto",
                                  }}
                                >
                                  {serviceData?.issueimgs?.map(
                                    (images, index) => (
                                      <Box
                                        sx={{ height: "80%" }}
                                        key={index}
                                        position="relative"
                                        display="inline-block"
                                        mx={1} // Add some margin to separate the images
                                      >
                                        <Box
                                          as="img"
                                          src={images}
                                          alt="incImage"
                                          sx={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                        <Box
                                          position="absolute"
                                          bottom={-30}
                                          right={-10}
                                          borderRadius={25}
                                          sx={{ backgroundColor: "white" }}
                                        >
                                          <IconButton
                                            style={{ cursor: "pointer" }}
                                          >
                                            <DownloadIcon
                                              onClick={() =>
                                                handleDownload(images)
                                              }
                                            />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    )
                                  )}
                                </Stack>
                              )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  {Object.entries(IssueData1).map(([key, value]) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: { xs: "100%", sm: "100%", md: "60%" },

                          textAlign: "start",
                          minHeight: "130px",
                          background: "rgba(244, 246, 246, 1)",
                          padding: "20px",
                        }}
                      >
                        <Label>{key}</Label>

                        <Typography
                          sx={{
                            marginTop: "10px",
                            marginBottom: "20px",
                            wordWrap: "break-word",
                          }}
                        >
                          {value}
                        </Typography>
                        <Stack
                          sx={{
                            width: "20%",
                            gap: "10px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "row",
                          }}
                        >
                          <>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              {serviceData?.servicedimgs !== "NA" &&
                                serviceData?.servicedimgs !== "" && (
                                  <Stack
                                    display="flex"
                                    flexDirection={"row"}
                                    sx={{ justifyContent: "center" }}
                                  >
                                    {serviceData?.servicedimgs?.map(
                                      (images, index) => (
                                        <Box
                                          sx={{
                                            height: "85%",
                                            width: "30%",
                                          }}
                                          key={index}
                                          position="relative"
                                          display="inline-block"
                                        >
                                          <Box
                                            as="img"
                                            src={images}
                                            alt="incImage"
                                            sx={{
                                              width: "60px",

                                              height: "80px",
                                              margin: "10px",
                                            }}
                                          />
                                          <Box
                                            position="absolute"
                                            bottom={-30}
                                            right={-10}
                                            borderRadius={25}
                                            sx={{ backgroundColor: "white" }}
                                          >
                                            <IconButton
                                              style={{ cursor: "pointer" }}
                                            >
                                              <DownloadIcon
                                                onClick={() =>
                                                  handleDownload(images)
                                                }
                                              />
                                            </IconButton>
                                          </Box>
                                        </Box>
                                      )
                                    )}
                                  </Stack>
                                )}
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Stack
                          sx={{
                            gap: "10px",
                            display: { xs: "none", sm: "flex" },
                            flexDirection: "row",
                          }}
                        >
                          <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            {serviceData?.servicedimgs !== "NA" &&
                              serviceData?.servicedimgs !== "" && (
                                <Stack
                                  display="flex"
                                  flexDirection={"row"}
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    // overflowY: "hidden",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // overflowX: "auto",
                                  }}
                                >
                                  {serviceData?.servicedimgs?.map(
                                    (images, index) => (
                                      <Box
                                        sx={{ height: "80%" }}
                                        key={index}
                                        position="relative"
                                        display="inline-block"
                                        mx={1} // Add some margin to separate the images
                                      >
                                        <Box
                                          as="img"
                                          src={images}
                                          alt="incImage"
                                          sx={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                        <Box
                                          position="absolute"
                                          bottom={-30}
                                          right={-10}
                                          borderRadius={25}
                                          sx={{ backgroundColor: "white" }}
                                        >
                                          <IconButton
                                            style={{ cursor: "pointer" }}
                                          >
                                            <DownloadIcon
                                              onClick={() =>
                                                handleDownload(images)
                                              }
                                            />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    )
                                  )}
                                </Stack>
                              )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                  {Object.entries(IssueData2).map(([key, value]) => (
                    <Stack
                      sx={{
                        width: { sm: "100%", md: "60%" },

                        textAlign: "start",
                        minHeight: "130px",
                        background: "rgba(244, 246, 246, 1)",
                        padding: "20px",
                      }}
                    >
                      <Label>{key}</Label>

                      <Typography
                        sx={{ marginTop: "10px", wordWrap: "break-word" }}
                      >
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>

              {ExpenditureData?.Cost.length > 0 && (
                <Box>
                  <Stack
                    sx={{
                      textAlign: "start",

                      marginTop: "25px",
                      paddingLeft: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <Label variant="subtitle1">Expenditure Details</Label>
                    <Divider />
                  </Stack>

                  <Stack
                    flexDirection={"row"}
                    sx={{
                      gap: "20px",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      {serviceData.expenditure.length > 0 ? (
                        serviceData.expenditure.map((expenditure, index) => (
                          <Box
                            key={index}
                            elevation={2}
                            sx={{
                              display: { sm: "grid", md: "flex,", lg: "flex" },
                              marginBottom: "20px",
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Grid
                                container
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Reason
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Cost
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      margin: "5px",
                                      width: "100%",
                                      fontSize: "0.75rem",
                                      wordWrap: "break-word",
                                    }}
                                    variant="body1"
                                  >
                                    {expenditure}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ width: "100%", fontSize: "0.75rem" }}
                                  >
                                    ₹ {serviceData.amt_spent[index]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                            {/* <Box sx={{ width: "100%" }}>
                              {serviceData.receipt_id[index] ? (
                                <>
                                  <Typography sx={{ textAlign: "start", margin: "5px", fontWeight: "700" }} variant="body1">Receipt</Typography>
                                  <Stack
                                    sx={{
                                      width: "100%",

                                      textAlign: "start",
                                      minHeight: "130px",
                                      background: "rgba(244, 246, 246, 1)",
                                    }}
                                  >
                                    <Stack sx={{ position: "relative", width: "fit-content", margin: "10px" }}>
                                      <img
                                        src={`${serviceData.receipt_id[index]}`} // Replace with your image URL logic
                                        style={{ width: '100px', height: '100px' }} // Adjust styling as needed
                                        onClick={() => {
                                          const link = document.createElement('a');
                                          link.href = serviceData.receipt_id[index]; // Image URL
                                          link.download = `receipt_${index}.jpg`; // Specify the download filename
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        }}
                                      />
                                      <IconButton
                                        onClick={() => {
                                          const link = document.createElement('a');
                                          link.href = serviceData.receipt_id[index];
                                          link.download = `receipt_${index}.jpg`;
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        }}  
                                        aria-label="download"
                                        sx={{
                                          position: 'absolute',
                                          bottom: -6,
                                          right: -3,
                                          zIndex: 1,
                                          color: "black",
                                          padding: "0px",
                                          backgroundColor: "white",
                                          color:"#757575",
                                          padding:"5px" 
                                        }}
                                      >
                                        <DownloadIcon />
                                      </IconButton>

                                    </Stack>

                                  </Stack>
                                </>
                              ) : (
                                <Typography variant="body1">No image available</Typography>
                              )}

                            </Box> */}
                            <Box sx={{ width: "100%" }}>
                              {serviceData.receipt_id[index] ? (
                                <>
                                  <Typography
                                    sx={{
                                      textAlign: "start",
                                      margin: "5px",
                                      fontWeight: "700",
                                    }}
                                    variant="body1"
                                  >
                                    Receipt
                                  </Typography>
                                  <Stack
                                    sx={{
                                      width: "100%",
                                      textAlign: "start",
                                      minHeight: "130px",
                                      background: "rgba(244, 246, 246, 1)",
                                      margin: "10px",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        position: "relative",
                                        width: "fit-content",
                                        margin: "10px",
                                      }}
                                    >
                                      {(() => {
                                        const receiptUrl =
                                          serviceData.receipt_id[index];
                                        // console.log("Receipt URL:", receiptUrl);
                                        if (isPDF(receiptUrl)) {
                                          // console.log("This is a PDF file.");
                                          return (
                                            <IconButton>
                                              {/* <PictureAsPdfIcon />{" "} */}
                                              <img
                                                src={pdfImage}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              ></img>
                                            </IconButton>
                                          );
                                        } else {
                                          // console.log("This is an image file.");
                                          return (
                                            <img
                                              src={receiptUrl}
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              onClick={() => {
                                                const link =
                                                  document.createElement("a");
                                                link.href = receiptUrl;
                                                link.download = `receipt_${index}.jpg`;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                              }}
                                            />
                                          );
                                        }
                                      })()}
                                      <IconButton
                                        onClick={() => {
                                          const link =
                                            document.createElement("a");
                                          link.href =
                                            serviceData.receipt_id[index];
                                          link.download = `receipt_${index}.jpg`;
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        }}
                                        aria-label="download"
                                        sx={{
                                          position: "absolute",
                                          bottom: -6,
                                          right: -3,
                                          zIndex: 1,
                                          color: "black",
                                          padding: "0px",
                                          backgroundColor: "white",
                                          color: "#757575",
                                          padding: "5px",
                                        }}
                                      >
                                        <DownloadIcon />
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </>
                              ) : (
                                <Typography variant="body1">
                                  No image available
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <div>No expenditures found.</div>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              )}
              <Stack
                sx={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <Button
                  onClick={handleDownloadPDF}
                  sx={{
                    border: "2px solid #4690ff",
                    borderRadius: "20px",
                    fontWeight: "bold",
                    color: "#4690ff",
                    width: "120px",
                  }}
                >
                  Download PDF
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            height: "100%",
          }}
        >
          <Stack
            sx={{
              width: {
                xs: "100%",
                sm: "500px  ",
                md: "750px",
                lg: "750px",
                // xl: "auto"
              },

              borderRadius: "8px",

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              gap: "10px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",

                padding: "10px 20px 10px 20px",
              }}
            >
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}
              >
                Service Request
              </Label>

              <Label>{serviceData.reqdate}</Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: {
                      xs: "",
                      sm: "none",
                      md: "block",
                    },
                  }}
                >
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                      <TableBody
                        sx={{
                          display: {
                            xs: "table-row-group",
                            sm: "table-row-group",
                            md: "none",
                          },
                        }}
                      >
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                    },
                  }}
                >
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}
                  >
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}
                              >
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Issue
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Stack
                    sx={{
                      width: "auto",

                      textAlign: "start",
                      minHeight: "170px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography sx={{ marginTop: "10px" }}>
                      {serviceData?.issue}
                    </Typography>
                    {serviceData?.issueimgs !== "NA" &&
                      serviceData?.issueimgs !== "" && (
                        <Stack
                          flexDirection={"row"}
                          sx={{
                            width: "100%",
                            height: "100px",
                            justifyContent: "center",
                            alignItems: "center",
                            // overflowX: "auto",
                            display: { xs: "flex", sm: "none" },
                            marginTop: "10px",
                          }}
                        >
                          {serviceData?.issueimgs?.map((images, index) => (
                            <Box
                              sx={{ height: "80%" }}
                              key={index}
                              position="relative"
                              display="inline-block"
                              mx={1}
                            >
                              <Box
                                as="img"
                                src={images}
                                alt="incImage"
                                sx={{ width: "70px", height: "80px" }}
                              />
                              <Box
                                position="absolute"
                                bottom={-5}
                                right={0}
                                borderRadius={0}
                                sx={{ backgroundColor: "white" }}
                              >
                                <IconButton
                                  style={{ cursor: "pointer", padding: "0px" }}
                                >
                                  <DownloadIcon
                                    onClick={() => handleDownload(images)}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      )}
                  </Stack>
                </Stack>
                {serviceData?.issueimgs !== "NA" &&
                  serviceData?.issueimgs !== "" && (
                    <Stack
                      flexDirection={"row"}
                      sx={{
                        width: "50%",
                        height: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        // overflowX: "auto",
                        display: { xs: "none", sm: "flex" },
                      }}
                    >
                      {serviceData?.issueimgs?.map((images, index) => (
                        <Box
                          sx={{ height: "80%" }}
                          key={index}
                          position="relative"
                          display="inline-block"
                          mx={1}
                        >
                          <Box
                            as="img"
                            src={images}
                            alt="incImage"
                            sx={{ width: "80px", height: "80px" }}
                          />
                          <Box
                            position="absolute"
                            bottom={-5}
                            right={0}
                            borderRadius={0}
                            sx={{ backgroundColor: "white" }}
                          >
                            <IconButton
                              style={{ cursor: "pointer", padding: "0px" }}
                            >
                              <DownloadIcon
                                onClick={() => handleDownload(images)}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              // width: "750px",
              height: "70px",
              padding: "20px",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
                      {userAccess.role !== "Guest" && (

            <Button
              onClick={() =>
                navigate(
                  `/clear-service/${serviceData?.assetid}/${serviceData.serv_id}`
                )
              }
              variant="contained"
              sx={{ width: "150px", borderRadius: "22px" }}
            >
              Clear Service
            </Button>
                      )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ServiceDetail;
