import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, styled, Button, Tooltip, Divider } from "@mui/material";
import { DownloadPDF, userManual } from "../redux/data/action";
import { Link, useNavigate } from "react-router-dom";
import nomannul from "../images/No Mannul.png";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const UserManualList = () => {
  const navigate = useNavigate();
  const userManualData = useSelector((store) => store.data?.userManual);
  const localStorageId = JSON.parse(localStorage.getItem("userData"));
  const userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));
  let userIds =
  JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"));
  const [brandData, setBrandData] = useState([]);
  const [manualBrand, setManualBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(null); // New state to track selected brand
  const dispatch = useDispatch();

  const search = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = userManualData?.manuallist?.filter((item) =>
        item?.product_name.toLowerCase().includes(keyword.toLowerCase()) ||
      item?.brand_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item?.model_no.toLowerCase().includes(keyword.toLowerCase())       
      );
      let data = { manuallist: results };
      setManualBrand(data);
    } else {
      setManualBrand(userManualData);
    }
    setProductName(keyword);
  };

  useEffect(() => {
    let data = {
      hospid: localStorageId?.hospid,
      userid: localStorageId?.userid,
      userrole:userAccess?.role,
      depart_ids:userAccess.departmentids,
    };
    dispatch(userManual(data, localStorage.getItem("userToken")));
  }, [dispatch]);

  useEffect(() => {
    if (userManualData?.brandlist?.length > 0) {
      setBrandData(userManualData?.brandlist);
    }
  }, [userManualData?.brandlist]);

  useEffect(() => {
    if (userManualData?.manuallist?.length > 0) {
      let data = { manuallist: userManualData?.manuallist };
      setManualBrand(data);
    }
  }, [userManualData?.manuallist]);

  const handleFilter = (clickedBrand) => {
    const filtered = userManualData?.manuallist?.filter(
      (item) => item?.brand_name === clickedBrand?.brand_name
    );
    if (filtered?.length !== "") {
      let data = { manuallist: filtered };
      setManualBrand(data);
    } else {
      setManualBrand(userManualData);
    }
    setSelectedBrand(clickedBrand);
  };

  useEffect(() => {
    if (userIds?.subs_plan === "Basic" ) {
      navigate("/"); // Redirect to homepage
    }
  }, [userIds?.subs_plan, navigate]);

  return (
    <Stack>
      <Box display="flex" flexDirection="row" height="85vh" width={"100%"} gap={"10px"}>
        <Stack
          sx={{
            width: {
              xs: selectedBrand ? "0%" : "100%",
              sm: selectedBrand ? "0%" : "100%",
              md: "40%",
              lg: "40%",
              xl: "40%",
            },
            display: {
              xs: selectedBrand ? "none" : "block",
              sm: selectedBrand ? "none" : "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
            transition: "width 0.3s ease",


          }}
        >
          <Stack

            flexDirection={"row"}
            height="50px"
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            marginBottom={"10px"}
            
            sx={{
              
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              width: "auto",
            }}>
            Total brands:
            <Typography style={{ color: "rgba(70, 144, 255, 1)" }}>
              {brandData.length}
            </Typography>
          </Stack>
          <Stack

            sx={{ bgcolor: "white", boxShadow: "0px 0px 15px rgba(219, 231, 229, 1)", gap: "10px", paddingLeft: "10px", paddingRight: "10px", height: {xs: "540px",  md: "655px"}, overflow: "auto" }}>
            <Typography sx={{ margin: "15px 0 0 5px", display: "flex" }}>Brands</Typography>
            <Stack sx={{ width: "100%", bgcolor: "rgba(70, 144, 255, 1)", margin: "5px 0" }}>
              <Divider />
            </Stack>
            {brandData.map((imageUrl, index) => (
              <Stack
                key={index}
                onClick={() => handleFilter(imageUrl)}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  cursor: "pointer",
                  height: "100%"
                }}
              >
                <Button sx={{ color: "black" }}>{imageUrl?.brand_name}</Button>
                <Box as="img" sx={{ height: "50px", width: "50px" }} src={imageUrl?.brand_icon_url} />
              </Stack>
            ))}
          </Stack>
        </Stack>


        <Box

          sx={{
            display: {
              xs: selectedBrand ? "block" : "none",
              sm: selectedBrand ? "block" : "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
              lg: "60%",
              xl: "60%",
            },
            transition: "display 0.3s ease",
          }}
        >
          <Box flex="2">
            <Stack marginBottom={"10px"} flexDirection="row" justifyContent="space-between" padding={"10px 16px 14px 16px"} sx={{ bgcolor: "white" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon size={15} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  value={productName}
                  onChange={search}
                />
              </Search>
            </Stack>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0px 0px 15px rgba(219, 231, 229, 1)",
                height: { xs: "430px", md: "560px" },
              }}
            >
              {manualBrand?.manuallist?.length > 0 ?  <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Model No</TableHeading>
                    <TableHeading>Branch</TableHeading>
                    <TableHeading style={{ textAlign: "center" }}>
                      Download PDF
                    </TableHeading>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manualBrand?.manuallist?.map((el) => (
                    <TableRow key={el?.prod_id}>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal',textDecoration: "none"}}
                        component={Link}
                      >
                        <Tooltip title={el?.product_name}>
                          {el?.product_name}
                        </Tooltip>
                      </TableCells>
                      <TableCells>{el?.model_no}</TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal',textDecoration: "none"}}
                        component={Link}
                      >
                        <Tooltip title={el?.brand_name}>
                          {el?.brand_name}
                        </Tooltip>
                      </TableCells>
                      <TableCells style={{ textAlign: "center" }}>
                        <DownloadIcon
                          onClick={() =>
                            dispatch(
                              DownloadPDF(el?.manual_link, el?.brand_name)
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>:
             <Stack sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>
             <img src={nomannul} style={{height:"50%",width:"50%"}}></img>
             <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
               No Mannul found.
               </Typography>
             </Stack>
           
                
                
                }
             
            </TableContainer>
            <Stack
              sx={{
                width: "auto",
                padding: "20px",
                bgcolor: "white",
                gap: "20px",
              }}>
              <Divider />
              <Stack
                sx={{
                  bgcolor: "white",

                  alignItems: "flex-end",
                  flexDirection: "column",
                }}>
                <Button
                  onClick={() => navigate("/contact")}
                  variant="contained"
                  sx={{
                    borderRadius: "22px",
                    background: "rgba(70, 144, 255, 1)",
                    marginRight: "20px",
                    width: "170px",
                  }}>
                  Request Manual
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default UserManualList;
