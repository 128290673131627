import React, { useEffect, useState } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import { v4 as uuid } from "uuid";

import {
  serviceWorkOrder,
  retrieveServiceAssets,
  createServiceImageUpload,
  serviceRequestImageRemove,
  addWorkOrderList,
  serviceList,
} from "../redux/data/action";

import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "auto",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  "& fieldset": { border: "none" },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const WorkOrderPage = () => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const [open, setOpen] = useState(false);
  const [getAllData, setGetAllData] = useState([]);
  const [data, setData] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const [emailError, setEmailError] = useState("");
  const [isMobileNumberError, setIsMobileNumberError] = useState(null);

  const Workorderlist = useSelector((store) => store.data.serviceworkorderdata);
  const [filteredData, setFilteredData] = useState([]); // For filtered data
  const [descriptionError, setDescriptionError] = useState("");

  const serviceAssets = useSelector(
    (store) => store?.data?.retrieveServiceAsset
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(serviceWorkOrder(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: "all",
      userrole: userAccess?.role,
    };

    dispatch(retrieveServiceAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(Workorderlist) && Workorderlist.length > 0) {
      setData(Workorderlist);
    }
  }, [Workorderlist]);

  const navigate = useNavigate();

  useEffect(() => {
    if (serviceAssets && serviceAssets?.length !== 0) {
      setGetAllData(serviceAssets);
    }
  }, [serviceAssets]);

  const handleOpenBox = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (Workorderlist?.length > 0) {
      setData(Workorderlist);
    }
  }, [Workorderlist]);

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const handleCloseBox = () => {
    setOpen(false);
  };
  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };

    dispatch(serviceList(data, token));
  }, [dispatch]);

  const handleDescription = (e) => {
    let value = e.target.value;
    const regex = /^[a-zA-Z0-9.,\s-]*$/;

    setDescription(value);

    if (value.trim() === "") {
      setDescriptionError("Description is required");
    } else if (value.length < 3) {
      setDescriptionError("required description");
    } else if (!regex.test(value)) {
      setDescriptionError("Enter a valid description");
    } else {
      setDescriptionError("");
    }
  };
  const [assetError, setAssetError] = useState("");

  const handleAssetName = (event, value) => {
    const selected = getAllData?.assets?.find(
      (asset) => `${asset.name} - ${asset.assetcode}` === value
    );
    setSelectedAsset(selected || null); // Set the selected object or null if none
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Wrong  email");
    }
    if (value === "") {
      setEmailError("Please fill the field");
    }
  };

  const handleImageCancel = () => {
    setSelectedImage(null);
  };
  const HospitalLeftDataOpen = {
    "Asset Name": selectedAsset?.name || "N/A",
    "Asset Code": selectedAsset?.assetcode || "N/A",
    Department: selectedAsset?.department || "N/A",
    "Serial No.": selectedAsset?.serialno || "N/A",
    "Modal No.": selectedAsset?.modelno || "N/A",
    Branch: selectedAsset?.brand || "N/A",
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.-]+$/i;
    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const handleNumber = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, "");

    // Limit input to 10 digits
    if (value.length > 10) return;

    const regex = /^\d{10}$/;

    setWhatsapp(value);

    if (value === "") {
      setIsMobileNumberError("Please fill the field");
    } else if (!regex.test(value)) {
      setIsMobileNumberError("Wrong Mobile number");
    } else {
      setIsMobileNumberError(null);
    }
  };

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 4 MB

      if (!allowedTypes.includes(singlefile.type)) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        setIsPhotoError(`Can't upload image more than 4MB.`);
        return;
      }

      setIsPhotoError(null);
      const reader = new FileReader();

      reader.onloadend = () => {
        let uuidShort = uuid().replace(/-/g, "").substring(0, 16); // Extract 8 characters from UUID
        let assetPrefix = "AST_"; // Ensuring a fixed prefix
        let imageid = `${assetPrefix}${uuidShort}`.substring(0, 16); // Ensuring exactly 16 characters

        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}.${fileType}`, // Appends file extension
          image: reader.result,
          branchid: userBranch.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };

        setFileUrl([...fileUrl, sendData]);
        dispatch(createServiceImageUpload(sendData, token));
      };

      reader.readAsDataURL(singlefile);
    }
  };

  const handleDelete = (id) => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      imageid: id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(serviceRequestImageRemove(data));
    setFileUrl(fileUrl?.filter((el) => el?.imageid !== id));
  };
  const validateForm = () => {
    let isValid = true;


    if (!selectedAsset) {
      setAssetError("Asset selection is required");
      isValid = false;
    } else {
      setAssetError("");
    }


    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    }
   


    if (!whatsapp || whatsapp.length !== 10) {
      setIsMobileNumberError("Valid 10-digit mobile number is required");
      isValid = false;
    }


    if (
      !email ||
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      setEmailError("Valid email is required");
      isValid = false;
    }


    if (!description || description.length < 3) {
      setDescriptionError("Description must be at least 3 characters");
      isValid = false;
    }


    if (!fileUrl || fileUrl.length === 0) {
      setIsPhotoError("At least one image is required");
      isValid = false;
    } else {
      setIsPhotoError("");
    }


    return isValid;
  };


  const handleSubmit = () => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }


    const payload = {
      assetcode: selectedAsset.assetcode,
      assetid: selectedAsset.assetid,
      assetname: selectedAsset.name,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      brand: selectedAsset.brand,
      depart_ids: "all",
      hospid: ids?.hospid,
      hospname: ids?.hospname,
      userid: ids?.userid,
      departname: selectedAsset.department,
      departid: selectedAsset?.departid,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
      issuedetail_img: fileUrl?.map((file) => file.imageid),
      request_email: email,
      request_mobile: whatsapp,
      request_name: name,
      issuedetail: description,
      username: ids?.username,
      usermail: ids?.email,
      userphone: ids?.mobile,
      serialno: selectedAsset.serialno,
      assetcode: selectedAsset.assetcode,
      modelno: selectedAsset.modelno,
      incident: "No",
      incident_id: "",
      react_time: "",
    };
    dispatch(addWorkOrderList(payload)).then(() => {
      let data = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        branchid: userBranch.id,
        depart_ids: userAccess.departmentids,
        userrole: userAccess?.role,
      };
      dispatch(serviceWorkOrder(data, token));
      setSelectedAsset(null);
      setName("");
      setWhatsapp("");
      setEmail("");
      setDescription("");
      setFileUrl([]); // Assuming fileUrl is an array
      setAssetError("");
      setNameError("");
      setIsMobileNumberError("");
      setEmailError("");
      setDescriptionError("");
      setIsPhotoError("");


      handleCloseBox();
      handleCloseBox();
    });
  };


  return (
    <Box
      display="flex"
      alignItems="stretch"
      gap="10px"
      padding="16px"
      sx={{
        flexDirection:{
          xs:"column",
          sm:"column",
          md:"row"
        },
        height: {
          xs: "200vh",
          sm: "200vh",
          md: "90vh",
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Work Order.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          {userAccess.role !== "Guest" && (
            <Button
              onClick={handleOpenBox}
              sx={{
                background: "rgba(70, 144, 255, 1)",
                color: "white",
                "&:hover": {
                  background: "rgba(70, 144, 255, 1)", // Keep the same background on hover
                  color: "white", // Keep the same text color on hover
                },
              }}
            >
              Create Work Order <AddIcon />
            </Button>
          )}
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
          height="auto"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Typography>Total :</Typography>
            <Typography sx={{ color: "#4690FF" }}>
              {` ${data?.length}`}
            </Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data} // Pass the updated data state
              setData={setData} // Pass the state updater function
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="dept"
              Filter2="serv_status"
              FilterName1="Department"
              FilterName2="Status"
              sortDate="reqdate"
              dueDate=""
              sortPrice=""
              Grade=""
              name="assetname"
              csvName="All Assets"
            />
          </Box>
          {userAccess.role !== "Guest" && (

          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Button
              onClick={handleOpenBox}
              sx={{
                background: "rgba(70, 144, 255, 1)",
                color: "white",
                "&:hover": {
                  background: "rgba(70, 144, 255, 1)", 
                  color: "white",
                },
              }}
            >
              Create Work Order <AddIcon />
            </Button>
          </Stack>
)}
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {/* {filteredData.length > 0 ? ( */}
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 237, 237, 1)",
                    },
                  }}
                >
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>

                  <TableHeading>Department</TableHeading>

                  <TableHeading>Date & Time</TableHeading>

                  <TableHeading>Company Name</TableHeading>
                  <TableHeading>User</TableHeading>
                  <TableHeading
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Status
                  </TableHeading>
                  <TableHeading>Reaction Time</TableHeading>
                </TableRow>
              </TableHead>

              <TableBody style={{ overflowY: "scroll" }}>
                {currentData.length > 0 ? (
                  currentData.map((item, index) => (
                    <TableRow
                      key={item?.servid}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.dept}>{item.dept}</Tooltip>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        {item.servdate === item?.servtime ? (
                          <></>
                        ) : (
                          <>
                            <>{item?.servdate}</>@<>{item?.servtime}</>
                          </>
                        )}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.company}>{item.company}</Tooltip>
                      </TableCells>
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        <Tooltip title={item.req_name}>{item.req_name}</Tooltip>
                      </TableCells>
                      {item?.serv_status === "Open" ? (
                        <TableCells
                          sx={{
                            textOverflow: "clip",
                            whiteSpace: "normal",
                            textAlign: "start",
                          }}
                        >
                          <Link
                            to={`/service-list/${item?.servid}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Box
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                height: "30px",
                                padding: "4px",

                                width: {
                                  xs: "85px",
                                  sm: "65px",
                                  md: "70px",
                                  lg: "100px",
                                },
                                bgcolor: "rgba(255, 107, 0, 1)",

                                border: "1px solid rgba(255, 107, 0, 1)",

                                color: "white",
                              }}
                            >
                              <Typography
                                variant="caption"
                                display="block"
                                sx={{
                                  color: "white",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Close
                              </Typography>
                            </Box>
                          </Link>
                        </TableCells>
                      ) : (
                        <TableCells
                          sx={{
                            textOverflow: "clip",
                            whiteSpace: "normal",
                            textAlign: "center",
                          }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              borderRadius: "30px",
                              margin: "auto",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              height: "30px",
                              width: "100px",

                              color: " rgba(70, 144, 255, 1)",
                            }}
                          >
                            <Link
                              to={`/service-list/${item?.servid}`}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                variant="caption"
                                display="block"
                                sx={{ color: "black" }}
                              >
                                Closed
                              </Typography>
                            </Link>
                          </Box>
                        </TableCells>
                      )}
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                        to={`/service-list/${item?.servid}`}
                      >
                        {item.servdate === item?.servtime ? (
                          <></>
                        ) : (
                          <>
                            <>{item?.reqdate}</>@<>{item?.reqtime}</>
                          </>
                        )}
                      </TableCells>
                    </TableRow>
                  ))
                ) : (
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Not Work Order
                    </Typography>
                  </Stack>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            sx={{
              padding: "10px",
              bgcolor: "white",
              gap: "20px",
            }}
          >
            <Divider />
            <Stack
              sx={{
                bgcolor: "white",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate("/vendor")}
                sx={{
                  borderRadius: "22px",
                  background: "rgba(70, 144, 255, 1)",
                  marginRight: "20px",
                  width: "150px",
                }}
              >
                Suppliers
              </Button>

              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            bgcolor: "white",
            padding: "20px",
            height: "100%",
            overflow: "hidden",
            overflowY: "scroll",
            width: {
              xs: "auto",
              sm: "auto",
              md: "40%",
            },
          }}
          width="40%"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}
          >
            <Label variant="h6" style={{ color: "#4690FF" }}>
              Create Work Order
            </Label>
            <CloseIcon
              size={15}
              style={{
                color: " rgba(255, 58, 68, 1)",
                cursor: "pointer",
              }}
              onClick={handleCloseBox}
            />
          </Box>
          <Divider />

          <Stack
            sx={{
              width: "100%",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                width: "100%",
                gap: "20px",
                textAlign: "start",
              }}
            >
              <Label>Asset Details <span style={{ color: "red", fontWeight: "100" }}>*</span>
              </Label>
            </Box>
            <Stack>
              <Autocomplete
                id="asset-name-dropdown"
                value={
                  selectedAsset
                    ? `${selectedAsset.name} - ${selectedAsset.assetcode}`
                    : null
                }
                onChange={handleAssetName}
                options={
                  getAllData?.assets?.map(
                    (option) => `${option.name} - ${option.assetcode}`
                  ) || []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Asset Name"
                    sx={{
                      width: "300px",
                      background: "rgba(244, 246, 246, 1)",
                      "& fieldset": { border: "none" },
                    }}
                  />
                )}
              />
              <FormHelperText sx={{ color: "red" }}>
                {assetError}
              </FormHelperText>
            </Stack>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                {selectedAsset && (
                  <Table sx={{ maxWidth: "100%", marginLeft: "10px" }}>
                    <TableBody>
                      {Object.entries(HospitalLeftDataOpen).map(
                        ([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                                padding: "2px",
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells sx={{ padding: "2px" }}>
                              {value}
                            </TableCells>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "5px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  style={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Contact Details
                </Label>
                <Divider />
              </Stack>
              <Stack sx={{ gap: "5px" }}>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <label style={{ justifyContent: "left", display: "flex" }}>
                    Company Name:<span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </label>
                  <FormInput
                    id="filled-basic"
                    label="Name"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={name}
                    onChange={handleName}
                  />

                  <FormHelperText sx={{ color: "red" }}>
                    {nameError}
                  </FormHelperText>
                </Stack>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <label style={{ justifyContent: "left", display: "flex" }}>
                    Mobile (What'sapp)<span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </label>

                  <FormInput
                    id="filled-basic"
                    label="Mobile (What'sapp)"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={whatsapp}
                    onChange={handleNumber}
                  />

                  <FormHelperText sx={{ color: "red" }}>
                    {isMobileNumberError}
                  </FormHelperText>
                </Stack>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <label style={{ justifyContent: "left", display: "flex" }}>
                    Email:<span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </label>

                  <FormInput
                    id="filled-basic"
                    label="Email"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={email}
                    onChange={handleEmail}
                  />

                  <FormHelperText sx={{ color: "red" }}>
                    {emailError}
                  </FormHelperText>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "5px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  Description<span style={{ color: "red", fontWeight: "100" }}>*</span>
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>

              <Stack>
                <FormInput
                  id="filled-basic"
                  label="Description"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  value={description}
                  onChange={handleDescription}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {descriptionError}
                </FormHelperText>
              </Stack>

              <Stack
                sx={{
                  marginTop: "10px",
                  paddingLeft: "10px",
                  gap: "10px",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    <Label
                      variant="subtitle1"
                      sx={{ color: "rgba(70, 144, 255, 1)" }}
                    >
                      Upload Image<span style={{ color: "red", fontWeight: "100" }}>*</span>
                    </Label>
                  </Box>
                  <Box>
                    {uploadedImageName?.length < 3 && (
                      <>
                        <label htmlFor="img">
                          <FileUploadIcon
                            size={20}
                            style={{
                              cursor: "pointer",
                              color: "rgba(70, 144, 255, 1)",
                              height: "100%",
                              display: "flex",
                              alignItems: "end",
                            }}
                          />
                        </label>

                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",
                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handlePhotoUpload}
                        />
                      </>
                    )}
                  </Box>
                </Stack>

                {fileUrl?.length > 0 && (
                  <>
                    <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
                    <Stack
                      sx={{
                        height: "100px",
                        width: "auto",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {fileUrl?.map((item, index) => (
                        <Badge
                          key={index}
                          badgeContent={
                            <CloseIcon
                              size={15}
                              style={{
                                color: "rgba(255, 58, 68, 1)",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(item?.imageid)}
                            />
                          }
                          sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            marginTop: "10px",
                          }}
                        >
                          <Box
                            as="img"
                            src={item?.image}
                            alt=""
                            sx={{ width: "80px", height: "90px" }}
                          />
                        </Badge>
                      ))}
                    </Stack>
                  </>
                )}

                <Stack
                  sx={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                </Stack>
              </Stack>
            </Box>
            <Stack
              sx={{
                position: "relative",
                bottom: "30px",
                justifyContent: "space-between",
              }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default WorkOrderPage;
