import React, { useEffect, useState } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import { v4 as uuid } from "uuid";
import pdficon from "../../src/images/pdfImage.png";
import ImageIcon from "@mui/icons-material/Image";
import UploadIcon from "@mui/icons-material/Upload";

import {
  accessoryUploadImage,
  accessoryDeleteImage,
  createAccessory,
  createAccessoryNameList,
  accessoryUpdateList,
  AccessoryList,
} from "../redux/data/action";

import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "auto",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  "& fieldset": { border: "none" },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const AccessoriesList = () => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const [open, setOpen] = useState(false);
  const [getAllData, setGetAllData] = useState([]);
  const [data, setData] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const [filteredData, setFilteredData] = useState([]);
  const [accessoryName, setAccessoryName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [purchasedDate, setPurchasedDate] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);
  const dispatch = useDispatch();
  const namelist = useSelector((store) => store.data.accessorynamelistdata);
  const accesarylist = useSelector((store) => store.data.accessorylistdata);
  const [otherAccessory, setOtherAccessory] = useState("");
  const [accessoryError, setAccessoryError] = useState("");

  useEffect(() => {
    if (accesarylist?.length > 0) {
      setData(accesarylist);
    }
  }, [accesarylist]);

  const [errors, setErrors] = useState({});
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(value);
      setTotalPrice(value * unitPrice);
      setErrors((prev) => ({ ...prev, quantity: "" }));
    }
  };

  const handleUnitPriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setUnitPrice(value);
      setTotalPrice(quantity * value);
      setErrors((prev) => ({ ...prev, unitPrice: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    let errorMessage = "";

    if (!accessoryName?.trim()) {
      newErrors.accessoryName = "Accessory Name is required";
      errorMessage = "Please enter Accessory Name.";
    }

    if (accessoryName === "Other" && !otherAccessory?.trim()) {
      newErrors.otherAccessory = "Accessory Name is required";
      errorMessage = "Please enter Accessory Name.";
    }
    

    if (!(quantity?.trim()) || Number(quantity) === 0) {
      newErrors.quantity = "Quantity must be greater than 0";
      errorMessage = "Please enter a valid Quantity.";
    }
    if (!unitPrice?.trim() || Number(unitPrice) === 0) {
      newErrors.unitPrice = "Unit Price must be greater than 0";
      errorMessage = "Please enter a valid Unit Price.";
    }
    if (!vendorName?.trim()) {
      newErrors.vendorName = "Vendor Name is required";
      errorMessage = "Please enter Vendor Name.";
    }
    if (!purchasedDate?.trim()) {
      newErrors.purchasedDate = "Purchased Date is required";
      errorMessage = "Please enter Purchased Date.";
    }
    if (!fileUrl || fileUrl.length === 0) {
      newErrors.fileUrl = "Please upload an image or pdf";
      errorMessage = "Please upload an image or PDF.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setToastMessage(errorMessage);
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000); // Hide toast after 3 seconds
      return false;
    }

    return true;
  };

  const accessoryOptions = [
    ...(namelist?.map((item) => item.accessname) || []),
    "Other",
  ];

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(AccessoryList(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(createAccessoryNameList(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: "all",
      userrole: userAccess?.role,
    };

    // dispatch(retrieveServiceAssets(data, token));
  }, [dispatch]);

  const handleOpenBox = () => {
    setOpen(true);
  };

  const handleCloseBox = () => {
    setOpen(false);
    setAccessoryName("");
    setQuantity("");
    setUnitPrice("");
    setTotalPrice("");
    setVendorName("");
    setPurchasedDate("");
    setFileUrl([]);
  };

  const handleDownload = (pdfUrl) => {
    if (!pdfUrl) {
      console.error("PDF URL is missing");
      return;
    }
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "invoice.pdf"); // Set filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePhotoUpload = (e) => {
    const newFiles = Array.from(e.target.files).filter((file) => {
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 4 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        setIsPhotoError(`Can't upload ${file.type.split("/")[1]} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsPhotoError(`Can't upload image more than 4MB.`);
        return false;
      }

      setIsPhotoError(null);
      return true;
    });

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileExt = file.type.split("/")[1]; // Get file extension
        const uniqueId = uuid()
          .replace(/-/g, "")
          .substring(0, 15 - (fileExt.length + 1)); // Adjust length to fit 15 chars

        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${uniqueId}.${fileExt}`, // Ensure total length = 15
          image: reader.result,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };

        setFileUrl((prev) => [...prev, sendData]);
        dispatch(accessoryUploadImage(sendData, token));
      };
      reader.readAsDataURL(file);
    });
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(accessoryDeleteImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  const [selectedAccessoryId, setSelectedAccessoryId] = useState("");

  const handleSubmit = () => {
    if (validateForm()) {
      const finalAccessoryName =
        accessoryName === "Other" ? otherAccessory.trim() : accessoryName;

      // Check for duplicate accessory name before submission
      const isDuplicate = namelist.some(
        (item) =>
          item.accessname.toLowerCase() === finalAccessoryName.toLowerCase()
      );

      if (isDuplicate) {
        setErrors((prev) => ({
          ...prev,
          otherAccessory: "Product already exists",
        }));
        return; // Stop submission
      }

      const receiptId =
        fileUrl?.length > 0
          ? fileUrl.map((item) => item.imageid).join(",")
          : "NA";

      const data = {
        hospid: ids?.hospid,
        hospname: userIds?.hospname,
        branchid: userBranch.id,
        branchname: userBranch.branch,
        userid: ids?.userid,
        username: userIds?.username,
        accsname: finalAccessoryName, // Use entered product name if "Other" is selected
        price: Number(unitPrice),
        quantity: Number(quantity),
        totprice: Number(totalPrice),
        receiptid: receiptId,
        vendor: vendorName,
        purch_date: purchasedDate,
        userrole: userAccess?.role,
      };
      dispatch(createAccessory(data))
        .then(() => {
          let accessoryData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            branchid: userBranch.id,
            depart_ids: userAccess.departmentids,
            userrole: userAccess?.role,
          };
          dispatch(createAccessoryNameList(accessoryData, token));
        })
        .then(() => {
          let accessoryListData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            branchid: userBranch.id,
            depart_ids: userAccess.departmentids,
            userrole: userAccess?.role,
          };

          dispatch(AccessoryList(accessoryListData, token));
        })
        .finally(() => {
          handleCloseBox();
        });
    }
  };

  const handleUpdate = () => {

    const isInList = namelist?.some(
      (item) =>
        item.accessname?.trim().toLowerCase() === accessoryName?.trim().toLowerCase()
    );
  
    if (!isInList) {
      setAccessoryError("This product is not Existed");
      return;
    } else {
      setAccessoryError(""); // Clear error if found
    }
    
    if (validateForm()) {
      const receiptId =
        fileUrl?.length > 0
          ? fileUrl.map((item) => item.imageid).join(",")
          : "NA";

      const data = {
        hospid: ids?.hospid,
        hospname: userIds?.hospname,
        branchid: userBranch.id,
        branchname: userBranch.branch,
        userid: ids?.userid,
        username: userIds?.username,
        accsid: selectedAccessoryId,
        accsname: accessoryName,
        price: Number(unitPrice),
        quantity: Number(quantity),
        totprice: Number(totalPrice),
        receiptid: receiptId,
        vendor: vendorName,
        purch_date: purchasedDate,
        userrole: userAccess?.role,
      };
      dispatch(accessoryUpdateList(data))
        .then(() => {
          let accessoryData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            branchid: userBranch.id,
            depart_ids: userAccess.departmentids,
            userrole: userAccess?.role,
          };
          dispatch(createAccessoryNameList(accessoryData, token));
        })
        .then(() => {
          let accessoryListData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            branchid: userBranch.id,
            depart_ids: userAccess.departmentids,
            userrole: userAccess?.role,
          };

          dispatch(AccessoryList(accessoryListData, token));
        })
        .finally(() => {
          handleCloseBox();
        });
    }
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  return (
    <Box
      display="flex"
      alignItems="stretch"
      gap="10px"
      padding="16px"
      sx={{
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
        },
        height: {
          xs: "150vh",
          sm: "150vh",
          md: "90vh",
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
        >
          <Label variant="h6">
            Accessary List
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          {userAccess.role !== "Guest" && (
            <Button
              onClick={handleOpenBox}
              sx={{
                background: "rgba(70, 144, 255, 1)",
                color: "white",
                "&:hover": {
                  background: "rgba(70, 144, 255, 1)", // Keep the same background on hover
                  color: "white", // Keep the same text color on hover
                },
              }}
            >
              Add Accessories <AddIcon />
            </Button>
          )}
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
          height="auto"
        >
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}
          >
            <Typography>Total :</Typography>
            <Typography sx={{ color: "#4690FF" }}>
              {` ${data?.length}`}
            </Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data} // Pass the updated data state
              setData={setData} // Pass the state updater function
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1=""
              Filter2=""
              FilterName1=""
              FilterName2=""
              sortDate=""
              dueDate=""
              sortPrice="tot_cost"
              Grade=""
              name="accessname"
              csvName="All Assets"
            />
          </Box>
          {userAccess.role !== "Guest" && (
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              <Button
                onClick={handleOpenBox}
                sx={{
                  background: "rgba(70, 144, 255, 1)",
                  color: "white",
                  "&:hover": {
                    background: "rgba(70, 144, 255, 1)", // Keep the same background on hover
                    color: "white", // Keep the same text color on hover
                  },
                }}
              >
                Add Accessories <AddIcon />
              </Button>
            </Stack>
          )}
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {/* {filteredData.length > 0 ? ( */}
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 237, 237, 1)",
                    },
                  }}
                >
                  <TableHeading>Accessory Name</TableHeading>
                  <TableHeading>Price * Quantity</TableHeading>
                  <TableHeading>Total Unit Price</TableHeading>
                  <TableHeading>Total Cost</TableHeading>
                  <TableHeading>Purchase History</TableHeading>
                  <TableHeading>Total Used</TableHeading>
                  <TableHeading>Quantity Available</TableHeading>
                  <TableHeading>Usage Department</TableHeading>
                  <TableHeading>Equipment Assigned</TableHeading>
                  <TableHeading>Vendore Name</TableHeading>
                </TableRow>
              </TableHead>

              <TableBody style={{ overflowY: "scroll" }}>
                {currentData.length > 0 ? (
                  currentData.map((item, index) => (
                    <TableRow
                      key={item.accessid}
                      sx={{
                        "&:hover": {
                          bgcolor: "#EEF5FF",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        <Tooltip title={item.accessname}>
                          {item.accessname}
                        </Tooltip>
                      </TableCells>
                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {item.unit_price.map((price, index) => (
                            <Tooltip
                              key={index}
                              title={`${price} * ${item.quantity[index]}`}
                            >
                              <span>
                                {price} * {item.quantity[index]}
                              </span>
                            </Tooltip>
                          ))}
                        </div>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {item.totunit_price.map((price, index) => (
                            <Tooltip key={index} title={`${price}`}>
                              <span>{price} </span>
                            </Tooltip>
                          ))}
                        </div>
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        {item.tot_cost}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                      >
                        {Array.isArray(item?.purch_invoice) &&
                        item.purch_invoice.length > 0 ? (
                          item.purch_invoice.map((invoiceUrl, index) => (
                            <IconButton
                              key={index}
                              onClick={() => handleDownload(invoiceUrl)}
                            >
                              <ImageIcon style={{ color: "#1B2535" }} />
                            </IconButton>
                          ))
                        ) : (
                          <span>No Invoices</span> // Show message if there are no invoices
                        )}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        {item.tot_used}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        {item.avail_quantity}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        {item.dept_usage.department
                          .map((dept) => dept.depart_name)
                          .join(", ") || "N/A"}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        {item.asset_usage.asset
                          .map((asset) => `${asset.asset_name} - ${asset.used}`)
                          .join(", ")}
                      </TableCells>

                      <TableCells
                        sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                        component={Link}
                      >
                        {item.vendorname.join(", ")}{" "}
                        {/* Vendor names are in an array */}
                      </TableCells>
                    </TableRow>
                  ))
                ) : (
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Not Accessary found.
                    </Typography>
                  </Stack>
                )}
              </TableBody>

              {/* <TableBody style={{ overflowY: "scroll" }}>
                {filteredData.map((item, index) => (
                  <TableRow
                    key={item?.servid}
                    sx={{
                      "&:hover": {
                        bgcolor: "#EEF5FF",
                      },
                      textDecoration: "none",
                    }}
                  >
                    <TableCells
                      sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                      component={Link}
                    >
                      <Tooltip title={item.assetname}>{item.assetname}</Tooltip>
                    </TableCells>

                    <TableCells
                      sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                      component={Link}
                    >
                      <Tooltip title={item.assetcode}>{item.assetcode}</Tooltip>
                    </TableCells>

                    <TableCells
                      sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                      component={Link}
                    >
                      <Tooltip title={item.dept}>{item.dept}</Tooltip>
                    </TableCells>

                    <TableCells
                      sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                      component={Link}
                    >
                      {item.servdate === item?.servtime ? (
                        <>NA</>
                      ) : (
                        <>
                          <>{item?.servdate}</>@<>{item?.servtime}</>
                        </>
                      )}
                    </TableCells>

                    <TableCells
                      sx={{
                        textOverflow: "clip",
                        whiteSpace: "normal",
                        textAlign: "center",
                        display: "ruby-text",
                        color: "rgba(70, 144, 255, 1)",
                      }}
                      component={Link}
                    >
                      {item?.serv_status}
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody> */}

              {/* <TableBody style={{ overflowY: "scroll" }}>
                {filteredData.map((item, index) => (
                  <TableRow
                    key={item?.servid}
                    sx={{
                      "&:hover": {
                        bgcolor: "#EEF5FF",
                      },
                      textDecoration: "none",
                    }}
                  >
                  
                ))}
              </TableBody> */}
            </Table>
          </TableContainer>
          <Divider />
          <Stack
            sx={{
              bgcolor: "white",
              alignItems: "flex-end",

              justifyContent: "column",
            }}
          >
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            ></Pagination>
          </Stack>
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            bgcolor: "white",
            padding: "20px",
            overflow: "hidden",
            overflowY: "scroll",
            width: {
              xs: "auto",
              sm: "auto",
              md: "40%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}
          >
            <Label variant="h6" style={{ color: "#4690FF" }}>
              Add Accessories
            </Label>
            <CloseIcon
              size={15}
              style={{
                color: " rgba(255, 58, 68, 1)",
                cursor: "pointer",
              }}
              onClick={handleCloseBox}
            />
          </Box>
          <Divider />

          <Stack
            sx={{
              width: "100%",
              gap: "20px",
            }}
          >
            <Stack></Stack>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}
              >
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>

              <Stack sx={{ gap: "5px" }}>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <Label
                    sx={{
                      justifyContent: "left",
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Accessories Name{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>
                  <Autocomplete
                    id="accessory-name-autocomplete"
                    freeSolo
                    options={accessoryOptions}
                    value={accessoryName}
                    onChange={(event, newValue) => {
                      setAccessoryName(newValue);
                      setErrors((prev) => ({ ...prev, accessoryName: "" }));

                      if (newValue !== "Other") {
                        // Set accsid when a valid accessory is selected
                        const selectedItem = namelist.find(
                          (item) => item.accessname === newValue
                        );
                        setSelectedAccessoryId(selectedItem?.accessid || "");
                        setOtherAccessory("");
                      } else {
                        setSelectedAccessoryId("");
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setAccessoryName(newInputValue);
                      setErrors((prev) => ({ ...prev, accessoryName: "" }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product Name"
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        error={!!errors.accessoryName}
                        helperText={errors.accessoryName}
                      />
                    )}
                  />

                  {accessoryName === "Other" && (
                    <TextField
                      label="Enter New Product Name"
                      variant="filled"
                      fullWidth
                      value={otherAccessory}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const trimmedValue = newValue.trim(); // Trim spaces for validation

                        // Check for duplicate
                        const isDuplicate = namelist.some(
                          (item) =>
                            item.accessname.toLowerCase() ===
                            trimmedValue.toLowerCase()
                        );

                        if (isDuplicate) {
                          setErrors((prev) => ({
                            ...prev,
                            otherAccessory: "Product already exists",
                          }));
                        } else {
                          setErrors((prev) => ({
                            ...prev,
                            otherAccessory: "",
                          }));
                        }

                        setOtherAccessory(newValue); // Keep original input (with spaces)
                      }}
                      error={!!errors.otherAccessory}
                      helperText={errors.otherAccessory}
                    />
                  )}

                  {errors.accessoryName && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.accessoryName}
                    </FormHelperText>
                  )}
                    {accessoryError && (
    <FormHelperText style={{ color: "red", marginTop: "4px" }}>{accessoryError}</FormHelperText>
  )}
                </Stack>
                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <Label
                    sx={{
                      justifyContent: "left",
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Total Quantity{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>

                  <FormInput
                    id="filled-basic"
                    label="Quantity"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  {errors.quantity && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.quantity}
                    </FormHelperText>
                  )}
                </Stack>

                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <Label
                    sx={{
                      justifyContent: "left",
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Unit Price{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>

                  <FormInput
                    id="filled-basic"
                    label="Unit Price"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={unitPrice}
                    onChange={handleUnitPriceChange}
                  />
                  {errors.unitPrice && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.unitPrice}
                    </FormHelperText>
                  )}
                </Stack>

                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <Label
                    sx={{
                      justifyContent: "left",
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Total Price{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>

                  <FormInput
                    id="filled-basic"
                    label="Total Price"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={totalPrice}
                    disabled
                  />
                  {errors.totalPrice && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.totalPrice}
                    </FormHelperText>
                  )}
                </Stack>

                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <Label
                    sx={{
                      justifyContent: "left",
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    vendor Name{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>

                  <FormInput
                    id="filled-basic"
                    label="Vendor Name"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={vendorName}
                    onChange={(e) => {
                      setVendorName(e.target.value);
                      setErrors((prev) => ({ ...prev, vendorName: "" }));
                    }}
                  />
                  {errors.vendorName && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.vendorName}
                    </FormHelperText>
                  )}
                </Stack>

                <Stack
                  sx={{ justifyContent: "space-between", paddingLeft: "10px" }}
                >
                  <Label
                    sx={{
                      justifyContent: "left",
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Purchased Date{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>
                  <FormInput
                    type="date"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={purchasedDate}
                    onChange={(e) => {
                      setPurchasedDate(e.target.value);
                      setErrors((prev) => ({ ...prev, purchasedDate: "" })); // Clear error when corrected
                    }}
                  />
                  {errors.purchasedDate && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.purchasedDate}
                    </FormHelperText>
                  )}
                </Stack>
              </Stack>

              <Stack
                sx={{
                  marginTop: "10px",
                  paddingLeft: "10px",
                  gap: "10px",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    <Label
                      variant="subtitle1"
                      sx={{ color: "rgba(70, 144, 255, 1)" }}
                    >
                      Upload Invoice{" "}
                      <span style={{ color: "red", fontWeight: "100" }}>*</span>
                    </Label>
                  </Box>
                  <Box>
                    {uploadedImageName?.length < 1 && (
                      <>
                        <>
                          <label for="img">
                            <UploadIcon
                              size={20}
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                cursor: "pointer",
                              }}
                            />
                          </label>
                          <input
                            type="file"
                            name="uploadfile"
                            id="img"
                            style={{
                              display: "none",
                              width: "42px",
                              height: "42px",
                            }}
                            onChange={handlePhotoUpload}
                          />
                        </>
                      </>
                    )}
                  </Box>
                </Stack>
                <Stack sx={{ flexDirection: "row", gap: "10px" }}>
                  {fileUrl?.map((item, index) => (
                    <Badge
                      key={index}
                      badgeContent={
                        <CloseIcon
                          style={{
                            color: "rgba(255, 58, 68, 1)",
                          }}
                          onClick={() => removePhoto(item?.imageid)}
                        />
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        border: "0.5px solid rgba(27, 37, 53, 1)",
                        padding: "5px",
                        width: "max-content",
                      }}
                    >
                      {item?.imageid?.endsWith(".pdf") ? ( // Check if the file is a PDF
                        <Box
                          sx={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "gray",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          PDF
                        </Box>
                      ) : (
                        <Box
                          as="img"
                          src={item?.image}
                          alt=""
                          sx={{ width: "70px", height: "90px" }}
                        />
                      )}
                    </Badge>
                  ))}
                </Stack>

                <Stack
                  sx={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                  {errors.fileUrl && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.fileUrl}
                    </FormHelperText>
                  )}
                </Stack>
              </Stack>
            </Box>
            <Stack
              sx={{
                position: "relative",
                bottom: "30px",
                justifyContent: "space-between",
              }}
            >
              {accessoryName === "Other" ? (
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              ) : (
                <Button variant="contained" onClick={handleUpdate}>
                  Update
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
      {isToastVisible && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            fontSize: "16px",
            zIndex: 1000,
          }}
        >
          {toastMessage}
        </div>
      )}
    </Box>
  );
};

export default AccessoriesList;
