import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  styled,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import { Drawer } from "@mui/joy";
import { auditDetails } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",
  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Stack)(({ theme }) => ({
  width: "100%",

  borderRadius: "8px",

  // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // flexDirection:'column',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerComponent = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const LeftComponent = styled(Box)(({ theme }) => ({
  width: "60%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const RightComponent = styled(Box)(({ theme }) => ({
  width: "40%",
  flexDirection: "row",
  gap: "20px",

  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const renderStars = (rating = 0) => {
    const maxStars = 5;
    const filledStars = Math.min(maxStars, Math.max(0, Math.round(rating))); // Ensure rating is between 0 and maxStars
    const emptyStars = maxStars - filledStars;
  
    return (
      <>
        {[...Array(filledStars)].map((_, index) => (
          <Star key={`filled-${index}`} style={{ color: "#FFD700" }} />
        ))}
        {[...Array(emptyStars)].map((_, index) => (
          <Star key={`empty-${index}`} style={{ color: "#E0E0E0" }} />
        ))}
      </>
    );
  };
  

const AuditDetails = () => {
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const auditdatadetails = useSelector((store) => store.data.auditdetailsdata);

  const { auditId, assetid } = useParams();

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      assetid: assetid,
      auditid: auditId,
    };
    dispatch(auditDetails(data));
  }, [dispatch]);

  const auditData = [
    {
      category: "Operational Status",
      rating: auditdatadetails.operationalrate,
      description: [],
    },
    {
      category: "Maintenance History",
      rating: auditdatadetails.maintenancerate,
      description: [],
    },
    {
      category: "Warranty Status",
      rating: auditdatadetails.warrantyrate,
      description: [],
    },
    {
      category: "Physical Condition",
      rating: auditdatadetails.physicalrate,
      description: [],
    },
    {
      category: "Total Downtime",
      rating: auditdatadetails.downtimerate,
      description: [],
    },
    {
      category: "Usage and Reliability",
      rating: auditdatadetails.usagerate,
      description: [],
    },
  ];

  let operationalrate = parseFloat(auditdatadetails.operationalrate);
  let maintenancerate = parseFloat(auditdatadetails.maintenancerate);
  let warrantyrate = parseFloat(auditdatadetails.warrantyrate);
  let physicalrate = parseFloat(auditdatadetails.physicalrate);
  let downtimerate = parseFloat(auditdatadetails.downtimerate);
  let usagerate = parseFloat(auditdatadetails.usagerate);
  
  let totalStar = operationalrate + maintenancerate + warrantyrate + physicalrate + downtimerate + usagerate;
  
  


  const HospitalLeftDataOpen = {
    "Asset Name": auditdatadetails.name,
    "Asset Code": auditdatadetails.code,
    Modal: auditdatadetails.modelno,
    "Done By": auditdatadetails.done_by,
    "Ack'ment by": auditdatadetails.apprv_by,
  };

  const HospitalRightDataOpen = {
    Department: auditdatadetails.dept,
    Brand: auditdatadetails.brand,
    "Serial No": auditdatadetails.serialno,
    "Total Rating / Total Star": `${auditdatadetails.rating} / ${totalStar}`,
    // DownTime: auditdatadetails.downtimerate,
  };
  

  return (
    <>
      <Wrapper>
        <InnerSet>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "white",
              padding: "10px 20px 10px 20px",
            }}
          >
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              Equipment Audit Details
            </Label>

            {/* <Label sx={{ color: "#FF6B00" }}>{ticketdetails.status}</Label> */}
          </Stack>

          <Stack sx={{ bgcolor: "white", padding: "20px" }}>
            <Stack
              sx={{
                textAlign: "start",

                paddingLeft: "10px",
                marginBottom: "20px",
                width: "100%",
                gap: "10px",
              }}
            >
              <Label variant="subtitle1">Asset Details</Label>
              <Divider />
            </Stack>
            <InnerComponent>
              <LeftComponent sx={{ width: "60%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ maxWidth: "100%" }}>
                    <TableBody>
                      {Object.entries(HospitalLeftDataOpen).map(
                        ([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </LeftComponent>
              <RightComponent sx={{ width: "40%" }}>
                <TableContainer
                  style={{ overflowX: "auto", maxWidth: "100%" }}
                  sx={{}}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableBody>
                      {Object.entries(HospitalRightDataOpen).map(
                        ([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}
                            >
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </RightComponent>
            </InnerComponent>
          </Stack>
        </InnerSet>
      </Wrapper>
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Equipment Audit</strong>
              </TableCell>
              <TableCell  sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <strong>Stars</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <strong>{item.category}</strong>
                  <ul>
                    {item.description.map((desc, idx) => (
                      <li key={idx}>{desc}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"64px"}}>{renderStars(item.rating)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AuditDetails;
