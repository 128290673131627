import React, { useEffect, useState } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import { v4 as uuid } from "uuid";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  addGenerateTicket,
  getDepartmentData,
  recordIncidentImageUpload,
  recordIncidentImageDelete,
  listGenerateTicket,
  clearGenerateTicket,
  generateTicketAcknowledge,
} from "../redux/nbmData/action";

import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "auto",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  "& fieldset": { border: "none" },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const GeneralTicket = () => {
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const [open, setOpen] = useState(false);
  const [clearopen, setclearOpen] = useState(false);
  const dispatch = useDispatch();
  const [assetType, setAssetType] = React.useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const [customIssue, setCustomIssue] = useState("");
  const [filterValue, setFilterValue] = useState(null);
  const [opengenrateticket, setopengenrateticket] = useState(false);
  const [acknowlegmentdata, setacknowlegmentdata] = useState(false);
  const [selectedticketid, setSelectedTicketId] = useState(null);
  const [selectedDepart, setSelectedDepart] = useState(null);

  const allDepartment = useSelector((store) => store.data.department);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const TicketGenerate = useSelector(
    (store) => store.data.addgenerateticketdata
  );
  const generateticketlist = useSelector(
    (store) => store.nbmData.listgenerateticketdata
  );
  const generateticketclose = useSelector(
    (store) => store.data.cleargenerateticketdata
  );

  const [formType, setFormType] = useState(null); // null, 'general', or 'clear'
  const [issueReason, setIssueReason] = useState("");
  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(getDepartmentData(data));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(listGenerateTicket(data));
  }, [dispatch]);

  const handlePhotoUpload = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (!selectedFiles.length) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const allowedSize = 4 * 1024 * 1024; // 4MB limit

    const validFiles = selectedFiles.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        setIsPhotoError(`Can't upload ${file.type.split("/")[1]} file.`);
        return false;
      }
      if (file.size > allowedSize) {
        setIsPhotoError("Can't upload image more than 4MB.");
        return false;
      }
      return true;
    });

    // If no valid files, exit early
    if (validFiles.length === 0) return;

    setIsPhotoError(null); // Reset error message if valid files exist

    validFiles.forEach((file) => {
      const fileType = file.type.split("/")[1];
      const reader = new FileReader();

      reader.onloadend = () => {
        // Ensure exactly 16-character unique image ID
        let uniqueId = uuid().replace(/-/g, "").substring(0, 12); // 12 random characters
        let prefix = "assetId_".substring(0, 4); // Ensure consistency
        let imageid = `${prefix}${uniqueId}.${fileType}`; // 16-character ID with extension

        const sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}`,
          image: reader.result,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess?.departmentids,
        };

        // Update state safely
        setFileUrl((prevFiles) => [...prevFiles, sendData]);

        // Dispatch action for file upload
        dispatch(recordIncidentImageUpload(sendData, token)).catch((error) => {
          console.error("Image upload failed:", error);
        });
      };

      reader.readAsDataURL(file);
    });
  };

  const assets = [
    "Electrical Issues",
    "Plumbing Issues",
    "AC Issue",
    "Elevator Malfunctions",
    "Building Maintenance",
    "Waste Management",
    "Security Systems",
    "Other",
  ];

  const handleDepartmentChange = (event, value) => {
    setSelectedDepartment(value);
  };

  const handleIssueTypeChange = (event, newValue) => {
    if (newValue === "Other") {
      // If "Other" is selected, show input for custom issue
      setSelectedAsset(newValue);
    } else {
      // Otherwise, just select the issue type
      setSelectedAsset(newValue);
      setCustomIssue(""); // Clear custom issue text if it's not "Other"
    }
  };

  const handleOpenBox = () => {
    setOpen(true);
  };

  const handleClear = () => {
    setclearOpen(true);
  };

  const handleClearClose = () => {
    setclearOpen(false);
  };

  const handleCloseBox = () => {
    setFormType(null); // This will hide both forms when the close icon is clicked
  };

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedFiles = Array.from(files).map((file) => ({
      imageid: Math.random().toString(36).substr(2, 9), // Generate a unique ID
      image: URL.createObjectURL(file), // Preview image using Object URL
    }));

    setFileUrl((prev) => [...prev, ...updatedFiles]);
    setIsPhotoError("");
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(recordIncidentImageDelete(data));
    setFileUrl(fileUrl?.filter((item) => item?.imageid !== id));
  };

  const handleSubmit = () => {
    const issue_type = selectedAsset === "Other" ? customIssue : selectedAsset;

    const ticketData = {
      hospid: userData?.hospid,
      hospname: userIds?.hospname,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      username: userIds?.username,
      userid: userIds?.userid,
      userrole: userAccess?.role,
      departname: selectedDepartment,
      departid: allDepartment.find(
        (department) => department.departname === selectedDepartment
      )?.departid,
      issue_type: issue_type,
      issue_reason: issueReason,
      issue_level: assetType,
      issue_images: fileUrl.map((item) => item.imageid),
    };

    dispatch(addGenerateTicket(ticketData))
      .then(() => {
        // Reset input fields
        setSelectedDepartment(""); // Clear department selection
        setSelectedAsset(""); // Clear asset selection
        setCustomIssue(""); // Clear custom issue input
        setIssueReason(""); // Clear reason input
        setAssetType(""); // Reset priority level
        setFileUrl([]); // Clear uploaded images
        handleCloseBox();

        let data = {
          hospid: userData?.hospid,
          userid: userData?.userid,
          branchid: userBranch.id,
          depart_ids: userAccess.departmentids,
          userrole: userAccess?.role,
        };
        dispatch(listGenerateTicket(data));
      })
      .catch((error) => {
        console.error("Error generating ticket:", error);
      });
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? generateticketlist.filter((row) => row.status === filterValue)
      : generateticketlist;
    setData(newFilteredData);
  }, [filterValue, generateticketlist]);

  const isFormValid = () => {
    return (
      selectedDepartment &&
      selectedAsset &&
      (selectedAsset !== "Other" || customIssue) &&
      issueReason.trim() !== "" &&
      assetType &&
      fileUrl.length > 0
    );
  };

  const handleOpenAcknowlegement = () => {
    setacknowlegmentdata(true);
  };

  const handleAcknowledge = () => {
    if (!selectedticketid || !selectedDepart) {
      console.error("No ticket ID or department selected");
      return;
    }

    const payload = {
      hospid: userData?.hospid,
      hospname: userIds?.hospname,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      username: userIds?.username,
      userid: userIds?.userid,
      tktid: selectedticketid,
      userrole: userAccess?.role,
      departname: selectedDepart,
    };

    dispatch(generateTicketAcknowledge(payload))
      .then(() => {
        setacknowlegmentdata(false);
        let data = {
          hospid: userData?.hospid,
          userid: userData?.userid,
          branchid: userBranch.id,
          depart_ids: userAccess.departmentids,
          userrole: userAccess?.role,
        };
        dispatch(listGenerateTicket(data));
      })
      .catch((error) => {
        console.error("Error acknowledging ticket:", error);
      });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        gap="10px"
        padding="16px"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          height: {
            xs: "200vh",
            sm: "200vh",
            md: "90vh",
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap="10px" width="100%">
          <Stack
            justifyContent="space-between"
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
          >
            <Label variant="h6">
              General Ticket.
              <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
            </Label>
            <Button
              onClick={() => setFormType("general")}
              sx={{
                background: "rgba(70, 144, 255, 1)",
                color: "white",
                "&:hover": {
                  background: "rgba(70, 144, 255, 1)",
                  color: "white",
                },
              }}
            >
              Add Ticket <AddIcon />
            </Button>
          </Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px"
            height="auto"
          >
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              <Typography>Total :</Typography>
              <Typography sx={{ color: "#4690FF" }}>
                {/* {` ${data?.length}`} */}
              </Typography>
            </Stack>
            <Box flex="2">
              <SortSearchFilter
                data={data} // Pass the updated data state
                setData={setData} // Pass the state updater function
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                heading={"My Orders"}
                Filter1="depart"
                Filter2="level"
                FilterName1="Department"
                FilterName2="Issut Type"
                sortDate="issuedate"
                dueDate=""
                sortPrice=""
                Grade=""
                name="depart"
                csvName="All Assets"
              />
            </Box>

            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                display: { xs: "none", sm: "none", lg: "flex" },
              }}
            >
              <Button
                onClick={() => setFormType("general")}
                sx={{
                  background: "rgba(70, 144, 255, 1)",
                  color: "white",
                  "&:hover": {
                    background: "rgba(70, 144, 255, 1)",
                    color: "white",
                  },
                }}
              >
                Add Ticket <AddIcon />
              </Button>
            </Stack>
          </Box>

          <Box flex="1" overflow="auto">
            <TableContainer
              component={Paper}
              style={{
                height: "calc(90vh - 200px)",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 237, 237, 1)",
                      },
                    }}
                  >
                    <TableHeading>Ticket ID</TableHeading>
                    <TableHeading>Department</TableHeading>
                    <TableHeading>Issue Type</TableHeading>
                    <TableHeading>Issue Level</TableHeading>
                    <TableHeading>Issue Date</TableHeading>

                    <TableHeading
                      sx={{
                        textAlign: "start",
                      }}
                    >
                      Status
                    </TableHeading>
                    <TableHeading>Ack'ment</TableHeading>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ overflowY: "scroll" }}>
                  {currentData?.length > 0 ? (
                    currentData.map((item, index) => (
                      <TableRow
                        key={item?.tktid} // Fallback to index if `servid` is not defined
                        sx={{
                          "&:hover": {
                            bgcolor: "#EEF5FF",
                          },
                          textDecoration: "none",
                        }}
                      >
                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/General-Ticket-Details/${item.tktid}`}
                          // Assuming a `to` prop for navigation
                        >
                          <Tooltip title={item.tktid}>{item.tktid}</Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/General-Ticket-Details/${item.tktid}`}
                          // Assuming a `to` prop for navigation
                        >
                          <Tooltip title={item.depart}>{item.depart}</Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/General-Ticket-Details/${item.tktid}`}
                        >
                          <Tooltip title={item.issuetype}>
                            {item.issuetype}
                          </Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/General-Ticket-Details/${item.tktid}`}
                        >
                          <Tooltip title={item.level}>
                            {item?.level === "Normal" ? (
                              <Box
                                size="small"
                                sx={{
                                  borderRadius: "0px 8px 0px 8px",
                                  textTransform: "none",
                                  width: "90px",
                                  height: "25px",
                                  bgcolor: "skyblue",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                }}
                                variant="contained"
                              >
                                {item.level}
                              </Box>
                            ) : item?.level === "Urgent" ? (
                              <Box
                                size="small"
                                sx={{
                                  borderRadius: "0px 8px 0px 8px",
                                  textTransform: "none",
                                  width: "90px",
                                  height: "25px",
                                  bgcolor: "purple",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                }}
                                variant="contained"
                              >
                                {item.level}
                              </Box>
                            ) : item?.level === "Critical" ? (
                              <Box
                                size="small"
                                sx={{
                                  borderRadius: "0px 8px 0px 8px",
                                  textTransform: "none",
                                  width: "90px",
                                  height: "25px",
                                  bgcolor: "Red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                }}
                                variant="contained"
                              >
                                {item.level}
                              </Box>
                            ) : null}
                          </Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/General-Ticket-Details/${item.tktid}`}
                        >
                          <Tooltip title={item.issuedate}>
                            {item.issuedate}
                          </Tooltip>
                        </TableCells>

                        <TableCells
                          sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          component={Link}
                          to={`/General-Ticket-Details/${item.tktid}`}
                        >
                          <Tooltip title={item.tkt_status}>
                            {item?.tkt_status !== "Closed" ? (
                              <Box
                                size="small"
                                sx={{
                                  borderRadius: " 0px 8px 0px 8px",
                                  textTransform: "none",
                                  width: "90px",
                                  height: "25px",
                                  bgcolor: "#74ACFF",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                }}
                                variant="contained"
                              >
                                {item.tkt_status}
                              </Box>
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: "#ff731d",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.tkt_status}
                                </span>
                              </>
                            )}
                          </Tooltip>
                        </TableCells>
                        {item.creator_id === userData?.userid && (
                          <TableCells
                            sx={{
                              textOverflow: "clip",
                              whiteSpace: "normal",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "Center",
                            }}
                          >
                            <Tooltip title={item.ack_status}>
                              {item.ack_status === "Pending" ? (
                                <Button
                                  sx={{
                                    borderRadius: "30px",
                                    margin: "auto",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    height: "30px",
                                    padding: "4px",
                                    width: {
                                      xs: "85px",
                                      sm: "65px",
                                      md: "70px",
                                      lg: "100px",
                                    },
                                    bgcolor: "rgba(255, 107, 0, 1)",
                                    border: "1px solid rgba(255, 107, 0, 1)",
                                    color: "white",
                                    "&:hover": {
                                      bgcolor: "rgba(255, 107, 0, 1)",
                                      border: "1px solid rgba(255, 107, 0, 1)",
                                    },
                                  }}
                                  onClick={() => {
                                    setSelectedTicketId(item.tktid);
                                    setSelectedDepart(item.depart);
                                    handleOpenAcknowlegement();
                                  }}
                                >
                                  {item.ack_status}
                                </Button>
                              ) : item.ack_status === "Done" ? (
                                item.ack_status
                              ) : (
                                ""
                              )}
                            </Tooltip>
                          </TableCells>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCells colSpan={8}>No data available</TableCells>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              sx={{
                padding: "20px",
                bgcolor: "white",
                gap: "20px",
              }}
            >
              <Divider />
              <Stack
                sx={{
                  bgcolor: "white",
                  alignItems: "center",

                  justifyContent: "end",
                  flexDirection: "row",
                }}
              >
                <Pagination
                  count={Math.ceil(filteredData.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                ></Pagination>
              </Stack>
            </Stack>
          </Box>
        </Box>

        {formType === "general" && (
          <Box
            sx={{
              bgcolor: "white",
              padding: "20px",
              height: "100%",
              overflow: "hidden",
              overflowY: "scroll",
              width: {
                xs: "auto",
                sm: "auto",
                md: "40%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",
              }}
            >
              <Label variant="h6" style={{ color: "#4690FF" }}>
                General Ticket
              </Label>
              <CloseIcon
                size={15}
                style={{
                  color: " rgba(255, 58, 68, 1)",
                  cursor: "pointer",
                }}
                onClick={handleCloseBox}
              />
            </Box>
            <Divider />

            <Stack
              sx={{
                bgcolor: "white",
                alignItems: "start",
                padding: "15px",

                width: {
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                },
                gap: "10px",
                textAlign: "start",
                justifyContent: "start",

                // height: "100%",
              }}
            >
              <Typography>Details</Typography>
              <Stack
                sx={{
                  width: "100%",
                  background: "rgba(70, 144, 255, 1)",
                  margin: "5px 0",
                }}
              >
                <Divider />
              </Stack>
            </Stack>
            <Stack sx={{ display: "grid", gap: "10px", marginLeft: "8px" }}>
              <Stack sx={{ width: "100%" }}>
                <Autocomplete
                  options={allDepartment.map(
                    (department) => department.departname
                  )}
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Department *"
                      variant="outlined"
                    />
                  )}
                  sx={{
                    width: "100%",
                    background: "rgba(244, 246, 246, 1)",
                  }}
                />
              </Stack>

              <Stack sx={{ width: "100%" }}>
                <Autocomplete
                  options={assets}
                  value={selectedAsset}
                  onChange={handleIssueTypeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type of Issue *"
                      variant="outlined"
                    />
                  )}
                  sx={{
                    width: "100%",
                    background: "rgba(244, 246, 246, 1)",
                  }}
                />

                {selectedAsset === "Other" && (
                  <TextField
                    label="Please specify the issue"
                    variant="outlined"
                    value={customIssue}
                    onChange={(e) => setCustomIssue(e.target.value)}
                    sx={{
                      marginTop: 2,
                      width: "100%",
                      background: "rgba(244, 246, 246, 1)",
                    }}
                  />
                )}
              </Stack>
            </Stack>

            <Stack
              sx={{
                width: "100%",
                gap: "20px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <Stack sx={{ gap: "5px" }}>
                  <Stack
                    sx={{
                      justifyContent: "space-between",
                      paddingLeft: "10px",
                    }}
                  >
                    <Stack>
                      <TextField
                        multiline
                        rows={1}
                        placeholder="Reason *"
                        variant="outlined"
                        value={issueReason}
                        onChange={(e) => setIssueReason(e.target.value)}
                        sx={{
                          width: "auto",
                          background: "rgba(244, 246, 246, 1)",
                          "& fieldset": { border: "none" },
                        }}
                        error={issueReason.trim() === ""}
                        helperText={issueReason.trim() === "" ? "Reason is required" : ""}
                      />
                      <FormHelperText sx={{ color: "red" }}></FormHelperText>
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      height: "125px",
                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                      marginLeft: "8px",
                    }}
                  >
                    <Label>
                      Priority Level{" "}
                      <span style={{ color: "red", fontWeight: "100" }}>*</span>
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleAssetType}
                      value={assetType}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        value="Normal"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Normal
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Urgent"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Urgent
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Critical"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Critical
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Stack>
                </Stack>

                <Stack
                  sx={{
                    marginTop: "5px",
                    paddingLeft: "10px",
                    gap: "10px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "rgba(70, 144, 255, 1)" }}
                  >
                    Upload Image{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Typography>
                  <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
                  <Stack
                    sx={{
                      height: "auto",
                      width: "auto",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "20px",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    {fileUrl.map((item) => (
                      <Badge
                        key={item.imageid}
                        badgeContent={
                          <IconButton
                            size="small"
                            sx={{
                              color: "rgba(255, 58, 68, 1)",
                              cursor: "pointer",
                            }}
                            onClick={() => removePhoto(item?.imageid)}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          component="img"
                          src={item.image}
                          alt="uploaded"
                          sx={{
                            width: "80px",
                            height: "90px",
                            borderRadius: "5px",
                          }}
                        />
                      </Badge>
                    ))}
                  </Stack>

                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "end",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "red" }}>
                      {isPhotoError}
                    </Typography>
                    <label htmlFor="upload-input">
                      <input
                        id="upload-input"
                        type="file"
                        accept="image/*"
                        multiple
                        style={{ display: "none" }}
                        onChange={handlePhotoUpload}
                      />
                      {fileUrl.length < 3 && (
                        <IconButton component="span">
                          <FileUploadIcon />
                        </IconButton>
                      )}
                    </label>
                  </Stack>
                </Stack>
              </Box>
              <Stack
                sx={{
                  position: "relative",
                  bottom: "30px",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  disabled={!isFormValid()}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>

      <Modal
        open={acknowlegmentdata}
        onClose={() => setacknowlegmentdata(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirmation
            </Typography>
            <CloseIcon
              onClick={() => setacknowlegmentdata(false)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to Acknowledge this Ticket?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setacknowlegmentdata(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAcknowledge}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GeneralTicket;
