import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  TablePagination,
  Dialog,
  MenuItem,
  Select,
  Popover,
  OutlinedInput,
  styled,
  useMediaQuery,
  Menu,
} from "@mui/material";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calender,
  CalibrationReq,
  CalibrationTaskCompleted,
  departmentUserDetails,
} from "../redux/data/action";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import CalenderModal from "../components/CalenderModal";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

const headingCellStyles = {
  color: "#1746A2",

  whiteSpace: "nowrap",
};

const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const DueDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const YesDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",
}));

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "400px",
}));


const CalibModal = ({
  calibrationModal,
  setCalibrationModalOpen,
  calibrationModalOpen,
  setCalibrationModal,
  selectedMonth,
  selectedYear,
}) => {
  const userData = useSelector((store) => store.data.departmentUserList);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const initialInputValues = {};
  const initialSelectedUsers = {};
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [inputValues, setInputValues] = useState(initialInputValues);
  const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers);
  const [durationError, setDurationError] = useState("");
  const [selectedUserDetail, setSelectedUserDetail] = useState({});
  const [modalData, setModalData] = useState(calibrationModal);
  const [getId, setGetId] = useState("");
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const [isAnyTaskStatusNotEmpty, setIsAnyTaskStatusNotEmpty] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");

  const [getDetail, setGetDetail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (data) => {
    setGetDetail(data);
  };
  const handleClicks = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (getDetail && getDetail.department_id) {
      let data = {
        adminid: userIds.userid,
        hosp_id: userIds.hospid,
        branchid: userBranch.id,
        departid: getDetail.department_id,
        userrole:userAccess?.role,
        depart_ids:userAccess.departmentids,
      };
      dispatch(departmentUserDetails(data));
    }
  }, [dispatch, getDetail]);

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date && endDate) {
      setError("");
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    if (startDate && date) {
      setError("");
    }
  };
  const handleClick = (event, data) => {
    setGetId(data);
  };

  const handleSubmit = () => {
    setIsPopoverVisible(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleInputChange = (event, asset_id) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/, "").slice(0, 2);

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [asset_id]: numericValue,
    }));

    if (numericValue === "") {
      setDurationError((prevErrors) => ({
        ...prevErrors,
        [asset_id]: "Enter year",
      }));
    } else {
      setDurationError((prevErrors) => ({
        ...prevErrors,
        [asset_id]: "",
      }));
    }

    const updatedModalData = modalData.map((item) => {
      if (item.asset_id === asset_id) {
        return {
          ...item,
          req_status: numericValue !== "" ? "Requested" : "",
        };
      }
      return item;
    });
    setModalData(updatedModalData);
  };

  const handleRenewClick = (asset_id) => {
    setDurationError((prevErrors) => ({
      ...prevErrors,
      [asset_id]: "",
    }));

    const asset = calibrationModal.find((item) => item.asset_id === asset_id);

    const enteredValue = inputValues[asset_id] || "";
    const selectedUser = selectedUsers[asset_id] || {};

    let previousUserDetail = {};

    if (asset.assign_userid) {
      previousUserDetail =
        userData.find((user) => user.userid === asset.assign_userid) || {};
    }

    if (enteredValue === "") {
      setDurationError((prevErrors) => ({
        ...prevErrors,
        [asset_id]: "Enter year",
      }));
      return;
    }

    if (asset && enteredValue !== "") {
      const { userid, username, email, mobile } = selectedUser;

      let data = {
        hospid: ids.hospid,
        userid: ids.userid,
        hospname: ids.hospname,
        hospcity: ids.city,
        assetid: asset?.asset_id,
        assetname: asset?.asset_name,
        brand: asset?.manufacturer,
        depart: asset?.department,
        dop: asset?.date_of_purchase,
        expdate: asset?.calib_due_date,
        req_years: enteredValue,
        branchid: userBranch?.id,
        branchname: userBranch.branch,
        depart_id: asset.department_id,
        req_id:
          selectedUsers[asset_id] === "assignedToYourself"
            ? ids.userid
            : selectedUserDetail && selectedUserDetail.userid
            ? selectedUser.userid
            : previousUserDetail && previousUserDetail.userid
            ? previousUserDetail.userid
            : ids.userid,
        reqname:
          selectedUsers[asset_id] === "assignedToYourself"
            ? ids.username
            : selectedUserDetail && selectedUserDetail.username
            ? selectedUser.username
            : previousUserDetail && previousUserDetail.username
            ? previousUserDetail.username
            : ids.username,
        reqemail:
          selectedUsers[asset_id] === "assignedToYourself"
            ? ids.email
            : selectedUserDetail && selectedUserDetail.email
            ? selectedUser.email
            : previousUserDetail && previousUserDetail.email
            ? previousUserDetail.email
            : ids.email,
        reqphone:
          selectedUsers[asset_id] === "assignedToYourself"
            ? ids.mobile
            : selectedUserDetail && selectedUserDetail.mobile
            ? selectedUser.mobile
            : previousUserDetail && previousUserDetail.mobile
            ? previousUserDetail.mobile
            : ids.mobile,
            userrole:userAccess?.role,
            depart_ids:userAccess.departmentids,
      };

      dispatch(CalibrationReq(data))
        .then(() => {
          const requestData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            branchid: userBranch?.id,
            month:
              selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1,
            year: selectedYear,
            depart_ids: userAccess.departmentids,
            userrole:userAccess?.role,
          };
          dispatch(calender(requestData));

          setSuccessDialogOpen(true);
          previousUserDetail = {};
          setSelectedUserDetail({});
        })
        .catch((error) => {});
    } else {
    }
  };

  const handleClose = () => {
    setCalibrationModalOpen(false);
    setIsAnyTaskStatusNotEmpty(false);
    handleClose2();
  };

  useEffect(() => {
    setInputValues(initialInputValues);
    setSelectedUsers(initialSelectedUsers);
  }, [calibrationModalOpen]);

  const handleClose2 = () => {
    setSuccessDialogOpen(false);
  };

  useEffect(() => {
    setPage(0);
  }, [calibrationModal]);

  const handleSelectChange = (event, asset_id) => {
    const { value } = event.target;

    const selectedUserObject = userData?.find(
      (user) => user.userid === value.userid
    );

    setSelectedUserDetail(selectedUserObject);

    setSelectedUsers((prevUsers) => ({
      ...prevUsers,
      [getDetail.asset_id]: selectedUserObject || value,
    }));

    const updatedModalData = modalData.map((item) => {
      if (item.asset_id === getDetail.asset_id) {
        return {
          ...item,
          req_status: selectedUserObject ? "Requested" : "",
        };
      }
      return item;
    });
    setModalData(updatedModalData);
  };

  calibrationModal.forEach((item) => {
    initialInputValues[item.asset_id] =
      item.req_status === "Requested" ? item.duration : "";
    initialSelectedUsers[item.asset_id] =
      item.req_status === "Requested" ? item.assigned_to : "";
  });

  const handleSubmitDate = () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("DD-MM-YYYY");
      const formattedEndDate = dayjs(endDate).format("DD-MM-YYYY");

      if (dayjs(endDate).isBefore(startDate)) {
        setError("End Date cannot be less than Start Date");

        return;
      }

      setError("");
      let data = {
        hospid: ids.hospid,
        userid: ids.userid,
        hospname: ids.hospname,
        hospcity: ids.city,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        expdate: getId?.calib_due_date,
        assetid: getId?.asset_id,
        username: ids.username,
        branchid: userBranch.id,
        userrole:userAccess?.role,
        depart_ids:userAccess.departmentids,
      };

      dispatch(CalibrationTaskCompleted(data))
        .then(() => {
          const requestData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            branchid: userBranch?.id,
            month:
              selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1,
            year: selectedYear,
            depart_ids: userAccess.departmentids,
            userrole:userAccess?.role,
          };
          dispatch(calender(requestData));
          setStartDate(null);
          setEndDate(null);
          setIsPopoverVisible(false);
        })
        .catch((error) => {});
      handleClose();
    } else {
      setError("Please select both start and End Dates");
    }
  };

  const [day, month, year] =
    getId && getId.calib_due_date.split("-").map(Number);

  const calibrationExpDate = new Date(year, month - 1, day);

  calibrationExpDate.setMonth(calibrationExpDate.getMonth() + 1);

  const newCalibrationExpDate = `${calibrationExpDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${(calibrationExpDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${calibrationExpDate.getFullYear()}`;

  function formatDateForInput(dateString) {
    const parts = dateString.split("-");

    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
  const checkTaskStatus = () => {
    const anyNotEmpty = calibrationModal.some(
      (item) =>
        item.task_status !== "" &&
        item.task_status !== "NA" &&
        item.task_status !== "Completed"
    );
    setIsAnyTaskStatusNotEmpty(anyNotEmpty);
  };

  React.useEffect(() => {
    checkTaskStatus();
  }, [calibrationModal]);
  const [selectedItem, setSelectedItem] = useState("Item 1");
  const [showDropdown, setShowDropdown] = useState(false);

  const dummyData = ["Item 1", "Item 2eeeeeeeeeeeeeeeeeeeeeeeeee", "Item 3"];

  const handleLinkClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownSelect = (item) => {
    setSelectedItem(item);
    setShowDropdown(false);
  };

  return (
    <Dialog
      open={calibrationModalOpen}
      onClose={handleClose}
      maxWidth="lg"
      // fullWidth
      sx={{
        borderRadius: "0px",
        "& .MuiDialog-paper": {
          borderRadius: "0px",
        },
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0px",

          backgroundColor: "#6100FF",
        }}>
        <Typography
          variant={isMobileScreen ? "" : "h4"}
          sx={{
            color: "white",
            backgroundColor: "#6100FF",
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
          }}>
          Calibration
        </Typography>

        {isMobile ? (
          <Typography
            onClick={handleClose}
            variant="body2"
            sx={{
              color: "white",
              backgroundColor: "#6100FF",
              height: "100%",
              cursor: "pointer",
              paddingRight: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <CloseIcon />
          </Typography>
        ) : (
          <Typography
            onClick={handleClose}
            variant="body2"
            sx={{
              color: "white",
              backgroundColor: "#6100FF",
              height: "100%",
              cursor: "pointer",
            }}>
            Close
          </Typography>
        )}
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",

            maxHeight: 540,
          }}>
          <Table sx={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow sx={{ bgcolor: "#EFF5FE" }}>
                <TableCell style={headingCellStyles}>Asset Name</TableCell>
                <TableCell style={headingCellStyles}>Asset Code</TableCell>
                <TableCell style={headingCellStyles}>Department</TableCell>
                {userAccess.role === "Owner" ||
                userAccess.role === "Branch_Admin" ||
                userAccess.role === "Super_Admin" ||
                userAccess.role === "Admin" ? (
                  <TableCell
                    style={headingCellStyles}
                    sx={{ textAlign: "center" }}>
                    Duration
                  </TableCell>
                ) : (
                  <>
                    <TableCell
                      style={headingCellStyles}
                      sx={{ textAlign: "center" }}>
                      Task
                    </TableCell>
                  </>
                )}
                {userAccess.role === "Owner" ||
                userAccess.role === "Branch_Admin" ||
                userAccess.role === "Super_Admin" ||
                userAccess.role === "Admin" ? (
                  <TableCell
                    style={headingCellStyles}
                    sx={{ textAlign: "center" }}>
                    Assign
                  </TableCell>
                ) : (
                  <TableCell style={headingCellStyles}></TableCell>
                )}

                {(userAccess.role === "Owner" ||
                  userAccess.role === "Branch_Admin" ||
                  userAccess.role === "Super_Admin" ||
                  userAccess.role === "Admin") && (
                  <>
                    {isAnyTaskStatusNotEmpty && (
                      <TableCell
                        style={headingCellStyles}
                        sx={{ textAlign: "center" }}>
                        Status
                      </TableCell>
                    )}
                    <TableCell style={headingCellStyles}></TableCell>
                    <TableCell style={headingCellStyles}></TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {calibrationModal

                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component={Link}
                      to={`/asset-detail/${item.asset_id}`}
                      sx={{ textDecoration: "none" }}>
                      {item.asset_name}
                    </TableCell>
                    <TableCell>{item.asset_code}</TableCell>
                    <TableCell>{item.department}</TableCell>

                    {userAccess.role !== "Guest" ? (
                      <>
                        <TableCell>
                          {item.req_status === "NA" ||
                          item.req_status === "" ? (
                            <>
                              <TextField
                                type="number"
                                placeholder="Enter year"
                                size="small"
                                inputProps={{
                                  min: 0,
                                  style: { textAlign: "center" },
                                }}
                                value={inputValues[item.asset_id] || ""}
                                onChange={(event) =>
                                  handleInputChange(event, item.asset_id)
                                }
                                style={{ width: "130px", textAlign: "center" }}
                                helperText={durationError[item.asset_id] || ""}
                              />
                            </>
                          ) : item.req_status === "Requested" ? (
                            <TextField
                              type="number"
                              inputProps={{
                                min: 0,
                                style: { textAlign: "center" },
                              }}
                              placeholder="Enter year"
                              size="small"
                              value={inputValues[item.asset_id] || ""}
                              onChange={(event) => {
                                const numericValue = event.target.value
                                  .replace(/\D/, "")
                                  .slice(0, 2); // Extract first 2 digits
                                handleInputChange(
                                  {
                                    ...event,
                                    target: {
                                      ...event.target,
                                      value: numericValue,
                                    },
                                  },
                                  item.asset_id
                                );
                              }}
                              style={{ width: "130px", textAlign: "center" }}
                            />
                          ) : null}
                        </TableCell>

                        <TableCell key={`button_${item.asset_id}`}>
                          <Button
                            key={`button_${item.asset_id}`}
                            variant="outlined"
                            size="small"
                            onClick={(event) => {
                              handleButtonClick(item);
                              handleClicks(event);
                            }}
                            style={{
                              width: "130px",
                              textDecoration: "underline",
                              border: "none",
                              color: "blue",
                              textTransform: "none",
                            }}>
                            <Typography>
                              {item.assigned_to !== "NA" &&
                              item.task_status !== "Completed" &&
                              selectedUsers[item.asset_id]?.username ===
                                undefined
                                ? item.assigned_to
                                : selectedUsers[item.asset_id]?.username !==
                                  undefined
                                ? selectedUsers[item.asset_id]?.username
                                : "Select User"}
                            </Typography>
                          </Button>
                          {userData && (
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleCloses}>
                              {item.assigned_to === "NA" &&
                              selectedUsers[item.asset_id] ? (
                                <MenuItem disabled>
                                  {selectedUsers[item.asset_id].username}
                                </MenuItem>
                              ) : (
                                <MenuItem disabled></MenuItem>
                              )}

                              {userData?.map((user) => (
                                <MenuItem
                                  key={user.userid}
                                  onClick={() => {
                                    handleSelectChange(
                                      { target: { value: user } },
                                      item.asset_id
                                    );
                                    handleCloses();
                                  }}
                                  disabled={item.assigned_to === user.username}
                                  sx={{
                                    "&:hover": { backgroundColor: "#d0d0d0" },
                                    cursor:
                                      item.assigned_to === user.username
                                        ? "not-allowed"
                                        : "pointer",
                                  }}>
                                  {user.username}
                                </MenuItem>
                              ))}
                            </Menu>
                          )}
                        </TableCell>

                        {isAnyTaskStatusNotEmpty &&
                          calibrationModal.some(
                            (item) => item.task_status !== "Completed"
                          ) && (
                            <TableCell
                              style={{ whiteSpace: "nowrap" }}
                              component={Link}
                              sx={{
                                textAlign: "center",
                                textDecoration: "none",
                              }}>
                              <Box
                                sx={{
                                  borderRadius: "5px",
                                  margin: "auto",
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: {
                                    xl: "18px",
                                    lg: "16px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "10px",
                                  },
                                  height: "30px",
                                  padding: "4px",

                                  border:
                                    item.task_status === "Not Completed"
                                      ? "1px solid #FF4B4B"
                                      : "white",
                                  color:
                                    item.task_status === "Not Completed"
                                      ? " #FF4B4B"
                                      : "white",
                                }}>
                                <Typography> {item.task_status}</Typography>
                              </Box>
                            </TableCell>
                          )}
                        <TableCell>
                          {item.req_status === "NA" ||
                          (item.req_status === "Requested" &&
                            (item.assigned_to !==
                              selectedUsers[item.asset_id] ||
                              item.duration !== inputValues[item.asset_id])) ? (
                            <Button
                              onClick={() => handleRenewClick(item.asset_id)}
                              variant="contained"
                              sx={{
                                bgcolor: "#FF4B4B",
                                textTransform: "none",
                              }}>
                              Request
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "none",
                                width: "90px",
                                bgcolor: "#00A884",
                              }}>
                              Requested
                            </Button>
                          )}
                        </TableCell>

                        <TableCell>
                          {userIds.username === item.assigned_to ? (
                            <>
                              {item.task_status === "Not Completed" ||
                              (item.req_status === "Requested" &&
                                (item.assigned_to !==
                                  selectedUsers[item.asset_id] ||
                                  item.duration !==
                                    inputValues[item.asset_id])) ? (
                                <div>
                                  <Button
                                    onClick={(event) => {
                                      setIsPopoverVisible(true);
                                      setPopoverAnchorEl(event.currentTarget);
                                      handleClick(event, item);
                                    }}
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#1746A2",
                                      textTransform: "none",
                                      width: "90px",
                                    }}>
                                    Complete
                                  </Button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          {" "}
                          {userIds.username === item.assigned_to ? (
                            <>
                              {" "}
                              <Typography sx={{ color: "#00A884" }}>
                                {" "}
                                Assigned to you
                              </Typography>
                            </>
                          ) : (
                            <>
                              {" "}
                              <Typography sx={{ color: "red" }}>
                                Not assigned{" "}
                              </Typography>
                            </>
                          )}
                        </TableCell>

                        <TableCell>
                          {" "}
                          {userIds.username === item.assigned_to ? (
                            <>
                              {item.task_status === "Not Completed" ||
                              (item.req_status === "Requested" &&
                                (item.assigned_to !==
                                  selectedUsers[item.asset_id] ||
                                  item.duration !==
                                    inputValues[item.asset_id])) ? (
                                <div>
                                  <Button
                                    onClick={(event) => {
                                      setIsPopoverVisible(true);
                                      setPopoverAnchorEl(event.currentTarget);
                                      handleClick(event, item);
                                    }}
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#FF4B4B",
                                      textTransform: "none",
                                      width: "90px",
                                    }}>
                                    Complete
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "none",
                                    width: "90px",
                                    bgcolor: "#00A884",
                                  }}>
                                  Completed
                                </Button>
                              )}
                            </>
                          ) : (
                            <>
                              <Typography></Typography>{" "}
                            </>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {calibrationModal?.length > rowsPerPage && (
          <TablePagination
            sx={{
              bgcolor: "white",
              borderRadius: "  0px 0px 25px 25px",
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={calibrationModal.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
      <CalenderModal
        open={successDialogOpen}
        onClose={handleClose2}
        setInputValues={setInputValues}
        initialInputValues={initialInputValues}
        setSelectedUsers={setSelectedUsers}
        initialSelectedUsers={initialSelectedUsers}
        data={calibrationModal}
        modalClose={handleClose}
      />
      <Popover
        open={isPopoverVisible}
        anchorEl={popoverAnchorEl}
        onClose={() => setIsPopoverVisible(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          borderRadius: "3px 0px 13px 13px",
        }}>
        <div
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "380px", // Increase the width here
            height: "175px",
            borderRadius: "3px 0px 13px 13px",
            background: "white",
          }}>
          <Typography
            variant="caption"
            sx={{ marginBottom: "2%", fontWeight: 700, color: "#1746A2" }}>
            Please enter warranty Start Date and End Date.
          </Typography>
          <YesDateBox>
            <DateBox padding="5px" bgcolor="#EFF5FE">
              <CustomText fontWeight="600"> Start Date</CustomText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                  <DatePicker
                    format="DD/MM/YYYY"
                    sx={{ bgcolor: "white" }}
                    minDate={getId && dayjs(getId.calib_due_date, "DD-MM-YYYY")}
                    slotProps={{
                      textField: {
                        error: false,
                        size: "small",
                      },
                    }}
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
              </LocalizationProvider>
            </DateBox>

            <DueDateBox>
              <DateBox padding="5px" bgcolor="#EFF5FE">
                <CustomText fontWeight="600"> End Date</CustomText>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>
                    <DatePicker
                      format="DD/MM/YYYY"
                      minDate={
                        getId && dayjs(newCalibrationExpDate, "DD-MM-YYYY")
                      }
                      value={endDate}
                      sx={{ bgcolor: "white" }}
                      slotProps={{
                        textField: {
                          error: false,
                          size: "small",
                        },
                      }}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </LocalizationProvider>
              </DateBox>
            </DueDateBox>
          </YesDateBox>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#1746A2",
              textTransform: "none",
              borderRadius: "7px",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            size="small"
            onClick={handleSubmitDate}>
            Submit
          </Button>
        </div>
      </Popover>
    </Dialog>
  );
};

export default CalibModal;
