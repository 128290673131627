import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import CloseIcon from "@mui/icons-material/Close";
import {
  assetAuditLists,
  equipmentAudit,
  assetAuditIgnore,
  assetAuditApproveList,
  assetAuditIgnoreList,
  auditUploadImage,
  auditDeleteImage,
} from "../redux/data/action";
import { useParams } from "react-router-dom";
import jsQR from "jsqr";
import QRScanner from "react-qr-scanner";
import { v4 as uuid } from "uuid";

import {
  IconButton,
  FormControl,
  Stack,
  Typography,
  styled,
  Tooltip,
  Checkbox,
  Button,
  Modal,
  Divider,
  Rating,
  Tab,
  Tabs,
  Grid,
  Badge,
  FormHelperText,
} from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  whiteSpace: "nowrap",
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const AddAssetAudit = () => {
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [ratings, setRatings] = useState(Array(8).fill(0));
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [item, setitem] = useState([]);
  const videoRef = useRef(null);
  const [scannedData, setScannedData] = useState(null);
  const AssetAuditlist = useSelector((store) => store.data.assetauditlistdata);
  const AssetAuditIgnore = useSelector(
    (store) => store.data.assetauditignoredata
  );
  const auditapprovelist = useSelector(
    (store) => store.data.assetauditApproveListdata
  );
  const auditIgnoreList = useSelector(
    (store) => store.data.assetauditignorelistdata
  );

  const { auditId, departid } = useParams();
  useEffect(() => {
    if (auditId && departid) {
      const data = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch.id,
        auditid: auditId,
        departid: departid,
        userrole: userAccess?.role,
      };
      dispatch(assetAuditLists(data));
    }
  }, [dispatch]);

  const [filterValue, setFilterValue] = useState("");
  const [dateError, setDateError] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const handleSelectAllClick = () => {
    const allSelectableAssetIds = data
      ?.filter(
        (item) => item.check_in_status !== "checked_out" && item.rating <= 0
      )
      .map((item) => item.assetid);

    if (selectAll) {
      setSelectedAssetIds([]);
    } else {
      setSelectedAssetIds(allSelectableAssetIds);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedAssetIds, assetId]; // Add to selection
    } else {
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1), // Remove from selection
      ];
    }

    setSelectedAssetIds(newSelected); // Update the state
  };

  const itemsPerPage = 10;
  const itemsPerPage1 = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfLastItem1 = currentPage1 * itemsPerPage1;

  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const currentData1 = filteredData1.slice(indexOfFirstItem1, indexOfLastItem1);

  const tableRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const [audit1Open, setAudit1Open] = useState(false);
  const [audit2Open, setAudit2Open] = useState(false);
  const [ignore1Open, setIgnore1Open] = useState(false);
  const [ignore2Open, setIgnore2Open] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const handleClose = (setter) => () => setter(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [filteredAsset, setFilteredAsset] = React.useState(null);

  const handleRatingChange = (page, value) => {
    const updatedRatings = [...ratings];
    updatedRatings[page - 1] = value;
    setRatings(updatedRatings);
  };

  const nextPage = () => {
    if (currentPage < 9) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? AssetAuditlist.filter((row) => row.status === filterValue)
      : AssetAuditlist;
    setData(newFilteredData);
  }, [filterValue, AssetAuditlist]);

  useEffect(() => {
    const newFilteredData = filterValue
      ? auditIgnoreList.filter((row) => row.status === filterValue)
      : auditIgnoreList;
    setData1(newFilteredData); // Update data1 directly
  }, [filterValue, auditIgnoreList]);

  const handleScan = (data) => {
    if (data) {
      // Ensure data is available and clean inputs
      const scannedId = data.text ? data.text.trim() : "";
      const matchedAsset = AssetAuditlist.find(
        (item) => item.assetid.trim() === scannedId
      );

      if (matchedAsset) {
        setCurrentPage(2); // Navigate to Page 2
        setErrorMessage(""); // Clear any previous error message
        setFilteredAsset(matchedAsset); // Save the matched asset for display
      } else {
        setErrorMessage("Scanned asset does not match any in the list!"); // Set the error message
      }
    }
  };

  const handleError = (err) => {
    console.error("QR Scan Error:", err);
  };

  const calculateTotalRating = () => {
    return ratings.reduce((acc, curr) => acc + curr, 0);
  };

  const calculateAllTotalCount = () => {
    return (calculateTotalRating() / 6).toFixed(2);
  };

  const handleSubmit = async () => {
    const selectedAssets = AssetAuditlist.filter((asset) =>
      selectedAssetIds.includes(asset.assetid)
    );

    const assetDetails = selectedAssets[0] || {};

    const data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
      hospname: ids?.hospname,
      branchname: userBranch?.branch,
      username: ids?.username,
      auditid: auditId,
      assetid: assetDetails.assetid,
      assetname: assetDetails.asset,
      serialno: assetDetails.serial,
      assetcode: assetDetails.code,
      modelno: assetDetails.model,
      department: assetDetails.depart,
      departid: assetDetails.departid,
      brand: assetDetails.brand,
      operat_rating: ratings[2] || "0",
      maint_rating: ratings[3] || "0",
      warnt_rating: ratings[4] || "0",
      phyc_rating: ratings[5] || "0",
      downtime_rating: ratings[6] || "0",
      usage_rating: ratings[7] || "0",
      avg_rating: calculateAllTotalCount(),
      prodimgs: fileUrl.map((file) => file.imageid),
    };

    await dispatch(equipmentAudit(data));

    if (auditId && departid) {
      const secondData = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch.id,
        auditid: auditId,
        departid: departid,
        userrole: userAccess?.role,
      };
      dispatch(assetAuditLists(secondData));
    }

    setSelectedAssetIds([]);
    setRatings(Array(7).fill(0));
    setCurrentPage(1);
    handleCloseModal(false);
    setAudit2Open(true);
  };

  const handleOpenAuditModal = () => {
    if (selectedAssetIds.length > 1) {
      setAudit1Open(true);
    } else if (selectedAssetIds.length === 1) {
      setOpenModal(true);
    }
  };

  const handleCloseAudit1Modal = () => {
    setAudit1Open(false);
  };

  const handleOpenIgnoreClick = () => {
    setIgnore2Open(true);
  };

  const handleIgnoreClick = () => {
    if (selectedAssetIds.length > 0) {
      const ignorePromises = selectedAssetIds.map((assetId) => {
        const asset = AssetAuditlist.find((item) => item.assetid === assetId);
        const payload = {
          hospid: ids?.hospid,
          branchid: userBranch?.id,
          auditid: auditId,
          assetid: asset.assetid,
          assetname: asset.asset,
          serialno: asset.serial,
          assetcode: asset.code,
          modelno: asset.model,
          depart: asset.depart,
          departid: asset.departid,
          brand: asset.brand,
          username: ids?.username,
          userid: ids?.userid,
          hospname: ids?.hospname,
          branchname: userBranch?.branch,
          depart_ids: userAccess?.departmentids,
        };

        return dispatch(assetAuditIgnore(payload));
      });

      Promise.all(ignorePromises)
        .then(() => {
          if (auditId && departid) {
            const secondData = {
              hospid: ids?.hospid,
              userid: ids?.userid,
              branchid: userBranch.id,
              auditid: auditId,
              departid: departid,
              userrole: userAccess?.role,
            };
            dispatch(assetAuditLists(secondData));
          }
          setIgnore2Open(false);
        })
        .catch((error) => {
          console.error("Error during asset audit ignore:", error);
        });
    } else {
      console.log("No assets selected or found.");
    }

    // Clear selected assets and open the ignore modal
    setSelectedAssetIds([]);
  };

  const handleauditlist = () => {
    if (auditId && departid) {
      const data = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch.id,
        auditid: auditId,
        departid: departid,
        userrole: userAccess?.role,
      };
      dispatch(assetAuditLists(data));
    }
  };

  const handleIgnorelist = () => {
    if (auditId && departid) {
      const data = {
        hospid: ids?.hospid,
        userid: ids?.userid,
        branchid: userBranch?.id,
        auditid: auditId,
        departid: departid,
        userrole: userAccess?.role,
      };
      dispatch(assetAuditIgnoreList(data)); // Dispatch the action for ignore list
    }
  };
  const [fileUrl, setFileUrl] = useState([]);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const [isPhotoError, setIsPhotoError] = useState("");

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsPhotoError(`Can't upload image more than 4mb.`);
        // compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: `${"assetId_"}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
            userrole: userAccess?.role,
            depart_ids: userAccess.departmentids,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(auditUploadImage(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };
  const handleDelete = (id) => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      imageid: id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(auditDeleteImage(data));
    setFileUrl(fileUrl?.filter((el) => el?.imageid !== id));
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        gap="10px"
        height="90vh"
        padding="16px"
        marginTop="-15px"
      >
        <Box
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Box>
            <Button
              onClick={() => {
                setActiveTab(0); // Set active tab to Audit
                handleauditlist(); // Call audit list handler
              }}
              sx={{
                borderRadius: "50px",
                backgroundColor: activeTab === 0 ? "#1B2535" : "transparent",
                color: activeTab === 0 ? "white" : "#1B2535",
                width: {
                  xs: "150px",
                  sm: "150px",
                  md: "200px",
                },
                border: "1px solid #1B2535",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
              }}
            >
              Audit Asset
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => {
                setActiveTab(1); // Set active tab to Ignore
                handleIgnorelist(); // Call ignore list handler
              }}
              sx={{
                borderRadius: "50px",
                backgroundColor: activeTab === 1 ? "#1B2535" : "transparent",
                color: activeTab === 1 ? "white" : "#1B2535",
                width: {
                  xs: "150px",
                  sm: "150px",
                  md: "200px",
                },
                border: "1px solid #1B2535",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
              }}
            >
              Ignore Asset
            </Button>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap="10px">
          <Stack
            justifyContent="space-between"
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ display: { xs: "flex", sm: "none", lg: "none" } }}
          >
            <Label variant="h6">
              Select Assets.
              {/* <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span> */}
            </Label>
          </Stack>

          {activeTab === 0 ? (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                gap="10px"
              >
                <Stack
                  flexDirection={"row"}
                  height="50px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding="16px"
                  gap={"10px"}
                  sx={{
                    bgcolor: "white",
                    boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                    display: { xs: "none", sm: "none", lg: "flex" },
                  }}
                >
                  <Typography>{`Total : ${data?.length}`}</Typography>
                </Stack>
                <Box flex="2" width={"250px"}>
                  <SortSearchFilter
                    data={data}
                    setData={setData}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    heading={"My Orders"}
                    Filter1="depart"
                    Filter2=""
                    FilterName1="Department"
                    FilterName2=""
                    sortDate=""
                    Grade=""
                    sortPrice=""
                    dueDate=""
                    name="asset"
                    csvName=""
                  />
                </Box>
              </Box>
              <Box flex="1" overflow="auto">
                <TableContainer
                  component={Paper}
                  style={{
                    height: "calc(80vh - 200px)",
                    boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                    maxHeight: "500px",
                    overflowY: "auto",
                  }}
                  ref={tableRef}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeading>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {selectedAssetIds.length > 0 &&
                              selectedAssetIds.length}
                            <Checkbox
                              color="primary"
                              indeterminate={
                                selectedAssetIds.length > 0 &&
                                selectedAssetIds.length <
                                  filteredData?.filter(
                                    (item) =>
                                      item.check_in_status !== "checked_out"
                                  ).length
                              }
                              checked={
                                selectedAssetIds.length ===
                                filteredData?.filter(
                                  (item) =>
                                    item.check_in_status !== "checked_out"
                                ).length
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all assets",
                              }}
                            />
                          </div>
                        </TableHeading>
                        <TableHeading>Asset Name</TableHeading>
                        <TableHeading>Asset Code</TableHeading>
                        <TableHeading>Department</TableHeading>
                        <TableHeading>Brand</TableHeading>
                        <TableHeading>Modal No</TableHeading>
                        <TableHeading>Serial No</TableHeading>
                        <TableHeading>Rating</TableHeading>
                        <TableHeading>Audited Date</TableHeading>
                        <TableHeading>Status</TableHeading>
                        <TableHeading></TableHeading>
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ overflowY: "scroll" }}>
                      {currentData.map((item) => (
                        <TableRow key={item.assetid}>
                          <TableCells>
                            <Checkbox
                              checked={selectedAssetIds.includes(item.assetid)}
                              disabled={
                                item.check_in_status === "checked_out" ||
                                item.rating > 0
                              }
                              onChange={() =>
                                handleSelectRowClick(item.assetid)
                              }
                              onClick={(event) => {
                                if (
                                  item.check_in_status === "checked_out" ||
                                  item.rating > 0
                                ) {
                                  event.stopPropagation();
                                  showModal(
                                    item.check_in_status === "checked_out"
                                      ? `The asset ${item.assetid} is not available in your hospital.`
                                      : `The asset ${item.assetid} cannot be selected as its rating is greater than 0.`
                                  );
                                }
                              }}
                            />
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.asset}>{item.asset}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.code}>{item.code}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.depart}>{item.depart}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.brand}>{item.brand}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.model}>{item.model}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.serial}>{item.serial}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.rating}>{item.rating}</Tooltip>
                          </TableCells>

                          <TableCells>{item.aud_date}</TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={item.rating > 0 ? Link : "span"} // Render Link only if rating > 0
                            to={
                              item.rating > 0
                                ? `/Audit-Details/${auditId}/${item.assetid}`
                                : "#"
                            }
                          >
                            <Tooltip title={item.apprv_status}>
                              {item.apprv_status}
                            </Tooltip>
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack
                  sx={{
                    padding: "20px",
                    bgcolor: "white",
                    gap: "20px",
                  }}
                >
                  <Divider />
                  <Stack
                    sx={{
                      bgcolor: "white",
                      alignItems: "center",

                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      gap="10px"
                    >
                      <Button
                        variant="contained"
                        onClick={handleOpenAuditModal}
                        disabled={
                          selectedAssetIds.length === 0 ||
                          selectedAssetIds.length > 1 ||
                          userAccess?.role === "Guest" ||
                          userAccess?.role === "Admin"
                        }
                        style={{
                          display:
                            selectedAssetIds.length > 1
                              ? "none"
                              : "inline-block",
                        }}
                      >
                        Audit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleOpenIgnoreClick()}
                        disabled={
                          selectedAssetIds.length === 0 ||
                          selectedAssetIds.length > 1 ||
                          userAccess?.role === "Guest" ||
                          userAccess?.role === "Admin"
                        }
                        style={{
                          display:
                            AssetAuditlist.length === 1
                              ? "none"
                              : "inline-block",
                        }}
                      >
                        Ignore
                      </Button>
                    </Box>
                    <Pagination
                      count={Math.ceil(filteredData.length / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    ></Pagination>
                  </Stack>
                </Stack>
              </Box>
            </>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                gap="10px"
              >
                <Stack
                  flexDirection={"row"}
                  height="50px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding="16px"
                  gap={"10px"}
                  sx={{
                    bgcolor: "white",
                    boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                    display: { xs: "none", sm: "none", lg: "flex" },
                  }}
                >
                  <Typography>{`Total : ${data1?.length}`}</Typography>
                </Stack>
                <Box flex="2" width={"250px"}>
                  <SortSearchFilter
                    data={data1}
                    setData={setData1}
                    filteredData={filteredData1}
                    setFilteredData={setFilteredData1}
                    heading={"My Orders"}
                    Filter1="depart"
                    Filter2=""
                    FilterName1="Department"
                    FilterName2=""
                    sortDate=""
                    Grade=""
                    sortPrice=""
                    dueDate=""
                    name="asset"
                    csvName="All Assets"
                  />
                </Box>
              </Box>
              <Box flex="1" overflow="auto">
                {/* Table */}
                <TableContainer
                  component={Paper}
                  style={{
                    height: "calc(80vh - 200px)",
                    boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                    maxHeight: "500px",
                    overflowY: "auto",
                  }}
                  ref={tableRef}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeading>Asset Name</TableHeading>
                        <TableHeading>Asset Code</TableHeading>
                        <TableHeading>Department</TableHeading>
                        <TableHeading>Brand</TableHeading>
                        <TableHeading>Modal No</TableHeading>
                        <TableHeading>Serial No</TableHeading>
                        <TableHeading>Ignored By</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading></TableHeading>
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ overflowY: "scroll" }}>
                      {currentData1.map((item) => (
                        <TableRow key={item.assetid}>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                            component={Link}
                            to={`/asset-detail/${item.assetid}`}
                          >
                            <Tooltip title={item.asset}>{item.asset}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.code}>{item.code}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.depart}>{item.depart}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.brand}>{item.brand}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.model}>{item.model}</Tooltip>
                          </TableCells>

                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.serial}>{item.serial}</Tooltip>
                          </TableCells>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.ign_by}>{item.ign_by}</Tooltip>
                          </TableCells>
                          <TableCells
                            sx={{ textOverflow: "clip", whiteSpace: "normal" }}
                          >
                            <Tooltip title={item.ign_date}>
                              {item.ign_date}
                            </Tooltip>
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack
                  sx={{
                    padding: "20px",
                    bgcolor: "white",
                    gap: "20px",
                  }}
                >
                  <Divider />
                  <Stack
                    sx={{
                      bgcolor: "white",
                      alignItems: "center",

                      justifyContent: "end",
                      flexDirection: "row",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(filteredData1.length / itemsPerPage)}
                      page={currentPage1}
                      onChange={handlePageChange}
                      color="primary"
                    ></Pagination>
                  </Stack>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            width: {
              xs:"auto",
              sm:"auto",
              md:600,
            },
            margin: "auto",
            mt: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Typography variant="h6">Equipment Audit</Typography>

            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "relative",
                top: 10,
                right: 10,
                color: "red",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Pagination */}
          <Typography variant="h6">{`Page ${currentPage} of 9`}</Typography>

          <Box sx={{ mt: 3 }}>
            {currentPage === 1 && (
              <>
                <Box sx={{ bgcolor: "white", padding: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "250px",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <QRScanner
                        delay={300}
                        style={{ width: "100%", height: "100%" }}
                        onScan={handleScan}
                        onError={handleError}
                      />
                    </div>
                    <Typography color="primary">
                      Scan Asset QR code here.
                    </Typography>
                    {errorMessage && (
                      <Box
                        sx={{
                          color: "red",
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        {errorMessage}
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}

            {currentPage === 2 && filteredAsset && (
              <>
                <Stack>
                  <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                    Asset Details:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Asset Name : </strong> {filteredAsset.asset}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Code : </strong> {filteredAsset.code}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Model : </strong>
                        {filteredAsset.model || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Brand : </strong>
                        {filteredAsset.brand || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <strong>Department : </strong>
                        {filteredAsset.depart}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "50%",
                      marginTop: "10px",
                    }}
                  >
                    <Box>
                      <Label
                        variant="subtitle1"
                        sx={{ color: "rgba(70, 144, 255, 1)" }}
                      >
                        Upload Asset Image (Recent)
                      </Label>
                    </Box>
                    <Box>
                      {uploadedImageName?.length < 3 && (
                        <>
                          <label htmlFor="img">
                            <FileUploadIcon
                              size={20}
                              style={{
                                cursor: "pointer",
                                color: "rgba(70, 144, 255, 1)",
                                height: "100%",
                                display: "flex",
                                alignItems: "end",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="uploadfile"
                            id="img"
                            style={{
                              display: "none",
                              width: "42px",
                              height: "42px",
                            }}
                            onChange={handlePhotoUpload}
                          />
                        </>
                      )}
                    </Box>
                  </Stack>

                  {fileUrl?.length > 0 && (
                    <>
                      <Stack
                        sx={{
                          height: "100px",
                          width: "auto",
                          flexDirection: "row",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        {fileUrl?.map((item, index) => (
                          <Badge
                            key={index}
                            badgeContent={
                              <CloseIcon
                                size={15}
                                style={{
                                  color: "rgba(255, 58, 68, 1)",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(item?.imageid)}
                              />
                            }
                            sx={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-start",
                              marginTop: "10px",
                              border: "0.5px solid rgba(27, 37, 53, 1)",
                            }}
                          >
                            <Box
                              as="img"
                              src={item?.image}
                              alt=""
                              sx={{ width: "80px", height: "90px" }}
                            />
                          </Badge>
                        ))}
                      </Stack>
                    </>
                  )}
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "end",
                      justifyContent: "space-between",
                    }}
                  >
                    <HelperText sx={{ color: "red" }}>
                      {isPhotoError}
                    </HelperText>
                  </Stack>
                </Stack>
              </>
            )}

            {currentPage === 3 && (
              <>
                <Typography>Operational Status:</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  <Typography variant="body2">
                    1: Non-functional, requiring immediate repair or
                    replacement.
                  </Typography>
                  <Typography variant="body2">
                    2: Frequently malfunctioning, unreliable.
                  </Typography>
                  <Typography variant="body2">
                    3: Occasionally malfunctioning, generally reliable with
                    minor issues.
                  </Typography>
                  <Typography variant="body2">
                    4: Fully functional, rarely has issues.
                  </Typography>
                  <Typography variant="body2">
                    5: Excellent condition, never malfunctions.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Rating
                    value={ratings[currentPage - 1]}
                    onChange={(e, newValue) => {
                      handleRatingChange(currentPage, newValue);
                    }}
                    max={5}
                    precision={1}
                  />
                </Box>
              </>
            )}

            {currentPage === 4 && (
              <>
                <Typography>Maintenance History:</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  <Typography variant="body2">
                    1: No maintenance performed, significant overdue tasks.
                  </Typography>
                  <Typography variant="body2">
                    2: Irregular maintenance, major overdue tasks.
                  </Typography>
                  <Typography variant="body2">
                    3: Some maintenance performed, minor overdue tasks.
                  </Typography>
                  <Typography variant="body2">
                    4: Regular maintenance, all tasks up to date.
                  </Typography>
                  <Typography variant="body2">
                    5: Meticulous maintenance, proactive upkeep and no overdue
                    tasks.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Rating
                    value={ratings[currentPage - 1]}
                    onChange={(e, newValue) => {
                      handleRatingChange(currentPage, newValue);
                    }}
                    max={5}
                    precision={1}
                  />
                </Box>
              </>
            )}

            {currentPage === 5 && (
              <>
                <Typography>Warranty & AMC/CMC Status</Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Rating
                    value={ratings[currentPage - 1]}
                    onChange={(e, newValue) => {
                      handleRatingChange(currentPage, newValue);
                    }}
                    max={5}
                    precision={1}
                  />
                </Box>
              </>
            )}

            {currentPage === 6 && (
              <>
                <Typography>Physical Condition:</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  <Typography variant="body2">
                    1: Significant wear and tear, broken parts.
                  </Typography>
                  <Typography variant="body2">
                    2: Noticeable wear and tear, some damage affecting use.
                  </Typography>
                  <Typography variant="body2">
                    3: Moderate wear and tear, but fully usable.
                  </Typography>
                  <Typography variant="body2">
                    4: Minor wear and tear, almost like new.
                  </Typography>
                  <Typography variant="body2">
                    5: Excellent physical condition, no signs of wear and tear.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Rating
                    value={ratings[currentPage - 1]}
                    onChange={(e, newValue) => {
                      handleRatingChange(currentPage, newValue);
                    }}
                    max={5}
                    precision={1}
                  />
                </Box>
              </>
            )}

            {currentPage === 7 && (
              <>
                <Typography>Total Downtime:</Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Rating
                    value={ratings[currentPage - 1]}
                    onChange={(e, newValue) => {
                      handleRatingChange(currentPage, newValue);
                    }}
                    max={5}
                    precision={1}
                  />
                </Box>
              </>
            )}

            {currentPage === 8 && (
              <>
                <Typography>Usage and Reliability:</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  <Typography variant="body2">
                    1: Rarely used due to frequent issues.
                  </Typography>
                  <Typography variant="body2">
                    2: Used with frequent reliability concerns.
                  </Typography>
                  <Typography variant="body2">
                    3: Moderately used, generally reliable.
                  </Typography>
                  <Typography variant="body2">
                    4: Regularly used, consistently reliable.
                  </Typography>
                  <Typography variant="body2">
                    5: Heavily used, highly reliable without issues.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Rating
                    value={ratings[currentPage - 1]}
                    onChange={(e, newValue) => {
                      handleRatingChange(currentPage, newValue);
                    }}
                    max={5}
                    precision={1}
                  />
                </Box>
              </>
            )}

            {currentPage === 9 && (
              <>
                <Typography>Final Page: Review and Submit</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Total Selected Stars: {calculateTotalRating()}
                  </Typography>
                  <Typography>
                    Total Star Rating: {calculateAllTotalCount()}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            {currentPage !== 1 && currentPage !== 2 && (
              <Button
                variant="outlined"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Back
              </Button>
            )}
            {currentPage !== 1 && (
              <>
                {currentPage === 9 ? (
                  <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={nextPage}
                    disabled={
                      (ratings[currentPage - 1] === 0 && currentPage !== 2) ||
                      fileUrl.length === 0
                    }
                  >
                    Next
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Box>
      </Modal>

      <Modal open={audit2Open} onClose={handleClose(setAudit2Open)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">
            Successfully Asset have been Audited
          </Typography>
          <Button
            variant="contained"
            onClick={handleClose(setAudit2Open)}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      <Modal open={ignore2Open} onClose={() => setIgnore2Open(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Confirmation
            </Typography>
            <CloseIcon
              onClick={() => setIgnore2Open(false)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to ignore the selected assets?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIgnore2Open(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleIgnoreClick}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddAssetAudit;
