import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Stack, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const style = {
  bgcolor: "background.paper",
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "330px",
}));

export default function RegisterModal({ handleClose, status,open }) {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin");
    window.location.reload();
    handleClose();
  };
  return (
    <div style={{}}>
      <Modal
        keepMounted
        open={open && status === true} // or just open={open && status}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}>
            {/* <IconButton onClick={handleClose}>
              <IoClose />
            </IconButton> */}
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
            }}>
            <IconButton style={{ color: "rgba(0, 201, 32, 1)" }}>
              <DoneIcon size={60} />
            </IconButton>
            <Typography variant="h6" style={{ fontWeight: 700 }}>
              Registration Successful!
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Your account will be activated after verification. For any
              <br />
              query, Call or WhatsApp +91-89715-80265
            </Typography>
            <Stack sx={{ width: "90%" }}>
              <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
            </Stack>

            <Button
              size="small"
              variant="contained"
              onClick={handleClick}
              sx={{
                borderRadius: "35px",

                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}>
              Done
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </div>
  );
}
