import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
} from "@mui/material";
import { Calculate, GetApp, Close } from "@mui/icons-material";
import VajraLogo from "../images/White_Vajra.png";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Chart from "chart.js/auto";
import noteicon from "../images/notesicon.png"
import detailsicon from "../images/detailsicon.png"
import graphicon from "../images/graphicon.png"
import calendericon from "../images/calendericon.png"


import {
  assetDetailsData,
  departmentAssetDetails,
  getDepartmentData,
    profile,
} from "../redux/data/action";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const DepreciationCalculator = () => {
  const departmentData = useSelector((store) => store.data.department) || [];
    let profileData = useSelector((store) => store.data.profile);
  
  const [showDetail, setShowDetail] = useState(false);
  const departAssetData = useSelector(
    (store) => store.data.departmentAssetDetails
  );
  const detailData = useSelector((store) => store.data.assetDetails);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [usefulLife, setUsefulLife] = useState("");
  const [modalno, setModalNo] = useState("");
  const [serialno, setSerialNo] = useState("");
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState("");
  const [isUsefulLifeError, setIsUsefulLifeError] = useState("");
  const [yearlyData, setYearlyData] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [data, setData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");

  const [toggle, setToggle] = useState(false);

  const filteredDepartments = departmentData.filter(
    (department) => parseInt(department.totalassets) > 0
  );

  const location = useLocation();
  const { selectedAssets: initialSelectedAssets } = location.state || {};

  const [selectedAssets, setSelectedAssets] = useState(initialSelectedAssets || []);

    useEffect(() => {
      let data = {
        userid: userIds.userid,
        hospid: userIds.hospid,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };
  
      dispatch(profile(data));
    }, [dispatch]);

  const handleUsefulLife = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;

    if (value === "") {
      setIsUsefulLifeError("Please fill the field");
      setUsefulLife(value);
      setShow(false);
      setYearlyData([]);
      return;
    }

    if (!regex.test(value)) {
      setIsUsefulLifeError("Enter valid number");
      return;
    }

    if (parseInt(value, 10) > 80) {
      setIsUsefulLifeError("Value should not exceed 80");
      return;
    }

    setUsefulLife(value);
    setIsUsefulLifeError("");
    setShow(false);
    setYearlyData([]);
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(detailData)?.length > 0) {
      setData(detailData);
    }
  }, [detailData]);

  const handleDepartment = (e, item) => {
    if (item !== null && item !== undefined) {
      setSelectedId(item.id);
      setDepartment(item.name);
      setShow(false);
      setShowDetail(false);
      setYearlyData([]);
      setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
        userrole: userAccess?.role,
      };
      dispatch(getDepartmentData(data, token));
      setData({});
    }
  };
  // console.log("data", data);

  const handleAssetName = (e, item) => {
    if (!item) {
      setPrice(null);
      setUsefulLife(null);
      setModalNo(null);
      setSerialNo(null);
      setSelectedDate(null);
    }
    if (item !== null && item !== undefined) {
      setAssetId(item.value);
      setToggle(true);
      setAsset(item.name);
      setAssetValue(item.label);
      setShowDetail(true);
    }
    if (item === null && item === undefined) {
      setShow(false);
    }
  };
  // console.log("assetId", assetId);
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: selectedId,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch, selectedId]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`; // Convert to YYYY-MM-DD
  };

  useEffect(() => {
    setModalNo(detailData?.modelno);
    setSerialNo(detailData?.serialno);
    setUsefulLife(detailData?.lifeyrs);
    setPrice(detailData?.price);
    setSelectedDate(formatDate(detailData?.dop));
  }, [detailData]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: assetId,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(assetDetailsData(data, token));
    setToggle(false);
  }, [dispatch, assetId, toggle]);

  let print = {
    name: detailData.name,
    dop: selectedDate,
    price: price,
    modalno: modalno,
    serialno: serialno,
    usefulLife: usefulLife,
    department: detailData.dept,
    yearlyData: yearlyData,
  };

  const downloadPDF = (asset, selectedAssets, selectedDate) => {
    const pdf = new jsPDF();
  
    // Function to add footer
    const addFooter = (doc) => {
      const footerY = doc.internal.pageSize.height - 7; // Adjusted position from bottom
      const textX = doc.internal.pageSize.width - 53; // Right-aligned text
  
      doc.setTextColor(100, 100, 100);
      doc.setFontSize(10);
      doc.text("Powered by :", textX, footerY, "left");
  
      if (VajraLogo) {
        const imgWidth = 20;
        const imgHeight = 12;
        const imgX = doc.internal.pageSize.width - 30;
        const imgY = footerY - 7;
        doc.addImage(VajraLogo, "PNG", imgX, imgY, imgWidth, imgHeight);
      }
    };
  
    // Background color
    pdf.setFillColor(255, 251, 244);
    pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, "F");
  
    // Title
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(16);
    pdf.text("Calculated Depreciation Expense", pdf.internal.pageSize.width / 2, 18, "center");
  
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(14, 79, 205);
    pdf.line(pdf.internal.pageSize.width / 2 - 90, 25, pdf.internal.pageSize.width / 2 + 90, 25);
  
    // Function to format date
    const formatDate = (dateString) => {
      if (!dateString) return "N/A"; // Handle empty values
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "N/A"; // Handle invalid dates
      return date.toLocaleDateString("en-GB"); // Formats as dd/mm/yyyy
    };
  
    // Ensuring `selectedAssets` is defined
    const fallbackAsset = selectedAssets?.length ? selectedAssets[0] : {};
    console.log("POD",fallbackAsset.productdate)

    const parseDMYDate = (dateStr) => {
      if (!dateStr) return null;
      const [day, month, year] = dateStr.split("-");
      return new Date(`${year}-${month}-${day}`);
    };
  
    // Asset details with safe fallbacks
    const assetName = asset?.name || fallbackAsset?.name || "N/A";
    const assetDOP = selectedDate
    ? formatDate(selectedDate)
    : formatDate(parseDMYDate(fallbackAsset.productdate));    const assetModalNo = asset?.modalno || fallbackAsset?.modalno || "N/A";
    const assetSerialNo = asset?.serialno || fallbackAsset?.serialno || "N/A";
    const assetPrice = asset?.price || fallbackAsset?.price || "N/A";
    const assetUsefulLife = asset?.usefulLife || fallbackAsset?.userlife || "N/A";
    const assetDepartment = asset?.department || fallbackAsset?.department || "N/A";
  
    // Printing asset details in PDF
    const fields = [
      ["Asset Name:", assetName],
      ["Date of Purchase:", assetDOP],
      ["Model No:", assetModalNo],
      ["Serial No:", assetSerialNo],
      ["Price:", assetPrice],
      ["Useful Life:", `${assetUsefulLife} years`],
      ["Department:", assetDepartment],
    ];
  
    let yPos = 50;
    fields.forEach(([label, value]) => {
      pdf.setTextColor(14, 79, 205);
      pdf.setFontSize(12);
      pdf.text(label, 20, yPos);
      pdf.setTextColor(0, 0, 0);
      pdf.text(value, 70, yPos);
      yPos += 10;
    });
  
    // Depreciation Title
    pdf.setTextColor(14, 79, 215);
    pdf.setFontSize(16);
    pdf.text("Depreciation", pdf.internal.pageSize.width / 2, yPos + 12, "center");
  
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(pdf.internal.pageSize.width / 2 - 30, yPos + 17, pdf.internal.pageSize.width / 2 + 30, yPos + 17);
  
    // Check if `asset.yearlyData` exists and is an array
    const depreciationData = asset?.yearlyData?.map((item) => [
      `Depreciation Expense for Year ${item.year}`,
      `Expense: ${item.depreciationExpense}`,
    ]) || [];
  
    // Adding table if depreciation data exists
    if (depreciationData.length > 0) {
      pdf.autoTable({
        body: depreciationData,
        startY: yPos + 25,
        didDrawPage: () => addFooter(pdf),
      });
    } else {
      pdf.setTextColor(255, 0, 0);
      pdf.text("No depreciation data available.", 20, yPos + 30);
    }
  
    // Save PDF
    pdf.save("Depreciation.pdf");
  };

  const handleClear = () => {
    setSelectedId("");
    setDepartment("");
    setAssetId("");
    setAsset("");
    setAssetValue("");
    setPrice("");
    setUsefulLife("");
    setYearlyData([]);
    setData({});
    setSelectedAssets([]);
  };

  useEffect(() => {
    // Cleanup function to reset all fields when the component unmounts
    return () => {
      handleClear();
    };
  }, []);

  const calculateDepreciationAndExpense = () => {
    let assetPrice =
      price && price !== "NA" && price !== "N/A"
        ? price
        : selectedAssets[0]?.price;
    let assetUsefulLife =
      usefulLife && usefulLife !== "NA" && usefulLife !== "N/A"
        ? usefulLife
        : selectedAssets[0]?.userlife;

    if (
      !assetPrice ||
      assetPrice === "NA" ||
      assetPrice === "N/A" ||
      isNaN(assetPrice) ||
      assetPrice <= 0
    ) {
      setIsPriceError("Enter a valid price");
      return;
    } else {
      setIsPriceError("");
    }

    if (
      !assetUsefulLife ||
      assetUsefulLife === "NA" ||
      assetUsefulLife === "N/A" ||
      isNaN(assetUsefulLife) ||
      assetUsefulLife <= 0
    ) {
      setIsUsefulLifeError("Enter a valid useful life year");
      return;
    } else if (assetUsefulLife > 80) {
      setIsUsefulLifeError("Useful life should not exceed 80 years");
      return;
    } else {
      setIsUsefulLifeError("");
    }

    let usefulLifeCount = parseInt(assetUsefulLife, 10);
    let initialPrice = parseFloat(assetPrice);
    const depreciationPercentage = (2 / usefulLifeCount) * 100;
    let remainingPrice = initialPrice;

    const data = [];
    for (let year = 1; year <= usefulLifeCount; year++) {
      const depreciationExpense = Math.floor(
        (depreciationPercentage / 100) * remainingPrice
      );
      const percentage = ((depreciationExpense / initialPrice) * 100).toFixed(
        2
      );

      data.push({ year, depreciationExpense, percentage });
      remainingPrice -= depreciationExpense;
    }

    setYearlyData(data);
    setShow(true);
  };
  useEffect(() => {
    if (selectedAssets?.length) {
      setUsefulLife(selectedAssets[0]?.userlife || "");
      setPrice(selectedAssets[0]?.price || "");
      setSelectedDate(selectedAssets[0]?.productdate || "");
    } else {
      setUsefulLife("");
      setPrice("");
      setSelectedDate("");
    }
  }, [selectedAssets]);

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}
    >
      <Stack
        sx={{
          width: {
            xs: "-webkit-fill-available",
            sm: "auto",
            md: "40%",
            lg: "40%",
            xl: "40%",
          },

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}
        >
          <Label variant="h6" color="primary">
            Calculate depreciation expense
          </Label>
          <IconButton>
            <Calculate color="primary" />
          </IconButton>
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}
          >
            <Label variant="h6">Depreciation / year</Label>
            <Box sx={{ marginBottom: "10px" }}>
              {yearlyData?.length > 0 && show && (
                <IconButton
                onClick={() => downloadPDF(print, selectedAssets, selectedDate)}
                  style={{ color: "rgba(70, 144, 255, 1)" }}
                >
                  <DownloadIcon />
                </IconButton>
              )}

              <IconButton
                style={{ color: "rgba(70, 144, 255, 1)" }}
                onClick={handleClear}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <Stack
            flexDirection={"row"}
            gap="10px"
            alignItems={"center"}
            sx={{
              width: "100%",
              overflowY: "auto",

              padding: "5px 0",
              display: "flex",
              overflowX: "scroll",
              height: "70px",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {yearlyData?.map((item) => (
              <Box
                key={item.year}
                sx={{
                  minWidth: "100px",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body2">Year {item.year}</Typography>
                <Label variant="body2" sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  {item.percentage}%
                </Label>
                <Label variant="body2" sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  ₹ {item?.depreciationExpense?.toLocaleString("en-IN")}
                </Label>
              </Box>
            ))}
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
              gap: "20px",
              marginTop: "10px",
            }}
          >
            <Autocomplete
              value={department || selectedAssets?.[0]?.department || ""}
              onChange={handleDepartment}
              placeholder="e.g. ICU Department"
              options={filteredDepartments?.map((option) => ({
                label: `${option?.departname}`,
                name: option?.departname,
                id: option?.departid,
              }))}
              renderInput={(params) => (
                <Field {...params} placeholder="Select Department *" />
              )}
              sx={{
                width: "100%",
                marginRight: "16px",
                "& fieldset": { border: "none" },
              }}
              disabled={!!selectedAssets?.length}
            />
            <Autocomplete
              value={
                assetValue ||
                (selectedAssets?.length
                  ? `${selectedAssets[0]?.name} (${selectedAssets[0]?.assetid})`
                  : "")
              }
              onChange={handleAssetName}
              placeholder="e.g. Ventilator"
              options={departAssetData?.map((option) => ({
                label: `${option?.name} (${option?.assetcode})`,
                value: option?.assetid,
                name: option?.name,
              }))}
              renderInput={(params) => (
                <Field {...params} placeholder="Select Asset *" />
              )}
              sx={{
                width: "100%",
                "& fieldset": { border: "none" },
              }}
              disabled={!!selectedAssets?.length}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
              gap: "20px",
              marginTop: "16px",
            }}
          >
            <Stack sx={{ flexShrink: 1, width: "100%", textAlign: "start" }}>
              <Field
                value={selectedAssets?.[0]?.modalno || modalno || ""}
                placeholder="Modal No"
                variant="outlined"
                sx={{ width: "100%" }}
                disabled
              />
            </Stack>
            <Stack sx={{ flexShrink: 1, width: "100%", textAlign: "start" }}>
              <Field
                value={selectedAssets?.[0]?.serialno || serialno || ""}
                placeholder="Serial No"
                variant="outlined"
                sx={{ width: "100%" }}
                disabled
              />
            </Stack>
          </Box>
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },
                gap: "20px",
                marginTop: "16px",
              }}
            >
              <Box sx={{ flexGrow: 1, marginRight: "16px", width: "100%" }}>
                <TextField
                  label="Select Date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)} // Editable
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="date"
                />
              </Box>
              <Stack sx={{ flexShrink: 1, width: "100%", textAlign: "start" }}>
                <Field
                  value={price}
                  onChange={(e) => setPrice(e.target.value)} // Editable
                  placeholder="Asset Price *"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <Typography variant="caption" sx={{ color: "red" }}>
                  {isPriceError}
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },
                gap: "20px",
                marginTop: "16px",
              }}
            >
              <Stack
                sx={{
                  flexGrow: 1,
                  marginRight: "16px",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                <Field
                  value={usefulLife}
                  placeholder="Useful Life *"
                  onChange={(e) => setUsefulLife(e.target.value)} // Allow editing
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <Typography variant="caption" sx={{ color: "red" }}>
                  {isUsefulLifeError}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={calculateDepreciationAndExpense}
                  variant="contained"
                  disabled={
                    usefulLife === "" ||
                    usefulLife === "NA" ||
                    usefulLife === "N/A" ||
                    price === "" ||
                    price === "NA" ||
                    price === "N/A" ||
                    (!assetValue && !selectedAssets?.[0]?.name)
                  }
                  sx={{
                    width: "154px",
                    borderRadius: "22px",
                    bgcolor: "rgba(70, 144, 255, 1)",
                  }}
                >
                  Calculate
                </Button>
              </Stack>
            </Box>
          </>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DepreciationCalculator;
