import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
import pdfImage from "../images/pdfImage.png";
import { useDispatch } from "react-redux";
import {
  CreateQRCode,
  addAsset,
  deleteImage,
  getDepartmentData,
  uploadImage,
  uploadQrCodeImage,
  uploadedCertificate,
  deleteAMCCMCCertificate,
  uploadedAMCCMCCertificate,
} from "../redux/data/action";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddReport from "./AddReport";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import SuccessPopUp from "../components/SuccessPopUp";
import AddAssetModal from "../components/AddAssetModal";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const BlueStepper = styled(Stepper)(({ theme }) => ({
  width: "90%",
  ".MuiStepConnector-active": {
    "& > .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
      width: "700px",
    },
  },
  "& .MuiStepConnector-line": {
    borderLeftWidth: "558px",
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",

  height: "80px",
  "& fieldset": {
    border: "none",
  },
}));

const BottomElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
}));

const HalfInput2 = styled(DatePicker)(({ theme }) => ({
  width: { xs: "100%", sm: "100%", md: "80%", lg: "60%", xl: "50%" },
  padding: 0,
  background: "white",
}));
const CheckBoxContainer = styled(Box)(({ theme }) => ({
  minHeight: "50px",
}));

const FormRadio = styled(FormControl)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  bgcolor: "red",
}));

const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
}));

const DueDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const YesDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",
}));

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "400px",
  color: "rgba(166, 166, 166, 1)",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const OuterSet = styled(Box)(({ theme }) => ({
  width: "800px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "96%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SplitContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",

  justifyContent: "space-between",
  alignItems: "start",
  gap: "30px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const InnerSplitContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "15px",

  height: "100%",
  textAlign: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "98%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const OuterBox = styled(Box)(({ theme }) => ({
  marginTop: 2,
  width: "90%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AddAssetPage = () => {
  const [assetId, setAssetId] = useState("");

  let idSplit = assetId.substring(0, 4);
  const [toggle, setToggle] = useState(false);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userIds?.hospname) {
      let hospnamePrefix = userIds.hospname.slice(0, 4).toUpperCase();
      let centerText = "mp";
      let randomDigitsLength = 18 - (hospnamePrefix.length + centerText.length);
      let randomDigits = Array.from({ length: randomDigitsLength }, () =>
        Math.floor(Math.random() * 10)
      ).join("");
      const generatedId = hospnamePrefix + centerText + randomDigits;
      setAssetId(generatedId);
    } else {
      console.error("Hospital name is missing in user data");
    }
  }, [userIds?.hospname]);

  const token = localStorage.getItem("userToken");
  const [locale, setLocale] = React.useState("en-gb");
  const qrCode = useSelector((store) => store.data.createQrCode) || [];
  const [assetReport, setAssetReport] = useState([]);
  const [calibrationStartDate, setCalibrationStartDate] = useState(null);
  const [calibrationEndDate, setCalibrationEndDate] = useState(null);
  const handleAssetReport = (data) => {
    setAssetReport(data);
  };

  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const departmentData = useSelector((store) => store.data.department) || [];
  const allAssets = useSelector((store) => store.data.allAssets);
  const status = useSelector((store) => store.data.addAsset);
  const [department, setDepartment] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [certificateFlag, setCertificateFlag] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [name, setName] = useState([]);

  const [assetName, setAssetName] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [brand, setBrand] = useState("");
  const [usefullLife, setUsefullLife] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [price, setPrice] = useState("");
  const [assetType, setAssetType] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [assetGrade, setAssetGrade] = useState("");
  const [calibrationStatus, setCalibrationStatus] = useState("");
  const [cmcAmc, setCmcAmc] = useState("");
  const [cmcAmcStartDate, setCmcAmcStartDate] = useState(null);
  const [cmcAmcEndDate, setCmcAmcEndDate] = useState(null);
  const [calibrationDate, setCalibrationDate] = useState("");
  const [required, setRequired] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [notes, setNotes] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [isSerialNumberError, setIsSerialNumberError] = useState(null);
  const [isUsefullLifeError, setIsUsefullLifeError] = useState(null);
  const [isModelNumberError, setIsModelNumberError] = useState(null);
  const [isBrandError, setIsBrandError] = useState(null);
  const [isSoldByError, setIsSoldByError] = useState(null);
  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isPriceError, setIsPriceError] = useState(null);
  const [error, setError] = useState("");

  const [warrantyStartDate, setWarrantyStartDate] = useState(null);
  const [warrantyEndDate, setWarrantyEndDate] = useState(null);

  const [phone, setPhone] = useState("");

  const [isPhoneError, setIsPhoneError] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);

  const [isDueDateError, setIsDueDateError] = useState(null);
  const [isAssetCodeError, setIsAssetCodeError] = useState(null);

  const [isAmcEndDateError, setIsAmcEndDateError] = useState(null);
  const [isCmcEndDateError, setIsCmcEndDateError] = useState(null);

  const [isAssetTypeError, setIsAssetTypeError] = useState(null);
  const [isAssetStatusError, setIsAssetStatusError] = useState(null);
  const [isAssetGradeError, setIsAssetGradeError] = useState(null);
  const [isCalibrationStatusError, setIsCalibrationStatusError] =
    useState(null);
  const [isCmcAmcError, setIsCmcAmcError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);
  const [isRequiredError, setIsRequiredError] = useState(null);
  const [isDepartmentError, setIsDepartmentError] = useState(null);
  const [isDateOfPurchaseError, setIsDateOfPurchaseError] = useState(null);
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [isCertificateError, setIsCertificateError] = useState(null);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const uploadedCertificateName = certificateUrl?.map((el) => el?.imageid);
  ///////////////////////////////////////////////////////////////////////////
  const [isCostCertificateError, setIsCostCertificateError] = useState(null);
  const [isCMCCostCertificateError, setIsCMCCostCertificateError] =
    useState(null);
  const [cost, setCost] = useState("");
  const [costImageUrl, setCostImageUrl] = useState([]);
  const [cmcCostImageUrl, setCmcCostImageUrl] = useState([]);
  const [cmcCost, setCmcCost] = useState("");
  const [isCostError, setIsCostError] = useState(null);
  const [isCMCCostError, setIsCMCCostError] = useState(null);
  const uploadedCostCertificateName = costImageUrl?.map((el) => el?.imageid);
  const uploadedCMCCostCertificateName = cmcCostImageUrl?.map(
    (el) => el?.imageid
  );
  const [toastMessage, setToastMessage] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);

  /////////////////////////////////////////////////////////////////////////

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];
  
    Array.from(e.target.files).forEach((file) => {
      const fileType = file.type.split("/")[1]; 
      const allowedTypes = ["jpeg", "jpg", "png"]; 
      const allowedSize = 4 * 1024 * 1024;
  
      if (!allowedTypes.includes(fileType)) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return;
      }
  
      if (file.size > allowedSize) {
        setIsPhotoError(`Can't upload image more than 4MB.`);
        return;
      }
  
      setIsPhotoError(null);
      const reader = new FileReader();
  
      reader.onloadend = () => {
        let uuidShort = uuid().replace(/-/g, "").substring(0, 8); 
        let imageid = `${idSplit}_${uuidShort}.${fileType}`; 
        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}`,
          image: reader.result,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
  
        setFileUrl([...fileUrl, sendData]);
        dispatch(uploadImage(sendData, token));
      };
  
      reader.readAsDataURL(file);
    });
  };
  

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}_` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}_` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
                userrole: userAccess?.role,
                depart_ids: userAccess.departmentids,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(uploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(deleteImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  const handleCertificateUpload = (e) => {
    const splitId = assetId.substring(0, 4); // First 4 characters of assetId
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1]; // Extract subtype (jpeg, png, pdf)
      const allowedTypes = ["jpeg", "jpg", "png", "pdf"];
  
      const allowedSize = fileType === "pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;
  
      if (!allowedTypes.includes(fileType)) {
        setIsCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }
  
      if (file.size > allowedSize) {
        setIsCertificateError(
          fileType === "pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return false;
      }
  
      setIsCertificateError(null);
      const reader = new FileReader();
  
      reader.onloadend = () => {
        let uuidShort = uuid().replace(/-/g, "").substring(0, 16);
        let imageid = `${splitId}_${uuidShort}`.substring(0, 16); // Ensure exactly 16 chars
  
        let checkData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}.${fileType}`, // Include the file extension
          image: reader.result,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };
  
        setCertificateUrl((prevUrls) => [...prevUrls, checkData]);
        dispatch(uploadedCertificate(checkData, token));
      };
  
      reader.readAsDataURL(file);
      return true;
    });
  };
  

  const compressImageCertificate = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}_` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let checkData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}_` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
                userrole: userAccess?.role,
                depart_ids: userAccess.departmentids,
              };
              setCertificateUrl([...certificateUrl, checkData]);
              dispatch(uploadedCertificate(checkData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removeCertificate = (id) => {
    setCertificateUrl(certificateUrl?.filter((item) => item?.imageid !== id));
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
      userrole: userAccess?.role,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  const handleAssetName = (e) => {
    const value = e.target.value;
    const trimmedValue = value.trim();
    const regex = /^[a-zA-Z0-9\s-]+$/;

    // Check if the input starts with a space
    if (value.startsWith(" ")) {
      setIsAssetError("Asset name cannot start with a space");
      setAssetName("");
    } else if (!value) {
      setIsAssetError("Please fill the field");
      setAssetName("");
    } else if (!regex.test(value)) {
      setIsAssetError("Wrong asset name");
      setAssetName("");
    } else {
      setAssetName(value);
      setIsAssetError(null);
    }
  };

  const handleAssetCode = (e) => {
    let value = e.target.value;

    if (value.includes("'")) {
      value = value.replace(/'/g, "");
    }

    const lowerCaseValue = value.trim().toLowerCase();

    if (value.trim() === "") {
      setAssetCode("");
      setIsAssetCodeError("Please fill the field");
      return;
    }

    const isDuplicate = allAssets.some(
      (asset) => asset.assetcode.toLowerCase() === lowerCaseValue
    );

    setAssetCode(value);

    if (isDuplicate) {
      setIsAssetCodeError("This asset code already exists.");
    } else {
      setIsAssetCodeError(null);
    }
  };

  const handleSerialNumber = (e) => {
    const value = e.target.value;
    const trimmedValue = value.trim();
    const regex = /^[A-Za-z0-9\s-]+$/;

    if (value.startsWith(" ")) {
      setIsSerialNumberError("Serial number cannot start with a space");
      setSerialNumber("");
    } else if (!value) {
      setIsSerialNumberError("Please fill the field");
      setSerialNumber("");
    } else if (!regex.test(value)) {
      setIsSerialNumberError("Wrong serial number");
      setSerialNumber("");
    } else {
      setSerialNumber(value);
      setIsSerialNumberError(null);
    }
  };

  const handleModelNumber = (e) => {
    const value = e.target.value;
    const trimmedValue = value.trim();
    const regex = /^[A-Za-z0-9\s-]+$/;

    if (value.startsWith(" ")) {
      setIsModelNumberError("Model number cannot start with a space");
      setModelNumber("");
    } else if (!value) {
      setIsModelNumberError("Please fill the field");
      setModelNumber("");
    } else if (!regex.test(value)) {
      setIsModelNumberError("Wrong model number");
      setModelNumber("");
    } else {
      setModelNumber(value);
      setIsModelNumberError(null);
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value;
    const trimmedValue = value.trim();
    const regex = /^[A-Za-z0-9\s/-]+$/;

    if (value.startsWith(" ")) {
      setIsBrandError("Brand name cannot start with a space");
      setBrand("");
    } else if (!value) {
      setIsBrandError("Please fill the field");
      setBrand("");
    } else if (!regex.test(value)) {
      setIsBrandError("Wrong brand name");
      setBrand("");
    } else {
      setBrand(value);
      setIsBrandError(null);
    }
  };

  const handleSoldBy = (e) => {
    const value = e.target.value;

    const trimmedValue = value.trim();
    const regex = /^[A-Za-z\s]+$/;

    if (value === "") {
      setSoldBy(value);
      setIsSoldByError(null);
      setIsPhoneError(null);
      setIsEmailError(null);
      setEmail("");
      setPhone("");
      return;
    }

    if (regex.test(trimmedValue)) {
      setSoldBy(value);
      setIsSoldByError(null);

      if (phone === "") {
        setIsPhoneError("Please fill the field");
      }
      if (email === "") {
        setIsEmailError("Please fill the field");
      }
    } else {
      setIsSoldByError("Field cannot be only spaces");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    setEmail(value);

    if (value === "") {
      setIsEmailError("Please fill the field");
    } else if (!regex.test(value)) {
      setIsEmailError("Enter valid email");
    } else {
      setIsEmailError(null);
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,10}$/;

    if (regex.test(value)) {
      setPhone(value);
      setIsPhoneError(null);
    }

    if (value !== "" && !regex.test(value)) {
      setIsPhoneError("Enter a valid 10-digit number");
    }

    if (value === "") {
      setIsPhoneError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]+$/;
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  const handleUsefulLife = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d+$/;

    if (value === "" || regex.test(value)) {
      const numericValue = Number(value);
      if (numericValue > 80) {
        setIsUsefullLifeError("Useful life cannot exceed 80");
      } else {
        setUsefullLife(value);
        setIsUsefullLifeError(null);
      }
    } else {
      setIsUsefullLifeError("Enter a valid Useful life value");
    }
  };

  const calibrationValidateDateRange = (startDate, endDate) => {
    const start = startDate ? dayjs(startDate) : null;
    const end = endDate ? dayjs(endDate) : null;

    if (start && end) {
      if (start.isSame(end)) {
        setIsCalibrationDateError("Start and End Dates cannot be the same");
      } else if (start.isAfter(end)) {
        setIsCalibrationDateError("Invalid date range");
      } else {
        setIsCalibrationDateError(null);
      }
    } else {
      setIsCalibrationDateError("Please select valid dates");
    }
  };

  const handleCalibrationStartDate = (date) => {
    const formattedDate =
      date && date.isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
    setCalibrationStartDate(formattedDate);
    calibrationValidateDateRange(formattedDate, calibrationEndDate);
  };

  const handleCalibrationEndDate = (date) => {
    const formattedDate =
      date && date.isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
    setCalibrationEndDate(formattedDate);
    calibrationValidateDateRange(calibrationStartDate, formattedDate);
  };

  const handleWarrantyStartDate = (date) => {
    if (date && dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setWarrantyStartDate(formattedDate);
      warrantyValidDateRange(formattedDate, warrantyEndDate);
    }
    if (date && !warrantyEndDate) {
      setIsWarrantyError("Please fill End Date");
    }
  };

  const handleWarrantyEndDate = (date) => {
    if (date && dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setWarrantyEndDate(formattedDate);
      warrantyValidDateRange(warrantyStartDate, formattedDate);
    }
    if (date && !warrantyStartDate) {
      setIsWarrantyError("Please fill Start Date");
    }
  };

  const warrantyValidDateRange = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);

      if (start.isAfter(end)) {
        setIsWarrantyError("Invalid date range");
        return false;
      }

      if (start.isSame(end)) {
        setIsWarrantyError("Start and End Dates cannot be the same");
        return false;
      }

      setIsWarrantyError(null);
      return true;
    }

    return true;
  };

  const handleNotes = (e) => {
    const value = e.target.value;

    if (value.length > 1000) {
      setError("Maximum character limit of 1000 exceeded.");
      return;
    }

    if (value.includes("'")) {
      setError("Single quote (') is not allowed.");
      setNotes(value.replace(/'/g, ""));
    } else {
      setError("");
      setNotes(value);
    }
  };

  const handleDepartment = (event, newValue) => {
    setDepartment(newValue);
    if (assetId) {
      dispatch(CreateQRCode(assetId));
    } else {
      console.error("Asset ID is not available");
    }
    const selectedDepartment = departmentData?.find(
      (item) => item?.departname === newValue
    );

    if (selectedDepartment) {
      setDepartmentId(selectedDepartment?.departid);
      setIsDepartmentError(null);
    } else {
      setDepartmentId("");
    }
  };

  const handleAmcCmcStartDateChange = (date) => {
    const formattedDate =
      date && dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
    setCmcAmcStartDate(formattedDate);
    amcValidateDateRange(formattedDate, cmcAmcEndDate);
  };

  const handleAmcCmcEndDateChange = (date) => {
    const formattedDate =
      date && dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
    setCmcAmcEndDate(formattedDate);
    amcValidateDateRange(cmcAmcStartDate, formattedDate);
  };

  const amcValidateDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return;

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
      setIsAmcEndDateError("Invalid date range");
    } else if (start.getTime() === end.getTime()) {
      setIsAmcEndDateError("Invalid date range");
    } else {
      setIsAmcEndDateError(null);
      setIsCmcEndDateError(null);
    }
  };

  // const handleAmcCmc = (e) => {
  //   const value = e.target.value;
  //   setCmcAmc(value);

  //   setCmcAmcEndDate("");
  //   setCmcAmcStartDate("");
  //   setIsCmcEndDateError(null);
  //   setIsAmcEndDateError(null);

  //   if (value === "None") {
  //     setIsCmcEndDateError(null);
  //     setIsAmcEndDateError(null);
  //   } else if (value === "AMC") {
  //     setIsCmcEndDateError("Please enter the date");
  //   } else if (value === "CMC") {
  //     setIsAmcEndDateError("Please enter the date");
  //   }

  //   if (value !== "") {
  //     setIsCmcAmcError(null);
  //   }
  // };

  const handleCalibrationStatus = (e) => {
    let value = e.target.value;
    setCalibrationStatus(value);
    if (value === "No") {
      setCalibrationDate("");
      setIsRequiredError("");
      setIsCalibrationDateError(null);
    }
    if (value === "Yes") {
      setRequired("NA");
      setIsRequiredError(null);
      setCalibrationStartDate("");
      setCalibrationEndDate("");
      setIsCalibrationDateError("Please enter the date");
    }
    if (value !== "") {
      setIsCalibrationStatusError(null);
    }
  };

  useEffect(() => {
    let Id = uuid();
    localStorage.setItem("assetId", Id);
  }, []);

  const handleAssetStatus = (e) => {
    const value = e.target.value;
    setAssetStatus(value);
    if (value !== "") {
      setIsAssetStatusError(null);
    }
  };

  const handleAssetGrade = (e) => {
    const value = e.target.value;
    setAssetGrade(value);
    if (value !== "") {
      setIsAssetGradeError(null);
    }
  };

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
    if (value !== "") {
      setIsAssetTypeError(null);
    }
  };

  const handlePurchaseDate = (date) => {
    if (date) {
      setDateOfPurchase(dayjs(date));
      setIsDateOfPurchaseError(null);
    } else {
      setIsDateOfPurchaseError("Please select a date");
    }
  };

  const handleCalibrationDate = (e) => {
    let value = e.target.value;
    setCalibrationDate(value);
    if (value !== "") {
      setIsCalibrationDateError(null);
    }
  };

  const handleRequired = (e) => {
    let value = e.target.value;
    setRequired(value);
    setIsRequiredError(null);
    setCalibrationStartDate("");
    setCalibrationEndDate("");
  };
  ////////////////////////////////////////////////////////////////////////////////
  const handleCostCertificateUpload = (e) => {
    const splitId = assetId.substring(0, 4); // First 4 characters of assetId
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];

      const allowedSize =
        file.type === "application/pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        setIsCostCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsCostCertificateError(
          file.type === "application/pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return false;
      }

      setIsCostCertificateError(null);
      const reader = new FileReader();

      reader.onloadend = () => {
        let uuidShort = uuid().replace(/-/g, "").substring(0, 16); // Extract 11 characters from UUID
        let imageid = `${splitId}_${uuidShort}`; // Combine splitId and UUID part

        // Ensure imageid is exactly 16 characters
        imageid = imageid.substring(0, 16);

        let checkData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}.${fileType}`,
          image: reader.result,
          branchid: userBranch?.id,
        };

        setCostImageUrl((prevUrls) => [...prevUrls, checkData]);
        dispatch(uploadedAMCCMCCertificate(checkData, token));
      };

      reader.readAsDataURL(file);
      return true;
    });
  };

  const handleCMCCostCertificateUpload = (e) => {
    const splitId = assetId.substring(0, 4); // First 4 characters of assetId
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];

      const allowedSize =
        file.type === "application/pdf" ? 6 * 1024 * 1024 : 4 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        setIsCMCCostCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsCMCCostCertificateError(
          file.type === "application/pdf"
            ? "PDF file size cannot exceed 6 MB."
            : "Image file size cannot exceed 4 MB."
        );
        return false;
      }

      setIsCMCCostCertificateError(null);
      const reader = new FileReader();

      reader.onloadend = () => {
        let uuidShort = uuid().replace(/-/g, "").substring(0, 16); // Extract 11 characters from UUID
        let imageid = `${splitId}_${uuidShort}`; // Combine splitId and UUID part

        // Ensure imageid is exactly 16 characters
        imageid = imageid.substring(0, 16);

        let checkData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          imageid: `${imageid}.${fileType}`,
          image: reader.result,
          branchid: userBranch?.id,
        };

        setCmcCostImageUrl((prevUrls) => [...prevUrls, checkData]);
        dispatch(uploadedAMCCMCCertificate(checkData, token));
      };

      reader.readAsDataURL(file);
      return true;
    });
  };

  const removeCostCertificate = async (id, type) => {
    try {
      const checkData = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        imageid: id,
        branchid: userBranch?.id,
      };

      if (type === "cost") {
        await dispatch(deleteAMCCMCCertificate(checkData, token));
        setCostImageUrl(costImageUrl?.filter((item) => item?.imageid !== id));
      } else if (type === "cmc") {
        await dispatch(deleteAMCCMCCertificate(checkData, token));
        setCmcCostImageUrl(
          cmcCostImageUrl?.filter((item) => item?.imageid !== id)
        );
      }
    } catch (error) {
      console.error(`Failed to delete the ${type} certificate:`, error);
    }
  };

  const handleCost = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d+$/;

    if (value === "" || regex.test(value)) {
      setCost(value);
      setIsCostError(null);
    } else {
      setIsCostError("Enter a valid Cost value");
    }
  };

  const handleCMCCost = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d+$/;

    if (value === "" || regex.test(value)) {
      setCmcCost(value);
      setIsCMCCostError(null);
    } else {
      setIsCMCCostError("Enter a valid Cost value");
    }
  };

  const handleAmcCmc = (e) => {
    const value = e.target.value;
    setCmcAmc(value);

    setCmcAmcEndDate("");
    setCmcAmcStartDate("");
    setIsCmcEndDateError(null);
    setIsAmcEndDateError(null);

    switch (value) {
      case "None":
        setIsCmcEndDateError(null);
        setIsAmcEndDateError(null);
        setCost("");
        setCmcCost("");
        setCostImageUrl([]);
        setCmcCostImageUrl([]);

        break;
      case "AMC":
        setIsCmcEndDateError("Please enter the date");
        setCmcCost("");
        setCmcCostImageUrl([]);
        break;
      case "CMC":
        setIsAmcEndDateError("Please enter the date");
        setCost("");
        setCostImageUrl([]);
        break;
      default:
        break;
    }

    // Clear error if any value is selected
    if (value !== "") {
      setIsCmcAmcError(null);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////
  // const handleSubmit = () => {
  //   assetName === "" && setIsAssetError("Please fill the field");
  //   (department === "" || department === null) &&
  //     setIsDepartmentError("Please fill the field");
  //   assetType === "" && setIsAssetTypeError("Please fill the field");
  //   assetStatus === "" && setIsAssetStatusError("Please fill the field");
  //   calibrationStatus === "" &&
  //     setIsCalibrationStatusError("Please fill the field");
  //   cmcAmc === "" && setIsCmcAmcError("Please fill the field");
  //   assetCode === "" && setIsAssetCodeError("Please fill the field");
  //   fileUrl?.length <= 0 && setIsPhotoError("Please add an image");
  //   if (calibrationStatus === "Yes") {
  //     ((calibrationStartDate === "NA" || calibrationStartDate === "") &&
  //       setIsCalibrationDateError("Please enter the date")) ||
  //       ((calibrationEndDate === "NA" || calibrationEndDate === "") &&
  //         setIsCalibrationDateError("Please enter the date"));
  //   }
  //   if (calibrationStatus === "No") {
  //     (required === "NA" || required === "") &&
  //       setIsRequiredError("Please fill the field");
  //   }

  //   if (cmcAmc === "AMC" || cmcAmc === "CMC") {
  //     (cmcAmcEndDate === "" ||
  //       cmcAmcEndDate === "NA" ||
  //       cmcAmcStartDate === "" ||
  //       cmcAmcStartDate === "NA") &&
  //       setIsCmcEndDateError("Please fill this field");
  //   }

  //   if (
  //     assetName === "" ||
  //     department === "" ||
  //     department === null ||
  //     assetType === "" ||
  //     // branchname === "" ||
  //     assetStatus === "" ||
  //     calibrationStatus === "" ||
  //     cmcAmc === "" ||
  //     fileUrl.length === 0 ||
  //     assetCode === ""
  //   ) {
  //     setSnackbarMessage("Please fill all the mandatory fields.");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   if (
  //     assetName !== "" &&
  //     department !== "" &&
  //     department !== null &&
  //     assetType !== "" &&
  //     assetStatus !== "" &&
  //     calibrationStatus !== "" &&
  //     cmcAmc !== "" &&
  //     fileUrl.length !== 0 &&
  //     assetCode !== ""
  //   ) {
  //     if (
  //       isAssetError === null &&
  //       isSerialNumberError === null &&
  //       isModelNumberError === null &&
  //       isPhoneError === null &&
  //       isEmailError === null &&
  //       isWarrantyError === null &&
  //       isDateOfPurchaseError === null &&
  //       isPriceError === null &&
  //       isAmcEndDateError === null &&
  //       isCmcEndDateError === null &&
  //       isAssetTypeError === null &&
  //       isAssetStatusError === null &&
  //       isCalibrationStatusError === null &&
  //       isCmcAmcError === null &&
  //       isUsefullLifeError === null &&
  //       isCalibrationDateError === null &&
  //       isRequiredError === null &&
  //       isPhotoError === null &&
  //       isCertificateError === null &&
  //       isAssetCodeError === null
  //     ) {
  //       const splitId = assetId.substring(0, 4);
  //       let dataQr = {
  //         hospid: userIds.hospid,
  //         userid: userIds.userid,
  //         imageid: `${splitId}_` + uuid() + `.${"png"}`,
  //         image: qrCode,
  //         branchid: userBranch?.id,
  //       };

  //       dispatch(uploadQrCodeImage(dataQr));

  //       let data = {
  //         assetid: assetId,
  //         hospid: userIds?.hospid,
  //         branchname: userBranch.branch,
  //         hospname: userIds?.hospname,
  //         userid: userIds?.userid,
  //         username: userIds?.username,
  //         assetname: assetName.trim(),
  //         serialno: serialNumber === "" ? "" : serialNumber.trim(),
  //         assetcode: assetCode === "" ? "" : assetCode.trim(),
  //         modelno: modelNumber === "" ? "" : modelNumber.trim(),
  //         department: department,
  //         department_id: departmentId.trim(),
  //         assettype: assetType.trim(),
  //         assetstatus: assetStatus.trim(),
  //         branchid: userBranch.id,
  //         brand: brand === "" ? "" : brand.trim(),
  //         sellermobile: phone === "" ? "" : phone,
  //         selleremail: email === "" ? "" : email,
  //         lifeyrs: usefullLife === "" ? "" : usefullLife.trim(),
  //         price: price === "" ? "" : price.trim(),
  //         purchdate:
  //           dateOfPurchase === ""
  //             ? ""
  //             : moment(dateOfPurchase).format("DD-MM-YYYY"),
  //         warntdate:
  //           warrantyStartDate === ""
  //             ? ""
  //             : moment(warrantyStartDate).format("DD-MM-YYYY"),
  //         wrntymonth: "1",
  //         expdate:
  //           warrantyEndDate === "" || warrantyEndDate === ""
  //             ? ""
  //             : moment(warrantyEndDate).format("DD-MM-YYYY"),
  //         sellername: soldBy === "" ? "" : soldBy.trim(),
  //         prodimgs:
  //           fileUrl?.length > 0
  //             ? fileUrl?.map((item) => {
  //                 return item.imageid;
  //               })
  //             : "NA",
  //         wrntyimage:
  //           certificateUrl?.length > 0
  //             ? certificateUrl?.map((item) => {
  //                 return item.imageid;
  //               })
  //             : "NA",
  //         qrcodeimg: dataQr?.imageid,
  //         calibrate_status: calibrationStatus,
  //         calibrate_date:
  //           calibrationStartDate === ""
  //             ? ""
  //             : moment(calibrationStartDate).format("DD-MM-YYYY"),
  //         calibrate_req: required === "" ? "" : required,
  //         calibrate_duemonth: "1",
  //         calibrate_duedate:
  //           calibrationEndDate === ""
  //             ? ""
  //             : moment(calibrationEndDate)
  //                 .add(dueDate, "months")
  //                 .subtract(1, "days")
  //                 .format("DD-MM-YYYY"),
  //         cmcamc: cmcAmc,
  //         cmcamc_start:
  //           cmcAmcStartDate === ""
  //             ? ""
  //             : moment(cmcAmcStartDate).format("DD-MM-YYYY"),
  //         cmcamc_due:
  //           cmcAmcEndDate === ""
  //             ? ""
  //             : moment(cmcAmcEndDate).format("DD-MM-YYYY"),
  //         calibformat:
  //           calibrationStartDate === ""
  //             ? ""
  //             : moment(calibrationStartDate).format("YYYY-MM-DD"),
  //         purdateformat:
  //           dateOfPurchase === ""
  //             ? ""
  //             : moment(dateOfPurchase).format("YYYY-MM-DD"),
  //         notes: notes === "" ? "" : notes.trim(),
  //         report_names:
  //           assetReport?.length === 0
  //             ? []
  //             : assetReport?.map((item) => {
  //                 return item.report_name;
  //               }),
  //         report_ids:
  //           assetReport?.length === 0
  //             ? []
  //             : assetReport?.map((item) => {
  //                 return item.imageid;
  //               }),
  //       };
  //       dispatch(addAsset(data));

  //       setToggle(true);
  //       setOpenModal(true);
  //     }
  //   }
  // };

  const handleSubmit = () => {
    /////////////////////////////////////////////////////////////////////
    let isValid = true;
    let errorMessage = "";

    if (cmcAmc === "AMC" && (!cost || cost.trim() === "")) {
      setIsCostError("Please enter the cost.");
      errorMessage = "Please enter AMC cost.";

      isValid = false;
    } else {
      setIsCostError("");
    }

    if (cmcAmc === "CMC" && (!cmcCost || cmcCost.trim() === "")) {
      setIsCMCCostError("Please enter the cost.");
      errorMessage = "Please enter CMC cost.";

      isValid = false;
    } else {
      setIsCMCCostError("");
    }

    // Validation for file upload
    if (cmcAmc === "AMC" && uploadedCostCertificateName.length === 0) {
      setIsCostCertificateError("Please upload the AMC Contract Document.");
      errorMessage = "Please Upload AMC file";
      isValid = false;
    } else {
      setIsCostCertificateError("");
    }

    if (cmcAmc === "CMC" && uploadedCMCCostCertificateName.length === 0) {
      setIsCMCCostCertificateError("Please upload the CMC Contract Document.");
      errorMessage = "Please Upload CMC file";
      isValid = false;
    } else {
      setIsCMCCostCertificateError("");
    }

    if (!isValid) {
      setToastMessage(errorMessage);
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return;
    }
    setIsToastVisible(false);

    /////////////////////////////////////////////////////////////////////
    assetName.trim() === "" &&
      setIsAssetError("Please fill the field")(
        department === "" || department === null
      ) &&
      setIsDepartmentError("Please fill the field");
    assetType.trim() === "" && setIsAssetTypeError("Please fill the field");

    if (assetStatus.trim() === "") {
      setIsAssetStatusError("Please fill the field");

      setToastMessage("Select Asset Status");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Prevent further execution if there's an error
    }
    if (assetGrade.trim() === "") {
      setIsAssetGradeError("Please fill the field");

      setToastMessage("Select Asset Grade");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Prevent further execution if there's an error
    }
    calibrationStatus.trim() === "" &&
      setIsCalibrationStatusError("Please fill the field");
    cmcAmc.trim() === "" && setIsCmcAmcError("Please fill the field");
    assetCode.trim() === "" && setIsAssetCodeError("Please fill the field");
    fileUrl?.length <= 0 && setIsPhotoError("Please add an image");
    ///////////////////////////////////////////////////////////////////////
    cost === "" && setIsCostError("Please fill the field");
    cmcCost === "" && setIsCMCCostCertificateError("Please fill the field");
    //////////////////////////////////////////////////////////////////////////

    if (assetType.trim() === "") {
      setToastMessage("select Asset Type");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
    }

    if (calibrationStatus.trim() === "") {
      setToastMessage("select Calibration Status");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
    }

    if (cmcAmc.trim() === "") {
      setToastMessage("select AMC/CMC Status");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
    }

    if (calibrationStatus === "Yes") {
      let calibrationError = false;

      if (calibrationStartDate === "NA" || calibrationStartDate === "") {
        setIsCalibrationDateError("Please enter the Start Date");
        calibrationError = true;
      }

      if (calibrationEndDate === "NA" || calibrationEndDate === "") {
        setIsCalibrationDateError("Please enter the End Date");
        calibrationError = true;
      }

      if (calibrationError) {
        setToastMessage("Select Calibration Start Date and End Date");
        setIsToastVisible(true);
        setTimeout(() => setIsToastVisible(false), 3000);
        return; // Prevent further execution if there's an error
      }
    }

    if (calibrationStatus === "No") {
      if (required === "NA" || required === "") {
        setIsRequiredError("Please fill the field");

        setToastMessage("Select Calibration: Required or Not");
        setIsToastVisible(true);
        setTimeout(() => setIsToastVisible(false), 3000);
        return; // Prevent further execution if there's an error
      }
    }

    if (cmcAmc === "AMC" || cmcAmc === "CMC") {
      let cmcAmcError = false;

      if (cmcAmcStartDate === "" || cmcAmcStartDate === "NA") {
        setIsCmcEndDateError("Please fill the Start Date");
        cmcAmcError = true;
      }

      if (cmcAmcEndDate === "" || cmcAmcEndDate === "NA") {
        setIsCmcEndDateError("Please fill the End Date");
        cmcAmcError = true;
      }

      if (cmcAmcError) {
        if (cmcAmc === "AMC") {
          setToastMessage("Select AMC Start Date and End Date");
        } else {
          setToastMessage("Select CMC Start Date and End Date");
        }

        setIsToastVisible(true);
        setTimeout(() => setIsToastVisible(false), 3000);
        return; // Prevent further execution if there's an error
      }
    }

    if (
      assetName.trim() === "" ||
      department === "" ||
      department === null ||
      assetType.trim() === "" ||
      assetStatus.trim() === "" ||
      calibrationStatus.trim() === "" ||
      cmcAmc.trim() === "" ||
      fileUrl.length === 0 ||
      assetCode.trim() === ""
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }

    if (
      assetName.trim() !== "" &&
      department !== "" &&
      department !== null &&
      assetType.trim() !== "" &&
      assetStatus.trim() !== "" &&
      assetGrade.trim() !== "" &&
      calibrationStatus.trim() !== "" &&
      cmcAmc.trim() !== "" &&
      fileUrl.length !== 0 &&
      assetCode.trim() !== ""
    ) {
      if (
        isAssetError === null &&
        isPhoneError === null &&
        isEmailError === null &&
        isWarrantyError === null &&
        isDateOfPurchaseError === null &&
        isPriceError === null &&
        isAmcEndDateError === null &&
        isCmcEndDateError === null &&
        isAssetTypeError === null &&
        isAssetStatusError === null &&
        isAssetGradeError === null &&
        isCalibrationStatusError === null &&
        isCmcAmcError === null &&
        isUsefullLifeError === null &&
        isCalibrationDateError === null &&
        isRequiredError === null &&
        isPhotoError === null &&
        isCertificateError === null &&
        isAssetCodeError === null
      ) {
        const splitId = assetId.substring(0, 4);
        let dataQr = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          imageid: `${splitId}_` + uuid() + `.${"png"}`,
          image: qrCode,
          branchid: userBranch?.id,
          userrole: userAccess?.role,
          depart_ids: userAccess.departmentids,
        };

        dispatch(uploadQrCodeImage(dataQr));

        let data = {
          assetid: assetId,
          hospid: userIds?.hospid,
          branchname: userBranch.branch,
          hospname: userIds?.hospname,
          userid: userIds?.userid,
          username: userIds?.username,
          assetname: assetName.trim(),
          serialno: serialNumber === "" ? "" : serialNumber.trim(),
          assetcode: assetCode.trim(),
          modelno: modelNumber === "" ? "" : modelNumber.trim(),
          department: department,
          department_id: departmentId.trim(),
          assettype: assetType.trim(),
          assetstatus: assetStatus.trim(),
          asset_grade: assetGrade.trim(),
          branchid: userBranch.id,
          brand: brand === "" ? "" : brand.trim(),
          sellermobile: phone === "" ? "" : phone,
          selleremail: email === "" ? "" : email,
          lifeyrs: usefullLife === "" ? "" : usefullLife.trim(),
          price: price === "" ? "" : price.trim(),
          purchdate:
            dateOfPurchase === ""
              ? ""
              : moment(dateOfPurchase).format("DD-MM-YYYY"),
          warntdate:
            warrantyStartDate && dayjs(warrantyStartDate).isValid()
              ? moment(warrantyStartDate).format("DD-MM-YYYY")
              : "",
          wrntymonth: "1",
          expdate:
            warrantyEndDate && dayjs(warrantyEndDate).isValid()
              ? moment(warrantyEndDate).format("DD-MM-YYYY")
              : "",
          sellername: soldBy === "" ? "" : soldBy.trim(),
          prodimgs:
            fileUrl?.length > 0
              ? fileUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          wrntyimage:
            certificateUrl?.length > 0
              ? certificateUrl?.map((item) => {
                  return item.imageid;
                })
              : "",
          qrcodeimg: dataQr?.imageid,
          calibrate_status: calibrationStatus,
          calibrate_date: calibrationStartDate
            ? moment(calibrationStartDate).format("DD-MM-YYYY")
            : "",

          calibrate_req: required === "" ? "" : required,
          calibrate_duemonth: "1",
          calibrate_duedate: calibrationEndDate
            ? moment(calibrationEndDate).format("DD-MM-YYYY")
            : "",
          cmcamc: cmcAmc,
          cmcamc_start:
            cmcAmcStartDate === ""
              ? ""
              : moment(cmcAmcStartDate).format("DD-MM-YYYY"),
          cmcamc_due:
            cmcAmcEndDate === ""
              ? ""
              : moment(cmcAmcEndDate).format("DD-MM-YYYY"),
          calibformat: calibrationStartDate
            ? moment(calibrationStartDate).format("YYYY-MM-DD")
            : "",
          purdateformat:
            dateOfPurchase === ""
              ? ""
              : moment(dateOfPurchase).format("YYYY-MM-DD"),
          notes: notes === "" ? "" : notes.trim(),
          report_names:
            assetReport?.length === 0
              ? []
              : assetReport?.map((item) => {
                  return item.report_name;
                }),
          report_ids:
            assetReport?.length === 0
              ? []
              : assetReport?.map((item) => {
                  return item.imageid;
                }),
          accessoryname: [],
          accessoryprice: [],
          accessoryquantity: [],
          accessoryimage_id: [],
          userrole: userAccess?.role,
          depart_ids: userAccess?.departmentids,
          amc_cmc_cost: cost || cmcCost,
          amc_cmc_file:
            cmcCostImageUrl?.length > 0
              ? cmcCostImageUrl[0].imageid
              : costImageUrl?.length > 0
              ? costImageUrl[0].imageid
              : "NA",
        };
        dispatch(addAsset(data));

        setToggle(true);
        setOpenModal(true);
        // console.log("updated data",data)
      }
    }
  };


  const handleCertificateFlag = () => {
    setCertificateFlag(!certificateFlag);

    setIsCertificateError(null);
  };

  const handleImageFlag = () => {
    setPhotoFlag(!photoFlag);

    setIsPhotoError(null);
  };

  const [activeStep, setActiveStep] = useState(0);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate("/all-assets");
  };
  const handleNext = () => {
    let errorMessage = "";

    // Validate each field and set error messages
    if (assetName.trim() === "") {
      setToastMessage("Please fill the asset name");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Exit after showing the first error
    }
    if (assetCode === "") {
      setIsAssetCodeError("Please fill the asset code");
      setToastMessage("Please fill the asset code");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Exit after showing the first error
    }

    // Validate department
    if (department === "" || department === null) {
      setIsDepartmentError("Please fill the department");
      setToastMessage("Please fill the department");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Exit after showing the first error
    }

    // Validate brand
    if (brand === "") {
      setIsBrandError("Please fill the brand");
      setToastMessage("Please fill the brand");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Exit after showing the first error
    }

    // Validate fileUrl
    if (fileUrl?.length <= 0) {
      setIsPhotoError("Please add an image");
      setToastMessage("Please add Asset image");
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return; // Exit after showing the first error
    }

// Validate phone & email only if soldBy is filled
if (soldBy.trim() !== "") {
  const phoneRegex = /^\d{10}$/;
  if (!phoneRegex.test(phone)) {
    setIsPhoneError("Please enter a valid 10-digit phone number");
    setToastMessage("Please enter a valid 10-digit phone number");
    setIsToastVisible(true);
    setTimeout(() => setIsToastVisible(false), 3000);
    return;
  }

  if (email.trim() === "") {
    setIsEmailError("Please fill the field");
    setToastMessage("Please fill the email field");
    setIsToastVisible(true);
    setTimeout(() => setIsToastVisible(false), 3000);
    return;
  }
}

  
    // If there's an error message, show the toast and exit
    if (errorMessage) {
      setToastMessage(errorMessage);
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return;
    }



    // Proceed to the next step if there are no errors
    setIsToastVisible(false);
    if (
      assetName !== "" &&
      department !== "" &&
      department !== null &&
      assetCode !== "" &&
      brand !== "" &&
      fileUrl.length !== 0 &&
      !isAssetError &&
      !isPhoneError &&
      !isEmailError &&
      !isDateOfPurchaseError &&
      !isPriceError &&
      !isUsefullLifeError &&
      !isAssetCodeError &&
      !isPhotoError
    ) {
      if (soldBy.trim() !== "") {
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phone) || email.trim() === "") {
          return; // Don't proceed
        }
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };


  const isGuest = userAccess && userAccess.role === "Guest";
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Wrapper sx={{ bgcolor: "white" }}>
            <SplitContainer>
              <InnerSplitContainer>
                <Stack>
                  <Field
                    name="destination"
                    label="Asset Name *"
                    onChange={handleAssetName}
                    variant="filled"
                    value={assetName}
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />
                  <HelperText variant="caption">{isAssetError}</HelperText>
                </Stack>
                <Stack>
                  <Autocomplete
                    disabled={departmentData?.length <= 0}
                    sx={{ bgcolor: "#EFF5FE" }}
                    value={department}
                    options={departmentData?.map((option) => option.departname)}
                    onChange={handleDepartment}
                    renderInput={(params) => (
                      <Field
                        placeholder="e.g. Emergency Department  *"
                        {...params}
                      />
                    )}
                  />

                  <HelperText variant="caption">{isDepartmentError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Asset Code *"
                    value={assetCode}
                    onChange={handleAssetCode}
                    variant="filled"
                    autoComplete="off"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isAssetCodeError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Serial No"
                    onChange={handleSerialNumber}
                    value={serialNumber}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />

                  <HelperText variant="caption">
                    {isSerialNumberError}
                  </HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    value={usefullLife}
                    label="Useful Life"
                    onChange={handleUsefulLife}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />

                  <HelperText variant="caption">
                    {isUsefullLifeError}
                  </HelperText>
                </Stack>

                <Stack sx={{}}>
                  <Field
                    label="Seller Name"
                    onChange={handleSoldBy}
                    value={soldBy}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />

                  <HelperText variant="caption">{isSoldByError}</HelperText>
                </Stack>
                {soldBy && (
                  <>
                    <Stack>
                      <Field
                        name="destination"
                        label="Seller Email *"
                        onChange={handleEmail}
                        value={email}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        autoComplete="off"
                      />

                      <HelperText variant="caption">{isEmailError}</HelperText>
                    </Stack>
                    <Stack>
                      <Field
                        name="destination"
                        label="Seller Phone *"
                        value={phone}
                        onChange={handlePhone}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        autoComplete="off"
                      />

                      <HelperText variant="caption">{isPhoneError}</HelperText>
                    </Stack>
                  </>
                )}
              </InnerSplitContainer>

              <InnerSplitContainer>
                <Stack>
                  <Field
                    label="Brand *"
                    onChange={handleBrand}
                    value={brand}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />

                  <HelperText variant="caption">{isBrandError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Model No."
                    onChange={handleModelNumber}
                    value={modelNumber}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />

                  <HelperText variant="caption">
                    {isModelNumberError}
                  </HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Price"
                    onChange={handlePrice}
                    value={price}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                  />

                  <HelperText variant="caption">{isPriceError}</HelperText>
                </Stack>
                <Stack>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <DatePicker
                      label="Purchase Date"
                      value={dateOfPurchase}
                      slotProps={{
                        textField: {
                          error: false,
                          variant: "filled",
                        },
                      }}
                      format="DD-MM-YYYY"
                      sx={{
                        border: "none",
                        backgroundColor: "rgba(244, 246, 246, 1)",
                        "& label.Mui-focused": {
                          color: "rgba(166, 166, 166, 1)",
                        },
                        "& .MuiFilledInput-root": {
                          backgroundColor: "rgba(244, 246, 246, 1)",
                        },
                        "&:hover .MuiFilledInput-root": {
                          backgroundColor: "rgba(244, 246, 246, 1)",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      onChange={handlePurchaseDate}
                      InputProps={{ disableUnderline: true }}
                    />
                  </LocalizationProvider>
                  {<HelperText>{isDateOfPurchaseError}</HelperText>}
                  <Stack sx={{ marginTop: "20px", gap: "10px" }}>
                    <Stack>
                      <Stack
                        flexDirection={"row"}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          Upload Asset Images{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>

                        {uploadedImageName?.length < 3 && (
                          <>
                            <label for="img">
                              <UploadIcon
                                size={20}
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  cursor: "pointer",
                                }}
                              />
                            </label>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img"
                              style={{
                                display: "none",
                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        )}
                      </Stack>

                      <Stack
                        sx={{
                          minHeight: "50px",
                          maxHeight: "150px",
                          width: "auto",
                          flexDirection: "row",
                          overflowX: "auto",
                          overflowY: "hidden",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        {fileUrl?.map((item, index) => (
                          <Badge
                            key={index}
                            badgeContent={
                              <CloseIcon
                                style={{
                                  color: "rgba(255, 58, 68, 1)",
                                }}
                                onClick={() => removePhoto(item?.imageid)}
                              />
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                              border: "0.5px solid rgba(27, 37, 53, 1)",
                              padding: "5px",
                            }}
                          >
                            <Box
                              as="img"
                              src={item?.image}
                              alt=""
                              sx={{ width: "70px", height: "90px" }}
                            />
                          </Badge>
                        ))}
                      </Stack>
                      {isPhotoError && (
                        <HelperText sx={{ color: "red" }}>
                          {isPhotoError}
                        </HelperText>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </InnerSplitContainer>
            </SplitContainer>
          </Wrapper>
        );
      case 1:
        return (
          <Box sx={{ bgcolor: "white", padding: "20px" }}>
            <SplitContainer>
              <InnerSplitContainer
                sx={{
                  width: "50%",
                  gap: "15px",

                  height: "100%",
                  textAlign: "start",
                }}
              >
                <Label>Asset</Label>

                <Stack
                  sx={{
                    height: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <Label>
                    Type{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleAssetType}
                    value={assetType}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      value="New"
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontWeight={500}>
                          New
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Old"
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontWeight={500}>
                          Old
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Refurbished"
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontWeight={500}>
                          Refurbished
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  {<HelperText>{isAssetTypeError}</HelperText>}
                </Stack>

                <Stack
                  sx={{
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <Label>
                    Working Status{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleAssetStatus}
                    value={assetStatus}
                  >
                    <Stack>
                      <FormControlLabel
                        value="Working"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Working
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Not working"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Not working
                          </Typography>
                        }
                      />
                    </Stack>
                    <Stack>
                      <FormControlLabel
                        value="Need Service"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Need Service
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Discard"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Discard
                          </Typography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                  {
                    <HelperText sx={{ textAlign: "left" }}>
                      {isAssetStatusError}
                    </HelperText>
                  }
                </Stack>
                <Stack
                  sx={{
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <Label>Warranty</Label>
                  <YesDateBox>
                    <DateBox>
                      <CustomText fontWeight="600">Start Date</CustomText>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <HalfInput2
                          format="DD/MM/YYYY"
                          onChange={(date) =>
                            handleWarrantyStartDate(dayjs(date))
                          }
                          value={
                            warrantyStartDate ? dayjs(warrantyStartDate) : null
                          }
                          sx={{ bgcolor: "white" }}
                        />
                      </LocalizationProvider>
                    </DateBox>

                    <DateBox>
                      <CustomText fontWeight="600">End Date</CustomText>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <HalfInput2
                          format="DD/MM/YYYY"
                          onChange={(date) =>
                            handleWarrantyEndDate(dayjs(date))
                          }
                          value={
                            warrantyEndDate ? dayjs(warrantyEndDate) : null
                          }
                        />
                      </LocalizationProvider>
                    </DateBox>
                  </YesDateBox>
                  <Stack>{<HelperText>{isWarrantyError}</HelperText>}</Stack>
                </Stack>
                <Divider />

                <Stack sx={{}}>
                  <Stack
                    flexDirection={"row"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Upload Warranty certificate</Typography>
                    {uploadedCertificateName.length < 3 && (
                      <>
                        <label for="img">
                          <UploadIcon
                            size={20}
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",
                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handleCertificateUpload}
                        />
                      </>
                    )}
                  </Stack>

                  <Stack
                    sx={{
                      minHeight: "50px",
                      maxHeight: "150px",
                      width: "auto",
                      flexDirection: "row",
                      overflowX: "auto",
                      overflowY: "hidden",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    {certificateUrl?.map((item, index) => (
                      <Badge
                        key={index}
                        badgeContent={
                          <CloseIcon
                            style={{
                              color: " rgba(255, 58, 68, 1)",
                            }}
                            onClick={() => removeCertificate(item?.imageid)}
                          />
                        }
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                          border: "0.5px solid rgba(27, 37, 53, 1)",
                          padding: "5px",
                        }}
                      >
                        <Box
                          as="img"
                          src={
                            item.imageid.toLowerCase().endsWith(".pdf")
                              ? pdfImage
                              : item?.image
                          }
                          alt={item?.image}
                          sx={{ width: "70px", height: "90px" }}
                        />
                      </Badge>
                    ))}
                  </Stack>
                  {isCertificateError && (
                    <HelperText sx={{ color: "red" }}>
                      {isCertificateError}
                    </HelperText>
                  )}
                </Stack>
              </InnerSplitContainer>
              <InnerSplitContainer
                sx={{
                  width: "50%",
                  height: "100%",

                  gap: "15px",
                  textAlign: "start",
                }}
              >
                <Typography style={{ color: "white" }}>. </Typography>

                <Stack
                  sx={{
                    minHeight: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <Label>
                    Calibration Status{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>
                  <CheckBoxContainer>
                    <FormRadio onChange={handleCalibrationStatus}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={calibrationStatus}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              Yes
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              No
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {<HelperText>{isCalibrationStatusError}</HelperText>}
                    </FormRadio>
                    {calibrationStatus === "Yes" && (
                      <YesDateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">
                            Done Date{" "}
                            <span style={{ color: "red", fontWeight: "100" }}>
                              *
                            </span>
                          </CustomText>

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}
                          >
                            <HalfInput2
                              format="DD/MM/YYYY"
                              onChange={(date) =>
                                handleCalibrationStartDate(dayjs(date))
                              }
                              value={
                                calibrationStartDate
                                  ? dayjs(calibrationStartDate)
                                  : null
                              }
                            />
                          </LocalizationProvider>
                          <Box>
                            <HelperText>{isCalibrationDateError}</HelperText>
                          </Box>
                        </DateBox>

                        <DueDateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">
                              Due Date{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </CustomText>

                            <LocalizationProvider
                              format="DD/MM/YYYY"
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <HalfInput2
                                onChange={(date) =>
                                  handleCalibrationEndDate(dayjs(date))
                                }
                                value={
                                  calibrationEndDate
                                    ? dayjs(calibrationEndDate)
                                    : null
                                }
                              />
                            </LocalizationProvider>

                            <Box>
                              <Typography
                                variant="caption"
                                sx={{ color: "#EFF5FE" }}
                              >
                                {isCalibrationDateError}
                              </Typography>
                            </Box>
                          </DateBox>
                        </DueDateBox>
                      </YesDateBox>
                    )}
                    {calibrationStatus === "No" && (
                      <DateBox padding="5px" maxHeight="88px" maxWidth="258px">
                        <CustomText fontWeight="600" textAlign={"left"}>
                          Required ?
                        </CustomText>
                        <FormRadio>
                          <Box borderRadius="6px">
                            <RadioGroup
                              onChange={handleRequired}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="Required"
                                control={<Radio size="small" />}
                                label={
                                  <CustomText sx={{ color: "#000 !important" }}>
                                    Required{" "}
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "100",
                                      }}
                                    >
                                      *
                                    </span>
                                  </CustomText>
                                }
                              />
                              <FormControlLabel
                                value="Not Required"
                                control={<Radio size="small" />}
                                label={
                                  <CustomText sx={{ color: "#000 !important" }}>
                                    Not Required{" "}
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "100",
                                      }}
                                    >
                                      *
                                    </span>
                                  </CustomText>
                                }
                              />
                            </RadioGroup>
                            {<HelperText>{isRequiredError}</HelperText>}
                          </Box>
                        </FormRadio>
                      </DateBox>
                    )}
                  </CheckBoxContainer>
                </Stack>
                <Stack
                  sx={{
                    minHeight: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <Label>
                    CMC/AMC{" "}
                    <span style={{ color: "red", fontWeight: "100" }}>*</span>
                  </Label>
                  <CheckBoxContainer>
                    <FormRadio>
                      {/* <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={cmcAmc}
                      >
                        <FormControlLabel
                          value="CMC"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              CMC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="AMC"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              AMC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="None"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              None
                            </Typography>
                          }
                        />
                      </RadioGroup> */}
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={cmcAmc}
                        onChange={handleAmcCmc}
                      >
                        <FormControlLabel
                          value="CMC"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              CMC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="AMC"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              AMC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="None"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              None
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {<HelperText>{isCmcAmcError}</HelperText>}
                    </FormRadio>

                    {/* {cmcAmc === "AMC" && (
                      <YesDateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">Start Date</CustomText>

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}
                          >
                            <HalfInput2
                              onChange={(date) =>
                                handleAmcCmcStartDateChange(dayjs(date))
                              }
                              value={
                                cmcAmcStartDate ? dayjs(cmcAmcStartDate) : null
                              }
                            />
                          </LocalizationProvider>
                        </DateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">End Date</CustomText>

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}
                          >
                            <HalfInput2
                              onChange={(date) =>
                                handleAmcCmcEndDateChange(dayjs(date))
                              }
                              value={
                                cmcAmcEndDate ? dayjs(cmcAmcEndDate) : null
                              }
                            />
                          </LocalizationProvider>
                        </DateBox>
                      </YesDateBox>
                    )}

                    {cmcAmc === "CMC" && (
                      <YesDateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">Start Date</CustomText>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}
                          >
                            <HalfInput2
                              onChange={(date) =>
                                handleAmcCmcStartDateChange(dayjs(date))
                              }
                              value={
                                cmcAmcStartDate ? dayjs(cmcAmcStartDate) : null
                              }
                            />
                          </LocalizationProvider>
                        </DateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">End Date</CustomText>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}
                          >
                            <HalfInput2
                              onChange={(date) =>
                                handleAmcCmcEndDateChange(dayjs(date))
                              }
                              value={
                                cmcAmcEndDate ? dayjs(cmcAmcEndDate) : null
                              }
                            />
                          </LocalizationProvider>
                        </DateBox>
                      </YesDateBox>
                    )} */}

                    {cmcAmc === "AMC" && (
                      <>
                        <YesDateBox>
                          {/* Start Date */}
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">
                              Start Date{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <HalfInput2
                                onChange={(date) =>
                                  handleAmcCmcStartDateChange(dayjs(date))
                                }
                                value={
                                  cmcAmcStartDate
                                    ? dayjs(cmcAmcStartDate)
                                    : null
                                }
                              />
                            </LocalizationProvider>
                          </DateBox>

                          {/* End Date */}
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">
                              End Date{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <HalfInput2
                                onChange={(date) =>
                                  handleAmcCmcEndDateChange(dayjs(date))
                                }
                                value={
                                  cmcAmcEndDate ? dayjs(cmcAmcEndDate) : null
                                }
                              />
                            </LocalizationProvider>
                          </DateBox>
                        </YesDateBox>
                        {<HelperText>{isAmcEndDateError}</HelperText>}
                        {<HelperText>{isCmcEndDateError}</HelperText>}

                        {/* Useful Life Section - Outside YesDateBox */}
                        <Stack>
                          <label
                            htmlFor="costvalue"
                            style={{ color: "black", fontWeight: "300" }}
                          >
                            Cost{" "}
                            <span style={{ color: "red", fontWeight: "100" }}>
                              *
                            </span>
                          </label>
                          <Field
                            name="costvalue"
                            value={cost}
                            onChange={handleCost}
                            placeholder="Enter cost"
                            variant="filled"
                            InputProps={{
                              disableUnderline: true,
                              style: { backgroundColor: "white" },
                            }}
                            autoComplete="off"
                          />
                        </Stack>
                        {<HelperText>{isCostError}</HelperText>}

                        {/* Warranty Certificate Upload Section - Outside YesDateBox */}
                        <Stack sx={{}}>
                          <Stack
                            flexDirection={"row"}
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <Typography>
                              AMC/CMC Contract Document{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </Typography>
                            {uploadedCostCertificateName.length < 1 && (
                              <>
                                <label for="img1">
                                  <UploadIcon
                                    size={20}
                                    sx={{
                                      color: "rgba(27, 37, 53, 1)",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                                <input
                                  type="file"
                                  name="uploadfile"
                                  id="img1"
                                  style={{
                                    display: "none",
                                    width: "42px",
                                    height: "42px",
                                  }}
                                  onChange={handleCostCertificateUpload}
                                />
                              </>
                            )}
                          </Stack>

                          <Stack
                            sx={{
                              minHeight: "50px",
                              maxHeight: "150px",
                              width: "auto",
                              flexDirection: "row",
                              overflowX: "auto",
                              overflowY: "hidden",
                              gap: "20px",
                              alignItems: "center",
                            }}
                          >
                            {costImageUrl?.map((item, index) => (
                              <Badge
                                key={index}
                                badgeContent={
                                  <CloseIcon
                                    style={{
                                      color: " rgba(255, 58, 68, 1)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      removeCostCertificate(
                                        item?.imageid,
                                        "cost"
                                      )
                                    }
                                  />
                                }
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                  border: "0.5px solid rgba(27, 37, 53, 1)",
                                  padding: "5px",
                                }}
                              >
                                <Box
                                  as="img"
                                  src={
                                    item.imageid.toLowerCase().endsWith(".pdf")
                                      ? pdfImage
                                      : item?.image
                                  }
                                  alt={item?.image}
                                  sx={{ width: "70px", height: "90px" }}
                                />
                              </Badge>
                            ))}
                          </Stack>
                          {isCostCertificateError && (
                            <HelperText sx={{ color: "red" }}>
                              {isCostCertificateError}
                            </HelperText>
                          )}
                        </Stack>
                      </>
                    )}

                    {cmcAmc === "CMC" && (
                      <>
                        <YesDateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">
                              Start Date{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <HalfInput2
                                onChange={(date) =>
                                  handleAmcCmcStartDateChange(dayjs(date))
                                }
                                value={
                                  cmcAmcStartDate
                                    ? dayjs(cmcAmcStartDate)
                                    : null
                                }
                              />
                            </LocalizationProvider>
                          </DateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">
                              End Date{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <HalfInput2
                                onChange={(date) =>
                                  handleAmcCmcEndDateChange(dayjs(date))
                                }
                                value={
                                  cmcAmcEndDate ? dayjs(cmcAmcEndDate) : null
                                }
                              />
                            </LocalizationProvider>
                          </DateBox>
                        </YesDateBox>
                        {<HelperText>{isAmcEndDateError}</HelperText>}
                        {<HelperText>{isCmcEndDateError}</HelperText>}

                        <Stack>
                          <label
                            htmlFor="costvalue"
                            style={{ color: "black", fontWeight: "300" }}
                          >
                            Cost{" "}
                            <span style={{ color: "red", fontWeight: "100" }}>
                              *
                            </span>
                          </label>
                          <Field
                            name="costvalue"
                            value={cmcCost}
                            onChange={handleCMCCost}
                            placeholder="Enter cost"
                            variant="filled"
                            InputProps={{
                              disableUnderline: true,
                              style: { backgroundColor: "white" },
                            }}
                            autoComplete="off"
                          />
                          <HelperText variant="caption">
                            {isCMCCostError}
                          </HelperText>
                        </Stack>

                        {/* Warranty Certificate Upload Section - Outside YesDateBox */}
                        <Stack sx={{}}>
                          <Stack
                            flexDirection={"row"}
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <Typography>
                              AMC/CMC Contract Document{" "}
                              <span style={{ color: "red", fontWeight: "100" }}>
                                *
                              </span>
                            </Typography>
                            {uploadedCMCCostCertificateName.length < 1 && (
                              <>
                                <label for="img2">
                                  <UploadIcon
                                    size={20}
                                    sx={{
                                      color: "rgba(27, 37, 53, 1)",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                                <input
                                  type="file"
                                  name="uploadfile"
                                  id="img2"
                                  style={{
                                    display: "none",
                                    width: "42px",
                                    height: "42px",
                                  }}
                                  onChange={handleCMCCostCertificateUpload}
                                />
                              </>
                            )}
                          </Stack>

                          <Stack
                            sx={{
                              minHeight: "50px",
                              maxHeight: "150px",
                              width: "auto",
                              flexDirection: "row",
                              overflowX: "auto",
                              overflowY: "hidden",
                              gap: "20px",
                              alignItems: "center",
                            }}
                          >
                            {cmcCostImageUrl?.map((item, index) => (
                              <Badge
                                key={index}
                                badgeContent={
                                  <CloseIcon
                                    style={{
                                      color: " rgba(255, 58, 68, 1)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      removeCostCertificate(
                                        item?.imageid,
                                        "cmc"
                                      )
                                    }
                                  />
                                }
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                  border: "0.5px solid rgba(27, 37, 53, 1)",
                                  padding: "5px",
                                }}
                              >
                                <Box
                                  as="img"
                                  src={
                                    item.imageid.toLowerCase().endsWith(".pdf")
                                      ? pdfImage
                                      : item?.image
                                  }
                                  alt={item?.image}
                                  sx={{ width: "70px", height: "90px" }}
                                />
                              </Badge>
                            ))}
                          </Stack>
                          {isCMCCostCertificateError && (
                            <HelperText sx={{ color: "red" }}>
                              {isCMCCostCertificateError}
                            </HelperText>
                          )}
                        </Stack>
                      </>
                    )}
                  </CheckBoxContainer>
                </Stack>

                <Stack
                  sx={{
                    minHeight: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <Stack
                    sx={{
                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                    }}
                  >
                    <Label>
                      Asset Grade{" "}
                      <span style={{ color: "red", fontWeight: "100" }}>*</span>
                    </Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleAssetGrade}
                      value={assetGrade}
                    >
                      <Tooltip title="A refers to Very Critical" arrow>
                        <Stack>
                          <FormControlLabel
                            value="A"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                A
                              </Typography>
                            }
                          />
                        </Stack>
                      </Tooltip>
                      <Tooltip title="B refers to Critical" arrow>
                        <Stack>
                          <FormControlLabel
                            value="B"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                B
                              </Typography>
                            }
                          />
                        </Stack>
                      </Tooltip>
                      <Tooltip title="C refers to Important" arrow>
                        <Stack>
                          <FormControlLabel
                            value="C"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                C
                              </Typography>
                            }
                          />
                        </Stack>
                      </Tooltip>
                      <Tooltip title="D refers to Others" arrow>
                        <Stack>
                          <FormControlLabel
                            value="D"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                D
                              </Typography>
                            }
                          />
                        </Stack>
                      </Tooltip>
                    </RadioGroup>
                    <Stack>
                      <Label>Note</Label>
                      <Box>A. Refers to Very Critical</Box>
                      <Box>B. Refers to Critical</Box>
                      <Box>C. Refers to Important</Box>
                      <Box>D. Refers to Others</Box>
                    </Stack>
                    {
                      <HelperText sx={{ textAlign: "left" }}>
                        {isAssetGradeError}
                      </HelperText>
                    }
                  </Stack>
                </Stack>
              </InnerSplitContainer>
            </SplitContainer>
            <BottomElement>
              <Label sx={{ marginTop: "15px" }}>Notes</Label>
              <NotesInput
                placeholder="e.g. Regular inspection log maintained to comply with regulations. (Max 1000 characters)"
                onChange={handleNotes}
                value={notes}
                sx={{ bgcolor: "#F4F6F6", width: "100%" }}
                autoComplete="off"
              />
              <HelperText sx={{ color: "red" }}>{error}</HelperText>
            </BottomElement>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <OuterSet
        sx={{
          width: "800px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}
        >
          <Label variant="subtitle1" color="primary">
            New asset
          </Label>
        </Stack>

        <Stack
          sx={{
            width: "100%",
            maxWidth: 800,
            margin: "auto",
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BlueStepper
            activeStep={activeStep}
            nonLinear
            style={{
              height: "70px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Step>
              <StepLabel>Step 1</StepLabel>
            </Step>
            <Step>
              <StepLabel>Step 2</StepLabel>
            </Step>
          </BlueStepper>

          <OuterBox sx={{}}>
            {getStepContent(activeStep)}

            <>
              <Stack
                sx={{
                  width: "100%",

                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Divider />
                {isToastVisible && (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "20px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      fontSize: "16px",
                      zIndex: 1000,
                    }}
                  >
                    {toastMessage}
                  </div>
                )}
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "16px",
                    }}
                  >
                    <Stack
                      sx={{
                        flexShrink: 1,
                        width: {
                          xs: "85%",
                          sm: "90%",
                          md: "100%",
                          lg: "100%",
                          xl: "100%",
                        },
                        alignItems: "flex-end",
                        gap: "20px",
                        flexDirection: "row",
                        justifyContent: "end",
                        padding: "20px",
                      }}
                    >
                      {activeStep === 0 ? null : (
                        <Button
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}
                          onClick={handleBack}
                          disabled={
                            isWarrantyError ||
                            isAmcEndDateError ||
                            isCmcEndDateError ||
                            isCalibrationDateError
                          }
                        >
                          Back
                        </Button>
                      )}

                      {activeStep === 0 ? (
                        <Button
                          onClick={handleNext}
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          disabled={isGuest}
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </>
              </Stack>
            </>
          </OuterBox>
        </Stack>
      </OuterSet>
      {/* <AddAssetModal
        status={status}
        handleCloseModal={handleCloseModal}
        toggle={toggle}
        text={"Successful! "}
        subText1={"New asset added.  "}
        subText2={"  "}
      /> */}
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"New asset added . "}
        subText2={"  "}
      />
    </Stack>
  );
};

export default AddAssetPage;
