import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
import pdfImage from "../../src/images/pdfImage.png";

import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import {
  edtClearIncident,
  clearIncidentExpenditureImageRemove,
  clearIncidentImageRemove,
  clearIncidentImageUpload,
  retriveIncidentDetails,
  incidentExpenditureImageUpload,
  incidentIssue,
  serviceImageUpload,
} from "../redux/data/action";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "dayjs/locale/de";
import "dayjs/locale/en-gb";

import { DatePicker } from "@mui/x-date-pickers";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  height: "20px",
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const EditIncidentDetails = () => {
  let incidentDetail = useSelector(
    (store) => store.data.retriveincientdetailsdata
  );
  let incidentIssues = useSelector((store) => store.data.incidentIssue);
  const [description, setDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState(null);
  const [isAccessoryPhotoError, setIsAccessoryPhotoError] = useState("");
  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState(null);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const response = useSelector((store) => store.data.clearIncident);
  const token = localStorage.getItem("userToken");
  const [fileUrlAccessory, setFileUrlAccessory] = useState([]);
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [nameError, setNameError] = useState(null);
  const [isMobileNumberError, setIsMobileNumberError] = useState(null);
  const [isEmailError, setIsEmailError] = useState(null);
  const [isActionError, setIsActionError] = useState(null);
  const uploadedAccessoryImageName = fileUrlAccessory?.map((el) => el?.imageid);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    description: "",
    totalAmountSpent: "",
    expImage: [{ image: "", imageId: "" }],
  });

  useEffect(() => {
    setName(incidentDetail?.person_name || "");
    setMobile(incidentDetail?.person_phone || "");
    setEmail(incidentDetail?.person_email || "");
    setAction(incidentDetail?.actiontaken || "");
    setRemark(incidentDetail?.feedback || "");
    setFileUrl(incidentDetail?.clear_imgs || []);
    setClosedate(incidentDetail?.clear_date || []);
    setClosetime(incidentDetail?.clear_time || []);
  }, [incidentDetail]);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];
    const fileType = singlefile.type.split("/")[1];
    const allowedTypes = ["jpeg", "jpg", "png"];
    const allowedSize = 4 * 1024 * 1024; // 4MB

    if (!allowedTypes.includes(fileType)) {
      setIsPhotoError(`Can't upload ${fileType} file.`);
      return;
    }

    if (singlefile.size > allowedSize) {
      setIsPhotoError(`Can't upload image more than 4MB.`);
      return;
    }

    setIsPhotoError(null);

    const reader = new FileReader();
    reader.onloadend = () => {
      let sendData = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        imageid: `assetId_${uuid()}.${fileType}`,
        image: reader.result, // Base64 encoded image data
        branchid: userBranch.id,
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
      };


      setFileUrl([...fileUrl, sendData]);
      dispatch(clearIncidentImageUpload(sendData, token));
    };
    reader.readAsDataURL(singlefile);
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
                userrole: userAccess?.role,
                depart_ids: userAccess.departmentids,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(clearIncidentImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [isRemarkError, setIsRemarkError] = useState("");
  const [closedate, setClosedate] = useState("");
  const [closetime, setClosetime] = useState("");

  const dispatch = useDispatch();
  const params = useParams();

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    localStorage.removeItem("incidentId");
    navigate(-1);
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.-]+$/i;
    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:, -]+$/;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };
  const handleNumber = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, "");
    if (value.length > 10) return;

    const regex = /^\d{10}$/;

    setMobile(value);

    if (value === "") {
      setIsMobileNumberError("Please fill the field");
    } else if (!regex.test(value)) {
      setIsMobileNumberError("Wrong Mobile number");
    } else {
      setIsMobileNumberError(null);
    }
  };

  const handleRemarkChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.;:, -]+$/;

    if (value === "" || regex.test(value)) {
      setRemark(value);
      setIsRemarkError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsRemarkError("Invalid character");
    }
    if (value === "") {
      setIsRemarkError("Please fill the field");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "") {
      setEmail(value);
      setIsEmailError("Please enter a valid email");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setEmail(value);
      setIsEmailError(null);
    } else {
      setIsEmailError("Enter valid email");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
    };

    dispatch(retriveIncidentDetails(data));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    dispatch(incidentIssue(data, token));
  }, [dispatch]);

  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };
    dispatch(clearIncidentImageRemove(data));

    // Fixing the filtering logic to remove the correct image
    setFileUrl(fileUrl?.filter((el) => el.imageid !== id && el !== id));
  };

  const validateExpenses = (expenditures) => {
    const errors = expenditures.map((expense, index) => {
      let error = {};

      if (!expense.description || expense.description.trim() === "") {
        error.error = `Please fill description`;
      }

      if (!expense.totalAmountSpent || expense.totalAmountSpent.trim() === "") {
        error.error = `Please fill total amount spent`;
      }

      if (
        !expense.expImage ||
        expense.expImage.length === 0 ||
        !expense.expImage[0].image ||
        expense.expImage[0].image.trim() === ""
      ) {
        error.error = `Please add image`;
      }

      return Object.keys(error).length ? error : null;
    });

    return errors.filter((error) => error !== null);
  };

  let expend = validateExpenses(expenditures)?.length;
  let expError = validateExpenses(expenditures);

  const handleClicks = () => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();
    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();
  
    let errorMessage = null;
  
    // Regex for email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Clear previous errors
    setNameError("");
    setIsMobileNumberError("");
    setIsEmailError("");
    setIsActionError("");
  
    if (trimmedName === "") {
      errorMessage = "Please enter a valid Name.";
      setNameError(errorMessage);
    } else if (trimmedMobile === "" || !/^\d{10}$/.test(trimmedMobile)) {
      errorMessage = "Please enter a valid 10-digit mobile number.";
      setIsMobileNumberError(errorMessage);
    } else if (trimmedEmail === "" || !emailRegex.test(trimmedEmail)) {
      errorMessage = "Please enter a valid email address.";
      setIsEmailError(errorMessage);
    } else if (trimmedAction === "") {
      errorMessage = "Please enter a valid action.";
      setIsActionError(errorMessage);
    } else if (!fileUrl || fileUrl.length === 0) {
      errorMessage = "Please upload at least one image.";
    }
  
    for (let i = 0; i < expenditureRows.length; i++) {
      const item = expenditureRows[i];
    
      if (
        !item.reason || item.reason.trim() === "" || 
        !item.amtSpent || item.amtSpent.toString().trim() === "" || 
        !item.receiptUrl
      ) {
        errorMessage = "All expenditure fields (reason, amount, and receipt) are mandatory.";
        break;
      }
    }
    
  
    if (errorMessage) {
      setToastMessage(errorMessage);
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000);
      return;
    }
  
    // Proceed to submit if all validations pass
    if (
      trimmedName &&
      /^\d{10}$/.test(trimmedMobile) &&
      emailRegex.test(trimmedEmail) &&
      trimmedAction &&
      !isTotalAmountSpentError &&
      !isDescriptionError
    ) {
      let datas = {
        hospid: userIds.hospid,
        username: userIds.username,
        userid: userIds.userid,
        userrole: userAccess.role,
        assetid: incidentDetail.assetid,
        incidid: incidentDetail.incidt_id,
        asset_name: incidentDetail.assetname,
        assetcode: incidentDetail.assetcode,
        model_no: incidentDetail.modelno,
        serial_no: incidentDetail.serialno,
        departid: incidentDetail.departid,
        departname: incidentDetail.deptname,
        incid_startdate: incidentDetail.start_date,
        incid_starttime: incidentDetail.start_time,
        servicer_name: trimmedName,
        serviced_phone: trimmedMobile,
        serviced_email: trimmedEmail,
        action_taken: trimmedAction,
        serviced_feedback: remark.length > 0 ? remark.trim() : "NA",
  
        cleared_images: fileUrl
          ?.map((item) => {
            if (typeof item === "string") {
              const match = item.match(/cleared-incidents\/([^?]+)/);
              return match ? match[1] : null;
            } else if (item.imageid) {
              return item.imageid;
            }
            return null;
          })
          .filter(Boolean),
  
        incid_issue: incidentIssues.incdt_issue,
        branchid: userBranch.id,
        expenditure: expenditureRows?.map((item) => item.reason),
        amt_spent: expenditureRows?.map((item) => Number(item.amtSpent)),
        receipt_id: expenditureRows.map(
          (row) => row.receiptId || row.expenditureId
        ),
        userrole: userAccess?.role,
        depart_ids: userAccess.departmentids,
        incid_cleardate: closedate,
        incid_cleartime: closetime || "",
      };
  
      setOpenModal(true);
      setToggle(true);
      dispatch(edtClearIncident(datas, token));
    }
  };
  

  useEffect(() => {
    if (response === "Updated" && name !== "") {
      setOpenModal(true);
    }
  }, [response]);

  const handleTotalAmountSpent = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError(null);
    } else {
      setIsTotalAmountSpentError("Enter a valid amount");
    }
  };

  const handleAddExpenditure = () => {
    setExpenditureRows((prevRows) => [
      ...prevRows,
      { reason: "", amtSpent: "", receiptUrl: "" },
    ]);
  };

  const [expenditureRows, setExpenditureRows] = useState(
    incidentDetail.expenditure?.map((reason, index) => ({
      reason,
      amtSpent: incidentDetail.amt_spent[index] || "",
      receiptUrl: incidentDetail.receipt_id[index] || "",
      expenditureId: incidentDetail.expenditure_ids[index] || "",
    })) || []
  );

  useEffect(() => {
    setExpenditureRows(
      incidentDetail.expenditure?.map((reason, index) => ({
        reason,
        amtSpent: incidentDetail.amt_spent[index] || "",
        receiptUrl: incidentDetail.receipt_id[index] || "",
        expenditureId: incidentDetail.expenditure_ids[index] || "",
      })) || []
    );
  }, [incidentDetail]);

  const handleDescriptionChange = (e, index) => {
    const updatedRows = [...expenditureRows];
    updatedRows[index].reason = e.target.value;
    setExpenditureRows(updatedRows);
  };

  const handleAmountChange = (e, index) => {
    let value = e.target.value;
    if (value === "0") return;
    const updatedRows = [...expenditureRows];
    updatedRows[index].amtSpent = e.target.value;
    setExpenditureRows(updatedRows);
  };

  const handleRemoveExpenditure = (index) => {
    const updatedRows = expenditureRows.filter((_, i) => i !== index);
    setExpenditureRows(updatedRows);
  };

  const handleFileUpload = (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const allowedImageSize = 4 * 1024 * 1024; // 4MB
      const allowedPdfSize = 6 * 1024 * 1024; // 6MB
      const fileType = file.type.split("/")[1]; // Extract subtype (jpeg, jpg, png, pdf)
      const allowedTypes = ["jpeg", "jpg", "png", "pdf"];

      if (!allowedTypes.includes(fileType)) {
        setIsAccessoryPhotoError(`Can't upload ${fileType} file.`);
        return;
      }

      // Validate file size
      if (fileType !== "pdf" && file.size > allowedImageSize) {
        setIsAccessoryPhotoError("Image files must be less than 4MB.");
        return;
      }

      if (fileType === "pdf" && file.size > allowedPdfSize) {
        setIsAccessoryPhotoError("PDF files must be less than 6MB.");
        return;
      }

      setIsAccessoryPhotoError(null); // Clear any previous errors

      const reader = new FileReader();

      reader.onloadend = () => {
        // Generate a unique 8-character ID
        const uuidShort = uuid().replace(/-/g, "").substring(0, 8);
        const imageid = `${incidentDetail.assetid.substring(
          0,
          4
        )}_${uuidShort}.${fileType}`; // 12-char ID

        const newFile = {
          id: uuid(),
          name: file.name,
          type: fileType,
          url: URL.createObjectURL(file),
          imageid, // 12-character image ID
        };

        const payload = {
          hospid: ids?.hospid,
          userid: ids?.userid,
          imageid: newFile.imageid,
          image: reader.result, // Base64 representation of the image
          branchid: userBranch.id,
        };

        // Dispatch action to upload the file
        dispatch(incidentExpenditureImageUpload(payload));

        // Update the state to reflect the new uploaded image
        setExpenditureRows((prevRows) => {
          const updatedRows = [...prevRows];
          updatedRows[index] = {
            ...updatedRows[index],
            receiptUrl: newFile.url, // The new image URL
            receiptId: newFile.imageid, // The reduced image ID for each row
          };
          return updatedRows;
        });
      };

      reader.readAsDataURL(file); // Read file as base64 for dispatch payload
    }
  };

  const handleDeleteExpImage = (id, index) => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();
    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();

    let hasIncompleteExpenditure = false;
    let errorMessage = null;

    if (
      trimmedName === "" ||
      trimmedMobile === "" ||
      trimmedEmail === "" ||
      trimmedAction === ""
    ) {
      if (trimmedName === "") {
        errorMessage = "Please enter a valid Name.";
        setNameError("Please enter a valid Name.");
      } else if (trimmedMobile === "") {
        errorMessage = "Please enter a valid mobile number.";
        setIsMobileNumberError("Please enter a valid mobile number.");
      } else if (trimmedEmail === "") {
        errorMessage = "Please enter a valid email.";
        setIsEmailError("Please enter a valid email.");
      } else if (trimmedAction === "") {
        errorMessage = "Please enter a valid action.";
        setIsActionError("Please enter a valid action.");
      }
    }

    if (!fileUrl || fileUrl.length === 0) {
      errorMessage = "Please upload at least one image.";
    }

    if (fileUrl.length === 1) {
      errorMessage = "At least one image is required. or ReUpload the image";
    }

    for (let i = 0; i < expenditureRows.length; i++) {
      const item = expenditureRows[i];
      if (!item.reason || !item.amtSpent || !item.receiptUrl) {
        errorMessage = "All expenditure fields are mandatory.";
        break;
      }
    }

    // If there is an error message, set the toast and return
    if (errorMessage) {
      setToastMessage(errorMessage);
      setIsToastVisible(true);
      setTimeout(() => setIsToastVisible(false), 3000); // Hide toast after 3 seconds
      return;
    }

    const data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
      userrole: userAccess?.role,
      depart_ids: userAccess.departmentids,
    };

    // dispatch(clearIncidentExpenditureImageRemove(data))
    // .then(()=>{
    //   let datas = {
    //     hospid: userIds.hospid,
    //     username: userIds.username,
    //     userid: userIds.userid,
    //     userrole: userAccess.role,
    //     assetid: incidentDetail.assetid,
    //     incidid: incidentDetail.incidt_id,
    //     asset_name: incidentDetail.assetname,
    //     assetcode: incidentDetail.assetcode,
    //     model_no: incidentDetail.modelno,
    //     serial_no: incidentDetail.serialno,
    //     departid: incidentDetail.departid,
    //     departname: incidentDetail.deptname,
    //     incid_startdate: incidentDetail.start_date,
    //     incid_starttime: incidentDetail.start_time,
    //     servicer_name: trimmedName,
    //     serviced_phone: trimmedMobile,
    //     serviced_email: trimmedEmail,
    //     action_taken: trimmedAction,
    //     serviced_feedback: remark.length > 0 ? remark.trim() : "NA",

    //     cleared_images: fileUrl
    //       ?.map((item) => {
    //         if (typeof item === "string") {
    //           // Extract assetId.filename from URL
    //           const match = item.match(/cleared-incidents\/([^?]+)/);
    //           return match ? match[1] : null;
    //         } else if (item.imageid) {
    //           return item.imageid;
    //         }
    //         return null;
    //       })
    //       .filter(Boolean), // Remove any null values

    //     incid_issue: incidentIssues.incdt_issue,
    //     branchid: userBranch.id,
    //     expenditure: expenditureRows?.map((item) => item.reason), // Description
    //     amt_spent: expenditureRows?.map((item) => Number(item.amtSpent)), // Amount spent
    //     receipt_id: expenditureRows.map(
    //       (row) => row.receiptId || row.expenditureId
    //     ), // Ensure all receipt IDs are included
    //     userrole: userAccess?.role,
    //     depart_ids: userAccess.departmentids,
    //     incid_cleardate: closedate,
    //     incid_cleartime: closetime || "",
    //   };
    //         dispatch(edtClearIncident(datas, token));
    // })

    // setExpenditureRows((prevRows) =>
    //   prevRows.map((row, i) => (i === index ? { ...row, receiptUrl: "" } : row))
    // );
  };

  const handleDescriptionObject = (e, index) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9.,\s-]*$/;

    if (regex.test(value)) {
      let updatedExpenditures = [...expenditures];
      updatedExpenditures[index].description = value;
      setExpenditures(updatedExpenditures);
    }
  };

  // const handleFileUpload = (event, index) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const allowedImageSize = 4 * 1024 * 1024;
  //     const allowedPdfSize = 6 * 1024 * 1024;
  //     const isPdf = file.type === "application/pdf";
  //     const isImage = file.type.includes("image");

  //     if (isImage && file.size > allowedImageSize) {
  //       setIsAccessoryPhotoError("Image files must be less than 4MB.");
  //       return;
  //     }

  //     if (isPdf && file.size > allowedPdfSize) {
  //       setIsAccessoryPhotoError("PDF files must be less than 6MB.");
  //       return;
  //     }
  //     setIsAccessoryPhotoError(null);
  //     const fileType = isPdf ? "pdf" : "png";
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       const newFile = {
  //         id: uuid(),
  //         name: file.name,
  //         type: file.type,
  //         url: URL.createObjectURL(file),
  //         imageid: `${params.id}-${uuid()}.${fileType}`,
  //       };

  //       const payload = {
  //         hospid: ids?.hospid,
  //         userid: ids?.userid,
  //         imageid: newFile.imageid,
  //         image: reader.result,
  //         branchid: userBranch.id,
  //         userrole: userAccess?.role,
  //         depart_ids: userAccess.departmentids,
  //       };

  //       dispatch(incidentExpenditureImageUpload(payload))
  //         .then((response) => {
  //           const uploadedImage = {
  //             ...newFile,
  //             url: response?.payload?.url || newFile.url,
  //           };

  //           const updatedExpenditures = [...expenditures];
  //           updatedExpenditures[index].expImage = [uploadedImage];

  //           setExpenditures(updatedExpenditures);

  //           const updatedFileUrls = [...fileUrlAccessory];
  //           updatedFileUrls[index] = [uploadedImage];

  //           setFileUrlAccessory(updatedFileUrls);
  //         })
  //         .catch((error) => {});
  //     };

  //     reader.readAsDataURL(file);
  //   }

  //   event.target.value = null;
  // };

  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

          height: "100%",
        }}
      >
        <Stack
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "80%",
              lg: "60%",
              xl: "50%",
            },

            borderRadius: "8px",

            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            gap: "10px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",

              padding: "20px",
            }}
          >
            <Label
              variant="subtitle1"
              color="primary"
              sx={{ paddingLeft: "20px" }}
            >
              Edit Incident
            </Label>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              // maxWidth: 800,
              margin: "auto",
              bgcolor: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginTop: 2, width: "90%" }}>
              <Stack sx={{ bgcolor: "white", gap: "30px" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  <Stack
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "50%",
                      },

                      gap: "15px",

                      height: "100%",
                      textAlign: "start",
                    }}
                  >
                    <Label style={{ color: "#4690FF", textAlign: "left" }}>
                      Representative
                    </Label>
                    <Stack>
                      <Field
                        label=" Name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />

                      <HelperText variant="caption">{nameError}</HelperText>
                    </Stack>
                    <Stack>
                      <Field
                        label=" Mobile Number"
                        onChange={(e) => setMobile(e.target.value)}
                        value={mobile}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />

                      <HelperText variant="caption">
                        {isMobileNumberError}
                      </HelperText>
                    </Stack>
                    <Stack>
                      <Field
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />

                      <HelperText variant="caption">{isEmailError}</HelperText>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack sx={{ marginBottom: "10px", marginTop: "10px" }}>
                  <Divider />
                </Stack>

                <Label style={{ color: "#4690FF", textAlign: "left" }}>
                  Issue
                </Label>

                <Box sx={{ bgcolor: "white" }}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                    }}
                  >
                    <Stack
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                        },
                        gap: "15px",

                        height: "100%",
                        textAlign: "start",
                      }}
                    >
                      <Stack sx={{ gap: "10px" }}>
                        <Label>Action Taken</Label>
                        <Field
                          multiline
                          rows={3}
                          label="Action Taken"
                          onChange={(e) => setAction(e.target.value)}
                          value={action}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          sx={{ width: "100%" }}
                        />
                      </Stack>
                      <Stack sx={{ gap: "10px" }}>
                        <Label>Remark</Label>
                        <Field
                          multiline
                          rows={3}
                          label="Remark"
                          onChange={(e) => setRemark(e.target.value)}
                          value={remark}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          sx={{ width: "100%" }}
                        />
                      </Stack>
                      {<HelperText>{isRemarkError}</HelperText>}
                    </Stack>

                    <Stack
                      sx={{
                        width: "50%",
                        gap: "3px",
                        height: "100%",
                        textAlign: "start",
                        display: { xs: "none", sm: "none", md: "flex" },
                      }}
                    >
                      <Stack
                        sx={{
                          width: "100%",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Label>Upload Images</Label>

                        {/* Hide Upload Icon if 3 or more images are uploaded */}
                        {fileUrl?.length < 3 && (
                          <>
                            <label htmlFor="img">
                              <FileUploadIcon
                                size={20}
                                style={{
                                  color: "rgba(27, 37, 53, 1)",
                                  cursor: "pointer",
                                }}
                              />
                            </label>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img"
                              style={{
                                display: "none",
                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        )}
                      </Stack>

                      {/* Image Display */}
                      <Stack
                        sx={{
                          height: "93px",
                          width: "100%",
                          flexDirection: "row",
                          overflowX: "auto",
                          gap: "20px",
                          alignItems: "center",
                          background: "rgba(244, 246, 246, 1)",
                          justifyContent: "center",
                        }}
                      >
                        {Array.isArray(fileUrl)
                          ? fileUrl.map((item, index) => (
                              <Badge
                                key={index}
                                badgeContent={
                                  <CloseIcon
                                    size={15}
                                    style={{
                                      color: "rgba(255, 58, 68, 1)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDelete(item.imageid || item)
                                    } // Ensure `imageid` exists
                                  />
                                }
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                  padding: "5px",
                                }}
                              >
                                <Box
                                  as="img"
                                  src={item.image || item}
                                  alt=""
                                  sx={{ width: "50px", height: "50px" }}
                                />
                              </Badge>
                            ))
                          : null}
                      </Stack>

                      {isPhotoError && (
                        <HelperText sx={{ color: "red" }}>
                          {isPhotoError}
                        </HelperText>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "30px" }}
              >
                <Stack sx={{ width: "50%" }}>
                  <Field
                    label="Close Date"
                    onChange={(e) => {
                      const formattedDate = dayjs(e.target.value).format(
                        "DD-MM-YYYY"
                      );
                      setClosedate(formattedDate);
                    }}
                    type="date"
                    value={
                      closedate
                        ? dayjs(closedate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        : ""
                    }
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ min: dayjs().format("YYYY-MM-DD") }}
                  />
                  <HelperText variant="caption"></HelperText>
                </Stack>

                <Stack sx={{ width: "50%" }}>
                  <TextField
                    label="Close Time"
                    type="time"
                    variant="filled"
                    value={closetime}
                    onChange={(e) => setClosetime(e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ step: 60 }} // Ensures minute precision
                  />
                </Stack>
              </Stack>
              <Stack sx={{ marginBottom: "10px", marginTop: "20px" }}>
                <Divider />
              </Stack>

              <Stack
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Label style={{ color: "#4690FF", textAlign: "left" }}>
                  Expenditure
                </Label>

                <Button
                  onClick={handleAddExpenditure}
                  variant="outlined"
                  sx={{ borderRadius: "25px" }}
                  disabled={
                    expenditureRows.length > 0 &&
                    (!expenditureRows[expenditureRows.length - 1].reason ||
                      !expenditureRows[expenditureRows.length - 1].amtSpent ||
                      !expenditureRows[expenditureRows.length - 1].receiptUrl)
                  }
                >
                  Add Expenditure
                </Button>
              </Stack>

              <Stack spacing={2}>
                {expenditureRows.length > 0 ? (
                  expenditureRows.map((expenditureItem, index) => (
                    <Stack
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "20px",
                        border: "1px solid #cbcbcb",
                        borderRadius: "20px",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Stack
                        sx={{
                          justifyContent: "end",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <Stack
                          onClick={() => handleRemoveExpenditure(index)}
                          sx={{ cursor: "pointer" }}
                        >
                          <CloseIcon />
                        </Stack>
                      </Stack>

                      {/* Expenditure Reason */}
                      <Stack
                        sx={{
                          justifyContent: "space-between",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{ gap: "10px", width: "50%", textAlign: "start" }}
                        >
                          <Field
                            value={expenditureItem.reason || ""}
                            placeholder="Reason description"
                            onChange={(e) => handleDescriptionChange(e, index)}
                            variant="outlined"
                            sx={{ width: "100%" }}
                          />
                          <HelperText>
                            {expenditureItem.reason
                              ? ""
                              : "Please fill in the reason description."}
                          </HelperText>
                        </Stack>

                        {/* Total Amount Spent */}
                        <Stack
                          sx={{ gap: "10px", width: "50%", textAlign: "start" }}
                        >
                          <Field
                            value={expenditureItem.amtSpent || ""}
                            placeholder="Total amount spent"
                            onChange={(e) => handleAmountChange(e, index)}
                            variant="outlined"
                            sx={{ width: "100%" }}
                            type="number"
                          />
                          <HelperText>
                            {expenditureItem.amtSpent
                              ? ""
                              : "Please fill in the Total amount spent."}
                          </HelperText>
                        </Stack>
                      </Stack>

                      {/* File Upload */}
                      <Stack
                        sx={{
                          justifyContent: "space-between",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            gap: "3px",
                            height: "100%",
                            textAlign: "start",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "50%",
                              justifyContent: "end",
                              flexDirection: "row",
                            }}
                          >
                            <label htmlFor={`file-upload-${index}`}>
                              <FileUploadIcon
                                size={20}
                                style={{
                                  color: "rgba(27, 37, 53, 1)",
                                  cursor: "pointer",
                                }}
                              />
                            </label>
                            <input
                              type="file"
                              id={`file-upload-${index}`}
                              style={{
                                display: "none",
                                width: "42px",
                                height: "42px",
                              }}
                              accept="image/*,.pdf"
                              onChange={(e) => handleFileUpload(e, index)}
                            />
                          </Stack>

                          {/* Display Uploaded Files */}
                          <Stack
                            sx={{
                              height: "93px",
                              flexDirection: "row",
                              overflowX: "auto",
                              gap: "20px",
                              alignItems: "center",
                              background: "rgba(244, 246, 246, 1)",
                              width: "47.2%",
                              justifyContent: "center",
                            }}
                          >
                            {expenditureItem.receiptUrl && (
                              <Badge
                                key={index}
                                badgeContent={
                                  <CloseIcon
                                    size={15}
                                    style={{
                                      color: "rgba(255, 58, 68, 1)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteExpImage(
                                        expenditureItem.expenditureId,
                                        index
                                      )
                                    }
                                  />
                                }
                              >
                                {expenditureItem.receiptId?.endsWith(".pdf") ? (
                                  // Display a gray box for PDFs
                                  <Box
                                    sx={{
                                      width: "50px",
                                      height: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      background: "gray",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    PDF
                                  </Box>
                                ) : (
                                  <>
                                  
                                    <Box
                                      as="img"
                                      src={
                                        expenditureItem.receiptUrl?.includes(
                                          ".pdf"
                                        )
                                          ? pdfImage
                                          : expenditureItem.receiptUrl
                                      }
                                      alt={`uploaded-file-${index}`}
                                      sx={{ width: "50px", height: "50px" }}
                                    />
                                  </>
                                )}
                              </Badge>
                            )}
                          </Stack>

                          {(!expenditureItem.receiptUrl ||
                            expenditureItem.receiptUrl.length === 0) && (
                            <HelperText sx={{ color: "red" }}>
                              Please upload an image
                            </HelperText>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  ))
                ) : (
                  <HelperText sx={{ color: "red" }}>
                    No expenditures available.
                  </HelperText>
                )}
              </Stack>

              {isToastVisible && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    zIndex: 1000,
                  }}
                >
                  {toastMessage}
                </div>
              )}

              <>
                <Stack
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexShrink: 1,
                          width: "100%",
                          alignItems: "flex-end",
                          gap: "20px",
                          flexDirection: "row",
                          justifyContent: "end",
                          padding: "20px",
                        }}
                      >
                        <Button
                          onClick={handleClicks}
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </Box>
                  </>
                </Stack>
              </>
            </Box>
          </Stack>
        </Stack>

        <SuccessPopUp
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          text={"Successful! "}
          subText1={"Incident Edit  has been successfully processed.  "}
          subText2={"  "}
        />
      </Stack>
    </>
  );
};

export default EditIncidentDetails;
